import React from 'react';
import {
    useGetDocumentQuery,
    usePostCommentMutation,
} from '../../../../services/apAutomation/apAutomation.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { useFormFields } from '../../../../libs/hooksLib';

const useCommentsBox = (documentId: string) => {
    const [postComment, { isLoading: isLoadingCommentSave }] =
        usePostCommentMutation();
    const { data: activeDocument } = useGetDocumentQuery(
        documentId ? documentId : skipToken
    );
    const [fields, handleFieldChange] = useFormFields({
        comment: '',
    });

    const submitComment = async (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();

        const args = {
            documentId: activeDocument.documentId,
            comment: fields.comment,
        };
        postComment(args);
    };

    const isCommentValid = () => {
        return fields.comment.length > 0;
    };

    return {
        fields,
        handleFieldChange,
        submitComment,
        isLoadingCommentSave,
        activeDocument,
        isCommentValid,
    };
};

export default useCommentsBox;
