export const GridDefaults = {
    domLayout: 'autoHeight',
    tooltipShowDelay: 0,
    pagination: true,
    paginationPageSize: 10,
    immutableData: false,
    sizeColumnsToFit: true,
    displayToolbar: false,
    alwaysShowHorizontalScroll: true,
    accentedSort: true,
};

export const GridOptions = {
    sortFilterAndWrapColumns: {
        resizable: true,
        sortable: true,
        unSortIcon: true,
        filter: true,
        wrapText: true,
    },
};

export const GridPopups: {
    [key: string]: { gridName: string; fieldName: string };
} = {
    CAS_EXPOSURE: { gridName: 'CasExposure', fieldName: 'code' },
    ECO_TOX: { gridName: 'CasEcoToxity', fieldName: 'toxicTestCode' },
    ECO_TOX_SPECIES: {
        gridName: 'CasEcoToxity',
        fieldName: 'toxicSpeciesCode',
    },
    ITEM_CHEMICAL_COMPOSITION: {
        gridName: 'ItemChemicalComposition',
        fieldName: 'casNumber',
    },
    FORMULA_CAS_COMPOSITION: {
        gridName: 'RDFormulaCasComposition',
        fieldName: 'casValue',
    },
    SDS_CAS_COMPOSITION: {
        gridName: 'SdsCasComposition',
        fieldName: 'casValue',
    },
};
