import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import FormPartial from '../../components/form/formContainer/FormPartial';
import useDocDefinitionForm from './hooks/useDocDefinitionForm';
import BaseForm from '../../components/form/baseForm/BaseForm';
import TranslatableText from '../../components/i18n/TranslatableText';
import { DocDefinitionFormDefs } from '../../constants/i18n/translations/termDefinitions/platform';
import CheckBoxInput from '../../components/form/formInputs/CheckBoxInput/CheckBoxInput';
import SelectInput from '../../components/form/formInputs/SelectInput/SelectInput';

type ParamTypes = { id: string };

const DocDefinitionForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        handleClose,
        handleFieldChange,
        handleCreateDocDefinition,
        handleUpdateDocDefinition,
        isLoading,
        formMethods,
        termSet,
        applicationsForSelect,
        entityTypesForSelect,
        canEditDocDefinition,
        canCreateDocDefinition,
        canDeleteDocDefinition,
        showDeleteConfirmModal,
    } = useDocDefinitionForm(id);

    return (
        <BaseForm
            title={
                <>
                    {id ? (
                        fields.name
                    ) : (
                        <>
                            <TranslatableText
                                termKey={
                                    DocDefinitionFormDefs.Create_New_DocDefinition
                                }
                                termSet={termSet}
                            />
                        </>
                    )}
                </>
            }
            formMethods={formMethods}
            isLoading={isLoading}
            isNew={!id}
            onCreate={handleCreateDocDefinition}
            onUpdate={handleUpdateDocDefinition}
            onDelete={showDeleteConfirmModal}
            onCancel={handleClose}
            displayCreateButton={canCreateDocDefinition}
            displayUpdateButton={canEditDocDefinition}
            displayDeleteButton={canDeleteDocDefinition}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={DocDefinitionFormDefs.Name}
                                    termSet={termSet}
                                />
                            }
                            id="name"
                            value={fields.name}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={90}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={DocDefinitionFormDefs.Code}
                                    termSet={termSet}
                                />
                            }
                            id="code"
                            value={fields.code}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={90}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectInput
                            fullWidth
                            SelectProps={{
                                multiple: false,
                            }}
                            label={
                                <TranslatableText
                                    termKey={
                                        DocDefinitionFormDefs.ApplicationName
                                    }
                                    termSet={termSet}
                                />
                            }
                            id="applicationId"
                            value={fields.applicationId}
                            onChange={handleFieldChange}
                            options={applicationsForSelect}
                            formMethods={formMethods}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectInput
                            fullWidth
                            SelectProps={{
                                multiple: false,
                            }}
                            label={
                                <TranslatableText
                                    termKey={
                                        DocDefinitionFormDefs.EntityTypeName
                                    }
                                    termSet={termSet}
                                />
                            }
                            id="entityTypeId"
                            value={fields.entityTypeId}
                            onChange={handleFieldChange}
                            options={entityTypesForSelect}
                            formMethods={formMethods}
                            required={true}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termKey={DocDefinitionFormDefs.Settings_Divider}
                            termSet={termSet}
                        />
                    ) as unknown as string
                }>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextInput
                            fullWidth
                            id="purgeHours"
                            value={fields.purgeHours}
                            label={
                                <TranslatableText
                                    termKey={DocDefinitionFormDefs.PurgeHours}
                                    termSet={termSet}
                                />
                            }
                            maxLength={15}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput
                            fullWidth
                            id="purgeYears"
                            value={fields.purgeYears}
                            label={
                                <TranslatableText
                                    termKey={DocDefinitionFormDefs.PurgeYears}
                                    termSet={termSet}
                                />
                            }
                            maxLength={15}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={2}>
                        <CheckBoxInput
                            id="enablePreview"
                            label={
                                (
                                    <TranslatableText
                                        termKey={
                                            DocDefinitionFormDefs.EnablePreview
                                        }
                                        termSet={termSet}
                                    />
                                ) as unknown as string
                            }
                            checked={fields.enablePreview}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <CheckBoxInput
                            id="enableSaveAttachment"
                            label={
                                (
                                    <TranslatableText
                                        termKey={
                                            DocDefinitionFormDefs.EnableSaveAttachment
                                        }
                                        termSet={termSet}
                                    />
                                ) as unknown as string
                            }
                            checked={fields.enableSaveAttachment}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <CheckBoxInput
                            id="keepAllCopies"
                            label={
                                (
                                    <TranslatableText
                                        termKey={
                                            DocDefinitionFormDefs.KeepAllCopies
                                        }
                                        termSet={termSet}
                                    />
                                ) as unknown as string
                            }
                            checked={fields.keepAllCopies}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <CheckBoxInput
                            id="checkForDuplicates"
                            label={
                                (
                                    <TranslatableText
                                        termKey={
                                            DocDefinitionFormDefs.CheckForDuplicates
                                        }
                                        termSet={termSet}
                                    />
                                ) as unknown as string
                            }
                            checked={fields.checkForDuplicates}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <CheckBoxInput
                            id="checkForStaleData"
                            label={
                                (
                                    <TranslatableText
                                        termKey={
                                            DocDefinitionFormDefs.CheckForStaleData
                                        }
                                        termSet={termSet}
                                    />
                                ) as unknown as string
                            }
                            checked={fields.checkForStaleData}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default DocDefinitionForm;
