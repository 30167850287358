import React, { useContext } from 'react';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { Popover } from '@mui/material';
import './attachmentSummary.renderer.css';
import moment from 'moment';
import { GridValueFormatterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import FolderIcon from '@mui/icons-material/Folder';
import { AttachmentFileVersion } from '../../../services/attachments/attachments.service';
import NameDisplay from '../../display/NameDisplay';
import AttachmentSummaryContext from '../../../contexts/attachmentSummary.context';

export const AttachmentSummaryPopover = (props: any) => {
    const {
        isAttachmentSummaryOpen,
        setIsAttachmentSummaryOpen,
        rowData,
        anchorEl,
    } = useContext(AttachmentSummaryContext);

    // ensure this popover is associated with the row that was selected.
    const popoverOpen =
        isAttachmentSummaryOpen &&
        rowData?.uuid !== undefined &&
        rowData?.uuid === props.value?.entityUuid;

    // if no attachments, no folder icon.
    if (props.value?.attachments === undefined) {
        return <></>;
    }

    // prep the DataGrid data from the attachments summary.
    const rowDataConverted =
        props.value?.attachments?.map((attachment: any) => {
            const fileVersion = attachment?.attachmentFileVersions
                ?.slice()
                .sort((a: AttachmentFileVersion, b: AttachmentFileVersion) => {
                    return moment(a.updatedAt).isBefore(moment(b.updatedAt))
                        ? 1
                        : -1;
                })[0];

            return {
                id: attachment.id,
                name: `${attachment.name ?? ''}.${attachment.extension ?? ''}`,
                version: fileVersion?.versionNumber,
                createdByUser: attachment.createdByUser,
                createdAt: fileVersion?.createdAt,
                updatedByUser: attachment.updatedByUser,
                updatedAt: fileVersion?.updatedAt,
            };
        }) ?? [];

    const createdUserName = (params: GridCellParams) => (
        <NameDisplay fields={params.row.createdByUser} />
    );

    const updatedUserName = (params: GridCellParams) => (
        <NameDisplay fields={params.row.updatedByUser} />
    );

    const dateFormatter = (params: GridValueFormatterParams<string>) => {
        return moment(params.value).format('MM/DD/YYYY');
    };

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Attachment Name',
            minWidth: 175,
            flex: 1,
        },
        { field: 'version', headerName: 'Version', minWidth: 100, flex: 1 },
        {
            field: 'createdBy',
            headerName: 'Added By',
            minWidth: 100,
            renderCell: createdUserName,
            flex: 1,
        },
        {
            field: 'createdAt',
            headerName: 'Added Date',
            minWidth: 175,
            valueFormatter: dateFormatter,
            flex: 1,
        },
        {
            field: 'updatedBy',
            headerName: 'Updated By',
            minWidth: 100,
            renderCell: updatedUserName,
            flex: 1,
        },
        {
            field: 'updatedAt',
            headerName: 'Updated Date',
            minWidth: 175,
            valueFormatter: dateFormatter,
            flex: 1,
        },
    ];

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        padding: '10px',
    };

    const popoverElement =
        anchorEl !== null && popoverOpen ? (
            <Popover
                open={popoverOpen}
                onClose={() => setIsAttachmentSummaryOpen(false)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                <div style={{ width: '60vw', height: '30vh' }}>
                    <DataGrid
                        classes={{ columnHeader: 'attachment-summary-header' }}
                        sx={style}
                        autoHeight={false}
                        disableExtendRowFullWidth={false}
                        hideFooter={true}
                        hideFooterPagination={true}
                        checkboxSelection={false}
                        disableSelectionOnClick={true}
                        disableColumnFilter={true}
                        disableColumnMenu={true}
                        rows={rowDataConverted}
                        columns={columns}
                        components={{ Toolbar: AttachmentSummaryTitle }}
                    />
                </div>
            </Popover>
        ) : (
            <></>
        );

    return (
        <>
            <div className="centered-content-cell-container">
                <FolderIcon style={{ color: '#F8D775' }} />
            </div>
            {popoverElement}
        </>
    );
};

const AttachmentSummaryTitle = (props: any) => {
    const { rowData } = useContext(AttachmentSummaryContext);

    return <h3>Attachments Summary for Id: {rowData.id}</h3>;
};
