import { ParameterizedValidator, Validator } from './validator.interface';
import _ from 'lodash';
import React from 'react';

export const IsUniqueValidator: ParameterizedValidator = (
    uniqueList: any[]
) => {
    return {
        key: 'isUnique',
        getErrorMessage: (fieldLabel: string | object) => {
            if (_.isObject(fieldLabel)) {
                return <>{fieldLabel} has a value that is already in use</>;
            }
            return `${fieldLabel} has a value that is already in use`;
        },
        isValid: (value: any) => {
            let hasMatch;
            _.forEach(uniqueList, (uniqueListItem): void => {
                if (typeof uniqueListItem === 'string') {
                    uniqueListItem = uniqueListItem.toLowerCase();
                    value = value?.toLowerCase();
                }
                if (value === uniqueListItem) {
                    hasMatch = true;
                }
            });
            return !hasMatch;
        },
    } as Validator;
};
