import React, { useEffect } from 'react';
import NotificationItem from './NotificationItem';
import { FormControlLabel, Link, Popover, Switch } from '@mui/material';
import Divider from '@mui/material/Divider';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import MenuItem from '@mui/material/MenuItem';
import { useMarkReadMutation } from '../../../services/notifications/notifications.service';
import { useNavigate } from 'react-router-dom';
import useTranslation from '../../i18n/hooks/useTranslation';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';
import TranslatableText from '../../i18n/TranslatableText';
import { NotificationPopoverDefs } from '../../../constants/i18n/translations/termDefinitions/platform';

const NotificationsPopover = ({
    notifications,
    anchorEl,
    setAnchorEl,
    open,
}: any) => {
    let navigate = useNavigate();
    const { termSet } = useTranslation(PLATFORM_DEFS.NOTIFICATIONS);
    const [showOnlyUnread, setShowOnlyUnread] = React.useState(true);
    const [listNotifications, setListNotifications] = React.useState<any>([]);
    const [markRead] = useMarkReadMutation();

    useEffect(() => {
        setListNotifications(notifications);
    }, [anchorEl]);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMarkAllAsRead = () => {
        notifications.forEach((notification: any) => {
            if (!notification.read) {
                // mark as read
                markRead(notification?.notification?.id);
            }
        });
    };

    const handleGoToNotificationsPage = () => {
        navigate('/administration/notifications');
        setAnchorEl(null);
    };

    return (
        <Popover
            open={open}
            slotProps={{
                paper: {
                    style: {
                        width: '40%',
                        height: '60%',
                    },
                },
            }}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            onClose={handleClose}>
            <div
                style={{
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between', // Ensures toggle is all the way to the right
                    padding: '24px',
                    position: 'sticky',
                    top: 0,
                    backgroundColor: 'white',
                    zIndex: 1,
                    boxShadow: '0 6px 6px rgba(0, 0, 0, 0.1)', // Shadow stays under the header
                    margin: '0', // Remove margins to make the shadow go edge-to-edge
                }}>
                <span style={{ fontSize: '1.71429em', flexGrow: '1' }}>
                    <TranslatableText
                        termSet={termSet}
                        termKey={NotificationPopoverDefs.Title}
                    />
                </span>
                <div style={{ padding: '10px' }}>
                    {' '}
                    <span
                        onClick={handleGoToNotificationsPage}
                        style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'black',
                        }}>
                        <TranslatableText
                            termSet={termSet}
                            termKey={NotificationPopoverDefs.ViewAll}
                        />
                    </span>
                </div>
                <FormControlLabel
                    style={{ marginLeft: 'auto' }}
                    value="showOnlyUnread"
                    onChange={() => setShowOnlyUnread(!showOnlyUnread)}
                    control={<Switch checked={showOnlyUnread} />}
                    label={
                        <TranslatableText
                            termSet={termSet}
                            termKey={NotificationPopoverDefs.OnlyShowUnread}
                        />
                    }
                    labelPlacement={'start'}
                />
            </div>
            <Divider />

            <div
                style={{
                    maxHeight: 'calc(100% - 88px)',
                    overflowY: 'auto',
                    padding: '0 24px',
                }}>
                {showOnlyUnread && (
                    <span
                        style={{
                            display: 'flex',
                            alignItems: 'end',
                            width: '100%',
                            justifyContent: 'end',
                            paddingTop: '5px',
                        }}
                        onClick={handleMarkAllAsRead}>
                        <Link style={{ color: 'black', cursor: 'pointer' }}>
                            <TranslatableText
                                termSet={termSet}
                                termKey={NotificationPopoverDefs.MarkAllAsRead}
                            />
                        </Link>
                    </span>
                )}
                {!isNilOrEmpty(
                    listNotifications?.filter((item: any) =>
                        showOnlyUnread ? !item.read : item
                    )
                ) &&
                    listNotifications
                        ?.filter((item: any) =>
                            showOnlyUnread ? !item.read : item
                        )
                        ?.slice(0, 20)
                        ?.map((notification: any) => (
                            <NotificationItem
                                key={notification.id}
                                notification={notification}
                            />
                        ))}
            </div>

            {isNilOrEmpty(
                listNotifications?.filter((item: any) =>
                    showOnlyUnread ? !item.read : item
                )
            ) && (
                <div
                    style={{
                        padding: '24px',
                        display: 'grid',
                        placeItems: 'center',
                        textAlign: 'center',
                    }}>
                    <TranslatableText
                        termSet={termSet}
                        termKey={NotificationPopoverDefs.UpToDate}
                    />
                </div>
            )}
        </Popover>
    );
};

export default NotificationsPopover;
