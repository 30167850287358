import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, Typography } from '@mui/material';
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from '@mui/lab';
import AttachmentIcon from '@mui/icons-material/Attachment';
import AttachmentItemModal from './AttachmentItemModal';
import FileDropUploader from '../../dragAndDrop/FileDropUploader';
import useAttachmentsDrawer from './hooks/useAttachmentsDrawer';
import useDate from '../../../utils/hooks/useDate';

const drawerWidth = 400;

interface themeTypes {
    theme: any;
}

const DrawerHeader = styled('div')(({ theme }: themeTypes) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(10, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

interface AttachmentsDrawerProps {
    open: boolean;
    setOpen: (_: boolean) => void;
    /** The uuid of the entity you're getting attachment data for */
    entityUuid: string;
    entityType: string;
    shouldRefresh?: boolean;
    setShouldRefresh?: (_: boolean) => void;
}

export default function AttachmentsDrawer({
    open,
    setOpen,
    entityUuid,
    shouldRefresh,
    setShouldRefresh,
    entityType,
}: AttachmentsDrawerProps) {
    const {
        selectedAttachment,
        openAttachmentItem,
        setOpenAttachmentItem,
        handleDrawerClose,
        uploadAttachments,
        attachmentsList,
        handleOpen,
        theme,
        entityTypes,
        sources,
    } = useAttachmentsDrawer({
        entityUuid,
        entityType,
        setOpen,
        shouldRefresh,
        setShouldRefresh,
    });

    const { dateFormatter } = useDate();
    return (
        <Box sx={{ display: 'flex' }}>
            <AttachmentItemModal
                item={selectedAttachment}
                open={openAttachmentItem}
                onClose={() => setOpenAttachmentItem(false)}
            />
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                    },
                }}
                variant="temporary"
                anchor="right"
                open={open}>
                <DrawerHeader>
                    <Typography variant="h6">Attachments</Typography>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? (
                            <ChevronLeftIcon />
                        ) : (
                            <ChevronRightIcon />
                        )}
                    </IconButton>
                    {entityTypes && sources && (
                        <FileDropUploader onDrop={uploadAttachments} />
                    )}
                </DrawerHeader>
                <Divider />
                <Timeline
                    position="left"
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}>
                    {attachmentsList?.length > 0 ? (
                        attachmentsList?.map(
                            (attachment: any, index: number) => (
                                <Button
                                    style={{ textTransform: 'none' }}
                                    variant="text"
                                    size="small"
                                    key={attachment.id}
                                    onClick={(e) => handleOpen(attachment)}>
                                    <TimelineItem sx={{ width: '100%' }}>
                                        <TimelineOppositeContent
                                            sx={{ py: '18px', px: 2 }}>
                                            <Typography>
                                                {dateFormatter(
                                                    attachment.createdAt
                                                )}
                                                <br />
                                                {`${attachment?.createdByUser.firstName} ${attachment?.createdByUser.lastName}`}
                                            </Typography>
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineDot>
                                                <AttachmentIcon />
                                            </TimelineDot>
                                            {index !==
                                                attachmentsList.length - 1 && (
                                                <TimelineConnector />
                                            )}
                                        </TimelineSeparator>
                                        <TimelineContent
                                            sx={{ py: '18px', px: 2 }}>
                                            <Typography>
                                                {attachment?.name}
                                            </Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                </Button>
                            )
                        )
                    ) : (
                        <>No Attachments Available...</>
                    )}
                </Timeline>
            </Drawer>
        </Box>
    );
}
