// #region Imports
import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, Typography } from '@mui/material';
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from '@mui/lab';
import WorkIcon from '@mui/icons-material/Work';
import useDate from '../../../utils/hooks/useDate';
import { useSearchJobRunsMutation } from '../../../services/jobs/jobs.service';
import { useGetUsersQuery } from '../../../services/organizations/organizations.service';
import SelectInput from '../../form/formInputs/SelectInput/SelectInput';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import _ from 'lodash';
import { SelectionOption } from '../../../types/Shared.types';
import moment from 'moment/moment';
import { openJobItemModal } from '../../../store/uiElements';
import { useDispatch } from 'react-redux';
// #endregion Imports

// #region Styles
const drawerWidth = 400;

interface themeTypes {
    theme: any;
}

const DrawerHeader = styled('div')(({ theme }: themeTypes) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(10, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

// #endregion Styles

interface JobsDrawerProps {
    open: boolean;
    setOpen: (_: boolean) => void;
    /** The uuid of the entity you're getting attachment data for */
    entityUuid: string;
    shouldRefresh?: boolean;
    setShouldRefresh?: (_: boolean) => void;
}

export default function JobsDrawer({
    open,
    setOpen,
    entityUuid,
}: JobsDrawerProps) {
    // #region Hooks
    const dispatch = useDispatch();
    const { dateFormatter } = useDate();
    const theme = useTheme();
    const [search] = useSearchJobRunsMutation();
    const [jobList, setJobList] = useState(null);
    const [originalJobList, setOriginalJobList] = useState(null);
    const [selectedJobType, setSelectedJobType] = useState(null);
    const { data: userList, isLoading: isLoadingUserOptions } =
        useGetUsersQuery();
    // #endregion Hooks

    // #region effects

    useEffect(() => {
        handleSearch();
    }, [entityUuid, open]);

    useEffect(() => {
        handleFilter();
    }, [selectedJobType]);

    // #endregion effects

    // #region Functions

    /**
     * Searches for job runs based on the entityUuid
     * @returns void
     */
    const handleSearch = async () => {
        if (entityUuid) {
            const args = {
                postBody: {
                    entityUuids: [entityUuid],
                    entityTypeCodes: ['RDF'],
                    authenticateByEntity: true,
                },
            };

            try {
                const response = await search(args).unwrap();
                const responseClone = [...response];
                responseClone.sort((a: any, b: any) => {
                    return moment(b.startTime).diff(moment(a.startTime));
                });
                setJobList(responseClone);
                setOriginalJobList(responseClone);
                return responseClone;
            } catch (error) {
                setJobList([]);
                setOriginalJobList([]);
                return [];
            }
        }
    };

    /**
     * Filters the job list based on the selected job type
     * @returns void
     */
    const handleFilter = async () => {
        if (!isNilOrEmpty(selectedJobType)) {
            const filtered = originalJobList?.filter(
                (job: any) =>
                    job?.jobDefinitionConfiguration?.jobDefinition?.name ===
                    selectedJobType
            );

            setJobList(filtered);
            return;
        }

        setJobList(originalJobList);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleOpen = (item: any) => {
        dispatch(
            openJobItemModal({
                isOpen: true,
                jobRunId: item.id,
            })
        );
    };

    // #endregion Functions

    // #region JSX
    return (
        <Box sx={{ display: 'flex' }}>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                    },
                }}
                variant="temporary"
                anchor="right"
                open={open}>
                <DrawerHeader style={{ height: '50px' }}>
                    <Typography variant="h6">Job Logs</Typography>

                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? (
                            <ChevronLeftIcon />
                        ) : (
                            <ChevronRightIcon />
                        )}
                    </IconButton>
                </DrawerHeader>
                <div style={{ padding: '10px' }}>
                    <SelectInput
                        fullWidth
                        SelectProps={{
                            showClearButton: true,
                        }}
                        label={'Job Type'}
                        value={selectedJobType}
                        onChange={(e: any) =>
                            setSelectedJobType(e.target.value)
                        }
                        options={
                            _.uniqBy(
                                originalJobList?.map((job: any) => {
                                    return {
                                        label: job?.jobDefinitionConfiguration
                                            ?.jobDefinition?.name,
                                        value: job?.jobDefinitionConfiguration
                                            ?.jobDefinition?.name,
                                    };
                                }) ?? [],
                                'value'
                            ) as unknown as SelectionOption[]
                        }
                    />
                </div>
                <br />
                <Divider />
                <Timeline
                    position="left"
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}>
                    {jobList?.length > 0 ? (
                        jobList?.map((job: any, index: number) => (
                            <Button
                                style={{ textTransform: 'none' }}
                                variant="text"
                                size="small"
                                key={job.id}
                                onClick={(e) => handleOpen(job)}>
                                <TimelineItem sx={{ width: '100%' }}>
                                    <TimelineOppositeContent
                                        sx={{ py: '18px', px: 2 }}>
                                        <Typography>
                                            {dateFormatter(job.createdAt)}
                                            <br />
                                            {`${job?.startedByUser?.firstName} ${job?.startedByUser?.lastName}`}
                                        </Typography>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot>
                                            <WorkIcon />
                                        </TimelineDot>
                                        {index !== jobList.length - 1 && (
                                            <TimelineConnector />
                                        )}
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: '18px', px: 2 }}>
                                        <Typography>
                                            {
                                                job?.jobDefinitionConfiguration
                                                    ?.jobDefinition?.name
                                            }
                                        </Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            </Button>
                        ))
                    ) : (
                        <>No Jobs Ran...</>
                    )}
                </Timeline>
            </Drawer>
        </Box>
    );
    // #endregion JSX
}
