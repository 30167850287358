import React, { useEffect, useState } from 'react';
import BaseTaskButton from './BaseTaskButton';
import { useListTaskTypesQuery } from '../../../services/task-manager/task-manager.service';
import { useListEntityTypesQuery } from '../../../services/system/entityType.service';
import { skipToken } from '@reduxjs/toolkit/query';

const TaskButtonGroup = ({ entityType }: any) => {
    const { data: taskTypes } = useListTaskTypesQuery(
        entityType ? { entityTypeId: entityType?.id } : skipToken
    );

    if (!taskTypes) {
        return <></>;
    }

    return (
        <>
            {taskTypes.map((taskType: any) => (
                <BaseTaskButton key={taskType.id} taskType={taskType} />
            ))}
        </>
    );
};

export default TaskButtonGroup;
