export const CAS_HAZARD_PHRASES_FIELDS = {
    phraseId: 'Phrase ID',
    phraseText: 'Phrase Text',
    hazardType: 'Hazard Type',
    hazardLang: 'Language',
};

export const CAS_PRECAUTIONARY_PHRASES_FIELDS = {
    phraseId: 'Code',
    phraseText: 'Phrase Text',
    statementType: 'Statement Type',
    hazardLang: 'Language',
};

export const CAS_TOXICITY_FIELDS = {
    route: 'Route',
    testType: 'Test Type',
    testDescription: 'Test Description',
    speciesType: 'Species',
    speciesDescription: 'Species Description',
    effectiveDose: 'Effective Dose',
    exposureTime: 'Exposure Time',
    notes: 'Notes',
};

export const CAS_ECO_TOXICITY_FIELDS = {
    toxicTestId: 'Test Type ID',
    toxicSpeciesId: 'Species ID',
    effectiveDose: 'Effective Dose',
    exposureTime: 'Exposure Time',
    notes: 'Notes',
    source: 'Source',
    method: 'Method',
    speciesClass: 'Class of Species ID',
};

export const CAS_EXPOSURE_FIELDS = {
    exposureAgencyCode: 'Exposure Agency Code',
    exposureDescription: 'Description',
    exposureValue: 'Exposure Value',
    exposureForm: 'Exposure Form',
    notes: 'Notes',
};

export const CAS_PROPERTY_FIELDS = {
    propertyCode: 'Property Code',
    propertyName: 'Property Name',
    propertyValue: 'Value',
    propertyUnit: 'UOM',
};

export const CAS_REGULATION_FIELDS = {
    code: 'Code',
    title: 'Title',
    onList: 'On List',
    importManufactureLimit: 'Import/Manufacture Limit',
    threshold: 'Threshold',
    notes: 'Notes',
    regulationBody: 'Regulation Body',
    regulationSection: 'Section',
    stateCode: 'State/Province',
    countryCode: 'Country',
};

export const CAS_HAZARD_CLASSIFICATION_FIELDS = {
    code: 'Hazard Code',
    locale: 'Locale Group ID',
    localeValue: 'Locale Group Value',
};
