import React, { useContext, useEffect } from 'react';
import { Grid, Dialog, DialogContent, DialogTitle } from '@mui/material';
import LoaderButton from '../../LoaderButton';
import TextInput from '../../form/formInputs/TextInput/TextInput';
import MultiSelectInput from '../../form/formInputs/MultiSelectInput/MutliSelectInput';
import {
    useGetGroupsForDropdownQuery,
    useGetUsersForDropdownQuery,
} from '../../../services/organizations/organizations.service';
import DateInput from '../../form/formInputs/DateInput/DateInput';
import {
    useAssignTaskMutation,
    useCreateTaskMutation,
    useGetTaskQuery,
    useListTaskTypesQuery,
    useMarkCompleteMutation,
    useUpdateTaskMutation,
} from '../../../services/task-manager/task-manager.service';
import { useListEntityTypesQuery } from '../../../services/system/entityType.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import GlobalEntityDataContext from '../../../contexts/globalEntityData.context';
import { Link, useNavigate } from 'react-router-dom';
import CheckBoxInput from '../../form/formInputs/CheckBoxInput/CheckBoxInput';
import TranslatableText from '../../i18n/TranslatableText';
import { TaskManagerDefs } from '../../../constants/i18n/translations/termDefinitions/platform';
import useTranslation from '../../i18n/hooks/useTranslation';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';
import SelectInput from '../../form/formInputs/SelectInput/SelectInput';

const TaskModal = ({
    open,
    setOpen,
    taskFields,
    setTaskFields,
    selectedTask,
    setSelectedTask,
    isPersonal,
    setIsPersonal,
    showAssignee,
}: any) => {
    let navigate = useNavigate();
    const { data: users } = useGetUsersForDropdownQuery();
    const { data: groups } = useGetGroupsForDropdownQuery();
    const user = useSelector((state: RootState) => state.user);
    const { data: taskTypes } = useListTaskTypesQuery(null);
    const { data: entityTypes } = useListEntityTypesQuery();
    const [createTask] = useCreateTaskMutation();
    const [assignTask] = useAssignTaskMutation();
    const [updateTask] = useUpdateTaskMutation();
    const [markComplete] = useMarkCompleteMutation();

    const { entityData } = useContext(GlobalEntityDataContext);
    const { termSet } = useTranslation(PLATFORM_DEFS.TASK_MANAGER);

    const { data: task } = useGetTaskQuery(
        selectedTask?.id ? selectedTask?.id : skipToken
    );

    useEffect(() => {
        handleSetTaskFields();
    }, [task, open]);

    const handleSetTaskFields = () => {
        if (task && selectedTask) {
            setTaskFields({
                ...task,
                taskUsers: task?.taskUsers?.map((user: any) => {
                    return {
                        label: `${user?.user?.firstName} ${user?.user?.lastName}`,
                        value: user?.user?.id,
                    };
                }),
                taskGroups:
                    task?.taskGroups?.map((group: any) => {
                        return {
                            label: group?.group?.name,
                            value: group?.group?.id,
                        };
                    }) || [],
            });
        } else {
            setTaskFields({
                title: '',
                notes: '',
                dueAt: null,
                taskTypeId: '',
                entityTypeId: entityData?.entityType?.id || '',
                entityUuid: entityData?.entityUuid || null,
                entityPath: entityData?.fullPath || null,
                entityPageTitle: entityData?.pageTitle || null,
                taskUsers: [],
                taskGroups: [],
            });
        }
    };

    useEffect(() => {
        if (entityData?.entityType && !taskFields?.createdByUserId) {
            setTaskFields((prev: any) => ({
                ...prev,
                entityTypeId: entityData?.entityType?.id,
                entityUuid: entityData?.entityUuid || null,
            }));
        }
    }, [entityData]);

    const handleClose = () => {
        setOpen(false);
        setSelectedTask(null);
        setIsPersonal(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTaskFields((prev: any) => ({
            ...prev,
            [event.target.id]: event.target.value,
        }));
    };

    const handleAddTask = async () => {
        const createArgs = {
            title: taskFields.title,
            notes: taskFields.notes,
            dueAt: taskFields.dueAt,
            taskTypeId: taskFields.taskTypeId || null,
            entityTypeId: taskFields.entityTypeId || null,
            entityPath: taskFields?.entityPath || null,
            entityPageTitle: taskFields?.entityPageTitle || null,
            entityUuid: taskFields.entityUuid || null,
            createdByUserId: taskFields.createdByUserId || null,
        };

        const response = await createTask(createArgs).unwrap();
        const assignedUsers = isPersonal
            ? [{ id: user?.userId }]
            : taskFields.taskUsers?.map((user: any) => {
                  return { id: user?.value || user?.id };
              });

        const assignedGroups = taskFields.taskGroups?.map((group: any) => {
            return { id: group?.value || group?.id };
        });

        assignTask({
            id: response?.id,
            postBody: {
                users: assignedUsers,
                groups: isPersonal ? null : assignedGroups,
            },
        });

        setOpen(false);
    };

    const handleUpdateTask = async () => {
        const updateArgs = {
            id: taskFields.id,
            postBody: {
                title: taskFields.title,
                notes: taskFields.notes,
                dueAt: taskFields.dueAt,
                taskTypeId: taskFields.taskTypeId || null,
                entityTypeId: taskFields.entityTypeId || null,
                entityUuid: taskFields.entityUuid || null,
            },
        };

        const assignedGroups = taskFields.taskGroups?.map((group: any) => {
            return { id: group?.value || group?.id };
        });

        const response = await updateTask(updateArgs).unwrap();

        assignTask({
            id: response?.id,
            postBody: {
                users: taskFields.taskUsers?.map((user: any) => {
                    return { id: user?.value || user?.id };
                }),
                groups: assignedGroups,
            },
        });

        setOpen(false);
    };

    const getUserDisplayNameById = (id: string) => {
        return users?.find((user: any) => user.value === id)?.label;
    };

    const handleEntityClick = () => {
        navigate(taskFields.entityPath);
    };

    const handleCreateTaskTitle = () => {
        if (taskFields?.createdByUserId) {
            return (
                <>
                    Assigned By:{' '}
                    {getUserDisplayNameById(taskFields?.createdByUserId)}
                </>
            );
        } else {
            return 'Add New Task';
        }
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{handleCreateTaskTitle()}</DialogTitle>
            <DialogContent>
                <div style={{ padding: '10px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {showAssignee && (
                                <SelectInput
                                    fullWidth
                                    label={'Assigned By'}
                                    id="createdByUserId"
                                    value={taskFields.createdByUserId}
                                    options={users}
                                    onChange={(e: any) => {
                                        setTaskFields((prev: any) => ({
                                            ...prev,
                                            createdByUserId: e.target.value,
                                        }));
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                fullWidth
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={TaskManagerDefs.New_Task_Title}
                                    />
                                }
                                id="title"
                                value={taskFields.title}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextInput
                                fullWidth
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={TaskManagerDefs.New_Task_Notes}
                                    />
                                }
                                id="notes"
                                value={taskFields.notes}
                                onChange={handleChange}
                                multiline
                                rows={4}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DateInput
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            TaskManagerDefs.New_Task_Due_At
                                        }
                                    />
                                }
                                id="dueAt"
                                value={taskFields.dueAt}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectInput
                                SelectProps={{
                                    showClearButton: true,
                                }}
                                fullWidth
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            TaskManagerDefs.New_Task_Entity_Type
                                        }
                                    />
                                }
                                id="entityTypeId"
                                options={entityTypes?.map((type: any) => ({
                                    label: type.name,
                                    value: type.id,
                                }))}
                                value={taskFields.entityTypeId}
                                onChange={(e: any) => {
                                    setTaskFields((prev: any) => ({
                                        ...prev,
                                        entityTypeId: e.target.value,
                                    }));
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CheckBoxInput
                                id={'markComplete'}
                                label={'Mark Complete'}
                                checked={taskFields?.completedAt ? true : false}
                                onChange={() => {
                                    if (taskFields?.completedAt) {
                                        updateTask({
                                            id: taskFields.id,
                                            postBody: {
                                                completedAt: null,
                                                completedByUserId: null,
                                            },
                                        });
                                    } else {
                                        markComplete({
                                            id: taskFields.id,
                                            postBody: {
                                                completedAt: new Date(),
                                                completedByUserId: user?.userId,
                                            },
                                        });
                                        setOpen(false);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            {!isPersonal && (
                                <MultiSelectInput
                                    fullWidth
                                    label={
                                        (
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    TaskManagerDefs.New_Task_Assigned_To
                                                }
                                            />
                                        ) as unknown as string
                                    }
                                    id="taskUsers"
                                    value={taskFields.taskUsers}
                                    options={users}
                                    onChange={handleChange}
                                />
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            {!isPersonal && (
                                <MultiSelectInput
                                    fullWidth
                                    label={
                                        (
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    TaskManagerDefs.New_Task_Assigned_Groups
                                                }
                                            />
                                        ) as unknown as string
                                    }
                                    id="taskGroups"
                                    value={taskFields.taskGroups}
                                    options={groups}
                                    onChange={handleChange}
                                />
                            )}
                        </Grid>
                        {!taskFields?.createdByUserId &&
                            entityData?.entityType && (
                                <Grid item xs={6}>
                                    <CheckBoxInput
                                        id={'relatedToEntity'}
                                        label={`Related to ${entityData?.entityType?.name}?`}
                                        checked={
                                            taskFields?.entityPath
                                                ? true
                                                : false
                                        }
                                        onChange={() => {
                                            if (taskFields?.entityPath) {
                                                setTaskFields((prev: any) => ({
                                                    ...prev,
                                                    entityPath: null,
                                                    entityPageTitle: null,
                                                }));
                                            } else {
                                                setTaskFields((prev: any) => ({
                                                    ...prev,
                                                    entityPath:
                                                        entityData?.fullPath,
                                                    entityPageTitle:
                                                        entityData?.pageTitle,
                                                }));
                                            }
                                        }}
                                    />
                                </Grid>
                            )}
                        <Grid item xs={12}>
                            {!taskFields?.id ? (
                                <LoaderButton
                                    fullWidth
                                    onClick={handleAddTask}
                                    color="primary"
                                    variant="contained">
                                    +{' '}
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            TaskManagerDefs.Add_Task_Button
                                        }
                                    />
                                </LoaderButton>
                            ) : (
                                <LoaderButton
                                    fullWidth
                                    onClick={handleUpdateTask}
                                    color="primary"
                                    variant="contained">
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            TaskManagerDefs.Update_Task_Button
                                        }
                                    />
                                </LoaderButton>
                            )}
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default TaskModal;
