import React from 'react';
import { Grid, Dialog, DialogContent, DialogTitle } from '@mui/material';
import LoaderButton from '../../LoaderButton';
import TextInput from '../../form/formInputs/TextInput/TextInput';
import SelectDropdown from '../../form/selectDropdown/SelectDropdown';
import MultiSelectInput from '../../form/formInputs/MultiSelectInput/MutliSelectInput';
import DateInput from '../../form/formInputs/DateInput/DateInput';
import { useNavigate } from 'react-router-dom';
import useEntityTaskModal from './hooks/useEntityTaskModal';
import TranslatableText from '../../i18n/TranslatableText';
import { TaskManagerDefs } from '../../../constants/i18n/translations/termDefinitions/platform';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';
import useTranslation from '../../i18n/hooks/useTranslation';

const EntityTaskModal = ({ open, setOpen, taskType }: any) => {
    let navigate = useNavigate();
    const {
        taskFields,
        setTaskFields,
        entityTypes,
        users,
        groups,
        handleChange,
        handleAddTask,
        handleClose,
        handleCreateTaskTitle,
    } = useEntityTaskModal({ open, setOpen, taskType });

    const { termSet } = useTranslation(PLATFORM_DEFS.TASK_MANAGER);

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{handleCreateTaskTitle()}</DialogTitle>
            <DialogContent>
                <div style={{ padding: '10px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextInput
                                fullWidth
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={TaskManagerDefs.New_Task_Title}
                                    />
                                }
                                id="title"
                                value={taskFields.title}
                                onChange={handleChange}
                                formMethods={[] as any}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                fullWidth
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={TaskManagerDefs.New_Task_Notes}
                                    />
                                }
                                id="notes"
                                value={taskFields.notes}
                                onChange={handleChange}
                                formMethods={[] as any}
                                multiline
                                rows={4}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DateInput
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            TaskManagerDefs.New_Task_Due_At
                                        }
                                    />
                                }
                                id="dueAt"
                                value={taskFields.dueAt}
                                onChange={handleChange}
                                formMethods={[] as any}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectDropdown
                                fullWidth
                                size={'large'}
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            TaskManagerDefs.New_Task_Entity_Type
                                        }
                                    />
                                }
                                id="entityTypeId"
                                options={entityTypes?.map((type: any) => ({
                                    label: type.name,
                                    value: type.id,
                                }))}
                                value={taskFields.entityTypeId}
                                onChange={(value: any) => {
                                    setTaskFields((prev: any) => ({
                                        ...prev,
                                        entityTypeId: value,
                                    }));
                                }}
                                isDisabled={true}
                                formMethods={[] as any}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MultiSelectInput
                                fullWidth
                                label={
                                    (
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={
                                                TaskManagerDefs.New_Task_Assigned_To
                                            }
                                        />
                                    ) as unknown as string
                                }
                                id="taskUsers"
                                value={taskFields.taskUsers}
                                options={users}
                                onChange={handleChange}
                                //@ts-ignore
                                formMethods={[] as any}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MultiSelectInput
                                fullWidth
                                label={
                                    (
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={
                                                TaskManagerDefs.New_Task_Assigned_Groups
                                            }
                                        />
                                    ) as unknown as string
                                }
                                id="taskGroups"
                                value={taskFields.taskGroups}
                                options={groups}
                                onChange={handleChange}
                                //@ts-ignore
                                formMethods={[] as any}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LoaderButton
                                fullWidth
                                onClick={handleAddTask}
                                color="primary"
                                variant="contained">
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={TaskManagerDefs.Add_Task_Button}
                                />
                            </LoaderButton>
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default EntityTaskModal;
