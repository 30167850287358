import Login from '../containers/login/Login';
import Signup from '../containers/login/Signup';
import NewPasswordRequired from '../containers/login/NewPasswordRequired';
import ResetPassword from '../containers/login/resetPassword/ResetPassword';
import ResetPasswordConfirmation from '../containers/login/resetPassword/ResetPasswordConfirmation';
import ResetPasswordSuccess from '../containers/login/resetPassword/ResetPasswordSuccess';
import { RouteProps } from './routesConfig';

const LoginRoutes: RouteProps[] = [
    {
        path: '/login',
        component: Login,
    },
    {
        path: '/login/updated',
        component: Login,
    },
    {
        path: '/signup',
        component: Signup,
    },
    {
        path: '/signupConfirmation',
        component: Signup,
    },
    {
        path: '/login/newPasswordRequired',
        component: NewPasswordRequired,
    },
    {
        path: '/login/reset',
        component: ResetPassword,
    },
    {
        path: '/login/resetConfirmation',
        component: ResetPasswordConfirmation,
    },
    {
        path: '/login/resetSuccess',
        component: ResetPasswordSuccess,
    },
];

export default LoginRoutes;
