import * as Sentry from '@sentry/react';
import { AlertColor } from '@mui/material';
import { openSnackbarBasicWithMessage } from '../store/uiElements';
import { MutationError } from '../types/Shared.types';
import React from 'react';
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';

const errorStringIgnore = [
    'No current user',
    'The user is not authenticated',
    'data-sentry-element',
];

export function initSentry() {
    const isSentryEnabled = import.meta.env.VITE_ENABLE_SENTRY === 'true';
    const stage = import.meta.env.VITE_NAME;
    const enabledStages = ['prod', 'staging', 'dev'];

    // Enable sentry if current stage is an enabled stage
    if (!enabledStages.includes(stage) || !isSentryEnabled) {
        return;
    }

    console.info('Enabling and Configuring Sentry');

    Sentry.init({
        dsn: 'https://68619c3660014b4d8e73e3ab10ecc7c9@o410909.ingest.sentry.io/5285423',
        integrations: [
            Sentry.browserProfilingIntegration(),
            Sentry.captureConsoleIntegration({
                levels: ['error', 'warn'],
            }),
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
            Sentry.sessionTimingIntegration(),
        ],
        environment: import.meta.env.VITE_NAME,
        initialScope: {
            user: {
                id: null,
                email: null,
            },
        },
        tracesSampleRate: 1.0,
        autoSessionTracking: true,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 0.1,
        profilesSampleRate: 1.0,
        ignoreErrors: errorStringIgnore,
        beforeSend: function (event, hint) {
            if (event.message?.toLowerCase().includes('ag-grid')) {
                event.level = 'warning';
                event.fingerprint = ['ag-grid-warning'];
            }

            return event;
        },
    });
}

export function logError(error: Error, errorInfo: any = null) {
    Sentry.withScope((scope) => {
        errorInfo && scope.setExtras(errorInfo);
        Sentry.captureException(error);
    });
}

export function onError(error: any, doAlertError = true) {
    let errorInfo: any = {};
    let message = error.toString();

    // Auth errors
    if (!(error instanceof Error) && error.message) {
        errorInfo = error;
        message = error.message;
        error = new Error(message);
        // API errors
    } else if (error.config && error.config.url) {
        errorInfo.url = error.config.url;
    }

    logError(error, errorInfo);

    if (doAlertError) {
        alert(message);
    }
}

export function showNotificationError(
    message: string,
    severity: AlertColor,
    dispatch: any
) {
    dispatch(
        openSnackbarBasicWithMessage({ message: message, severity: severity })
    );
    onError(message, false);
}

export function handleError(error: MutationError, dispatch: any) {
    const severity = 'error';
    if (error.status === 400) {
        let errorMessage = '';
        if (Array.isArray(error.data) && Array.isArray(error.data[0])) {
            for (const errorElement of error.data) {
                errorMessage = errorElement
                    .map((error: any) => {
                        return error.message;
                    })
                    .join('\r\n');
            }
        } else {
            errorMessage = error.data
                .map((error: any) => {
                    return error.message;
                })
                .join('\r\n');
        }
        showNotificationError(errorMessage, severity, dispatch);
    } else {
        showNotificationError(
            `An unexpected error occurred: ${error.data?.toString()}`,
            severity,
            dispatch
        );
    }
}
