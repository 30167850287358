import {
    configureStore,
    combineReducers,
    Middleware,
    Action,
} from '@reduxjs/toolkit';
import user from './user';
import scheduler from './scheduler';
import thunk from 'redux-thunk';
import warehouses from './warehouses';
import batchTickets from './batchTickets';
import workcenters from './workcenters';
import equipment from './equipment';
import pricingAnalysisTool from './pricingAnalysisTool';
import grids from './grids';
import uiElements from './uiElements';
import accounting from './accounting';
import fixedAssets from './fixedAssets';
import suppliers from './suppliers';
import formulation from './formulation/formulation';
import sds from './formulation/sds';

import { datacorAppApi, datacorPublicAppApi } from '../services/datacorAppApi';
import { accountingAppApi } from '../services/accountingAppApi';
import { formulationAppApi } from '../services/formulationAppApi';
import { supplyChainAppApi } from '../services/supplyChainAppApi';
import { workflowAppApi } from '../services/workflowAppApi';

/* istanbul ignore file */
const reducer = combineReducers({
    [datacorPublicAppApi.reducerPath]: datacorPublicAppApi.reducer,
    [datacorAppApi.reducerPath]: datacorAppApi.reducer,
    [accountingAppApi.reducerPath]: accountingAppApi.reducer,
    [formulationAppApi.reducerPath]: formulationAppApi.reducer,
    [supplyChainAppApi.reducerPath]: supplyChainAppApi.reducer,
    [workflowAppApi.reducerPath]: workflowAppApi.reducer,
    user,
    scheduler,
    warehouses,
    batchTickets,
    workcenters,
    equipment,
    pricingAnalysisTool,
    grids,
    formulation,
    sds,
    uiElements,
    accounting,
    fixedAssets,
    suppliers,
});

const rootReducer = (state: any, action: Action) => {
    if (action.type === 'user/logoutSuccess') {
        state = undefined;
    }
    return reducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
            .concat(
                datacorPublicAppApi.middleware as Middleware<
                    (action: Action<'specialAction'>) => number,
                    RootState
                >,
                datacorAppApi.middleware as Middleware<
                    (action: Action<'specialAction'>) => number,
                    RootState
                >,
                formulationAppApi.middleware as Middleware<
                    (action: Action<'specialAction'>) => number,
                    RootState
                >,
                accountingAppApi.middleware as Middleware<
                    (action: Action<'specialAction'>) => number,
                    RootState
                >,
                supplyChainAppApi.middleware as Middleware<
                    (action: Action<'specialAction'>) => number,
                    RootState
                >,
                workflowAppApi.middleware as Middleware<
                    (action: Action<'specialAction'>) => number,
                    RootState
                >
            )
            .prepend(thunk),
});

export type AppDispatch = typeof store.dispatch;

export default store;
