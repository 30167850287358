import { createContext } from 'react';

const AttachmentSummaryContext = createContext({
    isAttachmentSummaryOpen: false,
    setIsAttachmentSummaryOpen: (values: any) => {},
    rowData: null,
    setRowData: (values: any) => {},
    anchorEl: null,
    setAnchorEl: (values: any) => {},
});

export default AttachmentSummaryContext;
