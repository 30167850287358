export const numericGridCell = () => {
    return {
        filter: 'agTextColumnFilter',
        comparator: (
            valueA: any,
            valueB: any,
            nodeA: any,
            nodeB: any,
            isInverted: boolean
        ) => valueA - valueB,
    };
};
