import LoaderButton from '../../components/LoaderButton';
import React, { useState } from 'react';
import TaskModal from '../../components/administration/task-manager/TaskModal';

const TaskManagerHelper = (props: any) => {
    const [openTasksModal, setOpenTasksModal] = React.useState(false);

    const [taskFields, setTaskFields] = useState<any>({
        title: '',
        notes: '',
        dueAt: '',
        taskTypeId: '',
        entityTypeId: '',
        entityUuid: '',
        taskUsers: [],
        taskGroups: [],
        createdByUserId: null,
    });

    return (
        <>
            <h3>Task Manager Helper</h3>
            <TaskModal
                open={openTasksModal}
                setOpen={setOpenTasksModal}
                taskFields={taskFields}
                setTaskFields={setTaskFields}
                showAssignee={true}
            />
            <LoaderButton
                variant={'contained'}
                onClick={() => setOpenTasksModal(true)}>
                + New Task
            </LoaderButton>
        </>
    );
};

export default TaskManagerHelper;
