import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import {
    useCreateEntitySettingMutation,
    useGetUserQuery,
    useListGridLayoutsQuery,
    useUpdateCurrentGridLayoutMutation,
} from '../../../services/organizations/organizations.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { showNotificationError } from '../../../libs/errorLib';

const useEntitySettingsDrawer = ({
    settingValue,
    settingKey,
    type,
    setOpen,
}: any) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const [settingData, setSettingData] = useState(null);

    const { data: entitySettingsList, isLoading } = useListGridLayoutsQuery(
        settingData ? settingData : skipToken
    );
    const [createEntitySetting] = useCreateEntitySettingMutation();
    const [updateGridLayout] = useUpdateCurrentGridLayoutMutation();

    const { data: userData, isLoading: isLoadingUser } = useGetUserQuery(
        user?.userId ? { userId: user?.userId?.toString() } : skipToken
    );
    const [settingName, setSettingName] = useState('');
    const [isPublic, setIsPublic] = useState(false);
    const [currentSetting, setCurrentSetting] = useState(null);
    const [gridSettings, setGridSettings] = useState(null);

    const [openEditModal, setOpenEditModal] = useState(null);

    // on load set the active setting to the active one in the db for this entity
    useEffect(() => {
        if (!isNilOrEmpty(entitySettingsList)) {
            const current = entitySettingsList.find(
                (setting: any) => setting.current
            );
            setCurrentSetting(current ? current : null);
        }
    }, [entitySettingsList]);

    useEffect(() => {
        if (!isNilOrEmpty(settingValue.current!.columnApi?.columnModel)) {
            setGridSettings({ gridColumnApi: settingValue.current!.columnApi });
        }

        if (!isNilOrEmpty(settingValue.current!.api?.columnModel)) {
            setGridSettings((prev: any) => ({
                ...prev,
                gridApi: settingValue.current!.api,
            }));
        }
    }, [settingValue.current?.api, settingValue.current?.columnApi]);

    // we need to set the column/filters based on our active
    useEffect(() => {
        if (
            currentSetting &&
            !isNilOrEmpty(gridSettings?.gridApi) &&
            !isNilOrEmpty(gridSettings?.gridColumnApi)
        ) {
            if (type.toUpperCase() === 'GRID') {
                gridSettings.gridColumnApi.applyColumnState({
                    state: JSON.parse(currentSetting.value).columnState,
                    applyOrder: true,
                });

                gridSettings.gridApi.setFilterModel(
                    JSON.parse(currentSetting.value).filterModel
                );
            }
        }
    }, [currentSetting, gridSettings, settingValue.current]);

    // get the uuid for the user from the userdata when we have it
    useEffect(() => {
        if (userData) {
            setSettingData((prev: any) => ({
                ...prev,
                uuid: userData?.uuid,
                settingKey: settingKey,
                publicUuid: user?.businessEntity?.uuid,
            }));
        }
    }, [userData]);

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleSelectionChange = (selectedOption: any) => {
        const current = entitySettingsList?.find(
            (setting: any) => setting.id === selectedOption.target.value
        );
        // if were using a grid type we need to set the columns
        if (type.toUpperCase() === 'GRID') {
            gridSettings?.gridColumnApi.applyColumnState({
                state: JSON.parse(current.value).columnState,
                applyOrder: true,
            });

            gridSettings?.gridApi.setFilterModel(
                JSON.parse(current.value).filterModel
            );
        }

        setCurrentSetting(current);
        // making the updated selection the active
        const args = {
            id: selectedOption.target.value,
            postBody: {
                current: true,
                uuid: userData?.uuid,
            },
        };
        updateGridLayout(args);
    };

    const handleCreateEntitySetting = () => {
        let value;
        if (type.toUpperCase() === 'GRID') {
            const columnState = gridSettings?.gridColumnApi?.getColumnState();
            const filterModel = gridSettings?.gridApi?.getFilterModel();

            value = { columnState: columnState, filterModel: filterModel };
        } else {
            value = settingValue;
        }
        const args = {
            postBody: {
                //if the setting is public we set the uuid to the beID
                uuid: isPublic ? user?.businessEntity?.uuid : settingData.uuid,
                owner: settingData.uuid,
                settingKey: settingData.settingKey,
                subKey: !isNilOrEmpty(settingName) ? settingName : null,
                value: JSON.stringify(value),
                current: false,
            },
        };

        if (isNilOrEmpty(settingName)) {
            showNotificationError('Name cannot be empty.', 'error', dispatch);
        } else {
            createEntitySetting(args);
            setSettingName('');
            setIsPublic(false);
        }
    };

    const handleEdit = (event: any, optionId: any) => {
        const setting = entitySettingsList.find(
            (setting: any) => setting.id === optionId
        );
        setOpenEditModal(setting);
    };

    return {
        openEditModal,
        setOpenEditModal,
        handleDrawerClose,
        theme,
        settingName,
        setSettingName,
        isPublic,
        setIsPublic,
        handleCreateEntitySetting,
        user,
        handleEdit,
        currentSetting,
        entitySettingsList,
        handleSelectionChange,
    };
};

export default useEntitySettingsDrawer;
