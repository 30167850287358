import React from 'react';
import WarehouseSelect from '../../components/WarehouseSelect';
import './HeaderControls.css';
import {
    ToggleButton,
    ToggleButtonGroup,
    Grid,
    TextField,
} from '@mui/material';
import {
    FaRegCalendarMinus,
    FaRegCalendarPlus,
    FaSearchMinus,
    FaSearchPlus,
} from 'react-icons/fa';
import { BiRefresh } from 'react-icons/bi';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import useHeaderControls from './hooks/useHeaderControls';
import moment from 'moment';

interface HeaderControlsProps {
    onDateChange: (param: any) => void;
    onViewChange: (param: any) => void;
    onZoomIn: () => void;
    onZoomOut: () => void;
    onRefresh: () => void;
}

const HeaderControls = ({
    onDateChange,
    onViewChange,
    onZoomIn = null,
    onZoomOut = null,
    onRefresh = null,
}: HeaderControlsProps) => {
    const { onWarehouseChange, schedulerDataSet } = useHeaderControls({
        onDateChange,
        onViewChange,
        onZoomIn,
        onZoomOut,
        onRefresh,
    });

    return (
        <Grid container spacing={2} className="headerRow">
            <Grid item xs={12}>
                <ToggleButtonGroup
                    style={{ cursor: 'pointer' }}
                    onChange={(event: React.MouseEvent<HTMLElement>) =>
                        onViewChange(event)
                    }
                    defaultValue={'resourceTimelineDay'}>
                    <ToggleButton
                        color="secondary"
                        value={'resourceTimelineDay'}>
                        Day
                    </ToggleButton>
                    <ToggleButton
                        color="secondary"
                        value={'resourceTimelineWeek'}>
                        Week
                    </ToggleButton>
                    <ToggleButton color="secondary" value={'dayGridMonth'}>
                        Month
                    </ToggleButton>
                    <ToggleButton color="secondary" value={'listWeek'}>
                        List
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12}>
                <WarehouseSelect onChange={onWarehouseChange} />
                <br />

                <FaRegCalendarMinus
                    className="justify-content-end"
                    onClick={() =>
                        onDateChange(
                            moment(schedulerDataSet.selectedDate)
                                .subtract(1, 'day')
                                .format()
                        )
                    }
                    size={25}
                    style={{ cursor: 'pointer' }}
                />
            </Grid>
            <Grid item xs={12}>
                <DesktopDatePicker
                    label="Date"
                    inputFormat="MMMM dd, yyyy"
                    value={schedulerDataSet.selectedDate}
                    onChange={(date) => onDateChange(date)}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Grid>
            <Grid item xs={12}>
                <FaRegCalendarPlus
                    onClick={() =>
                        onDateChange(
                            schedulerDataSet.selectedView.includes('Month')
                                ? moment(schedulerDataSet.selectedDate)
                                      .add(1, 'month')
                                      .format()
                                : moment(schedulerDataSet.selectedDate)
                                      .add(1, 'day')
                                      .format()
                        )
                    }
                    size={25}
                    style={{ cursor: 'pointer' }}
                />
            </Grid>

            <Grid item xs={4}>
                <FaSearchPlus
                    style={{ cursor: 'pointer' }}
                    size={25}
                    onClick={() => onZoomIn()}
                />
                <FaSearchMinus
                    style={{ cursor: 'pointer' }}
                    size={25}
                    onClick={() => onZoomOut()}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <BiRefresh
                    style={{ cursor: 'pointer' }}
                    size={50}
                    onClick={() => onRefresh()}
                />
            </Grid>
        </Grid>
    );
};

export default HeaderControls;
