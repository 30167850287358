import { BaseUpdateEntityArgs } from '../serviceInterfaces';
import { datacorAppApi } from '../datacorAppApi';

export const accessAPI = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        getBusinessEntityFeatureFlags: build.query<any, string>({
            query: (businessEntityId) => {
                return {
                    url: `access/feature-flags`,
                    params: { businessEntityId: businessEntityId },
                };
            },
            transformResponse: (response: FeatureFlag[]) => {
                return response.filter((flag) => {
                    return !flag.hidden;
                });
            },
            providesTags: ['FeatureFlags'],
        }),
        getEnabledBusinessEntityFeatureFlags: build.query<any, string>({
            query: (businessEntityId) => {
                return {
                    url: `access/feature-flags`,
                    params: { businessEntityId: businessEntityId },
                };
            },
            transformResponse: (response: FeatureFlag[]) => {
                return response
                    .filter((flag) => flag.active)
                    .map((flag) => flag.code);
            },
            providesTags: ['FeatureFlags'],
        }),
        getGlobalFeatureFlags: build.query<any, void>({
            query: () => `access/feature-flags/global`,
            transformResponse: (response: FeatureFlag[]) => {
                return response.filter((flag) => {
                    return !flag.hidden;
                });
            },
            providesTags: ['GlobalFeatureFlags'],
        }),
        updateBusinessEntityFeatureFlag: build.mutation<
            any,
            BaseUpdateEntityArgs<FeatureFlag>
        >({
            query: (args) => ({
                url: `access/feature-flags/${args.id}`,
                method: 'PUT',
                body: args.postBody,
                params: { businessEntityId: args.businessEntityId },
            }),
            invalidatesTags: ['FeatureFlags'],
        }),
        updateGlobalFeatureFlag: build.mutation<
            any,
            BaseUpdateEntityArgs<FeatureFlag>
        >({
            query: (args) => ({
                url: `access/feature-flags/global/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['GlobalFeatureFlags'],
        }),
        getAccessLogsByUuid: build.query<any, string>({
            query: (uuid) => `access/logs/${uuid}`,
        }),
        validateAccessRestrictions: build.mutation<boolean, void>({
            query: () => {
                return {
                    url: `access/validateAccessRestrictions`,
                    method: 'POST',
                };
            },
        }),
        getAccessRestrictions: build.query<any, any>({
            query: (timestamp: any) => `access/getAccessRestrictions`,
            providesTags: ['AccessRestrictions'],
        }),
        updateAccessRestrictions: build.mutation<any, any>({
            query: (args) => {
                return {
                    url: `access/updateAccessRestrictions`,
                    method: 'PUT',
                    body: args,
                };
            },
            invalidatesTags: ['AccessRestrictions'],
        }),
        deleteAccessRestrictions: build.mutation<any, string>({
            query: () => {
                return {
                    url: `access/deleteAccessRestrictions`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['AccessRestrictions'],
        }),
    }),
});

export const {
    useGetBusinessEntityFeatureFlagsQuery,
    useGetEnabledBusinessEntityFeatureFlagsQuery,
    useGetGlobalFeatureFlagsQuery,
    useUpdateBusinessEntityFeatureFlagMutation,
    useUpdateGlobalFeatureFlagMutation,
    useGetAccessLogsByUuidQuery,
    useValidateAccessRestrictionsMutation,
    useGetAccessRestrictionsQuery,
    useUpdateAccessRestrictionsMutation,
    useDeleteAccessRestrictionsMutation,
} = accessAPI;

export interface FeatureFlag {
    id?: bigint;
    uuid?: string;
    code: string;
    name?: string;
    description?: string;
    path?: string;
    hidden: boolean;
    locked?: boolean;
    active?: boolean;
    createdAt?: string;
    updatedAt?: string;
}
