import { useUpdateAttachmentMutation } from '../../../services/attachments/attachments.service';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogActions, DialogContent, TextField } from '@mui/material';
import LoaderButton from '../../LoaderButton';
import React from 'react';

const NotesModal = ({ open, setOpen, notes, setNotes, id }: any) => {
    const [updateAttachment] = useUpdateAttachmentMutation();

    const handleSave = () => {
        updateAttachment({
            id: id,
            postBody: {
                notes: notes,
            },
        });
        setOpen(false);
    };
    return (
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth={'xl'}>
            <DialogTitle>Add Note</DialogTitle>
            <DialogContent style={{ width: '45vw' }}>
                <TextField
                    value={notes}
                    onChange={(e: any) => setNotes(e.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    label={'Add notes...'}
                />
            </DialogContent>
            <DialogActions>
                <LoaderButton
                    onClick={() => setOpen(false)}
                    variant={'outlined'}>
                    Cancel
                </LoaderButton>
                <LoaderButton onClick={handleSave} variant={'contained'}>
                    Save
                </LoaderButton>
            </DialogActions>
        </Dialog>
    );
};

export default NotesModal;
