// @ts-nocheck
import { accountingAppApi } from '../../services/accountingAppApi';
import { datacorAppApi } from '../../services/datacorAppApi';
import { formulationAppApi } from '../../services/formulationAppApi';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../services/serviceInterfaces';
import { supplyChainAppApi } from '../../services/supplyChainAppApi';
export type AppApis =
    | typeof datacorAppApi
    | typeof formulationAppApi
    | typeof accountingAppApi
    | typeof supplyChainAppApi;

// Intened to wrap and automate the injection of endpoints with a few parameters
// Search "simpleEndpoints" in Confluence for details on usage
export const simpleEndpoints = <T>(
    api: AppApis,
    modelName: string,
    basePath: string,
    dropdownLabel: string = null,
    dropdownQueryStringFilters: string = null,
    useUpdateListRequest: boolean = false,
    invalidateTags: string[] = []
) => {
    const listQuery = injectList<T>(
        api,
        modelName,
        `list${modelName}`,
        basePath
    );
    const updateListMutation = injectUpdateList<T>(
        api,
        modelName,
        `updateList${modelName}`,
        basePath,
        useUpdateListRequest,
        false,
        invalidateTags
    );
    const getQuery = injectGet<T>(api, modelName, `get${modelName}`, basePath);
    const createMutation = injectCreate<T>(
        api,
        modelName,
        `create${modelName}`,
        basePath,
        false,
        invalidateTags
    );
    const updateMutation = injectUpdate<T>(
        api,
        modelName,
        `update${modelName}`,
        basePath,
        false,
        invalidateTags
    );
    const deleteMutation = injectDelete<T>(
        api,
        modelName,
        `delete${modelName}`,
        basePath,
        false,
        invalidateTags
    );

    const dropDownQuery = dropdownLabel
        ? injectDropdown<T>(
              api,
              modelName,
              `dropdown${modelName}`,
              basePath,
              dropdownLabel,
              'id',
              dropdownQueryStringFilters
          )
        : null;

    return {
        listQuery,
        getQuery,
        createMutation,
        updateMutation,
        deleteMutation,
        updateListMutation,
        dropDownQuery,
    };
};

export const injectGet = <T>(
    api: AppApis,
    modelName: string,
    service: string,
    basePath: string,
    overrideExisting: boolean = false
) => {
    api.enhanceEndpoints({
        addTagTypes: [`Active_${modelName}`],
    });

    api.injectEndpoints({
        endpoints: (build) => ({
            [service]: build.query<T, string>({
                query: (id) => `${basePath}/${id}`,
                // @ts-ignore
                providesTags: [`Active_${modelName}`],
            }),
        }),
        overrideExisting,
    });

    // @ts-ignore
    return api.endpoints[service].useQuery;
};

export const injectCreate = <T>(
    api: AppApis,
    modelName: string,
    service: string,
    basePath: string,
    overrideExisting: boolean = false,
    invalidateTags: string[] = []
) => {
    api.injectEndpoints({
        endpoints: (build) => ({
            [service]: build.mutation<any, BaseCreateEntityArgs<any>>({
                query: (args: BaseCreateEntityArgs<T>) => ({
                    url: basePath,
                    method: 'POST',
                    body: args.postBody,
                }),
                invalidatesTags: [
                    `List_${modelName}`,
                    `Active_${modelName}`,
                    `Dropdown_${modelName}`,
                    ...invalidateTags,
                ],
            }),
        }),
        overrideExisting,
    });

    // @ts-ignore
    return api.endpoints[service].useMutation;
};

export const injectDelete = <T>(
    api: AppApis,
    modelName: string,
    service: string,
    basePath: string,
    overrideExisting: boolean = false,
    invalidateTags: string[] = []
) => {
    api.injectEndpoints({
        endpoints: (build) => ({
            [service]: build.mutation<any, string>({
                query: (id) => ({
                    url: `${basePath}/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: [
                    `List_${modelName}`,
                    `Dropdown_${modelName}`,
                    ...invalidateTags,
                ],
            }),
        }),
        overrideExisting,
    });

    // @ts-ignore
    return api.endpoints[service].useMutation;
};

export const injectUpdate = <T>(
    api: AppApis,
    modelName: string,
    service: string,
    basePath: string,
    overrideExisting: boolean = false,
    invalidateTags: string[] = []
) => {
    api.injectEndpoints({
        endpoints: (build) => ({
            [service]: build.mutation<any, BaseUpdateEntityArgs<any>>({
                query: (args) => ({
                    url: `${basePath}/${args.id}`,
                    method: 'PATCH',
                    body: args.postBody,
                }),
                invalidatesTags: [
                    `List_${modelName}`,
                    `Active_${modelName}`,
                    `Dropdown_${modelName}`,
                    ...invalidateTags,
                ],
            }),
        }),
        overrideExisting,
    });

    // @ts-ignore
    return api.endpoints[service].useMutation;
};
export const injectList = <T>(
    api: AppApis,
    modelName: string,
    service: string,
    basePath: string,
    overrideExisting: boolean = false
) => {
    api.enhanceEndpoints({
        addTagTypes: [`List_${modelName}`],
    });

    api.injectEndpoints({
        endpoints: (build) => ({
            [service]: build.query<T[], void>({
                query: () => basePath,
                providesTags: [`List_${modelName}`],
            }),
        }),
        overrideExisting,
    });

    // @ts-ignore
    return api.endpoints[service].useQuery;
};

export const injectDropdown = <T>(
    api: AppApis,
    modelName: string,
    service: string,
    basePath: string,
    label: string,
    id: string,
    queryStringFilters: string = null,
    overrideExisting: boolean = false
) => {
    api.enhanceEndpoints({
        addTagTypes: [`Dropdown_${modelName}`],
    });

    api.injectEndpoints({
        endpoints: (build) => ({
            [service]: build.query<T[], void>({
                query: () => `${basePath}?${queryStringFilters ?? ''}`,
                // @ts-ignore
                transformResponse: (response: T[]) => {
                    return response.map((item: any) => {
                        return {
                            value: item[id],
                            label: item[label],
                            object: item,
                        };
                    });
                },
                providesTags: [`Dropdown_${modelName}`],
            }),
        }),
        overrideExisting,
    });

    // @ts-ignore
    return api.endpoints[service].useQuery;
};

export const injectUpdateList = <T>(
    api: AppApis,
    modelName: string,
    service: string,
    basePath: string,
    useUpdateListRequest: boolean = false,
    overrideExisting: boolean = false,
    invalidateTags: string[] = []
) => {
    api.injectEndpoints({
        endpoints: (build) => ({
            [service]: build.mutation<boolean, any>({
                query: (args) => ({
                    url: `${basePath}/updateList`,
                    method: 'POST',
                    body: useUpdateListRequest ? { items: args } : args,
                }),
                invalidatesTags: [
                    `List_${modelName}`,
                    `Active_${modelName}`,
                    `Dropdown_${modelName}`,
                    ...invalidateTags,
                    'Revisions',
                ],
            }),
        }),
        overrideExisting,
    });

    // @ts-ignore
    return api.endpoints[service].useMutation;
};

export const injectApplyTemplate = (
    api: AppApis,
    modelName: string,
    service: string,
    basePath: string,
    overrideExisting: boolean = false,
    invalidateTags: string[] = []
) => {
    api.injectEndpoints({
        endpoints: (build) => ({
            [service]: build.mutation<any, any>({
                query: (args) => ({
                    url: `${basePath}/profile/applyTemplate/${args.id}`,
                    method: 'POST',
                    body: args.postBody,
                }),
                invalidatesTags: [`Active_${modelName}`, ...invalidateTags],
            }),
        }),
        overrideExisting,
    });

    // @ts-ignore
    return api.endpoints[service].useMutation;
};

export const injectUpdateTemplate = (
    api: AppApis,
    modelName: string,
    service: string,
    basePath: string,
    overrideExisting: boolean = false,
    invalidateTags: string[] = []
) => {
    api.injectEndpoints({
        endpoints: (build) => ({
            [service]: build.mutation<any, any>({
                query: (args) => ({
                    url: `${basePath}/attributes/update/${args.id}`,
                    method: 'POST',
                    body: args.postBody,
                }),
                invalidatesTags: [`Active_${modelName}`, ...invalidateTags],
            }),
        }),
        overrideExisting,
    });

    // @ts-ignore
    return api.endpoints[service].useMutation;
};

export const injectUpdateEntityProfile = (
    api: AppApis,
    modelName: string,
    service: string,
    basePath: string,
    overrideExisting: boolean = false,
    invalidateTags: string[] = []
) => {
    api.injectEndpoints({
        endpoints: (build) => ({
            [service]: build.mutation<any, any>({
                query: (args) => ({
                    url: `${basePath}/profile/deleteProfile`,
                    method: 'POST',
                    body: args.postBody,
                }),
                invalidatesTags: [
                    `Active_${modelName}`,
                    `List_${modelName}`,
                    ...invalidateTags,
                ],
            }),
        }),
        overrideExisting,
    });

    // @ts-ignore
    return api.endpoints[service].useMutation;
};
