import React, { useState } from 'react';
import { Card, CardContent } from '@mui/material';
import MigrationManager from './MigrationManager';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import StorageInfo from './StorageInfo';
import TaskManagerHelper from './TaskManagerHelper';
import LoaderButton from '../../components/LoaderButton';
import {
    useListPublishedEventsQuery,
    useTestSystemEventMutation,
} from '../../services/notifications/notifications.service';
import SystemOverview from './SystemOverview';
import { useNavigate } from 'react-router-dom';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const DefaultDevArea = () => {
    const [value, setValue] = useState(0);
    const [testEvent] = useTestSystemEventMutation();
    const { data: events, isLoading } = useListPublishedEventsQuery();
    let navigate = useNavigate();

    const TabPanel = (props: TabPanelProps) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}>
                {value === index && (
                    <Box sx={{ p: 3, height: '65vh' }}>{children}</Box>
                )}
            </div>
        );
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleClickEvent = async () => {
        await testEvent({});
    };

    return (
        <Card>
            <CardContent>
                <h3>Datacor Dev Area</h3>
                <Tabs
                    value={value}
                    variant="scrollable"
                    onChange={handleChange}
                    textColor="inherit"
                    indicatorColor="secondary">
                    <Tab label="View Migrations" />
                    <Tab label="S3 Storage" />
                    <Tab label="Task Manager Helper" />
                    <Tab label="Events" />
                    <Tab label="System Overview" />
                    <Tab
                        label={
                            <a
                                onClick={() =>
                                    navigate('/workflow/test-entity')
                                }>
                                Workflow Test
                            </a>
                        }
                    />
                    <Tab
                        label={
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={
                                    'https://zeroheight.com/074533c57/p/66e8cb-welcome-to-lapis'
                                }>
                                Design System
                            </a>
                        }
                    />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <MigrationManager />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <StorageInfo />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <TaskManagerHelper />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Box style={{ overflow: 'auto', height: '100%' }}>
                        <LoaderButton
                            variant={'contained'}
                            onClick={handleClickEvent}>
                            Test System Event
                        </LoaderButton>
                        <br />
                        <h4>Recent Events</h4>
                        <Box style={{ height: '100%', overflow: 'auto' }}>
                            {events &&
                                events?.map((event: any) => (
                                    <div key={event.id}>
                                        Variable Data:{' '}
                                        {JSON.stringify(event.variableData)}
                                        <br />
                                        <br />
                                        Directed Context:{' '}
                                        {event.directedContext}
                                        <br />
                                        <br />
                                    </div>
                                ))}
                        </Box>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <SystemOverview />
                </TabPanel>
            </CardContent>
        </Card>
    );
};
//
// Creating this dev area for datacorAdmins only that we can put components
// easily into the UI if they don't exist/have a use-case yet.
//
const DevArea = () => {
    return <DefaultDevArea />;
};

export default DevArea;
