import React, { useContext, useEffect, useMemo, useState } from 'react';
import FormContext from '../../simpleForm/FormContext';
import TranslatableText from '../../../i18n/TranslatableText';
import { useForm } from '../../../../libs/hooksLib';
import _ from 'lodash';

type FormControlProps = {
    fields?: any;
    handleFieldChange?: any;
    onChange?: any;
    termSetKey?: string;
    [key: string]: any;
};

const useFormControlProps = (props: FormControlProps) => {
    const formContext = useContext(FormContext);

    const [inputLabel, setInputLabel] = useState<any>(null);

    const useFormData = useForm(null);

    const formMethods = useMemo(() => {
        return formContext ? formContext.formMethods : props.formMethods;
    }, [formContext, props.formMethods]);

    let handleFieldChange = useMemo(() => {
        if (formContext && !props.formMethods) {
            return formContext.handleFieldChange;
        }

        if (props.onChange || props.handleFieldChange) {
            return props.onChange ? props.onChange : props.handleFieldChange;
        }

        return useFormData.handleFieldChange;
    }, [formContext, props.onChange, props.handleFieldChange]);

    let value =
        formContext?.fields && !props.formMethods
            ? _.get(formContext?.fields, props.id)
            : props.value;

    let fields = formContext?.fields ? formContext?.fields : props.fields;

    useEffect(() => {
        if (formContext && formContext?.fields) {
            value = _.get(formContext?.fields, props.id);
        }
    }, [formContext]);

    useEffect(() => {
        value = formContext?.fields
            ? _.get(formContext?.fields, props.id)
            : props.value;
    }, [props.options]);

    useEffect(() => {
        if (props.termSetKey) {
            setInputLabel(
                <TranslatableText
                    termSet={formContext.termSet}
                    termKey={props.termSetKey}
                />
            );
        }
    }, [formContext?.termSet]);

    return {
        formMethods,
        handleFieldChange,
        value,
        fields,
        label: inputLabel ? inputLabel : props.label,
    };
};

export default useFormControlProps;
