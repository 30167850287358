import React from 'react';
import './Comments.css';
import useCommentsList from '../hooks/Comments/useCommentsList';

const CommentsList = ({ documentId }: any) => {
    const { commentComponents } = useCommentsList(documentId);

    return <div>{commentComponents}</div>;
};

export default CommentsList;
