import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import BuildIcon from '@mui/icons-material/Build';
import { alpha, styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import SettingsMenu from './menuIconMenus/SettingsMenu';
import { RootState } from '../../store';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import TaskListPopover from '../administration/task-manager/TaskListPopover';
import TaskModal from '../administration/task-manager/TaskModal';
import { Badge } from '@mui/material';
import RecentItems from './menuIconMenus/RecentItems';
import LinkIcon from '@mui/icons-material/Link';
import useTasks from '../administration/task-manager/hooks/useTasks';
import NotificationsIcon from '@mui/icons-material/Notifications';
import useNotifications from '../administration/notifications/useNotifications';
import NotificationsPopover from '../administration/notifications/NotificationsPopover';
import { displayDevOnly } from '../../utils/displayUtils';

const MenuIconsWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    color: theme.palette.headerNavIcons.main,
    marginLeft: 0,
    textAlign: 'right',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const MenuIcons = () => {
    const user = useSelector((state: RootState) => state.user);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [recentItemsAnchorEl, setRecentItemsAnchorEl] =
        useState<HTMLElement | null>(null);

    const {
        shouldSeeTasks,
        handleClickTask,
        newTaskPopoverOpen,
        setNewTaskPopoverOpen,
        openTasksModal,
        setOpenTasksModal,
        selectedTask,
        setSelectedTask,
        isPersonal,
        setIsPersonal,
        taskFields,
        setTaskFields,
        openTasks,
        tasksAchorEl,
        setTasksAnchorEl,
    } = useTasks();

    const {
        notifications,
        shouldShowNotifications,
        handleClickNotification,
        notificationAnchorEl,
        setNotificationAnchorEl,
        notificationOpen,
        handleCloseNotification,
        isLoading,
    } = useNotifications();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickRecentItems = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setRecentItemsAnchorEl(event.currentTarget);
    };

    return (
        <MenuIconsWrapper>
            {shouldShowNotifications && (
                <IconButton
                    data-testid="notification-icon"
                    onClick={handleClickNotification}>
                    <Badge
                        badgeContent={
                            notifications &&
                            notifications?.filter((item: any) => !item.read)
                                .length
                        }
                        color="error">
                        {/*@ts-ignore*/}
                        <NotificationsIcon color="headerNavIcons" />
                    </Badge>
                </IconButton>
            )}
            {shouldSeeTasks && (
                <IconButton data-testid="task-icon">
                    <Badge
                        onClick={handleClickTask}
                        badgeContent={openTasks && openTasks?.length}
                        color="error">
                        {/*@ts-ignore*/}
                        <AssignmentTurnedInIcon color="headerNavIcons" />
                    </Badge>
                </IconButton>
            )}
            <IconButton
                onClick={handleClickRecentItems}
                data-testid="recent-items-icon">
                {/*@ts-ignore*/}
                <LinkIcon color="headerNavIcons" />
            </IconButton>
            <IconButton onClick={handleClick} data-testid="settings-icon">
                {/*@ts-ignore*/}
                <BuildIcon color="headerNavIcons" />
            </IconButton>
            <RecentItems
                anchorEl={recentItemsAnchorEl}
                setAnchorEl={setRecentItemsAnchorEl}
            />
            <SettingsMenu
                user={user}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
            />
            <TaskListPopover
                open={openTasksModal}
                anchorEl={tasksAchorEl}
                setAnchorEl={setTasksAnchorEl}
                setOpen={setOpenTasksModal}
                setNewTaskPopoverOpen={setNewTaskPopoverOpen}
                setTaskFields={setTaskFields}
                setSelectedTask={setSelectedTask}
                isPersonal={isPersonal}
                setIsPersonal={setIsPersonal}
            />
            <TaskModal
                open={newTaskPopoverOpen}
                setOpen={setNewTaskPopoverOpen}
                taskFields={taskFields}
                setTaskFields={setTaskFields}
                selectedTask={selectedTask}
                setSelectedTask={setSelectedTask}
                isPersonal={isPersonal}
                setIsPersonal={setIsPersonal}
            />
            <NotificationsPopover
                notifications={notifications}
                anchorEl={notificationAnchorEl}
                setAnchorEl={setNotificationAnchorEl}
                open={notificationOpen}
                handleClose={handleClickNotification}
            />
        </MenuIconsWrapper>
    );
};

export default MenuIcons;
