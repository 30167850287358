import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import TextInput from '../form/formInputs/TextInput/TextInput';
import SelectInput from '../form/formInputs/SelectInput/SelectInput';
import LoaderButton from '../LoaderButton';
import { isNilOrEmpty } from '../../utils/objectUtils';
import FormPartial from '../form/formContainer/FormPartial';
import EditModal from './EditModal';
import { PermissionsUtil } from '../../utils/permissions/permissionsUtil';
import useEntitySettingsDrawer from './hooks/useEntitySettingsDrawer';

const drawerWidth = 400;

interface themeTypes {
    theme: any;
}

const DrawerHeader = styled('div')(({ theme }: themeTypes) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(10, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

export default function EntitySettingsDrawer({
    open,
    setOpen,
    settingValue,
    settingKey,
    type,
    saveNewText,
    currentSelectionText,
}: any) {
    const {
        openEditModal,
        setOpenEditModal,
        handleDrawerClose,
        theme,
        settingName,
        setSettingName,
        isPublic,
        setIsPublic,
        handleCreateEntitySetting,
        user,
        handleEdit,
        currentSetting,
        entitySettingsList,
        handleSelectionChange,
    } = useEntitySettingsDrawer({ settingValue, settingKey, type, setOpen });

    return (
        <Box sx={{ display: 'flex' }}>
            <EditModal
                settingValue={openEditModal}
                handleClose={() => setOpenEditModal(null)}
            />
            <CssBaseline />
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                    },
                }}
                variant="persistent"
                anchor="right"
                open={open}>
                <DrawerHeader>
                    <Typography variant="h6">{type} Settings</Typography>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? (
                            <ChevronLeftIcon />
                        ) : (
                            <ChevronRightIcon />
                        )}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <div style={{ padding: '10px' }}>
                    <FormPartial title={saveNewText}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <TextInput
                                    label="Name"
                                    value={settingName}
                                    onChange={(event: any) =>
                                        setSettingName(event.target.value)
                                    }
                                    size={'small'}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            key={Math.random()}
                                            id={'public'}
                                            checked={isPublic}
                                            onChange={() =>
                                                setIsPublic((prev) => !prev)
                                            }
                                        />
                                    }
                                    label={'Make Public?'}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <LoaderButton
                                    onClick={handleCreateEntitySetting}
                                    variant={'contained'}>
                                    Save
                                </LoaderButton>
                            </Grid>
                        </Grid>
                    </FormPartial>
                    <div style={{ paddingBottom: '10px' }} />
                    <FormPartial title={currentSelectionText}>
                        <SelectInput
                            fullWidth
                            showEditButton={PermissionsUtil.isPermissionEnabled(
                                user?.permissions,
                                'Settings.EntitySettings.GridLayouts.edit'
                            )}
                            handleEdit={handleEdit}
                            value={currentSetting && currentSetting?.id}
                            options={
                                (!isNilOrEmpty(entitySettingsList) &&
                                    entitySettingsList?.map((setting: any) => {
                                        return {
                                            label: `${setting.subKey} ${
                                                setting.public ? '(Public)' : ''
                                            }`,
                                            value: setting.id,
                                        };
                                    })) ||
                                []
                            }
                            onChange={handleSelectionChange}
                        />
                    </FormPartial>
                </div>
            </Drawer>
        </Box>
    );
}
