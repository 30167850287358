import { isNil } from '../utils/objectUtils';
import { ParameterizedValidator, Validator } from './validator.interface';

export const MinLengthValidator: ParameterizedValidator = (
    minLength: number
) => {
    return {
        key: 'minLength',
        getErrorMessage: (fieldLabel: string) => {
            return `${fieldLabel} has a minimum length of ${minLength} characters`;
        },
        isValid: (value: any) => {
            return isNil(value) || value.toString().length >= minLength;
        },
    } as Validator;
};
