import React from 'react';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

interface BreadcrumbsProps {
    active: string;
}

const PABreadcrumbs = ({ active }: BreadcrumbsProps) => {
    return (
        <Breadcrumbs>
            <Link to="/pricingAnalysisTool">Pricing Analysis Summary</Link>

            <Link to="/pricingAnalysisTool/pricingConcernDetails">
                Customer Product Pricing Concern Detail
            </Link>

            {active === 'pricingCustomerProductDetails' ? (
                <Typography>Customer Product Transaction Detail</Typography>
            ) : null}
            {active === 'pricingProposalReport' ? (
                <Typography>Customer Product Proposal Report</Typography>
            ) : null}
        </Breadcrumbs>
    );
};

export default PABreadcrumbs;
