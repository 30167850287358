/**
 * all of our editable grid fields we have on the
 * sds page
 * we can add or remove fields here as we need them
 */

export const GHS_PHRASE_FIELDS = {
    phraseText: 'Phrase Text',
    phraseId: 'Phrase ID',
    hazardTypeId: 'Hazard Type',
};

export const GHS_PRECAUTIONARY_PHRASE_FIELDS = {
    phraseText: 'Phrase Text',
    phraseId: 'Phrase ID',
    statementTypeId: 'Statement Type',
};

export const SDS_CAS_FIELDS = {
    casId: 'CAS ID',
    classification: 'Classification',
    chemicalName: 'Chemical Name',
    ecIdentifier: 'EC No.',
    itemId: 'Item ID',
    lowerPercentage: 'Lower Percentage',
    lowerOperatorId: 'Lower Operator',
    reachNumber: 'Reach No.',
    tradeSecret: 'Trade Secret',
    upperOperatorId: 'Upper Operator',
    upperPercentage: 'Upper Percentage',
};

export const SDS_EXPOSURE_FIELDS = {
    casId: 'CAS ID',
    exposureAgencyId: 'Exposure Agency',
    exposureValue: 'Exposure Value',
    exposureForm: 'Exposure Form',
    notes: 'Notes',
};

export const SDS_PROPERTY_FIELDS = {
    propertyId: 'Property ID',
    propertyValue: 'Property Value',
    propertyUnitId: 'Property Unit',
};

export const SDS_TOXICITY_FIELDS = {
    chemicalName: 'Chemical Name',
    effectiveDose: 'Effective Dose',
    exposureTime: 'Exposure Time',
    notes: 'Notes',
};

export const SDS_ECO_TOXICITY_FIELDS = {
    chemicalName: 'Chemical Name',
    effectiveDose: 'Effective Dose',
    exposureTime: 'Exposure Time',
    notes: 'Notes',
    source: 'Source',
};

export const SDS_REGULATION_FIELDS = {
    code: 'Regulation Code',
    countryCodeId: 'Country',
    importManufactureLimit: 'Import Manufacture Limit',
    notes: 'Notes',
    onList: 'On List',
};

export const SDS_SELECTED_PHRASE_FIELDS = {
    textValue: 'Text Value',
};

export const SDS_OPTIONAL_FORMULA_HAZARD_CLASSIFICATION_FIELDS = {
    formulaHazardClassificationId: 'Formula Hazard Classification ID',
    sdsOptionalPhraseLibraryId: 'SDS Optional Phrase Library ID',
    operatorId: 'Operator',
    value: 'Value',
    comparatorId: 'Comparator',
};

export const SDS_OPTIONAL_GHS_CLASSIFICATION_FIELDS = {
    ghsClassificationId: 'GHS Classification ID',
    operatorId: 'Operator',
    comparatorId: 'Comparator',
};

export const SDS_OPTIONAL_HAZARD_PHRASES_FIELDS = {
    hazardPhraseId: 'Hazard Phrase ID',
    exist: 'Exist',
    comparatorId: 'Comparator',
};

export const SDS_OPTIONAL_PRECAUTIONARY_PHRASES_FIELDS = {
    precautionaryPhraseId: 'Precautionary Phrase ID',
    exist: 'Exist',
    comparatorId: 'Comparator',
};

export const SDS_OPTIONAL_PROPERTIES_FIELDS = {
    propertyId: 'Property ID',
    operatorId: 'Operator',
    value: 'Value',
    valueField: 'Value Field',
    quantity1: 'Quantity 1',
    quantity2: 'Quantity 2',
};

export const SDS_OPTIONAL_LOCALES_FIELDS = {
    localeId: 'Locale ID',
};

export const SDS_OPTIONAL_LANGUAGE_FIELDS = {
    sdsOptionalPhraseLibraryId: 'SDS Optional Library ID',
    languageId: 'Language ID',
    text: 'Text',
};
