/*TODO: ts-nocheck is added because of the png files being imported,
 need to find the proper way to handle type declarations. Currently index.d.ts is handling this,
  but it isn't working properly. This has no effect on the functionality.
 */
//@ts-nocheck
import React from 'react';
import { Grid, CardMedia, CardHeader, Card } from '@mui/material';
import sales from './/images/sales.PNG';
import salesRetail from './/images/salesRetail.PNG';
import financial from './/images/financial.PNG';
import maintenance from './/images/maintenance.PNG';
import payable from './/images/payable.PNG';
import performance from './/images/performance.PNG';
import production from './/images/production.PNG';
import quality from './/images/quality.PNG';
import receivables from './/images/receivables.PNG';
import './BusinessIntelligence.css';

const BusinessIntelligence = () => {
    return (
        <div className="businessIntel">
            <Grid container spacing={2} className="businessIntel-card-deck">
                <Grid item xs={12} className="justify-content-md-center">
                    <h1>Business Intelligence Links</h1>
                </Grid>
                <Grid item xs={4}>
                    <a
                        href="https://app.powerbi.com/view?r=eyJrIjoiNjI3ODk5MGUtNDNlNy00MjRkLWFhOWItM2NlOGM0MzRjMDQwIiwidCI6IjU3NGMzZTU2LTQ5MjQtNDAwNC1hZDFhLWQ4NDI3ZTdkYjI0MSIsImMiOjZ9"
                        target="_blank"
                        rel="noopener noreferrer"
                        key="sales">
                        <Card>
                            <CardMedia
                                style={{ height: '200px' }}
                                className="businessIntel-image"
                                image={sales}
                            />
                            <CardHeader
                                title="Sales"
                                className="businessIntel-card-title"
                            />
                        </Card>
                    </a>
                </Grid>
                <Grid item xs={4}>
                    <a
                        href="https://app.powerbi.com/view?r=eyJrIjoiZGIyMWVmMjQtZWM5My00OTJjLThiZmQtODZlZTVlY2IwMmU4IiwidCI6IjU3NGMzZTU2LTQ5MjQtNDAwNC1hZDFhLWQ4NDI3ZTdkYjI0MSIsImMiOjZ9"
                        target="_blank"
                        rel="noopener noreferrer"
                        key="salesRetail">
                        <Card className="businessIntel-card">
                            <CardMedia
                                style={{ height: '200px' }}
                                className="businessIntel-image"
                                image={salesRetail}
                            />
                            <CardHeader
                                className="businessIntel-card-title"
                                title="Sales Retail"
                            />
                        </Card>
                    </a>
                </Grid>
                <Grid item xs={4}>
                    <a
                        href="https://app.powerbi.com/view?r=eyJrIjoiYzE5MzljODEtYTAxZS00ODhlLTg3MzUtMTM1NjFkYjNkZjQzIiwidCI6IjU3NGMzZTU2LTQ5MjQtNDAwNC1hZDFhLWQ4NDI3ZTdkYjI0MSIsImMiOjZ9"
                        target="_blank"
                        rel="noopener noreferrer"
                        key="performance">
                        <Card className="businessIntel-card">
                            <CardMedia
                                style={{ height: '200px' }}
                                className="businessIntel-image"
                                image={performance}
                            />
                            <CardHeader
                                className="businessIntel-card-title"
                                title="Purchasing"
                            />
                        </Card>
                    </a>
                </Grid>
                <Grid item xs={4}>
                    <a
                        href="https://app.powerbi.com/view?r=eyJrIjoiMjYwYzVmZDgtMDBmMi00MzU4LWFkOTItYzA2NDg0MGM5N2Y4IiwidCI6IjU3NGMzZTU2LTQ5MjQtNDAwNC1hZDFhLWQ4NDI3ZTdkYjI0MSIsImMiOjZ9"
                        target="_blank"
                        rel="noopener noreferrer"
                        key="production">
                        <Card className="businessIntel-card">
                            <CardMedia
                                style={{ height: '200px' }}
                                className="businessIntel-image"
                                image={production}
                            />
                            <CardHeader
                                title="Production"
                                className="businessIntel-card-title"
                            />
                        </Card>
                    </a>
                </Grid>
                <Grid item xs={4}>
                    <a
                        href="https://app.powerbi.com/view?r=eyJrIjoiMWRiYzA0MWItZTRjNi00YWU4LTlkM2UtZTYzZjQ3ZTBmMjY5IiwidCI6IjU3NGMzZTU2LTQ5MjQtNDAwNC1hZDFhLWQ4NDI3ZTdkYjI0MSIsImMiOjZ9"
                        target="_blank"
                        rel="noopener noreferrer"
                        key="quality">
                        <Card className="businessIntel-card">
                            <CardMedia
                                style={{ height: '200px' }}
                                className="businessIntel-image"
                                image={quality}
                            />
                            <CardHeader
                                className="businessIntel-card-title"
                                title="Quality"
                            />
                        </Card>
                    </a>
                </Grid>
                <Grid item xs={4}>
                    <a
                        href="https://app.powerbi.com/view?r=eyJrIjoiMjEyNWIzMTQtYmZhZS00MzEzLTk4MTQtODUzNmJhMGEzOTUxIiwidCI6IjU3NGMzZTU2LTQ5MjQtNDAwNC1hZDFhLWQ4NDI3ZTdkYjI0MSIsImMiOjZ9"
                        target="_blank"
                        rel="noopener noreferrer"
                        key="maintenance">
                        <Card className="businessIntel-card">
                            <CardMedia
                                style={{ height: '200px' }}
                                className="businessIntel-image"
                                image={maintenance}
                            />
                            <CardHeader
                                title="Maintenance"
                                className="businessIntel-card-title"
                            />
                        </Card>
                    </a>
                </Grid>
                <Grid item xs={4}>
                    <a
                        href="https://app.powerbi.com/view?r=eyJrIjoiNTJkYzcyYTYtOWI0NC00NTIzLWE1NjQtZWUxMzQ4NGZjZjliIiwidCI6IjU3NGMzZTU2LTQ5MjQtNDAwNC1hZDFhLWQ4NDI3ZTdkYjI0MSIsImMiOjZ9"
                        target="_blank"
                        rel="noopener noreferrer"
                        key="financial">
                        <Card className="businessIntel-card">
                            <CardMedia
                                style={{ height: '200px' }}
                                className="businessIntel-image"
                                image={financial}
                            />
                            <CardHeader
                                title="Financial"
                                className="businessIntel-card-title"
                            />
                        </Card>
                    </a>
                </Grid>
                <Grid item xs={4}>
                    <a
                        href="https://app.powerbi.com/view?r=eyJrIjoiOTk5ODIwMmYtZWRkNC00YTcxLThjMmYtNjViMjUyYTQxMmUwIiwidCI6IjU3NGMzZTU2LTQ5MjQtNDAwNC1hZDFhLWQ4NDI3ZTdkYjI0MSIsImMiOjZ9"
                        target="_blank"
                        rel="noopener noreferrer"
                        key="receivables">
                        <Card className="businessIntel-card">
                            <CardMedia
                                style={{ height: '200px' }}
                                className="businessIntel-image"
                                image={receivables}
                            />
                            <CardHeader
                                title="Receivables"
                                className="businessIntel-card-title"
                            />
                        </Card>
                    </a>
                </Grid>
                <Grid item xs={4}>
                    <a
                        href="https://app.powerbi.com/view?r=eyJrIjoiODM5NDA0NTYtZDA3ZC00NzBiLThiMGYtM2I2NTczZDRjNDE4IiwidCI6IjU3NGMzZTU2LTQ5MjQtNDAwNC1hZDFhLWQ4NDI3ZTdkYjI0MSIsImMiOjZ9"
                        target="_blank"
                        rel="noopener noreferrer"
                        key="payable">
                        <Card className="businessIntel-card">
                            <CardMedia
                                style={{ height: '200px' }}
                                className="businessIntel-image"
                                image={payable}
                            />
                            <CardHeader
                                title="Payables"
                                className="businessIntel-card-title"
                            />
                        </Card>
                    </a>
                </Grid>
            </Grid>
        </div>
    );
};

export default BusinessIntelligence;
