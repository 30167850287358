import React from 'react';
import './ResetPassword.css';
import { Card, Grid, TextField } from '@mui/material';
import LoaderButton from '../../../components/LoaderButton';
import useResetPassword from '../hooks/useResetPassword';

const ResetPassword = () => {
    const {
        fields,
        handleFieldChange,
        isSendingCode,
        handleSendCodeClick,
        validateCodeForm,
    } = useResetPassword();

    return (
        <Card className="ResetPassword">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        id="email"
                        variant="outlined"
                        label="Email"
                        type="email"
                        required
                        fullWidth
                        value={fields.email}
                        onChange={handleFieldChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <LoaderButton
                        fullWidth
                        onClick={handleSendCodeClick}
                        type="submit"
                        variant="contained"
                        isLoading={isSendingCode}
                        disabled={!validateCodeForm()}>
                        Send Confirmation
                    </LoaderButton>
                </Grid>
            </Grid>
        </Card>
    );
};

export default ResetPassword;
