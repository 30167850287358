export const PLATFORM_DEFS = {
    USER_FORM: 'user-form',
    USER_GRID: 'user-grid',
    USER_SETTINGS: 'user-settings',
    BUSINESS_ENTITY_GRID: 'business-entity-grid',
    SUBSCRIBER_GRID: 'subscriber-grid',
    SUBSCRIBER_FORM: 'subscriber-form',
    BUSINESS_ENTITY_FORM: 'business-entity-form',
    BUILDINGS_GRID: 'buildings-grid',
    BUILDINGS_FORM: 'buildings-form',
    GROUPS_GRID: 'groups-grid',
    ROLES_GRID: 'roles-grid',
    GROUPS_FORM: 'groups-form',
    ROLES_FORM: 'roles-form',
    REVISION_HISTORY_FORM: 'revision-history-form',
    ATTACHMENTS_SEARCH_FORM: 'attachments-search-form',
    ATTACHMENTS_FORM: 'attachments-form',
    ATTACHMENTS_FOLDER_FORM: 'attachments-folder-form',
    ATTACHMENTS_FOLDER_GRID: 'attachments-folder-grid',
    ATTACHMENTS_CHECKED_OUT_GRID: 'attachments-checked-out-grid',
    LANGUAGES_GRID: 'languages-grid',
    DEPARTMENT_CODES_FORM: 'department-codes-form',
    DEPARTMENT_CODES_GRID: 'department-codes-grid',
    PERMISSIONS_GRID: 'permissions-grid',
    FEATURE_FLAGS_GRID: 'feature-flags-grid',
    SHARED_COMPONENTS: 'shared-components',
    BUSINESS_ENTITY_SETTINGS: 'business-entity-settings',
    DOC_DEFINITION_GRID: 'doc-definition-grid',
    DOC_DEFINITION_FORM: 'doc-definition-form',
    DOC_TEMPLATE_GRID: 'doc-template-grid',
    DOC_TEMPLATE_FORM: 'doc-template-form',
    JOB_RUNS_GRID: 'job-runs-grid',
    JOB_RUNS_FORM: 'job-runs-form',
    JOB_DETAIL_LOG_GRID: 'job-detail-log-grid',
    JOB_DETAIL_LOG_FORM: 'job-detail-log-form',
    JOB_RUN_STEPS_GRID: 'job-run-steps-grid',
    JOB_RUN_STEPS_MODAL: 'job-run-steps-modal',
    JOB_DEFINITION_CONFIGURATION_GRID: 'job-definition-configuration-grid',
    JOB_DEFINITION_CONFIGURATION_FORM: 'job-definition-configuration-form',
    JOB_STARTED_MODAL: 'job-started-modal',
    JOB_LOGS_BASIC_GRID: 'job-logs-basic-grid',
    AUTH_ERROR_MESSAGES: 'auth-error-messages',
    ACCESS_RESTRICTIONS: 'access-restrictions',
    TASK_MANAGER: 'task-manager',
    EQUATION_BUILDER_FORM: 'equation-builder-form',
    NOTIFICATIONS: 'notifications',
};
