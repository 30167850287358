import _ from 'lodash';

export const isNil = (value: any) => {
    return value === null || value === undefined || value === '';
};

export const isNilOrEmpty = (value: any) => {
    return value === null || value === undefined || value?.length === 0;
};

export const StringUtils = {
    equals: (stringA: string, stringB: string) => {
        return stringA.toLowerCase() === stringB.toLowerCase();
    },
};

export const isObjectField = (field: any) => {
    return field && typeof field === 'object';
};

export const isJsonString = (json: any) => {
    try {
        JSON.parse(json);
        return true;
    } catch (e) {
        return false;
    }
};

export const paginationStateToQuerystringObject = (object: any) => {
    const queryStringParams: any = {};

    //check for pagination properties
    if (!_.isNil(object.page)) {
        queryStringParams.page = object.page;
    }

    if (object.sorting) {
        object.sorting.forEach((sort: any) => {
            queryStringParams[`sort_${sort.colId}`] = sort.sort;
        });
    }

    if (object.pageSize) {
        queryStringParams.pageSize = object.pageSize;
    }

    return queryStringParams;
};

export const getAllObjectKeysRecursive = (
    obj: any,
    prefix: string = ''
): string[] => {
    const keys = [];
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const path = !_.isEmpty(prefix) ? `${prefix}.${key}` : key;
            keys.push(path);
            if (typeof obj[key] === 'object') {
                const subKeys = getAllObjectKeysRecursive(obj[key], path);
                keys.push(...subKeys);
            }
        }
    }

    return keys;
};
