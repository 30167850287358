import React, { useEffect, useRef, useState } from 'react';
import { useGetLicenseKeyQuery } from '../../../services/documentDesigner/documentDesigner.service';
//import { Stimulsoft } from 'stimulsoft-reports-js/Scripts/stimulsoft.viewer';

const useDocumentViewer = (document: any) => {
    const { data: license } = useGetLicenseKeyQuery();
    const stimulsoft = (window as any).Stimulsoft;

    /**
     * load the report when the document changes
     */
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (document) {
            loadSelectedReport();
        }
    }, [document]);

    const loadSelectedReport = async () => {
        if (
            license?.Parameter.Value &&
            license?.Parameter.Value !== 'license_key'
        ) {
            stimulsoft.Base.StiLicense.Key = license?.Parameter.Value;
        }
        const options = new stimulsoft.Viewer.StiViewerOptions();
        options.appearance.fullScreenMode = false;
        options.appearance.scrollbarsMode = false;

        const viewer = new stimulsoft.Viewer.StiViewer(
            options,
            'StiViewer',
            false
        );

        viewer.report = document;

        viewer.report.renderAsync(() => {
            //after we render the report we can render the viewer
            viewer.renderHtml('reportViewer');
            setIsLoading(false);
        });

        // Render the viewer in the designated element
    };

    return { isLoading };
};

export default useDocumentViewer;
