import { datacorAppApi } from '../datacorAppApi';

export const documentDesignerApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        createDocTemplate: build.mutation<any, any>({
            query: (args) => ({
                url: `/document-designer/doc-templates`,
                method: 'POST',
                body: args,
            }),
            invalidatesTags: ['DocTemplates'],
        }),
        updateDocTemplate: build.mutation<any, any>({
            query: (args) => ({
                url: `/document-designer/doc-templates/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['DocTemplates'],
        }),
        listDocTemplates: build.query<any, any>({
            query: (entityType) => ({
                url: `/document-designer/doc-templates`,
                method: 'GET',
                params: entityType ? { entityTypeId: entityType } : {},
            }),
            providesTags: ['DocTemplates'],
        }),
        listActiveDocTemplates: build.query<any, any>({
            query: (entityType) => ({
                url: `/document-designer/doc-templates`,
                method: 'GET',
                params: entityType ? { entityTypeId: entityType } : {},
            }),
            transformResponse: (response: any) => {
                return response.filter(
                    (item: any) => item.docTemplateStatus.code === 'active'
                );
            },
            providesTags: ['DocTemplates'],
        }),
        getDocTemplate: build.query<any, string>({
            query: (id) => ({
                url: `/document-designer/doc-templates/${id}`,
                method: 'GET',
            }),
            providesTags: ['DocTemplates'],
        }),
        deleteDocTemplate: build.mutation<any, string>({
            query: (id) => ({
                url: `/document-designer/doc-templates/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['DocTemplates'],
        }),
        createDocDataSource: build.mutation<any, any>({
            query: (args) => ({
                url: `/document-designer/doc-data-sources`,
                method: 'POST',
                body: args,
            }),
            invalidatesTags: ['DocDataSource'],
        }),
        updateDocDataSource: build.mutation<any, any>({
            query: (args) => ({
                url: `/document-designer/doc-data-sources/${args.id}`,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['DocDataSource'],
        }),
        listDocDataSources: build.query<any, void>({
            query: () => ({
                url: `/document-designer/doc-data-sources`,
                method: 'GET',
            }),
            providesTags: ['DocDataSource'],
        }),
        listDocDataSourcesForDropdown: build.query<any, void>({
            query: () => ({
                url: `/document-designer/doc-data-sources`,
                method: 'GET',
            }),
            transformResponse: (response: any) => {
                return response.map((item: any) => {
                    return {
                        label: item.name,
                        value: item.id,
                    };
                });
            },
            providesTags: ['DocDataSource'],
        }),
        getDocDataSource: build.query<any, string>({
            query: (id) => ({
                url: `/document-designer/doc-data-sources/${id}`,
                method: 'GET',
            }),
            providesTags: ['DocDataSource'],
        }),
        deleteDocDataSource: build.mutation<any, string>({
            query: (id) => ({
                url: `/document-designer/doc-data-sources/${id}`,
                method: 'POST',
            }),
            invalidatesTags: ['DocDataSource'],
        }),
        listDocDefinitionsForDropdown: build.query<any, void>({
            query: () => ({
                url: `/document-designer/doc-definitions`,
                method: 'GET',
            }),
            transformResponse: (response: any) => {
                return response.map((item: any) => {
                    return {
                        label: item.name,
                        value: item.id,
                    };
                });
            },
            providesTags: ['DocDataSource'],
        }),
        listDocDefinitions: build.query<any, void>({
            query: () => ({
                url: `/document-designer/doc-definitions`,
                method: 'GET',
            }),
            providesTags: ['DocDefinitions'],
        }),
        getDocDefinition: build.query<any, any>({
            query: (args) => ({
                url: `/document-designer/doc-definitions/${args.id}`,
                method: 'GET',
            }),
            providesTags: ['DocDefinitions'],
        }),
        createDocDefinition: build.mutation<any, any>({
            query: (args) => ({
                url: '/document-designer/doc-definitions',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['DocDefinitions'],
        }),
        updateDocDefinition: build.mutation<any, any>({
            query: (args) => ({
                url: `/document-designer/doc-definitions/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['DocDefinitions'],
        }),
        deleteDocDefinition: build.mutation<any, any>({
            query: (args) => ({
                url: `/document-designer/doc-definitions/${args.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['DocDefinitions'],
        }),
        generateDocument: build.mutation<any, any>({
            query: (args) => ({
                url: `/document-designer/doc-templates/${args.id?.toString()}/render`,
                method: 'POST',
                body: args,
            }),
            invalidatesTags: ['Doc', 'Attachments'],
        }),
        downloadReport: build.query<any, string>({
            query: (id) => ({
                url: `/document-designer/dev-only-doc-data/${id}`,
                method: 'GET',
                headers: {
                    Accept: 'application/pdf',
                },
                responseHandler: (response) => response.blob(),
            }),
            providesTags: ['Doc'],
        }),
        getLicenseKey: build.query<any, void>({
            query: () => ({
                url: `/document-designer/license`,
                method: 'GET',
            }),
        }),
        getDocTemplateStatusesForDropdown: build.query<any, void>({
            query: () => ({
                url: `/document-designer/doc-templates/statuses`,
                method: 'GET',
            }),
            transformResponse: (response: any) => {
                return response.map((item: any) => {
                    return {
                        label: item.name,
                        value: item.id,
                    };
                });
            },
        }),
        searchListDocTemplatesForDropDown: build.query<any, any>({
            query: (args) => ({
                url: `/document-designer/doc-templates/searchlist`,
                method: 'POST',
                body: args,
            }),
            transformResponse: (response: any) => {
                return response.map((item: any) => {
                    return {
                        label: item.name,
                        value: item.id,
                    };
                });
            },
        }),
        searchListDocTemplatesForPrintModal: build.query<any, any>({
            query: (args) => ({
                url: `/document-designer/doc-templates/searchlist`,
                method: 'POST',
                body: args,
            }),
        }),
    }),
});

export const {
    useCreateDocDefinitionMutation,
    useUpdateDocDefinitionMutation,
    useListDocDefinitionsQuery,
    useListDocDefinitionsForDropdownQuery,
    useGetDocDefinitionQuery,
    useDeleteDocDefinitionMutation,
    useCreateDocTemplateMutation,
    useUpdateDocTemplateMutation,
    useListDocTemplatesQuery,
    useListActiveDocTemplatesQuery,
    useGetDocTemplateQuery,
    useDeleteDocTemplateMutation,
    useCreateDocDataSourceMutation,
    useUpdateDocDataSourceMutation,
    useListDocDataSourcesQuery,
    useListDocDataSourcesForDropdownQuery,
    useGetDocDataSourceQuery,
    useDeleteDocDataSourceMutation,
    useGenerateDocumentMutation,
    useDownloadReportQuery,
    useGetLicenseKeyQuery,
    useGetDocTemplateStatusesForDropdownQuery,
    useSearchListDocTemplatesForDropDownQuery,
    useSearchListDocTemplatesForPrintModalQuery,
} = documentDesignerApi;

export interface DocDefinition {
    id?: bigint;
    applicationId: bigint;
    entityTypeId: bigint;
    code: string;
    name: string;
    enablePreview: boolean;
    enableSaveAttachment: boolean;
    purgeHours: number;
    purgeYears: number;
    keepAllCopies: boolean;
    checkForDuplicates: boolean;
    checkForStaleData: boolean;
}

export interface DocTemplate {
    id: string;
    name: string;
    code: string;
    docDefinitionId: string;
    locked: boolean;
    default: boolean;
    outputFileName: string;
    templateData: any;
}

export interface DocDataSource {
    id: bigint;
    docDefinitionId: bigint;
    name: string;
    code: string;
    version: bigint;
    data: any;
}
