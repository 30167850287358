import { IAccountingNav } from '../../types/Accounting.types';
import { IBankNav } from '../../types/accounting';

export const AccountingNavValues = {
    accountingNav: {
        sourceCurrencyCode: '',
        rateTypeCode: '',
        currencyId: '',
        tabIndex: '1',
        isFormRedirect: false,
    } as IAccountingNav,
};
export const FunctionGroups = {
    FixedAssets: {
        DepreciationExpense: 1,
        AccumulatedDepreciation: 2,
        Asset: 3,
        AssetControl: 4,
        DisposalGain: 5,
        DisposalLoss: 6,
        DisposalSettlement: 7,
    },
    Accounting: {
        Cash: 8,
        AccountsReceivable: 9,
        AccountsPayable: 10,
        RetainedEarnings: 11,
        foreignExchangeGain: 12,
        foreignExchangeLoss: 13,
    },
};

export const BankNavValues = {
    bankNav: {
        bankId: null,
    } as IBankNav,
};
