import React, {
    ForwardedRef,
    useContext,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { GridDefaults } from '../Grid.constants';
import UseBaseGridEditable from './useBaseGridEditable';
import useBaseGridHelpers from './useBaseGridHelpers';
import { BaseGridProps } from '../Grids.propTypes';
import SettingsContext from '../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';
import { skipToken } from '@reduxjs/toolkit/query';

const useBaseGrid = (props: BaseGridProps, baseGridRef?: ForwardedRef<any>) => {
    const { dataKey, autoSize, immutableData } = props;
    const shouldAutoResize = autoSize !== undefined ? autoSize : true;
    const hasImmutableData =
        immutableData || (dataKey && GridDefaults.immutableData);
    const gridRef = useRef<any>(null);
    const [openSettings, setOpenSettings] = useState(false);
    const [openGridDrawer, setOpenGridDrawer] = useState(false);
    const [openSortGrid, setOpenSortGrid] = useState(false);
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: PLATFORM_DEFS.SHARED_COMPONENTS,
              }
            : skipToken
    );
    /** Editable Grid Hook **/
    const {
        createNewEditableRow,
        handleNewValue,
        handleDeleteRow,
        handleDeleteRows,
        handleErrorTooltipGetter,
        setSelectedInvalidRow,
        handleValidationOnDataChange,
        invalidRows,
        getAllRows,
        deletedRows,
        handleEditedData,
        updateRowData,
    } = UseBaseGridEditable(props, gridRef);

    /** helpers hook **/
    const {
        resetState,
        exportDataAsExcel,
        defaultColDefOptions,
        onGridSizeChanged,
        handleColBackSpaceKeyboardEvent,
        onGridReady,
        onFirstDataRendered,
        handleRowDrag,
        onRowDragEnd,
        getContextMenuItems,
    } = useBaseGridHelpers({
        gridRef,
        handleNewValue,
        props,
        createNewEditableRow,
        handleDeleteRow,
        handleDeleteRows,
        handleErrorTooltipGetter,
        setSelectedInvalidRow,
        invalidRows,
        shouldAutoResize,
        deletedRows,
        handleEditedData,
    });

    /**
     * helper props
     * **/
    const {
        isEditable,
        gridClass,
        gridStyle,
        testId,
        context,
        frameworkComponents,
        domLayout,
        pagination,
        paginationPageSize,
        tooltipShowDelay,
        pinnedBottomRowData,
        singleSelectApplyFilter,
    } = props;

    useImperativeHandle(
        baseGridRef,
        () => ({
            createNewEditableRow() {
                return createNewEditableRow();
            },
            deleteRow(data: any) {
                handleDeleteRow(data);
            },
            updateRows(data: any) {
                updateRowData(data);
                handleEditedData();
            },
            getRowData() {
                return getAllRows();
            },
        }),
        [
            createNewEditableRow,
            handleDeleteRow,
            updateRowData,
            handleEditedData,
            getAllRows,
        ]
    );

    /**
     * display related props
     * **/

    const {
        displayGrid,
        displayToolbar,
        displayCreateNewButton,
        displayExportDataButton,
        displayImportDataButton,
        displayResetStateButton,
        displayEntitySettings,
        displayEnableDisableButton,
        displaySummaryRow,
        displaySortButton,
    } = props;

    /**
     * helper method props
     **/
    const { onEnableDisable, onCreateNew, onImportData, onRefetchRowData } =
        props;

    /**
     * Popout grid props
     * **/

    const { gridButtonTitle, settingKey } = props;

    const { displayDrawer, title, grid, detailText, persist } =
        props?.popoutGridProps ?? {};

    const { children, toolbarChildren } = props;

    return {
        gridRef,
        /** All display/permission related props **/
        display: {
            displayToolbar,
            displayCreateNewButton,
            displayExportDataButton,
            displayImportDataButton,
            displayResetStateButton,
            displayEnableDisableButton,
            displayEntitySettings,
            displayGrid,
            displayDrawer,
            displaySortButton,
        },
        /** generic helper props **/
        helpers: {
            dataKey,
            gridClass,
            gridStyle,
            testId,
            context,
            frameworkComponents,
            domLayout,
            pagination,
            paginationPageSize,
            tooltipShowDelay,
            pinnedBottomRowData,
            hasImmutableData,
            displaySummaryRow,
            getAllRows,
        },
        /** generic helper methods **/
        helperMethods: {
            onEnableDisable,
            onCreateNew,
            onImportData,
            resetState,
            exportDataAsExcel,
            onGridSizeChanged,
            handleColBackSpaceKeyboardEvent,
            onGridReady,
            onFirstDataRendered,
            handleRowDrag,
            onRowDragEnd,
            termSet,
            onRefetchRowData,
            getContextMenuItems,
        },
        /** editable grid related props **/
        editableGrid: {
            isEditable,
            createNewEditableRow,
            handleNewValue,
            handleDeleteRow,
            handleDeleteRows,
            handleErrorTooltipGetter,
            setSelectedInvalidRow,
            handleValidationOnDataChange,
            invalidRows,
            singleSelectApplyFilter,
        },
        /** popout grid props **/
        popoutGrid: {
            displayDrawer,
            title,
            grid,
            detailText,
            openGridDrawer,
            setOpenGridDrawer,
            gridButtonTitle,
            persist,
        },
        /** entity settings props **/
        entitySettings: {
            openSettings,
            setOpenSettings,
            settingKey,
        },
        /** generic children props **/
        children: {
            toolbarChildren,
            children,
        },
        sortGrid: {
            openSortGrid,
            setOpenSortGrid,
        },
        colDefOptions: {
            defaultColDefOptions,
        },
    };
};

export default useBaseGrid;
