import { Button } from '@mui/material';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import React from 'react';

//if we have less than 6 sections - show the normal menu
const NormalMenu = (props: any) => {
    const { sections, handleGoToSection } = props;
    return (
        <>
            {sections.map((section: any) => (
                <Button
                    key={section?.link}
                    color={'secondary'}
                    onClick={() => handleGoToSection(section.link)}
                    startIcon={<KeyboardDoubleArrowDownIcon />}>
                    {section.name}
                </Button>
            ))}
        </>
    );
};

export default NormalMenu;
