import moment from 'moment';

export const currencyFormatter = (
    param: any,
    precision = 2,
    currencySign = 'standard'
) => {
    const rawValue = param instanceof Object ? param.value : param;

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: precision,
        currencySign: currencySign,
    });

    return isFinite(rawValue) ? formatter.format(rawValue) : rawValue;
};

/**
 * @Deprecated
 * For most scenarios we want to use the dateFormatter located in the useDate.tsx hook
 * This function is only used in the following places:
 * classes
 * non-react components
 * Areas where we don't want to consider the business entity setting
 * legacy code
 * @param param
 * @param returnRawValue
 */
export const dateFormatter = (param: any, returnRawValue?: boolean) => {
    const rawValue =
        param instanceof Date || !(param instanceof Object)
            ? param
            : param.value;
    if (rawValue && moment(rawValue).isValid()) {
        const utcDate = moment(rawValue).utc();
        return moment({
            year: utcDate.year(),
            month: utcDate.month(),
            day: utcDate.date(),
        }).format('L');
    }

    return returnRawValue ? moment(rawValue).format('L') : '';
};

/**
 * @Deprecated
 * For most scenarios we want to use the dateFormatter located in the useDate.tsx hook
 * This function is only used in the following places:
 * classes
 * non-react components
 * Areas where we don't want to consider the business entity setting
 * legacy code
 * @param param
 * @param returnRawValue
 */
export const dateTimeFormatter = (param: any) => {
    const rawValue = param instanceof Object ? param.value : param;
    return moment(rawValue).isValid() ? moment(rawValue).format('L LT') : '';
};

export const numberFormatter = (param: any) => {
    const rawValue = param instanceof Object ? param.value : param;

    return new Intl.NumberFormat().format(rawValue);
};

export const percentFormatter = (param: any, precision = 2) => {
    const rawValue = param instanceof Object ? param.value : param;

    return isFinite(rawValue)
        ? Number(rawValue / 100).toLocaleString(undefined, {
              style: 'percent',
              minimumFractionDigits: precision,
          })
        : rawValue;
};

export const percentFromDecimalFormatter = (param: any, precision = 2) => {
    const rawValue = param instanceof Object ? param.value : param;

    return isFinite(rawValue)
        ? Number(rawValue).toLocaleString(undefined, {
              style: 'percent',
              minimumFractionDigits: precision,
          })
        : rawValue;
};

export const yesNoFormatter = (param: any) => {
    return param.value !== null && param.value !== '' ? 'Yes' : 'No';
};

export const alphabeticalSortObject = (a: any, b: any, key: string) => {
    return a[key]?.toUpperCase() < b[key]?.toUpperCase()
        ? -1
        : a[key]?.toUpperCase() > b[key]?.toUpperCase()
        ? 1
        : 0;
};

/**
 *
 * @param dateValidated: value that need to change to Date
 * @param fromExcel: if the value is from an excel file, it can come in different formats.
 */
export const convertToDateFormat = (
    dateValidated: number | string | Date,
    fromExcel: boolean = false
) => {
    if (fromExcel && typeof dateValidated === 'number') {
        dateValidated = new Date(
            (Number(dateValidated) - (25567 + 2)) * 86400 * 1000
        );
    } else if (
        typeof dateValidated === 'string' ||
        typeof dateValidated === 'number'
    ) {
        dateValidated = new Date(dateValidated);
    }

    return dateFormatter(dateValidated);
};
