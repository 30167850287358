import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Typography } from '@mui/material';
import React from 'react';
import RevisionValueFormatter from './RevisionValueFormatter';
import _ from 'lodash';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import DisplayChanges from './DisplayChanges';

const DeltaListItem = ({
    delta,
    isLoading,
    setIsLoading,
    setRenderDeltaGrid,
    fieldMappings,
}: any) => {
    const shouldDisplayField = (key: any, delta: any) => {
        const formatter: any = new RevisionValueFormatter().format(key, delta);

        /**
         * if the field is being shown in a grid, we don't need to
         * display it in the list
         */
        if (_.isArray(formatter)) {
            setRenderDeltaGrid(true);
            return false;
        }

        if (isNilOrEmpty(formatter?.before) && isNilOrEmpty(formatter?.after)) {
            return false;
        } else {
            if (_.isObject(formatter?.before) || _.isObject(formatter?.after)) {
                // @ts-ignore
                return !_.isEqual(
                    formatter?.before?.[Object.keys(fieldMappings[key])[1]],
                    formatter?.after?.[Object.keys(fieldMappings[key])[1]]
                );
            } else {
                return !_.isEqual(formatter?.before, formatter?.after);
            }
        }
    };
    return (
        <>
            {delta &&
                Object.keys(delta)?.map((key) => (
                    <div key={key}>
                        <List dense={true} disablePadding>
                            <ListItem>
                                {
                                    // @ts-ignore
                                    fieldMappings[[key]] &&
                                    shouldDisplayField(key, delta) ? (
                                        <>
                                            <Typography>
                                                {`${
                                                    // @ts-ignore
                                                    _.isObject(
                                                        fieldMappings[[key]]
                                                    )
                                                        ? fieldMappings[[key]][
                                                              'displayValue'
                                                          ]
                                                        : fieldMappings[[key]]
                                                }:`}
                                            </Typography>
                                            <Typography
                                                style={{
                                                    textDecoration:
                                                        'line-through',
                                                    paddingRight: '10px',
                                                    paddingLeft: '10px',
                                                    whiteSpace: 'pre-line',
                                                }}>
                                                <DisplayChanges
                                                    fieldMappings={
                                                        fieldMappings
                                                    }
                                                    deltaKey={key}
                                                    delta={delta}
                                                    type={'before'}
                                                    isLoading={isLoading}
                                                    setIsLoading={setIsLoading}
                                                    setRenderDeltaGrid={
                                                        setRenderDeltaGrid
                                                    }
                                                />
                                            </Typography>
                                            <Typography
                                                style={{
                                                    whiteSpace: 'pre-line',
                                                }}>
                                                <DisplayChanges
                                                    fieldMappings={
                                                        fieldMappings
                                                    }
                                                    deltaKey={key}
                                                    delta={delta}
                                                    type={'after'}
                                                    isLoading={isLoading}
                                                    setIsLoading={setIsLoading}
                                                    setRenderDeltaGrid={
                                                        setRenderDeltaGrid
                                                    }
                                                />
                                            </Typography>
                                        </>
                                    ) : null
                                }
                            </ListItem>
                        </List>
                    </div>
                ))}
        </>
    );
};

export default DeltaListItem;
