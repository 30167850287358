import React, { useEffect } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { getWarehouses } from '../store/warehouses';
import { Warehouse } from '../types/PricingAnalysis.types';
import { RootState } from '../store';

const WarehouseSelect = ({ onChange = null }) => {
    const dispatch = useDispatch();
    const { schedulerDataSet } = useSelector(
        (state: RootState) => state.scheduler
    );
    const { warehousesSet } = useSelector(
        (state: RootState) => state.warehouses
    );
    let selectedValue =
        warehousesSet?.warehouses[
            warehousesSet?.warehouses.findIndex(
                (warehouse: Warehouse) =>
                    warehouse.WarehouseNumber === schedulerDataSet.warehouseId
            )
        ];

    useEffect(() => {
        dispatch(getWarehouses());
    }, [dispatch]);

    const warehouseSelectOnChange = (selectedValue: string) => {
        onChange(selectedValue);
    };

    return (
        <Select
            className="basic-select"
            isClearable={true}
            isSearchable={true}
            name="warehouseSelect"
            placeholder="Select Warehouse"
            options={warehousesSet.warehouses}
            defaultValue={selectedValue}
            autoFocus={true}
            isLoading={warehousesSet.isLoading}
            onChange={warehouseSelectOnChange}
            getOptionLabel={(option) => `${option.WarehouseName}`}
            getOptionValue={(option) => `${option.WarehouseNumber}`}
        />
    );
};

export default WarehouseSelect;
