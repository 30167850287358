import { useDispatch, useSelector } from 'react-redux';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout, setIsTimedOut } from '../../../store/user';
import { RootState } from '../../../store';
import SettingsContext from '../../../contexts/settings.context';
import { isNilOrEmpty } from '../../../utils/objectUtils';

const useLanding = () => {
    const user = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const idleTimer = React.createRef<any>();
    const { settings } = useContext(SettingsContext);

    const onAction = () => {
        dispatch(setIsTimedOut(false));
    };

    const onIdle = () => {
        if (user?.isSignedIn) {
            logoutAndResetRoute();
        }
    };

    const logoutAndResetRoute = () => {
        navigate(
            !isNilOrEmpty(settings.userSettings?.defaultPage)
                ? `/login?redirect=${settings.userSettings?.defaultPage}`
                : '/myApps'
        );
        dispatch(logout());
    };
    return { onIdle, onAction, user, idleTimer };
};
export default useLanding;
