import { createSlice } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { onError } from '../libs/errorLib';
import { DATACOR_MULTIPLEXER_URL } from '../libs/constantsLib';
import { SliceState } from '../types/store/EquipmentStore.types';
import { AppDispatch } from './index';

const initialState: SliceState = {
    equipmentSet: {
        isLoading: false,
        equipment: [],
    },
};

const slice = createSlice({
    name: 'equipment',
    initialState: initialState,
    reducers: {
        equipmentLoading: (state, action) => {
            state.equipmentSet.isLoading = action.payload;
        },
        getEquipmentSuccess: (state, action) => {
            state.equipmentSet.equipment = action.payload.equipment;
            state.equipmentSet.isLoading = action.payload.isLoading;
        },
    },
});

export default slice.reducer;

// Actions
export const { equipmentLoading, getEquipmentSuccess } = slice.actions;

export const getEquipment = () => async (dispatch: AppDispatch) => {
    dispatch(equipmentLoading(true));
    const sessionData: any = await Auth.currentSession();
    await fetch(DATACOR_MULTIPLEXER_URL + 'equipment', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionData.idToken.jwtToken,
            'Ocp-Apim-Trace': 'True',
            'Ocp-Apim-Subscription-Key': '536572ce1293439e9147aac7511889dd',
        },
    })
        // By design, the instant state and value of a promise cannot be inspected synchronously from code, without calling the then() method
        .then((dataWrappedByPromise) => dataWrappedByPromise.json())
        .then((data) => {
            dispatch(
                getEquipmentSuccess({ isLoading: false, equipment: data })
            );
        })
        .catch((error) => {
            return onError(error.message);
        });
};
