import { RouteProps } from './routesConfig';
import { PERMISSIONS } from '../constants/permissions/Permissions.constants';
import { ENTITY_TYPES } from '../constants/shared/EntityTypes.constants';
import { lazy } from 'react';

const AccountFunctionCodeGrid = lazy(
    () => import('../containers/accounting/AccountFunctionCodeGrid')
);
const AccountFunctionCodeForm = lazy(
    () => import('../containers/accounting/AccountFunctionCodeForm')
);
const CurrencyGrid = lazy(
    () => import('../containers/accounting/currencies/CurrencyGrid')
);
const CurrencyForm = lazy(
    () => import('../containers/accounting/currencies/CurrencyForm')
);
const CurrencyRateTypeGrid = lazy(
    () =>
        import(
            '../containers/accounting/currencyRateTypes/CurrencyRateTypeGrid'
        )
);
const CurrencyRateTypeForm = lazy(
    () =>
        import(
            '../containers/accounting/currencyRateTypes/CurrencyRateTypeForm'
        )
);
const CurrencyExchangeRateGrid = lazy(
    () =>
        import(
            '../containers/accounting/currencyExchangeRates/CurrencyExchangeRateGrid'
        )
);
const CurrencyExchangeRateForm = lazy(
    () =>
        import(
            '../containers/accounting/currencyExchangeRates/CurrencyExchangeRateForm'
        )
);
const FiscalYearStatusGrid = lazy(
    () =>
        import(
            '../containers/accounting/periodicity/fiscalYearStatuses/FiscalYearStatusGrid'
        )
);
const FiscalYearGrid = lazy(
    () =>
        import(
            '../containers/accounting/periodicity/fiscalYears/FiscalYearGrid'
        )
);
const FiscalYearStatusForm = lazy(
    () =>
        import(
            '../containers/accounting/periodicity/fiscalYearStatuses/FiscalYearStatusForm'
        )
);
const FiscalYearForm = lazy(
    () =>
        import(
            '../containers/accounting/periodicity/fiscalYears/FiscalYearForm'
        )
);
const PeriodStatusGrid = lazy(
    () =>
        import(
            '../containers/accounting/periodicity/periodStatuses/PeriodStatusGrid'
        )
);
const PeriodStatusForm = lazy(
    () =>
        import(
            '../containers/accounting/periodicity/periodStatuses/PeriodStatusForm'
        )
);
const PeriodQuarterForm = lazy(
    () =>
        import(
            '../containers/accounting/periodicity/periodQuarters/PeriodQuarterForm'
        )
);
const PeriodQuartersGrid = lazy(
    () =>
        import(
            '../containers/accounting/periodicity/periodQuarters/PeriodQuartersGrid'
        )
);
const PeriodGrid = lazy(
    () => import('../containers/accounting/periodicity/periods/PeriodGrid')
);
const Accounting = lazy(() => import('../containers/accounting/Accounting'));
const JournalStatusForm = lazy(
    () =>
        import(
            '../containers/accounting/journal/setup/statuses/JournalStatusForm'
        )
);
const JournalStatusGrid = lazy(
    () =>
        import(
            '../containers/accounting/journal/setup/statuses/JournalStatusGrid'
        )
);
const JournalTypeGrid = lazy(
    () => import('../containers/accounting/journal/setup/types/JournalTypeGrid')
);
const JournalTypeForm = lazy(
    () => import('../containers/accounting/journal/setup/types/JournalTypeForm')
);
const PeriodForm = lazy(
    () => import('../containers/accounting/periodicity/periods/PeriodForm')
);
const JournalGrid = lazy(
    () => import('../containers/accounting/journal/journals/JournalGrid')
);
const JournalForm = lazy(
    () => import('../containers/accounting/journal/journals/JournalForm')
);
const Sequences = lazy(
    () => import('../containers/accounting/sequences/Sequences')
);
const RecurringJournalGrid = lazy(
    () =>
        import(
            '../containers/accounting/journal/recurringJournals/RecurringJournalGrid'
        )
);
const RecurringJournalForm = lazy(
    () =>
        import(
            '../containers/accounting/journal/recurringJournals/RecurringJournalForm'
        )
);
const GeneralLedgerAccountGrid = lazy(
    () => import('../containers/accounting/GeneralLedgerAccountGrid')
);
const GeneralLedgerAccountForm = lazy(
    () => import('../containers/accounting/GeneralLedgerAccountForm')
);
const PaymentTermStatusGrid = lazy(
    () =>
        import(
            '../containers/accounting/paymentTerm/setup/statuses/PaymentTermStatusGrid'
        )
);
const PaymentTermStatusForm = lazy(
    () =>
        import(
            '../containers/accounting/paymentTerm/setup/statuses/PaymentTermStatusForm'
        )
);
const SupplierInvoiceStatusGrid = lazy(
    () =>
        import(
            '../containers/accounting/supplierInvoice/setup/statuses/SupplierInvoiceStatusGrid'
        )
);
const SupplierInvoiceStatusForm = lazy(
    () =>
        import(
            '../containers/accounting/supplierInvoice/setup/statuses/SupplierInvoiceStatusForm'
        )
);
const PaymentTermTypeGrid = lazy(
    () =>
        import(
            '../containers/accounting/paymentTerm/setup/types/PaymentTermTypeGrid'
        )
);
const PaymentTermTypeForm = lazy(
    () =>
        import(
            '../containers/accounting/paymentTerm/setup/types/PaymentTermTypeForm'
        )
);
const DimensionStatusGrid = lazy(
    () =>
        import(
            '../containers/accounting/dimension/setup/statuses/DimensionStatusGrid'
        )
);
const DimensionStatusForm = lazy(
    () =>
        import(
            '../containers/accounting/dimension/setup/statuses/DimensionStatusForm'
        )
);
const DimensionTypeForm = lazy(
    () =>
        import(
            '../containers/accounting/dimension/setup/types/DimensionTypeForm'
        )
);
const DimensionTypeGrid = lazy(
    () =>
        import(
            '../containers/accounting/dimension/setup/types/DimensionTypeGrid'
        )
);
const BankAccountStatusGrid = lazy(
    () =>
        import(
            '../containers/accounting/bank/setup/statuses/BankAccountStatusGrid'
        )
);
const BankAccountStatusForm = lazy(
    () =>
        import(
            '../containers/accounting/bank/setup/statuses/BankAccountStatusForm'
        )
);
const BankAccountTypeGrid = lazy(
    () =>
        import('../containers/accounting/bank/setup/types/BankAccountTypeGrid')
);
const BankAccountTypeForm = lazy(
    () =>
        import('../containers/accounting/bank/setup/types/BankAccountTypeForm')
);
const PaymentStatusGrid = lazy(
    () =>
        import(
            '../containers/accounting/payment/setup/paymentStatuses/PaymentStatusGrid'
        )
);
const PaymentStatusForm = lazy(
    () =>
        import(
            '../containers/accounting/payment/setup/paymentStatuses/PaymentStatusForm'
        )
);
const SupplierInvoiceTypeGrid = lazy(
    () =>
        import(
            '../containers/accounting/supplierInvoice/setup/types/SupplierInvoiceTypeGrid'
        )
);
const SupplierInvoiceTypeForm = lazy(
    () =>
        import(
            '../containers/accounting/supplierInvoice/setup/types/SupplierInvoiceTypeForm'
        )
);
const CustomerInvoiceStatusGrid = lazy(
    () =>
        import(
            '../containers/accounting/customerInvoice/setup/statuses/CustomerInvoiceStatusGrid'
        )
);
const CustomerInvoiceStatusForm = lazy(
    () =>
        import(
            '../containers/accounting/customerInvoice/setup/statuses/CustomerInvoiceStatusForm'
        )
);
const PaymentTypeForm = lazy(
    () =>
        import(
            '../containers/accounting/payment/setup/paymentTypes/PaymentTypeForm'
        )
);
const PaymentTypeGrid = lazy(
    () =>
        import(
            '../containers/accounting/payment/setup/paymentTypes/PaymentTypeGrid'
        )
);
const PaymentTermGrid = lazy(
    () =>
        import(
            '../containers/accounting/paymentTerm/setup/paymentTerms/PaymentTermGrid'
        )
);
const PaymentTermForm = lazy(
    () =>
        import(
            '../containers/accounting/paymentTerm/setup/paymentTerms/PaymentTermForm'
        )
);
const CustomerInvoiceGrid = lazy(
    () =>
        import(
            '../containers/accounting/customerInvoice/customerInvoices/CustomerInvoiceGrid'
        )
);
const CustomerInvoiceForm = lazy(
    () =>
        import(
            '../containers/accounting/customerInvoice/customerInvoices/CustomerInvoiceForm'
        )
);
const CustomerInvoiceTypeGrid = lazy(
    () =>
        import(
            '../containers/accounting/customerInvoice/setup/types/CustomerInvoiceTypeGrid'
        )
);
const CustomerInvoiceTypeForm = lazy(
    () =>
        import(
            '../containers/accounting/customerInvoice/setup/types/CustomerInvoiceTypeForm'
        )
);
const DepositStatusGrid = lazy(
    () =>
        import(
            '../containers/accounting/deposit/setup/depositStatuses/DepositStatusGrid'
        )
);
const DepositStatusForm = lazy(
    () =>
        import(
            '../containers/accounting/deposit/setup/depositStatuses/DepositStatusForm'
        )
);
const DepositTypeForm = lazy(
    () =>
        import(
            '../containers/accounting/deposit/setup/depositTypes/DepositTypeForm'
        )
);
const DepositTypeGrid = lazy(
    () =>
        import(
            '../containers/accounting/deposit/setup/depositTypes/DepositTypeGrid'
        )
);
const PaymentRunTypeGrid = lazy(
    () =>
        import(
            '../containers/accounting/payment/setup/paymentRunTypes/PaymentRunTypeGrid'
        )
);
const PaymentRunTypeForm = lazy(
    () =>
        import(
            '../containers/accounting/payment/setup/paymentRunTypes/PaymentRunTypeForm'
        )
);
const PaymentRunStatusGrid = lazy(
    () =>
        import(
            '../containers/accounting/payment/setup/paymentRunStatuses/PaymentRunStatusGrid'
        )
);
const PaymentRunStatusForm = lazy(
    () =>
        import(
            '../containers/accounting/payment/setup/paymentRunStatuses/PaymentRunStatusForm'
        )
);
const DimensionGrid = lazy(
    () => import('../containers/accounting/dimension/dimensions/DimensionGrid')
);
const DimensionForm = lazy(
    () => import('../containers/accounting/dimension/dimensions/DimensionForm')
);
const SupplierInvoiceGrid = lazy(
    () =>
        import(
            '../containers/accounting/supplierInvoice/supplierInvoices/SupplierInvoiceGrid'
        )
);
const SupplierInvoiceForm = lazy(
    () =>
        import(
            '../containers/accounting/supplierInvoice/supplierInvoices/SupplierInvoiceForm'
        )
);
const BankGrid = lazy(
    () => import('../containers/accounting/bank/banks/BankGrid')
);
const BankForm = lazy(
    () => import('../containers/accounting/bank/banks/BankForm')
);
const BankAccountForm = lazy(
    () => import('../containers/accounting/bank/bankAccounts/BankAccountForm')
);
const PaymentGrid = lazy(
    () => import('../containers/accounting/payment/payments/PaymentGrid')
);
const PaymentForm = lazy(
    () => import('../containers/accounting/payment/payments/PaymentForm')
);
const PaymentRunGrid = lazy(
    () => import('../containers/accounting/payment/paymentRuns/PaymentRunGrid')
);
const PaymentRunForm = lazy(
    () => import('../containers/accounting/payment/paymentRuns/PaymentRunForm')
);
const DepositGrid = lazy(
    () => import('../containers/accounting/deposit/deposits/DepositGrid')
);
const DepositForm = lazy(
    () => import('../containers/accounting/deposit/deposits/DepositForm')
);
const CustomerStatementGrid = lazy(
    () =>
        import(
            '../containers/accounting/reports/customerStatements/CustomerStatementGrid'
        )
);
const DepositPaymentForm = lazy(
    () =>
        import(
            '../containers/accounting/deposit/deposits/depositPayments/DepositPaymentForm'
        )
);

const AccountingRoutes: RouteProps[] = [
    {
        path: '/accounting/',
        component: Accounting,
        featureFlag: 'dashboard.nav.apps.accounting',
    },
    {
        path: '/accounting/generalLedgerAccount/:group',
        component: GeneralLedgerAccountGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.CHART_OF_ACCOUNTS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.GENERAL_LEDGER_ACCOUNT,
    },
    {
        path: '/accounting/generalLedgerAccount/:group/:id',
        component: GeneralLedgerAccountForm,
        permissionKey: PERMISSIONS.ACCOUNTING.CHART_OF_ACCOUNTS.VIEW,
        entityType: ENTITY_TYPES.GENERAL_LEDGER_ACCOUNT,
        primaryEntity: true,
    },
    {
        path: '/accounting/functionCode/:group',
        component: AccountFunctionCodeGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.FUNCTION_CODES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.FUNCTION_CODE_GROUP,
    },
    {
        path: '/accounting/functionCode/:group/:id',
        component: AccountFunctionCodeForm,
        permissionKey: PERMISSIONS.ACCOUNTING.FUNCTION_CODES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.FUNCTION_CODE_GROUP,
        primaryEntity: true,
    },
    {
        path: '/accounting/currency',
        component: CurrencyGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.CURRENCIES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.CURRENCIES,
    },
    {
        path: '/accounting/currency/:id',
        component: CurrencyForm,
        permissionKey: PERMISSIONS.ACCOUNTING.CURRENCIES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.CURRENCIES,
        primaryEntity: true,
    },
    {
        path: '/accounting/currencyRateType',
        component: CurrencyRateTypeGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.CURRENCY_RATE_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.CURRENCY_RATE_TYPE,
    },
    {
        path: '/accounting/currencyRateType/:id',
        component: CurrencyRateTypeForm,
        permissionKey: PERMISSIONS.ACCOUNTING.CURRENCY_RATE_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.CURRENCY_RATE_TYPE,
        primaryEntity: true,
    },
    {
        path: '/accounting/currencyExchangeRate',
        component: CurrencyExchangeRateGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.CURRENCY_EXCHANGE_RATES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.CURRENCY_EXCHANGE_RATE,
    },
    {
        path: '/accounting/currencyExchangeRate/:id',
        component: CurrencyExchangeRateForm,
        permissionKey: PERMISSIONS.ACCOUNTING.CURRENCY_EXCHANGE_RATES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.CURRENCY_EXCHANGE_RATE,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/fiscalYearStatus',
        component: FiscalYearStatusGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.FISCAL_YEAR_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.FISCAL_YEAR_STATUS,
    },
    {
        path: '/accounting/setup/fiscalYearStatus/:id',
        component: FiscalYearStatusForm,
        permissionKey: PERMISSIONS.ACCOUNTING.FISCAL_YEAR_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.FISCAL_YEAR_STATUS,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/fiscalYears',
        component: FiscalYearGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.FISCAL_YEARS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.FISCAL_YEAR,
    },
    {
        path: '/accounting/setup/fiscalYears/:id',
        component: FiscalYearForm,
        permissionKey: PERMISSIONS.ACCOUNTING.FISCAL_YEARS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.FISCAL_YEAR,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/periodStatus',
        component: PeriodStatusGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.PERIOD_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.PERIOD_STATUS,
    },
    {
        path: '/accounting/setup/periodStatus/:id',
        component: PeriodStatusForm,
        permissionKey: PERMISSIONS.ACCOUNTING.PERIOD_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.PERIOD_STATUS,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/periodQuarters',
        component: PeriodQuartersGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.PERIOD_QUARTERS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.PERIOD_QUARTERS,
    },
    {
        path: '/accounting/setup/periodQuarters/:id',
        component: PeriodQuarterForm,
        permissionKey: PERMISSIONS.ACCOUNTING.PERIOD_QUARTERS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.PERIOD_QUARTERS,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/period',
        component: PeriodGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.PERIODS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.PERIOD,
    },
    {
        path: '/accounting/setup/period/:id',
        component: PeriodForm,
        permissionKey: PERMISSIONS.ACCOUNTING.PERIODS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.PERIOD,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/journalStatuses',
        component: JournalStatusGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.JOURNAL_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.JOURNAL_STATUS,
    },
    {
        path: '/accounting/setup/journalStatuses/:id',
        component: JournalStatusForm,
        permissionKey: PERMISSIONS.ACCOUNTING.JOURNAL_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.JOURNAL_STATUS,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/journalTypes',
        component: JournalTypeGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.JOURNAL_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.JOURNAL_TYPE,
    },
    {
        path: '/accounting/setup/journalTypes/:id',
        component: JournalTypeForm,
        permissionKey: PERMISSIONS.ACCOUNTING.JOURNAL_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.JOURNAL_TYPE,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/paymentTerms',
        component: PaymentTermGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.PAYMENT_TERMS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.PAYMENT_TERM,
    },
    {
        path: '/accounting/setup/paymentTerms/:id',
        component: PaymentTermForm,
        permissionKey: PERMISSIONS.ACCOUNTING.PAYMENT_TERMS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.PAYMENT_TERM,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/paymentTerms/paymentTermTypes',
        component: PaymentTermTypeGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.PAYMENT_TERM_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.PAYMENT_TERM_TYPE,
    },
    {
        path: '/accounting/setup/paymentTerms/paymentTermTypes/:id',
        component: PaymentTermTypeForm,
        permissionKey: PERMISSIONS.ACCOUNTING.PAYMENT_TERM_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.PAYMENT_TERM_TYPE,
        primaryEntity: true,
    },
    {
        path: '/accounting/journal',
        component: JournalGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.JOURNALS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.JOURNALS,
    },
    {
        path: '/accounting/journal/:id',
        component: JournalForm,
        permissionKey: PERMISSIONS.ACCOUNTING.JOURNALS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.JOURNALS,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/sequences',
        component: Sequences,
        permissionKey: PERMISSIONS.ACCOUNTING.SEQUENCES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.SEQUENCE,
    },
    {
        path: '/accounting/recurringJournal',
        component: RecurringJournalGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.RECURRING_JOURNALS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.RECURRING_JOURNAL,
    },
    {
        path: '/accounting/recurringJournal/:id',
        component: RecurringJournalForm,
        permissionKey: PERMISSIONS.ACCOUNTING.RECURRING_JOURNALS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.RECURRING_JOURNAL,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/paymentTerms/paymentTermStatuses',
        component: PaymentTermStatusGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.PAYMENT_TERM_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.PAYMENT_TERM_STATUS,
    },
    {
        path: '/accounting/setup/paymentTerms/paymentTermStatuses/:id',
        component: PaymentTermStatusForm,
        permissionKey: PERMISSIONS.ACCOUNTING.PAYMENT_TERM_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.PAYMENT_TERM_STATUS,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/dimensions/dimensionStatuses',
        component: DimensionStatusGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.DIMENSION_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.DIMENSION_STATUS,
    },
    {
        path: '/accounting/setup/dimensions/dimensionStatuses/:id',
        component: DimensionStatusForm,
        permissionKey: PERMISSIONS.ACCOUNTING.DIMENSION_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.DIMENSION_STATUS,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/dimensions/dimensionTypes',
        component: DimensionTypeGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.DIMENSION_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.DIMENSION_TYPES,
    },
    {
        path: '/accounting/setup/dimensions/dimensionTypes/:id',
        component: DimensionTypeForm,
        permissionKey: PERMISSIONS.ACCOUNTING.DIMENSION_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.DIMENSION_TYPES,
        primaryEntity: true,
    },
    {
        path: '/accounting/accountsPayable/supplierInvoices',
        component: SupplierInvoiceGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.SUPPLIER_INVOICES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.SUPPLIER_INVOICES,
    },
    {
        path: '/accounting/accountsPayable/supplierInvoices/:id',
        component: SupplierInvoiceForm,
        permissionKey: PERMISSIONS.ACCOUNTING.SUPPLIER_INVOICES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.SUPPLIER_INVOICES,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/dimensions',
        component: DimensionGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.DIMENSIONS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.DIMENSIONS,
    },
    {
        path: '/accounting/setup/dimensions/:id',
        component: DimensionForm,
        permissionKey: PERMISSIONS.ACCOUNTING.DIMENSIONS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.DIMENSIONS,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/accountsPayable/supplierInvoiceStatuses',
        component: SupplierInvoiceStatusGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.SUPPLIER_INVOICE_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.SUPPLIER_INVOICE_STATUS,
    },
    {
        path: '/accounting/setup/accountsPayable/supplierInvoiceStatuses/:id',
        component: SupplierInvoiceStatusForm,
        permissionKey: PERMISSIONS.ACCOUNTING.SUPPLIER_INVOICE_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.SUPPLIER_INVOICE_STATUS,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/accountsPayable/supplierInvoiceTypes',
        component: SupplierInvoiceTypeGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.SUPPLIER_INVOICE_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.SUPPLIER_INVOICE_TYPE,
    },
    {
        path: '/accounting/setup/accountsPayable/supplierInvoiceTypes/:id',
        component: SupplierInvoiceTypeForm,
        permissionKey: PERMISSIONS.ACCOUNTING.SUPPLIER_INVOICE_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.SUPPLIER_INVOICE_TYPE,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/banks/bankAccountStatuses',
        component: BankAccountStatusGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.BANK_ACCOUNT_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.BANK_ACCOUNT_STATUS,
    },
    {
        path: '/accounting/setup/banks/bankAccountStatuses/:id',
        component: BankAccountStatusForm,
        permissionKey: PERMISSIONS.ACCOUNTING.BANK_ACCOUNT_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.BANK_ACCOUNT_STATUS,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/banks/bankAccountTypes',
        component: BankAccountTypeGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.BANK_ACCOUNT_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.BANK_ACCOUNT_TYPE,
    },
    {
        path: '/accounting/setup/banks/bankAccountTypes/:id',
        component: BankAccountTypeForm,
        permissionKey: PERMISSIONS.ACCOUNTING.BANK_ACCOUNT_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.BANK_ACCOUNT_TYPE,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/accountsPayable/paymentStatuses',
        component: PaymentStatusGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.PAYMENT_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.PAYMENT_STATUS,
    },
    {
        path: '/accounting/setup/accountsPayable/paymentStatuses/:id',
        component: PaymentStatusForm,
        permissionKey: PERMISSIONS.ACCOUNTING.PAYMENT_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.PAYMENT_STATUS,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/accountsPayable/paymentTypes',
        component: PaymentTypeGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.PAYMENT_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.PAYMENT_TYPE,
    },
    {
        path: '/accounting/setup/accountsPayable/paymentTypes/:id',
        component: PaymentTypeForm,
        permissionKey: PERMISSIONS.ACCOUNTING.PAYMENT_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.PAYMENT_TYPE,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/accountsPayable/paymentRunTypes',
        component: PaymentRunTypeGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.PAYMENT_RUN_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.PAYMENT_RUN_TYPE,
    },
    {
        path: '/accounting/setup/accountsPayable/paymentRunTypes/:id',
        component: PaymentRunTypeForm,
        permissionKey: PERMISSIONS.ACCOUNTING.PAYMENT_RUN_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.PAYMENT_RUN_TYPE,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/accountsPayable/paymentRunStatuses',
        component: PaymentRunStatusGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.PAYMENT_RUN_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.PAYMENT_RUN_STATUS,
    },
    {
        path: '/accounting/setup/accountsPayable/paymentRunStatuses/:id',
        component: PaymentRunStatusForm,
        permissionKey: PERMISSIONS.ACCOUNTING.PAYMENT_RUN_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.PAYMENT_RUN_STATUS,
        primaryEntity: true,
    },
    {
        path: '/accounting/accountsPayable/payments',
        component: PaymentGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.PAYMENTS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.PAYMENT,
    },
    {
        path: '/accounting/accountsPayable/payments/:id',
        component: PaymentForm,
        permissionKey: PERMISSIONS.ACCOUNTING.PAYMENTS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.PAYMENT,
        primaryEntity: true,
    },
    {
        path: '/accounting/accountsPayable/paymentRuns',
        component: PaymentRunGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.PAYMENT_RUNS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.PAYMENT_RUN,
    },
    {
        path: '/accounting/accountsPayable/paymentRuns/:id',
        component: PaymentRunForm,
        permissionKey: PERMISSIONS.ACCOUNTING.PAYMENT_RUNS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.PAYMENT_RUN,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/accountsReceivable/customerInvoiceStatuses',
        component: CustomerInvoiceStatusGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.CUSTOMER_INVOICE_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.CUSTOMER_INVOICE_STATUS,
    },
    {
        path: '/accounting/setup/accountsReceivable/customerInvoiceStatuses/:id',
        component: CustomerInvoiceStatusForm,
        permissionKey: PERMISSIONS.ACCOUNTING.CUSTOMER_INVOICE_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.CUSTOMER_INVOICE_STATUS,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/accountsReceivable/customerInvoiceTypes',
        component: CustomerInvoiceTypeGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.CUSTOMER_INVOICE_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.CUSTOMER_INVOICE_TYPE,
    },
    {
        path: '/accounting/setup/accountsReceivable/customerInvoiceTypes/:id',
        component: CustomerInvoiceTypeForm,
        permissionKey: PERMISSIONS.ACCOUNTING.CUSTOMER_INVOICE_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.CUSTOMER_INVOICE_TYPE,
        primaryEntity: true,
    },
    {
        path: '/accounting/accountsReceivable/customerInvoices',
        component: CustomerInvoiceGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.CUSTOMER_INVOICES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.CUSTOMER_INVOICES,
    },
    {
        path: '/accounting/accountsReceivable/customerInvoices/:id',
        component: CustomerInvoiceForm,
        permissionKey: PERMISSIONS.ACCOUNTING.CUSTOMER_INVOICES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.CUSTOMER_INVOICES,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/accountsReceivable/depositStatuses',
        component: DepositStatusGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.DEPOSIT_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.DEPOSIT_STATUS,
    },
    {
        path: '/accounting/setup/accountsReceivable/depositStatuses/:id',
        component: DepositStatusForm,
        permissionKey: PERMISSIONS.ACCOUNTING.DEPOSIT_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.DEPOSIT_STATUS,
        primaryEntity: true,
    },
    {
        path: '/accounting/setup/accountsReceivable/depositTypes',
        component: DepositTypeGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.DEPOSIT_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.DEPOSIT_TYPE,
    },
    {
        path: '/accounting/setup/accountsReceivable/depositTypes/:id',
        component: DepositTypeForm,
        permissionKey: PERMISSIONS.ACCOUNTING.DEPOSIT_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.DEPOSIT_TYPE,
        primaryEntity: true,
    },
    {
        path: '/accounting/accountsReceivable/deposits',
        component: DepositGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.DEPOSITS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.DEPOSIT,
    },
    {
        path: '/accounting/accountsReceivable/deposits/:id',
        component: DepositForm,
        permissionKey: PERMISSIONS.ACCOUNTING.DEPOSITS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.DEPOSIT,
    },
    {
        path: '/accounting/accountsReceivable/deposits/depositPayments/:id',
        component: DepositPaymentForm,
        permissionKey: PERMISSIONS.ACCOUNTING.DEPOSITS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.DEPOSIT,
    },
    {
        path: '/accounting/setup/banks',
        component: BankGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.BANKS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.BANK,
    },
    {
        path: '/accounting/setup/banks/:id',
        component: BankForm,
        permissionKey: PERMISSIONS.ACCOUNTING.BANKS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.BANK,
        primaryEntity: true,
    },
    {
        path: 'bank/setup/bankAccount/:id',
        component: BankAccountForm,
        permissionKey: PERMISSIONS.ACCOUNTING.BANKS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
        entityType: ENTITY_TYPES.BANK,
        primaryEntity: true,
    },
    {
        path: '/accounting/reports/customerStatements',
        component: CustomerStatementGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.REPORTS.CUSTOMER_STATEMENTS.VIEW,
    },
];

export default AccountingRoutes;
