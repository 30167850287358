import { AgGridColumn } from 'ag-grid-react';
import React from 'react';
import Grid from '../../../components/grids/Grid';
import {
    FormControlLabel,
    FormGroup,
    Grid as MuiGrid,
    Switch,
} from '@mui/material';
import { GridReadyEvent } from 'ag-grid-community';
import ConfirmButton from '../../../components/apAutomation/ConfirmButton';
import { currencyFormatter } from '../../../utils/formattingUtils';
import usePoInformationTab from '../hooks/Tabs/usePoInformationTab';
import DatacorLogoSpinner from '../../../components/datacorLogoSpinner/DatacorLogoSpinner';

const PoInformationTab = ({ documentId }: any) => {
    const {
        shouldShowManualGrid,
        shouldShowButton,
        activeDocument,
        isLoading,
        unlinkPoColumn,
        linkPoColumn,
        rowData,
        showAll,
        handleClickShowAll,
        shouldLoad,
    } = usePoInformationTab(documentId);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px',
                }}>
                {shouldShowManualGrid && (
                    <div style={{ padding: '10px' }}>
                        <h4>Linked PO Receipts</h4>
                    </div>
                )}

                {shouldShowButton && (
                    <ConfirmButton
                        poStatus={activeDocument.poStatus}
                        documentId={documentId}
                    />
                )}
            </div>

            {!isLoading && (
                <Grid
                    testId="match-list-grid"
                    gridStyle={{ width: '90%' }}
                    immutableData={true}
                    dataKey="poNumber"
                    defaultColDef={{
                        resizable: true,
                        sortable: true,
                        unSortIcon: true,
                        filter: true,
                        wrapText: true,
                        floatingFilter: true,
                    }}
                    rowData={
                        activeDocument.purchaseOrderReceiptCosts
                            ? activeDocument.purchaseOrderReceiptCosts
                            : []
                    }
                    autofitColumns={true}
                    domLayout={'autoHeight'}
                    onCellClicked={(e: any) => (e.event.target.disabled = true)}
                    onGridReady={(params: GridReadyEvent) =>
                        params.api.sizeColumnsToFit()
                    }
                    frameworkComponents={{
                        unlinkPoColumn: unlinkPoColumn,
                    }}>
                    <AgGridColumn
                        width={70}
                        filter={false}
                        sortable={false}
                        field="unlinkPoColumn"
                        hide={!shouldShowManualGrid}
                        headerName=""
                        cellRenderer="unlinkPoColumn"
                    />
                    <AgGridColumn
                        field="poNumber"
                        headerName="PO Number"
                        filter="agTextColumnFilter"
                    />
                    <AgGridColumn
                        field="receiptNumber"
                        headerName="Receipt Number"
                        filter="agTextColumnFilter"
                    />
                    <AgGridColumn
                        field="erpCostCategory"
                        headerName="Cost Category"
                        filter="agTextColumnFilter"
                    />
                    <AgGridColumn
                        field="supplierName"
                        headerName="Supplier Name"
                        filter="agTextColumnFilter"
                    />
                    <AgGridColumn
                        field="costAmount"
                        headerName="Amount"
                        valueFormatter={currencyFormatter}
                        filter="agTextColumnFilter"
                    />
                    <AgGridColumn
                        field="currency"
                        headerName="Currency"
                        filter="agTextColumnFilter"
                    />
                </Grid>
            )}

            {shouldShowManualGrid && (
                <>
                    <div style={{ padding: '10px' }}>
                        <MuiGrid container spacing={2}>
                            <MuiGrid item xs={8}>
                                <h4>Unlinked PO Receipts</h4>
                            </MuiGrid>
                            <MuiGrid item xs={4}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                id="showAllPo"
                                                checked={showAll}
                                                onChange={handleClickShowAll}
                                            />
                                        }
                                        label="Show All PO Receipts"
                                    />
                                </FormGroup>
                            </MuiGrid>
                        </MuiGrid>
                    </div>
                    {!shouldLoad ? (
                        <Grid
                            testId="link-grid"
                            gridStyle={{ width: '90%' }}
                            rowData={rowData ? rowData : []}
                            onGridReady={(params: GridReadyEvent) =>
                                params.api.sizeColumnsToFit()
                            }
                            onCellClicked={(e: any) =>
                                (e.event.target.disabled = true)
                            }
                            autofitColumns={true}
                            defaultColDef={{
                                resizable: true,
                                sortable: true,
                                unSortIcon: true,
                                filter: true,
                                wrapText: true,
                                floatingFilter: true,
                            }}
                            frameworkComponents={{
                                linkPoColumn: linkPoColumn,
                            }}>
                            <AgGridColumn
                                width={70}
                                filter={false}
                                resizable={false}
                                sortable={false}
                                field="linkPoColumn"
                                headerName=""
                                cellRenderer="linkPoColumn"
                            />
                            <AgGridColumn
                                field="poNumber"
                                headerName="PO Number"
                                filter="agTextColumnFilter"
                            />
                            <AgGridColumn
                                field="receiptNumber"
                                headerName="Receipt Number"
                                filter="agTextColumnFilter"
                            />
                            <AgGridColumn
                                field="erpCostCategory"
                                headerName="Cost Category"
                                filter="agTextColumnFilter"
                            />
                            <AgGridColumn
                                field="supplierName"
                                headerName="Supplier Name"
                                filter="agTextColumnFilter"
                            />
                            <AgGridColumn
                                field="costAmount"
                                headerName="Amount"
                                valueFormatter={currencyFormatter}
                                filter="agTextColumnFilter"
                            />
                            <AgGridColumn
                                field="currency"
                                headerName="Currency"
                                filter="agTextColumnFilter"
                            />
                        </Grid>
                    ) : (
                        <DatacorLogoSpinner />
                    )}
                </>
            )}
        </>
    );
};

export default PoInformationTab;
