import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import { AgGridColumn } from 'ag-grid-react';
import {
    currencyFormatter,
    dateFormatter,
} from '../../../utils/formattingUtils';
import { numberAsStringComparator } from '../../../utils/comparisonUtils';
import BaseGrid from '../../grids/BaseGrid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DatacorLogoSpinner from '../../datacorLogoSpinner/DatacorLogoSpinner';
import ArchivedDocumentViewer from './ArchivedDocumentViewer';
import useArchivedInvoiceModal from './hooks/useArchivedInvoiceModal';
import { UserList } from '../../../utils/users/userList';

interface ArchivedInvoicesModalProps {
    handleClose: () => void;
    open: boolean;
}
const ArchivedInvoicesModal = ({
    handleClose,
    open,
}: ArchivedInvoicesModalProps) => {
    const {
        handleClick,
        archivedInvoices,
        isLoadingArchived,
        selectedDocument,
        setSelectedDocument,
        userList,
    } = useArchivedInvoiceModal();

    return (
        <Dialog onClose={handleClose} open={open} maxWidth={'xl'} fullWidth>
            <DialogTitle>
                {selectedDocument && (
                    <IconButton>
                        <ArrowBackIcon
                            onClick={() => setSelectedDocument(null)}
                        />
                    </IconButton>
                )}
                Export Archived Invoices
            </DialogTitle>
            <DialogContent>
                {selectedDocument ? (
                    <ArchivedDocumentViewer document={selectedDocument} />
                ) : (
                    <>
                        {!isLoadingArchived ? (
                            <BaseGrid
                                testId="archived-list-grid"
                                dataKey="id"
                                displayGrid={true}
                                immutableData={true}
                                archiveGrid={true}
                                displayExportDataButton={true}
                                rowData={archivedInvoices}
                                onRowClicked={(row: any) =>
                                    handleClick(row.data)
                                }
                                displayToolbar={true}
                                displayCreateNewButton={false}
                                defaultColDef={{
                                    resizable: true,
                                    sortable: true,
                                    unSortIcon: true,
                                    filter: true,
                                    wrapText: true,
                                }}>
                                <AgGridColumn
                                    minWidth={250}
                                    field="uploader"
                                    valueFormatter={(val) =>
                                        new UserList(
                                            userList
                                        ).getUsersFullNameFromEmail(
                                            val.value,
                                            true
                                        )
                                    }
                                    headerName="Uploader"
                                    filter="agTextColumnFilter"
                                />
                                <AgGridColumn
                                    minWidth={150}
                                    field="invoiceNumber"
                                    headerName="Invoice Number"
                                    filter="agTextColumnFilter"
                                />
                                <AgGridColumn
                                    minWidth={125}
                                    field="supplierNumber"
                                    headerName="Supplier Number"
                                    filter="agTextColumnFilter"
                                />
                                <AgGridColumn
                                    minWidth={200}
                                    field="supplierName"
                                    headerName="Supplier Name"
                                    filter="agTextColumnFilter"
                                />
                                <AgGridColumn
                                    minWidth={150}
                                    field="due"
                                    headerName="Amount"
                                    valueFormatter={currencyFormatter}
                                    comparator={numberAsStringComparator}
                                    filter="agTextColumnFilter"
                                />
                                <AgGridColumn
                                    minWidth={175}
                                    field="invoiceDate"
                                    headerName="Invoice Date"
                                    filter="agTextColumnFilter"
                                    valueFormatter={dateFormatter}
                                />
                                <AgGridColumn
                                    minWidth={175}
                                    field="dueDate"
                                    headerName="Due Date"
                                    filter="agTextColumnFilter"
                                    valueFormatter={dateFormatter}
                                />
                                <AgGridColumn
                                    minWidth={150}
                                    field="poNumber"
                                    headerName="PO Number"
                                    filter="agTextColumnFilter"
                                />
                                <AgGridColumn
                                    minWidth={150}
                                    field="poStatus.value"
                                    headerName="PO Status"
                                    filter="agSetColumnFilter"
                                />
                                <AgGridColumn
                                    minWidth={175}
                                    field="approvalStatus.value"
                                    headerName="Invoice Status"
                                    filter="agSetColumnFilter"
                                />
                                <AgGridColumn
                                    minWidth={150}
                                    field="voucherNumber"
                                    headerName="Voucher Number"
                                    filter="agTextColumnFilter"
                                />
                            </BaseGrid>
                        ) : (
                            <DatacorLogoSpinner />
                        )}
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ArchivedInvoicesModal;
