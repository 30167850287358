export const themeConfig = {
    PRIMARY: {
        main: '#007CBB',
        light: '#007CBB',
        dark: '#007CBB',
    },
    SECONDARY: {
        main: '#00A894',
        light: '#00A894',
        dark: '#007CBB',
    },
    ERROR: {
        main: '#BB0000',
        light: '#BB0000',
        dark: '#007CBB',
    },
    INFO: {
        main: '#007CBB',
        light: '#007CBB',
        dark: '#007CBB',
    },
    SUCCESS: {
        main: '#078F47',
        light: '#078F47',
        dark: '#007CBB',
    },
    HEADER_NAV: {
        MENU_ICONS: {
            main: '#3CB4E5',
            light: '#3CB4E5',
            dark: '#007CBB',
        },
    },
};

declare module '@mui/material/styles' {
    interface Palette {
        headerNavIcons: {
            main: string;
            light: string;
        };
    }
    // allow configuration using `createTheme`
    interface PaletteOptions {
        headerNavIcons?: {
            main?: string;
            light?: string;
        };
    }
}
