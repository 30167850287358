import React, { useContext } from 'react';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import './LoaderButton.css';
import { ButtonProps } from '@mui/material/Button';
import SharedTranslationsContext from '../contexts/translations.context';

interface LoaderButtonProps extends ButtonProps {
    /** determines whether to show the button or progress bar */
    isLoading?: boolean;
    className?: string;
    /** onClick handler for button action */
    onClick?: any;
    disabled?: boolean;
    children?: any;
    endIcon?: any;
    termKey?: string;
    // if set, will use this termset to get the tooltip text rather than the sharedTranslations
    tooltipTermSet?: any;
}

export default function LoaderButton({
    isLoading,
    size = 'small',
    className = '',
    onClick = null,
    disabled = false,
    type = 'button',
    termKey,
    tooltipTermSet,
    ...props
}: LoaderButtonProps) {
    const { sharedTranslations } = useContext(SharedTranslationsContext);
    const getTooltipText = () => {
        const childTermKey = termKey;
        const childTermSet: any = tooltipTermSet ?? sharedTranslations;

        let termString = null;
        if (childTermKey) {
            termString = childTermSet?.languageTermSetDef?.termDefs?.find(
                (term: any) => term.code === childTermKey + '-helper'
            )?.defaultValue;
        }

        if (disabled && childTermKey) {
            termString = childTermSet?.languageTermSetDef?.termDefs?.find(
                (term: any) => term.code === childTermKey + '-helper-disabled'
            )?.defaultValue;
        }

        return termString;
    };

    return (
        <Tooltip title={getTooltipText()} arrow>
            <span>
                <Button
                    className={`LoaderButton ${className}`}
                    onClick={onClick}
                    disabled={disabled || isLoading}
                    type={type ? type : null}
                    {...props}>
                    {isLoading ? (
                        <CircularProgress size={20} />
                    ) : (
                        <>{props.children}</>
                    )}
                </Button>
            </span>
        </Tooltip>
    );
}
