import React from 'react';
import './PricingConcernDetails.css';
import PricingConcernDetailsGrid from './PricingConcernDetailsGrid';
import PricingConcernDetailFilterBy from './PricingConcernDetailFilterBy';
import Breadcrumbs from '../Breadcrumbs';
import LoaderButton from '../../../components/LoaderButton';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { Grid, Card, CardContent, CardHeader } from '@mui/material';
import usePricingConcernDetails from '../hooks/PricingConcernDetails/usePricingConcernDetails';

const PricingConcernDetails = () => {
    const { gotoProposalReport, pricingAnalysisToolSet } =
        usePricingConcernDetails();

    return (
        <Card>
            <CardHeader
                title={
                    <>
                        <Breadcrumbs active={'pricingConcernDetail'} />
                    </>
                }
            />
            <CardContent>
                <div className="appPage">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <PricingConcernDetailFilterBy />
                        </Grid>

                        <Grid item xs={12}>
                            <PricingConcernDetailsGrid />
                        </Grid>
                        <Grid item xs={6}>
                            <LoaderButton
                                isLoading={null}
                                variant="contained"
                                style={{ margin: '10px' }}
                                onClick={() => gotoProposalReport()}
                                disabled={isNilOrEmpty(
                                    pricingAnalysisToolSet.selectedCustomerProductDetails
                                )}>
                                Build Proposal Report
                            </LoaderButton>
                        </Grid>
                    </Grid>
                </div>
            </CardContent>
        </Card>
    );
};

export default PricingConcernDetails;
