import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
interface SummaryPricingConcernCardProps {
    activeCumulativeSummary: any;
    alertType: any;
    title?: string;
    handleCheck?: any;
}
const useSummaryPricingConcernCard = ({
    activeCumulativeSummary,
    alertType,
}: SummaryPricingConcernCardProps) => {
    const { pricingAnalysisToolSet } = useSelector(
        (state: RootState) => state.pricingAnalysisTool
    );
    const checked =
        pricingAnalysisToolSet.pricingConcernSelectedCategories.includes(
            alertType
        );

    const dollarsLostStyle =
        activeCumulativeSummary[`${alertType}DollarsLost`] < 0
            ? { backgroundColor: '#9dd9f2', color: 'red' }
            : { backgroundColor: '#9dd9f2' };
    return { dollarsLostStyle, checked };
};
export default useSummaryPricingConcernCard;
