import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormFields } from '../../../libs/hooksLib';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { login } from '../../../store/user';
import { RootState } from '../../../store';
import { showNotificationError } from '../../../libs/errorLib';
import {
    useCreateAuthenticationRequestLogMutation,
    useUpdateAuthenticationRequestWithErrorMutation,
} from '../../../services/access/publicAccess.service';
import { isNull } from 'lodash';

const useLogin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const [createAuthenticationRequestLog] =
        useCreateAuthenticationRequestLogMutation();
    const [fields, handleFieldChange] = useFormFields({
        email: '',
        password: '',
    });

    useEffect(() => {
        if (user.requirePasswordChange) {
            navigate('/login/newPasswordRequired');
        }
    }, [user.requirePasswordChange, history]);

    function validateForm() {
        return !isNilOrEmpty(fields.email) && !isNilOrEmpty(fields.password);
    }

    const loginAction = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        try {
            await createAuthenticationRequestLog({
                postBody: {
                    forApplicationCode: 'plat',
                    username: fields.email,
                },
            });

            await dispatch(login(fields));
        } catch (e) {
            return showNotificationError(e.message, 'error', dispatch);
        }
    };

    return { user, fields, handleFieldChange, loginAction, validateForm };
};

export default useLogin;
