import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../config';
import { Auth } from 'aws-amplify';
import { datacorAppApiTagTypes } from './tagTypes';

// initialize an empty api service that we'll inject endpoints into later as needed
export const datacorAppApi = createApi({
    reducerPath: 'datacorAppApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiGateway.URL,
        prepareHeaders: async (headers, { getState }) => {
            const token = (await Auth.currentSession())
                .getIdToken()
                .getJwtToken();
            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('Authorization', token);
            }

            headers.set('Accept-Encoding', 'gzip');

            return headers;
        },
    }),
    tagTypes: datacorAppApiTagTypes,
    endpoints: () => ({}),
});

export const datacorPublicAppApi = createApi({
    reducerPath: 'datacorPublicAppApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiGateway.URL,
    }),
    tagTypes: datacorAppApiTagTypes,
    endpoints: () => ({}),
});
