import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormFields } from '../../../libs/hooksLib';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { Auth } from 'aws-amplify';
import { showNotificationError } from '../../../libs/errorLib';
import { useDispatch } from 'react-redux';
import { getDefaultMessageFromError } from '../../../utils/error/errorMessageUtils';

const useResetPasswordConfirmation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [fields, handleFieldChange] = useFormFields({
        code: '',
        email: '',
        password: '',
        confirmPassword: '',
    });
    const [isConfirming, setIsConfirming] = useState(false);

    function validateResetForm() {
        return (
            !isNilOrEmpty(fields.code) &&
            !isNilOrEmpty(fields.email) &&
            !isNilOrEmpty(fields.password) &&
            fields.password === fields.confirmPassword
        );
    }

    const handleConfirmClick = async (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();

        setIsConfirming(true);

        try {
            await Auth.forgotPasswordSubmit(
                fields.email,
                fields.code,
                fields.password
            );
            navigate('/login/resetSuccess');
        } catch (error) {
            const errorDisplayMessage = getDefaultMessageFromError(error);
            showNotificationError(errorDisplayMessage, 'error', dispatch);
            setIsConfirming(false);
        }
    };

    return {
        fields,
        handleFieldChange,
        handleConfirmClick,
        isConfirming,
        validateResetForm,
    };
};

export default useResetPasswordConfirmation;
