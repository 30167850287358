import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isNil, isNilOrEmpty } from '../../../../utils/objectUtils';
import {
    filterTransactionsByWarehouse,
    getTransactionsByConcernAndDateRange,
    updateSelectedPricingConcerns,
} from '../../../../store/pricingAnalysisTool';
import { RootState } from '../../../../store';

const usePricingConcernDetailFilterBy = () => {
    const [showAlert, setShowAlert] = useState(false);
    const dispatch = useDispatch();
    const { pricingAnalysisToolSet } = useSelector(
        (state: RootState) => state.pricingAnalysisTool
    );
    const [value, setValue] = useState(
        pricingAnalysisToolSet.selectedWarehouse
    );
    const initialRender = useRef(true);

    useEffect(() => {
        const getTransactionDataWithNewCriteria = () => {
            if (
                moment(pricingAnalysisToolSet.summaryStartDate.rawDate) <
                    moment(pricingAnalysisToolSet.summaryAsOfDate.rawDate) &&
                !isNilOrEmpty(
                    pricingAnalysisToolSet.pricingConcernSelectedCategories
                )
            ) {
                setShowAlert(false);
                dispatch(
                    getTransactionsByConcernAndDateRange(
                        pricingAnalysisToolSet.summaryStartDate.rawDate,
                        pricingAnalysisToolSet.summaryAsOfDate.rawDate,
                        pricingAnalysisToolSet.pricingConcernSelectedCategories
                    )
                );
            } else {
                setShowAlert(true);
            }
        };

        if (initialRender.current) {
            if (isNil(pricingAnalysisToolSet.activePricingConcernDetails)) {
                getTransactionDataWithNewCriteria();
            }
            initialRender.current = false;
        } else {
            getTransactionDataWithNewCriteria();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pricingAnalysisToolSet.pricingConcernSelectedCategories,
        pricingAnalysisToolSet.summaryAsOfDate.rawDate,
        pricingAnalysisToolSet.summaryStartDate.rawDate,
    ]);

    const handlePricingConcernChecks = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        dispatch(updateSelectedPricingConcerns(event.target));
    };

    const handleChange = (e: any) => {
        setValue(e.target.value);
        dispatch(filterTransactionsByWarehouse(e.target.value));
    };
    return {
        handleChange,
        handlePricingConcernChecks,
        value,
        showAlert,
        dispatch,
        pricingAnalysisToolSet,
    };
};

export default usePricingConcernDetailFilterBy;
