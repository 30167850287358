import { ISupplierNav } from '../../types/Supplier.types';
import { ICustomerNav } from '../../types/supply-chain/customer/CustomerAddress.type';

export const SupplierNavValues = {
    supplierNav: {
        supplierId: null,
    } as ISupplierNav,
    customerNav: {
        customerId: null,
    } as ICustomerNav,
};
