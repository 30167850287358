/**
 * all of our editable grid fields we have on the
 * accounting module
 * we can add or remove fields here as we need them
 */

export const JOURNAL_LINES_FIELDS = {
    id: 'ID',
    number: 'Line No',
    accountNumber: 'Account',
    description: 'Description',
    functionalDebit: 'Debit Amount',
    functionalCredit: 'Credit Amount',
    foreignDebit: 'Foreign Debit Amount',
    foreignCredit: 'Foreign Credit Amount',
};

export const RECURRING_JOURNAL_LINES_FIELDS = {
    id: 'ID',
    number: 'Line No',
    accountNumber: 'Account',
    description: 'Description',
    functionalDebit: 'Functional Debit',
    functionalCredit: 'Functional Credit',
};

export const SUPPLIER_INVOICE_LINES_FIELDS = {
    lineNumber: 'Line No',
    itemId: 'Item',
    description: 'Description',
    quantity: 'Quantity',
    functionalUnitPrice: 'Functional Unit Price',
    functionalAmount: 'Functional Amount',
    foreignUnitPrice: 'Foreign Unit Price',
    foreignAmount: 'Foreign Amount',
};

export const CUSTOMER_INVOICE_LINES_FIELDS = {
    lineNumber: 'Line No',
    itemId: 'Item',
    description: 'Description',
    quantity: 'Quantity',
    functionalUnitPrice: 'Functional Unit Price',
    functionalAmount: 'Functional Amount',
    foreignUnitPrice: 'Foreign Unit Price',
    foreignAmount: 'Foreign Amount',
};

export const PAYMENT_LINES_FIELDS = {
    lineNumber: 'Line No',
    accountNumber: 'Account',
    functionalDebitAmount: 'Functional Debit Amount',
    functionalCreditAmount: 'Functional Credit Amount',
    foreignDebitAmount: 'Foreign Debit Amount',
    foreignCreditAmount: 'Foreign Credit Amount',
};
