//#region Imports
import BaseGrid from '../../../components/grids/BaseGrid';
import React from 'react';
import useTranslation from '../../../components/i18n/hooks/useTranslation';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import useDate from '../../../utils/hooks/useDate';
import CustomHeader from '../../../components/grids/CustomHeader';
import { JobLogsBasicGridDefs } from '../../../constants/i18n/translations/termDefinitions/platform';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
//#endregion Imports

const JobLogsBasicGrid = (props: any) => {
    // #region Hooks
    const { termSet } = useTranslation(PLATFORM_DEFS.JOB_LOGS_BASIC_GRID);
    const logData = props?.data;

    const { dateTimeFormatter } = useDate();
    // #endregion Hooks

    // #region columnDefs
    const colDefs = [
        {
            field: 'loggedAt',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={JobLogsBasicGridDefs.LoggedAt}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
            valueFormatter: dateTimeFormatter,
        },
        {
            field: 'messageClass',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={JobLogsBasicGridDefs.MessageType}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'message',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={JobLogsBasicGridDefs.Message}
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
    ];

    // #endregion columnDefs

    // #region JSX
    return (
        <>
            {!isNilOrEmpty(logData) && (
                <BaseGrid
                    gridKey={'JobLogBasic'}
                    displayGrid={true}
                    displayToolbar={false}
                    gridClass="full-size-item"
                    rowData={logData}
                    columnDefs={colDefs}
                />
            )}
        </>
    );

    // #endregion JSX
};

export default JobLogsBasicGrid;
