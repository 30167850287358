import React, { ChangeEvent } from 'react';
import InputBase, { InputBaseProps } from '../InputBase/InputBase';
import {
    Checkbox,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
} from '@mui/material';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import { SelectionOption } from '../../../../types/Shared.types';
import InputAdornment from '@mui/material/InputAdornment';
import Clear from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import useFormControlProps from '../hooks/useFormControlProps';
import { isNil } from 'lodash';

export type SelectInputProps = {
    options?: SelectionOption[];
    showEditButton?: boolean;
    handleEdit?: (_: any, __: any) => void;
    disabledOptions?: number[] | bigint[];
    getOptionsApi?: any;
    SelectProps?: {
        multiple?: boolean;
        showClearButton?: boolean;
        readOnly?: boolean;
        renderValue?: any;
    };
    hiddenOption?: boolean;
} & InputBaseProps;

const SelectInput = ({ getOptionsApi, ...props }: SelectInputProps) => {
    let { formMethods, handleFieldChange, label, value } =
        useFormControlProps(props);

    let optionsResponse: any = null;

    if (getOptionsApi) {
        optionsResponse = getOptionsApi();
    }

    const optionSelected = (selectedOption: any) => {
        value = selectedOption.target.value;

        const changEvent = {
            target: { id: props.id, value: selectedOption.target.value },
        } as unknown as ChangeEvent<HTMLInputElement>;

        handleFieldChange(changEvent);
        if (props.onChange) {
            props.onChange(changEvent);
        }
    };

    const getNullStateValue = (): any => {
        if (props.SelectProps?.multiple) {
            return [];
        }

        return '';
    };

    const clearSelectedValue = () => {
        value = props.SelectProps?.multiple ? [] : null;
        optionSelected({
            target: {
                value: props.SelectProps?.multiple ? [] : null,
            },
        });
    };

    const getClearButton = () => {
        if (props.SelectProps?.showClearButton && !isNilOrEmpty(value)) {
            return (
                <InputAdornment
                    position="end"
                    sx={{ marginRight: '10px', cursor: 'pointer' }}
                    onClick={() => clearSelectedValue()}>
                    <Clear />
                </InputAdornment>
            );
        }
        return <></>;
    };

    const getOptions = () => {
        return !isNilOrEmpty(optionsResponse)
            ? optionsResponse.data
            : props.options;
    };

    const isDisabled = () => {
        const disable = isNil(props.disabled) ? false : props.disabled;
        if (getOptionsApi && optionsResponse) {
            return optionsResponse.isLoading || disable;
        }

        return disable;
    };

    return (
        <InputBase
            {...props}
            disabled={isDisabled()}
            select
            InputProps={{
                endAdornment: getClearButton(),
            }}
            value={value || getNullStateValue()}
            label={label}
            formMethods={formMethods}
            onChange={(selectedOption) => optionSelected(selectedOption)}>
            {!isNilOrEmpty(getOptions()) ? (
                getOptions()?.map((option: SelectionOption) => (
                    <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={option.hiddenOption ? { display: 'none' } : {}}
                        disabled={props.disabledOptions?.some(
                            (disabledOption) => disabledOption === option.value
                        )}>
                        {props.SelectProps?.multiple ? (
                            <Checkbox
                                sx={{ paddingTop: 0, paddingBottom: 0 }}
                                size={'small'}
                                checked={value?.indexOf(option.value) > -1}
                                disabled={props.disabledOptions?.some(
                                    (disabledOption) =>
                                        disabledOption === option.value
                                )}
                            />
                        ) : null}
                        {props.showEditButton ? (
                            <ListItem dense>
                                <ListItemText primary={option.label} />
                                <ListItemIcon
                                    onClick={(event: any) =>
                                        props.handleEdit(event, option.value)
                                    }>
                                    <EditIcon />
                                </ListItemIcon>
                            </ListItem>
                        ) : (
                            option.label
                        )}
                    </MenuItem>
                ))
            ) : (
                <MenuItem key="placeholder" value="" />
            )}
        </InputBase>
    );
};

export default SelectInput;
