import { User, Zone, Company } from '../../types/User.types';
import { UserList } from '../../utils/users/userList';
import { datacorAppApi } from '../datacorAppApi';

export const usersAPI = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        getUserById: build.query<User, string>({
            query: (userId) => `/users/getUserById/${userId}`,
            providesTags: ['User'],
        }),
        getAllUsers: build.query({
            query: (userId) => `/users/${userId}`,
            providesTags: ['User'],
            transformResponse: (response) => new UserList(response),
        }),
        getZones: build.query<any, void>({
            query: () => '/users/zonesList',
            providesTags: ['Zones'],
        }),
        getZonesForCompanies: build.mutation({
            query: (companyIds) => ({
                url: `/users/getZonesForCompanies`,
                method: 'POST',
                body: { companyIds: companyIds },
            }),
        }),
        getCompaniesByUserId: build.query<Company[], string>({
            query: (userId) => `/users/companies/${userId}`,
            providesTags: ['Company'],
        }),
        deleteUserLegacy: build.mutation<
            any,
            { username: string; userPoolId: string }
        >({
            query: (user) => ({
                url: '/users',
                method: 'DELETE',
                body: user,
            }),
            invalidatesTags: ['User'],
        }),
        updateUserStatus: build.mutation<any, { userStatus: string }>({
            query: (user) => ({
                url: '/users/updateUserStatus',
                method: 'POST',
                body: user,
            }),
            invalidatesTags: ['User'],
        }),
        createZone: build.mutation<any, { zone: Zone; companyId: string }>({
            query: (zone) => ({
                url: '/users/createZone',
                method: 'POST',
                body: zone,
            }),
            invalidatesTags: ['Zones'],
        }),
        createCompany: build.mutation<any, Company>({
            query: (company) => ({
                url: '/users/createCompany',
                method: 'POST',
                body: company,
            }),
            invalidatesTags: ['Company'],
        }),
        applyCompanyAndZone: build.mutation({
            query: (company) => ({
                url: '/users/updateUserCompanyAndZone',
                method: 'POST',
                body: company,
            }),
            invalidatesTags: ['Company'],
        }),
        getAuditDataByKey: build.query({
            query: (args) => ({
                url: `/getAuditDataByKey/${args.companyId}/${args.actionType}/${args.auditTrailKey}`,
                method: 'GET',
            }),
            transformResponse: (response: any) =>
                [...response].sort((a, b) => {
                    const dateA: any = new Date(a.dateCreated);
                    const dateB: any = new Date(b.dateCreated);
                    return dateB - dateA;
                }),
            providesTags: ['Audit'],
        }),
    }),
});

export const {
    useGetUserByIdQuery,
    useGetAllUsersQuery,
    useGetZonesQuery,
    useGetZonesForCompaniesMutation,
    useGetCompaniesByUserIdQuery,
    useDeleteUserLegacyMutation,
    useUpdateUserStatusMutation,
    useCreateZoneMutation,
    useCreateCompanyMutation,
    useApplyCompanyAndZoneMutation,
    useGetAuditDataByKeyQuery,
} = usersAPI;
