import React, { useMemo } from 'react';
import LoaderButton from '../../LoaderButton';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import EntityTaskModal from './EntityTaskModal';

const BaseTaskButton = ({ taskType }: any) => {
    const [openNewTaskModal, setOpenNewTaskModal] = React.useState(false);

    const handleClick = () => {
        setOpenNewTaskModal(true);
    };

    const handleEntityTaskModal = useMemo(() => {
        return (
            <EntityTaskModal
                open={openNewTaskModal}
                setOpen={setOpenNewTaskModal}
                taskType={taskType}
            />
        );
    }, [openNewTaskModal, taskType]);

    return (
        <>
            {handleEntityTaskModal}
            <LoaderButton
                style={{ minWidth: '175px' }}
                onClick={handleClick}
                className="form-header-button"
                variant="text">
                <AssignmentTurnedInIcon />
                <div className="form-header-button-text">
                    {taskType?.actionTitle}
                </div>
            </LoaderButton>
        </>
    );
};

export default BaseTaskButton;
