import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

const useGridDrawer = ({ setOpen }: any) => {
    const theme = useTheme();

    const user = useSelector((state: RootState) => state.user);

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return {
        handleDrawerClose,
        theme,
        user,
    };
};

export default useGridDrawer;
