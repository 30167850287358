import {
    AttachmentFolderForm,
    AttachmentFolderGrid,
    AttachmentsForm,
    AttachmentsSearchForm,
    BuildingsForm,
    BuildingsGrid,
    BusinessEntityForm,
    BusinessEntityGrid,
    BusinessEntitySettings,
    DepartmentCodesForm,
    DepartmentCodesGrid,
    FeatureFlagsGrid,
    GroupsForm,
    GroupsGrid,
    JobLogsBasicGrid,
    JobRunGrid,
    JobStartedModal,
    LanguagesGrid,
    PermissionsGrid,
    RevisionHistoryForm,
    RolesForm,
    RolesGrid,
    SharedComponents,
    SubscriberForm,
    SubscriberGrid,
    UserForm,
    UserGrid,
    UserSettings,
    AuthErrorMessages,
    AccessRestrictions,
    TaskManager,
    EquationBuilderForm,
} from './termDefs.types';

export const UserFormDefs: UserForm = {
    Create_New_User: 'user-create-new-user-text',
    Primary_Information: 'user-primary-information-text',
    Cancel: 'user-cancel-button',
    Add: 'user-add-button',
    Update: 'user-update-button',
    Delete: 'user-delete-button',
    History: 'user-history-button',
    Status: 'user-status',
    First_Name: 'user-first-name',
    Middle_Name: 'user-middle-name',
    Last_Name: 'user-last-name',
    Email: 'user-email',
    Phone: 'user-phone',
    Messaging_Preference: 'user-messaging-preference',
    External_Username: 'user-external-username',
    Datacor_Admin: 'user-datacor-admin',
    App_Stream: 'user-app-stream',
    AP_Automation: 'user-ap-automation-text',
    Invoice_Target: 'user-invoice-target',
    Groups_and_Roles: 'user-groups-and-roles-text',
    Groups: 'user-groups',
    Roles: 'user-roles',
    User_Trial: 'user-user-trial-text',
    Set_Up_User_With_Trial_Period: 'user-set-up-user-with-trial-period',
    Start_Date: 'user-start-mm/dd/yyyy',
    End_Date: 'user-end-mm/dd/yyyy',
    Subscribers_Text: 'user-subscribers-text',
    Subscribers: 'user-subscribers',
    Business_Entity: 'user-business-entity-text',
    Department_Code_ID: 'user-department-code-id',
    Supervisor_ID: 'user-supervisor-id',
};

export const UserGridDefs: UserGrid = {
    First_Name: 'user-grid-first-name',
    Last_Name: 'user-grid-last-name',
};

export const UserSettingsDefs: UserSettings = {
    SETTINGS_HEADER: 'user-settings-header',
    DARK_MODE: 'user-settings-dark-mode-switch',
    DEFAULT_PAGE: 'user-settings-default-page',
    LANGUAGE: 'user-settings-lang',
    LOGIN_HEADER: 'user-settings-login-settings-header',
    CHANGE_PASSWORD: 'user-settings-change-password-button',
    CHANGE_EMAIL: 'user-settings-change-email-button',
};

export const BusinessEntitySettingDefs: BusinessEntitySettings = {
    DEFAULT_LANGUAGE: 'business-entity-settings-default-language',
    TIME_ZONE: 'business-entity-settings-time-zone',
    TIME_FORMAT: 'business-entity-settings-time-format',
    DATE_FORMAT: 'business-entity-settings-date-format',
    LONG_DATE_FORMAT: 'business-entity-settings-long-date-format',
    NUMBER_FORMAT: 'business-entity-settings-number-format',
    DISPLAY_PREFERENCE: 'business-entity-settings-display-preference',
    ERP_START: 'business-entity-settings-erp-start',
    DEFAULT_COUNTRY: 'business-entity-settings-default-country',
    DEFAULT_STATE: 'business-entity-settings-default-state',
    TITLE: 'business-entity-settings-title',
    FUNCTIONAL_CURRENCY_CODE: 'business-entity-settings-functionalCurrencyCode',
};
export const SubscriberFormDefs: SubscriberForm = {
    Create_New_Subscriber: 'subscriber-create-new-subscriber-text',
    Name: 'subscriber-name',
    Primary_Information: 'subscriber-primary-information-text',
    Cancel_Button: 'subscriber-cancel-button',
    Update_Button: 'subscriber-update-button',
    History_Button: 'subscriber-history-button',
    Add_Button: 'subscriber-add-button',
    Is_Datacor: 'subscriber-is-datacor',
    Department_Codes: 'subscriber-department-codes-text',
    Business_Entity: 'subscriber-business-entity-text',
    Department_Name: 'subscriber-form-department-name',
    Department_Code: 'subscriber-form-department-code',
    View_Business_Entity: 'subscriber-view-business-entity',
};

export const SubscriberGridDefs: SubscriberGrid = {
    Title: 'subscriber-grid-title',
    Subscriber_Name: 'subscriber-subscriber-name',
    Subscriber_Description: 'subscriber-description',
    Subscriber_Date_Created: 'subscriber-date-created',
    Subscriber_Last_Update_Date: 'subscriber-last-update-date',
    Subscriber_Add_New: 'subscriber-add-new',
    View_Business_Entities: 'subscriber-view-business-entities',
};
export const BusinessEntityGridDefs: BusinessEntityGrid = {
    Title: 'business-entity-grid-title',
    View_Business_Entities: 'business-entity-grid-view-business-entities',
    Name: 'business-entity-grid-name',
    Description: 'business-entity-grid-description',
    Subscriber: 'business-entity-grid-subscriber',
    Date_Created: 'business-entity-grid-date-created',
    Last_Update_Date: 'business-entity-grid-last-update-date',
};

export const BusinessEntityFormDefs: BusinessEntityForm = {
    Create_New_Business_Entity: 'business-entity-create-new-business-entity',
    Name: 'business-entity-name',
    Description: 'business-entity-description',
    Type: 'business-entity-type',
    Parent_Business_Entity: 'business-entity-parent-business-entity',
    Enable_External_API_Access: 'business-entity-enable-external-api-access',
    Zone_Information: 'business-entity-zone-information',
    Platform: 'business-entity-platform',
    URL: 'business-entity-url',
    Port: 'business-entity-port',
};
export const BuildingsFormDefs: BuildingsForm = {
    Create_New_Building: 'buildings-create-new-building',
    Building_Code: 'buildings-form-building-code',
    Building_Name: 'buildings-form-building-name',
    Supplier_Code: 'buildings-form-supplier-code',
    Customer_Code: 'buildings-form-customer-code',
    Note: 'buildings-form-note',
    Address_Information: 'buildings-address-information',
    Country: 'buildings-country',
    Zip: 'buildings-zip',
    State: 'buildings-form-state',
    Street: 'buildings-form-street',
    County: 'buildings-form-county',
    City: 'buildings-form-city',
};

export const BuildingsGridDefs: BuildingsGrid = {
    code: 'buildings-building-code',
    name: 'buildings-building-name',
    supplierId: 'buildings-supplier-code',
    customerId: 'buildings-customer-code',
    ['address.street']: 'buildings-street',
    ['address.city']: 'buildings-city',
    ['address.county']: 'buildings-county',
    ['address.stateCode.name']: 'buildings-state',
    ['address.postalCode']: 'buildings-postal-code',
    ['address.countryCode.name']: 'buildings-country-code',
    note: 'buildings-note',
};
export const RolesGridDefs: RolesGrid = {
    Name: 'roles-grid-name',
    Description: 'roles-grid-description',
    ['businessEntity.name']: 'roles-grid-business-entity',
    createdAt: 'roles-grid-date-created',
    updatedAt: 'roles-grid-last-update-date',
};

export const GroupsGridDefs: GroupsGrid = {
    name: 'groups-group-name',
    description: 'groups-grid-description',
    ['businessEntity.name']: 'groups-business-entity',
    createdAt: 'groups-date-created',
    updatedAt: 'groups-last-update-date',
};
export const RolesFormDefs: RolesForm = {
    Name: 'roles-form-name',
    Description: 'roles-form-description',
    Users: 'roles-form-users',
    Permissions: 'roles-form-permissions',
    Create_New_Role: 'roles-form-create-new-role',
};

export const GroupsFormDefs: GroupsForm = {
    Create_New_Group: 'groups-create-new-group',
    Name: 'groups-name',
    Description: 'groups-form-description',
    Users: 'groups-users',
};
export const PermissionsGridDefs: PermissionsGrid = {
    Name: 'permissions-name',
    Description: 'permissions-description',
    ['businessEntity.name']: 'permissions-business-entity',
    createdAt: 'permissions-date-created',
    updatedAt: 'permissions-last-update-date',
};

export const RevisionHistoryFormDefs: RevisionHistoryForm = {
    Users: 'revision-history-form-users',
    Start_Date: 'revision-history-form-start',
    End_Date: 'revision-history-form-end',
    Actions: 'revision-history-form-actions',
    Sources: 'revision-history-form-sources',
    Modules: 'revision-history-form-modules',
    Date_Created: 'revision-history-form-date-created',
    User: 'revision-history-form-user',
    Revision_Action: 'revision-history-form-revision-action',
    Entity: 'revision-history-form-entity',
    Module: 'revision-history-form-module',
    Revision_Source: 'revision-history-form-revision-source',
    View_Items: 'revision-history-form-review-items',
    Search: 'revision-history-form-search',
};

export const AttachmentsSearchFormDefs: AttachmentsSearchForm = {
    Created_By_User: 'attachments-search-form-created-by-user',
    Created_Start_Date: 'attachments-search-form-created-start-date',
    Created_End_Date: 'attachments-search-form-created-end-date',
    Entity_Type: 'attachments-search-form-entity-type',
    Entity_Descriptor: 'attachments-search-form-entity-descriptor',
    Attachments_Source: 'attachments-search-form-attachment-source',
    Entity_Uuid: 'attachments-search-form-entity-uuid',
    Search: 'attachments-search-form-search',
    Created_Date: 'attachments-search-form-created-date',
    Extension: 'attachments-search-form-extension',
    Attachment_Name: 'attachments-search-form-attachment-name',
};

export const AttachmentsFormDefs: AttachmentsForm = {
    Title: 'attachments-form-title',
    Attachment_Name: 'attachments-form-attachment-name',
    Attachment_Source: 'attachments-form-attachment-source',
    Entity_Type: 'attachments-form-entity-type',
    Business_Entity: 'attachments-form-business-entity',
    Extension: 'attachments-form-extension',
    Controlled: 'attachments-form-controlled',
    Versioned: 'attachments-form-versioned',
    File_Deleted: 'attachments-form-file-deleted',
    Alias: 'attachments-form-alias',
    Notes: 'attachments-form-notes',
    Attachment_Control: 'attachments-form-attachment-control',
    Control_Number: 'attachments-form-attachment-control-number',
    Review_Schedule_Days:
        'attachments-form-attachment-control-review-schedule-days',
    Author_Notes: 'attachments-form-attachment-control-author-notes',
    Approvers_User_Ids:
        'attachments-form-attachment-control-approvers-user-ids',
    Approvers_Group_Id:
        'attachments-form-attachment-control-approvers-group-id',
    Distribution_User_Ids:
        'attachments-form-attachment-control-distribution-user-ids',
    Distribution_Group_Id:
        'attachments-form-attachment-control-distribution-group-id',
    Locked: 'attachments-form-attachment-control-locked',
    Checked_Out_By_User_Id:
        'attachments-form-attachment-control-checked-out-by-user-id',
    Attachment_Permission: 'attachments-form-attachment-permission',
    Allow_Read: 'attachments-form-attachment-permission-allow-read',
    Allow_Write: 'attachments-form-attachment-permission-allow-write',
    Allow_Own: 'attachments-form-attachment-permission-allow-own',
    Owned_By_User_Id: 'attachments-form-attachment-permission-owned-by-user-id',
    Attachment_Permission_Mappings:
        'attachments-form-attachment-permission-mappings',
    Attachment_Permission_Mapping_Entry:
        'attachments-form-attachment-permission-mappings-entry',
    Attachment_Permission_Mappings_User_Ids:
        'attachments-form-attachment-permission-mappings-user-ids',
    Attachment_Permission_Mappings_Group_Id:
        'attachments-form-attachment-permission-mappings-group-id',
};

export const AttachmentsFolderFormDefs: AttachmentFolderForm = {
    Title: 'attachments-folder-form-title',
    Name: 'attachments-folder-form-name',
    Parent_Folder: 'attachments-folder-form-parent-folder',
    Notes: 'attachments-folder-form-notes',
    Attachment_Folder_Permission: 'attachments-folder-form-permission',
    Allow_Read: 'attachments-folder-form-allow-read',
    Allow_Write: 'attachments-folder-form-allow-write',
    Owned_By: 'attachments-folder-form-owned-by',
};

export const AttachmentsFolderGridDefs: AttachmentFolderGrid = {
    Title: 'attachments-folder-grid-title',
    Name: 'attachments-folder-grid-name',
    Parent_Folder: 'attachments-folder-grid-parent-folder',
    Notes: 'attachments-folder-grid-notes',
    Attachment_Folder_Permission: 'attachments-folder-grid-permission',
    Allow_Read: 'attachments-folder-grid-allow-read',
    Allow_Write: 'attachments-folder-grid-allow-write',
    Owned_By: 'attachments-folder-grid-owned-by',
    Created_At: 'attachments-folder-grid-date-created',
    Updated_At: 'attachments-folder-grid-last-update-date',
    Created_By: 'attachments-folder-grid-created-by',
    Updated_By: 'attachments-folder-grid-updated-by',
};

export const AttachmentsCheckOutGridDefs = {
    Title: 'attachments-checked-out-grid-title',
    Name: 'attachments-checked-out-grid-name',
    FirstName: 'attachments-checked-out-grid-checked-out-by-first',
    LastName: 'attachments-checked-out-grid-checked-out-by-last',
    CheckedOutDate: 'attachments-checked-out-grid-checked-out-date',
    Email: 'attachments-checked-out-grid-checked-out-email',
};

export const LanguagesGridDefs: LanguagesGrid = {
    Code: 'languages-code',
    Name: 'languages-name',
    ISO3: 'languages-iso3',
    ISO2: 'languages-iso2',
};

export const DepartmentCodesFormDefs: DepartmentCodesForm = {
    Create_New_Department_Code: 'department-codes-create-new-department-code',
    Name: 'department-codes-form-name',
    Code: 'department-codes-form-code',
};

export const DepartmentCodesGridDefs: DepartmentCodesGrid = {
    Name: 'department-codes-grid-name',
    Code: 'department-codes-grid-code',
    createdAt: 'department-codes-grid-date-created',
    updatedAt: 'department-codes-grid-last-update-date',
};
export const FeatureFlagsGridDefs: FeatureFlagsGrid = {
    Global: 'feature-flags-global',
    Business_Entity: 'feature-flags-business-entity',
    Enabled: 'feature-flags-enabled',
    Name: 'feature-flags-name',
    Description: 'feature-flags-description',
    Locked: 'feature-flags-locked',
};
export const SharedComponentsDefs: SharedComponents = {
    Add_New: 'shared-base-grid-toolbar-add-new',
    Bulk_Download: 'shared-base-grid-toolbar-bulk-download',
    Settings: 'shared-base-grid-toolbar-settings',
    Bulk_Upload: 'shared-base-grid-toolbar-bulk-upload',
    Reset_View: 'shared-base-grid-toolbar-reset-view',
    Enable_Disable_Selected: 'shared-base-grid-toolbar-enable-disable-selected',
    Dock_Title: 'shared-base-grid-toolbar-dock-title',
    Sort: 'shared-base-grid-toolbar-sort',
    Cancel: 'shared-base-form-toolbar-cancel',
    Add: 'shared-base-form-toolbar-add',
    Update: 'shared-base-form-toolbar-update',
    Delete: 'shared-base-form-toolbar-delete',
    Copy: 'shared-base-form-toolbar-copy',
    History: 'shared-base-form-toolbar-history',
    Primary_Information: 'shared-base-form-primary-information',
    Business_Entity_Select: 'shared-business-entity-select',
    Selected: 'shared-multi-select-grid-selected',
    Select: 'shared-multi-select-grid-select',
    Modal_Cancel: 'shared-confirm-modal-cancel',
    Modal_Ok: 'shared-confirm-modal-ok',
    Id: 'shared-base-grid-columns-id',
    Business_Entity_Id: 'shared-base-grid-columns-businessEntityId',
    Created_At: 'shared-base-grid-columns-createdAt',
    Created_By: 'shared-base-grid-columns-createdBy',
    Updated_At: 'shared-base-grid-columns-updatedAt',
    Updated_By: 'shared-base-grid-columns-updatedBy',
    Unsaved_Changes: 'shared-unsaved-changes-prompt',
    Remove: 'shared-base-grid-toolbar-remove',
    Attachments: 'shared-base-grid-toolbar-attachments',
    Refetch: 'shared-base-grid-toolbar-refetch',
    Remove_All: 'shared-transfer-list-remove-all',
    Remove_Selected: 'shared-transfer-list-remove-selected',
    Add_Selected: 'shared-transfer-list-add-selected',
    Add_All: 'shared-transfer-list-add-all',
};

export const DocDefinitionGridDefs = {
    Name: 'doc-definition-grid-name',
    Code: 'doc-definition-grid-code',
    Date_Created: 'doc-definition-grid-date-created',
    Last_Update_Date: 'doc-definition-grid-last-update-date',
    Application_Name: 'doc-definition-grid-application-name',
};

export const DocDefinitionFormDefs = {
    Name: 'doc-definition-form-name',
    Code: 'doc-definition-form-code',
    ApplicationName: 'doc-definition-form-application-name',
    EntityTypeName: 'doc-definition-form-entity-type-name',
    EnablePreview: 'doc-definition-form-enable-preview',
    EnableSaveAttachment: 'doc-definition-form-enable-save-attachment',
    PurgeHours: 'doc-definition-form-purge-hours',
    PurgeYears: 'doc-definition-form-purge-years',
    KeepAllCopies: 'doc-definition-form-keep-all-copies',
    CheckForDuplicates: 'doc-definition-form-check-for-duplicates',
    CheckForStaleData: 'doc-definition-form-check-for-stale-data',
    Create_New_DocDefinition: 'doc-definition-form-create-new-doc-definition',
    Settings_Divider: 'doc-definition-form-settings-divider',
};

export const DocTemplateGridDefs = {
    Name: 'doc-template-grid-name',
    Code: 'doc-template-grid-code',
    Default: 'doc-template-grid-default',
    Locked: 'doc-template-grid-locked',
    OutputFileName: 'doc-template-grid-output-file-name',
    Title: 'doc-template-grid-title',
    Global: 'doc-template-grid-global',
};

export const DocTemplateFormDefs = {
    DataSource: 'doc-template-form-data-source',
    Name: 'doc-template-form-name',
    Code: 'doc-template-form-code',
    Default: 'doc-template-form-default',
    Locked: 'doc-template-form-locked',
    OutputFileName: 'doc-template-form-output-file-name',
    New: 'doc-template-form-new',
    Edit: 'doc-template-form-edit',
};

export const JobRunGridDefs: JobRunGrid = {
    Title: 'job-run-grid-title',
    StartedBy: 'job-runs-grid-started-by',
    CurrentStep: 'job-runs-grid-current-step',
    EndTime: 'job-runs-grid-end-time',
    InputData: 'job-runs-grid-input-data',
    JobConfigId: 'job-runs-grid-job-config-id',
    StartTime: 'job-runs-grid-start-time',
    Status: 'job-runs-grid-status',
    JobName: 'job-runs-grid-job-name',
    TotalExecutionTimeSeconds: 'job-runs-grid-total-execution-time-seconds',
};

export const JobRunsFormDefs = {
    Title: 'job-runs-form-title',
    StartedBy: 'job-runs-form-started-by',
    CurrentStep: 'job-runs-form-current-step',
    EndTime: 'job-runs-form-end-time',
    InputData: 'job-runs-form-input-data',
    JobConfigId: 'job-runs-form-job-config-id',
    StartTime: 'job-runs-form-start-time',
    Status: 'job-runs-form-status',
    JobRunStepsHeader: 'job-runs-form-job-run-steps-header',
};

export const JobRunStepsGridDefs = {
    JobRunId: 'job-run-steps-grid-job-run-id',
    JobRunStepId: 'job-run-steps-grid-job-run-step-id',
    JobStepNumber: 'job-run-steps-grid-job-run-step-number',
    JobTaskName: 'job-run-steps-grid-job-task-name',
    CreatedAt: 'job-run-steps-grid-created-at',
    ErrorMessage: 'job-run-steps-grid-error-message',
    OutputData: 'job-run-steps-grid-output-data',
    StartTime: 'job-run-steps-grid-start-time',
    EndTime: 'job-run-steps-grid-end-time',
    Status: 'job-run-steps-grid-status',
    TotalExecutionTime: 'job-run-steps-grid-total-execution-time',
    InputData: 'job-run-steps-grid-input-data',
};

export const JobRunStepsModalDefs = {
    Title: 'job-run-steps-modal-title',
    JobRunId: 'job-run-steps-modal-job-run-id',
    CreatedAt: 'job-run-steps-modal-created-at',
    ErrorMessage: 'job-run-steps-modal-error-message',
    OutputData: 'job-run-steps-modal-output-data',
    StartTime: 'job-run-steps-modal-start-time',
    Status: 'job-run-steps-modal-status',
    TotalExecutionTime: 'job-run-steps-modal-total-execution-time',
    InputData: 'job-run-steps-modal-input-data',
    ViewLogsButton: 'job-run-steps-modal-view-logs-button',
};

export const JobLogsGridDefs = {
    Title: 'job-logs-grid-title',
    EntityUuid: 'job-logs-grid-entity-uuid',
    JobRunId: 'job-logs-grid-job-run-id',
    JobName: 'job-logs-grid-job-name',
    JobRunStepId: 'job-logs-grid-job-run-step-id',
    LoggedAt: 'job-logs-grid-logged-at',
    Message: 'job-logs-grid-message',
    MessageClass: 'job-logs-grid-message-class',
    PercentageComplete: 'job-logs-grid-percentage-complete',
    CreatedAt: 'job-logs-grid-created-at',
};

export const JobLogsFormDefs = {
    Title: 'job-logs-form-title',
    EntityUuid: 'job-logs-form-entity-uuid',
    JobRunStepId: 'job-logs-form-job-run-step-id',
    LoggedAt: 'job-logs-form-logged-at',
    Message: 'job-logs-form-message',
    MessageClass: 'job-logs-form-message-class',
    PercentageComplete: 'job-logs-form-percentage-complete',
    CreatedAt: 'job-logs-form-created-at',
};

export const JobDefinitionConfigurationGridDefs = {
    Name: 'job-definition-configuration-grid-job-name',
    RunAsUser: 'job-definition-configuration-grid-run-as-user',
    BusinessEntity: 'job-definition-configuration-grid-business-entity',
};

export const JobDefinitionConfigurationFormDefs = {
    Title: 'job-definition-configuration-form-title',
    Name: 'job-definition-configuration-form-job-name',
    RunAsUser: 'job-definition-configuration-form-run-as-user',
    SettingsDivider: 'job-definition-configuration-form-settings-divider',
    BusinessEntity: 'job-definition-configuration-form-business-entity',
};

export const JobStartedModalDefs: JobStartedModal = {
    ViewJobRun: 'job-started-modal-view-job-run',
};

export const JobLogsBasicGridDefs: JobLogsBasicGrid = {
    LoggedAt: 'job-logs-basic-grid-logged-at',
    MessageType: 'job-logs-basic-grid-message-type',
    Message: 'job-logs-basic-grid-message',
};

export const AuthErrorsDefs: AuthErrorMessages = {
    ResetPasswordLimitExceededError:
        'auth-error-messages-reset-password-limit-exceeded-error',
    PasswordComplexityRuleFailedError:
        'auth-error-messages-password-complexity-rule-failed-error',
    InvalidEmailFormatError: 'auth-error-messages-invalid-email-format-error',
    UserNotFoundError: 'auth-error-messages-user-not-found-error',
    EmailInUseError: 'auth-error-messages-email-in-use-error',
    IncorrectUsernameOrPasswordError:
        'auth-error-messages-incorrect-username-or-password-error',
    NotAuthorizedError: 'auth-error-messages-not-authorized-error',
    UserPoolNotFoundError: 'auth-error-messages-user-pool-not-found',
    DefaultMessage: 'auth-error-messages-default-message',
    ExpiredCode: 'auth-error-messages-expired-code',
};

export const AccessRestrictionsDefs: AccessRestrictions = {
    Title: 'access-restrictions-title',
    Users: 'access-restrictions-users',
};

export const TaskManagerDefs: TaskManager = {
    Title: 'task-manager-task-list-title',
    New_Task_Button_Text: 'task-manager-new-task-button-text',
    New_Personal_Task_Button_Text: 'task-manager-new-personal-task-button-text',
    Mark_Complete_Header: 'task-manager-list-grid-mark-complete-header',
    Title_Header: 'task-manager-list-grid-title-header',
    Task_Type_Header: 'task-manager-list-grid-task-type-header',
    Assignment_Type_Header: 'task-manager-list-grid-assignment-type-header',
    Created_At_Header: 'task-manager-list-grid-createdAt-header',
    Due_At_Header: 'task-manager-list-grid-dueAt-header',
    Assigned_By_Header: 'task-manager-list-grid-assigned-by-header',
    Notes_Header: 'task-manager-list-grid-notes-header',
    Go_To_Item_Header: 'task-manager-list-grid-goToItem-header',
    Business_Entity_Error_Message: 'task-manager-business-entity-error-message',
    New_Task_Title: 'task-manager-new-task-title',
    New_Task_Notes: 'task-manager-new-task-notes',
    New_Task_Due_At: 'task-manager-new-task-dueAt',
    New_Task_Entity_Type: 'task-manager-new-task-entity-type',
    New_Task_Assigned_To: 'task-manager-new-task-assigned-to',
    New_Task_Assigned_Groups: 'task-manager-new-task-assigned-groups',
    Add_Task_Button: 'task-manager-new-task-add-task-button-text',
    Assigned_By: 'task-manager-modal-title-assigned-by',
    Default_Title: 'task-manager-modal-default-title',
    Update_Task_Button: 'task-manager-update-task-button-text',
};

export const NotificationsGridDefs = {
    Title: 'notifications-grid-title',
    Read: 'notifications-grid-read',
    Subject: 'notifications-grid-subject',
    Body: 'notifications-grid-body',
    Delivered: 'notifications-grid-delivered',
    EventCode: 'notifications-grid-event-code',
    EntityType: 'notifications-grid-entity-type',
    Application: 'notifications-grid-application',
    CreatedAt: 'notifications-grid-created-at',
};

export const NotificationPopoverDefs = {
    Title: 'notifications-popover-title',
    OnlyShowUnread: 'notifications-popover-only-show-unread',
    MarkAllAsRead: 'notifications-popover-mark-all-as-read',
    MarkAsRead: 'notifications-popover-mark-as-read',
    MarkAsUnread: 'notifications-popover-mark-as-unread',
    UpToDate: 'notifications-popover-up-to-date',
    ViewAll: 'notifications-popover-view-all',
};

export const BeSubscriptionConfigDefs = {
    AutoSubscribe: 'be-subscription-config-auto-subscribe',
    ReplyTo: 'be-subscription-config-reply-to',
    ViewSubscriptions: 'notifications-config-view-subscriptions',
};

export const BeSubscriptionGridDefs = {
    Title: 'be-subscription-grid-title',
    Subscribable: 'be-subscription-grid-subscribable',
    EventCode: 'be-subscription-grid-event-code',
    EntityType: 'be-subscription-grid-entity-type',
    Application: 'be-subscription-grid-application',
    Description: 'be-subscription-grid-description',
};

export const UserSubscriptionConfigDefs = {
    AutoSubscribe: 'user-subscription-config-auto-subscribe',
    AlternateEmail: 'user-subscription-config-alternate-email',
    AlternatePhone: 'user-subscription-config-alternate-phone',
    MessagePreference: 'user-subscription-config-message-preference',
    ViewSubscriptions: 'notifications-user-config-view-subscriptions',
};

export const UserSubscriptionGridDefs = {
    Title: 'user-subscription-grid-title',
    Subscribed: 'user-subscription-grid-subscribed',
    EventCode: 'user-subscription-grid-event-code',
    EntityType: 'user-subscription-grid-entity-type',
    Application: 'user-subscription-grid-application',
    Description: 'user-subscription-grid-description',
};

export const MessageTemplateGridDefs = {
    Title: 'message-template-grid-title',
    EventDefinition: 'message-template-grid-event-definition',
    BusinessEntity: 'message-template-grid-business-entity',
    Language: 'message-template-grid-language',
    MessageTemplate: 'message-template-grid-message-template',
};

export const MessageTemplateFormDefs = {
    Title: 'message-template-form-title',
    EventDefinition: 'message-template-form-event-definition',
    BusinessEntity: 'message-template-form-business-entity',
    Language: 'message-template-form-language',
    Variables: 'message-template-form-variables',
    MessageTemplate: 'message-template-form-message-template',
    Preview: 'message-template-form-preview',
};

export const EquationBuilderFormDefs: EquationBuilderForm = {
    Title: 'equation-builder-form-title',
    Name: 'equation-builder-form-name',
    Data_Context_Type: 'equation-builder-form-data-context-type',
    Description: 'equation-builder-form-description',
    Aggregate_Function: 'equation-builder-form-aggregate-function',
    Is_Aggregate_Equation: 'equation-builder-form-is-aggregate-equation',
    Unit_Of_Measure: 'equation-builder-form-unit-of-measure',
    Notes: 'equation-builder-form-notes',
    Condition: 'equation-builder-form-condition',
    Equation: 'equation-builder-form-equation',
    Properties_Divider: 'equation-builder-form-properties-divider',
    Edit_Equation: 'equation-builder-form-edit-equation',
    Apply_Button: 'equation-builder-form-apply-button',
    Symbol_Add: 'equation-builder-form-symbol-add',
    Symbol_Subtract: 'equation-builder-form-symbol-subtract',
    Symbol_Multiply: 'equation-builder-form-symbol-multiply',
    Symbol_Divide: 'equation-builder-form-symbol-divide',
    Symbol_Exponent: 'equation-builder-form-symbol-exponent',
    Symbol_Open_Parenthesis: 'equation-builder-form-symbol-open-parenthesis',
    Symbol_Close_Parenthesis: 'equation-builder-form-symbol-close-parenthesis',
    Symbol_Equals: 'equation-builder-form-symbol-equals',
    Symbol_Greater_Than: 'equation-builder-form-symbol-greater-than',
    Symbol_Less_Than: 'equation-builder-form-symbol-less-than',
    Symbol_Greater_Than_Or_Equal_To:
        'equation-builder-form-symbol-greater-than-or-equal-to',
    Symbol_Less_Than_Or_Equal_To:
        'equation-builder-form-symbol-less-than-or-equal-to',
    Symbol_Not_Equal_To: 'equation-builder-form-symbol-not-equal-to',
    UOM_Selection_Unit_Code: 'equation-builder-form-uom-selection-unit-code',
    UOM_Selection_Description:
        'equation-builder-form-uom-selection-description',
    Function_Modal_Title: 'equation-builder-form-function-modal-title',
    Function_Parameters: 'equation-builder-form-function-parameters',
    Function_Parameter: 'equation-builder-form-function-parameter',
};
