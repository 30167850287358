import React from 'react';
import { isNil } from '../utils/objectUtils';
import { ParameterizedValidator, Validator } from './validator.interface';
import _ from 'lodash';

export const MaxLengthValidator: ParameterizedValidator = (
    maxLength: number
) => {
    return {
        key: 'maxLength',
        getErrorMessage: (fieldLabel: string) => {
            if (_.isObject(fieldLabel)) {
                return (
                    <>
                        {fieldLabel} has a maximum length of {maxLength}{' '}
                        characters{' '}
                    </>
                );
            }
            return `${fieldLabel} has a maximum length of ${maxLength} characters`;
        },
        isValid: (value: any) => {
            return isNil(value) || value.toString().length <= maxLength;
        },
    } as Validator;
};
