import React from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    CardActions,
    Popover,
} from '@mui/material';
import { DATE_TIME_FORMAT } from './Utils';
import StepInfoModal from './StepInfoModal';
import useEventDisplay from './hooks/useEventDisplay';
import { formatDate } from '@fullcalendar/core';

interface EventDisplayProps {
    eventInfo: any;
    type: any;
}

const EventDisplay = ({ eventInfo, type }: EventDisplayProps) => {
    const {
        event,
        handleStepInfoModalClose,
        handleStepInfoModalOpen,
        selectedEvent,
        showStepInfoModal,
        workcenterInfo,
        stepDetails,
    } = useEventDisplay({ eventInfo, type });

    return (
        <>
            <StepInfoModal
                show={showStepInfoModal}
                step={selectedEvent}
                handleClose={() => handleStepInfoModalClose()}
            />

            <Popover id={event.id} open={true}>
                <Typography component="h3">
                    Batch {stepDetails.BatchNumber} Step{' '}
                    {stepDetails.StepNumber}
                </Typography>

                <Card>
                    <CardHeader subheader="Batch Step" />
                    <CardContent>
                        <Typography>
                            <strong>Batch Number</strong>:{' '}
                            {stepDetails.BatchNumber}
                        </Typography>
                        <Typography>
                            <strong>Step Description</strong>:{' '}
                            {stepDetails.Description}
                        </Typography>
                        <Typography>
                            <strong>Batch Due Date</strong>:{' '}
                            {formatDate(stepDetails.batchTicket.Duedate)}
                        </Typography>
                        <Typography>
                            <strong>Workcenter</strong>: {stepDetails.Tank100}
                        </Typography>
                        <br />
                        <Typography>Product Details</Typography>
                        <Typography>
                            <strong>Product Name</strong>:{' '}
                            {stepDetails.batchTicket.ProductName}
                        </Typography>
                        <Typography>
                            <strong>Product Number</strong>:{' '}
                            {stepDetails.batchTicket.Pnum}
                        </Typography>
                        <br />
                        {workcenterInfo ? (
                            <>
                                <Typography className="mb-2">
                                    Work Center
                                </Typography>
                                <Typography>
                                    <strong>Id</strong>: {workcenterInfo.Id}
                                </Typography>
                                <Typography>
                                    <strong>Description</strong>:{' '}
                                    {workcenterInfo.Description}
                                </Typography>
                                <Typography>
                                    <strong>Warehouse</strong>:{' '}
                                    {workcenterInfo.Warehouse}
                                </Typography>
                                <Typography>
                                    <strong>Category</strong>:{' '}
                                    {workcenterInfo.Category}
                                </Typography>
                                <Typography>
                                    <strong>Type</strong>: {workcenterInfo.Type}
                                </Typography>
                                <br />
                            </>
                        ) : null}
                    </CardContent>
                </Card>
            </Popover>

            {type === 'scheduled' ? (
                <span onClick={() => handleStepInfoModalOpen()}>
                    <b>
                        {formatDate(
                            stepDetails.start,
                            /*@ts-ignore */
                            DATE_TIME_FORMAT
                        )}{' '}
                    </b>
                    <i>
                        {stepDetails.batchTicket.ProductName} (
                        {stepDetails.batchTicket.Pnum}) <br />
                        {stepDetails.Description} Step: {stepDetails.StepNumber}{' '}
                        Due: {formatDate(stepDetails.batchTicket.Duedate)}
                        Sales Order: {stepDetails.batchTicket.SalesOrder}
                    </i>
                </span>
            ) : (
                <CardActions
                    className="fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event fc-event-main"
                    onClick={() => handleStepInfoModalOpen()}
                    style={{
                        backgroundColor: event.backgroundColor,
                        color: event.textColor ? event.textColor : 'white',
                    }}
                    //@ts-ignore
                    workcenter={event.Workcenter}
                    stepnumber={event.StepNumber}
                    batchnumber={event.BatchNumber}
                    backgroundcolor={event.backgroundColor}
                    id={event.id}>
                    <span>
                        {stepDetails.batchTicket.ProductName} <br />(
                        {stepDetails.batchTicket.Pnum}) <br />
                        {event.Description} Step: {event.StepNumber} <br />
                        Due: {formatDate(event.batchTicket.Duedate)} <br />
                        Sales Order: {stepDetails.batchTicket.SalesOrder}
                    </span>
                </CardActions>
            )}
        </>
    );
};

export default EventDisplay;
