import {
    Bar,
    CartesianGrid,
    Legend,
    Line,
    ComposedChart,
    ResponsiveContainer,
    XAxis,
    YAxis,
    Tooltip,
} from 'recharts';
import React, { useEffect, useState } from 'react';
import moment from 'moment';

const InvoiceCountGraph = ({ analyticData }: any) => {
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        let newChartData = [];
        if (analyticData.dateRangeData) {
            for (const [key, value] of Object.entries(
                analyticData.dateRangeData
            )) {
                newChartData.push({
                    fromDate: key,
                    //@ts-ignore
                    'Total Invoices': value.totalDocs,
                    //@ts-ignore
                    'Total Approved': value.totalApproved,
                    //@ts-ignore
                    'Total First Pass Success': value.totalFirstPassSuccess,
                });
            }
            setChartData(
                newChartData.sort((a, b) => {
                    const momentA = moment(a.fromDate, 'MM/YYYY').startOf(
                        'month'
                    );
                    const momentB = moment(b.fromDate, 'MM/YYYY').startOf(
                        'month'
                    );

                    return momentA.isAfter(momentB) ? 1 : -1;
                })
            );
        }
    }, [analyticData]);

    return (
        <ResponsiveContainer width={'50%'} height={250}>
            <ComposedChart
                data={chartData}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}>
                <CartesianGrid />
                <XAxis dataKey="fromDate" height={30} />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                    type="monotone"
                    dataKey="Total Invoices"
                    stroke="#004085"
                    fill="#b8daff"
                />
                <Line
                    type="monotone"
                    dataKey="Total Approved"
                    stroke="#0c5460"
                />
                <Line
                    type="monotone"
                    dataKey="Total First Pass Success"
                    stroke="#155724"
                />
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default InvoiceCountGraph;
