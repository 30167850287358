import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import 'ag-grid-enterprise';
import './PricingConcernDetails.css';
import {
    DaysWithConcernCell,
    daysWithConcernCellStyle,
    DollarsLostConcernCell,
    dollarsLostConcernCellStyle,
    DollarsPer,
    QuantityUom,
} from '../Utils';
import RecommendationModal from './RecommendationModal';
import PricingAnalysisToolGridHeaderTooltips from '../PricingAnalysisToolGridHeaderTooltips';
import PricingAnalysisDetailsModal from '../PricingAnalysisDetailsModal';
import {
    currencyFormatter,
    percentFormatter,
} from '../../../utils/formattingUtils';
import MultiSelectGrid from '../../../components/grids/MultiSelectGrid';
import { CellClickedEvent } from 'ag-grid-community';
import DatacorLogoSpinner from '../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import { numberAsStringComparator } from '../../../utils/comparisonUtils';
import usePricingConcernDetailsGrid from '../hooks/PricingConcernDetails/usePricingConcernDetailsGrid';
import HelpIcon from '@mui/icons-material/Help';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { IconButton } from '@mui/material';

const PricingConcernDetailsGrid = () => {
    const {
        pricingAnalysisToolSet,
        openRecommendationModalWithProps,
        handleRecommendationModalClose,
        openDetailsModal,
        setOpenDetailsModal,
        onBtExport,
        rowData,
        onFirstDataRendered,
        onSortChanged,
        onFilterChanged,
        onGridReady,
        Recommendation,
        HasReplacementPrice,
        OpportunityScore,
        cellClickedEvent,
        onRowSelected,
    } = usePricingConcernDetailsGrid();

    return !pricingAnalysisToolSet.pricingConcernDetailsIsLoading ? (
        <>
            <RecommendationModal
                show={openRecommendationModalWithProps !== null}
                customerProductData={openRecommendationModalWithProps}
                handleClose={() => handleRecommendationModalClose()}
            />
            <PricingAnalysisDetailsModal
                show={openDetailsModal}
                handleClose={() => setOpenDetailsModal(false)}
            />
            <IconButton
                color="primary"
                aria-label="export grid to excel"
                component="span"
                onClick={() => setOpenDetailsModal(true)}>
                <HelpIcon
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        padding: '5px',
                        fontSize: '30px',
                    }}
                />
            </IconButton>
            <IconButton
                color="primary"
                aria-label="export grid to excel"
                component="span"
                onClick={() => onBtExport()}>
                <FileDownloadIcon
                    style={{
                        color: 'green',
                        cursor: 'pointer',
                        padding: '5px',
                        fontSize: '30px',
                    }}
                />
            </IconButton>

            <MultiSelectGrid
                testId="pricing-concern-details"
                gridStyle={{ width: '100%' }}
                autofitColumns={false}
                dataKey="id"
                defaultColDef={{
                    menuTabs: [
                        'filterMenuTab',
                        'generalMenuTab',
                        'columnsMenuTab',
                    ],
                    resizable: true,
                    sortable: true,
                    unSortIcon: true,
                    filter: true,
                    tooltipComponent: 'customTooltip',
                }}
                tooltipShowDelay={1000}
                paginationPageSize={15}
                rowData={rowData}
                rowHeight="25"
                onFirstDataRendered={onFirstDataRendered}
                onSortChanged={onSortChanged}
                onFilterChanged={onFilterChanged}
                onGridReady={onGridReady}
                frameworkComponents={{
                    daysWithConcernCell: DaysWithConcernCell,
                    dollarsLostConcernCell: DollarsLostConcernCell,
                    recommendation: Recommendation,
                    quantityUom: QuantityUom,
                    dollarsPer: DollarsPer,
                    hasReplacementPrice: HasReplacementPrice,
                    opportunityScore: OpportunityScore,
                    customTooltip: PricingAnalysisToolGridHeaderTooltips,
                }}
                onCellClicked={(event: CellClickedEvent) =>
                    cellClickedEvent(event)
                }
                onRowSelected={onRowSelected}
                suppressMenuHide={true}>
                <AgGridColumn
                    field="select"
                    pinned={'left'}
                    suppressMenu={true}
                    resizable={false}
                    sortable={false}
                    headerName=""
                    headerClass={'pick-list-header'}
                    width={30}
                    headerCheckboxSelection={true}
                    headerCheckboxSelectionFilteredOnly={true}
                    checkboxSelection={true}
                />
                <AgGridColumn
                    field="recommendation"
                    pinned={'left'}
                    suppressMenu={true}
                    resizable={false}
                    sortable={false}
                    headerName=""
                    headerClass={'pick-list-header'}
                    width={50}
                    cellRenderer="recommendation"
                />
                <AgGridColumn
                    field="externalCustomer"
                    headerName="Customer Name"
                    pinned={'left'}
                    width={200}
                    headerClass={'pick-list-header'}
                />
                <AgGridColumn
                    field="product"
                    headerName="Product"
                    pinned={'left'}
                    width={200}
                    headerClass={'pick-list-header'}
                />
                <AgGridColumn
                    field="orderType"
                    pinned={'left'}
                    headerName="Order Type"
                    headerClass={'pick-list-header'}
                    width={120}
                />
                <AgGridColumn
                    field="datacorScore"
                    pinned={'left'}
                    headerName="Opportunity Score"
                    headerClass={'pick-list-header'}
                    width={160}
                    cellRenderer="opportunityScore"
                    headerTooltip="datacorScore"
                    tooltipComponent="customTooltip"
                />
                <AgGridColumn
                    field="mostRecentTransaction.salesAgentName"
                    pinned={'left'}
                    headerName="Sales Agent"
                    headerClass={'pick-list-header'}
                    width={160}
                />
                <AgGridColumn
                    headerName="Outdated Pricing"
                    headerClass="grid-group-2">
                    <AgGridColumn
                        field="outdatedPricingConsecutiveDays"
                        headerName="Days With"
                        headerClass={'pick-list-header'}
                        width={140}
                        cellRenderer="daysWithConcernCell"
                        cellStyle={daysWithConcernCellStyle}
                    />
                    <AgGridColumn
                        field="outdatedPricingDollarsLost"
                        headerName="Dollars Lost"
                        headerClass={'pick-list-header'}
                        width={140}
                        cellRenderer="dollarsLostConcernCell"
                        cellStyle={dollarsLostConcernCellStyle}
                        comparator={numberAsStringComparator}
                    />
                </AgGridColumn>
                <AgGridColumn
                    headerName="Pricing Lag"
                    headerClass="grid-group-2">
                    <AgGridColumn
                        field="pricingLagConsecutiveDays"
                        headerName="Days With"
                        headerClass={'pick-list-header'}
                        width={140}
                        cellRenderer="daysWithConcernCell"
                        cellStyle={daysWithConcernCellStyle}
                    />
                    <AgGridColumn
                        field="pricingLagDollarsLost"
                        headerName="Dollars Lost"
                        headerClass={'pick-list-header'}
                        width={140}
                        cellRenderer="dollarsLostConcernCell"
                        cellStyle={dollarsLostConcernCellStyle}
                        comparator={numberAsStringComparator}
                    />
                </AgGridColumn>
                <AgGridColumn
                    headerName="Below Avg Margin"
                    headerClass="grid-group-2">
                    <AgGridColumn
                        field="belowAverageMarginConsecutiveDays"
                        headerName="Days With"
                        headerClass={'pick-list-header'}
                        width={140}
                        cellRenderer="daysWithConcernCell"
                        cellStyle={daysWithConcernCellStyle}
                    />
                    <AgGridColumn
                        field="belowAverageMarginDollarsLost"
                        headerName="Dollars Lost"
                        headerClass={'pick-list-header'}
                        width={140}
                        cellRenderer="dollarsLostConcernCell"
                        cellStyle={dollarsLostConcernCellStyle}
                        comparator={numberAsStringComparator}
                    />
                </AgGridColumn>
                <AgGridColumn
                    headerName="Margin Below Goal"
                    headerClass="grid-group-2">
                    <AgGridColumn
                        field="marginBelowGoalConsecutiveDays"
                        headerName="Days With"
                        headerClass={'pick-list-header'}
                        width={140}
                        cellRenderer="daysWithConcernCell"
                        cellStyle={daysWithConcernCellStyle}
                    />
                    <AgGridColumn
                        field="marginBelowGoalDollarsLost"
                        headerName="Dollars Lost"
                        headerClass={'pick-list-header'}
                        width={140}
                        cellRenderer="dollarsLostConcernCell"
                        cellStyle={dollarsLostConcernCellStyle}
                        comparator={numberAsStringComparator}
                    />
                </AgGridColumn>
                <AgGridColumn
                    headerName="Price Below Avg"
                    headerClass="grid-group-2">
                    <AgGridColumn
                        field="belowAveragePriceConsecutiveDays"
                        headerName="Days With"
                        headerClass={'pick-list-header'}
                        width={140}
                        cellRenderer="daysWithConcernCell"
                        cellStyle={daysWithConcernCellStyle}
                    />
                    <AgGridColumn
                        field="belowAveragePriceDollarsLost"
                        headerName="Dollars Lost"
                        headerClass={'pick-list-header'}
                        width={140}
                        cellRenderer="dollarsLostConcernCell"
                        cellStyle={dollarsLostConcernCellStyle}
                        comparator={numberAsStringComparator}
                    />
                </AgGridColumn>
                <AgGridColumn
                    headerName="Transaction Summary"
                    headerClass="grid-group-3">
                    <AgGridColumn
                        field="transactionCount"
                        headerName="Count"
                        headerClass={'pick-list-header'}
                        width={140}
                    />
                    <AgGridColumn
                        field="mostRecentTransaction.productMeasure"
                        headerName="Measure"
                        headerClass={'pick-list-header'}
                        width={140}
                    />
                    <AgGridColumn
                        field="mostRecentTransaction.productUnitOfMeasure"
                        headerName="UOM"
                        headerClass={'pick-list-header'}
                        width={110}
                    />
                    <AgGridColumn
                        field="mostRecentTransaction.orderQuantity"
                        headerName="Quantity"
                        headerClass={'pick-list-header'}
                        width={140}
                    />
                    <AgGridColumn
                        field="mostRecentTransaction.productUnitOfPackaging"
                        headerName="UOP"
                        headerClass={'pick-list-header'}
                        width={110}
                    />
                    <AgGridColumn
                        field="meanSalesDollars"
                        headerName="Sales Dollars (avg)"
                        headerClass={'pick-list-header'}
                        width={155}
                        cellRenderer="dollarsPer"
                        headerTooltip="meanSalesDollars"
                        tooltipComponent="customTooltip"
                    />
                    <AgGridColumn
                        field="meanOrderPrice"
                        headerName="Order Price (avg)"
                        headerClass={'pick-list-header'}
                        width={140}
                        cellRenderer="dollarsPer"
                        headerTooltip="meanOrderPrice"
                        tooltipComponent="customTooltip"
                    />
                    <AgGridColumn
                        field="meanOrderCost"
                        headerName="Order Cost (avg)"
                        headerClass={'pick-list-header'}
                        width={140}
                        cellRenderer="dollarsPer"
                        headerTooltip="meanOrderCost"
                        tooltipComponent="customTooltip"
                    />
                    <AgGridColumn
                        field="meanProfit"
                        headerName="Profit (avg)"
                        headerClass={'pick-list-header'}
                        width={140}
                        valueFormatter={currencyFormatter}
                        headerTooltip="meanProfit"
                        tooltipComponent="customTooltip"
                    />
                    <AgGridColumn
                        field="meanMargin"
                        headerName="Margin (avg)"
                        headerClass={'pick-list-header'}
                        width={140}
                        valueFormatter={percentFormatter}
                        headerTooltip="meanMargin"
                        tooltipComponent="customTooltip"
                    />
                    <AgGridColumn
                        field="meanFreightCost"
                        headerName="Freight Cost (avg)"
                        headerClass={'pick-list-header'}
                        width={140}
                        cellRenderer="dollarsPer"
                    />
                    <AgGridColumn
                        field="hasReplacementPrice"
                        headerName="Replacement Cost?"
                        headerClass={'pick-list-header'}
                        width={160}
                        cellRenderer="hasReplacementPrice"
                    />
                </AgGridColumn>
                <AgGridColumn headerName="Margins" headerClass="grid-group-1">
                    <AgGridColumn
                        field="mostRecentTransaction.averageMargin"
                        headerClass={'pick-list-header'}
                        headerName="Product Average"
                        width={150}
                        valueFormatter={percentFormatter}
                        headerTooltip="averageMargin"
                        tooltipComponent="customTooltip"
                    />
                    <AgGridColumn
                        field="mostRecentTransaction.marginGoalForOrderType"
                        headerClass={'pick-list-header'}
                        headerName="Margin Goal"
                        width={150}
                        valueFormatter={percentFormatter}
                    />
                    <AgGridColumn
                        field="mostRecentTransaction.margin"
                        headerClass={'pick-list-header'}
                        headerName="Last Transaction"
                        width={150}
                        valueFormatter={percentFormatter}
                    />
                    <AgGridColumn
                        field="mostRecentTransaction.percentBelowAverageMargin"
                        headerClass={'pick-list-header'}
                        headerName="Last Transaction % Below Average"
                        width={180}
                        valueFormatter={percentFormatter}
                    />
                </AgGridColumn>
            </MultiSelectGrid>
        </>
    ) : (
        <DatacorLogoSpinner />
    );
};

export default PricingConcernDetailsGrid;
