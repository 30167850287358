import React, { useEffect, useState } from 'react';
import { DialogActions, Popover } from '@mui/material';
import SelectInput from '../form/formInputs/SelectInput/SelectInput';
import { useParams } from 'react-router-dom';
import LoaderButton from '../LoaderButton';
import {
    useGenerateDocumentMutation,
    useGetDocTemplateQuery,
    useGetLicenseKeyQuery,
    useListActiveDocTemplatesQuery,
} from '../../services/documentDesigner/documentDesigner.service';
import PreviewDocumentModal from './PreviewDocumentModal';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { skipToken } from '@reduxjs/toolkit/query';
import { isNilOrEmpty } from '../../utils/objectUtils';
import { useDispatch } from 'react-redux';
import { useForm } from '../../libs/hooksLib';
import { openSnackbarBasicWithMessage } from '../../store/uiElements';
import { useListEntityTypesQuery } from '../../services/system/entityType.service';
//import { Stimulsoft } from 'stimulsoft-reports-js/Scripts/stimulsoft.viewer';

const PrintDocumentPopover = ({
    anchorEl,
    open,
    onClose,
    documentType,
    documentQuery,
    setShouldRefresh,
    documentTemplateQuery = () => {
        return { data: null as any, refetch: () => {} };
    },
    documentTemplateQueryOptions,
}: any) => {
    /**The entityId of the entity we're generating the report for */
    const { id } = useParams();
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [renderedDocument, setRenderedDocument] = useState(null);
    const [documentTemplateList, setDocumentTemplateList] = useState([]);

    const stimulsoft = (window as any).Stimulsoft;

    const { fields, handleFieldChange, formMethods } = useForm({
        selectedDocument: null,
    });

    //this will be the dataSource we use for the actual rendered document
    const { data: documentData, refetch: refetchDocData } = documentQuery(
        id ? id : skipToken
    );

    const {
        data: documentTemplatesOverride,
        refetch: refetchTemplatesOverride,
    } = documentTemplateQuery(documentTemplateQueryOptions);

    const { data: license } = useGetLicenseKeyQuery();

    const [entityTypeId, setEntityTypeId] = useState(null);

    const { data: documentTemplates, refetch: refetchTemplates } =
        useListActiveDocTemplatesQuery(entityTypeId ? entityTypeId : skipToken);

    const { data: document, refetch } = useGetDocTemplateQuery(
        fields?.selectedDocument ? fields?.selectedDocument : skipToken
    );

    const { data: entityTypes } = useListEntityTypesQuery();

    const [generateDocument, { isLoading }] = useGenerateDocumentMutation();

    useEffect(() => {
        if (!isNilOrEmpty(entityTypes)) {
            const entityType = entityTypes.find(
                (entityType: any) => entityType.code === documentType
            );
            setEntityTypeId(entityType?.id);
        }
    }, [entityTypes]);

    useEffect(() => {
        if (documentTemplatesOverride) {
            setDocumentTemplateList(documentTemplatesOverride);
        } else if (documentTemplates) {
            setDocumentTemplateList(documentTemplates);
        }
    }, [documentTemplatesOverride, documentTemplates]);

    /**
     * when we select a document template
     * we need to replace the template data
     * with real data from the entity
     */
    useEffect(() => {
        if (fields?.selectedDocument && documentData && stimulsoft) {
            getDocumentData();
        }
    }, [
        fields?.selectedDocument,
        documentData,
        openModal,
        documentTemplates,
        document,
    ]);

    /**
     * first we try to find a defaultTemplate for their business entity
     * if they don't have a default template we try to find a global template
     */
    useEffect(() => {
        if (!isNilOrEmpty(documentTemplates)) {
            const defaultTemplate = documentTemplates.find(
                (template: any) =>
                    template.default && !isNilOrEmpty(template.businessEntityId)
            );
            const globalTemplate = documentTemplates.find((template: any) =>
                isNilOrEmpty(template.businessEntityId)
            );

            if (isNilOrEmpty(defaultTemplate) && isNilOrEmpty(globalTemplate)) {
                return;
            }
            if (defaultTemplate) {
                handleFieldChange({
                    target: {
                        value: defaultTemplate.id,
                        id: 'selectedDocument',
                    },
                });
                return;
            }

            if (globalTemplate) {
                handleFieldChange({
                    target: {
                        value: globalTemplate.id,
                        id: 'selectedDocument',
                    },
                });
            }
        }
    }, [documentTemplates]);

    const handleGeneratePdf = async () => {
        const args = {
            id: fields?.selectedDocument,
            entityUUID: documentData?.UUID?.value,
            documentType: documentType,
            data: documentData,
        };

        try {
            await generateDocument(args);
            onClose();
            setShouldRefresh(true);
            dispatch(
                openSnackbarBasicWithMessage({
                    message: 'Document generated successfully',
                    severity: 'success',
                })
            );
        } catch (e) {
            dispatch(
                openSnackbarBasicWithMessage({
                    message: `Error generating document: ${e}`,
                    severity: 'error',
                })
            );
        }
    };

    const getDocumentData = async () => {
        if (
            license?.Parameter.Value &&
            license?.Parameter.Value !== 'license_key'
        ) {
            stimulsoft.Base.StiLicense.Key = license?.Parameter.Value;
        }

        //add a new dataset to the report
        const dataSet = new stimulsoft.System.Data.DataSet('sdsDataSet');
        const report = new stimulsoft.Report.StiReport();

        //add the real data to the dataset
        dataSet.readJson(JSON.stringify(documentData));

        report.regData(dataSet.dataSetName, '', dataSet);

        //sync the dictionary
        report.dictionary.synchronize();

        //load the report with the template structure - only with the real data from the dataset
        report.load(document?.templateData);

        //set the report for the preview modal
        setRenderedDocument(report);
    };

    const handlePreview = () => {
        refetchTemplates();
        setOpenModal(true);
    };

    return (
        <>
            <PreviewDocumentModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                document={renderedDocument}
            />
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: { width: '20%', padding: '20px' },
                }}>
                <SelectInput
                    fullWidth
                    id={'selectedDocument'}
                    label="Select Document"
                    value={fields?.selectedDocument}
                    onChange={handleFieldChange}
                    options={
                        documentTemplateList &&
                        documentTemplateList?.map((document: any) => {
                            return {
                                label: document.name,
                                value: document.id,
                            };
                        })
                    }
                />
                <DialogActions>
                    <LoaderButton
                        endIcon={<PictureAsPdfIcon />}
                        disabled={!fields?.selectedDocument}
                        isLoading={isLoading}
                        variant="contained"
                        onClick={handleGeneratePdf}>
                        Generate
                    </LoaderButton>
                    <LoaderButton
                        endIcon={<VisibilityIcon />}
                        disabled={!fields?.selectedDocument}
                        variant="contained"
                        onClick={handlePreview}>
                        Preview
                    </LoaderButton>
                </DialogActions>
            </Popover>
        </>
    );
};

export default PrintDocumentPopover;
