import React, { ChangeEvent, useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import {
    DialogContent,
    Grid,
    IconButton,
    Stack,
    Typography,
    Box,
} from '@mui/material';
import DatacorLogoSpinner from '../../datacorLogoSpinner/DatacorLogoSpinner';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import NotesIcon from '@mui/icons-material/Notes';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Pagination from '@mui/material/Pagination';
import NotesModal from './NotesModal';
import useAttachmentItemModal from './hooks/useAttachmentItemModal';
import { Attachment } from '../../../services/attachments/attachments.service';

interface AttachmentItemModalProps {
    item: Attachment;
    onClose: () => void;
    open: boolean;
}

const AttachmentItemModal = ({
    item,
    onClose,
    open,
}: AttachmentItemModalProps) => {
    const {
        handleClose,
        openNotesModal,
        setOpenNotesModal,
        notes,
        setNotes,
        handleOpenNotesModal,
        handleDownload,
        previewContent,
        maxVersionNumber,
        fileVersion,
        onVersionChange,
        renderItem,
        dateTimeFormatter,
        prohibitAccess,
    } = useAttachmentItemModal({ item, onClose });
    return (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth={'lg'}>
            <DialogTitle>
                Attachment Details -{' '}
                {item && new Date(item?.createdAt).toLocaleString()}
            </DialogTitle>
            {!prohibitAccess ? (
                <DialogContent>
                    <NotesModal
                        open={openNotesModal}
                        setOpen={setOpenNotesModal}
                        notes={notes}
                        setNotes={setNotes}
                        id={item?.id}
                    />
                    {item ? (
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <PersonIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={'Created By'}
                                        secondary={`${item.createdByUser.firstName} ${item.createdByUser.lastName}`}
                                    />
                                </ListItem>
                            </Grid>
                            <Grid item xs={3}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AttachFileIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`Name: ${item.name}`}
                                        secondary={item.extension}
                                    />
                                </ListItem>
                            </Grid>
                            <Grid item xs={3}>
                                {/* TODO: add ability to edit/add notes to attachment */}
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <IconButton
                                                color={'primary'}
                                                onClick={handleOpenNotesModal}>
                                                <NotesIcon />
                                            </IconButton>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary="Notes"
                                        secondary={notes}
                                    />
                                </ListItem>
                            </Grid>
                            <Grid item xs={3}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <IconButton
                                                color={'primary'}
                                                onClick={handleDownload}>
                                                <FileDownloadIcon />
                                            </IconButton>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Download" />
                                </ListItem>
                            </Grid>
                            <Grid item xs={12}>
                                {previewContent}
                            </Grid>
                            <Grid container justifyContent="center">
                                <Grid item xs={4} />
                                <Grid container xs={4} justifyContent="center">
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center">
                                        <Typography>
                                            Viewing File Version
                                        </Typography>
                                        <Pagination
                                            count={maxVersionNumber}
                                            page={fileVersion?.versionNumber}
                                            onChange={onVersionChange}
                                            renderItem={renderItem}
                                            color="secondary"
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container justifyContent="flex-end">
                                        <Typography
                                            sx={{
                                                paddingRight: '30px',
                                            }}>
                                            <Box
                                                component="span"
                                                fontWeight="fontWeightMedium">
                                                Created At:{' '}
                                            </Box>
                                            {fileVersion?.createdAt
                                                ? dateTimeFormatter(
                                                      fileVersion?.createdAt
                                                  )
                                                : ''}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <DatacorLogoSpinner />
                    )}
                </DialogContent>
            ) : (
                <DialogContent>
                    <Typography variant="h6" gutterBottom component="div">
                        User does not have permission to view this attachment.
                    </Typography>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default AttachmentItemModal;
