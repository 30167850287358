import React, { useEffect, useState } from 'react';
import {
    useAssignApprovalWorkflowToDocumentMutation,
    useGetAllApprovalWorkflowsQuery,
    useGetDocumentQuery,
} from '../../../../services/apAutomation/apAutomation.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { ApprovalWorkflow } from '../../../../types/ApAutomation.types';
import LoaderButton from '../../../../components/LoaderButton';
import { isInvoiceLocked } from '../../Utils';
import { Tooltip } from '@mui/material';
import { UserData } from '../../../../types/User.types';
import { useGetUsersQuery } from '../../../../services/organizations/organizations.service';

const useApproverWorkflowButtonGroup = (documentId: string) => {
    const { data: approvalWorkflows, isLoading: isLoadingWorkflows } =
        useGetAllApprovalWorkflowsQuery();
    const { data: activeDocument, isLoading: isLoadingDoc } =
        useGetDocumentQuery(documentId ? documentId : skipToken);
    const { data: users, isLoading: isLoadingUsers } = useGetUsersQuery();
    const [assignApprovalWorkflowToDocument] =
        useAssignApprovalWorkflowToDocumentMutation();
    let [approvalWorkflowButtons, setApprovalWorkflowButtons] = useState([]);
    let [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isLoadingWorkflows || isLoadingUsers || isLoadingDoc) {
            return;
        }
        setApprovalWorkflowButtons(
            approvalWorkflows
                .filter(
                    (approvalWorkflow: ApprovalWorkflow) =>
                        approvalWorkflow.isActive
                )
                .map((approvalWorkflow: ApprovalWorkflow) => {
                    return (
                        <ApproversOverlay
                            key={approvalWorkflow.workflowId + '-button'}
                            approvers={approvalWorkflow.approvers}>
                            <div>
                                <LoaderButton
                                    isLoading={null}
                                    color="info"
                                    variant="contained"
                                    onClick={() =>
                                        assignWorkflowToDocument(
                                            approvalWorkflow.workflowId,
                                            approvalWorkflow.approvers
                                        )
                                    }
                                    disabled={
                                        isInvoiceLocked(activeDocument) ||
                                        isLoading
                                    }>
                                    {approvalWorkflow.workflowName}
                                </LoaderButton>
                            </div>
                        </ApproversOverlay>
                    );
                })
        );
    }, [approvalWorkflows, users, activeDocument]);

    const assignWorkflowToDocument = async (
        workflowId: string,
        approvers: Array<string>
    ) => {
        setIsLoading(true);

        const args = {
            documentId: documentId,
            workflowId: workflowId,
            approvers: approvers,
        };
        assignApprovalWorkflowToDocument(args);

        setIsLoading(false);
    };

    const ApproversOverlay = (props: any) => {
        return (
            <Tooltip
                id="approvers-overlay"
                title={
                    <>
                        {props.approvers &&
                            props.approvers.map((approver: string) => {
                                let user = users.find((user: UserData) => {
                                    return user.email === approver;
                                });
                                if (user) {
                                    return (
                                        <div key={user.username}>
                                            {user.firstName} {user.lastName}
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div
                                            key={
                                                approver
                                            }>{`Inactive approver - ${approver}`}</div>
                                    );
                                }
                            })}
                    </>
                }>
                <div>{props.children}</div>
            </Tooltip>
        );
    };

    return { approvalWorkflowButtons };
};

export default useApproverWorkflowButtonGroup;
