import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TranslatableText from '../i18n/TranslatableText';
import {
    JobStartedModalDefs,
    SharedComponentsDefs,
} from '../../constants/i18n/translations/termDefinitions/platform';
import React from 'react';
import useJobStartedModal from './hooks/useJobStartedModal';

const JobStartedModal = () => {
    const {
        isOpen,
        message,
        sharedTermSet,
        jobStartedModalTermSet,
        handleClose,
        handleViewJob,
    } = useJobStartedModal();

    return (
        <Dialog open={isOpen} onClose={handleClose} maxWidth={'lg'}>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message ?? ''}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    <TranslatableText
                        termSet={sharedTermSet}
                        termKey={SharedComponentsDefs.Modal_Cancel}
                    />
                </Button>
                <Button onClick={handleViewJob} autoFocus>
                    <TranslatableText
                        termSet={jobStartedModalTermSet}
                        termKey={JobStartedModalDefs.ViewJobRun}
                    />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default JobStartedModal;
