import { lazy } from 'react';

const FixedAssets = lazy(
    () => import('../containers/fixedAssets/assetRegister/FixedAssets')
);
const FixedAssetGrid = lazy(
    () => import('../containers/fixedAssets/assetRegister/FixedAssetGrid')
);
const FixedAssetForm = lazy(
    () => import('../containers/fixedAssets/assetRegister/FixedAssetForm')
);
const FixedAssetStatusGrid = lazy(
    () => import('../containers/fixedAssets/FixedAssetStatusGrid')
);
const FixedAssetsStatusForm = lazy(
    () => import('../containers/fixedAssets/FixedAssetsStatusForm')
);
const AccountFunctionCodeGrid = lazy(
    () => import('../containers/accounting/AccountFunctionCodeGrid')
);
const AccountFunctionCodeForm = lazy(
    () => import('../containers/accounting/AccountFunctionCodeForm')
);
const FixedAssetCostCodeGrid = lazy(
    () => import('../containers/fixedAssets/FixedAssetsCostCodeGrid')
);
const FixedAssetsCostCodeForm = lazy(
    () => import('../containers/fixedAssets/FixedAssetsCostCodeForm')
);
const FixedAssetTypeGrid = lazy(
    () => import('../containers/fixedAssets/FixedAssetTypeGrid')
);
const FixedAssetTypeForm = lazy(
    () => import('../containers/fixedAssets/FixedAssetTypeForm')
);
const FixedAssetDepreciationMethodStatusGrid = lazy(
    () =>
        import(
            '../containers/fixedAssets/FixedAssetDepreciationMethodStatusGrid'
        )
);
const FixedAssetDepreciationMethodStatusForm = lazy(
    () =>
        import(
            '../containers/fixedAssets/FixedAssetDepreciationMethodStatusForm'
        )
);
const FixedAssetDepreciationMethodTypeGrid = lazy(
    () =>
        import('../containers/fixedAssets/FixedAssetDepreciationMethodTypeGrid')
);
const FixedAssetDepreciationMethodTypeForm = lazy(
    () =>
        import('../containers/fixedAssets/FixedAssetDepreciationMethodTypeForm')
);
const FixedAssetDepreciationMethodGrid = lazy(
    () => import('../containers/fixedAssets/FixedAssetDepreciationMethodGrid')
);
const FixedAssetDepreciationMethodForm = lazy(
    () => import('../containers/fixedAssets/FixedAssetDepreciationMethodForm')
);
const ContractGrid = lazy(
    () => import('../containers/accounting/ContractGrid')
);
const ContractForm = lazy(
    () => import('../containers/accounting/ContractForm')
);
const ContractTypeGrid = lazy(
    () => import('../containers/accounting/ContractTypeGrid')
);
const ContractTypeForm = lazy(
    () => import('../containers/accounting/ContractTypeForm')
);
const GeneralLedgerAccountGrid = lazy(
    () => import('../containers/accounting/GeneralLedgerAccountGrid')
);
const GeneralLedgerAccountForm = lazy(
    () => import('../containers/accounting/GeneralLedgerAccountForm')
);
const AssetEntriesGrid = lazy(
    () => import('../containers/fixedAssets/assetEntries/AssetEntriesGrid')
);
const FixedAssetSchedulesGrid = lazy(
    () => import('../containers/fixedAssets/FixedAssetSchedulesGrid')
);
const FixedAssetDepreciationEntriesGrid = lazy(
    () => import('../containers/fixedAssets/FixedAssetDepreciationEntriesGrid')
);
const ContractCoverageGrid = lazy(
    () =>
        import(
            '../containers/fixedAssets/fixedAssetContract/ContractCoverageGrid'
        )
);
const FixedAssetDisposalEntriesGrid = lazy(
    () =>
        import(
            '../containers/fixedAssets/fixedAssetDisposalEntries/FixedAssetDisposalEntriesGrid'
        )
);
const AcquisitionsGrid = lazy(
    () =>
        import(
            '../containers/fixedAssets/reports/acquisitions/AcquisitionsGrid'
        )
);
const DisposalsGrid = lazy(
    () => import('../containers/fixedAssets/reports/disposals/DisposalsGrid')
);
const DepreciationSummaryGrid = lazy(
    () =>
        import(
            '../containers/fixedAssets/reports/depreciationSummary/DepreciationSummaryGrid'
        )
);
const DepreciationDetailGrid = lazy(
    () =>
        import(
            '../containers/fixedAssets/reports/depreciationDetails/DepreciationDetailGrid'
        )
);

import { RouteProps } from './routesConfig';
import { PERMISSIONS } from '../constants/permissions/Permissions.constants';

const FixedAssetsRoutes: RouteProps[] = [
    {
        path: '/accounting/fixedAssets',
        component: FixedAssets,
        featureFlag: 'dashboard.nav.apps.fixedAssets',
    },
    {
        path: '/accounting/fixedAssets/fixedAsset',
        component: FixedAssetGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.FIXED_ASSET_REGISTER.VIEW,
    },
    {
        path: '/accounting/fixedAssets/fixedAsset/:id',
        component: FixedAssetForm,
        permissionKey: PERMISSIONS.FIXED_ASSETS.FIXED_ASSET_REGISTER.VIEW,
    },
    {
        path: '/accounting/fixedAssets/assetSchedules',
        component: FixedAssetSchedulesGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.DEPRECIATION_SCHEDULES.VIEW,
    },
    {
        path: '/accounting/fixedAssets/depreciationEntries',
        component: FixedAssetDepreciationEntriesGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.DEPRECIATION_ENTRIES.VIEW,
    },
    {
        path: '/accounting/fixedAssets/status',
        component: FixedAssetStatusGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.ASSET_STATUSES.VIEW,
    },
    {
        path: '/accounting/fixedAssets/status/:id',
        component: FixedAssetsStatusForm,
        permissionKey: PERMISSIONS.FIXED_ASSETS.ASSET_STATUSES.VIEW,
    },
    {
        path: '/accounting/fixedAssets/functionCode/:group',
        component: AccountFunctionCodeGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.FUNCTION_CODES.VIEW,
        featureFlag: 'dashboard.nav.apps.fixedAssets',
    },
    {
        path: '/accounting/fixedAssets/functionCode/:group/:id',
        component: AccountFunctionCodeForm,
        permissionKey: PERMISSIONS.FIXED_ASSETS.FUNCTION_CODES.VIEW,
        featureFlag: 'dashboard.nav.apps.fixedAssets',
    },
    {
        path: '/accounting/fixedAssets/costCode',
        component: FixedAssetCostCodeGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.ASSET_COST_CODES.VIEW,
    },
    {
        path: '/accounting/fixedAssets/costCode/:id',
        component: FixedAssetsCostCodeForm,
        permissionKey: PERMISSIONS.FIXED_ASSETS.ASSET_COST_CODES.VIEW,
    },
    {
        path: '/accounting/fixedAssets/type',
        component: FixedAssetTypeGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.ASSET_TYPES.VIEW,
    },
    {
        path: '/accounting/fixedAssets/type/:id',
        component: FixedAssetTypeForm,
        permissionKey: PERMISSIONS.FIXED_ASSETS.ASSET_TYPES.VIEW,
    },
    {
        path: '/accounting/fixedAssets/depreciationMethodStatus',
        component: FixedAssetDepreciationMethodStatusGrid,
        permissionKey:
            PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHOD_STATUSES.VIEW,
    },
    {
        path: '/accounting/fixedAssets/depreciationMethodStatus/:id',
        component: FixedAssetDepreciationMethodStatusForm,
        permissionKey:
            PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHOD_STATUSES.VIEW,
    },
    {
        path: '/accounting/fixedAssets/depreciationMethodType',
        component: FixedAssetDepreciationMethodTypeGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHOD_TYPES.VIEW,
    },
    {
        path: '/accounting/fixedAssets/depreciationMethodType/:id',
        component: FixedAssetDepreciationMethodTypeForm,
        permissionKey: PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHOD_TYPES.VIEW,
    },
    {
        path: '/accounting/fixedAssets/depreciationMethod',
        component: FixedAssetDepreciationMethodGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHODS.VIEW,
    },
    {
        path: '/accounting/fixedAssets/depreciationMethod/:id',
        component: FixedAssetDepreciationMethodForm,
        permissionKey: PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHODS.VIEW,
    },
    {
        path: '/accounting/fixedAssets/reports/acquisitions',
        component: AcquisitionsGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.REPORTS.ACQUISITIONS.VIEW,
    },
    {
        path: '/accounting/fixedAssets/reports/disposals',
        component: DisposalsGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.REPORTS.DISPOSALS.VIEW,
    },
    {
        path: '/accounting/fixedAssets/reports/depreciationSummary',
        component: DepreciationSummaryGrid,
        permissionKey:
            PERMISSIONS.FIXED_ASSETS.REPORTS.DEPRECIATION_SUMMARY.VIEW,
    },
    {
        path: '/accounting/fixedAssets/reports/depreciationDetail',
        component: DepreciationDetailGrid,
        permissionKey:
            PERMISSIONS.FIXED_ASSETS.REPORTS.DEPRECIATION_DETAIL.VIEW,
    },
    {
        path: '/accounting/contract',
        component: ContractGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.CONTRACTS.VIEW,
    },
    {
        path: '/accounting/contract/:id',
        component: ContractForm,
        permissionKey: PERMISSIONS.FIXED_ASSETS.CONTRACTS.VIEW,
    },
    {
        path: '/accounting/contractCoverage',
        component: ContractCoverageGrid,
        permissionKey:
            PERMISSIONS.FIXED_ASSETS.CONTRACTS.VIEW_CONTRACT_COVERAGE,
    },
    {
        path: '/accounting/contractType',
        component: ContractTypeGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.CONTRACT_TYPES.VIEW,
    },
    {
        path: '/accounting/contractType/:id',
        component: ContractTypeForm,
        permissionKey: PERMISSIONS.FIXED_ASSETS.CONTRACT_TYPES.VIEW,
    },
    {
        path: '/accounting/generalLedgerAccount/:group',
        component: GeneralLedgerAccountGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.CHART_OF_ACCOUNTS.VIEW,
    },
    {
        path: '/accounting/generalLedgerAccount/:group/:id',
        component: GeneralLedgerAccountForm,
        permissionKey: PERMISSIONS.FIXED_ASSETS.CHART_OF_ACCOUNTS.VIEW,
    },
    {
        path: '/accounting/fixedAssets/disposalEntries',
        component: FixedAssetDisposalEntriesGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.DISPOSAL_ENTRY.VIEW,
    },
    {
        path: '/accounting/fixedAssets/assetEntries',
        component: AssetEntriesGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.ASSET_ENTRY.VIEW,
    },
];

export default FixedAssetsRoutes;
