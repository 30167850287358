import _ from 'lodash';

export class UserListUtils {
    static extractAndInjectUserListFields(
        baseObject: any,
        rootPath: string,
        lookupFieldName: string,
        injectGroupIdFieldName: string,
        injectUserIdFieldName: string
    ): void {
        const pathPrefix = !_.isEmpty(rootPath) ? `${rootPath}.` : '';

        let userListField = _.get(
            baseObject,
            `${pathPrefix}${lookupFieldName}`
        );

        if (userListField) {
            const groupId = userListField.groupId;
            const userIds = userListField.users?.map((user: any) => user.id);

            if (groupId) {
                _.set(
                    baseObject,
                    `${pathPrefix}${injectGroupIdFieldName}`,
                    groupId
                );
            }

            if (userIds) {
                _.set(
                    baseObject,
                    `${pathPrefix}${injectUserIdFieldName}`,
                    userIds
                );
            }
        }
    }
}
