import { createContext } from 'react';

const GlobalEntityDataContext = createContext({
    entityData: {
        entityType: null,
        fullPath: null,
        pageTitle: null,
        entityUuid: null,
    },
    setEntityData: (value: any) => {},
});

export default GlobalEntityDataContext;
