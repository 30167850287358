import config from '../../config';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../serviceInterfaces';
import * as _ from 'lodash';
import {
    UnitOfMeasure,
    UnitOfMeasureConversion,
    UnitType,
    ProperShippingName,
    BoilerPlateNote,
    UnitOfMeasureGlobal,
} from '../../types/Setup.types';
import { FeatureFlag } from '../access/access.service';
import { datacorAppApi } from '../datacorAppApi';
import { alphabeticalSortObject } from '../../utils/formattingUtils';

export const organizationsApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        createSubscriber: build.mutation<any, BaseCreateEntityArgs<Subscriber>>(
            {
                query: (args) => ({
                    url: 'core-entities/subscribers',
                    method: 'POST',
                    body: args.postBody,
                }),
                invalidatesTags: ['Subscribers', 'Revisions'],
            }
        ),
        updateSubscriber: build.mutation<any, BaseUpdateEntityArgs<Subscriber>>(
            {
                query: (args) => ({
                    url: `core-entities/subscribers/${args.id}`,
                    method: 'PUT',
                    body: args.postBody,
                }),
                invalidatesTags: ['Subscribers', 'Revisions'],
            }
        ),
        getSubscribers: build.query<any, void>({
            query: () => `core-entities/subscribers`,
            providesTags: ['Subscribers'],
        }),
        getSubscribersForDropdown: build.query<any, void>({
            query: () => `core-entities/subscribers`,
            transformResponse: (response: BusinessEntityType[]) => {
                return response
                    .map((subscriber) => {
                        return {
                            value: subscriber.id,
                            label: subscriber.name,
                        };
                    })
                    .sort((a, b) => alphabeticalSortObject(a, b, 'label'));
            },
            providesTags: ['Subscribers'],
        }),
        getSubscriber: build.query<any, string>({
            query: (id) => `core-entities/subscribers/${id}`,
            providesTags: ['Subscribers'],
        }),
        getDepartmentCodesForSubscriber: build.query<any, any>({
            query: (id) => {
                return {
                    url: `core-entities/subscribers/${id}`,
                    params: { full: 'true' },
                };
            },
            transformResponse: (response: Subscriber) => {
                return response.departmentCodes;
            },
            providesTags: ['DepartmentCodes'],
        }),
        getDeparmentCodesForDropdown: build.query<any, any>({
            query: (args) => {
                return {
                    url: `core-entities/subscribers/${args.id}`,
                    params: { full: 'true' },
                };
            },
            transformResponse: (response: Subscriber) => {
                return response.departmentCodes.map((dep) => {
                    return { value: dep.id, label: dep.name };
                });
            },
            providesTags: ['DepartmentCodes'],
        }),
        createBusinessEntity: build.mutation<
            any,
            BaseCreateEntityArgs<BusinessEntity>
        >({
            query: (args) => ({
                url: 'core-entities/business-entities',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['BusinessEntities', 'Revisions'],
        }),
        updateBusinessEntity: build.mutation<
            any,
            BaseUpdateEntityArgs<BusinessEntity>
        >({
            query: (args) => ({
                url: `core-entities/business-entities/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['BusinessEntities', 'Revisions'],
        }),
        getBusinessEntities: build.query<any, string>({
            query: (subscriberId) => {
                return {
                    url: `core-entities/business-entities`,
                    params: { subscriberId: subscriberId },
                };
            },
            transformResponse: (response: BusinessEntity[]) => {
                return response.sort((a, b) =>
                    alphabeticalSortObject(a, b, 'name')
                );
            },
            providesTags: ['BusinessEntities'],
        }),
        getBusinessEntitiesForDropdown: build.query<any, string>({
            query: (subscriberId) => {
                return {
                    url: `core-entities/business-entities`,
                    params: { subscriberId: subscriberId },
                };
            },
            transformResponse: (response: BusinessEntity[]) => {
                return response;
            },
            providesTags: ['BusinessEntities'],
        }),
        getBusinessEntitiesById: build.query<any, string>({
            query: (id) => {
                return {
                    url: `core-entities/business-entities/${id}`,
                    params: { full: true },
                };
            },
            transformResponse: (response: BusinessEntity) => {
                return [response];
            },

            providesTags: ['BusinessEntities'],
        }),
        getBusinessEntityChildren: build.query<any, string>({
            query: (id) => {
                return {
                    url: `core-entities/business-entities/${id}`,
                    params: { full: 'true' },
                };
            },
            transformResponse: (response: BusinessEntity) => {
                return response ? response.children : [];
            },
            providesTags: ['BusinessEntities'],
        }),
        getBusinessEntity: build.query<any, string>({
            query: (id) => `core-entities/business-entities/${id}`,
            providesTags: ['BusinessEntities'],
        }),
        getBusinessEntityFull: build.query<any, string>({
            query: (id) => {
                return {
                    url: `core-entities/business-entities/${id}`,
                    params: { full: true },
                };
            },
            providesTags: ['BusinessEntities'],
        }),
        getBusinessEntityTypes: build.query<any, void>({
            query: () => {
                return {
                    url: `core-entities/businessEntityTypes`,
                };
            },
            providesTags: ['BusinessEntityTypes'],
        }),
        getBusinessEntityTypesForDropdown: build.query<any, void>({
            query: () => `core-entities/business-entity-types`,
            transformResponse: (response: BusinessEntityType[]) => {
                return response.map((businessEntityType) => {
                    return {
                        value: businessEntityType.id,
                        label: businessEntityType.name,
                    };
                });
            },
            providesTags: ['BusinessEntityTypes'],
        }),
        getBusinessEntityType: build.query<any, any>({
            query: (args) => {
                return {
                    url: `core-entities/businessEntityTypes/${args.businessEntityTypeId}`,
                    params: args.full ? { full: args.full } : null,
                };
            },
            providesTags: ['BusinessEntities'],
        }),
        createBusinessEntityType: build.mutation<
            any,
            BaseCreateEntityArgs<BusinessEntityType>
        >({
            query: (args) => ({
                url: 'core-entities/businessEntityTypes',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['BusinessEntityTypes'],
        }),
        updateBusinessEntityType: build.mutation<
            any,
            BaseUpdateEntityArgs<BusinessEntityType>
        >({
            query: (args) => ({
                url: `core-entities/businessEntityTypes/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['BusinessEntityTypes'],
        }),

        getUsers: build.query<any, void>({
            query: () => {
                return {
                    url: `core-entities/users`,
                };
            },
            providesTags: ['BusinessEntityUsers'],
        }),
        getBusinessEntityUsers: build.query<any, string>({
            query: (businessEntityId) => {
                return {
                    url: `core-entities/users`,
                };
            },
            transformResponse: (response: any, meta: any, arg: any) => {
                return response.filter(
                    (user: User) => user?.businessEntity?.id?.toString() === arg
                );
            },

            providesTags: ['BusinessEntityUsers'],
        }),
        getBusinessEntityUsersForDropdown: build.query<any, string>({
            query: (businessEntityId) => {
                return {
                    url: `core-entities/users`,
                };
            },
            transformResponse: (response: any, meta: any, arg: any) => {
                const users = response.filter(
                    (user: User) => user?.businessEntity?.id?.toString() === arg
                );

                return users.map((user: any) => {
                    return {
                        value: user.id,
                        label: `${user.lastName}, ${user.firstName}`,
                    };
                });
            },

            providesTags: ['BusinessEntityUsers'],
        }),

        getUsersBySubscriber: build.query<any, void>({
            query: () => {
                return {
                    url: `core-entities/users/usersBySubscriber`,
                };
            },
            providesTags: ['BusinessEntityUsers'],
        }),
        getUsersBySubscriberForDropdown: build.query<any, void>({
            query: () => {
                return {
                    url: `core-entities/users/usersBySubscriber`,
                };
            },
            transformResponse: (response: User[]) => {
                return response.map((user) => {
                    return {
                        value: user.id,
                        label: `${user.lastName}, ${user.firstName}`,
                        email: user.email,
                    };
                });
            },
            providesTags: ['BusinessEntityUsers'],
        }),
        getUsersForDropdown: build.query<any, void>({
            query: () => {
                return {
                    url: `core-entities/users`,
                };
            },
            transformResponse: (response: User[]) => {
                return response.map((user) => {
                    return {
                        value: user.id,
                        label: `${user.lastName}, ${user.firstName}`,
                    };
                });
            },
            providesTags: ['BusinessEntityUsers'],
        }),
        getUsersWithEmailForDropdown: build.query<any, void>({
            query: (businessEntityId) => {
                return {
                    url: `core-entities/users`,
                };
            },
            transformResponse: (response: User[], meta: any, arg: any) => {
                return response
                    .filter(
                        (user: User) =>
                            user?.businessEntity?.id?.toString() === arg
                    )
                    .map((user) => {
                        return {
                            value: user.id,
                            label: `${user.lastName}, ${user.firstName}`,
                            email: user.email,
                        };
                    });
            },
            providesTags: ['BusinessEntityUsers'],
        }),
        getUser: build.query<any, any>({
            query: (args) => {
                return {
                    url: `core-entities/users/${args.userId}`,
                    params: args.full
                        ? {
                              full: args.full,
                              includeCognitoStatus: true,
                              userPoolId: config.cognito.USER_POOL_ID,
                          }
                        : {
                              includeCognitoStatus: true,
                              userPoolId: config.cognito.USER_POOL_ID,
                          },
                };
            },
            transformResponse: (response: User): any => {
                const transformedUser: any = response;
                if (response.groups) {
                    transformedUser.groups = _.map(response.groups, 'id');
                }

                if (response.roles) {
                    transformedUser.roles = _.map(response.roles, 'id');
                }

                return transformedUser;
            },
            providesTags: ['BusinessEntityUsers'],
        }),
        resendUserTempCredentials: build.mutation<any, any>({
            query: (args) => {
                return {
                    url: `core-entities/users/resendTempCredentials`,
                    method: 'GET',
                    params: {
                        loginName: args.loginName,
                        userPoolId: config.cognito.USER_POOL_ID,
                    },
                };
            },
        }),
        setAndGetUserTempCredentials: build.mutation<any, any>({
            query: (args) => {
                return {
                    url: `core-entities/users/setAndGetTempCredentials`,
                    method: 'GET',
                    params: {
                        loginName: args.loginName,
                    },
                };
            },
        }),
        getUserDetails: build.query<any, void>({
            query: () => {
                return {
                    url: `core-entities/users/userDetails`,
                };
            },
            transformResponse: (response: User): any => {
                const transformedUser: any = response;
                if (response.groups) {
                    transformedUser.groups = _.map(response.groups, 'id');
                }

                if (response.roles) {
                    let userPermissions: string[] = [];
                    response.roles.map((rol) => {
                        rol.permissions.map((p) => {
                            userPermissions.push(p.code);
                        });
                    });
                    transformedUser.permissions = userPermissions;
                    transformedUser.roles = _.map(response.roles, 'id');
                }

                return transformedUser;
            },
            providesTags: ['BusinessEntityUsers'],
        }),
        getUserPermissions: build.query<any, void>({
            query: () => {
                return {
                    url: `core-entities/getUserpermissions`,
                };
            },
            providesTags: ['BusinessEntityUsers'],
        }),
        createUser: build.mutation<any, BaseCreateEntityArgs<User>>({
            query: (args) => ({
                url: 'core-entities/users',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['BusinessEntityUsers', 'Revisions'],
        }),
        updateUser: build.mutation<any, BaseUpdateEntityArgs<User>>({
            query: (args) => ({
                url: `core-entities/users/${args.id}`,
                method: 'PUT',
                body: args.postBody,
                params: {
                    ignoreRevision: args.ignoreRevision
                        ? args.ignoreRevision
                        : 'false',
                },
            }),
            invalidatesTags: ['BusinessEntityUsers', 'Revisions'],
        }),
        deleteUser: build.mutation<any, string>({
            query: (id) => ({
                url: `core-entities/users/${id}`,
                method: 'DELETE',
                params: { useUpdate: 'true' },
            }),
            invalidatesTags: ['BusinessEntityUsers', 'Revisions'],
        }),
        updateUserEmail: build.mutation<any, any>({
            query: (email: string) => ({
                url: `core-entities/users/email/${email}`,
                method: 'PUT',
            }),
            invalidatesTags: ['BusinessEntityUsers', 'Revisions'],
        }),
        getGroups: build.query<any, void>({
            query: () => {
                return {
                    url: `core-entities/groups`,
                };
            },
            providesTags: ['BusinessEntityGroups'],
        }),
        getGroupsByBusinessEntitiesForDropdown: build.query<any, string>({
            query: (businessEntityIds) => {
                return {
                    url: `core-entities/getGroupsByBusinessEntities`,
                    params: { businessEntityIds: businessEntityIds },
                };
            },
            transformResponse: (response: Group[]) => {
                return response.map((group) => {
                    return {
                        value: group.id,
                        label: `${group.name} (${group.businessEntity.name})`,
                    };
                });
            },
            providesTags: ['BusinessEntityGroups'],
        }),
        getGroupsForDropdown: build.query<any, void>({
            query: () => {
                return {
                    url: `core-entities/groups`,
                };
            },
            transformResponse: (response: Group[]) => {
                return response.map((group) => {
                    return {
                        value: group.id,
                        label: `${group.name} (${group.businessEntity.name})`,
                    };
                });
            },
            providesTags: ['BusinessEntityGroups'],
        }),
        getGroup: build.query<any, any>({
            query: (args) => {
                return {
                    url: `core-entities/groups/${args.groupId}`,
                    params: args.full ? { full: args.full } : null,
                };
            },
            transformResponse: (response: Group): any => {
                const transformedUser: any = response;
                if (response.users) {
                    transformedUser.users = _.map(response.users, 'id');
                }

                return transformedUser;
            },
            providesTags: ['BusinessEntityGroups'],
        }),
        createGroup: build.mutation<any, BaseCreateEntityArgs<Group>>({
            query: (args) => ({
                url: 'core-entities/groups',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['BusinessEntityGroups', 'Revisions'],
        }),
        updateGroup: build.mutation<any, BaseUpdateEntityArgs<Group>>({
            query: (args) => ({
                url: `core-entities/groups/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['BusinessEntityGroups', 'Revisions'],
        }),
        getRoles: build.query<any, void>({
            query: () => {
                return {
                    url: `core-entities/roles`,
                };
            },
            providesTags: ['BusinessEntityRoles'],
        }),
        getRolesForDropdown: build.query<any, void>({
            query: () => {
                return {
                    url: `core-entities/roles`,
                };
            },
            transformResponse: (response: Role[]) => {
                return response.map((role) => {
                    return {
                        value: role.id,
                        label: `${role.name} (${role.businessEntity.name})`,
                    };
                });
            },
            providesTags: ['BusinessEntityRoles'],
        }),
        getRolesByBusinessEntitiesForDropdown: build.query<any, string>({
            query: (businessEntityIds) => {
                return {
                    url: `core-entities/getRolesByBusinessEntities`,
                    params: { businessEntityIds: businessEntityIds },
                };
            },
            transformResponse: (response: Role[]) => {
                return response.map((role) => {
                    return {
                        value: role.id,
                        label: `${role.name} (${role.businessEntity.name})`,
                    };
                });
            },
            providesTags: ['BusinessEntityRoles'],
        }),
        getRole: build.query<any, any>({
            query: (args) => {
                return {
                    url: `core-entities/roles/${args.roleId}`,
                    params: args.full ? { full: args.full } : null,
                };
            },
            transformResponse: (response: Role): any => {
                const transformedUser: any = response;
                if (response.users) {
                    transformedUser.users = _.map(response.users, 'id');
                }

                if (response.permissions) {
                    transformedUser.permissions = _.map(
                        response.permissions,
                        'id'
                    );
                }

                return transformedUser;
            },
            providesTags: ['BusinessEntityRoles'],
        }),
        createRole: build.mutation<any, BaseCreateEntityArgs<Role>>({
            query: (args) => ({
                url: 'core-entities/roles',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['BusinessEntityRoles', 'Revisions'],
        }),
        updateRole: build.mutation<any, BaseUpdateEntityArgs<Role>>({
            query: (args) => ({
                url: `core-entities/roles/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: [
                'BusinessEntityRoles',
                'BusinessEntityPermissions',
                'Revisions',
            ],
        }),
        getPermissions: build.query<any, void>({
            query: () => {
                return {
                    url: `core-entities/permissions`,
                };
            },
            providesTags: ['BusinessEntityPermissions'],
        }),
        getPermissionsForDropdown: build.query<any, void>({
            query: () => {
                return {
                    url: `core-entities/permissions`,
                };
            },
            transformResponse: (response: Permission[]) => {
                return response.map((permission) => {
                    return { value: permission.id, label: permission.name };
                });
            },
            providesTags: ['BusinessEntityPermissions'],
        }),
        getPermission: build.query<any, any>({
            query: (args) => {
                return {
                    url: `core-entities/permissions/${args.permissionId}`,
                    params: args.full ? { full: args.full } : null,
                };
            },
            providesTags: ['BusinessEntityPermissions'],
        }),
        createPermission: build.mutation<any, BaseCreateEntityArgs<Permission>>(
            {
                query: (args) => ({
                    url: 'core-entities/permissions',
                    method: 'POST',
                    body: args.postBody,
                }),
                invalidatesTags: ['BusinessEntityPermissions', 'Revisions'],
            }
        ),
        updatePermission: build.mutation<any, BaseUpdateEntityArgs<Permission>>(
            {
                query: (args) => ({
                    url: `core-entities/permissions/${args.id}`,
                    method: 'PUT',
                    body: args.postBody,
                }),
                invalidatesTags: ['BusinessEntityPermissions', 'Revisions'],
            }
        ),
        getDepartmentCodes: build.query<any, string>({
            query: (subscriberId) => {
                return {
                    url: `core-entities/department-codes`,
                    params: { subscriberId: subscriberId },
                };
            },
            providesTags: ['DepartmentCodes'],
        }),
        getDepartmentCodesForDropdown: build.query<any, void>({
            query: () => {
                return {
                    url: `core-entities/department-codes`,
                };
            },
            transformResponse: (response: DepartmentCode[]) => {
                return response.map((permission) => {
                    return { value: permission.id, label: permission.name };
                });
            },
            providesTags: ['DepartmentCodes'],
        }),
        getDepartmentCode: build.query<any, any>({
            query: (args) => {
                return {
                    url: `core-entities/department-codes/${args.departmentCodeId}`,
                    params: args.full ? { full: args.full } : null,
                };
            },
            providesTags: ['DepartmentCodes'],
        }),
        createDepartmentCode: build.mutation<
            any,
            BaseCreateEntityArgs<DepartmentCode>
        >({
            query: (args) => ({
                url: 'core-entities/department-codes',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['DepartmentCodes', 'Revisions'],
        }),
        updateDepartmentCode: build.mutation<
            any,
            BaseUpdateEntityArgs<DepartmentCode>
        >({
            query: (args) => ({
                url: `core-entities/department-codes/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['DepartmentCodes', 'Revisions'],
        }),
        deleteDepartmentCode: build.mutation<any, string>({
            query: (id) => ({
                url: `core-entities/department-codes/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['DepartmentCodes', 'Revisions'],
        }),
        getMessagingPreferencesForDropdown: build.query<any, void>({
            query: () => `core-entities/messaging-preferences`,
            transformResponse: (response: MessagingPreference[]) => {
                return response.map((preference) => {
                    return { value: preference.id, label: preference.name };
                });
            },
            providesTags: ['MessagingPreferences'],
        }),
        getBuildings: build.query<any, void>({
            query: () => {
                return {
                    url: `core-entities/buildings`,
                };
            },
            providesTags: ['Buildings'],
        }),
        getBuilding: build.query<any, any>({
            query: (id) => {
                return {
                    url: `core-entities/buildings/${id}`,
                };
            },
            providesTags: ['Buildings'],
        }),
        createBuilding: build.mutation<any, BaseCreateEntityArgs<Building>>({
            query: (args) => {
                return {
                    url: `core-entities/buildings`,
                    method: 'POST',
                    body: args.postBody,
                };
            },
            invalidatesTags: ['Buildings', 'Revisions'],
        }),
        updateBuilding: build.mutation<any, BaseUpdateEntityArgs<Building>>({
            query: (args) => {
                return {
                    url: `core-entities/buildings/${args.id}`,
                    method: 'PUT',
                    body: args.postBody,
                };
            },
            invalidatesTags: ['Buildings', 'Revisions'],
        }),
        getCountries: build.query<any, void>({
            query: () => {
                return {
                    url: `i18n/countries`,
                };
            },
            providesTags: ['Buildings'],
        }),
        getCountriesForDropdown: build.query<any, void>({
            query: () => {
                return {
                    url: `i18n/countries`,
                };
            },
            transformResponse: (response: CountryCode[]) => {
                return response.map((country) => {
                    return {
                        value: country.id,
                        label: country.name,
                    };
                });
            },
            providesTags: ['Buildings'],
        }),
        getCountriesForSettings: build.query<any, void>({
            query: () => {
                return {
                    url: `i18n/countries`,
                };
            },
            transformResponse: (response: CountryCode[]) => {
                return response.map((country) => {
                    return {
                        value: country.code2,
                        label: country.name,
                    };
                });
            },
            providesTags: ['Buildings'],
        }),
        getStates: build.query<any, void>({
            query: () => {
                return {
                    url: `i18n/states`,
                };
            },
            providesTags: ['Buildings'],
        }),
        getStatesForDropdown: build.query<any, void>({
            query: () => {
                return {
                    url: `i18n/states`,
                };
            },
            transformResponse: (response: StateCode[]) => {
                return response.map((state) => {
                    return {
                        value: state.id,
                        label: state.code + ' - ' + state.name,
                    };
                });
            },
            providesTags: ['Buildings'],
        }),
        getLanguages: build.query<any, void>({
            query: () => {
                return {
                    url: `i18n/languages`,
                };
            },
            providesTags: ['Languages'],
        }),
        listLanguagesDropdown: build.query<any, void>({
            query: () => {
                return {
                    url: `i18n/languages`,
                };
            },
            transformResponse: (response: Language[]) => {
                return response.map((language: Language) => {
                    return {
                        value: language.id,
                        description: language.name,
                        label: language.code + ' - ' + language.name,
                    };
                });
            },
            providesTags: ['Languages'],
        }),
        listUnitOfMeasure: build.query<any, void>({
            query: () => {
                return {
                    url: `setup/unitOfMeasure`,
                };
            },
            providesTags: ['UnitOfMeasure'],
        }),
        listUnitOfMeasureDropdown: build.query<any, void>({
            query: () => {
                return {
                    url: `setup/unitOfMeasure`,
                };
            },
            transformResponse: (response: UnitOfMeasure[]) => {
                return response.map((unitOfMeasure: UnitOfMeasure) => {
                    return {
                        value: unitOfMeasure.id,
                        description: unitOfMeasure.description,
                        label:
                            unitOfMeasure.unitCode +
                            ' - ' +
                            unitOfMeasure.description,
                        unitTypeId: unitOfMeasure.unitTypeId,
                        id: unitOfMeasure.id,
                        unitCode: unitOfMeasure.unitCode,
                    };
                });
            },
            providesTags: ['UnitOfMeasure'],
        }),
        listUnitOfMeasureAsChild: build.query<any, void>({
            query: () => {
                return {
                    url: `setup/unitOfMeasureAsChild`,
                };
            },
            providesTags: ['UnitOfMeasure'],
        }),
        listUnitOfMeasureDropdownAsChild: build.query<any, void>({
            query: () => {
                return {
                    url: `setup/unitOfMeasureAsChild`,
                };
            },
            transformResponse: (response: UnitOfMeasure[]) => {
                return response.map((unitOfMeasure: UnitOfMeasure) => {
                    return {
                        value: unitOfMeasure.id,
                        description: unitOfMeasure.description,
                        label:
                            unitOfMeasure.unitCode +
                            ' - ' +
                            unitOfMeasure.description,
                        unitTypeId: unitOfMeasure.unitTypeId,
                        id: unitOfMeasure.id,
                        unitCode: unitOfMeasure.unitCode,
                    };
                });
            },
            providesTags: ['UnitOfMeasure'],
        }),
        listUnitOfMeasureGlobal: build.query<any, void>({
            query: () => {
                return {
                    url: `setup/unitOfMeasureGlobal`,
                };
            },
            providesTags: ['UnitOfMeasureGlobal'],
        }),
        listUnitOfMeasureGlobalDropdown: build.query<any, void>({
            query: () => {
                return {
                    url: `setup/unitOfMeasureGlobal`,
                };
            },
            transformResponse: (response: UnitOfMeasureGlobal[]) => {
                return response.map((unitOfMeasure: UnitOfMeasureGlobal) => {
                    return {
                        value: unitOfMeasure.id,
                        description: unitOfMeasure.description,
                        label:
                            unitOfMeasure.unitCode +
                            ' - ' +
                            unitOfMeasure.description,
                        unitTypeId: unitOfMeasure.unitTypeId,
                        id: unitOfMeasure.id,
                        unitCode: unitOfMeasure.unitCode,
                    };
                });
            },
            providesTags: ['UnitOfMeasureGlobal'],
        }),
        createUnitOfMeasure: build.mutation<
            any,
            BaseCreateEntityArgs<UnitOfMeasure>
        >({
            query: (args) => ({
                url: `setup/unitOfMeasure`,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['UnitOfMeasure', 'Revisions'],
        }),
        getUnitOfMeasure: build.query<any, string>({
            query: (id) => {
                return {
                    url: `setup/unitOfMeasure/${id}`,
                };
            },
            providesTags: ['ActiveUnitOfMeasure'],
        }),
        getUnitTypesForDropdown: build.query<
            { label: string; value: bigint }[],
            void
        >({
            query: () => `unitType`,
            transformResponse: (response: UnitType[]) => {
                return response.map((unitType: UnitType) => {
                    return {
                        label: unitType.type,
                        value: unitType.id,
                    };
                });
            },
            providesTags: ['UnitType'],
        }),
        updateUnitOfMeasure: build.mutation<
            any,
            BaseUpdateEntityArgs<UnitOfMeasure>
        >({
            query: (args) => ({
                url: `setup/unitOfMeasure/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: [
                'UnitOfMeasure',
                'ActiveUnitOfMeasure',
                'Revisions',
            ],
        }),
        deleteUnitOfMeasure: build.mutation<UnitOfMeasure, string>({
            query: (id) => ({
                url: `setup/unitOfMeasure/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['UnitOfMeasure', 'Revisions'],
        }),
        listUnitOfMeasureConversion: build.query<any, void>({
            query: () => {
                return {
                    url: `setup/unitOfMeasureConversion`,
                };
            },
            providesTags: ['UnitOfMeasureConversion'],
        }),
        createUnitOfMeasureConversion: build.mutation<
            any,
            BaseCreateEntityArgs<UnitOfMeasureConversion>
        >({
            query: (args) => ({
                url: `setup/unitOfMeasureConversion`,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['UnitOfMeasureConversion'],
        }),
        getUnitOfMeasureConversion: build.query<any, string>({
            query: (id) => {
                return {
                    url: `setup/unitOfMeasureConversion/${id}`,
                };
            },
            providesTags: ['ActiveUnitOfMeasureConversion'],
        }),
        updateUnitOfMeasureConversion: build.mutation<
            any,
            BaseUpdateEntityArgs<UnitOfMeasureConversion>
        >({
            query: (args) => ({
                url: `setup/unitOfMeasureConversion/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: [
                'UnitOfMeasureConversion',
                'ActiveUnitOfMeasureConversion',
            ],
        }),
        deleteUnitOfMeasureConversion: build.mutation<
            UnitOfMeasureConversion,
            string
        >({
            query: (id) => ({
                url: `setup/unitOfMeasureConversion/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['UnitOfMeasureConversion'],
        }),
        listProperShippingName: build.query<any, void>({
            query: () => {
                return {
                    url: `product/properShippingName`,
                };
            },
            providesTags: ['ProperShippingName'],
        }),
        listProperShippingNameAsChild: build.query<any, void>({
            query: () => {
                return {
                    url: `product/properShippingNameAsChild`,
                };
            },
            providesTags: ['ProperShippingName'],
        }),
        createProperShippingName: build.mutation<
            any,
            BaseCreateEntityArgs<ProperShippingName>
        >({
            query: (args) => ({
                url: `product/properShippingName`,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['ProperShippingName'],
        }),
        getProperShippingName: build.query<any, string>({
            query: (id) => {
                return {
                    url: `product/properShippingName/${id}`,
                };
            },
            providesTags: ['ActiveProperShippingName'],
        }),
        updateProperShippingName: build.mutation<
            any,
            BaseUpdateEntityArgs<ProperShippingName>
        >({
            query: (args) => ({
                url: `product/properShippingName/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['ProperShippingName', 'ActiveProperShippingName'],
        }),
        deleteProperShippingName: build.mutation<ProperShippingName, string>({
            query: (id) => ({
                url: `product/properShippingName/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['ProperShippingName'],
        }),
        listProperShippingNameDropdown: build.query<any, void>({
            query: () => {
                return {
                    url: `product/properShippingNameAsChild`,
                };
            },
            transformResponse: (response: ProperShippingName[]) => {
                return response.map(
                    (properShippingName: ProperShippingName) => {
                        return {
                            value: properShippingName.id,
                            label: properShippingName.code,
                            description: properShippingName.properShippingName,
                        };
                    }
                );
            },
            providesTags: ['ProperShippingName'],
        }),
        getRevisions: build.query<any, any>({
            query: (args) => {
                return {
                    url: `revisions`,
                    params: {
                        entityId: args?.entityId || null,
                        isSummary: args?.isSummary || null,
                        entityUuid: args?.entityUuid || null,
                    },
                };
            },
            transformResponse: (response: any[]) => {
                return response.sort((a, b) => {
                    const dateA: any = new Date(a.createdAt);
                    const dateB: any = new Date(b.createdAt);
                    return dateB - dateA;
                });
            },
            providesTags: ['Revisions'],
        }),
        getRevision: build.query<any, string>({
            query: (id) => {
                return {
                    url: `revisions/${id}`,
                };
            },
            providesTags: ['Revisions'],
        }),
        createRevision: build.mutation<any, BaseUpdateEntityArgs<any>>({
            query: (args) => {
                return {
                    url: `revisions`,
                    method: 'PUT',
                    body: args.postBody,
                };
            },
            invalidatesTags: ['Revisions'],
        }),
        createBoilerPlateNote: build.mutation<
            any,
            BaseCreateEntityArgs<BoilerPlateNote>
        >({
            query: (args) => ({
                url: `settings/boilerPlateNote`,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['BoilerPlateNote'],
        }),
        updateBoilerPlateNote: build.mutation<
            any,
            BaseUpdateEntityArgs<BoilerPlateNote>
        >({
            query: (args) => ({
                url: `settings/boilerPlateNote/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['BoilerPlateNote', 'ActiveBoilerPlateNote'],
        }),
        listBoilerPlateNote: build.query<any, void>({
            query: () => {
                return {
                    url: `settings/boilerPlateNote`,
                };
            },
            providesTags: ['BoilerPlateNote'],
        }),
        listBoilerPlateNoteAsChild: build.query<any, void>({
            query: () => {
                return {
                    url: `settings/boilerPlateNoteAsChild`,
                };
            },
            providesTags: ['BoilerPlateNote'],
        }),
        getBoilerPlateNote: build.query<any, string>({
            query: (id) => {
                return {
                    url: `settings/boilerPlateNote/${id}`,
                };
            },
            providesTags: ['ActiveBoilerPlateNote'],
        }),
        deleteBoilerPlateNote: build.mutation<any, string>({
            query: (id) => ({
                url: `settings/boilerPlateNote/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['BoilerPlateNote'],
        }),
        getSearchParams: build.query<any, void>({
            query: () => {
                return {
                    url: `revisions/searchParams`,
                };
            },
            transformResponse: (response: any) => {
                const actions = response.actions.map((action: any) => {
                    return {
                        value: action.id,
                        label: action.name,
                    };
                });

                const sources = response.sources.map((source: any) => {
                    return {
                        value: source.id,
                        label: source.name,
                    };
                });

                const modules = response.modules.map((module: any) => {
                    return {
                        value: module.id,
                        label: module.module,
                    };
                });

                //used to group modules by their name
                const groupBy = (objectArray: any, property: any) => {
                    return objectArray.reduce((acc: any, obj: any) => {
                        const key = obj[property];
                        const curGroup = acc[key] ?? [];

                        return { ...acc, [key]: [...curGroup, obj] };
                    }, {});
                };

                const grouped = groupBy(modules, 'label');

                let final: any = [];

                // combine all ids of the same name into a single object
                Object.keys(grouped).forEach((key) => {
                    let newItem: any = { value: [], label: null };
                    grouped[key].forEach((item: any) => {
                        newItem.value.push(item.value);
                        newItem.label = item.label;
                    });
                    final.push(newItem);
                });

                return { modules: final, sources: sources, actions: actions };
            },
            providesTags: ['Revisions'],
        }),
        search: build.mutation<any, any>({
            query: (args) => {
                return {
                    url: `revisions/search`,
                    method: 'POST',
                    body: args,
                };
            },
            invalidatesTags: ['Revisions'],
        }),
        getEntitySetting: build.query<any, any>({
            query: (args) => {
                return {
                    url: `settings/entity-setting`,
                    params: {
                        uuid: args.uuid,
                        settingKey: args.settingKey,
                        subKey: args.subKey,
                    },
                };
            },
            providesTags: ['EntitySetting'],
        }),
        listEntitySettings: build.query<any, void>({
            query: () => {
                return {
                    url: `settings/entity-settings`,
                };
            },
            providesTags: ['EntitySetting'],
        }),
        listGridLayouts: build.query<any, any>({
            query: (args) => {
                return {
                    url: `settings/grid-layouts`,
                    params: {
                        settingKey: args.settingKey,
                    },
                };
            },
            providesTags: ['EntitySetting'],
        }),
        updateEntitySetting: build.mutation<any, BaseUpdateEntityArgs<any>>({
            query: (args) => ({
                url: `settings/entity-settings/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['EntitySetting'],
        }),
        updateCurrentGridLayout: build.mutation<any, BaseUpdateEntityArgs<any>>(
            {
                query: (args) => ({
                    url: `settings/grid-layouts/${args.id}`,
                    method: 'PUT',
                    body: args.postBody,
                }),
                invalidatesTags: ['EntitySetting'],
            }
        ),
        deleteGridLayout: build.mutation<any, any>({
            query: (id) => ({
                url: `settings/grid-layouts/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['EntitySetting'],
        }),
        createEntitySetting: build.mutation<any, BaseCreateEntityArgs<any>>({
            query: (args) => ({
                url: `settings/entity-settings`,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['EntitySetting'],
        }),
        createUserHistory: build.mutation<any, any>({
            query: (args) => {
                return {
                    url: `core-entities/user-history`,
                    method: 'POST',
                    body: args,
                };
            },
        }),
        getUserHistory: build.query<any, void>({
            query: () => {
                return {
                    url: `core-entities/user-history/get`,
                };
            },
        }),
    }),
});

export const {
    useCreateSubscriberMutation,
    useUpdateSubscriberMutation,
    useGetSubscribersQuery,
    useGetSubscriberQuery,
    useGetSubscribersForDropdownQuery,
    useGetDeparmentCodesForDropdownQuery,
    useCreateBusinessEntityMutation,
    useUpdateBusinessEntityMutation,
    useGetBusinessEntitiesQuery,
    useGetBusinessEntitiesForDropdownQuery,
    useGetBusinessEntitiesByIdQuery,
    useGetBusinessEntityChildrenQuery,
    useGetBusinessEntityQuery,
    useGetRolesByBusinessEntitiesForDropdownQuery,
    useGetBusinessEntityFullQuery,
    useGetBusinessEntityTypesQuery,
    useGetBusinessEntityTypesForDropdownQuery,
    useGetBusinessEntityTypeQuery,
    useCreateBusinessEntityTypeMutation,
    useUpdateBusinessEntityTypeMutation,
    useGetUsersQuery,
    useSetAndGetUserTempCredentialsMutation,
    useGetBusinessEntityUsersQuery,
    useGetBusinessEntityUsersForDropdownQuery,
    useResendUserTempCredentialsMutation,
    useGetUsersBySubscriberQuery,
    useGetUsersBySubscriberForDropdownQuery,
    useGetUsersForDropdownQuery,
    useGetUserQuery,
    useGetUserDetailsQuery,
    useGetUserPermissionsQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useUpdateUserEmailMutation,
    useGetGroupsQuery,
    useGetGroupsByBusinessEntitiesForDropdownQuery,
    useGetGroupsForDropdownQuery,
    useGetGroupQuery,
    useCreateGroupMutation,
    useUpdateGroupMutation,
    useGetRolesQuery,
    useGetRolesForDropdownQuery,
    useGetRoleQuery,
    useCreateRoleMutation,
    useUpdateRoleMutation,
    useGetPermissionsQuery,
    useGetPermissionsForDropdownQuery,
    useGetPermissionQuery,
    useCreatePermissionMutation,
    useUpdatePermissionMutation,
    useGetDepartmentCodesQuery,
    useGetDepartmentCodesForDropdownQuery,
    useGetDepartmentCodeQuery,
    useCreateDepartmentCodeMutation,
    useUpdateDepartmentCodeMutation,
    useDeleteDepartmentCodeMutation,
    useGetMessagingPreferencesForDropdownQuery,
    useGetBuildingsQuery,
    useGetBuildingQuery,
    useUpdateBuildingMutation,
    useCreateBuildingMutation,
    useGetCountriesQuery,
    useGetStatesQuery,
    useGetCountriesForDropdownQuery,
    useGetCountriesForSettingsQuery,
    useGetStatesForDropdownQuery,
    useGetLanguagesQuery,
    useListLanguagesDropdownQuery,
    useListUnitOfMeasureQuery,
    useListUnitOfMeasureDropdownQuery,
    useListUnitOfMeasureGlobalQuery,
    useListUnitOfMeasureGlobalDropdownQuery,
    useListUnitOfMeasureDropdownAsChildQuery,
    useListUnitOfMeasureAsChildQuery,
    useCreateUnitOfMeasureMutation,
    useGetUnitOfMeasureQuery,
    useGetUnitTypesForDropdownQuery,
    useUpdateUnitOfMeasureMutation,
    useDeleteUnitOfMeasureMutation,
    useListUnitOfMeasureConversionQuery,
    useCreateUnitOfMeasureConversionMutation,
    useGetUnitOfMeasureConversionQuery,
    useUpdateUnitOfMeasureConversionMutation,
    useDeleteUnitOfMeasureConversionMutation,
    useListProperShippingNameQuery,
    useListProperShippingNameAsChildQuery,
    useCreateProperShippingNameMutation,
    useGetProperShippingNameQuery,
    useUpdateProperShippingNameMutation,
    useDeleteProperShippingNameMutation,
    useListProperShippingNameDropdownQuery,
    useGetRevisionsQuery,
    useGetRevisionQuery,
    useCreateRevisionMutation,
    useGetSearchParamsQuery,
    useSearchMutation,
    useCreateBoilerPlateNoteMutation,
    useUpdateBoilerPlateNoteMutation,
    useDeleteBoilerPlateNoteMutation,
    useGetBoilerPlateNoteQuery,
    useListBoilerPlateNoteQuery,
    useListBoilerPlateNoteAsChildQuery,
    useGetEntitySettingQuery,
    useListEntitySettingsQuery,
    useListGridLayoutsQuery,
    useUpdateEntitySettingMutation,
    useUpdateCurrentGridLayoutMutation,
    useCreateEntitySettingMutation,
    useGetUsersWithEmailForDropdownQuery,
    useDeleteGridLayoutMutation,
    useGetDepartmentCodesForSubscriberQuery,
    useCreateUserHistoryMutation,
    useGetUserHistoryQuery,
} = organizationsApi;

export interface DepartmentCode {
    id?: bigint;
    uuid?: string;
    name: string;
    code: string;
    subscriberId: bigint;
    createdAt?: string;
    updatedAt?: string;
}

export interface Building {
    id?: bigint;
    uuid?: string;
    address: Address;
    addressId: bigint;
    businessEntityId: bigint;
    code: string;
    createdAt?: string;
    updatedAt?: string;
    customerId?: bigint;
    name?: string;
    note?: string;
    supplierId?: bigint;
}

export interface Address {
    id?: bigint;
    uuid?: string;
    countryCode: CountryCode;
    countryCodeId: bigint;
    createdAt?: string;
    updatedAt?: string;
    postalCode: string;
    stateCode: StateCode;
    stateCodeId: bigint;
    street: string;
    city: string;
    county?: string;
}

export interface CountryCode {
    id?: bigint;
    code2?: string;
    code3?: string;
    createdAt?: string;
    updatedAt?: string;
    iso?: string;
    longName?: string;
    name: string;
    numericCode?: string;
}

export interface StateCode {
    id?: bigint;
    uuid?: string;
    category: string;
    code?: string;
    code2?: string;
    countryCodeId?: bigint;
    createdAt?: string;
    updatedAt?: string;
    name: string;
}

export interface Subscriber {
    id: bigint;
    name: string;
    uuid: string;
    dynamoKey?: string;
    description: string;
    departmentCodes?: DepartmentCode[];
    userPoolId?: string;
    createdAt: string;
    updatedAt: string;
    isDatacor: boolean;
}

export interface BusinessEntityType {
    id: bigint;
    uuid: string;
    name: string;
}

export interface BusinessEntity {
    id: bigint;
    parentId: bigint;
    dynamoKey?: string;
    businessEntityTypeId: bigint;
    subscriberId: bigint;
    subscriber: Subscriber;
    children: BusinessEntity[];
    name: string;
    uuid: string;
    description: string;
    businessEntityType?: BusinessEntityType;
    createdAt: string;
    updatedAt: string;
    externalAuthorization?: boolean;
    externalAuthDetails?: ExternalAuthDetails;
    zone?: Zone;
}

export interface Zone {
    id?: string;
    platform: string;
    url: string;
    port: string;
}

export interface ExternalAuthDetails {
    appClientId: string;
    appClientSecret: string;
}

export interface Permission {
    id: bigint;
    uuid: string;
    datacorOnly: boolean;
    name: string;
    code: string;
    featureFlags: FeatureFlag[];
}

export interface Group {
    id?: bigint;
    uuid?: string;
    name: string;
    description: string;
    businessEntityId?: bigint;
    users?: User[];
    businessEntity?: BusinessEntity;
}

export interface Role {
    id?: bigint;
    uuid?: string;
    name: string;
    description: string;
    businessEntityId?: bigint;
    users?: User[];
    permissions?: Permission[];
    businessEntity?: BusinessEntity;
}

export interface User {
    id?: bigint;
    active?: boolean;
    email?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    mobilePhone?: string;
    businessEntityId?: bigint;
    businessEntity?: BusinessEntity;
    subscriberId?: bigint;
    messagingPrefId?: bigint;
    groups?: Group[];
    roles?: Role[];
    permissions?: Permissions[];
    isDatacorAdmin?: boolean;
    applyAccessRestrictions?: boolean;
    isAppStreamUser?: boolean;
    activeWebBusinessEntityId?: bigint;
    activeWebBusinessEntity?: BusinessEntity;
    externalUser?: string;
    userPoolId?: string;
    subscribers?: Subscriber[] | bigint[];
    businessEntities?: BusinessEntity[] | bigint[];
    apAutomationInvoiceTargetBusinessEntityId?: bigint;
}

export interface Employee {
    id?: bigint;
    departmentCodeId: bigint;
    supervisorId: bigint;
    userId: string;
}

export interface UserTrial {
    id?: bigint;
    isTrialUser: bigint;
    trialStartDate: string;
    trialEndDate: string;
}

export interface MessagingPreference {
    id: bigint;
    name: string;
}

export interface Language {
    id?: bigint;
    code?: string;
    name: string;
    iso2?: string;
    iso3?: string;
    createdAt?: string;
    updatedAt?: string;
}

export interface RevisionSummary {
    id: bigint;
    createdAt: string;
    revisionAction: {
        name: string;
    };
    user: {
        firstName: string;
        lastName: string;
    };
}

export interface Revision {
    id: bigint;
    entityUuid?: string;
    businessEntity: BusinessEntity;
    businessEntityId: bigint;
    createdAt: string;
    entityId: bigint;
    revisionAction: RevisionAction;
    revisionActionId: bigint;
    revisionEntityType: RevisionEntityType;
    revisionEntityTypeId: bigint;
    revisionSource: RevisionSource;
    revisionSourceId: bigint;
    subscriber: Subscriber;
    subscriberId: bigint;
    updatedAt: string;
    user: User;
    userId: bigint;
    uuid: string;
    delta: string;
}

export interface RevisionSource {
    id: bigint;
    createdAt: string;
    updatedAt: string;
    code: string;
    name: string;
}

export interface RevisionEntityType {
    id: bigint;
    createdAt: string;
    updatedAt: string;
    module: string;
    path: string;
    name: string;
    code: string;
}

export interface RevisionAction {
    id: bigint;
    createdAt: string;
    code: string;
    name: string;
    updatedAt: string;
}

export interface EntitySetting {
    id?: bigint;
    uuid: string;
    settingKey: string;
    subKey?: string;
    value: string;
    current: boolean;
    owner: string;
    createdAt?: string;
    updatedAt?: string;
}
