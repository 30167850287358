import React, { useContext, useEffect } from 'react';
import {
    useGetGroupsForDropdownQuery,
    useGetUsersForDropdownQuery,
} from '../../../../services/organizations/organizations.service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import {
    useAssignTaskMutation,
    useCreateTaskMutation,
    useListTaskTypesQuery,
} from '../../../../services/task-manager/task-manager.service';
import { useListEntityTypesQuery } from '../../../../services/system/entityType.service';
import GlobalEntityDataContext from '../../../../contexts/globalEntityData.context';

const useEntityTaskModal = (props: any) => {
    const { open, setOpen, taskType } = props;
    const { data: users } = useGetUsersForDropdownQuery();
    const { data: groups } = useGetGroupsForDropdownQuery();
    const user = useSelector((state: RootState) => state.user);
    const { data: taskTypes } = useListTaskTypesQuery(null);
    const { data: entityTypes } = useListEntityTypesQuery();
    const [createTask] = useCreateTaskMutation();
    const [assignTask] = useAssignTaskMutation();
    const [taskFields, setTaskFields] = React.useState<any>({
        title: '',
        notes: '',
        dueAt: null,
        taskTypeId: '',
        entityTypeId: '',
        entityUuid: null,
        entityPath: null,
        entityPageTitle: null,
        taskUsers: [],
        taskGroups: [],
    });

    const { entityData } = useContext(GlobalEntityDataContext);

    useEffect(() => {
        handleSetTaskFields();
    }, [open]);

    const handleSetTaskFields = () => {
        setTaskFields((prev: any) => ({
            ...prev,
            taskTypeId: taskType?.id,
        }));
    };

    useEffect(() => {
        if (entityData?.entityType && !taskFields?.createdByUserId) {
            setTaskFields((prev: any) => ({
                ...prev,
                entityTypeId: entityData?.entityType?.id,
                entityUuid: entityData?.entityUuid || null,
                entityPath: entityData?.fullPath || null,
                entityPageTitle: entityData?.pageTitle || null,
            }));
        }
    }, [entityData]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTaskFields((prev: any) => ({
            ...prev,
            [event.target.id]: event.target.value,
        }));
    };

    const handleAddTask = async () => {
        const createArgs = {
            title: taskFields.title,
            notes: taskFields.notes,
            dueAt: taskFields.dueAt,
            taskTypeId: taskFields.taskTypeId || null,
            entityTypeId: taskFields.entityTypeId || null,
            entityPath: taskFields?.entityPath || null,
            entityPageTitle: taskFields?.entityPageTitle || null,
            entityUuid: taskFields.entityUuid || null,
        };

        const response = await createTask(createArgs).unwrap();
        const assignedUsers = taskFields.taskUsers?.map((user: any) => {
            return { id: user?.value || user?.id };
        });

        const assignedGroups = taskFields.taskGroups?.map((group: any) => {
            return { id: group?.value || group?.id };
        });

        assignTask({
            id: response?.id,
            postBody: {
                users: assignedUsers,
                groups: assignedGroups,
            },
        });

        setOpen(false);
    };

    const getUserDisplayNameById = (id: string) => {
        return users?.find((user: any) => user.value === id)?.label;
    };

    const handleCreateTaskTitle = () => {
        if (taskFields?.createdByUserId) {
            return (
                <>
                    Assigned By:{' '}
                    {getUserDisplayNameById(taskFields?.createdByUserId)}
                </>
            );
        } else {
            return 'Add New Task';
        }
    };

    return {
        taskFields,
        setTaskFields,
        handleChange,
        handleAddTask,
        handleClose,
        handleCreateTaskTitle,
        entityTypes,
        users,
        groups,
        taskTypes,
    };
};

export default useEntityTaskModal;
