import React, { useEffect, useState, useRef } from 'react';
import TranslatableText from '../i18n/TranslatableText';
import './style.css';
import { Tooltip } from '@mui/material';

const CustomHeader = (props: any) => {
    const [sort, setSort] = useState(null);
    const [isHovered, setIsHovered] = useState(false);

    const refButton = useRef(null);

    const onMenuClicked = () => {
        props.showColumnMenu(refButton.current!);
    };

    const onMouseEnter = () => {
        setIsHovered(true);
    };

    const onMouseExit = () => {
        setIsHovered(false);
    };

    useEffect(() => {
        let columnState = null;

        if (props.column.columnApi) {
            columnState = props.column.columnApi
                .getColumnState()
                .find((col: any) => {
                    return col.colId === props.column.colId;
                });
        }

        setSort(columnState ? columnState.sort : null);
    }, [props.api, props.displayName]);

    const handleSort = () => {
        const currentSort = props.column.getSort();
        let newSort = 'asc';

        if (currentSort === 'asc') {
            newSort = 'desc';
        } else if (currentSort === 'desc') {
            newSort = null;
        }

        setSort(newSort);
        props.setSort(newSort);
        props.column.setSort(newSort);
    };

    let sortIcon = null;

    if (sort === 'asc') {
        sortIcon = (
            <span className="ag-icon ag-icon-small-left-spacing ag-icon-desc" />
        );
    } else if (sort === 'desc') {
        sortIcon = (
            <span className="ag-icon ag-icon-small-left-spacing ag-icon-asc" />
        );
    } else {
        sortIcon = (
            <span className="ag-icon ag-icon-small-left-spacing ag-icon-none" />
        );
    }

    const getTooltipText = () => {
        if (props.termKey) {
            return props.termSet?.languageTermSetDef?.termDefs?.find(
                (term: any) => term.code === props.termKey + '-helper'
            )?.defaultValue;
        }
        return null;
    };

    return (
        <>
            <div
                className="custom-header-container"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseExit}
                onClick={handleSort}>
                <Tooltip title={getTooltipText()} arrow placement={'top'}>
                    <div className="custom-header-container">
                        <span style={{ whiteSpace: 'pre-wrap' }}>
                            <TranslatableText
                                termSet={props.termSet}
                                termKey={props.termKey}
                            />
                        </span>
                        <span className="custom-header-sort">{sortIcon}</span>
                    </div>
                </Tooltip>
            </div>
            <span
                id={'menu-icon'}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseExit}
                className={!isHovered ? '' : 'ag-icon ag-icon-menu'}
                onClick={onMenuClicked}
                ref={refButton}
                style={{ float: 'right' }}
            />
        </>
    );
};

export default CustomHeader;
