import React from 'react';
import { Box, SxProps, Theme } from '@mui/material';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    sx?: SxProps<Theme>;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, sx, ...other } = props;
    const customSx = { ...sx, height: '100%' };
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && <Box sx={customSx}>{children}</Box>}
        </div>
    );
};

export default TabPanel;
