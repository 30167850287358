import {
    Card,
    CardHeader,
    CardContent,
    Typography,
    CardActions,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import React from 'react';
import './SummaryPricingConcernCard.css';
import {
    currencyFormatter,
    percentFormatter,
} from '../../utils/formattingUtils';
import useSummaryPricingConcernCard from './hooks/useSummaryPricingConcernCard';

interface SummaryPricingConcernCardProps {
    activeCumulativeSummary: any;
    alertType: any;
    title: string;
    handleCheck: any;
}

const SummaryPricingConcernCard = ({
    activeCumulativeSummary,
    alertType,
    title,
    handleCheck = null,
}: SummaryPricingConcernCardProps) => {
    const { dollarsLostStyle, checked } = useSummaryPricingConcernCard({
        title,
        handleCheck,
        activeCumulativeSummary,
        alertType,
    });

    return (
        <Card
            className="text-center summaryPricingAlert-card"
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                backgroundColor: '#ceecf8',
            }}>
            <CardHeader
                style={{ backgroundColor: 'white' }}
                title={
                    <>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={alertType}
                                        className="mb-2 font-weight-bold"
                                        onChange={handleCheck}
                                        checked={checked}
                                    />
                                }
                                label={title}
                            />
                        </FormGroup>
                    </>
                }
            />

            <CardContent style={{ backgroundColor: '#ceecf8' }}>
                <Typography>
                    <span>
                        <label className="col-sm-8 font-weight-bold mb-0">
                            {'# Transactions: '}
                        </label>
                        {activeCumulativeSummary[`${alertType}DollarsLost`] < -1
                            ? activeCumulativeSummary[
                                  `${alertType}NumberOfTransactions`
                              ]
                            : 0}
                    </span>
                    <br />
                    <span>
                        <label className="col-sm-8 font-weight-bold mb-0">
                            {'Product Sales Dollars: '}
                        </label>
                        {currencyFormatter(
                            activeCumulativeSummary[`${alertType}DollarsLost`] <
                                -1
                                ? activeCumulativeSummary[
                                      `${alertType}TotalPrice`
                                  ]
                                : 0
                        )}
                    </span>
                    <br />
                    <span>
                        <label className="col-sm-8 font-weight-bold mb-0">
                            {'Margin: '}
                        </label>
                        {percentFormatter(
                            activeCumulativeSummary[`${alertType}DollarsLost`] <
                                -1
                                ? (activeCumulativeSummary[
                                      `${alertType}ProfitDollars`
                                  ] /
                                      activeCumulativeSummary[
                                          `${alertType}TotalPrice`
                                      ]) *
                                      100
                                : 0
                        )}
                    </span>
                    <br />
                    <span>
                        <label className="col-sm-8 font-weight-bold mb-0">
                            {'Profit Dollars: '}
                        </label>
                        {currencyFormatter(
                            activeCumulativeSummary[`${alertType}DollarsLost`] <
                                -1
                                ? activeCumulativeSummary[
                                      `${alertType}ProfitDollars`
                                  ]
                                : 0
                        )}
                    </span>
                    <br />
                </Typography>
            </CardContent>
            <CardActions style={dollarsLostStyle} className="font-weight-bold">
                Lost:{' '}
                {currencyFormatter(
                    activeCumulativeSummary[`${alertType}DollarsLost`] < -1
                        ? activeCumulativeSummary[`${alertType}DollarsLost`]
                        : 0
                )}
            </CardActions>
        </Card>
    );
};

export default SummaryPricingConcernCard;
