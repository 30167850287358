import React, { useState } from 'react';
import {
    useListAccountingMigrationsQuery,
    useListFormMigrationsQuery,
    useListPlatformMigrationsQuery,
    useListSupplyChainMigrationsQuery,
} from '../../services/sequelize/sequelize.service';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const ListMigrations = ({ migrations }: any) => {
    const [value, setValue] = useState('');

    return (
        <div style={{ height: '100%' }}>
            <div>
                <TextInput
                    value={value}
                    onChange={(e: any) => setValue(e.target.value)}
                    size={'small'}
                    label={'Search...'}
                />
            </div>
            <div style={{ padding: '10px' }} />
            {migrations &&
                migrations
                    .filter((item: any) => item.name.includes(value))
                    .map((migration: any) => (
                        <span key={migration.name}>
                            {migration.name}
                            <br />
                        </span>
                    ))}
        </div>
    );
};

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 3, height: '65vh' }}>{children}</Box>
            )}
        </div>
    );
};

const MigrationManager = () => {
    const [value, setValue] = useState(0);
    const { data: platMigrations } = useListPlatformMigrationsQuery();
    const { data: accountingMigrations } = useListAccountingMigrationsQuery();
    const { data: formMigrations } = useListFormMigrationsQuery();
    const { data: supplyChainMigrations } = useListSupplyChainMigrationsQuery();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box style={{ overflow: 'auto' }}>
            <Tabs
                value={value}
                onChange={handleChange}
                textColor="inherit"
                indicatorColor="secondary">
                <Tab label="Platform" />
                <Tab label="Accounting" />
                <Tab label="Formulation" />
                <Tab label="Supply Chain" />
            </Tabs>

            <TabPanel value={value} index={0}>
                <ListMigrations migrations={platMigrations} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ListMigrations migrations={accountingMigrations} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ListMigrations migrations={formMigrations} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <ListMigrations migrations={supplyChainMigrations} />
            </TabPanel>
        </Box>
    );
};

export default MigrationManager;
