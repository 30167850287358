import React from 'react';
import './DatacorLogoSpinner.css';
import datacorLogo from '../../libs/images/Datacor_Company_Logo_RGB_Icon.png';

interface DatacorLogoSpinnerProps {
    size?: string;
}

const DatacorLogoSpinner = ({ size }: DatacorLogoSpinnerProps) => {
    return (
        <div className="spinnerContainer" style={{ width: size }}>
            <img
                src={datacorLogo}
                className="loadingIcon"
                style={size ? { width: size, height: size } : null}
            />
        </div>
    );
};

export default DatacorLogoSpinner;
