import { Auth } from 'aws-amplify';

export const getCurrentUserCompanyId = async () => {
    const session: any = await Auth.currentSession();
    return session.idToken.payload['custom:companyIdWeb'].toString();
};

export const getCurrentUserName = async () => {
    const session: any = await Auth.currentSession();
    return `${session.idToken.payload.given_name} ${session.idToken.payload.family_name}`;
};
