import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
} from '@mui/material';
import LoaderButton from '../LoaderButton';
import { useRejectDocumentMutation } from '../../services/apAutomation/apAutomation.service';

interface RejectOptionsDialogProps {
    open: boolean;
    onClose: () => void;
    documentId: string;
}

const RejectOptionsDialog = ({
    open,
    onClose,
    documentId,
}: RejectOptionsDialogProps) => {
    const [rejectDocument, { isLoading }] = useRejectDocumentMutation();
    const [selectedReason, setSelectedReason] = useState('duplicate');
    const handleRejectInvoice = () => {
        const args = {
            documentId: documentId,
            reason: selectedReason,
        };

        rejectDocument(args);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedReason((event.target as HTMLInputElement).value);
    };

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>Select Rejection Reason</DialogTitle>
            <DialogContent>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={selectedReason}
                        onChange={handleChange}
                        name="radio-buttons-group">
                        <FormControlLabel
                            value="duplicate"
                            control={<Radio />}
                            label="Duplicate Invoice"
                        />
                        <FormControlLabel
                            value="incorrectInfo"
                            control={<Radio />}
                            label="Incorrect Information"
                        />
                        <FormControlLabel
                            value="wrongDivision"
                            control={<Radio />}
                            label="Wrong Division"
                        />
                        <FormControlLabel
                            value="mistake"
                            control={<Radio />}
                            label="Mistaken Upload"
                        />
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button color="error" onClick={onClose}>
                    Cancel
                </Button>
                <LoaderButton
                    variant="contained"
                    onClick={handleRejectInvoice}
                    isLoading={isLoading}>
                    Save Changes
                </LoaderButton>
            </DialogActions>
        </Dialog>
    );
};

export default RejectOptionsDialog;
