import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import { isFeatureFlagEnabled } from '../../utils/featureFlag/featureFlagUtil';
import { PermissionsUtil } from '../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../constants/permissions/Permissions.constants';
import { currencyFormatter, dateFormatter } from '../../utils/formattingUtils';
import SingleSelectGrid from '../../components/grids/SingleSelectGrid';
import DatacorLogoSpinner from '../../components/datacorLogoSpinner/DatacorLogoSpinner';
import {
    Button,
    Card,
    CardHeader,
    CardContent,
    IconButton,
} from '@mui/material';
import { numberAsStringComparator } from '../../utils/comparisonUtils';
import useDocumentListView from './hooks/useDocumentListView';
import GridOptionsPopover from '../../components/apAutomation/GridOptionsPopover';
import FileDropUploader from '../../components/dragAndDrop/FileDropUploader';
import './DocumentListView.css';
import { UserList } from '../../utils/users/userList';
import ArchiveIcon from '@mui/icons-material/Archive';
import ArchivedInvoicesModal from '../../components/apAutomation/invoiceArchive/ArchivedInvoicesModal';
import SettingsIcon from '@mui/icons-material/Settings';
import EntitySettingsDrawer from '../../components/entitySettings/entitySettingsDrawer';
import { isNilOrEmpty } from '../../utils/objectUtils';
import { GRID_SETTINGS } from '../../constants/entitySettings/entitySettings.constants';

interface DocumentListViewProps {
    showChangeDefaultApproversModal: () => void;
    showChangeDefaultToleranceModal: () => void;
    showChangeApprovalWorkflows: () => void;
}

const DocumentListView = ({
    showChangeDefaultApproversModal,
    showChangeDefaultToleranceModal,
    showChangeApprovalWorkflows,
}: DocumentListViewProps) => {
    const {
        user,
        userList,
        shouldLoad,
        filteredRowData,
        onGridReady,
        openDocument,
        getValuesAsync,
        handleUploadInvoices,
        handleOpenModal,
        openArchiveModal,
        handleClose,
        openSettings,
        setOpenSettings,
        gridRef,
    } = useDocumentListView();

    return (
        <>
            <Card style={{ height: '95%' }}>
                <CardHeader
                    style={{ height: '30px' }}
                    title="Document List"
                    action={
                        <>
                            {PermissionsUtil.isPermissionEnabled(
                                user.permissions,
                                PERMISSIONS.AP_AUTOMATION.INVOICES.UPDATE
                            ) ? (
                                <div className="document-list-view-default-buttons">
                                    <Button
                                        data-testid="changeDefaultApprovers"
                                        onClick={
                                            showChangeDefaultApproversModal
                                        }>
                                        Default Approvers
                                    </Button>
                                    {isFeatureFlagEnabled(
                                        user.enabledFeatureFlags,
                                        'feature.apps.apAutomation.toleranceMatching'
                                    ) &&
                                    PermissionsUtil.isPermissionEnabled(
                                        user.permissions,
                                        PERMISSIONS.AP_AUTOMATION
                                            .DEFAULT_TOLERANCE.VIEW
                                    ) ? (
                                        <Button
                                            data-testid="changeDefaultTolerance"
                                            onClick={
                                                showChangeDefaultToleranceModal
                                            }>
                                            Default Tolerance
                                        </Button>
                                    ) : null}
                                    {isFeatureFlagEnabled(
                                        user.enabledFeatureFlags,
                                        'feature.apps.apAutomation.approvalWorkflows'
                                    ) &&
                                    PermissionsUtil.isPermissionEnabled(
                                        user.permissions,
                                        PERMISSIONS.AP_AUTOMATION
                                            .APPROVAL_WORKFLOWS.VIEW
                                    ) ? (
                                        <Button
                                            data-testid="changeDefaultApprovalWorkflows"
                                            onClick={
                                                showChangeApprovalWorkflows
                                            }>
                                            Approval Workflows
                                        </Button>
                                    ) : null}
                                </div>
                            ) : null}
                        </>
                    }
                />
                <CardContent style={{ height: '100%' }}>
                    {!shouldLoad ? (
                        <>
                            <ArchivedInvoicesModal
                                handleClose={handleClose}
                                open={openArchiveModal}
                            />
                            <EntitySettingsDrawer
                                open={openSettings}
                                setOpen={setOpenSettings}
                                settingKey={GRID_SETTINGS.APA_DOCUMENT_GRID}
                                settingValue={gridRef}
                                type={'Grid'}
                                saveNewText={'Save New Grid Layout'}
                                currentSelectionText={
                                    'Your Current Grid Layout'
                                }
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}>
                                <FileDropUploader
                                    onDrop={handleUploadInvoices}
                                />
                                <GridOptionsPopover />
                                <IconButton onClick={handleOpenModal}>
                                    <ArchiveIcon />
                                </IconButton>
                                <IconButton
                                    onClick={() => setOpenSettings(true)}>
                                    <SettingsIcon />
                                </IconButton>
                            </div>

                            <SingleSelectGrid
                                gridRef={gridRef}
                                testId="document-list-grid"
                                rowBuffer={0}
                                domLayout={null}
                                paginationPageSize={null}
                                pagination={false}
                                maxConcurrentDatasourceRequests={1}
                                cacheBlockSize={50}
                                cacheOverflowSize={2}
                                infiniteInitialRowCount={100}
                                maxBlocksInCache={5}
                                gridStyle={{
                                    height: '75%',
                                    width: '100%',
                                    overflow: 'scroll',
                                }}
                                immutableData={true}
                                dataKey="documentId"
                                rowModelType="infinite"
                                onGridReady={onGridReady}
                                onRowClicked={openDocument}
                                rowStyle={{ cursor: 'pointer' }}
                                defaultColDef={{
                                    resizable: true,
                                    sortable: true,
                                    unSortIcon: true,
                                    filter: true,
                                    wrapText: true,
                                    floatingFilter: true,
                                }}>
                                <AgGridColumn
                                    minWidth={250}
                                    field="uploader"
                                    headerName="Uploader"
                                    valueFormatter={(val) =>
                                        new UserList(
                                            userList
                                        ).getUsersFullNameFromEmail(
                                            val.value,
                                            true
                                        )
                                    }
                                    filter="agTextColumnFilter"
                                    filterParams={(params: any) => {
                                        const emailVals = getValuesAsync(
                                            params,
                                            'uploader'
                                        );
                                        const nameVals = emailVals.map(
                                            (email: string) => {
                                                return new UserList(
                                                    userList
                                                ).getUsersFullNameFromEmail(
                                                    email,
                                                    true
                                                );
                                            }
                                        );
                                        return {
                                            values: nameVals,
                                            suppressAndOrCondition: true,
                                        };
                                    }}
                                />
                                <AgGridColumn
                                    minWidth={150}
                                    field="invoiceNumber"
                                    headerName="Invoice Number"
                                    filter="agTextColumnFilter"
                                    filterParams={(params: any) => {
                                        return {
                                            values: getValuesAsync(
                                                params,
                                                'invoiceNumber'
                                            ),
                                            suppressAndOrCondition: true,
                                        };
                                    }}
                                />
                                <AgGridColumn
                                    minWidth={125}
                                    field="supplierNumber"
                                    headerName="Supplier Number"
                                    filter="agTextColumnFilter"
                                    filterParams={(params: any) => {
                                        return {
                                            values: getValuesAsync(
                                                params,
                                                'supplierNumber'
                                            ),
                                            suppressAndOrCondition: true,
                                        };
                                    }}
                                />
                                <AgGridColumn
                                    minWidth={200}
                                    field="supplierName"
                                    headerName="Supplier Name"
                                    filter="agTextColumnFilter"
                                    filterParams={(params: any) => {
                                        return {
                                            values: getValuesAsync(
                                                params,
                                                'supplierName'
                                            ),
                                            suppressAndOrCondition: true,
                                        };
                                    }}
                                />
                                <AgGridColumn
                                    minWidth={150}
                                    field="due"
                                    headerName="Amount"
                                    valueFormatter={currencyFormatter}
                                    comparator={numberAsStringComparator}
                                    filter="agTextColumnFilter"
                                    filterParams={(params: any) => {
                                        const amountVals = getValuesAsync(
                                            params,
                                            'due'
                                        );
                                        const formattedDueVals = amountVals.map(
                                            (amount: string) => {
                                                return currencyFormatter(
                                                    amount
                                                );
                                            }
                                        );
                                        return {
                                            values: formattedDueVals,
                                            suppressAndOrCondition: true,
                                        };
                                    }}
                                />
                                <AgGridColumn
                                    minWidth={175}
                                    field="invoiceDate"
                                    headerName="Invoice Date"
                                    filter="agTextColumnFilter"
                                    valueFormatter={dateFormatter}
                                    filterParams={(params: any) => {
                                        const dateVals = getValuesAsync(
                                            params,
                                            'invoiceDate'
                                        );
                                        const formattedDateVals = dateVals.map(
                                            (date: string) => {
                                                return dateFormatter(date);
                                            }
                                        );
                                        return {
                                            values: formattedDateVals,
                                            suppressAndOrCondition: true,
                                        };
                                    }}
                                />
                                <AgGridColumn
                                    minWidth={175}
                                    field="uploadDate"
                                    headerName="Upload Date"
                                    filter="agTextColumnFilter"
                                    valueFormatter={dateFormatter}
                                    filterParams={(params: any) => {
                                        const uploadDateVals = getValuesAsync(
                                            params,
                                            'uploadDate'
                                        );
                                        const formattedDateVals =
                                            uploadDateVals.map(
                                                (date: string) => {
                                                    return dateFormatter(date);
                                                }
                                            );
                                        return {
                                            values: formattedDateVals,
                                            suppressAndOrCondition: true,
                                        };
                                    }}
                                />
                                <AgGridColumn
                                    minWidth={175}
                                    field="dueDate"
                                    headerName="Due Date"
                                    filter="agTextColumnFilter"
                                    valueFormatter={dateFormatter}
                                    filterParams={(params: any) => {
                                        const dueDateVals = getValuesAsync(
                                            params,
                                            'dueDate'
                                        );
                                        const formattedDateVals =
                                            dueDateVals.map((date: string) => {
                                                return dateFormatter(date);
                                            });
                                        return {
                                            values: formattedDateVals,
                                            suppressAndOrCondition: true,
                                        };
                                    }}
                                />
                                <AgGridColumn
                                    minWidth={150}
                                    field="poNumber"
                                    headerName="PO Number"
                                    filter="agTextColumnFilter"
                                    filterParams={(params: any) => {
                                        return {
                                            values: getValuesAsync(
                                                params,
                                                'poNumber'
                                            ),
                                            suppressAndOrCondition: true,
                                        };
                                    }}
                                />
                                <AgGridColumn
                                    minWidth={150}
                                    field="poStatus.value"
                                    headerName="PO Status"
                                    filter="agSetColumnFilter"
                                    filterParams={(params: any) => {
                                        return {
                                            values: getValuesAsync(
                                                params,
                                                'poStatus',
                                                'value'
                                            ),
                                        };
                                    }}
                                />
                                <AgGridColumn
                                    minWidth={175}
                                    field="approvalStatus.value"
                                    headerName="Invoice Status"
                                    filter="agSetColumnFilter"
                                    filterParams={(params: any) => {
                                        return {
                                            values: getValuesAsync(
                                                params,
                                                'approvalStatus',
                                                'value'
                                            ),
                                        };
                                    }}
                                />
                                <AgGridColumn
                                    minWidth={150}
                                    field="voucherNumber"
                                    headerName="Voucher Number"
                                    filter="agTextColumnFilter"
                                    filterParams={(params: any) => {
                                        return {
                                            values: getValuesAsync(
                                                params,
                                                'voucherNumber'
                                            ),
                                            suppressAndOrCondition: true,
                                        };
                                    }}
                                />
                            </SingleSelectGrid>
                            <div
                                style={{
                                    textAlign: 'right',
                                    paddingTop: '5px',
                                }}>
                                <b>{filteredRowData?.length}</b> Invoice(s)
                            </div>
                        </>
                    ) : (
                        <DatacorLogoSpinner />
                    )}
                </CardContent>
            </Card>
        </>
    );
};

export default DocumentListView;
