import React, { useState } from 'react';
import ApprovalsModal from './ApprovalsModal';
import DocumentListView from './DocumentListView';
import MatchingModal from './toleranceMatching/MatchingModal';
import ApprovalWorkflowsModal from './approvalWorkflows/ApprovalWorkflowsModal';
import { DocumentGridContext } from './contexts/DocumentGridContext';

/* istanbul ignore next */
const ApAutomation = () => {
    const [
        showChangeDefaultApproversModal,
        setShowChangeDefaultApproversModal,
    ] = useState(false);
    const [
        showChangeDefaultToleranceModal,
        setShowChangeDefaultToleranceModal,
    ] = useState(false);
    const [
        showChangeApprovalWorkflowsModal,
        setShowChangeApprovalWorkflowsModal,
    ] = useState(false);
    const [documentGridOptions, setDocumentGridOptions] = useState({
        rowData: [],
        showVouchered: localStorage.getItem('showVouchered') === 'true',
        showRejected: localStorage.getItem('showRejected') === 'true',
    });
    const value: any = { documentGridOptions, setDocumentGridOptions };
    return (
        <>
            <ApprovalsModal
                data-testid="approvalsModal"
                show={showChangeDefaultApproversModal}
                handleClose={() => setShowChangeDefaultApproversModal(false)}
            />
            <MatchingModal
                data-testid="matchingModal"
                show={showChangeDefaultToleranceModal}
                handleClose={() => setShowChangeDefaultToleranceModal(false)}
            />
            <ApprovalWorkflowsModal
                data-testid="approvalWorkflowsModal"
                show={showChangeApprovalWorkflowsModal}
                handleClose={() => setShowChangeApprovalWorkflowsModal(false)}
            />
            <DocumentGridContext.Provider value={value}>
                <DocumentListView
                    showChangeDefaultApproversModal={() =>
                        setShowChangeDefaultApproversModal(true)
                    }
                    showChangeDefaultToleranceModal={() =>
                        setShowChangeDefaultToleranceModal(true)
                    }
                    showChangeApprovalWorkflows={() =>
                        setShowChangeApprovalWorkflowsModal(true)
                    }
                />
            </DocumentGridContext.Provider>
        </>
    );
};

export default ApAutomation;
