import React from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import {
    Area,
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import {
    currencyFormatter,
    percentFormatter,
} from '../../utils/formattingUtils';
import { themeConfig } from '../../styles/theme/themeConfig';
import useGrossMarginSummary from './hooks/useGrossMarginSummary';

const GrossMarginSummary = () => {
    const { chartData } = useGrossMarginSummary();

    return (
        <Card className="summaryGrids">
            <CardHeader
                style={{
                    backgroundColor: themeConfig.PRIMARY.main,
                    height: 50,
                    color: 'white',
                }}
                title="Margin Summary"
            />
            <CardContent>
                <ResponsiveContainer width={'100%'} height={250}>
                    <ComposedChart
                        width={550}
                        height={300}
                        data={chartData}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20,
                        }}>
                        <CartesianGrid stroke="#f5f5f5" />
                        <Tooltip
                            formatter={(value: any, name: any) =>
                                name === 'Margin'
                                    ? percentFormatter(value)
                                    : currencyFormatter(value)
                            }
                        />
                        <XAxis dataKey="fromDate" height={30} />
                        <YAxis
                            yAxisId="left"
                            label={{ value: '%', position: 'insideLeft' }}
                        />
                        <YAxis
                            yAxisId="right"
                            orientation="right"
                            label={{
                                value: '$ (in thousands)',
                                angle: -90,
                                position: 'insideTopRight',
                            }}
                        />
                        <Tooltip />
                        <Legend />
                        <Area
                            yAxisId="left"
                            type="monotone"
                            dataKey="Margin"
                            legendType="star"
                            fill="#f69333"
                            stroke="#f69333"
                        />
                        <Line
                            yAxisId="right"
                            type="monotone"
                            dataKey="Profit"
                            stroke="#3358FF"
                        />
                        <Line
                            yAxisId="right"
                            type="monotone"
                            dataKey="Sales Dollars"
                            stroke="#C70039"
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
};

export default GrossMarginSummary;
