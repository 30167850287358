import { RouteProps } from './routesConfig';
import { PERMISSIONS } from '../constants/permissions/Permissions.constants';
import { ENTITY_TYPES } from '../constants/shared/EntityTypes.constants';
import { lazy } from 'react';

const CustomerStatusGrid = lazy(
    () =>
        import(
            '../containers/customer/setup/customerStatuses/CustomerStatusGrid'
        )
);
const CustomerStatusForm = lazy(
    () =>
        import(
            '../containers/customer/setup/customerStatuses/CustomerStatusForm'
        )
);
const CustomerTypeGrid = lazy(
    () => import('../containers/customer/setup/customerTypes/CustomerTypeGrid')
);
const CustomerTypeForm = lazy(
    () => import('../containers/customer/setup/customerTypes/CustomerTypeForm')
);
const CustomerAddressTypeGrid = lazy(
    () =>
        import(
            '../containers/customer/setup/customerAddressTypes/CustomerAddressTypeGrid'
        )
);
const CustomerAddressTypeForm = lazy(
    () =>
        import(
            '../containers/customer/setup/customerAddressTypes/CustomerAddressTypeForm'
        )
);
const CustomerAddressStatusGrid = lazy(
    () =>
        import(
            '../containers/customer/setup/customerAddressStatuses/CustomerAddressStatusGrid'
        )
);
const CustomerAddressStatusForm = lazy(
    () =>
        import(
            '../containers/customer/setup/customerAddressStatuses/CustomerAddressStatusForm'
        )
);
const CustomerGrid = lazy(
    () => import('../containers/customer/customers/CustomerGrid')
);
const CustomerForm = lazy(
    () => import('../containers/customer/customers/CustomerForm')
);
const CustomerAddressForm = lazy(
    () => import('../containers/customer/customerAddreses/CustomerAddressForm')
);

const CustomerRoutes: RouteProps[] = [
    {
        path: '/customers/setup/customerStatuses',
        component: CustomerStatusGrid,
        permissionKey: PERMISSIONS.CUSTOMERS.SETUP.CUSTOMER_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.customers',
        entityType: ENTITY_TYPES.CUSTOMER_STATUSES,
    },
    {
        path: '/customers/setup/customerStatuses/:id',
        component: CustomerStatusForm,
        permissionKey: PERMISSIONS.CUSTOMERS.SETUP.CUSTOMER_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.customers',
        entityType: ENTITY_TYPES.CUSTOMER_STATUSES,
        primaryEntity: true,
    },
    {
        path: '/customers/setup/customerAddressStatuses',
        component: CustomerAddressStatusGrid,
        permissionKey:
            PERMISSIONS.CUSTOMERS.SETUP.CUSTOMER_ADDRESS_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.customers',
        entityType: ENTITY_TYPES.CUSTOMER_ADDRESS_STATUSES,
    },
    {
        path: '/customers/setup/customerAddressStatuses/:id',
        component: CustomerAddressStatusForm,
        permissionKey:
            PERMISSIONS.CUSTOMERS.SETUP.CUSTOMER_ADDRESS_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.customers',
        entityType: ENTITY_TYPES.CUSTOMER_ADDRESS_STATUSES,
        primaryEntity: true,
    },
    {
        path: '/customers/setup/customerTypes',
        component: CustomerTypeGrid,
        permissionKey: PERMISSIONS.CUSTOMERS.SETUP.CUSTOMER_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.customers',
        entityType: ENTITY_TYPES.CUSTOMER_TYPES,
    },
    {
        path: '/customers/setup/customerTypes/:id',
        component: CustomerTypeForm,
        permissionKey: PERMISSIONS.CUSTOMERS.SETUP.CUSTOMER_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.customers',
        entityType: ENTITY_TYPES.CUSTOMER_TYPES,
        primaryEntity: true,
    },
    {
        path: '/customers/setup/customerAddressTypes',
        component: CustomerAddressTypeGrid,
        permissionKey: PERMISSIONS.CUSTOMERS.SETUP.CUSTOMER_ADDRESS_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.customers',
        entityType: ENTITY_TYPES.CUSTOMER_ADDRESS_TYPES,
    },
    {
        path: '/customers/setup/customerAddressTypes/:id',
        component: CustomerAddressTypeForm,
        permissionKey: PERMISSIONS.CUSTOMERS.SETUP.CUSTOMER_ADDRESS_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.customers',
        entityType: ENTITY_TYPES.CUSTOMER_ADDRESS_TYPES,
        primaryEntity: true,
    },
    {
        path: '/customers/customer',
        component: CustomerGrid,
        permissionKey: PERMISSIONS.CUSTOMERS.CUSTOMERS.VIEW,
        featureFlag: 'dashboard.nav.apps.customers',
        entityType: ENTITY_TYPES.CUSTOMER,
    },
    {
        path: '/customers/customer/:id',
        component: CustomerForm,
        permissionKey: PERMISSIONS.CUSTOMERS.CUSTOMERS.VIEW,
        featureFlag: 'dashboard.nav.apps.customers',
        entityType: ENTITY_TYPES.CUSTOMER,
        primaryEntity: true,
    },
    {
        path: '/customers/customerAddress/:id',
        component: CustomerAddressForm,
        permissionKey: PERMISSIONS.CUSTOMERS.CUSTOMERS.VIEW,
        featureFlag: 'dashboard.nav.apps.customers',
        entityType: ENTITY_TYPES.CUSTOMER_ADDRESS,
        primaryEntity: true,
    },
];

export default CustomerRoutes;
