import { AuthErrorsDefs } from '../../constants/i18n/translations/termDefinitions/platform';

type CognitoErrorMessage = {
    name: string;
    code: string;
    message: string;
};

export const getTermDefCodeFromError = (error: CognitoErrorMessage): string => {
    // map cognito response object to a termdef code.  This is for when a cognito api call is invoked by the
    // frontend directly.
    switch (error.code) {
        case 'LimitExceededException':
            return AuthErrorsDefs.ResetPasswordLimitExceededError;
        case 'InvalidParameterException': {
            const errorMessageLowerCase = error.message.toLowerCase();
            if (
                errorMessageLowerCase.includes(
                    'must satisfy regular expression pattern'
                )
            ) {
                return AuthErrorsDefs.PasswordComplexityRuleFailedError;
            } else if (
                errorMessageLowerCase.includes('invalid email address format')
            ) {
                return AuthErrorsDefs.InvalidEmailFormatError;
            }
            return AuthErrorsDefs.DefaultMessage;
        }
        case 'UserNotFoundException':
            return AuthErrorsDefs.UserNotFoundError;
        case 'AliasExistsException':
            return AuthErrorsDefs.EmailInUseError;
        case 'NotAuthorizedException':
            return AuthErrorsDefs.IncorrectUsernameOrPasswordError;
        case 'InvalidPasswordException':
            return AuthErrorsDefs.PasswordComplexityRuleFailedError;
        case 'ExpiredCodeException':
            return AuthErrorsDefs.ExpiredCode;
        default:
            return AuthErrorsDefs.DefaultMessage;
    }
};

/**
 * map an ERP backend error message to a termdef code.  This is for when a cognito api call is invoked by the
 * backend, which then returns a string error message appropriate for the API response.  We'll catch that here
 * and map to a proper termdef code.
 */
export const getTermDefCodeFromErrorString = (error: string): string => {
    switch (error) {
        case 'An account with the given email already exists.':
            return AuthErrorsDefs.EmailInUseError;
        case 'Invalid email address format.':
            return AuthErrorsDefs.InvalidEmailFormatError;
        default:
            return AuthErrorsDefs.DefaultMessage;
    }
};

/**
 * For error display in contexts that do not have an authenticated user that can retrieve the termdef translations.
 * These will be English language only.
 * @param error
 */
export const getDefaultMessageFromError = (
    error: CognitoErrorMessage
): string => {
    const DEFAULT_ERROR_MESSAGE = 'An error occurred.';
    switch (error.code) {
        case 'InvalidPasswordException':
            return (
                'Password failed complexity requirements. Password must contain a number, ' +
                'a lower case letter, an upper case letter, a special character, and be at least 8 characters long.'
            );
        case 'InvalidParameterException': {
            const errorMessageLowerCase = error.message.toLowerCase();
            if (
                errorMessageLowerCase.includes(
                    'must satisfy regular expression pattern'
                )
            ) {
                return (
                    'Password failed complexity requirements. Password must contain a number, ' +
                    'a lower case letter, an upper case letter, a special character, and be at least 8 characters long.'
                );
            }
            return DEFAULT_ERROR_MESSAGE;
        }
        case 'CodeMismatchException':
            return 'Invalid verification code.';
        case 'LimitExceededException':
            return 'Reset password attempt limit exceeded. Please try again later.';
        case 'UserNotFoundException':
            return 'User not found for email provided.';
        case 'ExpiredCodeException':
            return 'Invalid confirmation code used.';
        default:
            return DEFAULT_ERROR_MESSAGE;
    }
};
