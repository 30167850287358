import { RouteProps } from './routesConfig';
import { lazy } from 'react';

const TestEntityForm = lazy(
    () => import('../containers/workflow/TestEntityForm')
);
const TestEntityGrid = lazy(
    () => import('../containers/workflow/TestEntityGrid')
);
const TestEntityStatusGrid = lazy(
    () => import('../containers/workflow/TestEntityStatusGrid')
);
const TestEntityStatusForm = lazy(
    () => import('../containers/workflow/TestEntityStatusForm')
);
const StepEditorContainer = lazy(
    () => import('../components/workflow/step-editor/StepEditorContainer')
);

const WorkflowRoutes: RouteProps[] = [
    {
        path: '/workflow/test-entity',
        component: TestEntityGrid,
        featureFlag: 'dashboard.nav.apps.workflow',
    },
    {
        path: '/workflow/test-entity/:id',
        component: TestEntityForm,
        featureFlag: 'dashboard.nav.apps.workflow',
    },
    {
        path: '/workflow/test-entity-status',
        component: TestEntityStatusGrid,
        featureFlag: 'dashboard.nav.apps.workflow',
    },
    {
        path: '/workflow/test-entity-status/:id',
        component: TestEntityStatusForm,
        featureFlag: 'dashboard.nav.apps.workflow',
    },
    {
        path: '/workflow/step-editor',
        component: StepEditorContainer,
        featureFlag: 'dashboard.nav.apps.workflow',
    },
];

export default WorkflowRoutes;
