import React, { ChangeEvent, useEffect, useState } from 'react';
import {
    Attachment,
    useAttachmentAccessCheckQuery,
    useGetUrlByKeyQuery,
} from '../../../../services/attachments/attachments.service';
import InvoicePreview from '../../../../containers/apAutomation/InvoicePreview';
import {
    Box,
    PaginationItem,
    PaginationRenderItemParams,
    Typography,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { FILE_EXTENSIONS } from '../../../../constants/attachments/fileTypes.constants';
import _ from 'lodash';
import useDate from '../../../../utils/hooks/useDate';

interface AttachmentItemModalProps {
    item: Attachment;
    onClose: () => void;
}

const useAttachmentItemModal = ({
    item,
    onClose,
}: AttachmentItemModalProps) => {
    const [openNotesModal, setOpenNotesModal] = useState(false);
    const [notes, setNotes] = useState('');
    const [fileVersion, setFileVersion] = useState(null);
    const [previewContent, setPreviewContent] = useState(null);

    useEffect(() => {
        if (item?.attachmentFileVersions?.length > 0) {
            setFileVersion(_.last(item?.attachmentFileVersions));
        }
    }, [item]);

    const { data: canAccess, isLoading: loadingAccessCheck } =
        useAttachmentAccessCheckQuery(
            item?.id ? { id: item?.id, accessType: 'READ' } : skipToken
        );

    const prohibitAccess = item?.id && !loadingAccessCheck && !canAccess;

    const { data: url } = useGetUrlByKeyQuery(
        fileVersion ? fileVersion?.fileHash : skipToken
    );

    const maxVersionNumber = _.max(
        item?.attachmentFileVersions?.map((fv) => fv.versionNumber) ?? [0]
    );

    const { dateTimeFormatter } = useDate();

    useEffect(() => {
        setNotes(item?.notes);
    }, [item]);

    useEffect(() => {
        if (!url || !item) {
            setPreviewContent('Loading preview...');
            return;
        }
        const fetchPreviewContent = async () => {
            try {
                const content = await handlePreview();
                setPreviewContent(content);
            } catch (error) {
                console.error('Error in fetching preview content:', error);
            }
        };
        fetchPreviewContent();
    }, [item, url]);

    const handleClose = () => {
        onClose();
    };

    const handleOpenNotesModal = () => {
        setOpenNotesModal(true);
    };

    const onVersionChange = (event: ChangeEvent<unknown>, version: number) => {
        const fileVersion = item?.attachmentFileVersions.find(
            (v) => v.versionNumber === version
        );
        setFileVersion(fileVersion);
    };

    const renderItem = (params: PaginationRenderItemParams) => {
        // hide the 'page' number if no file version exists for that versionNumber
        const hasFileVersion = item?.attachmentFileVersions?.some(
            (fv) => fv.versionNumber === params.page
        );
        if (hasFileVersion) {
            return <PaginationItem {...params} />;
        } else {
            return <></>;
        }
    };

    /**
     * depending on the file extension, we will render the preview differently
     */
    const handlePreview = async () => {
        switch (item.extension) {
            case FILE_EXTENSIONS.PDF:
                return <InvoicePreview pdfUrl={url} />;
            case FILE_EXTENSIONS.PNG:
            case FILE_EXTENSIONS.JPG:
            case FILE_EXTENSIONS.JPEG:
                return (
                    <div
                        style={{
                            display: 'grid',
                            placeItems: 'center',
                        }}>
                        <Box
                            component="img"
                            sx={{ maxHeight: '1200px', maxWidth: '720px' }}
                            src={url}
                            alt={item.name}
                        />
                    </div>
                );
            case FILE_EXTENSIONS.TXT:
                try {
                    const response = await fetch(url);

                    const text = await response.text();
                    return (
                        <div style={{ overflow: 'scroll' }}>
                            <Typography
                                variant={'body1'}
                                style={{ whiteSpace: 'pre-wrap' }}>
                                {text}
                            </Typography>
                        </div>
                    );
                } catch (e: any) {
                    return `Error fetching text file.`;
                }
            default:
                return 'No preview available for this file type.';
        }
    };
    /**
     * get the signed url
     * convert the blob to a file
     * download the file
     */
    const handleDownload = async () => {
        const response = await fetch(url, {
            headers: {
                AccessControlAllowOrigin: '*',
            },
        });

        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${item?.name}.${item?.extension}`;
        link.click();
    };

    return {
        handleClose,
        openNotesModal,
        setOpenNotesModal,
        notes,
        setNotes,
        handleOpenNotesModal,
        handleDownload,
        previewContent,
        maxVersionNumber,
        fileVersion,
        onVersionChange,
        renderItem,
        dateTimeFormatter,
        prohibitAccess,
    };
};

export default useAttachmentItemModal;
