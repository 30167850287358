import React from 'react';
import { useListTotalsQuery } from '../../services/attachments/attachments.service';

const StorageInstance = ({ total }: any) => {
    return (
        <div>
            <div>
                <span>Business Entity: {total.businessEntity?.name} </span>
                <span>
                    Total Storage Used: {total.storageSize} bytes (
                    {(total.storageSize / (1024 * 1024)).toFixed(2)} MB)
                </span>
            </div>
        </div>
    );
};

const StorageInfo = () => {
    const { data: totals } = useListTotalsQuery();

    return (
        <div>
            <h3>These values are updated nightly at midnight.</h3>

            <div>
                {totals &&
                    totals.map((total: any) => {
                        return <StorageInstance total={total} key={total.id} />;
                    })}
            </div>
        </div>
    );
};

export default StorageInfo;
