import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    currencyFormatter,
    percentFormatter,
} from '../../../utils/formattingUtils';
import { RootState } from '../../../store';

const useGeneralSummary = () => {
    const [rowData, setRowData] = useState([]);
    const { pricingAnalysisToolSet } = useSelector(
        (state: RootState) => state.pricingAnalysisTool
    );

    useEffect(() => {
        if (
            !pricingAnalysisToolSet.activeCumulativeSummaryIsLoading &&
            pricingAnalysisToolSet.activeCumulativeSummary
        ) {
            setRowData([
                {
                    category: '# of Transactions:',
                    value: pricingAnalysisToolSet.activeCumulativeSummary
                        .numberOfTransactions,
                },
                {
                    category: 'Product Sales Dollars: ',
                    value: currencyFormatter(
                        pricingAnalysisToolSet.activeCumulativeSummary
                            .totalPrice
                    ),
                },
                {
                    category: 'Margin: ',
                    value: percentFormatter(
                        pricingAnalysisToolSet.activeCumulativeSummary
                            .overallMargin
                    ),
                },
                {
                    category: 'Profit Dollars: ',
                    value: currencyFormatter(
                        pricingAnalysisToolSet.activeCumulativeSummary
                            .profitDollars
                    ),
                },
            ]);
        }
    }, [
        pricingAnalysisToolSet.activeCumulativeSummaryIsLoading,
        pricingAnalysisToolSet.activeCumulativeSummary,
    ]);
    return { rowData };
};
export default useGeneralSummary;
