import React, { useState, useEffect, useRef } from 'react';
import {
    getAllObjectKeysRecursive,
    isNil,
    isNilOrEmpty,
} from '../utils/objectUtils';
import { Validator } from '../validators/validator.interface';
import _ from 'lodash';
import useVerificationChangesHelper from '../containers/form/hooks/verification/useVerificationChangesHelper';

export interface HandleFieldChangedEvent {
    target: {
        id: string;
        type?: string;
        checked?: boolean;
        value: any;
    };
}

// custom React hook.
export function useFormFields(initialState: any) {
    const [fields, setValues] = useState(initialState);

    //pulling this out into a method because I believe as we move forward with more
    //complicated forms there will be more custom logic to consider.
    const getValue = (target: any) => {
        switch (target.type?.toUpperCase()) {
            case 'CHECKBOX':
                return target.checked;
            default:
                return target.value;
        }
    };

    const setMultipleValues = (newValue: any) => {
        setValues({
            ...fields,
            ...newValue,
        });
    };

    return [
        fields,
        function (event: React.ChangeEvent<HTMLInputElement>) {
            setValues({
                ...fields,
                [event.target.id]: getValue(event.target),
            });
        },
        setMultipleValues,
    ];
}

export function useForm(
    initialState: any,
    onlyUpdateEntityWithChanges?: boolean,
    supportJSONPathForSetValue: boolean = false,
    inspectValidationFieldsRecursively: boolean = false
) {
    const [fields, setFields] = useState(initialState);
    const [validators, setValidators] = useState(new Map());
    const [isFormValid, setIsFormValid] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [fieldValidationErrors, setFieldValidationErrors] = useState(
        new Map()
    );

    const { verifyChanges } = useVerificationChangesHelper({
        blankEntity: initialState,
        executeVerification: onlyUpdateEntityWithChanges || false,
    });

    const {
        notifyEntityChanges,
        notifyChildChanges,
        notifyChildrenChanges,
        notifyChildChangesWhenUpdated,
    } = verifyChanges.notificationMethods;

    useEffect(() => {
        validate();
    }, [fields, validators]);

    const setValues = (values: any) => {
        // pass in an updater function to ensure if multiple fields are updated in
        // separate calls before a render occurs, the subsequent calls will not overwrite
        // any fields updated by the earlier calls.
        //
        // https://react.dev/reference/react/useState#updating-state-based-on-the-previous-state

        setFields((currentState: any) => {
            return {
                ...currentState,
                ...values,
            };
        });
    };

    const addValidator = (
        fieldName: string,
        fieldLabel: any,
        validator: Validator
    ) => {
        const fieldValidator = {
            key: validator.key,
            fieldLabel: fieldLabel,
            validator: validator,
        };
        const existingValidators = validators.get(fieldName);
        if (!isNilOrEmpty(existingValidators)) {
            if (
                validatorAlreadyExists(fieldValidator.key, existingValidators)
            ) {
                // in instances where inputs can be initialized multiple times like in tabs for ex.
                // with each initialization of the input validators will be attempted to be re-added.
                // this prevents the same validator per input from being added multiple times.
                return;
            }
            existingValidators.push(fieldValidator);
        } else {
            setValidators(new Map(validators.set(fieldName, [fieldValidator])));
        }
    };

    const validatorAlreadyExists = (
        key: string,
        existingValidators: Validator[]
    ): Validator => {
        return _.find(existingValidators, { key: key });
    };
    const removeValidator = (field: string, validator: Validator) => {
        const existingValidators = validators.get(field);

        if (!isNil(existingValidators)) {
            _.remove(existingValidators, (fieldValidator: any) => {
                return fieldValidator.validator.key === validator.key;
            });
        }
    };

    const validate = () => {
        if (fields) {
            const validationErrorsMap = new Map();
            let hasErrors = false;

            let fieldKeys;
            // Allow support for inspecting recursive fields in addition to direct
            // object fields.  This allows locating values that have a path on a
            // nested object, i.e. `configuration.equationString`. This property is
            // being used as a safeguard to maintain legacy behavior on existing components,
            // as almost all forms rely on this function, but we should remove it
            // at some point and always inspect recursively.
            if (inspectValidationFieldsRecursively) {
                fieldKeys = getAllObjectKeysRecursive(fields);
            } else {
                fieldKeys = Object.keys(fields);
            }

            fieldKeys.forEach((fieldName) => {
                const fieldValidators = validators.get(fieldName);
                fieldValidators?.forEach((fieldValidator: any) => {
                    //If field is invalid for a given validator
                    const fieldValue = supportJSONPathForSetValue
                        ? _.get(fields, fieldName)
                        : fields[fieldName];
                    if (!fieldValidator.validator.isValid(fieldValue)) {
                        //add the validation error to the fieldValidationErrors
                        const fieldErrors = validationErrorsMap.get(fieldName)
                            ? validationErrorsMap.get(fieldName)
                            : [];
                        const errorMessage =
                            fieldValidator.validator.getErrorMessage(
                                fieldValidator.fieldLabel,
                                fieldValue
                            );
                        if (
                            isNil(
                                fieldErrors.find(
                                    (current: string) =>
                                        current === errorMessage
                                )
                            )
                        ) {
                            fieldErrors.push(errorMessage);
                        }
                        validationErrorsMap.set(fieldName, fieldErrors);
                        hasErrors = true;
                    }
                });
            });
            setIsFormValid(!hasErrors);
            setFieldValidationErrors(
                validationErrorsMap.size !== 0
                    ? new Map(validationErrorsMap)
                    : null
            );
        }
    };

    const updateField = (fieldName: string, value: any) => {
        setValues({ [fieldName]: value });
    };

    const getValue = (target: any) => {
        switch (target.type?.toUpperCase()) {
            case 'CHECKBOX':
                return target.checked;
            default:
                return target.value;
        }
    };

    const handleFieldChange = (event: HandleFieldChangedEvent): void => {
        if (event) {
            const fieldName = event.target.id;
            const value = getValue(event.target);
            notifyEntityChanges(fieldName, value, supportJSONPathForSetValue);
            setFields((previousValues: any) => {
                // To allow for using JSON Path in form ids for value setting
                // i.e. permission[0].allowRead
                // this will be consistent with useFormControlProps use of _.get to
                // retrieve values
                // legacy logic in place to prevent unexpected change of behavior
                if (supportJSONPathForSetValue) {
                    let newFields = _.cloneDeep(previousValues);
                    _.set(newFields, fieldName, value);
                    return newFields;
                } else {
                    return {
                        ...previousValues,
                        [fieldName]: value,
                    };
                }
            });
        }
    };

    const handleChildrenRecords = <TChild>(
        childrenNodeName: string,
        childrenRecords: TChild[]
    ): void => {
        notifyChildrenChanges(childrenNodeName, childrenRecords);
        setFields((previousValues: any) => ({
            ...previousValues,
            [childrenNodeName]: [...childrenRecords],
        }));
    };

    /**
     * used for updating multiple fields in a child record at one time
     * expects full child object with all current values
     */
    const updateCompleteChildRecord = <TChild>(
        childrenNodeName: string,
        childrenRecord: TChild
    ): void => {
        notifyChildChangesWhenUpdated(childrenNodeName, childrenRecord);
        setFields((previousValues: any) => ({
            ...previousValues,
            [childrenNodeName]: { ...childrenRecord },
        }));
    };

    const handleChildRecord = (
        childNodeName: string,
        event: HandleFieldChangedEvent,
        preventEntityChanges?: boolean
    ) => {
        const fieldName = event.target.id;
        const value = getValue(event.target);

        if (preventEntityChanges !== true) {
            notifyChildChanges(childNodeName, [fieldName, value]);
        }
        setFields((previousValues: any) => ({
            ...previousValues,
            [childNodeName]: {
                ...previousValues[childNodeName],
                [fieldName]: value,
            },
        }));
    };

    return {
        formMethods: {
            addValidator,
            removeValidator,
            validate,
            updateField,
            isFormSubmitted,
            setIsFormSubmitted,
            fieldValidationErrors,
            isFormValid,
            setIsFormValid,
            setFieldValidationErrors,
        },
        fields,
        setValues,
        handleFieldChange,
        updateField,
        addValidator,
        removeValidator,
        validate,
        isFormValid,
        fieldValidationErrors,
        isFormSubmitted,
        setIsFormSubmitted,
        getValue,
        handleChildrenRecords,
        handleChildRecord,
        verifyChanges,
        updateCompleteChildRecord,
    };
}

export interface FormMethods {
    removeValidator: (field: string, validator: any) => void;
    addValidator: (
        fieldName: string,
        fieldLabel: any,
        validator: Validator
    ) => void;
    updateField: (fieldName: string, value: any) => void;
    isFormSubmitted: boolean;
    setIsFormSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
    isFormValid: boolean;
    fieldValidationErrors: Map<string, any>;
    setIsFormValid?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const usePrevious = (value: any) => {
    const ref = useRef();
    useEffect(() => (ref.current = value));
    return ref.current;
};
