/**
 * all of our editable grid fields we have on the
 * item master page
 * we can add or remove fields here as we need them
 */

export const ITEM_PROPERTIES_FIELDS = {
    propertyID: 'Property ID',
    propertyName: 'Property Name',
    propertyValue: 'Value',
    propertyUnit: 'UOM',
};

export const ITEM_CHEMICAL_COMPOSITION_FIELDS = {
    casIdentifier: 'CAS No.',
    lowerValue: 'Lower Value %',
    upperValue: 'Upper Value %',
};
