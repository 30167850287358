import React from 'react';
import { useListNotificationsForUserQuery } from '../../../services/notifications/notifications.service';
import { isFeatureFlagEnabled } from '../../../utils/featureFlag/featureFlagUtil';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

const useNotifications = () => {
    const { data: notifications, isLoading } =
        useListNotificationsForUserQuery();
    const [notificationAnchorEl, setNotificationAnchorEl] =
        React.useState<null | HTMLElement>(null);
    const user = useSelector((state: RootState) => state.user);
    const notificationOpen = Boolean(notificationAnchorEl);

    const shouldShowNotifications = isFeatureFlagEnabled(
        user?.enabledFeatureFlags,
        'feature.apps.notifications'
    );

    const handleClickNotification = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setNotificationAnchorEl(event.currentTarget);
    };

    const handleCloseNotification = () => {
        setNotificationAnchorEl(null);
    };

    return {
        notifications,
        shouldShowNotifications,
        handleClickNotification,
        handleCloseNotification,
        notificationAnchorEl,
        setNotificationAnchorEl,
        notificationOpen,
        isLoading,
    };
};

export default useNotifications;
