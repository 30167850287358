import { isNil, isNilOrEmpty } from '../utils/objectUtils';
import _ from 'lodash';
import { Validator } from './validator.interface';
import React from 'react';
const isValid = (value: any) => {
    if (_.isString(value)) {
        return !isNil(value?.trim());
    }

    return _.isArray(value) ? !isNilOrEmpty(value) : !isNil(value);
};

const hasOnlyWhitespace = (value: any) => {
    if (_.isString(value)) {
        return isNilOrEmpty(value?.trim());
    }

    return false;
};

export const RequiredValidator: Validator = {
    key: 'required',
    getErrorMessage: (fieldLabel: any, value: any) => {
        if (hasOnlyWhitespace(value)) {
            if (_.isObject(fieldLabel)) {
                return (
                    <>{fieldLabel} cannot be empty or only contain whitespace</>
                );
            }
            return `${fieldLabel} cannot be empty or only contain whitespace`;
        }

        if (_.isObject(fieldLabel)) {
            return <>{fieldLabel} is required</>;
        }

        return `${fieldLabel} is required`;
    },
    isValid: isValid,
};
