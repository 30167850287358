import { createSlice } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { onError } from '../libs/errorLib';
import { DATACOR_MULTIPLEXER_URL } from '../libs/constantsLib';
import { AppDispatch } from './index';
import { Product } from '../types/BatchTicket.types';
import { SliceState } from '../types/store/WarehousesStore.types';

const initialState: SliceState = {
    warehousesSet: {
        isLoading: false,
        warehouses: [],
        warehouseProductsIsLoading: false,
        warehouseProductsByWarehouse: {},
    },
};

const slice = createSlice({
    name: 'warehouses',
    initialState: initialState,
    reducers: {
        warehousesLoading: (state, action) => {
            state.warehousesSet.isLoading = action.payload;
        },
        getWarehousesSuccess: (state, action) => {
            state.warehousesSet.warehouses = action.payload.warehouses;
            state.warehousesSet.isLoading = action.payload.isLoading;
        },
        warehouseProductsLoading: (state, action) => {
            state.warehousesSet.warehouseProductsIsLoading = action.payload;
        },
        getWarehouseProductsByWarehouseIdSuccess: (state: any, action) => {
            state.warehousesSet.warehouseProductsByWarehouse[
                action.payload.warehouseId
            ] = action.payload.warehouseProducts;
            state.warehousesSet.warehouseProductsIsLoading =
                action.payload.warehouseProductsIsLoading;
        },
        getProductByIdSuccess: (state: any, action) => {
            const productsByWarehouseId = state.warehousesSet
                .warehouseProductsByWarehouse[action.payload.warehouseId]
                ? state.warehousesSet.warehouseProductsByWarehouse[
                      action.payload.warehouseId
                  ]
                : [];

            const productIndex = productsByWarehouseId.findIndex(
                (product: Product) =>
                    product.ProductNumber ===
                    action.payload.product.ProductNumber
            );

            if (productIndex !== -1) {
                productsByWarehouseId.splice(
                    productIndex,
                    1,
                    action.payload.product
                );
            } else {
                productsByWarehouseId.push(action.payload.product);
            }

            state.warehousesSet.warehouseProductsByWarehouse[
                action.payload.warehouseId
            ] = productsByWarehouseId;
            state.warehousesSet.warehouseProductsIsLoading =
                action.payload.warehouseProductsIsLoading;
        },
    },
});

export default slice.reducer;

// Actions
export const {
    warehousesLoading,
    getWarehousesSuccess,
    warehouseProductsLoading,
    getWarehouseProductsByWarehouseIdSuccess,
    getProductByIdSuccess,
} = slice.actions;

export const getWarehouses = () => async (dispatch: AppDispatch) => {
    dispatch(warehousesLoading(true));
    const sessionData: any = await Auth.currentSession();
    await fetch(DATACOR_MULTIPLEXER_URL + 'warehouses', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionData.idToken.jwtToken,
            'Ocp-Apim-Trace': 'True',
            'Ocp-Apim-Subscription-Key': '536572ce1293439e9147aac7511889dd',
        },
    })
        // By design, the instant state and value of a promise cannot be inspected synchronously from code, without calling the then() method
        .then((dataWrappedByPromise) => dataWrappedByPromise.json())
        .then((data) => {
            dispatch(
                getWarehousesSuccess({ isLoading: false, warehouses: data })
            );
        })
        .catch((error) => {
            return onError(error.message);
        });
};

export const getWarehouseProductsByWarehouseId =
    (warehouseId: string) => async (dispatch: AppDispatch) => {
        dispatch(warehouseProductsLoading(true));
        const sessionData: any = await Auth.currentSession();
        await fetch(
            DATACOR_MULTIPLEXER_URL +
                'warehouseproducts?warehouse=' +
                warehouseId,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + sessionData.idToken.jwtToken,
                    'Ocp-Apim-Trace': 'True',
                    'Ocp-Apim-Subscription-Key':
                        '536572ce1293439e9147aac7511889dd',
                },
            }
        )
            // By design, the instant state and value of a promise cannot be inspected synchronously from code, without calling the then() method
            .then((dataWrappedByPromise) => dataWrappedByPromise.json())
            .then((data) => {
                dispatch(
                    getWarehouseProductsByWarehouseIdSuccess({
                        warehouseProductsIsLoading: false,
                        warehouseProducts: data,
                        warehouseId: warehouseId,
                    })
                );
            })
            .catch((error) => {
                return onError(error.message);
            });
    };

export const getProductById =
    (productId: string, warehouseId: string) =>
    async (dispatch: AppDispatch) => {
        dispatch(warehouseProductsLoading(true));
        const sessionData: any = await Auth.currentSession();
        await fetch(
            `${DATACOR_MULTIPLEXER_URL}warehouses/${warehouseId}/products/${productId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + sessionData.idToken.jwtToken,
                    'Ocp-Apim-Trace': 'True',
                    'Ocp-Apim-Subscription-Key':
                        '536572ce1293439e9147aac7511889dd',
                },
            }
        )
            // By design, the instant state and value of a promise cannot be inspected synchronously from code, without calling the then() method
            .then((dataWrappedByPromise) => dataWrappedByPromise.json())
            .then((data) => {
                dispatch(
                    getProductByIdSuccess({
                        warehouseProductsIsLoading: false,
                        product: data,
                        warehouseId: warehouseId,
                    })
                );
            })
            .catch((error) => {
                return onError(error.message);
            });
    };
