import React from 'react';
import { Auth } from 'aws-amplify';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import { DATACOR_MULTIPLEXER_URL_DOCUMENTATION } from '../../libs/constantsLib';
import './ApiDocumentation.css';

/* istanbul ignore file */
const ApiDocumentation = () => {
    const requestInterceptor = async (a: any = {}) => {
        const user: any = await Auth.currentSession();
        a.headers['authorization'] = 'Bearer ' + user.idToken.jwtToken;
        return a;
    };

    return (
        <div className="apiDocumentation">
            <SwaggerUI
                url={DATACOR_MULTIPLEXER_URL_DOCUMENTATION}
                supportedSubmitMethods={['get']}
                requestInterceptor={requestInterceptor}
            />
        </div>
    );
};

export default ApiDocumentation;
