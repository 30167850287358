import { RouteProps } from './routesConfig';
import { ENTITY_TYPES } from '../constants/shared/EntityTypes.constants';
import { PERMISSIONS } from '../constants/permissions/Permissions.constants';
import { lazy } from 'react';

const CasMaster = lazy(() => import('../containers/formulation/cas/CasMaster'));
const CasForm = lazy(
    () => import('../containers/formulation/cas/form/CasForm')
);
const ItemMaster = lazy(
    () => import('../containers/formulation/itemMaster/ItemMaster')
);
const ItemMasterForm = lazy(
    () => import('../containers/formulation/itemMaster/form/ItemMasterForm')
);
const FormulaProperty = lazy(
    () => import('../containers/formulation/formulaProperty/FormulaProperty')
);
const FormulaPropertyForm = lazy(
    () =>
        import(
            '../containers/formulation/formulaProperty/form/FormulaPropertyForm'
        )
);
const GhsHazardPhrases = lazy(
    () => import('../containers/formulation/ghsHazardPhrases/GhsHazardPhrases')
);
const GhsHazardPhraseShowForm = lazy(
    () =>
        import(
            '../containers/formulation/ghsHazardPhrases/GhsHazardPhraseShowForm'
        )
);
const GhsPrecautionaryPhrase = lazy(
    () =>
        import(
            '../containers/formulation/ghsPrecautionaryPhrases/GhsPrecautionaryPhrase'
        )
);
const GhsPrecautionaryPhraseShowForm = lazy(
    () =>
        import(
            '../containers/formulation/ghsPrecautionaryPhrases/GhsPrecautionaryPhraseShowForm'
        )
);
const Regulation = lazy(
    () => import('../containers/formulation/regulation/Regulation')
);
const RegulationForm = lazy(
    () => import('../containers/formulation/regulation/form/RegulationForm')
);
const ToxicTest = lazy(
    () => import('../containers/formulation/toxicTest/ToxicTest')
);
const ToxicTestForm = lazy(
    () => import('../containers/formulation/toxicTest/form/ToxicTestForm')
);
const ToxicSpecies = lazy(
    () => import('../containers/formulation/toxicSpecies/ToxicSpecies')
);
const ToxicSpeciesForm = lazy(
    () => import('../containers/formulation/toxicSpecies/form/ToxicSpeciesForm')
);
const ExposureAgency = lazy(
    () => import('../containers/formulation/exposureAgency/ExposureAgency')
);
const ExposureAgencyForm = lazy(
    () =>
        import(
            '../containers/formulation/exposureAgency/form/ExposureAgencyForm'
        )
);
const SafetyDataSheetSectionHeader = lazy(
    () =>
        import(
            '../containers/formulation/safetyDataSheetSectionHeader/SafetyDataSheetSectionHeader'
        )
);
const SafetyDataSheetSectionHeaderShowForm = lazy(
    () =>
        import(
            '../containers/formulation/safetyDataSheetSectionHeader/SafetyDataSheetSectionHeaderShowForm'
        )
);
const LocaleGroup = lazy(
    () => import('../containers/formulation/localeGroup/LocaleGroup')
);
const LocaleGroupForm = lazy(
    () => import('../containers/formulation/localeGroup/form/LocaleGroupForm')
);
const RDFormula = lazy(
    () => import('../containers/formulation/rdFormula/RDFormula')
);
const Locale = lazy(() => import('../containers/formulation/locale/Locale'));
const SafetyDataSheet = lazy(
    () => import('../containers/formulation/sds/SafetyDataSheet')
);
const SdsForm = lazy(
    () => import('../containers/formulation/sds/form/SdsForm')
);
const RDFormulaForm = lazy(
    () => import('../containers/formulation/rdFormula/form/RDFormulaForm')
);
const Sequences = lazy(
    () => import('../containers/formulation/sequences/Sequences')
);
const HazardClassificationMapping = lazy(
    () =>
        import(
            '../containers/formulation/hazardClasificationMapping/HazardClassificationMapping'
        )
);
const HazardRating = lazy(
    () => import('../containers/formulation/hazardRating/HazardRating')
);
const HazardClassificationRule = lazy(
    () =>
        import(
            '../containers/formulation/hazardClassificationRules/HazardClassificationRule'
        )
);
const HazardClassificationRuleForm = lazy(
    () =>
        import(
            '../containers/formulation/hazardClassificationRules/form/HazardClassificationRuleForm'
        )
);
const SdsOptionalPhraseLibrary = lazy(
    () =>
        import(
            '../containers/formulation/sdsOptionalPhraseLibrary/SdsOptionalPhraseLibrary'
        )
);
const SdsOptionalPhraseLibraryForm = lazy(
    () =>
        import(
            '../containers/formulation/sdsOptionalPhraseLibrary/form/SdsOptionalPhraseLibraryForm'
        )
);
const FormulaTemplate = lazy(
    () => import('../containers/formulation/formulaTemplate/FormulaTemplate')
);
const FormulaTemplateForm = lazy(
    () =>
        import(
            '../containers/formulation/formulaTemplate/form/FormulaTemplateForm'
        )
);
const FormulaIntegration = lazy(
    () =>
        import(
            '../containers/formulation/formulaIntegration/FormulaIntegration'
        )
);
const HazardClassifications = lazy(
    () =>
        import(
            '../containers/formulation/hazardClassification/HazardClassifications'
        )
);
const HazardClassificationShowForm = lazy(
    () =>
        import(
            '../containers/formulation/hazardClassification/HazardClassificationShowForm'
        )
);
const EquationBuilder = lazy(
    () => import('../components/equationBuilder/EquationBuilder')
);
const FormulationEquationBuilderForm = lazy(
    () =>
        import(
            '../containers/formulation/equationBuilder/form/FormulationEquationBuilderForm'
        )
);

const FormulationRoutes: RouteProps[] = [
    {
        path: '/formulation/RDFormula',
        component: RDFormula,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/casMaster',
        component: CasMaster,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/casMaster/new',
        component: CasForm,
    },
    {
        path: '/formulation/casMaster/:id',
        component: CasForm,
    },
    {
        path: '/formulation/itemMaster',
        component: ItemMaster,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/itemMaster/new',
        component: ItemMasterForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/itemMaster/:id',
        component: ItemMasterForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/ghsHazardPhrases',
        component: GhsHazardPhrases,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/ghsHazardPhrases/new',
        component: GhsHazardPhraseShowForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/ghsHazardPhrases/:phraseId',
        component: GhsHazardPhraseShowForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/formulaProperties',
        component: FormulaProperty,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/formulaProperties/:id',
        component: FormulaPropertyForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/ghsPrecautionaryPhrases',
        component: GhsPrecautionaryPhrase,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/ghsPrecautionaryPhrases/new',
        component: GhsPrecautionaryPhraseShowForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/ghsPrecautionaryPhrases/:phraseId',
        component: GhsPrecautionaryPhraseShowForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/regulations',
        component: Regulation,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/regulations/:id',
        component: RegulationForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/toxicSpecies',
        component: ToxicSpecies,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/toxicSpecies/:id',
        component: ToxicSpeciesForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/toxicTest',
        component: ToxicTest,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/toxicTest/:id',
        component: ToxicTestForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/exposureAgency',
        component: ExposureAgency,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/exposureAgency/:id',
        component: ExposureAgencyForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/sdsSectionHeader',
        component: SafetyDataSheetSectionHeader,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/sdsSectionHeader/new',
        component: SafetyDataSheetSectionHeaderShowForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/sdsSectionHeader/:headerId/:localeGroupId',
        component: SafetyDataSheetSectionHeaderShowForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/localeGroup',
        component: LocaleGroup,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/localeGroup/new',
        component: LocaleGroupForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/localeGroup/:id',
        component: LocaleGroupForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/rndFormula',
        component: RDFormula,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/rndFormula/new',
        component: RDFormulaForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/rndFormula/:id',
        component: RDFormulaForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/locale',
        component: Locale,
        featureFlag: 'dashboard.nav.apps.formulation',
        permissionKey: PERMISSIONS.FORMULATION.LOCALE.VIEW,
        entityType: ENTITY_TYPES.LOCALE_GROUPS,
    },
    {
        path: '/formulation/sds',
        component: SafetyDataSheet,
        featureFlag: 'dashboard.nav.apps.formulation',
        permissionKey: PERMISSIONS.FORMULATION.SDS_HEADER.VIEW,
        entityType: ENTITY_TYPES.SDS_HEADER,
    },
    {
        path: '/formulation/sds/new',
        component: SdsForm,
        featureFlag: 'dashboard.nav.apps.formulation',
        permissionKey: PERMISSIONS.FORMULATION.SDS_HEADER.VIEW,
        entityType: ENTITY_TYPES.SDS_HEADER,
    },
    {
        path: '/formulation/sds/:id',
        component: SdsForm,
        featureFlag: 'dashboard.nav.apps.formulation',
        permissionKey: PERMISSIONS.FORMULATION.SDS_HEADER.VIEW,
        entityType: ENTITY_TYPES.SDS_HEADER,
    },
    {
        path: '/formulation/sequences',
        component: Sequences,
        featureFlag: 'dashboard.nav.apps.formulation',
        permissionKey: PERMISSIONS.FORMULATION.SDS_HEADER.VIEW,
        entityType: ENTITY_TYPES.SDS_HEADER,
    },
    {
        path: '/formulation/hazardClassMapping',
        component: HazardClassificationMapping,
        featureFlag: 'dashboard.nav.apps.formulation',
        permissionKey: PERMISSIONS.FORMULATION.HAZARD_CLASS_MAPPING.VIEW,
        entityType: ENTITY_TYPES.HAZARD_CLASS_MAPPING,
    },
    {
        path: '/formulation/hazardRating',
        component: HazardRating,
        featureFlag: 'dashboard.nav.apps.formulation',
        permissionKey: PERMISSIONS.FORMULATION.HAZARD_RATINGS.VIEW,
        entityType: ENTITY_TYPES.HAZARD_RATINGS,
    },
    {
        path: '/formulation/hazardClassification',
        component: HazardClassifications,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/hazardClassification/:code',
        component: HazardClassificationShowForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/hazardClassificationRules',
        component: HazardClassificationRule,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/hazardClassificationRules/new',
        component: HazardClassificationRuleForm,
    },
    {
        path: '/formulation/hazardClassificationRules/:id',
        component: HazardClassificationRuleForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/sdsOptionalPhraseLibrary',
        component: SdsOptionalPhraseLibrary,
        permissionKey: PERMISSIONS.FORMULATION.SDS_OPTIONAL_PHRASE_LIBRARY.VIEW,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/sdsOptionalPhraseLibrary/:id',
        component: SdsOptionalPhraseLibraryForm,
        permissionKey: PERMISSIONS.FORMULATION.SDS_OPTIONAL_PHRASE_LIBRARY.VIEW,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/formulaTemplate',
        component: FormulaTemplate,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/formulaTemplate/new',
        component: FormulaTemplateForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/formulaTemplate/:id',
        component: FormulaTemplateForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/integration/rndFormula',
        component: FormulaIntegration,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/equationBuilder',
        component: EquationBuilder,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/equationBuilder/:id',
        component: FormulationEquationBuilderForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
];

export default FormulationRoutes;
