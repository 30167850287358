import { Validator } from './validator.interface';
import moment from 'moment';

const isDateValid = (value?: any) => {
    if (value) {
        const momentDate = moment(value);
        return momentDate.isValid();
    }
    return true;
};

export const DateValidator: Validator = {
    key: 'dateValidator',
    getErrorMessage: (fieldLabel: string, value?: any) => {
        return `Date is not valid`;
    },
    isValid: isDateValid,
};
