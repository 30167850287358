import { datacorAppApi } from '../datacorAppApi';
import _ from 'lodash';

export const jobsApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        listJobsRuns: build.query<any, void>({
            query: () => ({
                url: `/jobs/job-runs`,
                method: 'GET',
            }),
            providesTags: ['JobRuns'],
        }),
        getJobRunWithSteps: build.query<any, string>({
            query: (id) => ({
                url: `/jobs/job-run/${id}`,
                method: 'GET',
            }),
            transformResponse: (response: any) => {
                return {
                    ...response,
                    inputData: !_.isEmpty(response.inputData)
                        ? JSON.stringify(response.inputData)
                        : response.inputData,
                };
            },
            providesTags: ['JobRuns'],
        }),
        getJobRunSearchParams: build.query<any, void>({
            query: (id) => ({
                url: `/jobs/job-runs/search-params`,
                method: 'GET',
            }),
        }),
        searchJobRuns: build.mutation({
            query: (args) => ({
                url: `/jobs/job-runs/search`,
                method: 'POST',
                body: args.postBody,
            }),
        }),
        listJobDetailLogs: build.query<any, void>({
            query: () => ({
                url: `/jobs/job-detail-logs`,
                method: 'GET',
            }),
        }),
        getJobDetailLog: build.query<any, string>({
            query: (id) => ({
                url: `/jobs/job-detail-log/${id}`,
                method: 'GET',
            }),
        }),
        getJobLogSearchParams: build.query({
            query: (id) => ({
                url: `/jobs/job-detail-log/search-params`,
                method: 'GET',
            }),
        }),
        searchJobLogs: build.mutation({
            query: (args) => ({
                url: `/jobs/job-detail-log/search`,
                method: 'POST',
                body: args.postBody,
            }),
        }),
        listJobDefinitionConfigurations: build.query<any, void>({
            query: () => ({
                url: `/jobs/job-definition-configurations`,
                method: 'GET',
            }),
            providesTags: ['JobDefinitionConfigurations'],
        }),
        getJobDefinitionConfiguration: build.query<any, void>({
            query: (id) => ({
                url: `/jobs/job-definition-configuration/${id}`,
                method: 'GET',
            }),
            providesTags: ['JobDefinitionConfigurations'],
        }),
        createJobDefinitionConfiguration: build.mutation<any, any>({
            query: (args) => ({
                url: `/jobs/job-definition-configuration`,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['JobDefinitionConfigurations'],
        }),
        updateJobDefinitionConfiguration: build.mutation<any, any>({
            query: (args) => {
                return {
                    url: `/jobs/job-definition-configuration/${args.id}`,
                    method: 'PUT',
                    body: args.postBody,
                };
            },
            invalidatesTags: ['JobDefinitionConfigurations'],
        }),
        runJobDefinitionConfigurationByJobName: build.mutation<any, any>({
            query: (args) => ({
                url: `/jobs/job-definition-configuration/run`,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['JobRuns'],
        }),
        listJobDefinitions: build.query<any, void>({
            query: () => ({
                url: `/jobs/job-definitions`,
                method: 'GET',
            }),
            providesTags: ['JobDefinitions'],
        }),
    }),
});

export const {
    useListJobsRunsQuery,
    useGetJobRunWithStepsQuery,
    useListJobDetailLogsQuery,
    useGetJobDetailLogQuery,
    useGetJobRunSearchParamsQuery,
    useGetJobLogSearchParamsQuery,
    useSearchJobRunsMutation,
    useSearchJobLogsMutation,
    useListJobDefinitionConfigurationsQuery,
    useGetJobDefinitionConfigurationQuery,
    useCreateJobDefinitionConfigurationMutation,
    useUpdateJobDefinitionConfigurationMutation,
    useListJobDefinitionsQuery,
    useRunJobDefinitionConfigurationByJobNameMutation,
} = jobsApi;

export type JobDefinitionConfiguration = {
    jobDefinitionId?: bigint;
    businessEntityId: bigint;
    runAsUserId: number;
};
