import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import DocumentViewer from './DocumentViewer';

const PreviewDocumentModal = ({
    open,
    onClose,
    id,
    documentType,
    document,
}: any) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={'xl'}>
            <DialogContent>
                {open && (
                    <DocumentViewer
                        id={id}
                        documentType={documentType}
                        document={document}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default PreviewDocumentModal;
