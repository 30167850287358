import { datacorPublicAppApi } from '../datacorAppApi';

export const publicAccessAPI = datacorPublicAppApi.injectEndpoints({
    endpoints: (build) => ({
        createAuthenticationRequestLog: build.mutation<any, any>({
            query: (args) => {
                return {
                    url: `access/authenticate/request`,
                    method: 'POST',
                    body: args.postBody,
                };
            },
        }),
        updateAuthenticationRequestWithError: build.mutation<any, any>({
            query: (args) => {
                return {
                    url: `access/authenticate/update-error`,
                    method: 'POST',
                    body: args.postBody,
                };
            },
        }),
    }),
});

export const {
    useCreateAuthenticationRequestLogMutation,
    useUpdateAuthenticationRequestWithErrorMutation,
} = publicAccessAPI;
