import React from 'react';
import docWorkflow from './images/DocWorkflow.png';
import { Grid, Card, CardMedia, CardHeader } from '@mui/material';
const DocumentManagement = () => {
    return (
        <Grid container spacing={2} className="myApps">
            <Grid item xs={12} className="justify-content-md-center">
                <h1>Document Management</h1>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        title="AWS WorkDocs"
                        className="businessIntel-card-title"
                    />
                    <CardMedia
                        style={{ height: '500px' }}
                        image={docWorkflow}
                        className="businessIntel-image"
                    />
                </Card>
            </Grid>
        </Grid>
    );
};

export default DocumentManagement;
