/**
 * @description
 * File types constants
 * @export
 * @enum {string}
 * FILE_EXTENSIONS.PDF
 * SUPPORTED_UPLOAD_TYPES
 * MIME_EXTENSION_MAP
 */

export enum FILE_EXTENSIONS {
    PDF = 'pdf',
    PNG = 'png',
    JPG = 'jpg',
    JPEG = 'jpeg',
    TXT = 'txt',
    CSV = 'csv',
    XLSX = 'xlsx',
    XLS = 'xls',
}

export const SUPPORTED_UPLOAD_TYPES: any = {
    'application/pdf': [],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
    'application/vnd.ms-excel': [],
    'image/png': [],
    'image/jpg': [],
    'image/jpeg': [],
    'text/plain': [],
    'text/csv': [],
};

export const MIME_EXTENSION_MAP: any = {
    'application/pdf': FILE_EXTENSIONS.PDF,
    'image/png': FILE_EXTENSIONS.PNG,
    'image/jpg': FILE_EXTENSIONS.JPG,
    'image/jpeg': FILE_EXTENSIONS.JPEG,
    'text/plain': FILE_EXTENSIONS.TXT,
    'text/csv': FILE_EXTENSIONS.CSV,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        FILE_EXTENSIONS.XLSX,
    'application/vnd.ms-excel': FILE_EXTENSIONS.XLS,
};
