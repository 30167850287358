import React from 'react';
import { Card, CardMedia, Grid } from '@mui/material';
import cloud_storage from '../../libs/images/myApps/cloud_storage.png';
import chemcadImages from './images/CHEMCAD NXT_Logo_RGB_300_square.png';
import './AppStreaming.css';
import DatacorLogoSpinner from '../../components/datacorLogoSpinner/DatacorLogoSpinner';
import useAppStreaming from './hooks/useAppStreaming';

const streamingIconImages: { [key: string]: string } = {
    CHEMCAD: chemcadImages,
};

const AppStreaming = () => {
    const { streamingAppUrls, isLoadingAppStream, openTabToStreamApp } =
        useAppStreaming();

    const getImage = (streamingInfoName: String) => {
        const imageName = Object.keys(streamingIconImages).find((key) => {
            return streamingInfoName.toUpperCase().includes(key);
        });

        return imageName ? streamingIconImages[imageName] : cloud_storage;
    };

    return (
        <div className="app-streaming">
            <Grid container spacing={2} className="app-streaming-card-deck">
                {streamingAppUrls && !isLoadingAppStream ? (
                    streamingAppUrls.map((streamingInfo: any) =>
                        streamingInfo.StreamingURLResponse ? (
                            <Grid item xs={4} key={streamingInfo.name}>
                                <Card
                                    style={{ height: '300px' }}
                                    className="app-streaming-card"
                                    onClick={() =>
                                        openTabToStreamApp(streamingInfo)
                                    }
                                    key={streamingInfo.name}>
                                    <CardMedia
                                        style={{ height: '200px' }}
                                        className="app-streaming-image"
                                        image={getImage(streamingInfo.name)}
                                    />

                                    <p
                                        style={{
                                            textAlign: 'center',
                                            fontSize: '24px',
                                        }}>
                                        {streamingInfo.name}
                                    </p>
                                </Card>
                            </Grid>
                        ) : null
                    )
                ) : (
                    <DatacorLogoSpinner />
                )}
            </Grid>
        </div>
    );
};

export default AppStreaming;
