// #region Imports
import React, { useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import { DialogActions, DialogContent, Grid } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DatacorLogoSpinner from '../../datacorLogoSpinner/DatacorLogoSpinner';
import { useDispatch, useSelector } from 'react-redux';
import useDate from '../../../utils/hooks/useDate';
import LoaderButton from '../../LoaderButton';
import {
    useGetJobRunWithStepsQuery,
    useSearchJobLogsMutation,
} from '../../../services/jobs/jobs.service';
import JobLogsBasicGrid from '../../../containers/administration/jobs/JobLogsBasicGrid';
import { RootState } from '../../../store';
import { clearJobItemModal } from '../../../store/uiElements';
import { skipToken } from '@reduxjs/toolkit/query';
// #endregion Imports

interface JobItemModalProps {
    jobId: any;
    onClose: () => void;
    open: boolean;
}

const JobItemModal = () => {
    // #region Hooks
    const dispatch = useDispatch();
    const { jobItemModal } = useSelector(
        (state: RootState) => state.uiElements
    );

    const jobRunId = jobItemModal?.isOpen ? jobItemModal?.jobRunId : skipToken;

    const {
        data: job,
        isLoading: isJobLoading,
        isFetching: isJobFetching,
        refetch: refetchJob,
    } = useGetJobRunWithStepsQuery(jobRunId);

    const { dateTimeFormatter } = useDate();

    const [search, props] = useSearchJobLogsMutation();
    const { isLoading: searchIsLoading } = props;
    const [logList, setLogList] = useState([]);

    // #endregion Hooks

    // #region Functions
    const handleClose = () => {
        dispatch(clearJobItemModal());
    };

    useEffect(() => {
        handleSearch();
    }, [job?.id]);

    const getDuration = () => {
        return job?.totalExecutionTimeSeconds
            ? `${job?.totalExecutionTimeSeconds} seconds`
            : 'N/A';
    };

    const handleSearch = async () => {
        if (job?.id) {
            const args = {
                postBody: {
                    jobRunId: job?.id,
                    authenticateByEntity: true,
                },
            };

            try {
                const response = await search(args).unwrap();
                const responseClone = [...response];
                responseClone.sort((a: any, b: any) => {
                    return b.id - a.id;
                });
                setLogList(responseClone);
            } catch (error) {
                setLogList([]);
            }
        }
    };

    const refreshData = () => {
        handleSearch();
        refetchJob();
    };

    const isLoading = searchIsLoading || isJobLoading || isJobFetching;

    // #endregion Functions

    // #region JSX

    return (
        <Dialog
            onClose={handleClose}
            open={jobItemModal?.isOpen}
            fullWidth
            maxWidth={'lg'}>
            <DialogTitle>
                Job Details - {job && dateTimeFormatter(job?.createdAt)}
            </DialogTitle>
            <DialogContent>
                {!isLoading ? (
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <List
                                sx={{
                                    width: '100%',
                                    bgcolor: 'background.paper',
                                }}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <PersonIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`${job?.startedByUser?.firstName} ${job?.startedByUser?.lastName}`}
                                        secondary={`${job?.startedByUser?.email}`}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <BusinessIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary="Job Status"
                                        secondary={
                                            job?.jobStatusType?.name ?? 'N/A'
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <SyncAltIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary="Input Data"
                                        secondary={job?.inputData}
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={6}>
                            <List
                                sx={{
                                    width: '100%',
                                    bgcolor: 'background.paper',
                                }}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <ArrowBackIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`Total Time`}
                                        secondary={getDuration()}
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12}>
                            <JobLogsBasicGrid data={logList} />
                        </Grid>
                    </Grid>
                ) : (
                    <DatacorLogoSpinner />
                )}
            </DialogContent>
            <DialogActions>
                <LoaderButton onClick={refreshData} variant={'contained'}>
                    Refresh
                </LoaderButton>
            </DialogActions>
        </Dialog>
    );

    // #endregion JSX
};

export default JobItemModal;
