import { createSlice } from '@reduxjs/toolkit';
import { SliceState } from '../types/store/FixedAssetStore.types';

const initialState: SliceState = {
    sortOrderSet: {
        isLoading: false,
        maxValue: 0,
    },
};

const slice = createSlice({
    name: 'supplier',
    initialState: initialState,
    reducers: {
        getMaxSortOrderValue: (state, action) => {
            state.sortOrderSet.maxValue = action.payload.maxValue;
            state.sortOrderSet.isLoading = action.payload.isLoading;
        },
    },
});

export default slice.reducer;

export const { getMaxSortOrderValue } = slice.actions;
export const actions = slice.actions;
