import { UserData } from '../../../types/User.types';

export const setSelectApproverOptions = (users: Array<UserData>) => {
    return users.map((user) => {
        return {
            value: user.email,
            label: `${user.firstName} ${user.lastName}`,
        };
    });
};
