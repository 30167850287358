import { AppStream } from '../../types/AppStreaming.types';
import { datacorAppApi } from '../datacorAppApi';

export const appStreamingAPI = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        setSessionStatusOpen: build.mutation<any, void>({
            query: (sessionId) => ({
                url: `/appStream/setSessionStatusOpen/${sessionId}`,
                method: 'POST',
            }),
            invalidatesTags: ['stream'],
        }),
        createStreamingSession: build.mutation<any, AppStream>({
            query: (appStream) => ({
                url: '/appStream/createStreamingSession',
                method: 'POST',
                body: appStream,
            }),
            invalidatesTags: ['stream'],
        }),
        getAppStreamingUrl: build.query<any, any>({
            query: (email) => `/erpAppStreamingUrl/${email}`,
            providesTags: ['stream'],
        }),
    }),
});

export const {
    useSetSessionStatusOpenMutation,
    useCreateStreamingSessionMutation,
    useGetAppStreamingUrlQuery,
} = appStreamingAPI;
