import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import {
    confirmRecordChanges,
    zipRecords,
} from './VerificationChangesExtensions';

export interface VerifyChangesOnFieldsProps {
    blankEntity: any | any[];
    executeVerification: boolean;
    keyField?: string;
}

const useVerificationChangesHelper = (props: VerifyChangesOnFieldsProps) => {
    const { blankEntity, executeVerification, keyField } = props;
    const [originalEntity, setOriginalEntity] = useState<any | any[]>(
        blankEntity
    );
    const [allRecordsChanges, setAllRecordsChanges] = useState(
        new Map<string, boolean>()
    );
    const isMounted = useRef(false);

    const handleSetOriginalEntity = (entity: any | any[]) => {
        if (isMounted.current) {
            setOriginalEntity(entity);
        }
    };

    const handleSetAllRecordsChanges = (
        recordsChanges: Map<string, boolean>
    ) => {
        if (isMounted.current) {
            setAllRecordsChanges(recordsChanges);
        }
    };

    const notifyEntityChanges = (
        fieldName: string,
        value: any,
        supportJSONPathForGetValue: boolean = false
    ) => {
        if (!executeVerification) {
            return;
        }

        allRecordsChanges.set(
            `main-${fieldName}`,
            confirmRecordChanges(
                executeVerification,
                supportJSONPathForGetValue
                    ? _.get(originalEntity, fieldName)
                    : originalEntity[fieldName],
                value
            )
        );
        handleSetAllRecordsChanges(allRecordsChanges);
    };

    const notifyChildChanges = (
        childNodeName: string,
        [key, value]: [string, any]
    ) => {
        if (!executeVerification) {
            return;
        }

        if (originalEntity[childNodeName]) {
            allRecordsChanges.set(
                childNodeName,
                confirmRecordChanges(
                    executeVerification,
                    originalEntity[childNodeName][key],
                    value
                )
            );
        } else {
            allRecordsChanges.set(childNodeName, true);
        }
        handleSetAllRecordsChanges(allRecordsChanges);
    };

    const notifyChildrenChanges = <TChild>(
        childrenNodeName: string,
        newChildrenRecords: TChild[]
    ): void => {
        if (!executeVerification) {
            return;
        }

        const sortedRecords = zipRecords(
            _.sortBy(originalEntity[childrenNodeName], ['id']),
            _.sortBy(newChildrenRecords, ['id'])
        );

        let originalRecord: any;
        let newChildRecord: any;
        let hasChanges: boolean = false;

        for (let records of sortedRecords) {
            originalRecord = records[0];
            newChildRecord = records[1];
            if (originalRecord === undefined || newChildRecord === undefined) {
                hasChanges = true;
            } else {
                for (const fieldName of Object.keys(newChildRecord)) {
                    hasChanges = confirmRecordChanges(
                        executeVerification,
                        originalRecord[fieldName],
                        newChildRecord[fieldName]
                    );

                    if (hasChanges) {
                        break;
                    }
                }
            }

            allRecordsChanges.set(childrenNodeName, hasChanges);

            if (hasChanges) {
                break;
            }
        }
        handleSetAllRecordsChanges(allRecordsChanges);
    };

    const notifyEditableBaseFormGridChanges = <TModel>(
        rowData: TModel[]
    ): void => {
        if (!executeVerification) {
            return;
        }
        const keyId = keyField ? keyField : 'id';
        const sortedRecords = zipRecords(
            _.sortBy(originalEntity, [keyId]),
            _.sortBy(rowData, [keyId])
        );
        let originalRecord: any;
        let newRecord: any;
        let hasChanges: boolean = false;
        for (let records of sortedRecords) {
            originalRecord = records[0];
            newRecord = records[1];

            if (originalRecord === undefined || newRecord === undefined) {
                hasChanges = true;
            } else {
                if (newRecord !== undefined) {
                    const validFields = { ...newRecord };
                    delete validFields['rowStatus'];
                    delete validFields['hasError'];
                    for (const fieldName of Object.keys(validFields)) {
                        hasChanges = confirmRecordChanges(
                            executeVerification,
                            originalRecord[fieldName],
                            newRecord[fieldName]
                        );

                        if (hasChanges) {
                            break;
                        }
                    }
                }
            }

            allRecordsChanges.set('editableBaseFormGrid', hasChanges);

            if (hasChanges) {
                break;
            }
        }
        handleSetAllRecordsChanges(allRecordsChanges);
    };

    /**
     * used when multiple fields change at one time via updateCompleteChildRecord
     */
    const notifyChildChangesWhenUpdated = (
        childNodeName: string,
        childRecord: any
    ) => {
        if (!executeVerification) {
            return;
        }

        if (originalEntity[childNodeName]) {
            for (const fieldName of Object.keys(childRecord)) {
                allRecordsChanges.set(
                    childNodeName,
                    confirmRecordChanges(
                        executeVerification,
                        originalEntity[childNodeName][fieldName],
                        childRecord[fieldName]
                    )
                );
                if (allRecordsChanges.get(childNodeName)) {
                    break;
                }
            }
        } else {
            allRecordsChanges.set(childNodeName, true);
        }
        handleSetAllRecordsChanges(allRecordsChanges);
    };

    const clearChangesHistory = (): void => {
        handleSetAllRecordsChanges(new Map<string, boolean>());
    };

    const hasCurrentRecordChanged = (): boolean => {
        if (!executeVerification) {
            return false;
        }
        return [...allRecordsChanges.values()].includes(true);
    };

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    return {
        verifyChanges: {
            mainForm: {
                hasCurrentRecordChanged,
                setOriginalEntity: handleSetOriginalEntity,
                clearChangesHistory,
            },
            childForm: {
                hasCurrentRecordChanged,
                setOriginalEntity: handleSetOriginalEntity,
                clearChangesHistory,
            },
            editableBaseFormGrid: {
                hasCurrentRecordChanged,
                setOriginalEntity: handleSetOriginalEntity,
                clearChangesHistory,
            },
            notificationMethods: {
                notifyEntityChanges,
                notifyChildChanges,
                notifyChildrenChanges,
                notifyEditableBaseFormGridChanges,
                notifyChildChangesWhenUpdated,
            },
        },
    };
};

export default useVerificationChangesHelper;
