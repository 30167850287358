import React from 'react';
import CommentsList from './CommentsList';
import CommentsBox from './CommentsBox';

const CommentsView = ({ documentId }: any) => {
    return (
        <>
            <CommentsBox documentId={documentId} />
            <br />
            <CommentsList documentId={documentId} />
        </>
    );
};

export default CommentsView;
