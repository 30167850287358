import React, { useContext } from 'react';
import SettingsContext from '../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { skipToken } from '@reduxjs/toolkit/query';

/**
 *
 * @param termSetCode - the code for the termSetDefinition you want to get
 * @param skip(default false) - boolean for if you want to skip the api for any reason
 *
 */
const useTranslation = (termSetCode: string, skip: any = false) => {
    const { settings } = useContext(SettingsContext);

    const { data: termSet, isLoading: isLoadingTermSet } = useGetTermSetQuery(
        !skip
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: termSetCode,
              }
            : skipToken
    );

    return { termSet, isLoadingTermSet };
};

export default useTranslation;
