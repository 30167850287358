import React, { useState, useEffect } from 'react';
import LoaderButton from '../LoaderButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useUpdatePurchaseOrderMatchStatusMutation } from '../../services/apAutomation/apAutomation.service';

const CONFIRM_BUTTON_INFO: any = {
    MANUAL_MATCH: {
        variant: 'error',
        text: 'Unconfirm',
        matchAction: 'unconfirmMatch',
        endIcon: <HighlightOffIcon />,
    },
    MANUAL_PARTIAL_MATCH: {
        variant: 'success',
        text: 'Confirm',
        matchAction: 'confirmMatch',
        endIcon: <CheckCircleOutlineIcon />,
    },

    NOT_MATCHED: {
        variant: 'success',
        text: 'Confirm',
        matchAction: 'confirmMatch',
        endIcon: <CheckCircleOutlineIcon />,
    },
};

interface ConfirmButtonProps {
    poStatus: any;
    documentId: string;
}

const ConfirmButton = ({ poStatus, documentId }: ConfirmButtonProps) => {
    const [updatePurchaseOrderMatchStatus] =
        useUpdatePurchaseOrderMatchStatusMutation();
    const [confirmButtonInfo, setConfirmButtonInfo] = useState(
        CONFIRM_BUTTON_INFO[poStatus?.key]
    );
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        setConfirmButtonInfo(CONFIRM_BUTTON_INFO[poStatus?.key]);
    }, [poStatus]);

    const handleConfirmButtonClick = async () => {
        setIsSubmitting(true);

        const args = {
            documentId: documentId,
            matchAction: confirmButtonInfo.matchAction,
        };
        updatePurchaseOrderMatchStatus(args);

        setIsSubmitting(false);
    };

    return (
        <div style={{ padding: '10px' }}>
            <LoaderButton
                variant="contained"
                isLoading={isSubmitting}
                onClick={handleConfirmButtonClick}
                color={confirmButtonInfo.variant}
                endIcon={confirmButtonInfo.endIcon}>
                {confirmButtonInfo.text}
            </LoaderButton>
        </div>
    );
};

export default ConfirmButton;
