import { datacorAppApi } from '../datacorAppApi';
import {
    BaseQueryMeta,
    BaseQueryResult,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { Promise } from 'cypress/types/cy-bluebird';

export const notificationsAPI = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        listNotifications: build.query<any, void>({
            query: () => `/notifications`,
            providesTags: ['Notifications'],
        }),
        listNotificationsForUser: build.query<any, void>({
            query: () => `/notifications/user`,
            providesTags: ['Notifications'],
            transformResponse: (response: any) => {
                //sort by createdAt desc
                return response.sort((a: any, b: any) => {
                    return (
                        new Date(b.createdAt).getTime() -
                        new Date(a.createdAt).getTime()
                    );
                });
            },
        }),
        getNotification: build.query<any, any>({
            query: (args) => `/notifications/${args.id}`,
            providesTags: ['Notifications'],
        }),
        createNotification: build.mutation<any, any>({
            query: (args) => ({
                url: '/notifications',
                method: 'POST',
                body: args,
            }),
            invalidatesTags: ['Notifications'],
        }),
        markRead: build.mutation<any, any>({
            query: (id) => ({
                url: `/notifications/${id}/read`,
                method: 'POST',
            }),
            invalidatesTags: ['Notifications'],
        }),
        getBeConfig: build.query<any, void>({
            query: () => `/notifications/subscriptions/be/config`,
            providesTags: ['NotificationConfig'],
        }),
        updateBeConfig: build.mutation<any, any>({
            query: (args) => ({
                url: `/notifications/subscriptions/be/config`,
                method: 'POST',
                body: args,
            }),
            invalidatesTags: ['NotificationConfig'],
        }),
        getUserConfig: build.query<any, void>({
            query: () => `/notifications/subscriptions/user/config`,
            providesTags: ['NotificationConfig'],
        }),
        updateUserConfig: build.mutation<any, any>({
            query: (args) => ({
                url: `/notifications/subscriptions/user/config`,
                method: 'POST',
                body: args,
            }),
            invalidatesTags: ['NotificationConfig'],
        }),
        listBeSubscriptions: build.query<any, void>({
            query: () => `/notifications/subscriptions/be`,
            transformResponse: (response: any) => {
                return response.filter((item: any) => !item.required);
            },
            providesTags: ['NotificationConfig'],
        }),
        listUserSubscriptions: build.query<any, void>({
            query: () => `/notifications/subscriptions/user`,
            transformResponse: (response: any) => {
                return response.filter((item: any) => !item.required);
            },
            providesTags: ['NotificationConfig'],
        }),
        updateBeSubscription: build.mutation<any, any>({
            query: (args) => ({
                url: `/notifications/subscriptions/be`,
                method: 'POST',
                body: args,
            }),
            invalidatesTags: ['NotificationConfig'],
        }),
        updateUserSubscription: build.mutation<any, any>({
            query: (args) => ({
                url: `/notifications/subscriptions/user`,
                method: 'POST',
                body: args,
            }),
            invalidatesTags: ['NotificationConfig'],
        }),
        listNotificationContentTmpls: build.query<any, void>({
            query: () => `/notifications/templates`,
            providesTags: ['NotificationTemplates'],
        }),
        getNotificationContentTmpl: build.query<any, any>({
            query: (id) => `/notifications/templates/${id}`,
            providesTags: ['NotificationTemplates'],
        }),
        createNotificationContentTmpl: build.mutation<any, any>({
            query: (args) => ({
                url: '/notifications/templates',
                method: 'POST',
                body: args,
            }),
            invalidatesTags: ['NotificationTemplates'],
        }),
        updateNotificationContentTmpl: build.mutation<any, any>({
            query: (args) => ({
                url: `/notifications/templates/${args.id}`,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['NotificationTemplates'],
        }),
        deleteNotificationContentTmpl: build.mutation<any, any>({
            query: (id) => ({
                url: `/notifications/templates/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['NotificationTemplates'],
        }),
        listEventDefinitions: build.query<any, void>({
            query: () => `/event-definitions`,
            providesTags: ['Notifications'],
        }),
        testSystemEvent: build.mutation<any, any>({
            query: (args) => ({
                url: '/test-system-event',
                method: 'POST',
                body: args,
            }),
        }),
        listPublishedEvents: build.query<any, void>({
            query: () => `/published-events`,
            providesTags: ['Notifications'],
        }),
    }),
});

export const {
    useListNotificationsQuery,
    useListNotificationsForUserQuery,
    useGetNotificationQuery,
    useCreateNotificationMutation,
    useMarkReadMutation,
    useGetBeConfigQuery,
    useUpdateBeConfigMutation,
    useGetUserConfigQuery,
    useUpdateUserConfigMutation,
    useListBeSubscriptionsQuery,
    useListUserSubscriptionsQuery,
    useUpdateBeSubscriptionMutation,
    useUpdateUserSubscriptionMutation,
    useListNotificationContentTmplsQuery,
    useGetNotificationContentTmplQuery,
    useCreateNotificationContentTmplMutation,
    useUpdateNotificationContentTmplMutation,
    useDeleteNotificationContentTmplMutation,
    useListEventDefinitionsQuery,
    useTestSystemEventMutation,
    useListPublishedEventsQuery,
} = notificationsAPI;
