import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { IconButton, Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { SUPPORTED_UPLOAD_TYPES } from '../../constants/attachments/fileTypes.constants';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export type DragAndDropProps = {
    onDrop: (acceptedFiles: File[], rejectedFiles: File[]) => Promise<void>;
};

const FileDropper = (props: DragAndDropProps) => {
    const [shouldOpen, setShouldOpen] = useState(false);
    const [numFiles, setNumFiles] = useState(0);
    const [rejectedFiles, setRejectedFiles] = useState(0);

    const onDrop = async (acceptedFiles: any, rejectedFiles: any) => {
        setShouldOpen(true);
        setNumFiles(acceptedFiles.length);
        setRejectedFiles(rejectedFiles.length);

        await props.onDrop(acceptedFiles, rejectedFiles);
    };

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        acceptedFiles,
        fileRejections,
    } = useDropzone({
        accept: {
            ...SUPPORTED_UPLOAD_TYPES,
        },
        onDrop,
    });

    return (
        <>
            {rejectedFiles === 0 && (
                <Snackbar
                    open={shouldOpen}
                    autoHideDuration={5000}
                    onClose={() => setShouldOpen(false)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}>
                    <Alert
                        onClose={() => setShouldOpen(false)}
                        severity="success"
                        sx={{ width: '100%' }}>
                        {numFiles} File(s) Uploaded.
                    </Alert>
                </Snackbar>
            )}
            {rejectedFiles > 0 && (
                <Snackbar
                    open={shouldOpen}
                    autoHideDuration={5000}
                    onClose={() => setShouldOpen(false)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}>
                    <Alert
                        onClose={() => setShouldOpen(false)}
                        severity={numFiles > 0 ? 'warning' : 'error'}
                        sx={{ width: '100%' }}>
                        {numFiles > 0 && (
                            <>{numFiles} Uploaded Successfully - </>
                        )}
                        Please make sure {rejectedFiles} file(s) are in a PDF
                        format.
                    </Alert>
                </Snackbar>
            )}
            <div {...getRootProps()}>
                <input {...getInputProps()} />

                <div className="overlay-grid">
                    <IconButton color="primary" size="large">
                        <FileUploadIcon />
                    </IconButton>
                    {isDragActive && <span>Drop files to upload</span>}
                </div>
            </div>
        </>
    );
};

export default FileDropper;
