import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormFields } from '../../../libs/hooksLib';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { Auth } from 'aws-amplify';
import { showNotificationError } from '../../../libs/errorLib';
import { useDispatch } from 'react-redux';
import { getDefaultMessageFromError } from '../../../utils/error/errorMessageUtils';

const useResetPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [fields, handleFieldChange] = useFormFields({
        code: '',
        email: '',
        password: '',
        confirmPassword: '',
    });
    const [isSendingCode, setIsSendingCode] = useState(false);

    function validateCodeForm() {
        return !isNilOrEmpty(fields.email);
    }

    const handleSendCodeClick = async (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();

        setIsSendingCode(true);

        try {
            await Auth.forgotPassword(fields.email);
            navigate('/login/resetConfirmation');
        } catch (error) {
            const errorDisplayMessage = getDefaultMessageFromError(error);
            showNotificationError(errorDisplayMessage, 'error', dispatch);
            setIsSendingCode(false);
        }
    };
    return {
        fields,
        handleFieldChange,
        isSendingCode,
        handleSendCodeClick,
        validateCodeForm,
    };
};

export default useResetPassword;
