import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import {
    DaysWithConcernCell,
    DollarsLostConcernCell,
    dollarsLostConcernCellStyle,
    DollarsPer,
    QuantityUom,
} from '../Utils';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PricingAnalysisToolGridHeaderTooltips from '../PricingAnalysisToolGridHeaderTooltips';
import {
    currencyFormatter,
    percentFormatter,
} from '../../../utils/formattingUtils';
import Grid from '../../../components/grids/Grid';
import DatacorLogoSpinner from '../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import { numberAsStringComparator } from '../../../utils/comparisonUtils';
import usePricingCustomerProductDetailsGrid from '../hooks/PricingCustomerProductDetails/usePricingCustomerProductDetailsGrid';

const PricingCustomerProductDetailsGrid = () => {
    const {
        pricingAnalysisToolSet,
        onBtExport,
        rowData,
        onFirstDataRendered,
        onFilterChanged,
        onGridReady,
        onSortChanged,
    } = usePricingCustomerProductDetailsGrid();

    return !pricingAnalysisToolSet.activeCustomerProductDetailsLoading &&
        !pricingAnalysisToolSet.pricingConcernDetailsIsLoading ? (
        <>
            <FileDownloadIcon
                style={{
                    color: 'green',
                    cursor: 'pointer',
                    padding: '5px',
                    fontSize: '25px',
                }}
                onClick={() => onBtExport()}
            />
            <Grid
                gridStyle={{ width: '100%' }}
                testId="pricing-customer-product-details"
                dataKey="transactionId"
                autofitColumns={false}
                defaultColDef={{
                    menuTabs: [
                        'filterMenuTab',
                        'generalMenuTab',
                        'columnsMenuTab',
                    ],
                    resizable: true,
                    sortable: true,
                    unSortIcon: true,
                    filter: true,
                    wrapText: true,
                    tooltipComponent: 'customTooltip',
                }}
                paginationPageSize={15}
                rowData={rowData}
                rowHeight={25}
                onFirstDataRendered={onFirstDataRendered}
                onSortChanged={onSortChanged}
                onFilterChanged={onFilterChanged}
                onGridReady={onGridReady}
                frameworkComponents={{
                    daysWithConcernCell: DaysWithConcernCell,
                    dollarsLostConcernCell: DollarsLostConcernCell,
                    quantityUom: QuantityUom,
                    dollarsPer: DollarsPer,
                    customTooltip: PricingAnalysisToolGridHeaderTooltips,
                }}
                suppressMenuHide={true}>
                <AgGridColumn
                    field="warehouseName"
                    headerName="Warehouse"
                    headerClass={'pick-list-header'}
                    width={200}
                />
                <AgGridColumn
                    field="orderType"
                    headerName="Order Type"
                    headerClass={'pick-list-header'}
                    width={110}
                />
                <AgGridColumn
                    field="orderDate"
                    headerClass={'pick-list-header'}
                    headerName="Order Date"
                    width={150}
                />
                <AgGridColumn
                    headerName="Dollars Lost"
                    headerClass="grid-group-2">
                    <AgGridColumn
                        field="belowAverageMarginDollarsLost"
                        headerClass={'pick-list-header'}
                        headerName="Below Avg Margin"
                        width={170}
                        cellRenderer="dollarsLostConcernCell"
                        cellStyle={dollarsLostConcernCellStyle}
                        comparator={numberAsStringComparator}
                    />
                    <AgGridColumn
                        field="marginBelowGoalDollarsLost"
                        headerClass={'pick-list-header'}
                        headerName="Below Goal Margin"
                        width={170}
                        cellRenderer="dollarsLostConcernCell"
                        cellStyle={dollarsLostConcernCellStyle}
                        comparator={numberAsStringComparator}
                    />
                    <AgGridColumn
                        field="pricingLagDollarsLost"
                        headerClass={'pick-list-header'}
                        headerName="Pricing Lag"
                        width={170}
                        cellRenderer="dollarsLostConcernCell"
                        cellStyle={dollarsLostConcernCellStyle}
                        comparator={numberAsStringComparator}
                    />
                    <AgGridColumn
                        field="belowAveragePriceDollarsLost"
                        headerClass={'pick-list-header'}
                        headerName="Below Average Price"
                        width={170}
                        cellRenderer="dollarsLostConcernCell"
                        cellStyle={dollarsLostConcernCellStyle}
                        comparator={numberAsStringComparator}
                    />
                    <AgGridColumn
                        field="outdatedPricingDollarsLost"
                        headerClass={'pick-list-header'}
                        headerName="Outdated Pricing"
                        width={170}
                        cellRenderer="dollarsLostConcernCell"
                        cellStyle={dollarsLostConcernCellStyle}
                        comparator={numberAsStringComparator}
                    />
                </AgGridColumn>
                <AgGridColumn
                    headerName="Previous 12 month Averages"
                    headerClass="grid-group-1">
                    <AgGridColumn
                        field="averageMargin"
                        headerName="Avg Margin"
                        headerClass={'pick-list-header'}
                        width={140}
                        valueFormatter={percentFormatter}
                        headerTooltip="averageMargin"
                        tooltipComponent="customTooltip"
                    />
                    <AgGridColumn
                        field="percentBelowAverageMargin"
                        headerName="% Below Avg Margin"
                        headerClass={'pick-list-header'}
                        width={150}
                        valueFormatter={percentFormatter}
                    />
                    <AgGridColumn
                        field="averagePrice"
                        headerName="Avg Price"
                        headerClass={'pick-list-header'}
                        width={140}
                        cellRenderer="dollarsPer"
                        headerTooltip="averagePrice"
                        tooltipComponent="customTooltip"
                    />
                </AgGridColumn>
                <AgGridColumn
                    headerName="Transaction Details"
                    headerClass="grid-group-3">
                    <AgGridColumn
                        field="productMeasure"
                        headerName="Measure"
                        headerClass={'pick-list-header'}
                        width={140}
                    />
                    <AgGridColumn
                        field="productUnitOfMeasure"
                        headerName="UOM"
                        headerClass={'pick-list-header'}
                        width={110}
                    />
                    <AgGridColumn
                        field="orderQuantity"
                        headerName="Quantity"
                        headerClass={'pick-list-header'}
                        width={140}
                    />
                    <AgGridColumn
                        field="productUnitOfPackaging"
                        headerName="UOP"
                        headerClass={'pick-list-header'}
                        width={110}
                    />
                    <AgGridColumn
                        field="fob"
                        headerClass={'pick-list-header'}
                        headerName="FOB"
                        width={110}
                    />
                    <AgGridColumn
                        field="orderPrice"
                        headerClass={'pick-list-header'}
                        headerName="Order Price"
                        width={150}
                        cellRenderer="dollarsPer"
                    />
                    <AgGridColumn
                        field="orderCost"
                        headerClass={'pick-list-header'}
                        headerName="Material Cost"
                        width={140}
                        cellRenderer="dollarsPer"
                    />
                    <AgGridColumn
                        field="profit"
                        headerClass={'pick-list-header'}
                        headerName="Profit"
                        width={150}
                        cellRenderer="dollarsPer"
                    />
                    <AgGridColumn
                        field="margin"
                        headerClass={'pick-list-header'}
                        headerName="Margin"
                        width={140}
                        valueFormatter={percentFormatter}
                    />
                    <AgGridColumn
                        field="invoiceAmount"
                        headerClass={'pick-list-header'}
                        headerName="Sales Dollars"
                        width={150}
                        valueFormatter={currencyFormatter}
                    />
                    <AgGridColumn
                        field="lastCost"
                        headerClass={'pick-list-header'}
                        headerName="Last Cost"
                        width={150}
                        cellRenderer="dollarsPer"
                    />
                    <AgGridColumn
                        field="currentCost"
                        headerClass={'pick-list-header'}
                        headerName="Current Cost"
                        width={150}
                        cellRenderer="dollarsPer"
                    />
                    <AgGridColumn
                        field="dateOfLastCostIncrease"
                        headerClass={'pick-list-header'}
                        headerName="Last Cost Increase Date"
                        width={150}
                    />
                    <AgGridColumn
                        field="freightRate"
                        headerClass={'pick-list-header'}
                        headerName="Freight Rate"
                        width={150}
                    />
                    <AgGridColumn
                        field="freightRateUnitMeasure"
                        headerClass={'pick-list-header'}
                        headerName="Freight Rate Unit of Measure"
                        width={150}
                    />
                    <AgGridColumn
                        field="freightWeight"
                        headerClass={'pick-list-header'}
                        headerName="Freight Weight"
                        width={150}
                    />
                    <AgGridColumn
                        field="freightCost"
                        headerClass={'pick-list-header'}
                        headerName="Freight Cost"
                        width={150}
                        cellRenderer="dollarsPer"
                    />
                    <AgGridColumn
                        field="freightFlag"
                        headerClass={'pick-list-header'}
                        headerName="Freight Flag"
                        width={150}
                    />
                    <AgGridColumn
                        field="orderPriceDateSet"
                        headerClass={'pick-list-header'}
                        headerName="Order Price Date Set"
                        width={150}
                    />
                    <AgGridColumn
                        field="orderPriceExpiration"
                        headerClass={'pick-list-header'}
                        headerName="Order Price Expiration"
                        width={150}
                    />
                    <AgGridColumn
                        field="numberOfDaysSincePriceChange"
                        headerClass={'pick-list-header'}
                        headerName="Days Since Price Change"
                        width={150}
                    />
                    <AgGridColumn
                        field="exchangeRate"
                        headerClass={'pick-list-header'}
                        headerName="Exchange Rate"
                        width={150}
                    />
                    <AgGridColumn
                        field="invoiceNumber"
                        headerClass={'pick-list-header'}
                        headerName="Invoice Number"
                        width={150}
                    />
                </AgGridColumn>
            </Grid>
        </>
    ) : (
        <DatacorLogoSpinner />
    );
};

export default PricingCustomerProductDetailsGrid;
