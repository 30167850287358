import {
    currencyFormatter,
    dateFormatter,
    dateTimeFormatter,
    percentFormatter,
    percentFromDecimalFormatter,
} from '../../../utils/formattingUtils';
import { AgGridColumnProps } from 'ag-grid-react/lib/shared/agGridColumn';
import {
    dateComparator,
    dateValueComparator,
} from '../../../utils/comparisonUtils';
import BooleanCellRenderer from '../cellRenderers/booleanCell.renderer';
import LockedCellRenderer from '../cellRenderers/lockedCell.renderer';
import { AttachmentSummaryPopover } from '../cellRenderers/attachmentSummary.renderer';

export default class DefaultColumnTypes {
    static AutoHeight: AgGridColumnProps = {
        cellStyle: {
            overflow: 'visible',
            whiteSpace: 'normal',
            wordBreak: 'normal',
            height: 'auto',
        },
        autoHeight: true,
    };

    static ShortText: AgGridColumnProps = {
        minWidth: 150,
        filter: 'agTextColumnFilter',
    };

    static MediumText: AgGridColumnProps = {
        minWidth: 300,
        filter: 'agTextColumnFilter',
    };

    static LongText: AgGridColumnProps = {
        minWidth: 500,
        filter: 'agTextColumnFilter',
        ...DefaultColumnTypes.AutoHeight,
    };

    static DateOnly: AgGridColumnProps = {
        minWidth: 175,
        filter: 'agDateColumnFilter',
        filterParams: dateValueComparator,
        valueFormatter: dateFormatter,
    };

    static DateTime: AgGridColumnProps = {
        width: 250,
        minWidth: 200,
        maxWidth: 300,
        filter: 'agDateColumnFilter',
        filterParams: {
            comparator: dateComparator,
            browserDatePicker: true,
        },
        valueFormatter: dateTimeFormatter,
    };

    static NumberOnly: AgGridColumnProps = {
        minWidth: 150,
        filter: 'agNumberColumnFilter',
        headerClass: 'ag-right-aligned-header',
        cellClass: 'ag-right-aligned-cell',
    };

    static Currency: AgGridColumnProps = {
        minWidth: 150,
        filter: 'agNumberColumnFilter',
        valueFormatter: currencyFormatter,
        headerClass: 'ag-right-aligned-header',
        cellClass: 'ag-right-aligned-cell',
    };

    static AccountingCurrency: AgGridColumnProps = {
        width: 150,
        minWidth: 130,
        maxWidth: 200,
        filter: 'agNumberColumnFilter',
        valueFormatter: (val: any) => currencyFormatter(val, 2, 'accounting'),
        headerClass: 'ag-right-aligned-header',
        cellClass: 'ag-right-aligned-cell',
    };

    static AccountingCurrencyWithTotal: AgGridColumnProps = {
        ...DefaultColumnTypes.AccountingCurrency,
        aggFunc: (params) => {
            let total = 0;
            params.values.forEach((value) => (total += Number(value)));
            return total;
        },
    };

    static Percent: AgGridColumnProps = {
        width: 150,
        minWidth: 100,
        maxWidth: 200,
        filter: 'agNumberColumnFilter',
        valueFormatter: percentFormatter,
        headerClass: 'ag-right-aligned-header',
        cellClass: 'ag-right-aligned-cell',
    };

    static PercentFromDecimal: AgGridColumnProps = {
        width: 100,
        minWidth: 150,
        maxWidth: 150,
        filter: 'agNumberColumnFilter',
        valueFormatter: percentFromDecimalFormatter,
        headerClass: 'ag-right-aligned-header',
        cellClass: 'ag-right-aligned-cell',
    };

    static Boolean: AgGridColumnProps = {
        minWidth: 200,
        filter: 'agTextColumnFilter',
        cellRenderer: BooleanCellRenderer,
    };

    static Locked: AgGridColumnProps = {
        width: 100,
        minWidth: 50,
        maxWidth: 200,
        filter: 'agTextColumnFilter',
        cellRendererFramework: {
            lockedCellRenderer: LockedCellRenderer,
        },
        cellRenderer: 'lockedCellRenderer',
    };

    static LargeTextEditor: AgGridColumnProps = {
        cellEditorPopup: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: {
            maxLength: 255,
            rows: 10,
            cols: 50,
        },
        flex: 2,
    };

    static CheckboxSelection: AgGridColumnProps = {
        width: 60,
        minWidth: 50,
        maxWidth: 75,
        checkboxSelection: true,
        filter: false,
    };

    static StackedColumn: AgGridColumnProps = {
        filter: false,
        sortable: false,
        resizable: false,
    };

    static StackedCurrencyColumn: AgGridColumnProps = {
        ...DefaultColumnTypes.StackedColumn,
        width: 150,
        minWidth: 100,
        maxWidth: 200,
        headerClass: 'ag-right-aligned-header',
        cellClass: 'ag-right-aligned-cell',
    };

    static LockedInformationColumn: AgGridColumnProps = {
        ...DefaultColumnTypes.StackedColumn,
        width: 230,
        minWidth: 230,
        maxWidth: 280,
    };

    static attachmentSummaryColumn: AgGridColumnProps = {
        minWidth: 175,
        filter: 'agTextColumnFilter',
        cellRendererFramework: {
            attachmentSummaryPopover: AttachmentSummaryPopover,
        },
        cellRenderer: 'attachmentSummaryPopover',
    };
}
