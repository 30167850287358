import React, { useEffect } from 'react';
import { Menu } from '@mui/material';
import {
    organizationsApi,
    useGetUserHistoryQuery,
    User,
    useUpdateUserMutation,
} from '../../../services/organizations/organizations.service';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { handleUpdateUserBusinessEntity } from '../../../store/user';
import { BaseUpdateEntityArgs } from '../../../services/serviceInterfaces';
import { openSnackbarBasicWithMessage } from '../../../store/uiElements';

const RecentItems = ({ anchorEl, setAnchorEl }: any) => {
    const open = Boolean(anchorEl);
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [updateUser] = useUpdateUserMutation();
    const { data: recentItems, refetch } = useGetUserHistoryQuery();

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        refetch();
    }, [anchorEl]);

    const handleGoToRecentItem = async (item: any) => {
        if (user?.businessEntity?.id !== item?.businessEntityId) {
            try {
                const businessEntity: any = await dispatch(
                    organizationsApi.endpoints.getBusinessEntity.initiate(
                        item?.businessEntityId?.toString()
                    )
                );

                const { data: businessEntityData } = businessEntity;

                dispatch(handleUpdateUserBusinessEntity(businessEntityData));

                const args: BaseUpdateEntityArgs<User> = {
                    id: user?.userId as unknown as number,
                    ignoreRevision: 'true',
                    postBody: {
                        activeWebBusinessEntityId: item.businessEntityId,
                    },
                };

                updateUser(args);

                navigate(item?.fullPath);
            } catch (error) {
                console.log('error', error);

                dispatch(
                    openSnackbarBasicWithMessage({
                        message: `Unable to switch business entity. Please try again.`,
                        severity: 'error',
                    })
                );
            }
        } else {
            navigate(item?.fullPath);
        }
        setAnchorEl(null);
    };

    return (
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {_.uniqBy(recentItems, 'fullPath')
                ?.filter((item: any) => item?.primaryEntity)
                ?.sort((a: any, b: any) => {
                    const dateA: any = new Date(a.createdAt);
                    const dateB: any = new Date(b.createdAt);
                    return dateB - dateA;
                })
                .map((item: any) => (
                    <MenuItem
                        key={item.id}
                        onClick={() => handleGoToRecentItem(item)}>
                        {item?.pageTitle}
                    </MenuItem>
                ))}
        </Menu>
    );
};

export default RecentItems;
