import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import React from 'react';

export interface FormPartialProps {
    title?: string;
    isVisible?: boolean;
    children: React.ReactNode;
    link?: string;
}

const FormPartial = ({ isVisible = true, ...props }: FormPartialProps) => {
    return (
        isVisible && (
            <div id={props?.link}>
                {props.title && (
                    <Divider className="form-divider" textAlign="left">
                        {props.title}
                    </Divider>
                )}
                <Box component="div" sx={{ paddingLeft: '10px' }}>
                    {props.children}
                </Box>
            </div>
        )
    );
};

export default FormPartial;
