import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import { updateSelectedPricingConcerns } from '../../../../store/pricingAnalysisTool';
import { RootState } from '../../../../store';

const usePricingConcernDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pricingAnalysisToolSet } = useSelector(
        (state: RootState) => state.pricingAnalysisTool
    );

    useEffect(() => {
        if (
            isNilOrEmpty(
                Object.keys(
                    pricingAnalysisToolSet.pricingConcernSelectedCategories
                )
            )
        ) {
            dispatch(
                updateSelectedPricingConcerns([
                    'outdatedPricing',
                    'pricingLag',
                    'belowAverageMargin',
                    'marginBelowGoal',
                    'belowAveragePrice',
                ])
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricingAnalysisToolSet.pricingConcernSelectedCategories]);

    const gotoProposalReport = () => {
        navigate('/pricingAnalysisTool/pricingProposalReport');
    };

    return { gotoProposalReport, pricingAnalysisToolSet };
};

export default usePricingConcernDetails;
