export const PERMISSIONS = {
    SETTINGS: {
        FEATURE_FLAGS: {
            VIEW: 'Settings.featureFlag.view',
            EDIT: 'Settings.featureFlag.edit',
        },
    },
    USER_PORTAL: {
        VIEW: 'Administration.View.viewUserPortal',
        USERS: {
            VIEW: 'Administration.Users.view',
            CREATE: 'Administration.Users.create',
            EDIT: 'Administration.Users.edit',
            DELETE: 'Administration.Users.delete',
        },
        COMPANIES: {
            VIEW: 'Administration.Business Entities.View',
            CREATE: 'Administration.Business Entities.create',
            EDIT: 'Administration.Business Entities.edit',
            DELETE: 'Administration.Business Entities.delete',
        },
    },
    ADMINISTRATION: {
        VIEW: 'Administration.View.viewUserPortal',
        BUSINESS_ENTITIES: {
            VIEW: 'Administration.Business Entities.View',
            CREATE: 'Administration.Business Entities.create',
            EDIT: 'Administration.Business Entities.edit',
            DELETE: 'Administration.Business Entities.delete',
        },
        USERS: {
            VIEW: 'Administration.Users.view',
            CREATE: 'Administration.Users.create',
            EDIT: 'Administration.Users.edit',
            DELETE: 'Administration.Users.delete',
        },
        ACCESS_RESTRICTIONS: {
            VIEW: 'Administration.AccessRestrictions.view',
            EDIT: 'Administration.AccessRestrictions.edit',
            DELETE: 'Administration.AccessRestrictions.edit',
        },
    },
    AP_AUTOMATION: {
        VIEW: 'AP Automation.view',
        INVOICES: {
            UPDATE: 'AP Automation.updateInvoice',
        },
        APPROVAL_WORKFLOWS: {
            VIEW: 'AP Automation.approvalWorkflows.view',
            CREATE: 'AP Automation.approvalWorkflows.create',
            DELETE: 'AP Automation.approvalWorkflows.delete',
            UPDATE: 'AP Automation.approvalWorkflows.update',
        },
        DEFAULT_TOLERANCE: {
            VIEW: 'AP Automation.defaultTolerance.view',
            EDIT: 'AP Automation.defaultTolerance.edit',
        },
        MANUAL_MATCH: 'AP Automation.manualPoMatching',
        VIEW_ASSIGNED_ONLY: 'AP Automation.viewOnlyAssignedDocuments',
    },
    FIXED_ASSETS: {
        FIXED_ASSET_REGISTER: {
            CREATE: 'Fixed Assets.Fixed Asset Register.create',
            EDIT: 'Fixed Assets.Fixed Asset Register.edit',
            DELETE: 'Fixed Assets.Fixed Asset Register.delete',
            VIEW: 'Fixed Assets.Fixed Asset Register.view',
            IMPORT: 'Fixed Assets.Fixed Asset Register.import',
        },
        ASSET_ENTRY: {
            CREATE: 'Fixed Assets.Asset Entry.create',
            DELETE: 'Fixed Assets.Asset Entry.delete',
            LOCK: 'Fixed Assets.Asset Entry.lock',
            VIEW: 'Fixed Assets.Asset Entry.view',
            DOWNLOAD: 'Fixed Assets.Asset Entry.download',
        },
        ASSET_COST_CODES: {
            CREATE: 'Fixed Assets.Setup.Asset Cost Codes.create',
            EDIT: 'Fixed Assets.Setup.Asset Cost Codes.edit',
            DELETE: 'Fixed Assets.Setup.Asset Cost Codes.delete',
            VIEW: 'Fixed Assets.Setup.Asset Cost Codes.view',
        },
        DEPRECIATION_ENTRIES: {
            CREATE: 'Fixed Assets.Depreciation Entries.create',
            DOWNLOAD: 'Fixed Assets.Depreciation Entries.download',
            LOCK: 'Fixed Assets.Depreciation Entries.lock',
            VIEW: 'Fixed Assets.Depreciation Entries.view',
            EDIT: 'Fixed Assets.Depreciation Entries.edit',
            DELETE: 'Fixed Assets.Depreciation Entries.delete',
        },
        DEPRECIATION_METHODS: {
            CREATE: 'Fixed Assets.Setup.Depreciation Methods.create',
            EDIT: 'Fixed Assets.Setup.Depreciation Methods.edit',
            DELETE: 'Fixed Assets.Setup.Depreciation Methods.delete',
            VIEW: 'Fixed Assets.Setup.Depreciation Methods.view',
        },
        DEPRECIATION_METHOD_STATUSES: {
            CREATE: 'Fixed Assets.Setup.Depreciation Method Statuses.create',
            EDIT: 'Fixed Assets.Setup.Depreciation Method Statuses.edit',
            DELETE: 'Fixed Assets.Setup.Depreciation Method Statuses.delete',
            VIEW: 'Fixed Assets.Setup.Depreciation Method Statuses.view',
        },
        DEPRECIATION_METHOD_TYPES: {
            CREATE: 'Fixed Assets.Setup.Depreciation Method Types.create',
            EDIT: 'Fixed Assets.Setup.Depreciation Method Types.edit',
            DELETE: 'Fixed Assets.Setup.Depreciation Method Types.delete',
            VIEW: 'Fixed Assets.Setup.Depreciation Method Types.view',
        },
        DEPRECIATION_SCHEDULES: {
            CREATE: 'Fixed Assets.Depreciation Schedules.create',
            EDIT: 'Fixed Assets.Depreciation Schedules.edit',
            DELETE: 'Fixed Assets.Depreciation Schedules.delete',
            VIEW: 'Fixed Assets.Depreciation Schedules.view',
            LOCK: 'Fixed Assets.Depreciation Schedules.lock',
        },
        DISPOSAL_ENTRY: {
            CREATE: 'Fixed Assets.Disposal Entry.create',
            DELETE: 'Fixed Assets.Disposal Entry.delete',
            LOCK: 'Fixed Assets.Disposal Entry.lock',
            DOWNLOAD: 'Fixed Assets.Disposal Entry.download',
            VIEW: 'Fixed Assets.Disposal Entry.view',
        },
        ASSET_STATUSES: {
            CREATE: 'Fixed Assets.Setup.Asset Statuses.create',
            EDIT: 'Fixed Assets.Setup.Asset Statuses.edit',
            DELETE: 'Fixed Assets.Setup.Asset Statuses.delete',
            VIEW: 'Fixed Assets.Setup.Asset Statuses.view',
        },
        ASSET_TYPES: {
            CREATE: 'Fixed Assets.Setup.Asset Types.create',
            EDIT: 'Fixed Assets.Setup.Asset Types.edit',
            DELETE: 'Fixed Assets.Setup.Asset Types.delete',
            VIEW: 'Fixed Assets.Setup.Asset Types.view',
        },
        CONTRACTS: {
            CREATE: 'Fixed Assets.Contracts.create',
            EDIT: 'Fixed Assets.Contracts.edit',
            DELETE: 'Fixed Assets.Contracts.delete',
            VIEW: 'Fixed Assets.Contracts.view',
            VIEW_CONTRACT_COVERAGE:
                'Fixed Assets.Contracts.viewContractCoverage',
        },
        CONTRACT_TYPES: {
            CREATE: 'Fixed Assets.Setup.Contract Types.create',
            EDIT: 'Fixed Assets.Setup.Contract Types.edit',
            DELETE: 'Fixed Assets.Setup.Contract Types.delete',
            VIEW: 'Fixed Assets.Setup.Contract Types.view',
        },
        CHART_OF_ACCOUNTS: {
            CREATE: 'Fixed Assets.Setup.Chart Of Accounts.create',
            EDIT: 'Fixed Assets.Setup.Chart Of Accounts.edit',
            DELETE: 'Fixed Assets.Setup.Chart Of Accounts.delete',
            VIEW: 'Fixed Assets.Setup.Chart Of Accounts.view',
        },
        FUNCTION_CODES: {
            EDIT: 'Fixed Assets.Setup.Function Codes.edit',
            VIEW: 'Fixed Assets.Setup.Function Codes.view',
        },
        REPORTS: {
            ACQUISITIONS: {
                VIEW: 'Fixed Assets.Reports.viewAcquisitions',
            },
            DEPRECIATION_SUMMARY: {
                VIEW: 'Fixed Assets.Reports.viewDepreciationSummary',
            },
            DISPOSALS: {
                VIEW: 'Fixed Assets.Reports.viewDisposals',
            },
            DEPRECIATION_DETAIL: {
                VIEW: 'Fixed Assets.Reports.viewDepreciationDetail',
            },
        },
    },
    ACCOUNTING: {
        CHART_OF_ACCOUNTS: {
            CREATE: 'Accounting.Setup.Chart Of Accounts.create',
            EDIT: 'Accounting.Setup.Chart Of Accounts.edit',
            DELETE: 'Accounting.Setup.Chart Of Accounts.delete',
            VIEW: 'Accounting.Setup.Chart Of Accounts.view',
        },
        FUNCTION_CODES: {
            EDIT: 'Accounting.Setup.Function Codes.edit',
            VIEW: 'Accounting.Setup.Function Codes.view',
        },
        CURRENCIES: {
            CREATE: 'Accounting.Setup.Currencies.create',
            EDIT: 'Accounting.Setup.Currencies.edit',
            DELETE: 'Accounting.Setup.Currencies.delete',
            VIEW: 'Accounting.Setup.Currencies.view',
        },
        CURRENCY_RATE_TYPES: {
            CREATE: 'Accounting.Setup.Exchange Rate Types.create',
            EDIT: 'Accounting.Setup.Exchange Rate Types.edit',
            VIEW: 'Accounting.Setup.Exchange Rate Types.view',
            DELETE: 'Accounting.Setup.Exchange Rate Types.delete',
        },
        CURRENCY_EXCHANGE_RATES: {
            CREATE: 'Accounting.Setup.Exchange Rates.create',
            VIEW: 'Accounting.Setup.Exchange Rates.view',
            EDIT: 'Accounting.Setup.Exchange Rates.edit',
            DELETE: 'Accounting.Setup.Exchange Rates.delete',
        },
        FISCAL_YEAR_STATUSES: {
            CREATE: 'Accounting.Setup.Fiscal Year Statuses.create',
            EDIT: 'Accounting.Setup.Fiscal Year Statuses.edit',
            VIEW: 'Accounting.Setup.Fiscal Year Statuses.view',
            DELETE: 'Accounting.Setup.Fiscal Year Statuses.delete',
        },
        FISCAL_YEARS: {
            CREATE: 'Accounting.Setup.Fiscal Years.create',
            EDIT: 'Accounting.Setup.Fiscal Years.edit',
            VIEW: 'Accounting.Setup.Fiscal Years.view',
            DELETE: 'Accounting.Setup.Fiscal Years.delete',
        },
        PERIOD_STATUSES: {
            CREATE: 'Accounting.Setup.Period Statuses.create',
            EDIT: 'Accounting.Setup.Period Statuses.edit',
            VIEW: 'Accounting.Setup.Period Statuses.view',
            DELETE: 'Accounting.Setup.Period Statuses.delete',
        },
        PERIOD_QUARTERS: {
            CREATE: 'Accounting.Setup.Period Quarters.create',
            EDIT: 'Accounting.Setup.Period Quarters.edit',
            DELETE: 'Accounting.Setup.Period Quarters.delete',
            VIEW: 'Accounting.Setup.Period Quarters.view',
        },
        PERIODS: {
            CREATE: 'Accounting.Setup.Periods.create',
            EDIT: 'Accounting.Setup.Periods.edit',
            DELETE: 'Accounting.Setup.Periods.delete',
            VIEW: 'Accounting.Setup.Periods.view',
        },
        JOURNAL_STATUSES: {
            CREATE: 'Accounting.Setup.Journal Statuses.create',
            EDIT: 'Accounting.Setup.Journal Statuses.edit',
            DELETE: 'Accounting.Setup.Journal Statuses.delete',
            VIEW: 'Accounting.Setup.Journal Statuses.view',
        },
        JOURNAL_TYPES: {
            CREATE: 'Accounting.Setup.Journal Types.create',
            EDIT: 'Accounting.Setup.Journal Types.edit',
            DELETE: 'Accounting.Setup.Journal Types.delete',
            VIEW: 'Accounting.Setup.Journal Types.view',
        },
        JOURNALS: {
            CREATE: 'Accounting.Journals.create',
            EDIT: 'Accounting.Journals.edit',
            VOID: 'Accounting.Journals.void',
            RECUR: 'Accounting.Journals.recur',
            VIEW: 'Accounting.Journals.view',
        },
        RECURRING_JOURNALS: {
            CREATE: 'Accounting.Recurring Journals.create',
            EDIT: 'Accounting.Recurring Journals.edit',
            DELETE: 'Accounting.Recurring Journals.delete',
            VIEW: 'Accounting.Recurring Journals.view',
        },
        SEQUENCES: {
            EDIT: 'Accounting.Setup.Sequences.edit',
            VIEW: 'Accounting.Setup.Sequences.view',
        },
        SUPPLIER_INVOICES: {
            CREATE: 'Accounting.Accounts Payable.Supplier Invoices.create',
            EDIT: 'Accounting.Accounts Payable.Supplier Invoices.edit',
            DELETE: 'Accounting.Accounts Payable.Supplier Invoices.delete',
            VIEW: 'Accounting.Accounts Payable.Supplier Invoices.view',
            VOID: 'Accounting.Accounts Payable.Supplier Invoices.void',
        },
        PAYMENTS: {
            CREATE: 'Accounting.Accounts Payable.Payments.create',
            EDIT: 'Accounting.Accounts Payable.Payments.edit',
            DELETE: 'Accounting.Accounts Payable.Payments.delete',
            VIEW: 'Accounting.Accounts Payable.Payments.view',
            ISSUE: 'Accounting.Accounts Payable.Payments.issuePayment',
            VOID: 'Accounting.Accounts Payable.Payments.void',
        },
        PAYMENT_TERMS: {
            CREATE: 'Accounting.Setup.Payment Terms.Payment Terms.create',
            EDIT: 'Accounting.Setup.Payment Terms.Payment Terms.edit',
            DELETE: 'Accounting.Setup.Payment Terms.Payment Terms.delete',
            VIEW: 'Accounting.Setup.Payment Terms.Payment Terms.view',
        },
        CUSTOMER_INVOICES: {
            CREATE: 'Accounting.Accounts Receivable.Customer Invoices.create',
            EDIT: 'Accounting.Accounts Receivable.Customer Invoices.edit',
            DELETE: 'Accounting.Accounts Receivable.Customer Invoices.delete',
            VIEW: 'Accounting.Accounts Receivable.Customer Invoices.view',
            VOID: 'Accounting.Accounts Receivable.Customer Invoices.void',
            CONSOLIDATE:
                'Accounting.Accounts Receivable.Customer Invoices.consolidate',
            UNDO_CONSOLIDATE:
                'Accounting.Accounts Receivable.Customer Invoices.undoConsolidate',
        },
        DEPOSITS: {
            CREATE: 'Accounting.Accounts Receivable.Deposits.create',
            EDIT: 'Accounting.Accounts Receivable.Deposits.edit',
            DELETE: 'Accounting.Accounts Receivable.Deposits.delete',
            VIEW: 'Accounting.Accounts Receivable.Deposits.view',
        },
        PAYMENT_TERM_STATUSES: {
            CREATE: 'Accounting.Setup.Payment Terms.Payment Term Statuses.create',
            EDIT: 'Accounting.Setup.Payment Terms.Payment Term Statuses.edit',
            DELETE: 'Accounting.Setup.Payment Terms.Payment Term Statuses.delete',
            VIEW: 'Accounting.Setup.Payment Terms.Payment Term Statuses.view',
        },
        PAYMENT_TERM_TYPES: {
            CREATE: 'Accounting.Setup.Payment Terms.Payment Term Types.create',
            EDIT: 'Accounting.Setup.Payment Terms.Payment Term Types.edit',
            DELETE: 'Accounting.Setup.Payment Terms.Payment Term Types.delete',
            VIEW: 'Accounting.Setup.Payment Terms.Payment Term Types.view',
        },
        DIMENSION_TYPES: {
            CREATE: 'Accounting.Setup.Dimensions.Dimension Types.create',
            EDIT: 'Accounting.Setup.Dimensions.Dimension Types.edit',
            DELETE: 'Accounting.Setup.Dimensions.Dimension Types.delete',
            VIEW: 'Accounting.Setup.Dimensions.Dimension Types.view',
        },
        DIMENSION_STATUSES: {
            CREATE: 'Accounting.Setup.Dimensions.Dimension Statuses.create',
            EDIT: 'Accounting.Setup.Dimensions.Dimension Statuses.edit',
            DELETE: 'Accounting.Setup.Dimensions.Dimension Statuses.delete',
            VIEW: 'Accounting.Setup.Dimensions.Dimension Statuses.view',
        },
        DIMENSIONS: {
            CREATE: 'Accounting.Setup.Dimensions.Dimensions.create',
            EDIT: 'Accounting.Setup.Dimensions.Dimensions.edit',
            DELETE: 'Accounting.Setup.Dimensions.Dimensions.delete',
            VIEW: 'Accounting.Setup.Dimensions.Dimensions.view',
        },
        SUPPLIER_INVOICE_TYPES: {
            CREATE: 'Accounting.Setup.Accounts Payable.Supplier Invoice Types.create',
            EDIT: 'Accounting.Setup.Accounts Payable.Supplier Invoice Types.edit',
            DELETE: 'Accounting.Setup.Accounts Payable.Supplier Invoice Types.delete',
            VIEW: 'Accounting.Setup.Accounts Payable.Supplier Invoice Types.view',
        },
        SUPPLIER_INVOICE_STATUSES: {
            CREATE: 'Accounting.Setup.Accounts Payable.Supplier Invoice Statuses.create',
            EDIT: 'Accounting.Setup.Accounts Payable.Supplier Invoice Statuses.edit',
            DELETE: 'Accounting.Setup.Accounts Payable.Supplier Invoice Statuses.delete',
            VIEW: 'Accounting.Setup.Accounts Payable.Supplier Invoice Statuses.view',
        },
        PAYMENT_TYPES: {
            CREATE: 'Accounting.Setup.Accounts Payable.Payment Types.create',
            EDIT: 'Accounting.Setup.Accounts Payable.Payment Types.edit',
            DELETE: 'Accounting.Setup.Accounts Payable.Payment Types.delete',
            VIEW: 'Accounting.Setup.Accounts Payable.Payment Types.view',
        },
        PAYMENT_STATUSES: {
            CREATE: 'Accounting.Setup.Accounts Payable.Payment Statuses.create',
            EDIT: 'Accounting.Setup.Accounts Payable.Payment Statuses.edit',
            DELETE: 'Accounting.Setup.Accounts Payable.Payment Statuses.delete',
            VIEW: 'Accounting.Setup.Accounts Payable.Payment Statuses.view',
        },
        PAYMENT_RUNS: {
            CREATE: 'Accounting.Accounts Payable.Payment Runs.create',
            EDIT: 'Accounting.Accounts Payable.Payment Runs.edit',
            DELETE: 'Accounting.Accounts Payable.Payment Runs.delete',
            VIEW: 'Accounting.Accounts Payable.Payment Runs.view',
            REISSUE: 'Accounting.Accounts Payable.Payment Runs.reissue',
        },
        PAYMENT_RUN_TYPES: {
            CREATE: 'Accounting.Setup.Accounts Payable.Payment Run Types.create',
            EDIT: 'Accounting.Setup.Accounts Payable.Payment Run Types.edit',
            DELETE: 'Accounting.Setup.Accounts Payable.Payment Run Types.delete',
            VIEW: 'Accounting.Setup.Accounts Payable.Payment Run Types.view',
        },
        PAYMENT_RUN_STATUSES: {
            CREATE: 'Accounting.Setup.Accounts Payable.Payment Run Statuses.create',
            EDIT: 'Accounting.Setup.Accounts Payable.Payment Run Statuses.edit',
            DELETE: 'Accounting.Setup.Accounts Payable.Payment Run Statuses.delete',
            VIEW: 'Accounting.Setup.Accounts Payable.Payment Run Statuses.view',
        },
        BANKS: {
            CREATE: 'Accounting.Setup.Banks.Banks.create',
            EDIT: 'Accounting.Setup.Banks.Banks.edit',
            DELETE: 'Accounting.Setup.Banks.Banks.delete',
            VIEW: 'Accounting.Setup.Banks.Banks.view',
        },
        BANK_ACCOUNT_TYPES: {
            CREATE: 'Accounting.Setup.Banks.Bank Account Types.create',
            EDIT: 'Accounting.Setup.Banks.Bank Account Types.edit',
            DELETE: 'Accounting.Setup.Banks.Bank Account Types.delete',
            VIEW: 'Accounting.Setup.Banks.Bank Account Types.view',
        },
        BANK_ACCOUNT_STATUSES: {
            CREATE: 'Accounting.Setup.Banks.Bank Account Statuses.create',
            EDIT: 'Accounting.Setup.Banks.Bank Account Statuses.edit',
            DELETE: 'Accounting.Setup.Banks.Bank Account Statuses.delete',
            VIEW: 'Accounting.Setup.Banks.Bank Account Statuses.view',
        },
        CUSTOMER_INVOICE_TYPES: {
            CREATE: 'Accounting.Setup.Accounts Receivable.Customer Invoice Types.create',
            EDIT: 'Accounting.Setup.Accounts Receivable.Customer Invoice Types.edit',
            DELETE: 'Accounting.Setup.Accounts Receivable.Customer Invoice Types.delete',
            VIEW: 'Accounting.Setup.Accounts Receivable.Customer Invoice Types.view',
        },
        CUSTOMER_INVOICE_STATUSES: {
            CREATE: 'Accounting.Setup.Accounts Receivable.Customer Invoice Statuses.create',
            EDIT: 'Accounting.Setup.Accounts Receivable.Customer Invoice Statuses.edit',
            DELETE: 'Accounting.Setup.Accounts Receivable.Customer Invoice Statuses.delete',
            VIEW: 'Accounting.Setup.Accounts Receivable.Customer Invoice Statuses.view',
        },
        DEPOSIT_TYPES: {
            CREATE: 'Accounting.Setup.Accounts Receivable.Deposit Types.create',
            EDIT: 'Accounting.Setup.Accounts Receivable.Deposit Types.edit',
            DELETE: 'Accounting.Setup.Accounts Receivable.Deposit Types.delete',
            VIEW: 'Accounting.Setup.Accounts Receivable.Deposit Types.view',
        },
        DEPOSIT_STATUSES: {
            CREATE: 'Accounting.Setup.Accounts Receivable.Deposit Statuses.create',
            EDIT: 'Accounting.Setup.Accounts Receivable.Deposit Statuses.edit',
            DELETE: 'Accounting.Setup.Accounts Receivable.Deposit Statuses.delete',
            VIEW: 'Accounting.Setup.Accounts Receivable.Deposit Statuses.view',
        },
        REPORTS: {
            CUSTOMER_STATEMENTS: {
                VIEW: 'Accounting.Reports.Customer Statements.view',
            },
        },
    },
    FORMULATION: {
        REGULATIONS: {
            CREATE: 'Formulation.Setup.Regulatory.Regulations.create',
            EDIT: 'Formulation.Setup.Regulatory.Regulations.edit',
            DELETE: 'Formulation.Setup.Regulatory.Regulations.delete',
            VIEW: 'Formulation.Setup.Regulatory.Regulations.view',
            DELETE_ALL: 'Formulation.Setup.Regulatory.Regulations.deleteAll',
        },
        FORMULA_PROPERTIES: {
            CREATE: 'Formulation.Setup.Formulation.Formula Properties.create',
            EDIT: 'Formulation.Setup.Formulation.Formula Properties.edit',
            DELETE: 'Formulation.Setup.Formulation.Formula Properties.delete',
            VIEW: 'Formulation.Setup.Formulation.Formula Properties.view',
        },
        EXPOSURE_AGENCIES: {
            CREATE: 'Formulation.Setup.Regulatory.Exposure Agencies.create',
            EDIT: 'Formulation.Setup.Regulatory.Exposure Agencies.edit',
            DELETE: 'Formulation.Setup.Regulatory.Exposure Agencies.delete',
            VIEW: 'Formulation.Setup.Regulatory.Exposure Agencies.view',
        },
        GHS_HAZARD_PHRASE: {
            CREATE: 'Formulation.Setup.Regulatory.GHS Hazard Phrases.create',
            EDIT: 'Formulation.Setup.Regulatory.GHS Hazard Phrases.edit',
            DELETE: 'Formulation.Setup.Regulatory.GHS Hazard Phrases.delete',
            VIEW: 'Formulation.Setup.Regulatory.GHS Hazard Phrases.view',
        },
        GHS_PRECAUTIONARY_PHRASE: {
            CREATE: 'Formulation.Setup.Regulatory.GHS Precautionary Phrases.create',
            EDIT: 'Formulation.Setup.Regulatory.GHS Precautionary Phrases.edit',
            DELETE: 'Formulation.Setup.Regulatory.GHS Precautionary Phrases.delete',
            VIEW: 'Formulation.Setup.Regulatory.GHS Precautionary Phrases.view',
        },
        CAS_MASTER: {
            CREATE: 'Formulation.CAS Master.create',
            EDIT: 'Formulation.CAS Master.edit',
            DELETE: 'Formulation.CAS Master.delete',
            VIEW: 'Formulation.CAS Master.view',
        },
        ITEM_MASTER: {
            CREATE: 'Formulation.Item Master.create',
            EDIT: 'Formulation.Item Master.edit',
            DELETE: 'Formulation.Item Master.delete',
            VIEW: 'Formulation.Item Master.view',
        },
        LOCALE_GROUPS: {
            CREATE: 'Formulation.Setup.Regulatory.Locale Groups.create',
            EDIT: 'Formulation.Setup.Regulatory.Locale Groups.edit',
            DELETE: 'Formulation.Setup.Regulatory.Locale Groups.delete',
            VIEW: 'Formulation.Setup.Regulatory.Locale Groups.view',
        },
        TOXIC_SPECIES: {
            CREATE: 'Formulation.Setup.Regulatory.Toxic Species.create',
            EDIT: 'Formulation.Setup.Regulatory.Toxic Species.edit',
            DELETE: 'Formulation.Setup.Regulatory.Toxic Species.delete',
            VIEW: 'Formulation.Setup.Regulatory.Toxic Species.view',
        },
        TOXIC_TEST: {
            CREATE: 'Formulation.Setup.Regulatory.Toxic Test.create',
            EDIT: 'Formulation.Setup.Regulatory.Toxic Test.edit',
            DELETE: 'Formulation.Setup.Regulatory.Toxic Test.delete',
            VIEW: 'Formulation.Setup.Regulatory.Toxic Test.view',
        },
        SAFETY_DATA_SHEET_SECTION_HEADER: {
            CREATE: 'Formulation.Setup.Regulatory.SDS Section Header.create',
            EDIT: 'Formulation.Setup.Regulatory.SDS Section Header.edit',
            DELETE: 'Formulation.Setup.Regulatory.SDS Section Header.delete',
            VIEW: 'Formulation.Setup.Regulatory.SDS Section Header.view',
        },
        LOCALE: {
            EDIT: 'Formulation.Setup.Regulatory.Locales.edit',
            VIEW: 'Formulation.Setup.Regulatory.Locales.view',
        },
        SDS_HEADER: {
            CREATE: 'Formulation.Safety Data Sheet.create',
            EDIT: 'Formulation.Safety Data Sheet.edit',
            DELETE: 'Formulation.Safety Data Sheet.delete',
            VIEW: 'Formulation.Safety Data Sheet.view',
        },
        RD_FORMULA: {
            CREATE: 'Formulation.RD Formula.create',
            EDIT: 'Formulation.RD Formula.edit',
            DELETE: 'Formulation.RD Formula.delete',
            VIEW: 'Formulation.RD Formula.view',
        },
        HAZARD_CLASS_MAPPING: {
            EDIT: 'Formulation.Setup.Regulatory.Hazard Class Mapping.edit',
            VIEW: 'Formulation.Setup.Regulatory.Hazard Class Mapping.view',
        },
        HAZARD_CLASS_RULES: {
            CREATE: 'Formulation.Setup.Regulatory.Hazard Class Rule.create',
            EDIT: 'Formulation.Setup.Regulatory.Hazard Class Rule.edit',
            DELETE: 'Formulation.Setup.Regulatory.Hazard Class Rule.delete',
            VIEW: 'Formulation.Setup.Regulatory.Hazard Class Rule.view',
        },
        HAZARD_RATINGS: {
            CREATE: 'Formulation.Setup.Regulatory.Hazard Ratings.create',
            EDIT: 'Formulation.Setup.Regulatory.Hazard Ratings.edit',
            DELETE: 'Formulation.Setup.Regulatory.Hazard Ratings.delete',
            VIEW: 'Formulation.Setup.Regulatory.Hazard Ratings.view',
        },
        HAZARD_CLASSIFICATION: {
            EDIT: 'Formulation.Setup.Regulatory.Hazard Classification.edit',
            VIEW: 'Formulation.Setup.Regulatory.Hazard Classification.view',
        },
        SDS_OPTIONAL_PHRASE_LIBRARY: {
            CREATE: 'Formulation.Setup.Regulatory.SDS Optional Phrase Library.create',
            EDIT: 'Formulation.Setup.Regulatory.SDS Optional Phrase Library.edit',
            DELETE: 'Formulation.Setup.Regulatory.SDS Optional Phrase Library.delete',
            VIEW: 'Formulation.Setup.Regulatory.SDS Optional Phrase Library.view',
        },
        FORMULA_TEMPLATE: {
            CREATE: 'Formulation.Setup.Formulation.Formula Templates.create',
            EDIT: 'Formulation.Setup.Formulation.Formula Templates.edit',
            DELETE: 'Formulation.Setup.Formulation.Formula Templates.delete',
            VIEW: 'Formulation.Setup.Formulation.Formula Templates.view',
        },
        EQUATION_BUILDER: {
            CREATE: 'Formulation.Setup.General.Equation Builder.create',
            EDIT: 'Formulation.Setup.General.Equation Builder.edit',
            DELETE: 'Formulation.Setup.General.Equation Builder.delete',
            VIEW: 'Formulation.Setup.General.Equation Builder.view',
        },
        SEQUENCES: {
            EDIT: 'Formulation.Setup.General.Sequences.edit',
            VIEW: 'Formulation.Setup.General.Sequences.view',
        },
    },
    SETUP: {
        UNITS_OF_MEASURE: {
            CREATE: 'Setup.General.Units of Measure.create',
            EDIT: 'Setup.General.Units of Measure.edit',
            DELETE: 'Setup.General.Units of Measure.delete',
            VIEW: 'Setup.General.Units of Measure.view',
        },
        UNIT_OF_MEASURE_CONVERSION: {
            CREATE: 'Setup.General.Unit Of Measure Conversion.create',
            EDIT: 'Setup.General.Unit Of Measure Conversion.edit',
            DELETE: 'Setup.General.Unit Of Measure Conversion.delete',
            VIEW: 'Setup.General.Unit Of Measure Conversion.view',
        },
        PROPER_SHIPPING_NAME: {
            CREATE: 'Setup.General.Proper Shipping Name.create',
            EDIT: 'Setup.General.Proper Shipping Name.edit',
            DELETE: 'Setup.General.Proper Shipping Name.delete',
            VIEW: 'Setup.General.Proper Shipping Name.view',
        },
        BOILER_PLATE_NOTE: {
            CREATE: 'Setup.General.Boiler Plate Notes.create',
            EDIT: 'Setup.General.Boiler Plate Notes.edit',
            DELETE: 'Setup.General.Boiler Plate Notes.delete',
            VIEW: 'Setup.General.Boiler Plate Notes.view',
        },
        ATTRIBUTES: {
            MANAGE: 'Setup.General.Attributes.manage',
            VIEW: 'Setup.General.Attributes.view',
        },
        DOCUMENTS: {
            MANAGE: 'Setup.General.Documents.manage',
            VIEW: 'Setup.General.Documents.view',
        },
    },
    CUSTOMERS: {
        CUSTOMERS: {
            CREATE: 'Customers.Customers.create',
            EDIT: 'Customers.Customers.edit',
            DELETE: 'Customers.Customers.delete',
            VIEW: 'Customers.Customers.view',
        },
        SETUP: {
            CUSTOMER_STATUSES: {
                CREATE: 'Customers.Setup.Customer Statuses.create',
                EDIT: 'Customers.Setup.Customer Statuses.edit',
                DELETE: 'Customers.Setup.Customer Statuses.delete',
                VIEW: 'Customers.Setup.Customer Statuses.view',
            },
            CUSTOMER_TYPES: {
                CREATE: 'Customers.Setup.Customer Types.create',
                EDIT: 'Customers.Setup.Customer Types.edit',
                DELETE: 'Customers.Setup.Customer Types.delete',
                VIEW: 'Customers.Setup.Customer Types.view',
            },
            CUSTOMER_ADDRESS_STATUSES: {
                CREATE: 'Customers.Setup.Customer Address Statuses.create',
                EDIT: 'Customers.Setup.Customer Address Statuses.edit',
                DELETE: 'Customers.Setup.Customer Address Statuses.delete',
                VIEW: 'Customers.Setup.Customer Address Statuses.view',
            },
            CUSTOMER_ADDRESS_TYPES: {
                CREATE: 'Customers.Setup.Customer Address Types.create',
                EDIT: 'Customers.Setup.Customer Address Types.edit',
                DELETE: 'Customers.Setup.Customer Address Types.delete',
                VIEW: 'Customers.Setup.Customer Address Types.view',
            },
        },
    },
    SUPPLIERS: {
        SUPPLIER: {
            CREATE: 'Suppliers.Supplier.create',
            EDIT: 'Suppliers.Supplier.edit',
            DELETE: 'Suppliers.Supplier.delete',
            VIEW: 'Suppliers.Supplier.view',
        },
        SETUP: {
            SUPPLIER_TYPES: {
                CREATE: 'Suppliers.Setup.Supplier Types.create',
                EDIT: 'Suppliers.Setup.Supplier Types.edit',
                DELETE: 'Suppliers.Setup.Supplier Types.delete',
                VIEW: 'Suppliers.Setup.Supplier Types.view',
            },
            SUPPLIER_STATUSES: {
                CREATE: 'Suppliers.Setup.Supplier Statuses.create',
                EDIT: 'Suppliers.Setup.Supplier Statuses.edit',
                DELETE: 'Suppliers.Setup.Supplier Statuses.delete',
                VIEW: 'Suppliers.Setup.Supplier Statuses.view',
            },
            SUPPLIER_ADDRESS_STATUSES: {
                CREATE: 'Suppliers.Setup.Supplier Address Statuses.create',
                EDIT: 'Suppliers.Setup.Supplier Address Statuses.edit',
                DELETE: 'Suppliers.Setup.Supplier Address Statuses.delete',
                VIEW: 'Suppliers.Setup.Supplier Address Statuses.view',
            },
            SUPPLIER_ADDRESS_TYPES: {
                CREATE: 'Suppliers.Setup.Supplier Address Types.create',
                EDIT: 'Suppliers.Setup.Supplier Address Types.edit',
                DELETE: 'Suppliers.Setup.Supplier Address Types.delete',
                VIEW: 'Suppliers.Setup.Supplier Address Types.view',
            },
            SUPPLIER_BANK_ACCOUNT: {
                CREATE: 'Suppliers.Setup.Supplier Bank Accounts.create',
                EDIT: 'Suppliers.Setup.Supplier Bank Accounts.edit',
                DELETE: 'Suppliers.Setup.Supplier Bank Accounts.delete',
                VIEW: 'Suppliers.Setup.Supplier Bank Accounts.view',
            },
        },
    },
    DOCUMENT_DESIGNER: {
        DOC_DEFINITION: {
            CREATE: 'Document Designer.DocDefinition.create',
            EDIT: 'Document Designer.DocDefinition.edit',
            DELETE: 'Document Designer.DocDefinition.delete',
            VIEW: 'Document Designer.DocDefinition.view',
        },
        DOC_TEMPLATE: {
            CREATE: 'Document Designer.DocTemplate.create',
            EDIT: 'Document Designer.DocTemplate.edit',
            DELETE: 'Document Designer.DocTemplate.delete',
            VIEW: 'Document Designer.DocTemplate.view',
        },
    },
    ATTACHMENTS: {
        ATTACHMENT: {
            CREATE: 'Attachments.Attachment.create',
            EDIT: 'Attachments.Attachment.edit',
            DELETE: 'Attachments.Attachment.delete',
            VIEW: 'Attachments.Attachment.view',
        },
        ATTACHMENT_FOLDER: {
            CREATE: 'Attachments.AttachmentFolder.create',
            EDIT: 'Attachments.AttachmentFolder.edit',
            DELETE: 'Attachments.AttachmentFolder.delete',
            VIEW: 'Attachments.AttachmentFolder.view',
        },
        ATTACHMENT_CONTROL: {
            CREATE: 'Attachments.AttachmentControl.create',
            EDIT: 'Attachments.AttachmentControl.edit',
            DELETE: 'Attachments.AttachmentControl.delete',
            VIEW: 'Attachments.AttachmentControl.view',
        },
        ATTACHMENT_PERMISSION: {
            CREATE: 'Attachments.AttachmentPermission.create',
            EDIT: 'Attachments.AttachmentPermission.edit',
            DELETE: 'Attachments.AttachmentPermission.delete',
            VIEW: 'Attachments.AttachmentPermission.view',
        },
        ATTACHMENT_FILE_VERSION: {
            CREATE: 'Attachments.AttachmentFileVersion.create',
            EDIT: 'Attachments.AttachmentFileVersion.edit',
            DELETE: 'Attachments.AttachmentFileVersion.delete',
            VIEW: 'Attachments.AttachmentFileVersion.view',
        },
    },
    JOBS: {
        JOB_RUNS: {
            CREATE: 'Jobs.JobRun.create',
            EDIT: 'Jobs.JobRun.edit',
            DELETE: 'Jobs.JobRun.delete',
            VIEW: 'Jobs.JobRun.view',
        },
        JOB_DETAIL_LOGS: {
            CREATE: 'Jobs.JobDetailLog.create',
            EDIT: 'Jobs.JobDetailLog.edit',
            DELETE: 'Jobs.JobDetailLog.delete',
            VIEW: 'Jobs.JobDetailLog.view',
        },
        JOB_DEFINITION_CONFIGURATION: {
            CREATE: 'Jobs.JobDefinitionConfiguration.create',
            EDIT: 'Jobs.JobDefinitionConfiguration.edit',
            DELETE: 'Jobs.JobDefinitionConfiguration.delete',
            VIEW: 'Jobs.JobDefinitionConfiguration.view',
        },
    },
    TASKS: {
        VIEW: 'Tasks.Task.view',
        CREATE: 'Tasks.Task.create',
        EDIT: 'Tasks.Task.edit',
        DELETE: 'Tasks.Task.delete',
        ASSIGN: 'Tasks.Task.assign',
    },
    NOTIFICATIONS: {
        BUSINESS_ENTITY_SUBSCRIPTIONS: {
            VIEW: 'Notifications.Business Entity Subscriptions.view',
            EDIT: 'Notifications.Business Entity Subscriptions.edit',
        },
        BUSINESS_ENTITY_CONFIG: {
            VIEW: 'Notifications.Business Entity Configurations.view',
            EDIT: 'Notifications.Business Entity Configurations.edit',
        },
        MESSAGE_TEMPLATES: {
            VIEW: 'Notifications.Message Templates.view',
            EDIT: 'Notifications.Message Templates.edit',
            CREATE: 'Notifications.Message Templates.create',
            DELETE: 'Notifications.Message Templates.delete',
        },
    },
};
