import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../config';
import { Auth } from 'aws-amplify';
import { accountingAppApiTagTypes } from './tagTypes';

// initialize an empty api service that we'll inject endpoints into later as needed
export const accountingAppApi = createApi({
    reducerPath: 'accountingAppApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.accountingGateway.URL,
        prepareHeaders: async (headers, { getState }) => {
            const token = (await Auth.currentSession())
                .getIdToken()
                .getJwtToken();
            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('Authorization', token);
            }

            headers.set('Accept-Encoding', 'gzip');

            return headers;
        },
    }),
    tagTypes: accountingAppApiTagTypes,
    endpoints: () => ({}),
});

export const accountingPublicAppApi = createApi({
    reducerPath: 'accountingPublicAppApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.accountingGateway.URL,
    }),
    tagTypes: accountingAppApiTagTypes,
    endpoints: () => ({}),
});
