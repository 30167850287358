import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import _ from 'lodash';

const MultiselectDropdown = (props: any) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [value, setValue] = useState(null);

    const customStyles = {
        menu: (provided: any) => ({
            ...provided,
            zIndex: '99999',
            menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        }),
    };

    useEffect(() => {
        setSelectedOption([]);
        if (!isNilOrEmpty(props.options) && props.value != null) {
            const options = _.isArray(props.value)
                ? props.options.filter((option: any) =>
                      props.value.includes(option.value)
                  )
                : props.options.find(
                      (option: any) => option.value === props.value
                  );

            if (options) {
                setSelectedOption(options);
            } else {
                setSelectedOption(null);
            }
        }
    }, [props.value, props.options]);

    useEffect(() => {
        if (props.defaultValue && !isNilOrEmpty(props.options)) {
            const option = props.options.find(
                (option: any) => option.value === props.defaultValue
            );
            setSelectedOption(option);
            setValue(option.value);
            props.onChange(value);
        }
    }, [props.defaultValue]);

    const optionSelected = (selectedOption: any) => {
        if (props.isMulti) {
            setSelectedOption(selectedOption);
            setValue(selectedOption.map((option: any) => option.value));
            props.onChange(selectedOption.map((option: any) => option.value));
        } else {
            setSelectedOption(selectedOption);
            setValue(selectedOption.value);
            props.onChange(selectedOption.value);
        }
    };

    const onMenuClose = () => {
        if (props.onMenuClose) {
            props.onMenuClose();
        }
    };

    return (
        <Select
            className={'basic-select ' + props.className || ''}
            menuPlacement={props.menuPlacement || 'bottom'}
            menuPosition={props.menuPosition || ''}
            value={selectedOption}
            id={props.id}
            name={props.name}
            styles={customStyles}
            isDisabled={props.isDisabled}
            classNamePrefix={props.classNamePrefix}
            closeMenuOnSelect={props.closeMenuOnSelect}
            isMulti={props.isMulti}
            getOptionLabel={props.getOptionLabel}
            getOptionValue={props.getOptionValue}
            defaultValue={props.defaultValue}
            onMenuClose={() => onMenuClose()}
            options={props.options}
            maxMenuHeight={props.maxMenuHeight}
            onChange={(selectedOption) => optionSelected(selectedOption)}
            placeholder={props.placeholder}
            menuPortalTarget={props.menuPortalTarget || ''}
        />
    );
};

export default MultiselectDropdown;
