import React, { FocusEventHandler, useEffect, useState } from 'react';
import { TextField, TextFieldProps, Tooltip } from '@mui/material';
import { FormMethods } from '../../../../libs/hooksLib';
import { RequiredValidator } from '../../../../validators/required.validator';
import { isNil } from '../../../../utils/objectUtils';
import _ from 'lodash';

export type InputBaseProps = {
    formMethods?: FormMethods;
    stateful?: boolean;
    termSetKey?: string;
} & TextFieldProps;

const InputBase = ({
    formMethods,
    onBlur,
    termSetKey,
    ...props
}: InputBaseProps) => {
    const [value, setValue] = useState(props.value || '');

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const onChange = (e: any) => {
        if (props.stateful) {
            setValue(e.target.value);
        } else {
            if (props.onChange) {
                props.onChange(e);
            }
        }
    };

    useEffect(() => {
        //The idea here is that if the label is translatable
        //but we don't have the termSet loaded yet
        //we don't want to set validations
        //@ts-ignore
        if (props?.label?.props && !props?.label?.props.termSet) {
            return;
        }
        if (props.required && props.label) {
            formMethods?.addValidator(
                props.id,
                _.isObject(props.label) ? (
                    <>{props.label}</>
                ) : (
                    props.label?.toString()
                ),
                RequiredValidator
            );
        } else {
            formMethods?.removeValidator(props.id, RequiredValidator);
        }
        //@ts-ignore
    }, [props.required, props?.label?.props?.termSet]);

    const getHelperTextElements = (validationErrors: any[]) => {
        if (!validationErrors) {
            return;
        }

        return validationErrors.reduce((result, item) => (
            <>
                {result}
                <br></br>
                {item}
            </>
        ));
    };

    const handlerOnBlur = (onBlur: FocusEventHandler, event: any) => {
        if (props.stateful) {
            event.target.value = event.target.value.trim();
            if (props.onChange) {
                props.onChange(event);
            }

            if (onBlur) {
                onBlur(event);
            }
        }
    };

    const getTooltipText = () => {
        //@ts-ignore
        const labelTermKey = props.label?.props?.termKey;
        //@ts-ignore
        const labelTermSet = props.label?.props?.termSet;

        if (labelTermKey) {
            return labelTermSet?.languageTermSetDef?.termDefs?.find(
                (term: any) => term.code === labelTermKey + '-helper'
            )?.defaultValue;
        }
        return null;
    };

    return (
        <Tooltip title={getTooltipText()} arrow>
            <TextField
                {...props}
                onBlur={(event) => handlerOnBlur(onBlur, event)}
                value={
                    !props.stateful ? props.value : _.isNil(value) ? '' : value
                }
                onChange={onChange}
                error={
                    formMethods?.isFormSubmitted &&
                    !isNil(formMethods?.fieldValidationErrors?.get(props.id))
                }
                helperText={
                    formMethods?.isFormSubmitted ? (
                        <>
                            {getHelperTextElements(
                                formMethods?.fieldValidationErrors?.get(
                                    props.id
                                )
                            )}
                        </>
                    ) : null
                }
            />
        </Tooltip>
    );
};

export default InputBase;
