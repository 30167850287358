import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import LoaderButton from '../../LoaderButton';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import HistoryIcon from '@mui/icons-material/History';
import LockIcon from '@mui/icons-material/Lock';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import RevisionHistoryDrawer from '../../administration/revisionHistory/RevisionHistoryDrawer';
import TranslatableText from '../../i18n/TranslatableText';
import { SharedComponentsDefs } from '../../../constants/i18n/translations/termDefinitions/platform';
import BaseFormConfirmModal from './BaseFormConfirmModal';
import PrintIcon from '@mui/icons-material/Print';
import PrintDocumentPopover from '../../documentDesigner/PrintDocumentPopover';
import FolderIcon from '@mui/icons-material/Folder';
import AttachmentsDrawer from '../../administration/attachments/AttachmentsDrawer';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import JobsDrawer from '../../administration/jobs/JobsDrawer';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import JobStartedModal from '../../modals/JobStartedModal';
import JobItemModal from '../../administration/jobs/JobItemModal';
import {
    useGetAttachmentQuery,
    useCheckinAttachmentMutation,
    useCheckoutAttachmentMutation,
} from '../../../services/attachments/attachments.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import InventoryIcon from '@mui/icons-material/Inventory';
import AccessLogsModal from '../../administration/storage/AccessLogsModal';
import TaskButtonGroup from '../../administration/task-manager/TaskButtonGroup';
import GlobalEntityDataContext from '../../../contexts/globalEntityData.context';
import Divider from '@mui/material/Divider';

enum ButtonClickOption {
    None = 0,
    Add = 1,
    Update = 2,
    Delete = 3,
    Revision = 4,
    Lock = 5,
    Copy = 6,
    DocumentGenerate = 7,
    DocumentPreview = 8,
    Attachment = 9,
    Jobs = 10,
    AttachmentCheckInOrOut = 11,
    AccessLogs = 12,
}

export interface BaseFormToolbarProps {
    isNew?: boolean;
    onCreate?: () => void;
    onUpdate?: () => void;
    onDelete?: () => void;
    onCancel?: () => void;
    onLock?: () => void;
    onRevision?: () => void;
    onCopy?: () => void;
    showOnRevision?: boolean;
    fieldMappings?: any;
    entityUuid?: any;
    displayCreateButton?: boolean;
    displayUpdateButton?: boolean;
    displayDeleteButton?: boolean;
    displayLockButton?: boolean;
    displayCopyButton?: boolean;
    displayDocumentButtons?: boolean;
    displayAttachmentButton?: boolean;
    displayAttachmentCheckInOrOutButton?: boolean;
    displayAccessLogsButton?: boolean;
    displayJobsButton?: boolean;
    isLocked?: boolean;
    customToolbarButtons?: React.ReactNode;
    hasEntityChanges?: () => boolean;
    childForms?: any[];
    termSet?: any;
    setShowConfirm?: (_: boolean) => void;
    entityType?: string;
    documentQuery?: any;
    hideDeleteButton?: boolean;
    hideCancelButton?: boolean;
    documentTemplateQuery?: () => any;
    documentTemplateQueryOptions?: any;
}

const BaseFormToolbar = (props: BaseFormToolbarProps) => {
    let { id } = useParams();
    const [buttonClickOption, setButtonClickOption] =
        useState<ButtonClickOption>(ButtonClickOption.None);
    const user = useSelector((state: RootState) => state.user);
    const [open, setOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showAttachments, setShowAttachments] = useState(false);
    const [showJobs, setShowJobs] = useState(false);
    const [showGenerate, setShowGenerate] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const [showAccessLogs, setShowAccessLogs] = useState(false);
    const isMounted = useRef(false);
    const { entityData } = useContext(GlobalEntityDataContext);

    const { data: attachment } = useGetAttachmentQuery(
        props?.displayAttachmentCheckInOrOutButton ? id?.toString() : skipToken
    );
    const [checkoutAttachment] = useCheckoutAttachmentMutation();
    const [checkinAttachment] = useCheckinAttachmentMutation();

    const handleCancelButtonClick = () => {
        if (props.setShowConfirm) {
            props.setShowConfirm(false);
        }

        if (props.hasEntityChanges && props?.hasEntityChanges()) {
            // open modal
            setShowModal(true);
        } else {
            props.onCancel();
        }
    };
    const handleCreateButtonClick = async () => {
        setButtonClickOption(ButtonClickOption.Add);
        await props.onCreate();
        if (isMounted.current) {
            setButtonClickOption(ButtonClickOption.None);
        }
    };

    const handleUpdateButtonClick = async () => {
        setButtonClickOption(ButtonClickOption.Update);
        await props.onUpdate();
        if (isMounted.current) {
            setButtonClickOption(ButtonClickOption.None);
        }
    };

    const handleDeleteButtonClick = () => {
        setButtonClickOption(ButtonClickOption.Delete);
        props.onDelete();
        setButtonClickOption(ButtonClickOption.None);
    };

    const handleRevisionButtonClick = () => {
        setButtonClickOption(ButtonClickOption.Revision);
        setShowAttachments(false);
        setOpen((prev) => !prev);
        setButtonClickOption(ButtonClickOption.None);
    };

    const handleLockButtonClick = () => {
        setButtonClickOption(ButtonClickOption.Lock);
        props.onLock();
        setButtonClickOption(ButtonClickOption.None);
    };

    const handleCopyButtonClick = () => {
        setButtonClickOption(ButtonClickOption.Copy);
        props.onCopy();
        setButtonClickOption(ButtonClickOption.None);
    };

    const handleDocumentGenerateButtonClick = (e: any) => {
        setButtonClickOption(ButtonClickOption.DocumentGenerate);
        setShowGenerate((prev: any) => !prev);
        setAnchorEl(e.currentTarget);
        setButtonClickOption(ButtonClickOption.None);
    };

    const handleAttachmentButtonClick = () => {
        setButtonClickOption(ButtonClickOption.Attachment);
        setOpen(false);
        setShowAttachments((prev: any) => !prev);
        setButtonClickOption(ButtonClickOption.None);
    };

    const handleAttachmentCheckInOrOutButtonClick = () => {
        setButtonClickOption(ButtonClickOption.AttachmentCheckInOrOut);
        if (attachment?.attachmentControl?.locked) {
            checkinAttachment(attachment?.id?.toString());
        } else {
            checkoutAttachment(attachment?.id?.toString());
        }
        setButtonClickOption(ButtonClickOption.None);
    };

    const handleJobButtonClick = () => {
        setButtonClickOption(ButtonClickOption.Jobs);
        setOpen(false);
        setShowJobs((prev: any) => !prev);
        setButtonClickOption(ButtonClickOption.None);
    };

    const handleDisableUpdateButtonAction = (): boolean => {
        let disable =
            buttonClickOption !== ButtonClickOption.None &&
            buttonClickOption !== ButtonClickOption.Update;

        if (!disable && props.hasEntityChanges) {
            disable = !props.hasEntityChanges();
        }

        disable = disable || !props.displayUpdateButton;

        return disable;
    };

    const handleDisableCopyButtonAction = (): boolean => {
        let disable =
            buttonClickOption !== ButtonClickOption.None &&
            buttonClickOption !== ButtonClickOption.Copy;

        if (!disable && props.hasEntityChanges) {
            disable = props.hasEntityChanges();
        }

        disable = disable || !props.displayCopyButton;

        return disable;
    };

    const handleAccessLogsButtonClick = () => {
        setButtonClickOption(ButtonClickOption.AccessLogs);
        setOpen(false);
        setShowAccessLogs((prev: any) => !prev);
        setButtonClickOption(ButtonClickOption.None);
    };

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <>
            <div className="form-action-ribbon">
                <BaseFormConfirmModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    onCancel={props.onCancel}
                    setShowConfirm={props.setShowConfirm}
                />
                <div className={'form-header-body'}>
                    {!props.hideCancelButton && (
                        <Button
                            className="form-header-button"
                            variant="text"
                            data-testid="cancelButton"
                            onClick={handleCancelButtonClick}
                            disabled={
                                buttonClickOption !== ButtonClickOption.None
                            }>
                            <CancelOutlinedIcon color={'error'} />
                            <div className="form-header-button-text">
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SharedComponentsDefs.Cancel}
                                />
                            </div>
                        </Button>
                    )}
                    {props.isNew ? (
                        <LoaderButton
                            className="form-header-button"
                            type="submit"
                            variant="text"
                            data-testid="saveCreateButton"
                            onClick={handleCreateButtonClick}
                            termKey={SharedComponentsDefs.Add}
                            isLoading={
                                buttonClickOption === ButtonClickOption.Add
                            }
                            disabled={
                                (buttonClickOption !== ButtonClickOption.None &&
                                    buttonClickOption !==
                                        ButtonClickOption.Add) ||
                                !props.displayCreateButton
                            }>
                            <SaveIcon style={{ color: '#00A84E' }} />
                            <div className="form-header-button-text">
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SharedComponentsDefs.Add}
                                />
                            </div>
                        </LoaderButton>
                    ) : null}
                    {!props.isNew ? (
                        <LoaderButton
                            className="form-header-button"
                            type="submit"
                            variant="text"
                            data-testid="saveCreateButton"
                            termKey={SharedComponentsDefs.Update}
                            onClick={handleUpdateButtonClick}
                            isLoading={
                                buttonClickOption === ButtonClickOption.Update
                            }
                            disabled={handleDisableUpdateButtonAction()}>
                            <SaveIcon style={{ color: '#00A84E' }} />
                            <div className="form-header-button-text">
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SharedComponentsDefs.Update}
                                />
                            </div>
                        </LoaderButton>
                    ) : null}
                    {!props.isNew && props.displayDocumentButtons && (
                        <>
                            <PrintDocumentPopover
                                documentType={props?.entityType}
                                documentTemplateQuery={
                                    props?.documentTemplateQuery
                                }
                                documentTemplateQueryOptions={
                                    props?.documentTemplateQueryOptions
                                }
                                open={showGenerate}
                                onClose={() => setShowGenerate(false)}
                                anchorEl={anchorEl}
                                documentQuery={props?.documentQuery}
                                setShouldRefresh={setShouldRefresh}
                            />
                            <LoaderButton
                                className="form-header-button"
                                variant="text"
                                data-testid="printButton"
                                onClick={handleDocumentGenerateButtonClick}
                                isLoading={
                                    buttonClickOption ===
                                    ButtonClickOption.DocumentGenerate
                                }
                                disabled={
                                    (buttonClickOption !==
                                        ButtonClickOption.None &&
                                        buttonClickOption !==
                                            ButtonClickOption.DocumentGenerate) ||
                                    !props.displayDocumentButtons
                                }>
                                <PrintIcon />
                                <div className="form-header-button-text">
                                    {/*<TranslatableText
                                termSet={props.termSet}
                                termKey={SharedComponentsDefs.History}
                            />*/}
                                    Print
                                </div>
                            </LoaderButton>
                        </>
                    )}
                    {!props.isNew && props.showOnRevision && (
                        <LoaderButton
                            className="form-header-button"
                            variant="text"
                            data-testid="historyButton"
                            onClick={handleRevisionButtonClick}
                            isLoading={
                                buttonClickOption === ButtonClickOption.Revision
                            }
                            disabled={
                                (buttonClickOption !== ButtonClickOption.None &&
                                    buttonClickOption !==
                                        ButtonClickOption.Revision) ||
                                !props.showOnRevision
                            }>
                            <HistoryIcon />
                            <div className="form-header-button-text">
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SharedComponentsDefs.History}
                                />
                            </div>
                        </LoaderButton>
                    )}
                    {!props.isNew && props.displayLockButton && (
                        <LoaderButton
                            className="form-header-button"
                            variant="text"
                            data-testid="lockButton"
                            onClick={handleLockButtonClick}
                            isLoading={
                                buttonClickOption === ButtonClickOption.Lock
                            }
                            disabled={
                                (buttonClickOption !== ButtonClickOption.None &&
                                    buttonClickOption !==
                                        ButtonClickOption.Lock) ||
                                props.isLocked ||
                                !props.displayLockButton
                            }>
                            <LockIcon />
                            <div className="form-header-button-text">
                                {props.isLocked ? 'Locked' : 'Lock'}
                            </div>
                        </LoaderButton>
                    )}
                    <div className={'form-header-custom-buttons'}>
                        {props.customToolbarButtons}
                    </div>

                    {!props.isNew && props.displayCopyButton ? (
                        <LoaderButton
                            className="form-header-button"
                            type="submit"
                            variant="text"
                            data-testid="copyButton"
                            onClick={handleCopyButtonClick}
                            isLoading={
                                buttonClickOption === ButtonClickOption.Copy
                            }
                            disabled={handleDisableCopyButtonAction()}>
                            <CopyAllIcon style={{ color: '#00A84E' }} />
                            <div className="form-header-button-text">
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SharedComponentsDefs.Copy}
                                />
                            </div>
                        </LoaderButton>
                    ) : null}
                    {!props.isNew &&
                        props.displayAttachmentButton &&
                        !isNilOrEmpty(props.entityUuid) && (
                            <>
                                <LoaderButton
                                    className="form-header-button"
                                    style={{ minWidth: '100px' }}
                                    variant="text"
                                    data-testid="attachmentButton"
                                    onClick={handleAttachmentButtonClick}
                                    isLoading={
                                        buttonClickOption ===
                                        ButtonClickOption.Attachment
                                    }
                                    disabled={
                                        (buttonClickOption !==
                                            ButtonClickOption.None &&
                                            buttonClickOption !==
                                                ButtonClickOption.Attachment) ||
                                        !props.displayAttachmentButton
                                    }>
                                    <FolderIcon style={{ color: '#F8D775' }} />
                                    <div className="form-header-button-text">
                                        <TranslatableText
                                            termSet={props.termSet}
                                            termKey={
                                                SharedComponentsDefs.Attachments
                                            }
                                        />
                                    </div>
                                </LoaderButton>
                            </>
                        )}
                    {!props.isNew &&
                        props.displayAttachmentCheckInOrOutButton &&
                        !isNilOrEmpty(props.entityUuid) && (
                            <>
                                <LoaderButton
                                    className="form-header-button"
                                    style={{ minWidth: '100px' }}
                                    variant="text"
                                    data-testid="attachmentButton"
                                    onClick={
                                        handleAttachmentCheckInOrOutButtonClick
                                    }
                                    isLoading={
                                        buttonClickOption ===
                                        ButtonClickOption.AttachmentCheckInOrOut
                                    }
                                    disabled={
                                        (buttonClickOption !==
                                            ButtonClickOption.None &&
                                            buttonClickOption !==
                                                ButtonClickOption.AttachmentCheckInOrOut) ||
                                        !props.displayAttachmentCheckInOrOutButton ||
                                        (user?.userId !==
                                            attachment?.attachmentControl
                                                ?.checkedOutByUserId &&
                                            attachment?.attachmentControl
                                                ?.locked)
                                    }>
                                    <InventoryIcon color={'primary'} />
                                    <div className="form-header-button-text">
                                        {attachment?.attachmentControl
                                            ?.locked &&
                                        attachment?.attachmentControl
                                            ?.checkedOutByUserId ===
                                            user?.userId
                                            ? 'Check In'
                                            : 'Check Out'}
                                    </div>
                                </LoaderButton>
                            </>
                        )}
                    {!props.isNew && props.displayAccessLogsButton && (
                        <LoaderButton
                            className="form-header-button"
                            variant="text"
                            data-testid="accessLogsButton"
                            onClick={handleAccessLogsButtonClick}
                            isLoading={
                                buttonClickOption ===
                                ButtonClickOption.AccessLogs
                            }
                            disabled={
                                (buttonClickOption !== ButtonClickOption.None &&
                                    buttonClickOption !==
                                        ButtonClickOption.AccessLogs) ||
                                !props.displayAccessLogsButton
                            }>
                            <HistoryIcon />
                            <div className="form-header-button-text">Logs</div>
                        </LoaderButton>
                    )}
                    {!props.isNew &&
                        props.displayJobsButton &&
                        !isNilOrEmpty(props.entityUuid) && (
                            <>
                                <LoaderButton
                                    className="form-header-button"
                                    variant="text"
                                    data-testid="jobsButton"
                                    onClick={handleJobButtonClick}
                                    isLoading={
                                        buttonClickOption ===
                                        ButtonClickOption.Jobs
                                    }
                                    disabled={
                                        buttonClickOption !==
                                            ButtonClickOption.None &&
                                        buttonClickOption !==
                                            ButtonClickOption.Jobs
                                    }>
                                    <WorkHistoryIcon />
                                    <div className="form-header-button-text">
                                        {/*<TranslatableText
                                    termSet={props.termSet}
                                    termKey={SharedComponentsDefs.History}
                                />*/}
                                        Jobs
                                    </div>
                                </LoaderButton>
                                <JobsDrawer
                                    open={showJobs}
                                    setOpen={setShowJobs}
                                    entityUuid={props.entityUuid}
                                    shouldRefresh={shouldRefresh}
                                    setShouldRefresh={setShouldRefresh}
                                />
                            </>
                        )}
                    {entityData?.entityType && (
                        <TaskButtonGroup entityType={entityData?.entityType} />
                    )}
                </div>

                <div className={'form-header-delete'}>
                    {!props.isNew && !props.hideDeleteButton ? (
                        <>
                            <LoaderButton
                                className="form-header-button"
                                variant="text"
                                data-testid="deleteButton"
                                color={'error'}
                                onClick={handleDeleteButtonClick}
                                termKey={SharedComponentsDefs.Delete}
                                isLoading={
                                    buttonClickOption ===
                                    ButtonClickOption.Delete
                                }
                                disabled={
                                    (buttonClickOption !==
                                        ButtonClickOption.None &&
                                        buttonClickOption !==
                                            ButtonClickOption.Delete) ||
                                    !props.displayDeleteButton
                                }>
                                <DeleteIcon style={{ color: '#FF0000' }} />
                                <div className="form-header-button-text">
                                    <TranslatableText
                                        termSet={props.termSet}
                                        termKey={SharedComponentsDefs.Delete}
                                    />
                                </div>
                            </LoaderButton>
                        </>
                    ) : null}
                </div>
            </div>
            {!props.isNew && props.showOnRevision && (
                <RevisionHistoryDrawer
                    open={open}
                    setOpen={setOpen}
                    fieldMappings={props?.fieldMappings}
                    entityUuid={props?.entityUuid}
                />
            )}
            {!props.isNew && props.displayAccessLogsButton && (
                <AccessLogsModal
                    open={showAccessLogs}
                    setOpen={setShowAccessLogs}
                    entityUuid={props.entityUuid}
                    entityType={props.entityType}
                />
            )}
            <JobStartedModal />
            <JobItemModal />
            <AttachmentsDrawer
                open={showAttachments}
                setOpen={setShowAttachments}
                entityUuid={props.entityUuid}
                entityType={props.entityType}
                shouldRefresh={shouldRefresh}
                setShouldRefresh={setShouldRefresh}
            />
        </>
    );
};

export default BaseFormToolbar;
