import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    sortOrderSet: {
        isLoading: false,
        maxValue: 0,
    },
};

const slice = createSlice({
    name: 'accounting',
    initialState: initialState,
    reducers: {
        getMaxSortOrderValue: (state, action) => {
            state.sortOrderSet.maxValue = action.payload.maxValue;
            state.sortOrderSet.isLoading = action.payload.isLoading;
        },
    },
});

export default slice.reducer;

//TODO: specify actions for export
export const { getMaxSortOrderValue } = slice.actions;
export const actions = slice.actions;
