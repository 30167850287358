import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Grid } from '@mui/material';
import { getAvailableNavLinks } from '../../utils/navigation/navigationUtils';
import NavLink from '../../utils/navigation/navLink';
import { RootState } from '../../store';

interface CardContentProps {
    name: string;
    appImage: any;
    imageName: string;
    about: string;
}

const MyApps = () => {
    const user = useSelector((state: RootState) => state.user);

    const CardContents = (props: CardContentProps) => {
        return (
            <Card sx={{ maxWidth: 345, height: '100%' }}>
                <CardMedia
                    component="img"
                    height="145"
                    sx={{ objectFit: 'contain' }}
                    image={props.appImage[props.imageName]}
                />
                <CardContent>
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        data-testid="appName">
                        {props.name}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        data-testid="appAbout">
                        {props.about}
                    </Typography>
                </CardContent>
            </Card>
        );
    };

    return (
        <Grid container spacing={2} className="myApps" data-testid="myApps">
            {getAvailableNavLinks(user.enabledFeatureFlags, user.permissions)
                .filter(
                    (navLink: NavLink) =>
                        navLink.url !== '/myApps' && navLink.displayCard
                )
                .map((navLink: NavLink) => (
                    <Grid
                        item
                        xl={3}
                        lg={4}
                        md={6}
                        sm={12}
                        xs={12}
                        key={navLink.url}>
                        {navLink.isExternalURL() ? (
                            <a
                                href={navLink.url}
                                target="_blank"
                                style={{ textDecoration: 'none' }}
                                rel="noopener noreferrer">
                                <CardContents
                                    name={navLink.name}
                                    appImage={navLink.appImage}
                                    imageName={navLink.imageName}
                                    about={navLink.about}
                                />
                            </a>
                        ) : (
                            <Link
                                to={navLink.url}
                                style={{ textDecoration: 'none' }}>
                                <CardContents
                                    name={navLink.name}
                                    appImage={navLink.appImage}
                                    imageName={navLink.imageName}
                                    about={navLink.about}
                                />
                            </Link>
                        )}
                    </Grid>
                ))}
        </Grid>
    );
};

export default MyApps;
