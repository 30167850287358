import { isNilOrEmpty, isObjectField } from '../../../../utils/objectUtils';
import _ from 'lodash';

export const isPrimitiveField = (field: any) => {
    switch (typeof field) {
        case 'object':
            return false;
        case 'function':
            return false;
        default:
            return true;
    }
};

/**
 *  Combines two arrays into a new array [old, new] pair values.
 **/
export const zipRecords = (originalRecords: any[], newRecords: any[]) => {
    return Array.from(
        Array(Math.max(newRecords.length, originalRecords.length)),
        (_, i) => {
            const newRecordFound = newRecords.find(
                (element) => element.id === originalRecords[i]?.id
            );
            return [originalRecords[i], newRecordFound];
        }
    );
};

/**
 * Verifies if the current record has changes.
 * @param executeVerification System uses this parameter to execute or not the corresponding validations to verify changes.
 * @param originalValue Original value for the comparison.
 * @param newValue New value for the comparison.
 **/
export const confirmRecordChanges = (
    executeVerification: boolean,
    originalValue: any,
    newValue: any
): boolean => {
    if (!executeVerification) {
        return false;
    }

    if (isNilOrEmpty(originalValue) && isNilOrEmpty(newValue)) {
        return false;
    }

    if (isNilOrEmpty(originalValue) && !isNilOrEmpty(newValue)) {
        return true;
    }

    if (!isNilOrEmpty(originalValue) && isNilOrEmpty(newValue)) {
        return true;
    }

    if (isPrimitiveField(originalValue) && isPrimitiveField(newValue)) {
        return !_.isEqual(originalValue.toString(), newValue.toString());
    }

    if (isObjectField(originalValue) && isObjectField(newValue)) {
        return !_.isEqual(originalValue, newValue);
    }

    return originalValue === undefined && typeof newValue === 'object';
};
