import { Card, CardContent, CardHeader } from '@mui/material';
import React from 'react';
import DatacorLogoSpinner from '../datacorLogoSpinner/DatacorLogoSpinner';
import BaseGrid from './BaseGrid';
import { BaseGridProps } from './Grids.propTypes';
import AttachmentSummaryContext from '../../contexts/attachmentSummary.context';
import useBaseFormGrid from './hooks/useBaseFormGrid';

export type BaseFormGridProps = {
    title?: any;
    isLoading?: boolean;
    aboveGridChildren?: React.ReactNode;
    belowGridChildren?: React.ReactNode;
    hasEntityAttachments?: boolean;
    // field name of the column the entity attachment summary
    // column should be placed after
    entityAttachmentAfterColumnField?: string;
} & BaseGridProps;

const BaseFormGrid = (props: BaseFormGridProps) => {
    props = { domLayout: 'normal', paginationPageSize: 30, ...props };
    props.defaultColDef = props.defaultColDef || {
        resizable: true,
        sortable: true,
        minWidth: 150,
        unSortIcon: true,
        filter: true,
        wrapText: true,
        floatingFilter: true,
        flex: 1,
    };

    const { attachmentSummaryContextValues } = useBaseFormGrid(props);

    return (
        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardHeader
                title={<>{props.title}</>}
                sx={{ display: props.title ? 'block' : 'none' }}
            />

            {props.isLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <CardContent className={'full-size-item'}>
                    <AttachmentSummaryContext.Provider
                        value={attachmentSummaryContextValues}>
                        {props.aboveGridChildren}
                        <BaseGrid {...props} />
                        {props.belowGridChildren}
                    </AttachmentSummaryContext.Provider>
                </CardContent>
            )}
        </Card>
    );
};

export default BaseFormGrid;
