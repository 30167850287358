import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';
import { skipToken } from '@reduxjs/toolkit/query';
import {
    useCreateDocTemplateMutation,
    useGetDocDataSourceQuery,
    useGetDocTemplateQuery,
    useUpdateDocTemplateMutation,
    useGetLicenseKeyQuery,
} from '../../../services/documentDesigner/documentDesigner.service';
import useTranslation from '../../i18n/hooks/useTranslation';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
//import { Stimulsoft } from 'stimulsoft-reports-js/Scripts/stimulsoft.blockly.editor';
//TODO: add tests
const useDocumentDesigner = (
    documentId: string,
    dataSourceId: any,
    designerRef: any
) => {
    let navigate = useNavigate();
    const user = useSelector((state: RootState) => state.user);
    const stimulsoft = (window as any).Stimulsoft;
    const { termSet } = useTranslation(PLATFORM_DEFS.SHARED_COMPONENTS);
    const [createDocumentTemplate] = useCreateDocTemplateMutation();
    const [updateDocumentTemplate] = useUpdateDocTemplateMutation();
    const { data: documentTemplate } = useGetDocTemplateQuery(
        documentId ? documentId : skipToken
    );
    const { data: license } = useGetLicenseKeyQuery();

    //const key = getLicenseKey();

    const { data: dataSource } = useGetDocDataSourceQuery(
        dataSourceId ? dataSourceId : skipToken
    );

    useEffect(() => {
        if (designerRef.current?.report) {
            return;
        }
        if (!isNilOrEmpty(license)) {
            setDesignerRef();
            //when the datasource changes we need to add the data to the report
            if (
                designerRef.current &&
                !isNilOrEmpty(dataSource) &&
                isNilOrEmpty(documentTemplate)
            ) {
                handleSetupNewDocument();
            }
            if (designerRef.current && !isNilOrEmpty(documentTemplate)) {
                handleLoadReport();
            }
        }
    }, [
        dataSource,
        dataSourceId,
        designerRef.current,
        documentTemplate,
        license,
    ]);

    const handleSetupNewDocument = () => {
        if (
            license?.Parameter.Value &&
            license?.Parameter.Value !== 'license_key'
        ) {
            stimulsoft.Base.StiLicense.Key = license?.Parameter.Value;
        }
        const dataSet = new stimulsoft.System.Data.DataSet(dataSource.name);
        const report = new stimulsoft.Report.StiReport();
        dataSet.readJson(JSON.stringify(dataSource?.data));
        report.regData(dataSet.dataSetName, '', dataSet);

        const micrEncoding = stimulsoft.System.IO.File.getFile(
            '/fonts/MICR Encoding.ttf',
            true
        );

        const micrResource = new stimulsoft.Report.Dictionary.StiResource(
            'Micr',
            'Micr',
            false,
            stimulsoft.Report.Dictionary.StiResourceType.FontTtf,
            micrEncoding
        );

        report.dictionary.resources.add(micrResource);

        report.dictionary.synchronize();

        designerRef.current.report = report;
        designerRef.current!.renderHtml('designerContainer');
    };

    const setDesignerRef = async () => {
        if (
            license?.Parameter.Value &&
            license?.Parameter.Value !== 'license_key'
        ) {
            stimulsoft.Base.StiLicense.Key = license?.Parameter.Value;
        }
        // we can supply the designer with overrideable stylings
        const options = new stimulsoft.Designer.StiDesignerOptions();
        options.appearance.fullScreenMode = false;
        options.appearance.showSaveDialog = false;
        options.appearance.allowChangeWindowTitle = false;
        options.toolbar.visible = true;
        options.toolbar.showFileMenu = user?.isDatacorAdmin;

        //the actual designer class..
        const designer = new stimulsoft.Designer.StiDesigner(
            options,
            'StiDesigner',
            false
        );

        // Create designer instance
        designerRef.current = designer;
    };

    const handleLoadReport = () => {
        if (
            license?.Parameter.Value &&
            license?.Parameter.Value !== 'license_key'
        ) {
            stimulsoft.Base.StiLicense.Key = license?.Parameter.Value;
        }
        const report = new stimulsoft.Report.StiReport();

        report.load(documentTemplate?.templateData);

        designerRef.current!.report = report;

        designerRef.current!.renderHtml('designerContainer');
    };

    return {
        navigate,
        termSet,
    };
};

export default useDocumentDesigner;
