import Analytics from '../containers/analytics/Analytics';
import { RouteProps } from './routesConfig';

const AnalyticsRoutes: RouteProps[] = [
    {
        path: '/analytics',
        component: Analytics,
        featureFlag: 'dashboard.nav.apps.analytics',
    },
];

export default AnalyticsRoutes;
