import * as _ from 'lodash';
import { EDITABLE_GRID_MAPPINGS } from '../../../constants/revisionHistory/editableGrids/editableGridMappings.constants';
import { isNilOrEmpty } from '../../../utils/objectUtils';

const REVISION_KEYS = {
    GROUPS: 'groups',
    FROM_GROUPS: 'groupsChange',
    ROLES: 'roles',
    FROM_ROLES: 'rolesChange',
    SUPERVISOR: 'supervisor',
    DEPARTMENT_CODE: 'departmentCodeId',
    DATACOR_ADMIN: 'isDatacorAdmin',
    APP_STREAM: 'isAppStreamUser',
    ZONE: 'zone',
    PERMISSIONS: 'permissions',
    SUBSCRIBERS: 'subscribers',
    BUSINESS_ENTITIES: 'businessEntities',
    ATTRIBUTE_DEFINITIONS: 'attrDefinitions',
    OPTIONS: 'options',
    GHS_HAZARD: 'ghsHazardPhrases',
    GHS_PRECAUTIONARY: 'ghsPrecautionaryPhrases',
    CAS_COMPOSITION: 'sdsCasComposition',
    SDS_EXPOSURE: 'sdsExposures',
    SDS_PROP: 'sdsProperties',
    SDS_TOX: 'sdsToxicities',
    SDS_ECO_TOX: 'sdsEcoToxicities',
    SDS_REG: 'sdsRegulations',
    SDS_SELECTED_PHRASES: 'SdsSelectedOptionalPhrase',
    RD_FORMULA_CAS_COMPOSITIONS: 'casComposition',
    RD_FORMULA_COMPONENTS: 'components',
    RD_FORMULA_EQUATION: 'equation',
    RD_FORMULA_HAZARD_RATING_FACTORS: 'hazardRatings',
    JOURNAL_LINES: 'journalLines',
    RECURRING_JOURNAL_LINES: 'recurringJournalLines',
    SUPPLIER_INVOICE_LINES: 'supplierInvoiceLines',
    CUSTOMER_INVOICE_LINES: 'customerInvoiceLines',
    PAYMENT_LINES: 'paymentLines',
    SDS_OPTIONAL_FORMULA_HAZARD_CLASSIFICATION_GRID:
        'sdsOptionalFormulaHazardClassification',
    SDS_OPTIONAL_GHS_CLASSIFICATION_GRID: 'sdsOptionalGhsClassification',
    SDS_OPTIONAL_HAZARD_PHRASES_GRID: 'sdsOptionalHazardPhrase',
    SDS_OPTIONAL_PRECAUTIONARY_PHRASES_GRID: 'sdsOptionalPrecautionaryPhrase',
    SDS_OPTIONAL_PROPERTIES_GRID: 'sdsOptionalProperty',
    SDS_OPTIONAL_LOCALES_GRID: 'sdsOptionalLocale',
    SDS_OPTIONAL_LANGUAGE: 'sdsOptionalLanguagePhrase',
    ITEM_PROPERTIES: 'itemProperties',
    ITEM_CHEMICAL_COMPOSITION: 'chemicalComposition',
    CAS_HAZARD_PHRASES_GRID: 'casGhsHazardPhrases',
    CAS_PRECAUTIONARY_PHRASES_GRID: 'casGhsPrecautionaryPhrases',
    CAS_TOXICITIES_GRID: 'casToxicities',
    CAS_ECO_TOXICITIES_GRID: 'casEcoToxicities',
    CAS_EXPOSURES_GRID: 'casExposures',
    CAS_PROPERTIES_GRID: 'casProperties',
    CAS_REGULATIONS_GRID: 'casRegulations',
    CAS_HAZARD_CLASSIFICATION_GRID: 'casHazardClassificationMaps',
    DEFAULT_VALUE: 'defaultValue',
};

/* for an object array in the delta to be shown
 the values you want to show needs to be joined/formatted however needed
  for your usecase */
export default class RevisionValueFormatter {
    //the key you want to get from object array on + the full delta object
    format(key: any, delta: any) {
        //group formatter
        let beforeGroupNames: any = [];
        let afterGroupNames: any = [];

        //role formatter
        let beforeRoleNames: any = [];
        let afterRoleNames: any = [];

        //zone formatter
        let deltaBeforeZoneString = '';
        let deltaAfterZoneString = '';

        let deltaBeforeSubscribers: any = [];
        let deltaAfterSubscribers: any = [];

        let deltaBeforeBusinessEnts: any = [];
        let deltaAfterBusinessEnts: any = [];

        let deltaBeforeDefinitions: any = [];
        let deltaAfterDefinitions: any = [];

        switch (key) {
            // you can add your own value formatter for your usecase by
            // adding your key to the revision keys constant
            case REVISION_KEYS.GROUPS:
                delta.groups.before.forEach((group: any) => {
                    beforeGroupNames.push(`${group.group.name}`);
                });

                delta.groups.after.forEach((group: any) => {
                    afterGroupNames.push(`${group.group.name}`);
                });

                return {
                    before: beforeGroupNames.join(', '),
                    after: afterGroupNames.join(', '),
                };
            case REVISION_KEYS.FROM_GROUPS:
                delta.groupsChange.before.forEach((group: any) => {
                    beforeGroupNames.push(`${group.group.name}`);
                });

                delta.groupsChange.after.forEach((group: any) => {
                    afterGroupNames.push(`${group.group.name}`);
                });

                if (delta.groupsChange.before > delta.groupsChange.after) {
                    return {
                        before: beforeGroupNames[0],
                        after: '',
                    };
                } else {
                    return {
                        before: '',
                        after: afterGroupNames[0],
                    };
                }
            case REVISION_KEYS.ROLES:
                delta.roles.before.forEach((role: any) => {
                    beforeRoleNames.push(`${role.role.name}`);
                });

                delta.roles.after.forEach((role: any) => {
                    afterRoleNames.push(`${role.role.name}`);
                });

                return {
                    before: beforeRoleNames.join(', '),
                    after: afterRoleNames.join(', '),
                };
            case REVISION_KEYS.FROM_ROLES:
                delta.rolesChange.before.forEach((role: any) => {
                    beforeRoleNames.push(`${role.role.name}`);
                });

                delta.rolesChange.after.forEach((role: any) => {
                    afterRoleNames.push(`${role.role.name}`);
                });

                if (delta.rolesChange.before > delta.rolesChange.after) {
                    return {
                        before: beforeRoleNames[0],
                        after: '',
                    };
                } else {
                    return {
                        before: '',
                        after: afterRoleNames[0],
                    };
                }
            case REVISION_KEYS.SUPERVISOR:
                return {
                    before: `${delta?.supervisor?.before[0]?.firstName || ''} ${
                        delta?.supervisor?.before[0]?.lastName || ''
                    }`,
                    after: `${delta?.supervisor?.after[0]?.firstName} ${delta?.supervisor?.after[0]?.lastName}  `,
                };
            case REVISION_KEYS.APP_STREAM:
                return {
                    before: delta?.isAppStreamUser?.before ? 'Yes' : 'No',
                    after: delta?.isAppStreamUser?.after ? 'Yes' : 'No',
                };
            case REVISION_KEYS.DATACOR_ADMIN:
                return {
                    before: delta?.isDatacorAdmin?.before ? 'Yes' : 'No',
                    after: delta?.isDatacorAdmin?.after ? 'Yes' : 'No',
                };
            case REVISION_KEYS.ZONE:
                //check if each of the appropiate delta fields are different
                //if so show them. Since we're getting back the whole zone object
                if (
                    !_.isEqual(
                        delta?.zone?.before[0]?.platform,
                        delta?.zone?.after[0]?.platform
                    )
                ) {
                    deltaBeforeZoneString += `Name: ${delta?.zone?.before[0]?.platform} \n`;
                    deltaAfterZoneString += `Name: ${delta?.zone?.after[0]?.platform} \n`;
                }

                if (
                    !_.isEqual(
                        delta?.zone?.before[0]?.url,
                        delta?.zone?.after[0]?.url
                    )
                ) {
                    deltaBeforeZoneString += `URL: ${delta?.zone?.before[0]?.url} \n`;
                    deltaAfterZoneString += `URL: ${delta?.zone?.after[0]?.url} \n`;
                }

                if (
                    !_.isEqual(
                        delta?.zone?.before[0]?.port,
                        delta?.zone?.after[0]?.port
                    )
                ) {
                    deltaBeforeZoneString += `Port: ${delta?.zone?.before[0]?.port} \n`;
                    deltaAfterZoneString += `Port: ${delta?.zone?.after[0]?.port} \n`;
                }

                return {
                    before: deltaBeforeZoneString,
                    after: deltaAfterZoneString,
                };
            case REVISION_KEYS.SUBSCRIBERS:
                delta.subscribers.before.forEach((subscriber: any) => {
                    deltaBeforeSubscribers.push(subscriber.subscriber.name);
                });

                delta.subscribers.after.forEach((subscriber: any) => {
                    deltaAfterSubscribers.push(subscriber.subscriber.name);
                });

                return {
                    before: deltaBeforeSubscribers.join(', '),
                    after: deltaAfterSubscribers.join(', '),
                };
            case REVISION_KEYS.BUSINESS_ENTITIES:
                delta.businessEntities.before.forEach((businessEnt: any) => {
                    deltaBeforeBusinessEnts.push(
                        businessEnt.businessEntity.name
                    );
                });

                delta.businessEntities.after.forEach((businessEnt: any) => {
                    deltaAfterBusinessEnts.push(
                        businessEnt.businessEntity.name
                    );
                });

                return {
                    before: deltaBeforeBusinessEnts.join(', '),
                    after: deltaAfterBusinessEnts.join(', '),
                };
            case REVISION_KEYS.ATTRIBUTE_DEFINITIONS:
                delta.attrDefinitions.before.forEach((definition: any) => {
                    deltaBeforeDefinitions.push(definition.name);
                });

                delta.attrDefinitions.after.forEach((definition: any) => {
                    deltaAfterDefinitions.push(definition.name);
                });

                return {
                    before: deltaBeforeDefinitions.join(', '),
                    after: deltaAfterDefinitions.join(', '),
                };
            case REVISION_KEYS.OPTIONS:
                JSON.parse(delta.options.before).forEach((option: any) => {
                    deltaBeforeDefinitions.push(option.option);
                });

                JSON.parse(delta.options.after).forEach((option: any) => {
                    deltaAfterDefinitions.push(option.option);
                });

                return {
                    before: deltaBeforeDefinitions.join(', '),
                    after: deltaAfterDefinitions.join(', '),
                };
            case REVISION_KEYS.DEFAULT_VALUE:
                try {
                    JSON.parse(delta.defaultValue.before);
                    JSON.parse(delta.defaultValue.before);
                    return {
                        before: '',
                        after: 'Pick List Selections Changed...',
                    };
                } catch (e) {
                    return {
                        before: delta[key].before,
                        after: delta[key].after,
                    };
                }
            // we have all of our editable grid keys here
            // they fall through to the formatter
            // we can add more as we need
            case REVISION_KEYS.JOURNAL_LINES:
            case REVISION_KEYS.RECURRING_JOURNAL_LINES:
            case REVISION_KEYS.SUPPLIER_INVOICE_LINES:
            case REVISION_KEYS.CUSTOMER_INVOICE_LINES:
            case REVISION_KEYS.PAYMENT_LINES:
            case REVISION_KEYS.GHS_HAZARD:
            case REVISION_KEYS.GHS_PRECAUTIONARY:
            case REVISION_KEYS.CAS_COMPOSITION:
            case REVISION_KEYS.SDS_EXPOSURE:
            case REVISION_KEYS.SDS_PROP:
            case REVISION_KEYS.SDS_TOX:
            case REVISION_KEYS.SDS_ECO_TOX:
            case REVISION_KEYS.SDS_REG:
            case REVISION_KEYS.RD_FORMULA_CAS_COMPOSITIONS:
            case REVISION_KEYS.RD_FORMULA_EQUATION:
            case REVISION_KEYS.RD_FORMULA_COMPONENTS:
            case REVISION_KEYS.RD_FORMULA_HAZARD_RATING_FACTORS:
            case REVISION_KEYS.SDS_OPTIONAL_FORMULA_HAZARD_CLASSIFICATION_GRID:
            case REVISION_KEYS.SDS_OPTIONAL_GHS_CLASSIFICATION_GRID:
            case REVISION_KEYS.SDS_OPTIONAL_HAZARD_PHRASES_GRID:
            case REVISION_KEYS.SDS_OPTIONAL_PRECAUTIONARY_PHRASES_GRID:
            case REVISION_KEYS.SDS_OPTIONAL_PROPERTIES_GRID:
            case REVISION_KEYS.SDS_OPTIONAL_LOCALES_GRID:
            case REVISION_KEYS.SDS_OPTIONAL_LANGUAGE:
            case REVISION_KEYS.ITEM_PROPERTIES:
            case REVISION_KEYS.ITEM_CHEMICAL_COMPOSITION:
            case REVISION_KEYS.CAS_HAZARD_PHRASES_GRID:
            case REVISION_KEYS.CAS_PRECAUTIONARY_PHRASES_GRID:
            case REVISION_KEYS.CAS_TOXICITIES_GRID:
            case REVISION_KEYS.CAS_ECO_TOXICITIES_GRID:
            case REVISION_KEYS.CAS_EXPOSURES_GRID:
            case REVISION_KEYS.CAS_PROPERTIES_GRID:
            case REVISION_KEYS.CAS_REGULATIONS_GRID:
            case REVISION_KEYS.SDS_SELECTED_PHRASES:
            case REVISION_KEYS.CAS_HAZARD_CLASSIFICATION_GRID:
                return this.formatEditableGrid(
                    delta[key]?.before,
                    delta[key]?.after,
                    key
                );
            // add any additional formatters based on key in object array here
            // the default format is just returning the before and after
            default:
                return { before: delta[key].before, after: delta[key].after };
        }
    }

    /**
     * format editable grid values
     * @param before
     * @param after
     * @param key
     * @returns {{before: string, after: string}}
     * Uses the editablegridmappings constant to map the fields to the values
     * and then display them in the delta like other revisions
     */
    formatEditableGrid(before: any, after: any, key: any): any {
        //remove dups from before and after
        const beforeFiltered = this.removeSameValues(before, after);
        const afterFiltered = this.removeSameValues(after, before);

        return [...beforeFiltered, ...afterFiltered];
    }

    /**
     * due to the way editable grids are being saved
     * all items show in the delta each update
     * remove same values from the editable grid
     * @param item
     * @param compare
     */
    removeSameValues(item: any, compare: any) {
        return item.filter((item: any) => {
            return !_.isEqual(
                item,
                compare.find((compareItem: any) => compareItem.id === item.id)
            );
        });
    }

    /**
     * takes the delta array of our values and formats them to a string
     * to be shown in the delta
     * @param item
     * @param key
     */
    getDisplayAndConvertToString(item: any, key: any) {
        return item?.map((item: any) => {
            return Object.keys(item)
                .map((itemKey: any) => {
                    if (
                        EDITABLE_GRID_MAPPINGS[key][itemKey] &&
                        !isNilOrEmpty(item[itemKey])
                    ) {
                        return `${EDITABLE_GRID_MAPPINGS[key][itemKey]}: ${item[itemKey]}`;
                    }
                })
                .filter((str: any) => str)
                .join('\r\n');
        });
    }
}
