import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import {
    setActiveCustomerProductTransactionDetails,
    updateSelectedPricingConcerns,
} from '../../../../store/pricingAnalysisTool';
import { RootState } from '../../../../store';

type ParamTypes = { customerProductId: string };
const usePricingCustomerProductDetails = () => {
    let location = useLocation();
    const { customerProductId } = useParams<ParamTypes>();
    const hash = location.hash ? location.hash : '';
    const dispatch = useDispatch();
    const { pricingAnalysisToolSet } = useSelector(
        (state: RootState) => state.pricingAnalysisTool
    );
    const [costomerProductName, setCostomerProductName] = useState(null);
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (
            isNilOrEmpty(
                Object.keys(
                    pricingAnalysisToolSet.pricingConcernSelectedCategories
                )
            )
        ) {
            dispatch(
                updateSelectedPricingConcerns([
                    'outdatedPricing',
                    'pricingLag',
                    'belowAverageMargin',
                    'marginBelowGoal',
                    'belowAveragePrice',
                ])
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricingAnalysisToolSet.pricingConcernSelectedCategories]);

    useEffect(() => {
        if (pricingAnalysisToolSet.transactionsUsedForDetail) {
            dispatch(
                setActiveCustomerProductTransactionDetails(
                    customerProductId + hash
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerProductId, pricingAnalysisToolSet.transactionsUsedForDetail]);

    useEffect(() => {
        if (
            !isNilOrEmpty(pricingAnalysisToolSet.activeCustomerProductDetails)
        ) {
            setCostomerProductName(
                `${pricingAnalysisToolSet.activeCustomerProductDetails[0].externalCustomerName} / ${pricingAnalysisToolSet.activeCustomerProductDetails[0].productName}`
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricingAnalysisToolSet.activeCustomerProductDetails]);
    return { value, costomerProductName, handleChange };
};
export default usePricingCustomerProductDetails;
