import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    InputAdornment,
    TextField,
} from '@mui/material';
import React from 'react';
import { dateFormatter } from '../../utils/formattingUtils';

const AuditDialog = (props: any) => {
    const { onClose, open, audit } = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open} fullWidth>
            <DialogTitle>Update Details</DialogTitle>
            <DialogContent>
                <Grid container spacing={4}>
                    <br />
                    {audit?.actions ? (
                        <>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Invoice Number"
                                    id="invoiceNumber"
                                    type="text"
                                    value={
                                        audit.actions.find(
                                            (action: any) =>
                                                action.field === 'invoiceNumber'
                                        )?.newValue || 'No Change...'
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {audit.actions.find(
                                                    (action: any) =>
                                                        action.field ===
                                                        'invoiceNumber'
                                                )?.oldValue || ''}
                                            </InputAdornment>
                                        ),
                                    }}
                                    data-testid="invoice-number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="PO Number"
                                    id="poNumber"
                                    type="text"
                                    value={
                                        audit.actions.find(
                                            (action: any) =>
                                                action.field === 'poNumber'
                                        )?.newValue || 'No Change...'
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {audit.actions.find(
                                                    (action: any) =>
                                                        action.field ===
                                                        'poNumber'
                                                )?.oldValue || ''}
                                            </InputAdornment>
                                        ),
                                    }}
                                    data-testid="po-number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Invoice Date (MM/dd/yyyy)"
                                    value={
                                        audit.actions.find(
                                            (action: any) =>
                                                action.field === 'invoiceDate'
                                        )?.newValue
                                            ? dateFormatter(
                                                  audit.actions.find(
                                                      (action: any) =>
                                                          action.field ===
                                                          'invoiceDate'
                                                  )?.newValue
                                              )
                                            : 'No Change...'
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {audit.actions.find(
                                                    (action: any) =>
                                                        action.field ===
                                                        'invoiceDate'
                                                )?.oldValue
                                                    ? dateFormatter(
                                                          audit.actions.find(
                                                              (action: any) =>
                                                                  action.field ===
                                                                  'invoiceDate'
                                                          )?.oldValue
                                                      )
                                                    : ''}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="due"
                                    label="Total Amount"
                                    type="text"
                                    value={
                                        audit.actions.find(
                                            (action: any) =>
                                                action.field === 'due'
                                        )?.newValue || 'No Change...'
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {audit.actions.find(
                                                    (action: any) =>
                                                        action.field === 'due'
                                                )?.oldValue || ''}
                                            </InputAdornment>
                                        ),
                                    }}
                                    data-testid="due"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="supplierNumber"
                                    label="Supplier Number"
                                    fullWidth
                                    type="text"
                                    value={
                                        audit.actions.find(
                                            (action: any) =>
                                                action.field ===
                                                'supplierNumber'
                                        )?.newValue || 'No change...'
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {audit.actions.find(
                                                    (action: any) =>
                                                        action.field ===
                                                        'supplierNumber'
                                                )?.oldValue || ''}
                                            </InputAdornment>
                                        ),
                                    }}
                                    data-testid="VNUM"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="supplierName"
                                    label="Supplier Name"
                                    fullWidth
                                    type="text"
                                    value={
                                        audit.actions.find(
                                            (action: any) =>
                                                action.field === 'supplierName'
                                        )?.newValue || 'No change...'
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {audit.actions.find(
                                                    (action: any) =>
                                                        action.field ===
                                                        'supplierName'
                                                )?.oldValue || ''}
                                            </InputAdornment>
                                        ),
                                    }}
                                    data-testid="supplierName"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="Ad1"
                                    label="Line 1"
                                    fullWidth
                                    type="text"
                                    value={
                                        audit.actions.find(
                                            (action: any) =>
                                                action.field === 'remitAddress'
                                        )?.newValue?.Ad1 || 'No change...'
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {audit.actions.find(
                                                    (action: any) =>
                                                        action.field ===
                                                        'remitAddress'
                                                )?.oldValue?.Ad1 || ''}
                                            </InputAdornment>
                                        ),
                                    }}
                                    data-testid="Ad1"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="Ad2"
                                    label="Line 2"
                                    fullWidth
                                    type="text"
                                    value={
                                        audit.actions.find(
                                            (action: any) =>
                                                action.field === 'remitAddress'
                                        )?.newValue?.Ad2 || 'No change...'
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {audit.actions.find(
                                                    (action: any) =>
                                                        action.field ===
                                                        'remitAddress'
                                                )?.oldValue?.Ad2 || ''}
                                            </InputAdornment>
                                        ),
                                    }}
                                    data-testid="Ad2"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="Ad3"
                                    label="Line 3"
                                    fullWidth
                                    type="text"
                                    value={
                                        audit.actions.find(
                                            (action: any) =>
                                                action.field === 'remitAddress'
                                        )?.newValue?.Ad3 || 'No change...'
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {audit.actions.find(
                                                    (action: any) =>
                                                        action.field ===
                                                        'remitAddress'
                                                )?.oldValue?.Ad3 || ''}
                                            </InputAdornment>
                                        ),
                                    }}
                                    data-testid="Ad3"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="city"
                                    label="City"
                                    fullWidth
                                    type="text"
                                    value={
                                        audit.actions.find(
                                            (action: any) =>
                                                action.field === 'remitAddress'
                                        )?.newValue?.city || 'No change...'
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {audit.actions.find(
                                                    (action: any) =>
                                                        action.field ===
                                                        'remitAddress'
                                                )?.oldValue?.city || ''}
                                            </InputAdornment>
                                        ),
                                    }}
                                    data-testid="city"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="state"
                                    label="State"
                                    fullWidth
                                    type="text"
                                    value={
                                        audit.actions.find(
                                            (action: any) =>
                                                action.field === 'remitAddress'
                                        )?.newValue?.state || 'No change...'
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {audit.actions.find(
                                                    (action: any) =>
                                                        action.field ===
                                                        'remitAddress'
                                                )?.oldValue?.state || ''}
                                            </InputAdornment>
                                        ),
                                    }}
                                    data-testid="state"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="zip"
                                    label="Postal Code"
                                    fullWidth
                                    type="text"
                                    value={
                                        audit.actions.find(
                                            (action: any) =>
                                                action.field === 'remitAddress'
                                        )?.newValue?.zip || 'No change...'
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {audit.actions.find(
                                                    (action: any) =>
                                                        action.field ===
                                                        'remitAddress'
                                                )?.oldValue?.zip || ''}
                                            </InputAdornment>
                                        ),
                                    }}
                                    data-testid="zip"
                                />
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12}>
                            No update fields found...Please select another from
                            the list.
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default AuditDialog;
