import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import { isEmpty } from 'lodash';
import MultiselectDropdown from '../../../components/form/multiselectDropdown/MultiselectDropdown';
import './ApprovalWorkflows.css';
import LoaderButton from '../../../components/LoaderButton';
import ApproversCellRenderer from './ApproversCellRenderer';
import isActiveCellRenderer from './isActiveCellRenderer';
import { setSelectApproverOptions } from './Utils';
import DeleteCellRenderer from './DeleteCellRenderer';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import ApprovalsGrid from '../../../components/grids/Grid';
import DatacorLogoSpinner from '../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import { TextField, Grid } from '@mui/material';
import useApprovalWorkflowsForm from '../hooks/ApprovalWorkflows/useApprovalWorkflowsForm';

const ApprovalWorkflowsForm = () => {
    const {
        fields,
        handleFieldChange,
        user,
        users,
        isLoadingUsers,
        newApprovers,
        setNewApprovers,
        submitCreateApprovalWorkflow,
        isLoadingCreateWorkflows,
        shouldShowGrid,
        rowData,
        updateApprovalWorkflow,
        noWorkflows,
    } = useApprovalWorkflowsForm();

    return (
        <>
            <div className="create-workflow">
                <Grid container spacing={4} style={{ paddingBottom: '10px' }}>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            id="workflowName"
                            type="text"
                            value={fields.workflowName}
                            data-testid="create-workflow-name"
                            onChange={handleFieldChange}
                            placeholder="Workflow Name"
                            disabled={
                                !PermissionsUtil.isPermissionEnabled(
                                    user.permissions,
                                    PERMISSIONS.AP_AUTOMATION.APPROVAL_WORKFLOWS
                                        .CREATE
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        {!isLoadingUsers && (
                            <MultiselectDropdown
                                isMulti
                                isDisabled={
                                    !PermissionsUtil.isPermissionEnabled(
                                        user.permissions,
                                        PERMISSIONS.AP_AUTOMATION
                                            .APPROVAL_WORKFLOWS.CREATE
                                    )
                                }
                                menuPlacement={'bottom'}
                                id="create-workflow-select"
                                classNamePrefix="select"
                                className="create-workflow-approvers"
                                closeMenuOnSelect={false}
                                onMenuClose={() => {}}
                                value={newApprovers}
                                options={setSelectApproverOptions(users)}
                                placeholder={'Select Approvers'}
                                onChange={(
                                    approverOptionValues: Array<string>
                                ) => {
                                    setNewApprovers(approverOptionValues);
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item xs={4}>
                        <LoaderButton
                            variant="contained"
                            color="primary"
                            onClick={submitCreateApprovalWorkflow}
                            type="submit"
                            data-testid="uploadApprovalWorkflowBtn"
                            isLoading={isLoadingCreateWorkflows}
                            disabled={
                                !PermissionsUtil.isPermissionEnabled(
                                    user.permissions,
                                    PERMISSIONS.AP_AUTOMATION.APPROVAL_WORKFLOWS
                                        .CREATE
                                ) ||
                                fields.workflowName === '' ||
                                isEmpty(newApprovers)
                            }>
                            Create Approval Workflow
                        </LoaderButton>
                    </Grid>
                </Grid>
            </div>
            {shouldShowGrid ? (
                <ApprovalsGrid
                    testId="criteria-list-grid"
                    rowData={rowData}
                    frameworkComponents={{
                        ApproversCellRenderer: ApproversCellRenderer,
                        delete: DeleteCellRenderer,
                        isActiveCellRenderer: isActiveCellRenderer,
                    }}
                    gridOptions={{
                        suppressRowTransform: true,
                    }}
                    defaultColDef={{
                        resizable: true,
                    }}
                    immutableData={true}
                    dataKey={'workflowName'}>
                    <AgGridColumn
                        field="delete"
                        headerName=""
                        width={55}
                        cellRenderer="delete"
                    />
                    <AgGridColumn
                        field="workflowName"
                        editable={PermissionsUtil.isPermissionEnabled(
                            user.permissions,
                            PERMISSIONS.AP_AUTOMATION.APPROVAL_WORKFLOWS.UPDATE
                        )}
                        // @ts-ignore
                        valueSetter={async (data) => {
                            if (data.newValue) {
                                const args = {
                                    workflowId: data.data.workflowId,
                                    workflowName: data.newValue,
                                    approvers: data.data.approvers,
                                    isActive: data.data.isActive,
                                };

                                updateApprovalWorkflow(args);
                            }
                        }}
                        wrapText={true}
                        autoHeight={true}
                        sort="asc"
                    />
                    <AgGridColumn
                        cellStyle={{
                            overflow: 'visible',
                            whiteSpace: 'normal',
                            height: 'auto',
                        }}
                        field="approvers"
                        autoHeight={true}
                        wrapText={true}
                        flex={1}
                        cellRenderer="ApproversCellRenderer"
                    />
                    <AgGridColumn
                        field="isActive"
                        headerName="Is Active?"
                        cellRenderer="isActiveCellRenderer"
                        width={150}
                    />
                </ApprovalsGrid>
            ) : (
                <>{noWorkflows ? '' : <DatacorLogoSpinner />}</>
            )}
        </>
    );
};

export default ApprovalWorkflowsForm;
