import React, { useEffect } from 'react';
import BaseGrid from '../../grids/BaseGrid';
import { EDITABLE_GRID_MAPPINGS } from '../../../constants/revisionHistory/editableGrids/editableGridMappings.constants';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import _ from 'lodash';

const RevisionGridDelta = (props: any) => {
    const { delta } = props;
    const [colDefs, setColDefs] = React.useState<any>([]);
    const [rowData, setRowData] = React.useState<any>([]);

    useEffect(() => {
        if (delta) {
            setColDefs(setupColDefs());
            setRowData(setupRowData());
        }
    }, [delta]);

    /**
     * setting up the col defs
     * by mapping through the delta and matching it with the
     * editable grid mappings
     */
    const setupColDefs = () => {
        const colDefs: any = [];
        Object.keys(delta).map((key: string) => {
            if (!isNilOrEmpty(EDITABLE_GRID_MAPPINGS[key])) {
                Object.keys(EDITABLE_GRID_MAPPINGS[key]).map((item: any) => {
                    colDefs.push({
                        field: item,
                        cellRenderer: 'revisionCellRenderer',
                        resizable: true,
                        autoHeaderHeight: true,
                    });
                });
            }
        });

        return colDefs;
    };

    /**
     * we need to use a cellRenderer
     * because we are displaying the before and after values
     * in a single cell
     * @param props
     * @constructor
     */
    const RevisionCellRenderer = (props: any) => {
        return (
            <div>
                <span>{props.value}</span>
            </div>
        );
    };

    /**
     * there is a lot going on here...
     * we are mapping through the delta and setting up the rowData
     * we are also checking if the before and after values are different
     * we map through the before values and after values
     * if the row has been deleted we display the before values with a strikethrough
     * if a column has been updated, we strike through the before value and display the after value
     */
    const setupRowData = () => {
        const rowData: any = [];
        Object.keys(delta).map((key: string) => {
            const before = delta[key]?.before;
            const after = delta[key]?.after;

            if (!_.isArray(before)) {
                return;
            }

            before?.forEach((item: any) => {
                const matchedAfter = after.find(
                    (afterItem: any) => afterItem.id === item.id
                );
                let rowDataItem: any = {};
                if (matchedAfter) {
                    // this same condition is checked for in the next loop
                    // so we skip here and let the next loop handle it
                    // we do not want each row twice
                    return;
                } else {
                    Object.keys(item).map((itemKey) => {
                        // rows that were deleted
                        rowDataItem[itemKey] = (
                            <span style={{ textDecoration: 'line-through' }}>
                                {item[itemKey]?.toString()}
                            </span>
                        );
                    });
                }
                rowData.push(rowDataItem);
            });

            after?.forEach((item: any) => {
                const matchedBefore = before.find(
                    (beforeItem: any) => beforeItem.id === item.id
                );
                let rowDataItem: any = {};
                if (matchedBefore) {
                    Object.keys(item).map((itemKey) => {
                        if (item[itemKey] !== matchedBefore[itemKey]) {
                            rowDataItem[itemKey] = (
                                <>
                                    <span
                                        style={{
                                            textDecoration: 'line-through',
                                        }}>
                                        {matchedBefore[itemKey]?.toString()}
                                    </span>{' '}
                                    <br />
                                    <span>{item[itemKey]?.toString()}</span>
                                </>
                            );
                        } else {
                            rowDataItem[itemKey] = `${item[itemKey]}`;
                        }
                    });
                } else {
                    // newly added rows
                    Object.keys(item).map((itemKey) => {
                        rowDataItem[itemKey] = `${item[itemKey]}`;
                    });
                }
                rowData.push(rowDataItem);
            });
        });
        return rowData;
    };

    /**
     * we can dynamically set the row height if we need to
     * @param params
     */
    const getRowHeight = (params: any) => {
        return 60;
    };

    return (
        <BaseGrid
            displayGrid={true}
            getRowHeight={getRowHeight}
            columnDefs={colDefs}
            rowData={rowData}
            components={{
                revisionCellRenderer: RevisionCellRenderer,
            }}
        />
    );
};

export default RevisionGridDelta;
