import React, { useContext } from 'react';
import './Home.css';
import { Link, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import datacorLogo from '../libs/images/Datacor_Products_Logo_Selected_Outlined_RGB_ERP_Light.png';
import { Button, Card } from '@mui/material';
import { RootState } from '../store';
import SettingsContext from '../contexts/settings.context';

const Home = () => {
    const user = useSelector((state: RootState) => state.user);
    const { settings } = useContext(SettingsContext);

    const renderLander = () => {
        return (
            <Card className="lander">
                <img
                    src={datacorLogo}
                    alt="Logo"
                    style={{ height: '80%', width: '80%' }}
                />
                <div>
                    {/*@ts-ignore*/}
                    <Button
                        component={Link}
                        to="/login"
                        style={{ color: 'white', textDecoration: 'none' }}
                        variant="contained"
                        color="primary">
                        Login
                    </Button>
                </div>
            </Card>
        );
    };

    const redirect = () => {
        return (
            <Navigate
                to={
                    settings.userSettings?.defaultPage
                        ? settings.userSettings?.defaultPage
                        : '/myApps'
                }
                replace
            />
        );
    };

    return (
        <div className="Home">
            {user.isSignedIn ? redirect() : renderLander()}
        </div>
    );
};

export default Home;
