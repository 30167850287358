import { Grid, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import InvoicePreview from '../../../containers/apAutomation/InvoicePreview';
import React from 'react';

const ArchivedDocumentViewer = (props: any) => {
    const {
        invoiceNumber,
        poNumber,
        invoiceDate,
        dueDate,
        due,
        supplierNumber,
        supplierName,
        Ad1,
        Ad2,
        Ad3,
        city,
        state,
        zip,
        pdfUrl,
    } = props.document;
    return (
        <Grid container spacing={2} data-testid="document-info">
            <Grid item xs={12} md={12} lg={6} xl={6}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Invoice Number"
                            id="invoiceNumber"
                            required
                            type="text"
                            value={invoiceNumber}
                            data-testid="invoice-number"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="PO Number"
                            id="poNumber"
                            type="text"
                            value={poNumber}
                            data-testid="po-number"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DesktopDatePicker
                            label="Invoice Date (MM/dd/yyyy)"
                            inputFormat="MM/DD/yyyy"
                            value={invoiceDate}
                            onChange={null}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DesktopDatePicker
                            label="Due Date (MM/dd/yyyy)"
                            inputFormat="MM/DD/yyyy"
                            value={dueDate}
                            onChange={null}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            required
                            id="due"
                            label="Total Amount"
                            fullWidth
                            type="text"
                            value={due}
                            data-testid="due"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="supplierNumber"
                            label="Supplier Number"
                            fullWidth
                            required
                            type="text"
                            value={supplierNumber}
                            data-testid="VNUM"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="supplierName"
                            label="Supplier Name"
                            fullWidth
                            required
                            type="text"
                            value={supplierName}
                            data-testid="supplierName"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="Ad1"
                            label="Line 1"
                            fullWidth
                            required
                            type="text"
                            value={Ad1}
                            data-testid="Ad1"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="Ad2"
                            label="Line 2"
                            fullWidth
                            type="text"
                            value={Ad2}
                            data-testid="Ad2"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="Ad3"
                            label="Line 3"
                            fullWidth
                            type="text"
                            value={Ad3}
                            data-testid="Ad3"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            id="city"
                            label="City"
                            fullWidth
                            required
                            type="text"
                            value={city}
                            data-testid="city"
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            id="state"
                            label="State"
                            fullWidth
                            required
                            type="text"
                            value={state}
                            data-testid="state"
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            id="zip"
                            label="Postal Code"
                            fullWidth
                            required
                            type="text"
                            value={zip}
                            data-testid="zip"
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6}>
                <InvoicePreview pdfUrl={pdfUrl} />
            </Grid>
        </Grid>
    );
};

export default ArchivedDocumentViewer;
