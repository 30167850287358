import React, {
    useCallback,
    useContext,
    useState,
    useRef,
    useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useGetAllDocumentsForGridQuery,
    useGetAllOpenDocumentsQuery,
    useGetDocumentsByApproverQuery,
    useGetOpenDocumentsByApproverQuery,
    useUploadDocumentFromWebMutation,
} from '../../../services/apAutomation/apAutomation.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router-dom';
import { GridReadyEvent, SetFilterValuesFuncParams } from 'ag-grid-community';
import { DocumentGridContext } from '../contexts/DocumentGridContext';
import { RootState } from '../../../store';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import {
    currencyFormatter,
    dateFormatter,
} from '../../../utils/formattingUtils';
import { useGetUsersQuery } from '../../../services/organizations/organizations.service';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { UserList } from '../../../utils/users/userList';

const useDocumentListView = () => {
    const user = useSelector((state: RootState) => state.user);
    const { documentGridOptions } = useContext(DocumentGridContext);
    const gridRef = useRef();
    const [gridApi, setGridApi] = useState(null);

    const viewOnlyAssignedPermission =
        !user?.isDatacorAdmin &&
        !user?.isDatacorUser &&
        PermissionsUtil.isPermissionEnabled(
            user.permissions,
            PERMISSIONS.AP_AUTOMATION.VIEW_ASSIGNED_ONLY
        );

    const { data: userList, isLoading } = useGetUsersQuery();
    const { data: invoicesByApprover, isLoading: isLoadingInvoicesByApprover } =
        useGetDocumentsByApproverQuery(
            viewOnlyAssignedPermission && documentGridOptions.showVouchered
                ? user.signInEmail
                : skipToken
        );
    const {
        data: openInvoicesByApprover,
        isLoading: isLoadingOpenInvoicesByApprover,
    } = useGetOpenDocumentsByApproverQuery(
        viewOnlyAssignedPermission && !documentGridOptions.showVouchered
            ? user.signInEmail
            : skipToken
    );

    const {
        data: allDocuments,
        isLoading: isLoadingDocuments,
        isFetching: isFetchingAllDocuments,
    } = useGetAllDocumentsForGridQuery();
    const {
        data: allOpenDocuments,
        isLoading: isLoadingOpenDocuments,
        isFetching: isFetchingOpenDocuments,
    } = useGetAllOpenDocumentsQuery();
    const [uploadInvoice] = useUploadDocumentFromWebMutation();

    const [openArchiveModal, setOpenArchiveModal] = useState(false);
    const [filteredRowData, setFilteredRowData] = useState([]);
    const [openSettings, setOpenSettings] = useState(false);

    useEffect(() => {
        const docsforRow = viewOnlyAssignedPermission
            ? invoicesByApprover
            : allDocuments;
        const openDocsForRow = viewOnlyAssignedPermission
            ? openInvoicesByApprover
            : allOpenDocuments;

        const rowData = documentGridOptions.showVouchered
            ? docsforRow
            : openDocsForRow;

        const finalRowData =
            !documentGridOptions.showRejected && rowData
                ? rowData?.filter((row: any) => {
                      return row.approvalStatus.key !== 'REJECTED';
                  })
                : rowData;

        if (gridApi && finalRowData) {
            setFilteredRowData(finalRowData);
            handleSetDatasource(gridApi, finalRowData);
        }
    }, [
        allDocuments,
        allOpenDocuments,
        invoicesByApprover,
        openInvoicesByApprover,
        documentGridOptions.showRejected,
        documentGridOptions.showVouchered,
        gridApi,
    ]);

    const getValuesAsync = (
        params: SetFilterValuesFuncParams,
        fieldId: string,
        nested: string = null
    ) => {
        return filteredRowData.map((doc: any) => {
            return nested ? doc[fieldId][nested] : doc[fieldId];
        });
    };

    const navigate = useNavigate();
    const shouldLoad =
        isLoading ||
        isLoadingOpenDocuments ||
        isLoadingDocuments ||
        isLoadingInvoicesByApprover ||
        isLoadingOpenInvoicesByApprover;

    /* istanbul ignore next */
    const openDocument = async (row: any) => {
        navigate(`/apAutomation/document/${row.data.documentId}`);
    };

    const sortData = (sortModel: any, data: any) => {
        const sortPresent = sortModel && sortModel.length > 0;
        if (!sortPresent) {
            return data;
        }
        // do an in memory sort of the data, across all the fields
        const resultOfSort = data.slice();
        resultOfSort.sort(function (a: any, b: any) {
            for (let k = 0; k < sortModel.length; k++) {
                const sortColModel = sortModel[k];

                let valueA;
                let valueB;

                if (!a[sortColModel.colId] && !b[sortColModel.colId]) {
                    const splitNested = sortColModel.colId.split('.');

                    //if there is no value AND the the value isn't nested
                    //all values are blank so we can just return

                    if (splitNested.length < 2) {
                        return;
                    }
                    valueA = a[splitNested[0]][splitNested[1]];
                    valueB = b[splitNested[0]][splitNested[1]];
                } else {
                    valueA = a[sortColModel.colId];
                    valueB = b[sortColModel.colId];
                }

                // this filter didn't find a difference, move onto the next one
                if (valueA === valueB) {
                    continue;
                }
                const sortDirection = sortColModel.sort === 'asc' ? 1 : -1;
                if (valueA > valueB) {
                    return sortDirection;
                } else {
                    return sortDirection * -1;
                }
            }
            // no filters found a difference
            return 0;
        });
        return resultOfSort;
    };

    const checkFilterItem = (
        item: any,
        filterType: string,
        filterModel: any
    ) => {
        let shouldAdd = false;
        const valueLowerCase = filterModel[filterType].filter.toLowerCase();
        let filterTextLowerCase = '';
        if (filterType === 'uploader') {
            filterTextLowerCase = new UserList(userList)
                .getUsersFullNameFromEmail(item.uploader, true)
                .toLowerCase();
        } else if (
            filterType === 'invoiceDate' ||
            filterType === 'dueDate' ||
            filterType === 'uploadDate'
        ) {
            filterTextLowerCase = dateFormatter(item[filterType]);
        } else if (filterType === 'due') {
            filterTextLowerCase = currencyFormatter(item[filterType] || 0);
        } else {
            filterTextLowerCase = item[filterType]?.toLowerCase() || '';
        }

        switch (filterModel[filterType].type) {
            case 'contains':
                if (filterTextLowerCase.includes(valueLowerCase)) {
                    shouldAdd = true;
                }
                break;
            case 'notContains':
                if (!filterTextLowerCase.includes(valueLowerCase)) {
                    shouldAdd = true;
                }
                break;
            case 'equals':
                if (valueLowerCase === filterTextLowerCase) {
                    shouldAdd = true;
                }
                break;
            case 'notEqual':
                if (valueLowerCase !== filterTextLowerCase) {
                    shouldAdd = true;
                }
                break;
            case 'startsWith':
                if (valueLowerCase.indexOf(filterTextLowerCase) === 0) {
                    shouldAdd = true;
                }
                break;
            case 'endsWith':
                if (
                    valueLowerCase.lastIndexOf(filterTextLowerCase) >= 0 &&
                    valueLowerCase.lastIndexOf(filterTextLowerCase) ===
                        valueLowerCase.length - filterTextLowerCase.length
                ) {
                    shouldAdd = true;
                }
                break;
            default:
                // should never happen
                shouldAdd = false;
        }

        return shouldAdd;
    };

    const filterData = (filterModel: any, data: any[]) => {
        const filterPresent =
            filterModel && Object.keys(filterModel).length > 0;
        let shouldAdd = false;
        if (!filterPresent) {
            return data;
        }
        const resultOfFilter = [];
        for (let i = 0; i < data.length; i++) {
            const item = data[i];

            if (filterModel.uploader) {
                shouldAdd = checkFilterItem(item, 'uploader', filterModel);
            }

            if (filterModel.invoiceNumber) {
                shouldAdd = checkFilterItem(item, 'invoiceNumber', filterModel);
            }

            if (filterModel.supplierNumber) {
                shouldAdd = checkFilterItem(
                    item,
                    'supplierNumber',
                    filterModel
                );
            }

            if (filterModel.supplierName) {
                shouldAdd = checkFilterItem(item, 'supplierName', filterModel);
            }

            if (filterModel.due) {
                shouldAdd = checkFilterItem(item, 'due', filterModel);
            }

            if (filterModel.invoiceDate) {
                shouldAdd = checkFilterItem(item, 'invoiceDate', filterModel);
            }

            if (filterModel.uploadDate) {
                shouldAdd = checkFilterItem(item, 'uploadDate', filterModel);
            }

            if (filterModel.dueDate) {
                shouldAdd = checkFilterItem(item, 'dueDate', filterModel);
            }

            if (filterModel.poNumber) {
                shouldAdd = checkFilterItem(item, 'poNumber', filterModel);
            }

            if (filterModel['poStatus.value']) {
                if (
                    filterModel['poStatus.value'].values.indexOf(
                        item.poStatus.value
                    ) < 0
                ) {
                    continue;
                } else {
                    shouldAdd = true;
                }
            }

            if (filterModel['approvalStatus.value']) {
                if (
                    filterModel['approvalStatus.value'].values.indexOf(
                        item.approvalStatus.value
                    ) < 0
                ) {
                    continue;
                } else {
                    shouldAdd = true;
                }
            }

            if (filterModel.voucherNumber && item?.voucherNumber) {
                shouldAdd = checkFilterItem(item, 'voucherNumber', filterModel);
            }

            if (shouldAdd) {
                resultOfFilter.push(item);
            }
        }

        return resultOfFilter;
    };

    const sortAndFilter = (
        allOfTheData: any[],
        sortModel: any,
        filterModel: any
    ) => {
        return sortData(sortModel, filterData(filterModel, allOfTheData));
    };

    const handleSetDatasource = (
        gridApi: any,
        finalRowData: any[] = filteredRowData
    ) => {
        const dataSource: any = {
            rowCount: undefined,
            getRows: (params: any) => {
                const dataAfterSorting = sortAndFilter(
                    finalRowData,
                    params.sortModel,
                    params.filterModel
                );

                const rowsThisPage = dataAfterSorting.slice(
                    params.startRow,
                    params.endRow
                );
                let lastRow = -1;
                if (dataAfterSorting.length <= params.endRow) {
                    lastRow = dataAfterSorting.length;
                }
                params.successCallback(rowsThisPage, lastRow);
            },
        };
        gridApi.setDatasource(dataSource);
    };

    const onGridReady = useCallback(
        (params: GridReadyEvent) => {
            if (isLoading || !filteredRowData) {
                return;
            }
            setGridApi(params.api);
            params.api.sizeColumnsToFit();
        },
        [isLoading, filteredRowData]
    );

    const handleUploadInvoices = async (fileArray: string[]) => {
        //make sure there are no duplicates
        let uniquePdfs: any = [];
        const pdfsToUpload = fileArray.filter((ele: any) => {
            const isDuplicate = uniquePdfs.includes(ele);

            if (!isDuplicate) {
                uniquePdfs.push(ele);
                return true;
            }
            return false;
        });
        const args = {
            pdfArray: Array(pdfsToUpload.length).fill('0'),
            user: {
                email: user.signInEmail,
            },
        };
        const uploadUrls: any = await uploadInvoice(args);

        for (const urlObj of uploadUrls.data) {
            const index: number = uploadUrls.data.indexOf(urlObj);
            const parsed = JSON.parse(urlObj);
            let pdfFile = fileArray[index];
            let binary = window.atob(pdfFile.split(',')[1]);
            let array = [];
            for (let i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            let blobData = new Blob([new Uint8Array(array)], {
                type: 'application/pdf',
            });

            await fetch(parsed.uploadURL, {
                method: 'PUT',
                body: blobData,
                headers: {
                    'Content-Type': 'application/pdf',
                },
            });
        }
    };

    const handleOpenModal = () => {
        setOpenArchiveModal(true);
    };

    const handleClose = () => {
        setOpenArchiveModal(false);
    };

    return {
        user,
        userList,
        shouldLoad,
        filteredRowData,
        onGridReady,
        openDocument,
        getValuesAsync,
        handleUploadInvoices,
        handleOpenModal,
        openArchiveModal,
        handleClose,
        openSettings,
        setOpenSettings,
        gridRef,
    };
};

export default useDocumentListView;
