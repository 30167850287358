import React from 'react';
import { Link } from 'react-router-dom';
import './ResetPassword.css';
import { FaCheckSquare } from 'react-icons/fa';

const ResetPasswordSuccess = () => {
    return (
        <div className="ResetPassword">
            <div className="success">
                <FaCheckSquare />
                <p>Your password has been reset.</p>
                <p>
                    <Link to="/login">
                        Click here to login with your new credentials.
                    </Link>
                </p>
            </div>
        </div>
    );
};

export default ResetPasswordSuccess;
