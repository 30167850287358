import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getCustomerProductRecommendations } from '../../../../store/pricingAnalysisTool';
import { GridReadyEvent } from 'ag-grid-community';
import {
    currencyFormatter,
    percentFormatter,
} from '../../../../utils/formattingUtils';
import {
    AtTimeOfRecommendation,
    ResultingMargin,
} from '../../../../types/PricingAnalysis.types';
import { RootState } from '../../../../store';

const useRecommendationGrid = ({
    externalCustomerId = null,
    productId = null,
}) => {
    const dispatch = useDispatch();
    const [rowData, setRowData] = useState([]);
    const { pricingAnalysisToolSet } = useSelector(
        (state: RootState) => state.pricingAnalysisTool
    );

    useEffect(() => {
        if (!pricingAnalysisToolSet.activeCustomerProductRecommendations) {
            dispatch(
                getCustomerProductRecommendations(externalCustomerId, productId)
            );
        } else {
            setRowData(
                pricingAnalysisToolSet.activeCustomerProductRecommendations
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricingAnalysisToolSet.activeCustomerProductRecommendations]);

    const onGridReady = (params: GridReadyEvent) => {
        setRowData(pricingAnalysisToolSet.activeCustomerProductRecommendations);
    };

    const AtTimeOfRecommendation = (props: AtTimeOfRecommendation) => {
        return (
            <span>{`${currencyFormatter(
                props.data.priceAtTimeOfRecommendation
            )} / ${currencyFormatter(
                props.data.costAtTimeOfRecommendation
            )}`}</span>
        );
    };

    const ResultingMargin = (props: ResultingMargin) => {
        return percentFormatter(
            ((Number(props.data.recommendedPrice) -
                Number(props.data.costAtTimeOfRecommendation)) /
                Number(props.data.recommendedPrice)) *
                100
        );
    };
    return { rowData, onGridReady, ResultingMargin, AtTimeOfRecommendation };
};
export default useRecommendationGrid;
