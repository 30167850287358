import { useDispatch, useSelector } from 'react-redux';
import { updateWarehouseId } from '../../../store/scheduler';
import { RootState } from '../../../store';

interface HeaderControlsProps {
    onDateChange: (param: any) => void;
    onViewChange: (param: any) => void;
    onZoomIn: () => void;
    onZoomOut: () => void;
    onRefresh: () => void;
}

const useHeaderControls = ({
    onDateChange,
    onViewChange,
    onZoomIn,
    onZoomOut,
    onRefresh,
}: HeaderControlsProps) => {
    const dispatch = useDispatch();
    const { schedulerDataSet } = useSelector(
        (state: RootState) => state.scheduler
    );

    const onWarehouseChange = (selectedValue: any) => {
        const warehouseId = selectedValue
            ? selectedValue.WarehouseNumber
            : null;
        dispatch(updateWarehouseId(warehouseId));
        onRefresh();
    };
    return {
        onWarehouseChange,
        schedulerDataSet,
        onZoomOut,
        onZoomIn,
        onViewChange,
        onDateChange,
    };
};
export default useHeaderControls;
