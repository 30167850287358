import { datacorAppApi } from '../datacorAppApi';
import { UserListUtils } from '../../utils/users/userListUtils';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../serviceInterfaces';

export const attachmentsAPI = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        listAttachmentSources: build.query<any, void>({
            query: () => `/attachments/attachment-sources`,
            providesTags: ['AttachmentsSources'],
        }),
        createAttachment: build.mutation<any, any>({
            query: (args) => ({
                url: `/attachments/attachment`,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['Attachments'],
        }),
        getAttachment: build.query<any, string>({
            query: (id) => `/attachments/attachment/${id}?full=true`,
            providesTags: ['Attachments'],
            transformResponse: (response: any) => {
                if (response.attachmentControl?.approvers) {
                    UserListUtils.extractAndInjectUserListFields(
                        response,
                        'attachmentControl',
                        'approvers',
                        'approversGroupId',
                        'approversUserIds'
                    );
                }

                if (response.attachmentControl?.distributionUsers) {
                    UserListUtils.extractAndInjectUserListFields(
                        response,
                        'attachmentControl',
                        'distributionUsers',
                        'distributionGroupId',
                        'distributionUserIds'
                    );
                }

                if (
                    response.attachmentControl?.attachmentPermission
                        ?.attachmentPermissionMappings
                ) {
                    for (const mapping of response.attachmentControl
                        .attachmentPermission.attachmentPermissionMappings) {
                        UserListUtils.extractAndInjectUserListFields(
                            mapping,
                            '',
                            'userList',
                            'groupId',
                            'userIds'
                        );
                    }
                }

                return response;
            },
        }),
        getUrlByKey: build.query<any, string>({
            query: (key) => `/attachments/attachment-url/${key}`,
            providesTags: ['Attachments'],
        }),
        startUpload: build.mutation<any, void>({
            query: () => ({
                url: `/attachments/upload/start`,
                method: 'POST',
            }),
        }),
        uploadAttachment: build.mutation<any, any>({
            query: (args) => ({
                url: `/attachments/upload`,
                method: 'POST',
                body: args.postBody,
            }),
        }),
        searchAttachments: build.mutation<any, any>({
            query: (args) => ({
                url: `/attachments/attachment/search`,
                method: 'POST',
                body: args.postBody,
            }),
            transformResponse: (response: any) => {
                return response?.sort((a: any, b: any) => {
                    //sort by date created
                    return (
                        new Date(b.createdAt).getTime() -
                        new Date(a.createdAt).getTime()
                    );
                });
            },
            invalidatesTags: ['Attachments'],
        }),
        updateAttachment: build.mutation<any, any>({
            query: (args) => ({
                url: `/attachments/attachment/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['Attachments', 'AttachmentFolders'],
        }),
        deleteAttachment: build.mutation<any, string>({
            query: (id) => ({
                url: `/attachments/attachment/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Attachments'],
        }),
        updateAttachmentGraph: build.mutation<any, any>({
            query: (args) => ({
                url: `/attachments/attachment-graph/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: [
                'Attachments',
                'AttachmentsControls',
                'AttachmentsPermissions',
            ],
        }),
        updateAttachmentControl: build.mutation<any, any>({
            query: (args) => ({
                url: `/attachments/attachment-control/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['AttachmentsControls'],
        }),
        updateAttachmentPermission: build.mutation<any, any>({
            query: (args) => ({
                url: `/attachments/attachment-permission/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['AttachmentsPermissions'],
        }),
        createAttachmentPermissionMapping: build.mutation<any, any>({
            query: (args) => ({
                url: `/attachments/attachment-permission-mapping`,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['AttachmentsPermissions'],
        }),
        updateAttachmentPermissionMapping: build.mutation<any, any>({
            query: (args) => ({
                url: `/attachments/attachment-permission-mapping/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['AttachmentsPermissions'],
        }),
        createAttachmnetAlias: build.mutation<any, any>({
            query: (args) => ({
                url: `/attachments/attachment-alias`,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['AttachmentsAliases'],
        }),
        updateAttachmentAlias: build.mutation<any, any>({
            query: (args) => ({
                url: `/attachments/attachment-alias/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['AttachmentsAliases'],
        }),
        createAttachmentFileVersion: build.mutation<any, any>({
            query: (args) => ({
                url: `/attachments/attachment-file-version`,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['AttachmentsFileVersions'],
        }),
        getAttachmentSearchParams: build.query<any, void>({
            query: () => ({
                url: `/attachments/search-params`,
            }),
            providesTags: ['AttachmentsSources', 'EntityTypes'],
            transformResponse: (response: any): any => {
                const transformed: any = {};
                if (response?.entityTypes) {
                    transformed.entityTypes = response?.entityTypes.map(
                        (entityType: any) => {
                            return {
                                value: entityType.id,
                                label: entityType.name,
                            };
                        }
                    );
                }

                if (response?.attachmentSources) {
                    transformed.attachmentSources =
                        response?.attachmentSources.map(
                            (attachmentSource: any) => {
                                return {
                                    value: attachmentSource.id,
                                    label: attachmentSource.name,
                                };
                            }
                        );
                }
                return transformed;
            },
        }),
        attachmentAccessCheck: build.query<boolean, any>({
            query: (args: any) =>
                `/attachments/attachment-access-check/${args.id}?accessType=${args.accessType}`,
            providesTags: ['Attachments'],
        }),
        createAttachmentFolder: build.mutation<
            AttachmentFolder,
            BaseCreateEntityArgs<AttachmentFolder>
        >({
            query: (args) => ({
                url: `/attachments/attachment-folder`,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['AttachmentFolders'],
        }),
        updateAttachmentFolder: build.mutation<AttachmentFolder, any>({
            query: (args) => ({
                url: `/attachments/attachment-folder/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['AttachmentFolders'],
        }),
        deleteAttachmentFolder: build.mutation<AttachmentFolder, string>({
            query: (id) => ({
                url: `/attachments/attachment-folder/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AttachmentFolders', 'Attachments'],
        }),
        getAttachmentFolder: build.query<AttachmentFolder, string>({
            query: (id) => `/attachments/attachment-folder/${id}`,
            providesTags: ['AttachmentFolders', 'Attachments'],
        }),
        listAttachmentFolders: build.query<AttachmentFolder[], void>({
            query: () => `/attachments/attachment-folders`,
            providesTags: ['AttachmentFolders', 'Attachments'],
        }),
        checkoutAttachment: build.mutation<any, any>({
            query: (id: string) => ({
                url: `/attachments/checkout/${id}`,
                method: 'PUT',
            }),
            invalidatesTags: ['Attachments'],
        }),
        checkinAttachment: build.mutation<any, any>({
            query: (id: string) => ({
                url: `/attachments/checkin/${id}`,
                method: 'PUT',
            }),
            invalidatesTags: ['Attachments'],
        }),
        listCheckedOut: build.query<any, void>({
            query: () => `/attachments/checked-out`,
            providesTags: ['Attachments'],
        }),
        listTotals: build.query<any, void>({
            query: () => `/attachments/totals`,
            providesTags: ['Attachments'],
        }),
    }),
});

export const {
    useListAttachmentSourcesQuery,
    useCreateAttachmentMutation,
    useGetAttachmentQuery,
    useDeleteAttachmentMutation,
    useGetUrlByKeyQuery,
    useUploadAttachmentMutation,
    useStartUploadMutation,
    useSearchAttachmentsMutation,
    useUpdateAttachmentMutation,
    useUpdateAttachmentGraphMutation,
    useUpdateAttachmentControlMutation,
    useUpdateAttachmentPermissionMutation,
    useCreateAttachmentPermissionMappingMutation,
    useUpdateAttachmentPermissionMappingMutation,
    useCreateAttachmnetAliasMutation,
    useUpdateAttachmentAliasMutation,
    useCreateAttachmentFileVersionMutation,
    useGetAttachmentSearchParamsQuery,
    useAttachmentAccessCheckQuery,
    useCreateAttachmentFolderMutation,
    useUpdateAttachmentFolderMutation,
    useDeleteAttachmentFolderMutation,
    useGetAttachmentFolderQuery,
    useListAttachmentFoldersQuery,
    useCheckoutAttachmentMutation,
    useCheckinAttachmentMutation,
    useListCheckedOutQuery,
    useListTotalsQuery,
} = attachmentsAPI;

export type AttachmentFileVersion = {
    id: bigint;
    versionNumber: number;
    createdAt: string;
    updatedAt: string;
    fileHash: string;
};
export interface Attachment {
    id: bigint;
    uuid: string;
    attachmentSourceId: bigint;
    entityTypeId: bigint;
    businessEntityId: bigint;
    name: string;
    extension: string;
    entityUuid: string;
    controlled: boolean;
    versioned: boolean;
    fileDeleted: boolean;
    alias: boolean;
    notes: string;
    createdAt: string;
    updatedAt: string;
    createdBy: string;
    createdByUser: {
        firstName: string;
        lastName: string;
    };
    updatedBy: string;
    updatedByUser: {
        firstName: string;
        lastName: string;
    };
    attachmentFileVersions?: AttachmentFileVersion[];
    attachmentControl?: AttachmentControl;
}

export type AttachmentControl = {
    id: bigint;
    attachmentPermission?: AttachmentPermission;
    controlNumber: string;
    authorsUserIds: number[];
    authorsGroupId: number;
    authorNotes: string;
    approversUserIds: number[];
    approversGroupId: number;
    reviewScheduleDays: number;
    distributionUserIds: number[];
    distributionGroupId: number;
    locked: boolean;
    checkedOutByUserId: number;
};

export type AttachmentPermission = {
    id: bigint;
    allowRead: boolean;
    allowWrite: boolean;
    ownedByUserId: number;
    attachmentPermissionMappings?: AttachmentPermissionMapping[];
};

export type AttachmentPermissionMapping = {
    id: bigint;
    allowRead: boolean;
    allowWrite: boolean;
    allowOwn: boolean;
    userIds: number[];
    groupId: number;
};

export type EntityAttachmentSummary = {
    entityUuid: string;
    attachments: Attachment[];
};

export type AttachmentFolder = {
    id?: bigint;
    uuid?: string;
    attachmentFolders?: AttachmentFolder[];
    businessEntityId?: bigint;
    attachmentPermission?: {
        id: bigint;
        allowRead: boolean;
        allowWrite: boolean;
        ownedByUserId: number;
    };
    name: string;
    notes?: string;
    parentFolderId?: number;
    createdAt?: string;
    updatedAt?: string;
    createdBy?: string;
    updatedBy?: string;
    attachments?: Attachment[];
};
