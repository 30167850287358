import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Step, Workcenter } from '../../../types/BatchTicket.types';
import { RootState } from '../../../store';

interface EventDisplayProps {
    eventInfo: any;
    type: any;
}

const useEventDisplay = ({ eventInfo, type }: EventDisplayProps) => {
    const event = type === 'scheduled' ? eventInfo.event : eventInfo;
    const { schedulerDataSet } = useSelector(
        (state: RootState) => state.scheduler
    );
    const stepDetails = schedulerDataSet.allSteps.find(
        (step: Step) => step.BatchNumber + '-' + step.StepNumber === event.id
    );
    const workcenterInfo = schedulerDataSet.workcenters.find(
        (workcenter: Workcenter) => workcenter.Id === stepDetails.Workcenter
    );
    const [showStepInfoModal, setShowStepInfoModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const handleStepInfoModalOpen = () => {
        setShowStepInfoModal(true);
        setSelectedEvent(event);
    };

    const handleStepInfoModalClose = () => {
        setShowStepInfoModal(false);
    };
    return {
        event,
        handleStepInfoModalClose,
        handleStepInfoModalOpen,
        selectedEvent,
        showStepInfoModal,
        workcenterInfo,
        stepDetails,
    };
};
export default useEventDisplay;
