import React from 'react';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import SelectDropdown from '../../../components/form/selectDropdown/SelectDropdown';
import useIsActiveCellRenderer from '../hooks/ApprovalWorkflows/useIsActiveCellRenderer';

interface isActiveCellRendererProps {
    data: any;
    rowIndex: number;
}

const isActiveCellRenderer = ({
    data,
    rowIndex,
}: isActiveCellRendererProps) => {
    const { user, approvalWorkflows, updateWorkflow } = useIsActiveCellRenderer(
        { data, rowIndex }
    );

    return (
        <SelectDropdown
            isDisabled={
                !PermissionsUtil.isPermissionEnabled(
                    user.permissions,
                    PERMISSIONS.AP_AUTOMATION.APPROVAL_WORKFLOWS.UPDATE
                )
            }
            menuPortalTarget={document.getElementById(
                'approval-workflows-modal'
            )}
            menuPlacement={
                rowIndex >= approvalWorkflows.length / 2 ? 'top' : 'bottom'
            }
            value={data.isActive.toString()}
            options={[
                { value: true, label: 'Yes' },
                { value: false, label: 'No' },
            ]}
            onChange={updateWorkflow}
            menuPosition={'fixed'}
        />
    );
};

export default isActiveCellRenderer;
