import React from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Box, FormControl } from '@mui/material';

const ProductDropdown = () => {
    const [currentProduct, setCurrentProduct] = React.useState('10');

    const handleChange = (event: SelectChangeEvent) => {
        setCurrentProduct(event.target.value as string);
    };
    return (
        <Box sx={{ minWidth: 50, maxWidth: 150 }}>
            <FormControl fullWidth>
                <Select
                    variant="standard"
                    value={currentProduct}
                    label="Product Select..."
                    disableUnderline
                    disabled
                    onChange={handleChange}>
                    <MenuItem value={10}>Cloud</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
};

export default ProductDropdown;
