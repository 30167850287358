import React, { useEffect } from 'react';
import InputBase, { InputBaseProps } from '../InputBase/InputBase';
import { MaxLengthValidator } from '../../../../validators/maxLength.validator';
import { MinLengthValidator } from '../../../../validators/minLength.validator';
import { IsUniqueValidator } from '../../../../validators/isUnique.validator';
import _ from 'lodash';
import useFormControlProps from '../hooks/useFormControlProps';

export type TextInputProps = {
    minLength?: number;
    maxLength?: number;
    isUnique?: boolean;
    uniqueList?: any[];
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
} & InputBaseProps;

const TextInput = ({ stateful = true, ...props }: TextInputProps) => {
    const { formMethods, handleFieldChange, value, label } =
        useFormControlProps(props);

    useEffect(() => {
        //The idea here is that if the label is translatable
        //but we don't have the termSet loaded yet
        //we don't want to set validations
        if (label?.props && !label?.props.termSet) {
            return;
        }
        if (props.maxLength && label) {
            formMethods?.addValidator(
                props.id,
                _.isObject(label) ? <>{label}</> : label?.toString(),
                MaxLengthValidator(props.maxLength)
            );
        } else {
            formMethods?.removeValidator(
                props.id,
                MaxLengthValidator(props.maxLength)
            );
        }
    }, [props.maxLength, label?.props?.termSet]);

    useEffect(() => {
        if (label?.props && !label?.props.termSet) {
            return;
        }
        if (props.minLength && label) {
            formMethods?.addValidator(
                props.id,
                _.isObject(label) ? <>{label}</> : label?.toString(),
                MinLengthValidator(props.minLength)
            );
        } else {
            formMethods?.removeValidator(
                props.id,
                MinLengthValidator(props.minLength)
            );
        }
    }, [props.minLength, label?.props?.termSet]);

    useEffect(() => {
        if (label?.props && !label?.props.termSet) {
            return;
        }
        if (props.isUnique && props.uniqueList && label) {
            formMethods?.addValidator(
                props.id,
                _.isObject(label) ? <>{label}</> : label?.toString(),
                IsUniqueValidator(props.uniqueList)
            );
        } else {
            formMethods?.removeValidator(
                props.id,
                IsUniqueValidator(props.uniqueList)
            );
        }
    }, [props.isUnique, props.uniqueList, label?.props?.termSet]);

    return (
        <InputBase
            onBlur={props.onBlur}
            {...props}
            formMethods={formMethods}
            onChange={handleFieldChange}
            value={value}
            label={label}
            stateful={stateful}
        />
    );
};

export default TextInput;
