import React from 'react';
import { IAccountingNav } from './Accounting.types';
import { ISupplierNav } from './Supplier.types';
import { IFormulationNav } from './formulation';
import { IBankNav } from './accounting';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
    MutationDefinition,
} from '@reduxjs/toolkit/query';
import { RowStatus } from '../components/grids/hooks/useBaseGridEditable';
import { ICustomerNav } from './supply-chain/customer/CustomerAddress.type';

export interface StorageResponse {
    key?: string;
}

export interface SelectionOption {
    id?: bigint;
    label: string;
    value: any;
    object?: any;
    description?: string;
    hiddenOption?: boolean;
}

export interface MutationError {
    status: number;
    data: any;
}

export const NumberMaxValues = {
    smallint: 32767,
    integer: 2147483647,
    decimal192: 99999999999999999.99,
    decimal206: 999999999999999999.999999,
};

export enum RoutingValues {
    newId = 'new',
    copy = 'copy',
}

export enum FunctionGroupValues {
    accounting = 'Accounting',
    fixedAssets = 'Fixed Assets',
}

export interface ValidationRuleResult {
    isValid: boolean;
    getErrorMessage: string;
    fieldId?: string;
}

export interface EntityBase {
    id?: bigint;
    uuid?: string;
    createdAt?: Date;
    updatedAt?: Date;
    createdBy?: bigint;
    updatedBy?: bigint;
    businessEntityId?: bigint;
}

export interface EntityError {
    errorMessage: string;
    errorFieldId?: string;
    additionalErrorMessage?: string;
}

export interface ErrorEntity {
    hasError?: boolean;
    errors?: EntityError[];
    validationRules?: ValidationRuleResult[];
}

export interface EditableGridEntity extends ErrorEntity, EntityBase {
    rowStatus?: string;
    rowId?: number;
    autoPopulated?: boolean;
    // similar to rowStatus but will not change after initial set,
    // giving a reliable way to differentiate added rows from updated rows.
    // currently rowStatus='added' can be either a new row or an existing updated row.
    rowInitialStatus?: string;
}

export interface SelectedDropdownOption {
    id: bigint;
    display?: string;
    name?: string;
}

export interface CountryData {
    name?: string;
    dialCode?: string;
    countryCode?: string;
}

export interface PhoneNumberData {
    phoneNumber?: string;
    countryCode?: string;
}

export interface INavForm {
    sharedNav: {
        isCloseFormValid?: boolean;
        hasEntityChanges?: boolean;
        tabIndex?: number;
    };
    accountingNav?: IAccountingNav;
    supplierNav?: ISupplierNav;
    customerNav?: ICustomerNav;
    formulationNav?: IFormulationNav;
    setNavFormValue?: (navFormValues: INavForm) => void;
    bankNav?: IBankNav;
}

export interface MutationAction
    extends MutationTrigger<
        MutationDefinition<
            any,
            BaseQueryFn<
                string | FetchArgs,
                unknown,
                FetchBaseQueryError,
                {},
                FetchBaseQueryMeta
            >,
            string,
            any,
            'datacorAppApi'
        >
    > {}

export interface PaginateGridProps {
    gridRefresh: () => void;
    setFilters: (filters: any) => void;
    isLoadingData: () => boolean;
}

export enum ChipType {
    chip,
    input,
}

export interface ChipEntity {
    id: number;
    label: string;
    enableOnClick: boolean;
    focusInput?: boolean;
    chipType?: ChipType;
    chipStatus?: RowStatus;
    color?:
        | 'default'
        | 'primary'
        | 'secondary'
        | 'error'
        | 'info'
        | 'success'
        | 'warning'
        | string;
    variant?: 'filled' | 'outlined' | string;
    deleteIcon?: React.ReactElement;
    useCustomDelete?: boolean;
}

export interface AuditableSearchSectionFields {
    createdAtStart?: Date;
    createdAtEnd?: Date;
    updatedAtStart?: Date;
    updatedAtEnd?: Date;
    createdBy?: number;
    updatedBy?: number;
}

export interface OverrideFormMessageOptions {
    overrideMessage: string;
    useDefaultMessage: boolean;
}
