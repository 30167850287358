import { datacorAppApi } from '../datacorAppApi';

export const sequelizeAPI = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        listPlatformMigrations: build.query<any, void>({
            query: () => `/platform-utils/migrations`,
            providesTags: ['Migrations'],
        }),
        listAccountingMigrations: build.query<any, void>({
            query: () => `/accounting-utils/migrations`,
            providesTags: ['Migrations'],
        }),
        listFormMigrations: build.query<any, void>({
            query: () => `/formulation-utils/migrations`,
            providesTags: ['Migrations'],
        }),
        listSupplyChainMigrations: build.query<any, void>({
            query: () => `/supply-chain/migrations`,
            providesTags: ['Migrations'],
        }),
    }),
});

export const {
    useListPlatformMigrationsQuery,
    useListFormMigrationsQuery,
    useListAccountingMigrationsQuery,
    useListSupplyChainMigrationsQuery,
} = sequelizeAPI;
