import React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { currencyFormatter } from '../../utils/formattingUtils';
import { themeConfig } from '../../styles/theme/themeConfig';
import useDollarsLostByPricingConcernSummary from './hooks/useDollarsLostByPricingConcernSummary';

const DollarsLostByPricingConcernSummary = () => {
    const { chartData } = useDollarsLostByPricingConcernSummary();

    return (
        <Card className="summaryGrids">
            <CardHeader
                title="Dollars Lost by Pricing Concern"
                style={{
                    backgroundColor: themeConfig.PRIMARY.main,
                    height: 50,
                    color: 'white',
                }}
            />
            <CardContent>
                <ResponsiveContainer width={'100%'} height={250}>
                    <LineChart
                        data={chartData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}>
                        <CartesianGrid />
                        <XAxis dataKey="fromDate" height={30} />
                        <YAxis
                            tickFormatter={(value) =>
                                currencyFormatter(value, 0)
                            }
                        />
                        <Tooltip
                            formatter={(value: any) => currencyFormatter(value)}
                        />
                        <Legend />
                        <Line
                            type="monotone"
                            dataKey="Outdated Pricing"
                            stroke="#3358FF"
                        />
                        <Line
                            type="monotone"
                            dataKey="Pricing Lag"
                            stroke="#FFC300"
                        />
                        <Line
                            type="monotone"
                            dataKey="Below Average Margin"
                            stroke="#FF5733"
                        />
                        <Line
                            type="monotone"
                            dataKey="Margin Below Goal"
                            stroke="#581845"
                        />
                        <Line
                            type="monotone"
                            dataKey="Price Below Average"
                            stroke="#C70039"
                        />
                    </LineChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
};

export default DollarsLostByPricingConcernSummary;
