import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormFields } from '../../../libs/hooksLib';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { signup } from '../../../store/user';
import { showNotificationError } from '../../../libs/errorLib';

const useSignup = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [fields, handleFieldChange] = useFormFields({
        email: '',
        password: '',
        confirmPassword: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    function validateForm() {
        return (
            !isNilOrEmpty(fields.email) &&
            !isNilOrEmpty(fields.password) &&
            fields.password === fields.confirmPassword
        );
    }

    const signupEvent = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        setIsLoading(true);

        try {
            await dispatch(signup(fields));
            navigate('/signupConfirmation');
        } catch (error) {
            showNotificationError(error, 'error', dispatch);
            setIsLoading(false);
        }
    };
    return { fields, handleFieldChange, isLoading, validateForm, signupEvent };
};

export default useSignup;
