import { useEffect, useState } from 'react';
import {
    useCreateStreamingSessionMutation,
    useGetAppStreamingUrlQuery,
} from '../../../services/appStreaming/appStreaming.service';
import { AppStream } from '../../../types/AppStreaming.types';
import { skipToken } from '@reduxjs/toolkit/query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { showNotificationError } from '../../../libs/errorLib';

const useAppStreaming = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);

    const [streamingAppUrls, setStreamingAppUrls] = useState([]);
    const { data: appStreamData, isLoading: isLoadingAppStream } =
        useGetAppStreamingUrlQuery(user.email ? user.email : skipToken);
    const [createStreamingSession] = useCreateStreamingSessionMutation();

    useEffect(() => {
        if (!appStreamData) {
            return;
        }
        const getStreamingAppUrls = async () => {
            try {
                const availableAppStreams = appStreamData?.filter(
                    (appStream: AppStream) =>
                        appStream.StreamingURLResponse !== undefined
                );
                setStreamingAppUrls(availableAppStreams);
            } catch (e) {
                return showNotificationError(e, 'error', dispatch);
            }
        };

        getStreamingAppUrls();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingAppStream, appStreamData]);

    const openTabToStreamApp = async (streamingInfo: AppStream) => {
        const streamingUrl = streamingInfo.StreamingURLResponse.StreamingURL;
        window.open(streamingUrl, '_blank');

        createStreamingSession(streamingInfo);
    };
    return { streamingAppUrls, isLoadingAppStream, openTabToStreamApp };
};
export default useAppStreaming;
