//We can add all of our grid layout/other various settingKeys here.
export const GRID_SETTINGS = {
    USER_GRID: 'USERS_GRID_SETTINGS',
    APA_DOCUMENT_GRID: 'APA_DOCUMENT_GRID',
};

export const SETTINGS = {
    USER: 'USER_SETTING',
    BUSINESS_ENTITY: 'BUSINESS_ENTITY_SETTING',
    FORMULATION: 'FORMULATION_SETTING',
    ACCOUNTING: 'ACCOUNTING_SETTING',
};
