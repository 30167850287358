import { GridDefaults } from './Grid.constants';
import { AgGridReact } from 'ag-grid-react';
import React, { ForwardedRef } from 'react';
import './style.css';
import SelectablePopupGrid, {
    RenderCellValue,
} from './cellRenderers/selectablePopupGrid.render';
import EditableGridErrors from './EditableGridErrors';
import BaseGridToolbar from './BaseGridToolbar';
import GridPopovers from './GridPopovers';
import { BaseGridProps } from './Grids.propTypes';
import { RowNode } from 'ag-grid-enterprise';
import useBaseGrid from './hooks/useBaseGrid';
import useBaseGridState from './hooks/useBaseGridState';
import {
    FirstDataRenderedEvent,
    GridReadyEvent,
    PaginationChangedEvent,
} from 'ag-grid-community';

const BaseGrid = React.forwardRef(
    (props: BaseGridProps, ref?: ForwardedRef<any>) => {
        const {
            gridRef,
            display,
            helpers,
            helperMethods,
            editableGrid,
            popoutGrid,
            entitySettings,
            children,
            sortGrid,
            colDefOptions,
        } = useBaseGrid(props, ref);

        const { storeGridState, applyGridState, applyPageState } =
            useBaseGridState(gridRef, props.gridKey, props.disableGridState);

        const onGridReady = (params: GridReadyEvent) => {
            helperMethods.onGridReady(params);
            applyGridState();
        };

        const onFirstDataRendered = (params: FirstDataRenderedEvent) => {
            helperMethods.onFirstDataRendered(params);
            applyPageState();
        };

        const onPaginationChanged = (params: PaginationChangedEvent) => {
            if (params.newPage) {
                storeGridState();
            }
        };

        /**
         * Gets all AgGrid rows
         */
        const getAllRows = (): any[] => {
            let rowData: any = [];
            gridRef?.current?.api?.forEachNode((node: RowNode) =>
                rowData.push(node.data)
            );
            return rowData;
        };

        return (
            <>
                {display.displayGrid && (
                    <>
                        <EditableGridErrors editableGridProps={editableGrid} />
                        <div
                            className={
                                'ag-theme-alpine ' + (helpers.gridClass || '')
                            }
                            style={helpers.gridStyle}
                            data-testid={helpers.testId}>
                            {display.displayToolbar && (
                                <BaseGridToolbar
                                    customFormTermSet={props.customFormTermSet}
                                    displayProps={display}
                                    helperMethods={helperMethods}
                                    editableGrid={editableGrid}
                                    popoutGrid={popoutGrid}
                                    entitySettings={entitySettings}
                                    childrenProps={children}
                                    useRowDrag={props.useRowDrag}
                                    sortGrid={sortGrid}
                                    getAllRows={getAllRows}
                                    rowData={props.rowData}
                                    helpers={helpers}
                                    showDeleteButton={props.columnDefs?.some(
                                        (col: any) =>
                                            Object.values(col).includes(
                                                'deleteColumn'
                                            )
                                    )}
                                />
                            )}
                            <AgGridReact
                                {...props}
                                defaultColDef={
                                    colDefOptions.defaultColDefOptions
                                }
                                rowData={
                                    props.rowData?.filter(
                                        (row: any) =>
                                            row?.rowStatus !== 'deleted'
                                    ) || []
                                }
                                animateRows={props.useRowDrag}
                                onRowDragMove={
                                    !props.onRowDragEnd &&
                                    helperMethods.handleRowDrag
                                }
                                rowSelection={
                                    props.isEditable &&
                                    props.columnDefs?.some((col: any) =>
                                        Object.values(col).includes(
                                            'deleteColumn'
                                        )
                                    )
                                        ? 'multiple'
                                        : props.rowSelection
                                }
                                onRowDragEnd={
                                    props.onRowDragEnd ||
                                    helperMethods.onRowDragEnd
                                }
                                onGridSizeChanged={
                                    helperMethods.onGridSizeChanged
                                }
                                onRowDataChanged={(params: any) => {
                                    props.isEditable &&
                                        editableGrid.handleValidationOnDataChange(
                                            params
                                        );
                                    if (props?.onRowDataChanged) {
                                        props?.onRowDataChanged(params);
                                    }

                                    if (
                                        gridRef.current?.api.paginationGetTotalPages() >
                                            1 &&
                                        props.useRowDrag
                                    ) {
                                        gridRef.current?.api.setColumnDefs(
                                            gridRef.current?.api
                                                .getColumnDefs()
                                                .filter((col: any) => {
                                                    return (
                                                        col.field !== 'dragRow'
                                                    );
                                                })
                                        );
                                    }
                                }}
                                frameworkComponents={{
                                    cellEditor: (params: any) => (
                                        <SelectablePopupGrid
                                            params={params}
                                            context={helpers.context}
                                            applyFilter={
                                                editableGrid.singleSelectApplyFilter !==
                                                false
                                            }
                                        />
                                    ),
                                    cellRender: RenderCellValue,
                                    ...helpers.frameworkComponents,
                                }}
                                domLayout={helpers.domLayout}
                                accentedSort={GridDefaults.accentedSort}
                                ref={gridRef}
                                immutableData={helpers.hasImmutableData}
                                getRowNodeId={
                                    helpers.dataKey
                                        ? (data) => data[helpers.dataKey]
                                        : null
                                }
                                tooltipShowDelay={helpers.tooltipShowDelay}
                                pagination={helpers.pagination}
                                paginationPageSize={helpers.paginationPageSize}
                                pinnedBottomRowData={
                                    helpers.displaySummaryRow
                                        ? helpers.pinnedBottomRowData
                                        : []
                                }
                                onGridReady={onGridReady}
                                onFirstDataRendered={onFirstDataRendered}
                                onPaginationChanged={onPaginationChanged}
                                onSortChanged={storeGridState}
                                onFilterModified={storeGridState}
                                getContextMenuItems={
                                    props?.getContextMenuItems
                                        ? props?.getContextMenuItems
                                        : helperMethods.getContextMenuItems
                                }>
                                {children.children}
                            </AgGridReact>
                            <GridPopovers
                                popoutGrid={popoutGrid}
                                entitySettings={entitySettings}
                                display={display}
                                sortGrid={sortGrid}
                                gridRef={gridRef}
                                useRowDrag={props.useRowDrag}
                                parentProps={props}
                            />
                        </div>
                    </>
                )}
            </>
        );
    }
);
BaseGrid.defaultProps = {
    domLayout: GridDefaults.domLayout as 'normal' | 'autoHeight' | 'print',
    tooltipShowDelay: GridDefaults.tooltipShowDelay,
    pagination: GridDefaults.pagination,
    paginationPageSize: GridDefaults.paginationPageSize,
    sizeColumnsToFit: GridDefaults.sizeColumnsToFit,
    immutableData: GridDefaults.immutableData,
    displayToolbar: GridDefaults.displayToolbar,
};

export default BaseGrid;
