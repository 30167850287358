import SelectablePopupGrid, {
    RenderCellValue,
} from '../cellRenderers/selectablePopupGrid.render';

export const editableGridCellPopup = (params: any, context: any) => {
    return {
        cellRendererFramework: {
            cellEditor: () =>
                SelectablePopupGrid({ params: params, context: context }),
            cellRender: RenderCellValue,
        },
        cellRenderer: 'cellRender',
        cellEditor: 'cellEditor',
        cellEditorPopup: true,
    };
};
