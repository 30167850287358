import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import { HandleFieldChangedEvent } from '../../../../libs/hooksLib';
import useFormControlProps from '../hooks/useFormControlProps';

export interface CheckBoxInputProps {
    id: string;
    label?: string | React.ReactNode;
    checked?: boolean;
    onChange?: (event: HandleFieldChangedEvent) => void;
    required?: boolean;
    disabled?: boolean;
    termSetKey?: string;
}
const CheckBoxInput = (props: CheckBoxInputProps) => {
    const { formMethods, handleFieldChange, value, label } =
        useFormControlProps({ ...props, value: props.checked });

    return (
        <FormControlLabel
            label={label}
            control={
                <Checkbox
                    id={props.id}
                    checked={value || false}
                    onChange={handleFieldChange}
                    disabled={props.disabled || false}
                    required={props.required || false}
                />
            }
        />
    );
};

export default CheckBoxInput;
