import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { MonthlyDetails } from '../../../types/PricingAnalysis.types';
import moment from 'moment';
import { RootState } from '../../../store';

const useGrossMarginSummary = () => {
    const { pricingAnalysisToolSet } = useSelector(
        (state: RootState) => state.pricingAnalysisTool
    );
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        if (
            !pricingAnalysisToolSet.activeCumulativeSummaryIsLoading &&
            pricingAnalysisToolSet.activeCumulativeSummary
        ) {
            setChartData(
                pricingAnalysisToolSet.activeCumulativeSummary.monthlyDetails.map(
                    (detail: MonthlyDetails) => {
                        return {
                            fromDate: moment(detail.asOfDate).format(
                                'MM/DD/YYYY'
                            ),
                            Margin: Number(detail.overallMargin).toFixed(2),
                            Profit: (
                                Number(detail.profitDollars) / 1000
                            ).toFixed(2),
                            'Sales Dollars': (
                                Number(detail.salesDollars) / 1000
                            ).toFixed(2),
                        };
                    }
                )
            );
        }
    }, [
        pricingAnalysisToolSet.activeCumulativeSummaryIsLoading,
        pricingAnalysisToolSet.activeCumulativeSummary,
    ]);
    return { chartData };
};
export default useGrossMarginSummary;
