import { isNil } from '../objectUtils';
import { Permission } from '../../types/Settings.types';
import store from '../../store';

export const PermissionsUtil = {
    isPermissionEnabled: (
        userPermissions: Array<Permission>,
        permission: any
    ) => {
        const user = store.getState()?.user;
        if (isNil(permission) || user?.isDatacorAdmin || user?.isDatacorUser) {
            return true;
        }

        return userPermissions ? userPermissions.includes(permission) : false;
    },
    hasPermission: (permission: any) => {
        const user = store.getState()?.user;
        if (isNil(permission) || user?.isDatacorAdmin || user?.isDatacorUser) {
            return true;
        }

        return user ? user.permissions?.includes(permission) : false;
    },
};
