import React, { useState } from 'react';
import { useGetAllOpenUserTasksQuery } from '../../../../services/task-manager/task-manager.service';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';

const useTasks = () => {
    const [newTaskPopoverOpen, setNewTaskPopoverOpen] =
        useState<boolean>(false);
    const [tasksAchorEl, setTasksAnchorEl] = useState<any>(null);
    const [openTasksModal, setOpenTasksModal] = useState<any>(false);
    const [selectedTask, setSelectedTask] = useState<any>(null);
    const [isPersonal, setIsPersonal] = useState<boolean>(false);
    const [taskFields, setTaskFields] = useState<any>({
        title: '',
        notes: '',
        dueAt: '',
        taskTypeId: '',
        entityTypeId: '',
        entityUuid: '',
        taskUsers: [],
        taskGroups: [],
    });
    const { data: openTasks } = useGetAllOpenUserTasksQuery();

    const shouldSeeTasks = PermissionsUtil.hasPermission(
        PERMISSIONS.TASKS.VIEW
    );

    const handleClickTask = (event: React.MouseEvent<HTMLButtonElement>) => {
        setTasksAnchorEl(event.currentTarget);
        setOpenTasksModal(true);
    };

    return {
        shouldSeeTasks,
        handleClickTask,
        newTaskPopoverOpen,
        setNewTaskPopoverOpen,
        openTasksModal,
        setOpenTasksModal,
        selectedTask,
        setSelectedTask,
        isPersonal,
        setIsPersonal,
        taskFields,
        setTaskFields,
        openTasks,
        tasksAchorEl,
        setTasksAnchorEl,
    };
};

export default useTasks;
