import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    useCreateApprovalWorkflowMutation,
    useGetAllApprovalWorkflowsQuery,
    useUpdateApprovalWorkflowMutation,
} from '../../../../services/apAutomation/apAutomation.service';
import { useFormFields } from '../../../../libs/hooksLib';
import { RootState } from '../../../../store';
import { useGetUsersQuery } from '../../../../services/organizations/organizations.service';

const useApprovalWorkflowsForm = () => {
    const user = useSelector((state: RootState) => state.user);
    const [createApprovalWorkflow, { isLoading: isLoadingCreateWorkflows }] =
        useCreateApprovalWorkflowMutation();
    const [updateApprovalWorkflow] = useUpdateApprovalWorkflowMutation();
    const { data: approvalWorkflows, isLoading: isLoadingWorkflows } =
        useGetAllApprovalWorkflowsQuery();
    const { data: users, isLoading: isLoadingUsers } = useGetUsersQuery();
    const [fields, handleFieldChange] = useFormFields({
        workflowName: '',
    });
    const [newApprovers, setNewApprovers] = useState([]);
    const [rowData, setRowData] = useState([]);
    const shouldShowGrid = rowData.length > 0 && !isLoadingWorkflows;
    const noWorkflows = rowData.length <= 0 && !isLoadingWorkflows;

    useEffect(() => {
        if (isLoadingWorkflows) {
            return;
        }
        setRowData(approvalWorkflows);
        setNewApprovers([]);
        fields.workflowName = '';
    }, [approvalWorkflows]);

    const submitCreateApprovalWorkflow = async (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();

        const args = {
            approvers: newApprovers,
            workflowName: fields.workflowName,
        };
        createApprovalWorkflow(args);
    };

    return {
        fields,
        handleFieldChange,
        user,
        users,
        isLoadingUsers,
        newApprovers,
        setNewApprovers,
        submitCreateApprovalWorkflow,
        isLoadingCreateWorkflows,
        shouldShowGrid,
        rowData,
        updateApprovalWorkflow,
        noWorkflows,
    };
};

export default useApprovalWorkflowsForm;
