import { datacorAppApi } from '../datacorAppApi';

export const systemApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        listApplications: build.query<Array<Application>, void>({
            query: () => ({
                url: `/platform-system/applications`,
                method: 'GET',
            }),
            providesTags: ['Applications'],
        }),
        listSystems: build.query<any, void>({
            query: () => ({
                url: `/platform-system/systems`,
                method: 'GET',
            }),
            providesTags: ['Systems'],
        }),
        listServices: build.query<any, void>({
            query: () => ({
                url: `/platform-system/services`,
                method: 'GET',
            }),
            providesTags: ['Services'],
        }),
    }),
});

export const {
    useListApplicationsQuery,
    useListServicesQuery,
    useListSystemsQuery,
} = systemApi;

export interface Application {
    id: bigint;
    code: string;
    name: string;
    description?: string;
}
