import {
    Card,
    CardHeader,
    CardContent,
    Typography,
    Grid,
    Tooltip,
} from '@mui/material';
import React from 'react';
import './Notifications.css';
import EventDisplay from './EventDisplay';
import { isNilOrEmpty } from '../../utils/objectUtils';
import useNotifications from './hooks/useNotifications';

const Notifications = () => {
    const {
        unscheduledSteps,
        schedulingErrors,
        filteredUnscheduledSteps,
        filterItems,
    } = useNotifications();

    return !isNilOrEmpty(unscheduledSteps) ||
        !isNilOrEmpty(schedulingErrors) ? (
        <Grid container spacing={2}>
            <Grid item xs={2}>
                {!isNilOrEmpty(unscheduledSteps) ? (
                    <Card className={'mb-2'}>
                        <CardHeader title="Unscheduled Batch Steps" />
                        <CardContent
                            id="external-events"
                            className={'unscheduled-notification-card'}>
                            <Tooltip
                                placement="right"
                                title={
                                    'Filter by Batch Number, Description,\n' +
                                    'Product Name, Product Number or Sales\n' +
                                    'Order'
                                }>
                                <input
                                    type="text"
                                    style={{ width: '100%' }}
                                    onChange={(event) => filterItems(event)}
                                />
                            </Tooltip>
                            {filteredUnscheduledSteps.map(
                                (batchStep, index) => (
                                    <EventDisplay
                                        key={index}
                                        eventInfo={batchStep}
                                        type="unscheduled"
                                    />
                                )
                            )}
                        </CardContent>
                    </Card>
                ) : null}
            </Grid>
            <Grid item xs={2}>
                {!isNilOrEmpty(schedulingErrors) ? (
                    <Card className={'mb-2'}>
                        <CardHeader title="Scheduling Concerns" />
                        <CardContent className={'notification-card'}>
                            {schedulingErrors.map(
                                (error: string, index: number) => (
                                    <Typography key={index}>
                                        <span>{error}</span>
                                    </Typography>
                                )
                            )}
                        </CardContent>
                    </Card>
                ) : null}
            </Grid>
        </Grid>
    ) : null;
};

export default Notifications;
