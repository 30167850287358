import RevisionValueFormatter from './RevisionValueFormatter';
import React, { useEffect, useState } from 'react';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { organizationsApi } from '../../../services/organizations/organizations.service';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

const DisplayChanges = (props: any) => {
    const dispatch = useDispatch();
    const formatter = new RevisionValueFormatter();
    const { deltaKey, delta, type, isLoading, setIsLoading, fieldMappings } =
        props;

    const [changesText, setChangesText] = useState({
        before: [],
        after: [],
    });

    useEffect(() => {
        handleSetupData();
    }, [deltaKey, delta, type]);

    const handleSetupData = async () => {
        if (!isNilOrEmpty(delta?.permissions?.before)) {
            for (const item of delta?.permissions?.before) {
                const data = await dispatch(
                    organizationsApi.endpoints.getPermission.initiate({
                        permissionId: item.permissionId,
                        full: true,
                    })
                );

                setChangesText((prev) => ({
                    ...prev,
                    //@ts-ignore
                    before: [...prev.before, data?.data?.name],
                }));
            }
        }

        if (!isNilOrEmpty(delta?.permissions?.after)) {
            for (const item of delta?.permissions?.after) {
                const data = await dispatch(
                    organizationsApi.endpoints.getPermission.initiate({
                        permissionId: item.permissionId,
                        full: true,
                    })
                );

                setChangesText((prev) => ({
                    ...prev,
                    //@ts-ignore
                    after: [...prev.after, data?.data?.name],
                }));
            }
        }

        setIsLoading(false);
    };

    if (deltaKey === 'permissions') {
        return type === 'before' ? (
            <>
                {changesText.before.map((name: any) => (
                    <>{!isLoading && <div key={name}>{name}</div>}</>
                ))}
            </>
        ) : (
            <>
                {changesText.after.map((name: any) => (
                    <>{!isLoading && <div key={name}>{name}</div>}</>
                ))}
            </>
        );
    } else if (!isNilOrEmpty(deltaKey)) {
        let result: any = formatter.format(deltaKey, delta);

        if (_.isArray(result)) {
            props.setRenderDeltaGrid(true);
        }

        /**
         * this handles associations that are nested in an object
         * the 0 index should be a displayValue for the association
         * and the 1 index should be the key for the association
         * here we get the valueKey from the fieldMapping
         * in the DeltaListItem we get the displayValue from the fieldMapping
         */
        if (
            (result?.before?.toString() === '[object Object]' ||
                result?.after?.toString() === '[object Object]') &&
            _.isObject(result as any)
        ) {
            if (_.isObject(fieldMappings?.[deltaKey])) {
                const valueKey: any = Object.keys(fieldMappings?.[deltaKey])[1];
                result = {
                    before: result.before?.[valueKey],
                    after: result.after?.[valueKey],
                };
            } else {
                result = {
                    before: Object.values(result.before)[0],
                    after: Object.values(result.after)[0],
                };
            }
        }

        return type === 'before'
            ? result?.before?.toString() || ''
            : result?.after?.toString() || '';
    } else {
        return null;
    }
};

export default DisplayChanges;
