import { RouteProps } from './routesConfig';
import { lazy } from 'react';

const UnitOfMeasure = lazy(() => import('../containers/setup/UnitOfMeasure'));
const UnitOfMeasureForm = lazy(
    () => import('../containers/setup/pages/UnitOfMeasureForm')
);
const UnitOfMeasureConversion = lazy(
    () => import('../containers/setup/UnitOfMeasureConversion')
);
const UnitOfMeasureConversionForm = lazy(
    () => import('../containers/setup/pages/UnitOfMeasureConversionForm')
);
const ProperShippingName = lazy(
    () => import('../containers/setup/ProperShippingName')
);
const ProperShippingNameForm = lazy(
    () => import('../containers/setup/pages/ProperShippingNameForm')
);
const BoilerPlateNote = lazy(
    () => import('../containers/setup/BoilerPlateNote')
);
const BoilerPlateNoteForm = lazy(
    () => import('../containers/setup/pages/BoilerPlateNoteForm')
);
const AttributesView = lazy(
    () => import('../containers/setup/pages/AttributesView')
);
const AttributeFormHandler = lazy(
    () => import('../containers/setup/AttributeFormHandler')
);
const DocumentTemplatesGrid = lazy(
    () => import('../containers/setup/pages/DocumentTemplatesGrid')
);
const DocumentTemplate = lazy(
    () => import('../containers/setup/pages/DocumentTemplate')
);
const NotificationContentGrid = lazy(
    () => import('../containers/setup/pages/NotificationContentGrid')
);
const NotificationContentForm = lazy(
    () => import('../containers/setup/pages/NotificationContentForm')
);

const SetupRoutes: RouteProps[] = [
    {
        path: '/setup/unitOfMeasure',
        component: UnitOfMeasure,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/unitOfMeasure/:id',
        component: UnitOfMeasureForm,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/unitOfMeasureConversion',
        component: UnitOfMeasureConversion,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/unitOfMeasureConversion/new',
        component: UnitOfMeasureConversionForm,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/unitOfMeasureConversion/:id',
        component: UnitOfMeasureConversionForm,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/properShippingName',
        component: ProperShippingName,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/properShippingName/new',
        component: ProperShippingNameForm,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/properShippingName/:id',
        component: ProperShippingNameForm,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/boilerPlateNote',
        component: BoilerPlateNote,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/boilerPlateNote/:id',
        component: BoilerPlateNoteForm,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/attributes',
        component: AttributesView,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/attributes/:tab',
        component: AttributesView,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/attributes/:tab/new',
        component: AttributeFormHandler,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/attributes/:tab/:id',
        component: AttributeFormHandler,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/document-designer/templates',
        component: DocumentTemplatesGrid,
        featureFlag: 'dashboard.nav.apps.documentDesigner',
        permissionKey: 'DOCUMENT_DESIGNER.DOC_TEMPLATE.VIEW',
    },
    {
        path: '/setup/document-designer/templates/:id',
        component: DocumentTemplate,
        featureFlag: 'dashboard.nav.apps.documentDesigner',
        permissionKey: 'DOCUMENT_DESIGNER.DOC_TEMPLATE.EDIT',
    },
    {
        path: '/setup/document-designer/templates/new',
        component: DocumentTemplate,
        featureFlag: 'dashboard.nav.apps.documentDesigner',
        permissionKey: 'DOCUMENT_DESIGNER.DOC_TEMPLATE.CREATE',
    },
    {
        path: '/setup/notifications/templates',
        component: NotificationContentGrid,
        featureFlag: 'feature.apps.notifications',
        permissionKey: 'Notifications.Message Templates.View',
    },
    {
        path: '/setup/notifications/templates/:id',
        component: NotificationContentForm,
        featureFlag: 'feature.apps.notifications',
        permissionKey: 'Notifications.Message Templates.Edit',
    },
    {
        path: '/setup/notifications/templates/new',
        component: NotificationContentForm,
        featureFlag: 'feature.apps.notifications',
        permissionKey: 'Notifications.Message Templates.Create',
    },
];

export default SetupRoutes;
