import { useCallback, useContext, useEffect, useState } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import NavFormContext from '../../../contexts/navForm.context';

function useConfirmExit(
    confirmExit: () => boolean,
    when = true,
    showConfirm = true
) {
    const navContext = useContext(NavigationContext);
    const { navFormValue, setNavFormValue } = useContext(NavFormContext);

    useEffect(() => {
        if (!when || !showConfirm) {
            return;
        }

        const push = navContext?.navigator.push;

        navContext.navigator.push = (...args: Parameters<typeof push>) => {
            const result = confirmExit();
            if (result !== false) {
                push(...args);
            }
        };

        return () => {
            navContext.navigator.push = push;
            setNavFormValue((prev: any) => ({
                ...prev,
                sharedNav: {
                    ...prev.sharedNav,
                    hasEntityChanges: false,
                },
            }));
        };
    }, [navigator, confirmExit, when]);
}

export function usePrompt(message: string, when = true, showConfirm = true) {
    useEffect(() => {
        if (when && showConfirm) {
            window.onbeforeunload = function (e: any) {
                return '';
            };
        } else if (!showConfirm) {
            window.onbeforeunload = null;
        }

        return () => {
            window.onbeforeunload = null;
        };
    }, [message, when, showConfirm]);

    const confirmExit = useCallback(() => {
        if (showConfirm) {
            const confirm = window.confirm(message);
            return confirm;
        }
    }, [message, showConfirm]);
    useConfirmExit(confirmExit, when, showConfirm);
}
