import React, { useContext, useEffect, useState } from 'react';
import '.././style.css';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
} from '@mui/material';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import CloseIcon from '@mui/icons-material/Close';
import SingleSelectPopupsContext from '../../../contexts/singleSelectPopups.context';
import _ from 'lodash';

export const RenderCellValue = (props: any) => {
    const displayValue =
        typeof props.value === 'object' ? props.value.display : props.value;
    return <span>{displayValue}</span>;
};

const SelectablePopupGrid = (props: any) => {
    const [showModal, setShowModal] = useState(true);
    const baseGridParams = props.params;

    const { gridPopups, setGridPopups } = useContext(SingleSelectPopupsContext);

    const [rowData, setRowData] = useState(null);
    const handleClose = () => {
        baseGridParams.colDef.valueSetter(null);
        setShowModal(false);
    };

    useEffect(() => {
        handleSetupRowData();
    }, [gridPopups]);

    //method to setup the initial rowData when we load the selectable popup grid
    const handleSetupRowData = () => {
        let currentGridPopup = null;
        //if we have a single context value
        if (
            props.context &&
            !_.isArray(props.context) &&
            props.context.fieldName === props.params.colDef.field
        ) {
            currentGridPopup = gridPopups.find(
                (row: any) =>
                    row.gridName === props.context.gridName &&
                    row.fieldName === props.context.fieldName
            );
            //if we have multiple contexts passed in
        } else if (_.isArray(props.context)) {
            const currentContext = props.context.find(
                (current: any) =>
                    current.fieldName === props.params.colDef.field
            );
            if (currentContext) {
                currentGridPopup = gridPopups.find(
                    (row: any) =>
                        row.gridName === currentContext.gridName &&
                        row.fieldName === currentContext.fieldName
                );
            }
        }
        if (currentGridPopup) {
            setRowData(currentGridPopup.value);
            // we can fall back to the original colDef rowData if we don't have either of these
        } else {
            setRowData(baseGridParams.colDef.gridPopupParameters.rowData);
        }
    };

    const handleRowSelect = (params: any) => {
        params.newValue = {
            id: params.data.id,
            display: baseGridParams.colDef.gridPopupParameters.displayField
                ? params.data[
                      baseGridParams.colDef.gridPopupParameters.displayField
                  ]
                : params.data.id,
        };
        if (props.context && props.applyFilter) {
            //sometimes the context can be an array, if we pass in multiple contexts for the same parent grid
            if (_.isArray(props.context)) {
                handleFilterMultipleContexts(params);
                //if its not an array we can just use the context we passed in
            } else if (baseGridParams.colDef.field === props.context) {
                handleFilterSingleContext(params);
            }
        }

        baseGridParams.colDef.valueSetter(params);

        setShowModal(false);
    };

    //method to handle the filtering if we have an array of contexts as a prop
    const handleFilterMultipleContexts = (params: any) => {
        const context = baseGridParams.colDef.field;

        const index = gridPopups.findIndex((row: any) => {
            return row.name === context;
        });

        if (index !== -1) {
            const filter = handleUpdateGridPopupData(params, index, context);
            setRowData(filter);
        }
    };

    //method to handle the filtering if we have only passed in a single context as a prop
    const handleFilterSingleContext = (params: any) => {
        const index = gridPopups.findIndex((row: any) => {
            return row.name === props.context;
        });

        if (index !== -1) {
            handleUpdateGridPopupData(params, index, props.context);
        }
    };

    //method to update the gridPopups context with the passed in data
    const handleUpdateGridPopupData = (
        params: any,
        index: number,
        context: string
    ) => {
        const tempArr = [...gridPopups];

        //filter out our recently selected item
        const filter = gridPopups[index].value?.filter((item: any) => {
            return item.id !== params.data.id;
        });

        tempArr.splice(index, 1);

        //this means we already have a value selected - we need to add it back to the rowData
        if (_.isObject(baseGridParams.value)) {
            const allRows = baseGridParams.colDef.gridPopupParameters.rowData;
            const addBackIndex = allRows.findIndex((row: any) => {
                return row[context] === baseGridParams.value.display;
            });

            if (addBackIndex !== -1) {
                filter.splice(
                    allRows[addBackIndex].id - 1,
                    0,
                    allRows[addBackIndex]
                );
            }
        }

        tempArr.push({
            name: context,
            value: filter,
        });

        setGridPopups(tempArr);

        return filter;
    };

    return (
        <Dialog
            open={showModal}
            onClose={handleClose}
            maxWidth={'xl'}
            fullWidth={true}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>Select Value</Box>
                    <Box>
                        <IconButton onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent style={{ paddingTop: '10px' }}>
                <div className="menu-container">
                    <BaseFormGrid
                        displayGrid={
                            baseGridParams.colDef.gridPopupParameters
                                .displayGrid
                        }
                        isLoading={
                            baseGridParams.colDef.gridPopupParameters.isLoading
                        }
                        rowData={
                            //@ts-ignore
                            rowData?.value ? rowData?.value : rowData
                        }
                        dataKey={'id'}
                        columnDefs={
                            baseGridParams.colDef.gridPopupParameters
                                .columnDefs ??
                            baseGridParams.colDef.gridPopupParameters.columnDefs
                        }
                        defaultColDef={
                            baseGridParams.colDef.gridPopupParameters
                                .defaultColDef ??
                            baseGridParams.colDef.gridPopupParameters
                                .defaultColDef
                        }
                        onRowClicked={(params: any) => handleRowSelect(params)}
                        context={props.context}
                        gridClass="full-size-item"
                        sizeColumnsToFit={true}></BaseFormGrid>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default SelectablePopupGrid;
