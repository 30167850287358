import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
    PricingDetail,
    ProductDetail,
} from '../../../../types/PricingAnalysis.types';
import moment from 'moment';
import { RootState } from '../../../../store';

type ParamTypes = { customerProductId: string };

const usePricingCustomerProductDetailsCharts = () => {
    let location = useLocation();
    const { customerProductId } = useParams<ParamTypes>();
    const hash = location.hash ? location.hash : '';
    const { pricingAnalysisToolSet } = useSelector(
        (state: RootState) => state.pricingAnalysisTool
    );
    const [marginChartData, setMarginChartData] = useState([]);
    const [profitChartData, setProfitChartData] = useState([]);
    const [dollarsLostChartData, setDollarsLostChartData] = useState([]);
    const COLORS = ['#00A894', '#007cbb', '#f3b231', '#f69333', '#3cb4e5'];

    useEffect(() => {
        if (pricingAnalysisToolSet.activeCustomerProductDetails) {
            setMarginChartData(
                pricingAnalysisToolSet.activeCustomerProductDetails
                    .map((transactions: ProductDetail) => {
                        return {
                            orderDate: moment(transactions.orderDate).format(
                                'MM/DD/YYYY'
                            ),
                            Margin:
                                transactions.margin !== 'N/A'
                                    ? Number(transactions.margin)
                                    : 0,
                            'Average Margin': Number(
                                transactions.averageMargin
                            ),
                            'Below Average Margin': Number(
                                transactions.percentBelowAverageMargin
                            ),
                        };
                    })
                    .sort((a: any, b: any) =>
                        moment(a.orderDate) > moment(b.orderDate) ? 1 : -1
                    )
            );

            setProfitChartData(
                pricingAnalysisToolSet.activeCustomerProductDetails
                    .map((transactions: ProductDetail) => {
                        return {
                            orderDate: moment(transactions.orderDate).format(
                                'MM/DD/YYYY'
                            ),
                            'Material Cost': Number(transactions.orderCost),
                            Price: Number(transactions.orderPrice),
                            Profit: Number(transactions.profit),
                        };
                    })
                    .sort((a: any, b: any) =>
                        moment(a.orderDate) > moment(b.orderDate) ? 1 : -1
                    )
            );

            const customerProductPricingConernDetails =
                pricingAnalysisToolSet.activePricingConcernDetails.find(
                    (detail: PricingDetail) =>
                        detail.id === customerProductId + hash
                );

            setDollarsLostChartData([
                {
                    name: 'Pricing Lag',
                    value: customerProductPricingConernDetails.pricingLagDollarsLost,
                },
                {
                    name: 'Outdated Pricing',
                    value: customerProductPricingConernDetails.outdatedPricingDollarsLost,
                },
                {
                    name: 'Below Average Margin',
                    value: customerProductPricingConernDetails.belowAverageMarginDollarsLost,
                },
                {
                    name: 'Margin Below Goal',
                    value: customerProductPricingConernDetails.marginBelowGoalDollarsLost,
                },
                {
                    name: 'Price Below Average',
                    value: customerProductPricingConernDetails.belowAveragePriceDollarsLost,
                },
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricingAnalysisToolSet.activeCustomerProductDetails]);

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
    }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill="white"
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };
    return {
        pricingAnalysisToolSet,
        marginChartData,
        profitChartData,
        renderCustomizedLabel,
        dollarsLostChartData,
        COLORS,
    };
};
export default usePricingCustomerProductDetailsCharts;
