import React, { useEffect, useState } from 'react';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import { useDispatch, useSelector } from 'react-redux';
import { getBatchTickets } from '../../../store/batchTickets';
import { getWorkcenters } from '../../../store/workcenters';
import { getEquipment } from '../../../store/equipment';
import { isNilOrEmpty, StringUtils } from '../../../utils/objectUtils';
import { getWarehouseProductsByWarehouseId } from '../../../store/warehouses';
import {
    BatchTicket,
    Step,
    Workcenter,
} from '../../../types/BatchTicket.types';
import {
    checkForStepOverlapOrOutOfOrder,
    convertStepToFullCalendarEvent,
    notEnoughInventoryInWarehouseToCompleteBatchStep,
} from '../Utils';
import {
    setUpActiveScheduleData,
    updateBatchStepStartAndEndDate,
    updateSelectedDateAndView,
} from '../../../store/scheduler';
import EventDisplay from '../EventDisplay';
import { RootState } from '../../../store';

const SLOTS = [
    {
        slotDuration: '00:05:00',
        slotLabelInterval: '00:15:00',
    },
    {
        slotDuration: '00:15:00',
        slotLabelInterval: '00:30:00',
    },
    {
        slotDuration: '00:30:00',
        slotLabelInterval: '01:00:00',
    },
];

const SCROLL_TO_TIME = moment().subtract(1, 'hours').format('HH:mm:ss');

const useBatchScheduler = () => {
    const calendarRef = React.createRef<FullCalendar>();
    const dispatch = useDispatch();
    const [showAlertText, setShowAlertText] = useState(null);
    const { schedulerDataSet } = useSelector(
        (state: RootState) => state.scheduler
    );
    const { batchTicketSet } = useSelector(
        (state: RootState) => state.batchTickets
    );
    const { workcentersSet } = useSelector(
        (state: RootState) => state.workcenters
    );
    const { warehousesSet } = useSelector(
        (state: RootState) => state.warehouses
    );
    const [refreshData, setRefreshData] = useState(false);
    const scheduledSteps = schedulerDataSet.scheduledSteps;
    const workcenters = schedulerDataSet.workcenters;
    let activeSlotIndex = 1;

    useEffect(() => {
        const view = schedulerDataSet.selectedView
            ? schedulerDataSet.selectedView
            : 'resourceTimeline';
        const date = schedulerDataSet.selectedDate
            ? schedulerDataSet.selectedDate
            : moment();
        if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            calendarApi.changeView(view, date);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (refreshData && schedulerDataSet.warehouseId) {
            dispatch(getBatchTickets());
            dispatch(getWorkcenters());
            dispatch(getEquipment());
        }

        setRefreshData(false);
    }, [dispatch, refreshData, schedulerDataSet.warehouseId]);

    useEffect(() => {
        if (
            batchTicketSet.isLoading === false &&
            !isNilOrEmpty(Object.keys(batchTicketSet.batchTicketsByWarehouseId))
        ) {
            Object.keys(batchTicketSet.batchTicketsByWarehouseId).forEach(
                (warehouseId) => {
                    dispatch(getWarehouseProductsByWarehouseId(warehouseId));
                }
            );
        }
    }, [
        dispatch,
        batchTicketSet.isLoading,
        batchTicketSet.batchTicketsByWarehouseId,
    ]);

    useEffect(() => {
        const updateCalendarData = () => {
            let calendarApi: any;
            if (calendarRef.current) {
                calendarApi = calendarRef.current.getApi();
                calendarApi.removeAllEvents();
            }
            //should probably be an API call to ensure we have the most up to date, but as of now all data is stored in app state
            let batchStepsForWarehouseId: any = [];
            let utilizedWorkcenters = [];
            if (
                batchTicketSet.batchTicketsByWarehouseId[
                    schedulerDataSet.warehouseId
                ]
            ) {
                batchTicketSet.batchTicketsByWarehouseId[
                    schedulerDataSet.warehouseId
                ].forEach((batchTicket: BatchTicket) => {
                    //setup batchTicket step
                    batchTicket.Steps.forEach((step: Step) => {
                        const notEnoughInventory =
                            notEnoughInventoryInWarehouseToCompleteBatchStep(
                                step,
                                batchTicketSet.batchTicketsByWarehouseId[
                                    schedulerDataSet.warehouseId
                                ]
                            );
                        const textColor = notEnoughInventory ? 'red' : null;
                        batchStepsForWarehouseId.push(
                            convertStepToFullCalendarEvent(
                                step,
                                batchTicket.backgroundColor,
                                textColor,
                                batchTicket
                            )
                        );
                    });
                });

                utilizedWorkcenters = workcentersSet.workcentersByWarehouseId[
                    schedulerDataSet.warehouseId
                ].filter((workcenter: Workcenter) => {
                    return !workcenter.Id.includes('Unscheduled');
                });
            }

            dispatch(
                setUpActiveScheduleData(
                    schedulerDataSet.warehouseId,
                    batchStepsForWarehouseId,
                    utilizedWorkcenters
                )
            );

            if (calendarApi) {
                setTimeout(() => calendarApi.scrollToTime(SCROLL_TO_TIME));
            }
        };

        updateCalendarData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, batchTicketSet, schedulerDataSet.warehouseId]);

    const renderEventContent = (eventInfo: any) => {
        return <EventDisplay eventInfo={eventInfo} type="scheduled" />;
    };

    const allowStepToBeRescheduled = (eventMoveInfo: any) => {
        if (
            isDropAccepted(
                eventMoveInfo.event.getResources()[0].id,
                eventMoveInfo.oldEvent.getResources()[0].id,
                eventMoveInfo.event
            )
        ) {
            dispatch(
                updateBatchStepStartAndEndDate(
                    eventMoveInfo.event,
                    eventMoveInfo.event.getResources()[0].id,
                    false
                )
            );
        } else {
            eventMoveInfo.revert();
        }
    };

    const newScheduledBatchTicketStep = (info: any) => {
        if (StringUtils.equals(schedulerDataSet.selectedView, 'dayGridMonth')) {
            info.event.remove();
            setShowAlertText('Steps cannot be scheduled on the month view.');
        } else if (
            isDropAccepted(
                info.event.getResources()[0].id,
                info.draggedEl.getAttribute('Workcenter'),
                info.event
            )
        ) {
            //this is goofy and I don't like it one bit, BUT it seems to be the easiest way to prevent the event from
            // being added twice (once via calendarAPI and one via app state)
            info.event.remove();
            dispatch(
                updateBatchStepStartAndEndDate(
                    info.event,
                    info.event.getResources()[0].id,
                    true
                )
            );
        } else {
            info.event.remove();
        }
    };

    const isDropAccepted = (
        selectedWorkcenterId: string,
        requiredWorkcenterId: string,
        droppedStep: any
    ) => {
        let isInvalidWorkcenterCategory = false;
        let requiredWorkcenterCategory = '';
        if (requiredWorkcenterId !== null) {
            requiredWorkcenterCategory = workcenters.find(
                (workcenter: Workcenter) =>
                    workcenter.id === requiredWorkcenterId
            ).Category;
            const selectedWorkcenterCategory = workcenters.find(
                (workcenter: Workcenter) =>
                    workcenter.id === selectedWorkcenterId
            ).Category;
            isInvalidWorkcenterCategory =
                requiredWorkcenterCategory !== selectedWorkcenterCategory;
        }

        const eventsWithLikeBatchId = scheduledSteps.filter(
            (event: any) =>
                event.BatchNumber === droppedStep.extendedProps.BatchNumber
        );
        const batchStepHasOverlap = checkForStepOverlapOrOutOfOrder(
            droppedStep,
            eventsWithLikeBatchId
        );

        if (isInvalidWorkcenterCategory || batchStepHasOverlap) {
            let alertText;
            if (isInvalidWorkcenterCategory) {
                alertText = `Step can only be assigned to the following workcenter category: ${requiredWorkcenterCategory}`;
            } else {
                alertText = 'Batch step are overlapping or are out of order.';
            }

            setShowAlertText(alertText);

            return false;
        } else {
            return true;
        }
    };

    const zoomIn = () => {
        activeSlotIndex--;
        if (activeSlotIndex >= 0) {
            const calendarApi = calendarRef.current.getApi();
            calendarApi.setOption(
                'slotDuration',
                SLOTS[activeSlotIndex].slotDuration
            );
            calendarApi.setOption(
                'slotLabelInterval',
                SLOTS[activeSlotIndex].slotLabelInterval
            );
            setTimeout(() => calendarApi.scrollToTime(SCROLL_TO_TIME));
        }
    };

    const zoomOut = () => {
        activeSlotIndex++;
        if (activeSlotIndex < SLOTS.length) {
            const calendarApi = calendarRef.current.getApi();
            calendarApi.setOption(
                'slotDuration',
                SLOTS[activeSlotIndex].slotDuration
            );
            calendarApi.setOption(
                'slotLabelInterval',
                SLOTS[activeSlotIndex].slotLabelInterval
            );
            setTimeout(() => calendarApi.scrollToTime(SCROLL_TO_TIME));
        }
    };

    const updateDate = (date: any) => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.gotoDate(date);
    };

    const updateView = (view: any) => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.changeView(view);
    };

    const storeCurrentDateAndView = (dateAndViewInfo: any) => {
        let displayDate = moment(dateAndViewInfo.start);
        if (
            dateAndViewInfo.view.type.includes('Month') &&
            moment(dateAndViewInfo.start).date() > 20
        ) {
            displayDate = moment(displayDate).add(1, 'months').startOf('month');
        }
        dispatch(
            updateSelectedDateAndView(
                new Date(displayDate.toString()),
                dateAndViewInfo.view.type
            )
        );
    };
    return {
        batchTicketSet,
        scheduledSteps,
        activeSlotIndex,
        workcenters,
        calendarRef,
        setRefreshData,
        setShowAlertText,
        warehousesSet,
        refreshData,
        storeCurrentDateAndView,
        updateView,
        updateDate,
        zoomOut,
        zoomIn,
        newScheduledBatchTicketStep,
        allowStepToBeRescheduled,
        renderEventContent,
        workcentersSet,
        showAlertText,
        SLOTS,
    };
};
export default useBatchScheduler;
