import React, { useState } from 'react';
import { useGetDocumentPdfUrlQuery } from '../../../services/apAutomation/apAutomation.service';
import { skipToken } from '@reduxjs/toolkit/query';

const useInvoicePreview = (documentId: string) => {
    const { data: activeDocumentPdfUrl, isLoading: isLoadingPdfUrl } =
        useGetDocumentPdfUrlQuery(documentId ? documentId : skipToken);

    const [page, setPage] = useState(1);
    const [numPages, setNumPages] = useState(0);
    const [scale, setScale] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setNumPages(numPages);
    };

    return {
        activeDocumentPdfUrl,
        isLoadingPdfUrl,
        page,
        setPage,
        numPages,
        scale,
        setScale,
        onDocumentLoadSuccess,
    };
};

export default useInvoicePreview;
