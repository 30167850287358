import React from 'react';
import MultiselectDropdown from '../../../components/form/multiselectDropdown/MultiselectDropdown';
import LoaderButton from '../../../components/LoaderButton';
import { setSelectApproverOptions } from './Utils';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import * as _ from 'lodash';
import useApproversCellRenderer from '../hooks/ApprovalWorkflows/useApproversCellRenderer';

interface ApproversCellRendererProps {
    data: any;
    rowIndex: number;
}

const ApproversCellRenderer = ({
    data,
    rowIndex,
}: ApproversCellRendererProps) => {
    const {
        user,
        approvalWorkflows,
        localApproversOptions,
        users,
        setLocalApproversOptions,
        updateWorkflow,
        isRowSaving,
    } = useApproversCellRenderer({ data, rowIndex });

    return (
        <div className="approval-workflow-approvers-cell">
            <MultiselectDropdown
                isMulti
                isDisabled={
                    !PermissionsUtil.isPermissionEnabled(
                        user.permissions,
                        PERMISSIONS.AP_AUTOMATION.APPROVAL_WORKFLOWS.UPDATE
                    )
                }
                menuPlacement={
                    rowIndex >= approvalWorkflows.length / 2 ? 'top' : 'bottom'
                }
                id="approval-workflow-approvers-cell"
                menuPortalTarget={document.getElementById(
                    'approval-workflows-modal'
                )}
                classNamePrefix="select"
                closeMenuOnSelect={false}
                value={localApproversOptions}
                options={setSelectApproverOptions(users)}
                placeholder={'Select Approvers'}
                onChange={(approverOptionValues: any) => {
                    setLocalApproversOptions(approverOptionValues);
                }}
                menuPosition="fixed"
            />
            <LoaderButton
                onClick={() => updateWorkflow()}
                isLoading={isRowSaving}
                className="approval-workflow-update-button"
                disabled={
                    !PermissionsUtil.isPermissionEnabled(
                        user.permissions,
                        PERMISSIONS.AP_AUTOMATION.APPROVAL_WORKFLOWS.UPDATE
                    ) || _.isEqual(localApproversOptions, data.approvers)
                }>
                Update
            </LoaderButton>
        </div>
    );
};

export default ApproversCellRenderer;
