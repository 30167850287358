import React, { useEffect } from 'react';
import {
    DesktopDatePicker,
    DesktopDatePickerProps,
} from '@mui/x-date-pickers/DesktopDatePicker';
import { TextFieldProps } from '@mui/material';
import InputBase from '../InputBase/InputBase';
import { FormMethods } from '../../../../libs/hooksLib';
import _ from 'lodash';
import { MaxDateValidator } from '../../../../validators/maxDate.validator';
import useFormControlProps from '../hooks/useFormControlProps';
import moment from 'moment/moment';
import { DateValidator } from '../../../../validators/date.validator';
import useDate from '../../../../utils/hooks/useDate';
import { MinDateValidator } from '../../../../validators/minDate.validator';

export type DateInputProps = Omit<
    DesktopDatePickerProps<Date, Date>,
    'onChange' | 'renderInput' | 'value'
> & {
    id: string;
    onChange?: (event: any) => void;
    formMethods?: FormMethods;
    required?: boolean;
    minDate?: Date;
    maxDate?: Date;
    value?: any;
    termSetKey?: string;
    size?: 'small' | 'medium';
    useRawValue?: boolean;
};

const DateInput = (props: DateInputProps) => {
    const { formMethods, handleFieldChange, value, label } =
        useFormControlProps(props);

    const { dateFormatter } = useDate();

    const handleOnChange = (date?: unknown, keyboardInputValue?: string) => {
        const momentDate = date as moment.Moment;
        handleFieldChange({
            target: {
                id: props.id,
                value: date ? dateFormatter(momentDate?.format(), true) : null,
            },
        });
    };

    useEffect(() => {
        if (value) {
            formMethods?.addValidator(
                props.id,
                _.isObject(label) ? <>{label}</> : label?.toString(),
                DateValidator
            );
        } else {
            formMethods?.removeValidator(props.id, DateValidator);
        }
    }, [value]);

    useEffect(() => {
        if (label?.props && !label?.props.termSet) {
            return;
        }
        if (props.minDate && label) {
            formMethods?.addValidator(
                props.id,
                _.isObject(label) ? <>{label}</> : label?.toString(),
                MinDateValidator(props.minDate)
            );
        } else {
            formMethods?.removeValidator(
                props.id,
                MinDateValidator(props.minDate)
            );
        }
    }, [props.minDate, label?.props?.termSet]);

    useEffect(() => {
        if (label?.props && !label?.props.termSet) {
            return;
        }
        if (props.maxDate && label) {
            formMethods?.addValidator(
                props.id,
                _.isObject(label) ? <>{label}</> : label?.toString(),
                MaxDateValidator(props.maxDate)
            );
        } else {
            formMethods?.removeValidator(
                props.id,
                MaxDateValidator(props.maxDate)
            );
        }
    }, [props.maxDate, label?.props?.termSet]);

    return (
        <DesktopDatePicker
            {...props}
            value={
                value
                    ? (dateFormatter(
                          value,
                          props.useRawValue
                      ) as unknown as Date)
                    : null
            }
            onChange={handleOnChange}
            label={label}
            renderInput={(params: TextFieldProps) => (
                <InputBase
                    fullWidth
                    {...params}
                    id={props.id}
                    formMethods={formMethods}
                    required={props.required}
                    size={props.size}
                />
            )}
        />
    );
};

export default DateInput;
