import React, { useEffect, useState } from 'react';
import {
    useGetDefaultToleranceQuery,
    useUpdateDefaultToleranceChecksMutation,
} from '../../../../services/apAutomation/apAutomation.service';
import { useSelector } from 'react-redux';
import { useFormFields } from '../../../../libs/hooksLib';
import { cloneDeep, isEqual } from 'lodash';
import { IconButton, Popover } from '@mui/material';
import Typography from '@mui/material/Typography';
import LoaderButton from '../../../../components/LoaderButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { RootState } from '../../../../store';

const useMatchingForm = ({ onSubmit }: any) => {
    const [updateDefaultToleranceChecks, { isLoading: isLoadingSave }] =
        useUpdateDefaultToleranceChecksMutation();
    const { data: defaultTolerance, isLoading: isLoadingDefaultTolerance } =
        useGetDefaultToleranceQuery();
    const [localMatching, setLocalMatching] = useState([]);
    const user = useSelector((state: RootState) => state.user);
    const [fields, handleFieldChange] = useFormFields({
        matchCriteria: {
            value: 'LESS_THAN_OR_EQUAL',
            label: '<= (Less Than or Equal)',
        },
        matchType: { value: null, label: null },
        matchValue: '',
        poReceiptCostType: { value: null, label: null },
    });

    const updateTolerance = () => {
        updateDefaultToleranceChecks(localMatching);

        if (onSubmit) {
            onSubmit(true);
        }
    };

    useEffect(() => {
        if (isLoadingDefaultTolerance) {
            return;
        }
        setLocalMatching(cloneDeep(defaultTolerance));
    }, [defaultTolerance]);

    const removeMatchCriterion = (matchCriterionToRemove: any) => {
        setLocalMatching((oldMatchCriterion) =>
            oldMatchCriterion.filter(
                (matchCriterion) =>
                    !(
                        matchCriterion.criteria ===
                            matchCriterionToRemove.criteria &&
                        matchCriterion.type === matchCriterionToRemove.type &&
                        matchCriterion.value === matchCriterionToRemove.value
                    )
            )
        );
    };

    const setSelectTypeOptions = () => {
        return [
            {
                value: 'AMOUNT',
                label: '$ (Amount)',
            },
            {
                value: 'PERCENTAGE',
                label: '% (Percentage)',
            },
        ];
    };

    const setSelectPoCostCategoryOptions = () => {
        return [
            {
                value: 'OTHER',
                label: 'Other',
            },
            {
                value: 'FREIGHT',
                label: 'Freight',
            },
            {
                value: 'RMC',
                label: 'RMC',
            },
        ];
    };

    const submitAddMatchCriterion = async (
        event: React.ChangeEvent<HTMLFormElement>
    ) => {
        event.preventDefault();
        if (areFieldsPopulated()) {
            const matchCriterion = {
                criteria: {
                    key: fields.matchCriteria.value,
                    value: fields.matchCriteria.label,
                },
                type: {
                    key: fields.matchType.value,
                    value: fields.matchType.label,
                },
                value: fields.matchValue,
                poReceiptCostType: {
                    key: fields.poReceiptCostType.value,
                    value: fields.poReceiptCostType.label,
                },
            };
            setLocalMatching((oldMatchCriterion) => {
                let filteredOldMatchCriterion = oldMatchCriterion.filter(
                    (criterion) => {
                        return !isEqual(criterion, matchCriterion);
                    }
                );
                return [...filteredOldMatchCriterion, matchCriterion];
            });
        }
    };

    const areFieldsPopulated = () => {
        const numRegex = new RegExp(/^-?\d+\.?\d*$/, 'g');
        return (
            fields.matchCriteria.value !== null &&
            fields.matchType.value !== null &&
            fields.matchValue !== '' &&
            fields.matchValue.match(numRegex)
        );
    };

    const Delete = (props: any) => {
        const [showOverlay, setShowOverlay] = useState(false);
        const [anchorEl, setAnchorEl] =
            React.useState<HTMLButtonElement | null>(null);

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
            setShowOverlay(true);
        };
        const popover = (
            <Popover id="popover-basic" open={showOverlay} anchorEl={anchorEl}>
                <Typography sx={{ p: 2 }}>
                    Do you want to remove this matching criterion?
                </Typography>
                <LoaderButton
                    isLoading={null}
                    variant="contained"
                    data-testid="removeConfirmationBtn"
                    onClick={() => removeMatchCriterion(props.data)}>
                    Yes
                </LoaderButton>{' '}
                <LoaderButton
                    isLoading={null}
                    variant="contained"
                    data-testid="cancelRemoveBtn"
                    onClick={() => setShowOverlay(false)}>
                    No
                </LoaderButton>
            </Popover>
        );

        return props.data ? (
            <>
                {popover}
                <IconButton color="error" size="small" onClick={handleClick}>
                    <DeleteForeverIcon />
                </IconButton>
            </>
        ) : null;
    };

    return {
        user,
        fields,
        handleFieldChange,
        setSelectTypeOptions,
        setSelectPoCostCategoryOptions,
        submitAddMatchCriterion,
        areFieldsPopulated,
        isLoadingDefaultTolerance,
        localMatching,
        Delete,
        updateTolerance,
        isLoadingSave,
    };
};

export default useMatchingForm;
