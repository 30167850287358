import React, { useContext } from 'react';
import SettingsContext from '../../contexts/settings.context';
import moment from 'moment-timezone';

/**
 * date and dateTimeFormatter from our formattingUtils
 * with the added functionality of using the timezone from the settings
 */
export const useDate = () => {
    const { settings } = useContext(SettingsContext);

    const dateFormatter = (date: any, returnRawValue?: boolean) => {
        const rawValue =
            date instanceof Date || !(date instanceof Object)
                ? date
                : date.value;
        const timeZones = moment.tz.names();
        const currentTimeZone =
            timeZones[settings?.businessEntitySettings?.timeZone];
        if (rawValue && moment(rawValue).isValid() && !returnRawValue) {
            return moment(rawValue)
                .utc()
                .clone()
                .tz(currentTimeZone ? currentTimeZone : moment.tz.guess(true))
                .format(
                    settings?.businessEntitySettings?.dateFormat
                        ? settings?.businessEntitySettings?.dateFormat
                        : 'MM/DD/YYYY'
                );
        }

        return returnRawValue
            ? moment(rawValue).utc().format('MM/DD/YYYY')
            : '';
    };

    const dateTimeFormatter = (date: any) => {
        const timeFormatter =
            settings?.businessEntitySettings?.timeFormat === '24'
                ? ' HH:mm'
                : ' hh:mm A';
        const timeZones = moment.tz.names();
        const currentTimeZone =
            timeZones[settings?.businessEntitySettings?.timeZone];
        const rawValue = date instanceof Object ? date.value : date;
        return moment(rawValue).isValid()
            ? moment(rawValue)
                  .utc()
                  .clone()
                  .tz(currentTimeZone ? currentTimeZone : moment.tz.guess(true))
                  .format(
                      settings?.businessEntitySettings?.dateFormat
                          ? settings?.businessEntitySettings?.dateFormat +
                                timeFormatter
                          : 'MM/DD/YYYY' + timeFormatter
                  )
            : '';
    };

    return { dateFormatter, dateTimeFormatter };
};

export default useDate;
