import { useEffect, useState } from 'react';
import {
    useListAttachmentSourcesQuery,
    useSearchAttachmentsMutation,
    useStartUploadMutation,
    useUploadAttachmentMutation,
} from '../../../../services/attachments/attachments.service';
import { useTheme } from '@mui/material/styles';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import { MIME_EXTENSION_MAP } from '../../../../constants/attachments/fileTypes.constants';
import { useListEntityTypesQuery } from '../../../../services/system/entityType.service';

const useAttachmentsDrawer = ({
    entityUuid,
    entityType,
    setOpen,
    shouldRefresh,
    setShouldRefresh,
}: any) => {
    const [openAttachmentItem, setOpenAttachmentItem] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [searchAttachments] = useSearchAttachmentsMutation();
    const [startUpload] = useStartUploadMutation();
    const [uploadAttachment] = useUploadAttachmentMutation();
    const { data: entityTypes } = useListEntityTypesQuery();
    const { data: sources } = useListAttachmentSourcesQuery();
    const [attachmentsList, setAttachmentsList] = useState([]);
    const theme = useTheme();

    useEffect(() => {
        if (!isNilOrEmpty(entityUuid) || shouldRefresh) {
            getAttachments();
        }

        if (shouldRefresh) {
            setOpen(true);
            setShouldRefresh(false);
        }
    }, [entityUuid, open, shouldRefresh]);

    const getAttachments = async () => {
        const args = {
            postBody: {
                entityUuids: [entityUuid],
                full: true,
            },
        };
        const response = await searchAttachments(args);
        //@ts-ignore
        if (!isNilOrEmpty(response?.data)) {
            //@ts-ignore
            setAttachmentsList(response?.data);
        }
    };

    const uploadAttachments = async (fileArray: string[], rawFiles: any) => {
        for (let i = 0; i < fileArray.length; i++) {
            //we can probably get all urls at the same time and then upload them all at once
            //but for now we'll do it one at a time
            const response = await startUpload();

            //@ts-ignore
            const parsed = JSON.parse(response.data);

            let binary = window.atob(fileArray[i].split(',')[1]);
            let array = [];
            for (let i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            let blobData = new Blob([new Uint8Array(array)], {
                type: rawFiles[i].type,
            });

            await fetch(parsed.uploadURL, {
                method: 'PUT',
                body: blobData,
                headers: {
                    'Content-Type': rawFiles[i].type,
                },
            });

            const foundEntityType = entityTypes?.find(
                (et: any) => et.code === entityType
            );

            const foundSource = sources?.find((s: any) => s.code === 'user');

            await uploadAttachment({
                postBody: {
                    fileName: rawFiles[i].name,
                    entityUuid: entityUuid,
                    attachmentSourceId: foundSource?.id,
                    entityTypeId: foundEntityType?.id,
                    objectKey: parsed.objectKey,
                    extension: MIME_EXTENSION_MAP[rawFiles[i].type],
                },
            });
        }

        await getAttachments();
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleOpen = (item: any) => {
        setSelectedAttachment(item);
        setOpenAttachmentItem(true);
    };

    return {
        selectedAttachment,
        openAttachmentItem,
        setOpenAttachmentItem,
        handleDrawerClose,
        uploadAttachments,
        attachmentsList,
        handleOpen,
        theme,
        entityTypes,
        sources,
    };
};

export default useAttachmentsDrawer;
