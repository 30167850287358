import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../config';
import { Auth } from 'aws-amplify';
import { supplyChainAppApiTagTypes } from './tagTypes';

// initialize an empty api service that we'll inject endpoints into later as needed
export const supplyChainAppApi = createApi({
    reducerPath: 'supplyChainAppApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.supplyChainGateway.URL,
        prepareHeaders: async (headers, { getState }) => {
            const token = (await Auth.currentSession())
                .getIdToken()
                .getJwtToken();
            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('Authorization', token);
            }

            return headers;
        },
    }),
    tagTypes: supplyChainAppApiTagTypes,
    endpoints: () => ({}),
});

export const supplyChainPublicAppApi = createApi({
    reducerPath: 'supplyChainPublicAppApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.supplyChainGateway.URL,
    }),
    tagTypes: supplyChainAppApiTagTypes,
    endpoints: () => ({}),
});
