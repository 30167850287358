import { RouteProps } from './routesConfig';
import { lazy } from 'react';

const ChangePassword = lazy(() => import('../containers/login/ChangePassword'));
const ChangeEmail = lazy(() => import('../containers/login/ChangeEmail'));
const FeatureFlags = lazy(
    () => import('../containers/settings/featureFlags/FeatureFlags')
);
const ChangeEmailConfirmation = lazy(
    () => import('../containers/login/ChangeEmailConfirmation')
);
const BusinessEntitySettings = lazy(
    () => import('../containers/settings/entitySettings/BusinessEntitySettings')
);
const UserSettings = lazy(
    () => import('../containers/settings/entitySettings/UserSettings')
);
const ModuleSettings = lazy(
    () => import('../containers/settings/entitySettings/ModuleSettings')
);

const SettingsRoutes: RouteProps[] = [
    {
        path: '/settings',
        component: UserSettings,
        featureFlag: 'dashboard.nav.apps.settings',
    },
    {
        path: '/settings/user',
        component: UserSettings,
        featureFlag: 'dashboard.nav.apps.settings',
    },
    {
        path: '/settings/business-entity',
        component: BusinessEntitySettings,
        featureFlag: 'dashboard.nav.apps.settings',
    },
    {
        path: '/settings/module',
        component: ModuleSettings,
        featureFlag: 'dashboard.nav.apps.settings',
    },
    {
        path: '/settings/password',
        component: ChangePassword,
    },
    {
        path: '/settings/email',
        component: ChangeEmail,
    },
    {
        path: '/settings/featureFlags',
        component: FeatureFlags,
        featureFlag: 'settings.viewFeatureFlags',
    },
    {
        path: '/settings/emailConfirmation/:email',
        component: ChangeEmailConfirmation,
    },
];

export default SettingsRoutes;
