import React, { useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import {
    Revision,
    useGetRevisionQuery,
} from '../../../services/organizations/organizations.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { DialogContent, Grid } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DatacorLogoSpinner from '../../datacorLogoSpinner/DatacorLogoSpinner';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import useDate from '../../../utils/hooks/useDate';
import RevisionGridDelta from './RevisionGridDelta';
import DeltaListItem from './DeltaListItem';

interface RevisionItemModalProps {
    item: Revision;
    onClose: () => void;
    open: boolean;
    fieldMappings: any;
}

const RevisionItemModal = ({
    item,
    onClose,
    open,
    fieldMappings,
}: RevisionItemModalProps) => {
    const { data: revision, isLoading: isLoadingRevision } =
        useGetRevisionQuery(item?.id ? item?.id?.toString() : skipToken);

    const [delta, setDelta] = useState(null);
    const [renderDeltaGrid, setRenderDeltaGrid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { dateTimeFormatter } = useDate();
    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        if (!isLoadingRevision && revision?.delta) {
            const parsedRevision = JSON.parse(revision?.delta);
            const checkDelta =
                Object.keys(parsedRevision).length === 0
                    ? null
                    : JSON.parse(revision?.delta);

            setDelta(
                !isNilOrEmpty(checkDelta?.dataValues)
                    ? checkDelta?.dataValues
                    : checkDelta
            );
        } else {
            setDelta(null);
        }
    }, [revision, isLoadingRevision]);

    return (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth={'lg'}>
            <DialogTitle>
                Revision Details -{' '}
                {revision && dateTimeFormatter(revision?.createdAt)}
            </DialogTitle>
            <DialogContent>
                {!isLoadingRevision ? (
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <List
                                sx={{
                                    width: '100%',
                                    bgcolor: 'background.paper',
                                }}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <PersonIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`${revision?.user?.firstName} ${revision?.user?.lastName}`}
                                        secondary={`${revision?.user?.email}`}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <BusinessIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary="Source"
                                        secondary={`${revision?.revisionSource?.name}`}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <SyncAltIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary="Action"
                                        secondary={`${revision?.revisionAction?.name}`}
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={6}>
                            <List
                                sx={{
                                    width: '100%',
                                    bgcolor: 'background.paper',
                                }}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <ArrowBackIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`Changes`}
                                        secondary={
                                            delta ? (
                                                <div
                                                    style={{
                                                        marginLeft: '-15px',
                                                    }}>
                                                    <DeltaListItem
                                                        delta={delta}
                                                        isLoading={isLoading}
                                                        setIsLoading={
                                                            setIsLoading
                                                        }
                                                        setRenderDeltaGrid={
                                                            setRenderDeltaGrid
                                                        }
                                                        fieldMappings={
                                                            fieldMappings
                                                        }
                                                    />
                                                </div>
                                            ) : (
                                                'No changes found...'
                                            )
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12}>
                            <ListItem>
                                <ListItemText
                                    primary=""
                                    secondary={
                                        <>
                                            {renderDeltaGrid && (
                                                <RevisionGridDelta
                                                    delta={delta}
                                                />
                                            )}
                                        </>
                                    }
                                />
                            </ListItem>
                        </Grid>
                    </Grid>
                ) : (
                    <DatacorLogoSpinner />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default RevisionItemModal;
