import { GridDefaults } from './Grid.constants';
import { AgGridReact } from 'ag-grid-react';
import React from 'react';
import { ColumnResizedEvent, GridReadyEvent } from 'ag-grid-community';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const SingleSelectGrid = (props: any) => {
    const dataKey = props.dataKey;
    const immutableData =
        props.immutableData || (dataKey && GridDefaults.immutableData);

    const onGridReady = (params: GridReadyEvent) => {
        if (props.shouldAutofit) {
            params.api.sizeColumnsToFit();
        }
        if (props.onGridReady) {
            props.onGridReady(params);
        }
    };

    const onColumnResize = (params: ColumnResizedEvent) => {
        if (props.onColumnResized) {
            props.onColumnResized(params);
        }
    };

    return (
        <div
            className={'ag-theme-alpine ' + (props.gridClass || '')}
            style={props.gridStyle}
            data-testid={props.testId}>
            {props.displayToolbar && (
                <div className="action-bar">
                    {props.displayCreateNewButton && (
                        <Button
                            variant="text"
                            size="small"
                            onClick={props.onCreateNew}
                            data-testid="create-new-btn"
                            startIcon={<AddCircleIcon />}>
                            Add New
                        </Button>
                    )}
                </div>
            )}
            <AgGridReact
                ref={props.gridRef}
                domLayout={props.domLayout}
                immutableData={immutableData}
                getRowNodeId={dataKey ? (data) => data[dataKey] : null}
                defaultColDef={props.defaultColDef}
                gridOptions={props.gridOptions}
                tooltipShowDelay={props.tooltipShowDelay}
                rowBuffer={props.rowBuffer}
                maxConcurrentDatasourceRequests={
                    props.maxConcurrentDatasourceRequests
                }
                cacheBlockSize={props.cacheBlockSize}
                cacheOverflowSize={props.cacheOverflowSize}
                infiniteInitialRowCount={props.infiniteInitialRowCount}
                maxBlocksInCache={props.maxBlocksInCache}
                pagination={props.pagination}
                paginationPageSize={props.paginationPageSize}
                rowModelType={props.rowModelType}
                rowData={props.rowData}
                datasource={props.dataSource}
                rowStyle={{
                    ...props.rowStyle,
                    cursor: 'pointer',
                }}
                headerHeight={props.headerHeight}
                onFirstDataRendered={props.onFirstDataRendered}
                onSortChanged={props.onSortChanged}
                onFilterChanged={props.onFilterChanged}
                onGridReady={onGridReady}
                onColumnResized={onColumnResize}
                frameworkComponents={props.frameworkComponents}
                onCellClicked={props.onCellClicked}
                rowSelection={props.rowSelection}
                onRowSelected={props.onRowSelected}
                onRowClicked={props.onRowClicked}
                masterDetail={props.masterDetail}
                suppressMenuHide={props.suppressMenuHide}>
                {props.children}
            </AgGridReact>
        </div>
    );
};

SingleSelectGrid.defaultProps = {
    domLayout: GridDefaults.domLayout,
    tooltipShowDelay: GridDefaults.tooltipShowDelay,
    pagination: GridDefaults.pagination,
    paginationPageSize: GridDefaults.paginationPageSize,
    autofitColumns: GridDefaults.sizeColumnsToFit,
    immutableData: GridDefaults.immutableData,
    displayToolbar: GridDefaults.displayToolbar,
};

export default SingleSelectGrid;
