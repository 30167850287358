import { useDispatch, useSelector } from 'react-redux';
import { updateGridState } from '../../../store/grids';
import { RootState } from '../../../store';
import _ from 'lodash';

const useBaseGridState = (
    gridRef: any,
    gridKey: string,
    disableGridState: boolean = false
) => {
    const dispatch = useDispatch();
    const { globalGridState } = useSelector((state: RootState) => state.grids);

    /**
     * Store the state of the grid
     */
    const storeGridState = (): void => {
        if (!disableGridState && gridKey && gridRef) {
            const currentGridState = {
                columnState: gridRef?.current?.columnApi?.getColumnState(),
                filterState: gridRef?.current?.api?.getFilterModel(),
                currentPage: gridRef?.current?.api?.paginationGetCurrentPage(),
            };

            const existingGridState = globalGridState.get(gridKey);

            if (
                !existingGridState ||
                !_.isEqual(existingGridState, currentGridState)
            ) {
                dispatch(
                    updateGridState({
                        gridLocation: 'globalGridState',
                        gridKey: gridKey,
                        gridState: currentGridState,
                    })
                );
            }
        }
    };

    /**
     * Apply the grid state when the component is loaded
     */
    const applyGridState = (): void => {
        if (!disableGridState && gridKey) {
            const gridState = globalGridState.get(gridKey);

            if (gridState && gridState.columnState) {
                gridRef?.current?.columnApi?.applyColumnState({
                    state: gridState.columnState,
                    applyOrder: true,
                });
            }

            if (gridState && gridState.filterState) {
                gridRef?.current?.api?.setFilterModel(gridState.filterState);
            }
        }
    };

    /**
     * Set the grid to the last page the user was on
     */
    const applyPageState = (): void => {
        if (!disableGridState && gridKey) {
            const gridState = globalGridState.get(gridKey);

            if (gridState && gridState.currentPage) {
                gridRef?.current?.api?.paginationGoToPage(
                    gridState.currentPage
                );
            }
        }
    };

    return {
        storeGridState,
        globalGridState,
        applyGridState,
        applyPageState,
    };
};

export default useBaseGridState;
