import { RouteProps } from './routesConfig';
import { PERMISSIONS } from '../constants/permissions/Permissions.constants';
import { ENTITY_TYPES } from '../constants/shared/EntityTypes.constants';
import { lazy } from 'react';

const SupplierTypeGrid = lazy(
    () => import('../containers/supplier/setup/supplierTypes/SupplierTypeGrid')
);
const SupplierStatusGrid = lazy(
    () =>
        import(
            '../containers/supplier/setup/supplierStatuses/SupplierStatusGrid'
        )
);
const SupplierTypeForm = lazy(
    () => import('../containers/supplier/setup/supplierTypes/SupplierTypeForm')
);
const SupplierAddressStatusGrid = lazy(
    () =>
        import(
            '../containers/supplier/setup/supplierAddressStatuses/SupplierAddressStatusGrid'
        )
);
const SupplierStatusForm = lazy(
    () =>
        import(
            '../containers/supplier/setup/supplierStatuses/SupplierStatusForm'
        )
);
const SupplierAddressStatusForm = lazy(
    () =>
        import(
            '../containers/supplier/setup/supplierAddressStatuses/SupplierAddressStatusForm'
        )
);
const SupplierAddressTypeGrid = lazy(
    () =>
        import(
            '../containers/supplier/setup/supplierAddressTypes/SupplierAddressTypeGrid'
        )
);
const SupplierAddressTypeForm = lazy(
    () =>
        import(
            '../containers/supplier/setup/supplierAddressTypes/SupplierAddressTypeForm'
        )
);
const SupplierGrid = lazy(
    () => import('../containers/supplier/suppliers/SupplierGrid')
);
const SupplierForm = lazy(
    () => import('../containers/supplier/suppliers/SupplierForm')
);
const SupplierAddressForm = lazy(
    () => import('../containers/supplier/supplierAddreses/SupplierAddressForm')
);
const SupplierBankAccountForm = lazy(
    () =>
        import(
            '../containers/supplier/supplierBankAccounts/SupplierBankAccountForm'
        )
);

const SupplierRoutes: RouteProps[] = [
    {
        path: '/suppliers/',
        component: SupplierGrid,
        featureFlag: 'dashboard.nav.apps.suppliers',
    },
    {
        path: '/suppliers/supplier',
        component: SupplierGrid,
        permissionKey: PERMISSIONS.SUPPLIERS.SUPPLIER.VIEW,
        featureFlag: 'dashboard.nav.apps.suppliers',
        entityType: ENTITY_TYPES.SUPPLIER,
    },
    {
        path: '/suppliers/supplier/:id',
        component: SupplierForm,
        permissionKey: PERMISSIONS.SUPPLIERS.SUPPLIER.VIEW,
        featureFlag: 'dashboard.nav.apps.suppliers',
        entityType: ENTITY_TYPES.SUPPLIER,
        primaryEntity: true,
    },
    {
        path: '/suppliers/supplierAddress/:id',
        component: SupplierAddressForm,
        permissionKey: PERMISSIONS.SUPPLIERS.SUPPLIER.VIEW,
        featureFlag: 'dashboard.nav.apps.suppliers',
        entityType: ENTITY_TYPES.SUPPLIER_ADDRESS,
        primaryEntity: true,
    },
    {
        path: '/suppliers/setup/type',
        component: SupplierTypeGrid,
        permissionKey: PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.suppliers',
        entityType: ENTITY_TYPES.SUPPLIER_TYPES,
    },
    {
        path: '/suppliers/setup/type/:id',
        component: SupplierTypeForm,
        permissionKey: PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.suppliers',
        entityType: ENTITY_TYPES.SUPPLIER_TYPES,
        primaryEntity: true,
    },
    {
        path: '/suppliers/setup/status',
        component: SupplierStatusGrid,
        permissionKey: PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.suppliers',
        entityType: ENTITY_TYPES.SUPPLIER_STATUS,
    },
    {
        path: '/suppliers/setup/status/:id',
        component: SupplierStatusForm,
        permissionKey: PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.suppliers',
        entityType: ENTITY_TYPES.SUPPLIER_STATUS,
        primaryEntity: true,
    },
    {
        path: '/suppliers/setup/addressStatus',
        component: SupplierAddressStatusGrid,
        permissionKey:
            PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_ADDRESS_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.suppliers',
        entityType: ENTITY_TYPES.SUPPLIER_ADDRESS_STATUSES,
    },
    {
        path: '/suppliers/setup/addressStatus/:id',
        component: SupplierAddressStatusForm,
        permissionKey:
            PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_ADDRESS_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.suppliers',
        entityType: ENTITY_TYPES.SUPPLIER_ADDRESS_STATUSES,
        primaryEntity: true,
    },
    {
        path: '/suppliers/setup/addressType',
        component: SupplierAddressTypeGrid,
        permissionKey: PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_ADDRESS_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.suppliers',
        entityType: ENTITY_TYPES.SUPPLIER_ADDRESS_TYPES,
    },
    {
        path: '/suppliers/setup/addressType/:id',
        component: SupplierAddressTypeForm,
        permissionKey: PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_ADDRESS_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.suppliers',
        entityType: ENTITY_TYPES.SUPPLIER_ADDRESS_TYPES,
        primaryEntity: true,
    },
    {
        path: '/suppliers/setup/bankAccount/:id',
        component: SupplierBankAccountForm,
        permissionKey: PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_BANK_ACCOUNT.VIEW,
        featureFlag: 'dashboard.nav.apps.suppliers',
        entityType: ENTITY_TYPES.SUPPLIER_BANK_ACCOUNT,
        primaryEntity: true,
    },
];

export default SupplierRoutes;
