import React, { useState, useEffect } from 'react';
import { TreeSelect } from 'mui-tree-select';
import TextInput from '../formInputs/TextInput/TextInput';
import { FormMethods } from '../../../libs/hooksLib';
import { Checkbox } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { isNilOrEmpty } from '../../../utils/objectUtils';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface ExpandableDropdownProps {
    valueSelected: (value: number | string) => void;
    defaultValue?: number | string | [];
    items: any[];
    idKey: string;
    labelKey: string;
    label: string;
    formMethods?: FormMethods;
    id?: string;
    required?: boolean;
    size?: 'small' | 'medium';
    disabled?: boolean;
    disableClearable?: boolean;

    multiple?: boolean;
}

export class ExpandableTreeNode {
    id: number | string;
    label: string;
    parentId: number;
    childrenIds: number[];

    constructor(
        id: number | string,
        label: string,
        parentId: number,
        childrenIds: number[] = null
    ) {
        this.id = id;
        this.label = label;
        this.parentId = parentId;
        this.childrenIds = childrenIds;
    }

    toString() {
        return this.label;
    }

    getValue() {
        return this.id;
    }

    isEqual(to: ExpandableTreeNode) {
        return to.id === this.id;
    }

    getParent(nodeMap: Map<number, ExpandableTreeNode>) {
        return nodeMap.get(this.parentId);
    }

    getChildren(nodeMap: Map<number, ExpandableTreeNode>) {
        const children: ExpandableTreeNode[] = [];

        this.childrenIds?.forEach((childrenId) => {
            children.push(nodeMap.get(childrenId));
        });

        return children.length !== 0 ? children : null;
    }
}

const ExpandableDropdown = (props: ExpandableDropdownProps) => {
    const [value, setValue] = useState(props.multiple ? [] : null);
    const [selectedValue, setSelectedValue] = useState('');
    const [nodes, setNodes] = useState(new Map());
    const [rootNodes, setRootNodes] = useState([]);
    let nodeList = new Map();
    let rootNodesList: ExpandableTreeNode[] = [];

    const getChildren = (node: any) => {
        return node === null ? rootNodes : node.getChildren(nodes);
    };

    const getParent = (node: any) => {
        if (!node || node.length <= 0 || isNilOrEmpty(node[0])) {
            return null;
        } else {
            return node?.getParent(nodes) || null;
        }
    };

    const renderInput = (params: any) => {
        // params.inputProps.value = selectedValue;

        return (
            <TextInput
                {...params}
                value={selectedValue}
                label={props.label}
                formMethods={props.formMethods}
                id={props.id}
                required={props.required}
            />
        );
    };

    const renderOption = (props: any, option: any, { selected }: any) => {
        return (
            <li {...props}>
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                />
                {option.label}
            </li>
        );
    };

    const branchSelected = (event: any, node: any) => {
        //if the branch selected event has a checked property
        // then we have click on the checkbox
        //or else we have just clicked the option
        if (
            (!isNilOrEmpty(event.target.checked) && props.multiple) ||
            !props.multiple
        ) {
            if (node !== null) {
                setSelectedValue(node.label);
                props.multiple
                    ? setValue((prev: any) => [...prev, node])
                    : setValue(node);

                props.valueSelected(props.multiple ? node : node.id);
            } else {
                setSelectedValue('');
                setValue(props.multiple ? [] : null);
            }
        }
    };

    const valueSelected = (event: any, node: any) => {
        if (node === null) {
            setValue(props.multiple ? [] : null);
            setSelectedValue('');
            props.valueSelected(null);
            return;
        }
        setValue(node);
        setSelectedValue(node.label);
        props.valueSelected(props.multiple ? node : node.id);
    };

    useEffect(() => {
        if (props.idKey && props.labelKey && props.items) {
            const recursiveMap: any = (item: any, parentId: number) => {
                const itemNode = new ExpandableTreeNode(
                    item[props.idKey],
                    item[props.labelKey],
                    parentId,
                    item.children?.map((child: any) => child[props.idKey])
                );

                if (parentId === null) {
                    rootNodesList.push(itemNode);
                }
                nodeList.set(itemNode.id, itemNode);
                item.children?.map((child: any) =>
                    recursiveMap(child, itemNode.id)
                );
            };

            nodeList = new Map();
            rootNodesList = [];

            props.items.forEach((item: any) => recursiveMap(item, null));
            setRootNodes(rootNodesList);
            setNodes(new Map(nodeList));
        }
    }, [props.items, props.idKey, props.labelKey]);

    useEffect(() => {
        handleSetupNodes();
    }, [props.defaultValue, nodes]);

    const handleSetupNodes = async () => {
        if (props.defaultValue && Array.from(nodes).length > 0) {
            const node = nodes.get(props.defaultValue);
            if (props.multiple) {
                let nodeArr: any = [];
                //@ts-ignore
                if (props.defaultValue?.length > 0) {
                    //@ts-ignore
                    for (const item of props.defaultValue) {
                        if (!isNilOrEmpty(item?.id)) {
                            await nodeArr.push(nodes.get(item?.id));
                        }
                    }
                } else {
                    const defaultValue = props.defaultValue;
                    //@ts-ignore
                    if (defaultValue.length > 0) {
                        nodeArr.push(nodes.get(defaultValue));
                    } else if (!isNilOrEmpty(defaultValue)) {
                        //@ts-ignore
                        nodeArr.push(nodes.get(defaultValue?.id));
                    }
                }
                setValue(nodeArr);
            } else {
                setValue(node);
            }

            setSelectedValue(node ? node.label : '');
        }
    };

    return (
        <TreeSelect
            id={props.id}
            disabled={props.disabled}
            disableClearable={props.disableClearable}
            pathIcon={<></>}
            getChildren={getChildren}
            size={props.size}
            getParent={getParent}
            renderInput={renderInput}
            multiple={props.multiple}
            renderOption={props.multiple && renderOption}
            getOptionKey={(option: any) => option.id}
            value={value}
            disableCloseOnSelect={props.multiple}
            onBranchChange={(event, node) => branchSelected(event, node)}
            onChange={(event, node) => valueSelected(event, node)}
            isOptionEqualToValue={(option, nodeValue) => {
                return (option as ExpandableTreeNode).isEqual(
                    nodeValue as ExpandableTreeNode
                );
            }}
        />
    );
};

export default ExpandableDropdown;
