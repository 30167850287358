export const ENTITY_TYPES = {
    FIXED_ASSET: 'FIXED_ASSET',
    SDS: 'SDS',
    RDF: 'RDF',
    FOLDER: 'FOLDER',
    ATTACHMENT: 'ATTACHMENT',
    USERS: 'USERS',
    SUPPLIER: 'SUPPLIER',
    CUSTOMER: 'CUSTOMER',
    SUPPLIER_INVOICE: 'SUPPLIER_INVOICE',
    CUSTOMER_INVOICE: 'CUSTOMER_INVOICE',
    LOCALE_GROUPS: 'LOCALE_GROUPS',
    SDS_HEADER: 'SDS_HEADER',
    HAZARD_CLASS_MAPPING: 'HAZARD_CLASS_MAPPING',
    HAZARD_RATINGS: 'HAZARD_RATINGS',
    CUSTOMER_STATUSES: 'CUSTOMER_STATUSES',
    CUSTOMER_TYPES: 'CUSTOMER_TYPES',
    CUSTOMER_ADDRESS_STATUSES: 'CUSTOMER_ADDRESS_STATUSES',
    CUSTOMER_ADDRESS_TYPES: 'CUSTOMER_ADDRESS_TYPES',
    CUSTOMER_ADDRESS: 'CUSTOMER_ADDRESS',
    SUPPLIER_TYPES: 'SUPPLIER_TYPES',
    SUPPLIER_STATUS: 'SUPPLIER_STATUS',
    SUPPLIER_ADDRESS_STATUSES: 'SUPPLIER_ADDRESS_STATUSES',
    SUPPLIER_ADDRESS_TYPES: 'SUPPLIER_ADDRESS_TYPES',
    SUPPLIER_ADDRESS: 'SUPPLIER_ADDRESS',
    SUPPLIER_INVOICES: 'SUPPLIER_INVOICES',
    GENERAL_LEDGER_ACCOUNT: 'GENERAL_LEDGER_ACCOUNT',
    FUNCTION_CODE_GROUP: 'FUNCTION_CODE_GROUP',
    CURRENCIES: 'CURRENCIES',
    CURRENCY_RATE_TYPE: 'CURRENCY_RATE_TYPE',
    CURRENCY_EXCHANGE_RATE: 'CURRENCY_EXCHANGE_RATE',
    FISCAL_YEAR_STATUS: 'FISCAL_YEAR_STATUS',
    FISCAL_YEAR: 'FISCAL_YEAR',
    PERIOD_STATUS: 'PERIOD_STATUS',
    PERIOD_QUARTERS: 'PERIOD_QUARTERS',
    PERIOD: 'PERIOD',
    JOURNAL_STATUS: 'JOURNAL_STATUS',
    JOURNAL_TYPE: 'JOURNAL_TYPE',
    PAYMENT_TERM: 'PAYMENT_TERM',
    PAYMENT_TERM_TYPE: 'PAYMENT_TERM_TYPE',
    JOURNALS: 'JOURNALS',
    SEQUENCE: 'SEQUENCE',
    RECURRING_JOURNAL: 'RECURRING_JOURNAL',
    PAYMENT_TERM_STATUS: 'PAYMENT_TERM_STATUS',
    DIMENSION_STATUS: 'DIMENSION_STATUS',
    DIMENSION_TYPES: 'DIMENSION_TYPES',
    DIMENSIONS: 'DIMENSIONS',
    SUPPLIER_INVOICE_STATUS: 'SUPPLIER_INVOICE_STATUS',
    SUPPLIER_INVOICE_TYPE: 'SUPPLIER_INVOICE_TYPE',
    BANK_ACCOUNT_STATUS: 'BANK_ACCOUNT_STATUS',
    BANK_ACCOUNT_TYPE: 'BANK_ACCOUNT_TYPE',
    PAYMENT_STATUS: 'PAYMENT_STATUS',
    PAYMENT_TYPE: 'PAYMENT_TYPE',
    PAYMENT_RUN_TYPE: 'PAYMENT_RUN_TYPE',
    PAYMENT: 'PAYMENT',
    PAYMENT_RUN: 'PAYMENT_RUN',
    CUSTOMER_INVOICE_STATUS: 'CUSTOMER_INVOICE_STATUS',
    CUSTOMER_INVOICE_TYPE: 'CUSTOMER_INVOICE_TYPE',
    CUSTOMER_INVOICES: 'CUSTOMER_INVOICES',
    DEPOSIT_STATUS: 'DEPOSIT_STATUS',
    DEPOSIT_TYPE: 'DEPOSIT_TYPE',
    DEPOSIT: 'DEPOSIT',
    BANK: 'BANK',
    PAYMENT_RUN_STATUS: 'PAYMENT_RUN_STATUS',
    BANK_ACCOUNT: 'BANK_ACCOUNT',
    SUBSCRIBER: 'SUBSCRIBER',
    BUSINESS_ENTITY: 'BUSINESS_ENTITY',
    BUILDING: 'BUILDING',
    GROUP: 'GROUP',
    ROLE: 'ROLE',
    PERMISSIONS: 'PERMISSIONS',
    REVISIONS: 'REVISIONS',
    DEPARTMENT_CODES: 'DEPARTMENT_CODES',
    LANGUAGES: 'LANGUAGES',
    FEATURE_FLAGS: 'FEATURE_FLAGS',
    JOB_RUNS: 'JOB_RUNS',
    JOB_LOGS: 'JOB_LOGS',
    JOB_DEFINITION_CONFIGURATION: 'JOB_DEFINITION_CONFIGURATION',
    SUPPLIER_BANK_ACCOUNT: 'SUPPLIER_BANK_ACCOUNT',
};
