import DeleteColumn from '../cellRenderers/deleteRow.render';

export const editableGridDeleteRow = (params: any) => {
    return {
        maxWidth: 50,
        filter: false,
        resizable: false,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        sortable: false,
        field: 'deleteColumn',
        headerName: '',
    };
};
