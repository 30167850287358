/**
 * all of our editable grid fields we have on the
 * rd formula page
 * we can add or remove fields here as we need them
 */

export const RD_FORMULA_CAS_COMPOSITIONS_FIELDS = {
    casId: 'CAS No.',
    chemicalName: 'CAS Description',
    locked: 'Locked',
    itemId: 'Component ID',
    lowerOperatorId: 'Lower Operator',
    lowerPercentage: 'Lower Percentage',
    upperOperatorId: 'Upper Operator',
    upperPercentage: 'Upper Percentage',
    ecIdentifier: 'EC No.',
    classification: 'Classification',
    reachNumber: 'Reach No.',
    tradeSecret: 'Trade Secret',
};

export const RD_FORMULA_EQUATION_FIELDS = {
    equationCode: 'Equation Code',
    equationDescription: 'Equation Description',
    equationType: 'Equation Type',
    level: 'Level',
    uomId: 'UOM ID',
    result: 'Result',
    propertyId: 'Property ID',
};

export const RD_FORMULA_COMPONENTS_FIELDS = {
    stepNo: 'Step No',
    componentId: 'Component ID',
    componentTypeId: 'Component Type ID',
    formulaQty: 'Formula Qty',
    uomId: 'UOM ID',
    uomCost: 'UOM Cost',
    lockQty: 'Lock Qty',
    scrapPercent: 'Scrap Percent',
    boilerPlateNoteId: 'Boiler Plate Note ID',
    stockUomId: 'Stock UOM ID',
    stockCost: 'Stock Cost',
};

export const RD_FORMULA_HAZARD_RATINGS_FIELDS = {
    sourceId: 'SourceId',
    lock: 'Lock',
    hazardRatingCode: 'Code',
    hazardRatingDescription: 'Description',
    hazardRatingValue: 'Value',
    uomId: 'UOM ID',
    formId: 'Form ID',
};
