import { BaseFormGridProps } from '../BaseFormGrid';
import React from 'react';
import { entityAttachmentSummaryGridColumn } from '../columns/entityAttachmentSummaryGridColumn';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import _ from 'lodash';
import { isFeatureFlagEnabled } from '../../../utils/featureFlag/featureFlagUtil';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';

/**
 * Custom hook for the BaseFormGrid component.  BaseFormGrid wraps BaseGrid which has its own hook, but BaseFormGrid is used for
 * displaying our entity grids whereas BaseGrid is more generic and can also be used in modals, embedded in form grids, etc.
 * This hook should be used when adding logic pertaining to functionality specific to our entity grids (i.e. ItemMaster, Fixed Assets, etc.).
 * useBaseGrid hook should be used when adding general grid functionality that is not specific to our entity grids, i.e. editing cells, sorting,
 * handling user clicks, etc.  For example, entity attachment summary is an entity concept, so the entity attachment summary column logic is here.
 *
 * @param props
 */
const useBaseFormGrid = (props: BaseFormGridProps) => {
    const [isAttachmentSummaryOpen, setIsAttachmentSummaryOpen] =
        React.useState(false);
    const [rowData, setRowData] = React.useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);

    const user = useSelector((state: RootState) => state.user);

    if (
        props.hasEntityAttachments &&
        isFeatureFlagEnabled(
            user.enabledFeatureFlags,
            'feature.apps.attachments'
        ) &&
        PermissionsUtil.hasPermission(PERMISSIONS.ATTACHMENTS.ATTACHMENT.VIEW)
    ) {
        // add the column def if it hasn't been added already by a previous refresh
        const existingAttachmentColumn = props.columnDefs.find(
            (colDef: ColDef) => colDef.field === 'entityAttachmentSummary'
        );

        if (existingAttachmentColumn === undefined) {
            const entityAttachmentColumnDef = entityAttachmentSummaryGridColumn(
                {
                    isAttachmentSummaryOpen,
                    setIsAttachmentSummaryOpen,
                    rowData,
                    setRowData,
                    anchorEl,
                    setAnchorEl,
                }
            );

            let insertIndex = -1;
            if (props.entityAttachmentAfterColumnField) {
                insertIndex = _.findIndex(
                    props.columnDefs,
                    (col: ColDef) =>
                        col.field === props.entityAttachmentAfterColumnField
                );
            }

            if (insertIndex > -1) {
                props.columnDefs.splice(
                    insertIndex + 1,
                    0,
                    entityAttachmentColumnDef
                );
            } else {
                props.columnDefs.push(entityAttachmentColumnDef);
            }
        }
    }

    return {
        attachmentSummaryContextValues: {
            isAttachmentSummaryOpen,
            setIsAttachmentSummaryOpen,
            rowData,
            setRowData,
            anchorEl,
            setAnchorEl,
        },
    };
};

export default useBaseFormGrid;
