// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as serviceWorker from './serviceWorker';
import MomentUtils from '@date-io/moment';
import { LocalizationProvider } from '@mui/x-date-pickers';

//This uses the browser’s History API to create real URLs
import { BrowserRouter as Router } from 'react-router-dom';
import App from './app';
import { Amplify, Auth } from 'aws-amplify';
import config from './config';
import store from './store';
import { Provider } from 'react-redux';

import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey(
    'Using_this_{AG_Grid}_Enterprise_key_{AG-061495}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Datacor,_Inc.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Cloud_ERP}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Cloud_ERP}_need_to_be_licensed___{Cloud_ERP}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{10_June_2025}____[v3]_[01]_MTc0OTUxMDAwMDAwMA==bf90116d8fcceabcebec500900a99d67'
);

// set the various AWS resources that we want to interact with and maintains user session
//NOTES: Amplify refers to Cognito as Auth, S3 as Storage, and API Gateway as API
Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    },
    Storage: {
        region: config.s3.REGION,
        bucket: config.s3.BUCKET,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
    },
    API: {
        endpoints: [
            {
                name: 'datacorAPI',
                endpoint: config.apiGateway.URL,
                region: config.apiGateway.REGION,
            },
            {
                name: 'datacorAPIWithCustAuth',
                endpoint: config.apiGateway.URL,
                region: config.apiGateway.REGION,
                custom_header: async () => {
                    return {
                        Authorization: (await Auth.currentSession())
                            .getIdToken()
                            .getJwtToken(),
                    };
                },
            },
            {
                name: 'formulationAPI',
                endpoint: config.formulationGateway.URL,
                region: config.formulationGateway.REGION,
            },
            {
                name: 'accountingAPI',
                endpoint: config.accountingGateway.URL,
                region: config.accountingGateway.REGION,
            },
            {
                name: 'supplyChainAPI',
                endpoint: config.supplyChainGateway.URL,
                region: config.supplyChainGateway.REGION,
            },
        ],
    },
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <Router>
        <Provider store={store}>
            <LocalizationProvider dateAdapter={MomentUtils}>
                <App />
            </LocalizationProvider>
        </Provider>
    </Router>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
