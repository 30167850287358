import React from 'react';
import Breadcrumbs from '../Breadcrumbs';
import PricingProposalReportGrid from './PricingProposalReportGrid';
import { Card, CardContent } from '@mui/material';

const PricingProposalReport = () => {
    return (
        <Card>
            <CardContent>
                <div className="appPage">
                    <Breadcrumbs active={'pricingProposalReport'} />
                    <PricingProposalReportGrid />
                </div>
            </CardContent>
        </Card>
    );
};

export default PricingProposalReport;
