import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { isFeatureFlagEnabled } from '../../utils/featureFlag/featureFlagUtil';
import { useLocation } from 'react-router-dom';
import { PermissionsUtil } from '../../utils/permissions/permissionsUtil';
import * as Sentry from '@sentry/react';

const useRoutes = () => {
    /* istanbul ignore file */
    const user = useSelector((state: RootState) => state.user);
    const { pathname, search } = useLocation();
    const location = useLocation();

    React.useEffect(() => {
        Sentry.captureMessage(`Navigated to ${location.pathname}`);
    }, [location]);

    const querystring = (name: string, url = window.location.href) => {
        name = name.replace(/[[]]/g, '\\$&');

        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i');
        const results = regex.exec(url);

        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    };

    const shouldLoadRoute = (
        featureFlagKey: string,
        permissionKey: string,
        datacorAdminOnly: boolean
    ): boolean => {
        //this seems a little odd
        // but we still want to load the route, but the auth with handle
        // the redirect if the user is not signed in
        // after the route is loaded
        if (user.isSignedIn === false) {
            return true;
        }
        if (datacorAdminOnly) {
            return user.isDatacorAdmin;
        }

        /**
         * if we only have one of the keys, then we only need to check that one
         */
        if (permissionKey && !featureFlagKey) {
            return PermissionsUtil.isPermissionEnabled(
                user.permissions,
                permissionKey
            );
        }

        if (featureFlagKey && !permissionKey) {
            return isFeatureFlagEnabled(
                user.enabledFeatureFlags,
                featureFlagKey
            );
        }

        /**
         * if we have both keys, then we need to check both
         */
        if (featureFlagKey && permissionKey) {
            return (
                isFeatureFlagEnabled(
                    user.enabledFeatureFlags,
                    featureFlagKey
                ) &&
                PermissionsUtil.isPermissionEnabled(
                    user.permissions,
                    permissionKey
                )
            );
        }

        /**
         * if we have neither key, then we should load the route
         */
        return true;
    };

    const redirect = querystring('redirect');

    return {
        user,
        redirect,
        pathname,
        search,
        shouldLoadRoute,
    };
};

export default useRoutes;
