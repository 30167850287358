import React from 'react';
import {
    currencyFormatter,
    numberFormatter,
} from '../../utils/formattingUtils';

export const DaysWithConcernCell = (props: any) => {
    const consecutiveDaysValue = props.data[props.colDef.field];
    return (
        <span>
            {consecutiveDaysValue ? `${consecutiveDaysValue} Days` : '---'}
        </span>
    );
};

export const DollarsLostConcernCell = (props: any) => {
    const dollarsLostValue = currencyFormatter(
        -Math.abs(props.data[props.colDef.field])
    );
    return (
        <span>
            {-Math.abs(props.data[props.colDef.field]) < 0
                ? dollarsLostValue
                : '---'}
        </span>
    );
};

export const daysWithConcernCellStyle = (props: any) => {
    const consecutiveDaysValue = props.data[props.colDef.field];
    let backgroundColor = 'white';

    if (consecutiveDaysValue >= 70) {
        backgroundColor = 'red';
    } else if (consecutiveDaysValue >= 40 && consecutiveDaysValue < 70) {
        backgroundColor = 'orange';
    } else if (consecutiveDaysValue >= 15 && consecutiveDaysValue < 40) {
        backgroundColor = 'yellow';
    }

    return { backgroundColor: backgroundColor };
};

export const dollarsLostConcernCellStyle = (props: any) => {
    const dollarsLostValue = -props.data[props.colDef.field];
    let backgroundColor = 'white';

    if (dollarsLostValue < -1000) {
        backgroundColor = 'red';
    } else if (dollarsLostValue >= -1000 && dollarsLostValue < -500) {
        backgroundColor = 'orange';
    } else if (dollarsLostValue >= -500 && dollarsLostValue < -250) {
        backgroundColor = 'yellow';
    }

    return { backgroundColor: backgroundColor };
};

export const QuantityUom = (props: any) => {
    const uop = props.data.mostRecentTransaction
        ? props.data.mostRecentTransaction.productUnitOfPackaging
        : props.data.productUnitOfPackaging;
    return <span>{`${numberFormatter(props.data.orderQuantity)} ${uop}`}</span>;
};

export const DollarsPer = (props: any) => {
    const propName = props.colDef.field
        ? 'freightRateUnitMeasure'
        : 'productUnitOfMeasure';
    const uom = props.data.mostRecentTransaction
        ? props.data.mostRecentTransaction[propName]
        : props.data[propName];
    const value = props.colDef.field
        .split('.')
        .reduce((o: any, k: any) => (o || {})[k], props.data);
    return <span>{`${currencyFormatter(value)} / ${uom}`}</span>;
};
