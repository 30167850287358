import React from 'react';
import './ResetPassword.css';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import LoaderButton from '../../../components/LoaderButton';
import useResetPasswordConfirmation from '../hooks/useResetPasswordConfirmation';

const ResetPasswordConfirmation = () => {
    const {
        fields,
        handleFieldChange,
        handleConfirmClick,
        isConfirming,
        validateResetForm,
    } = useResetPasswordConfirmation();

    return (
        <div className="ResetPassword">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography>
                        Please check your email for the confirmation code.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Confirmation Code"
                        data-testid="code"
                        id="code"
                        type="tel"
                        value={fields.code}
                        onChange={handleFieldChange}
                        autoFocus
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        data-testid="email"
                        id="email"
                        type="email"
                        value={fields.email}
                        onChange={handleFieldChange}
                        autoFocus
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="New Password"
                        variant="outlined"
                        data-testid="password"
                        id="password"
                        type="password"
                        value={fields.password}
                        onChange={handleFieldChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Confirm Password"
                        data-testid="confirmPassword"
                        id="confirmPassword"
                        type="password"
                        value={fields.confirmPassword}
                        onChange={handleFieldChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <LoaderButton
                        fullWidth
                        variant="contained"
                        onClick={handleConfirmClick}
                        isLoading={isConfirming}
                        disabled={!validateResetForm()}>
                        Confirm
                    </LoaderButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default ResetPasswordConfirmation;
