import React from 'react';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Paper,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface PricingAnalysisDetailsModalProps {
    show: boolean;
    handleClose: (_: boolean) => void;
}

const PricingAnalysisDetailsModal = ({
    show = false,
    handleClose,
}: PricingAnalysisDetailsModalProps) => {
    return (
        <Dialog open={show} onClose={handleClose}>
            <DialogTitle data-testid="approval-workflows-modal-header">
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>Pricing Analysis Details</Box>
                    <Box>
                        <IconButton onClick={() => handleClose(true)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>

            <DialogContent>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontSize: '16px' }}>
                                    Opportunity Score
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <b>Recency</b>
                                </TableCell>
                                <TableCell>
                                    How recent was the customer's last purchase?
                                    Customers who recently made a purchase will
                                    still have the product on their mind and are
                                    more likely to purchase or use the product
                                    again. Businesses often measure recency in
                                    days. But, depending on the product, they
                                    may measure it in years, weeks or even
                                    hours.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <b>Frequency</b>
                                </TableCell>
                                <TableCell>
                                    How often did this customer make a purchase
                                    in a given period? Customers who purchased
                                    once are often are more likely to purchase
                                    again. Additionally, first time customers
                                    may be good targets for follow-up
                                    advertising to convert them into more
                                    frequent customers.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <b>Monetary</b>
                                </TableCell>
                                <TableCell>
                                    How much money did the customer spend in a
                                    given period? Customers who spend a lot of
                                    money are more likely to spend money in the
                                    future and have a high value to a business.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <b>Outdated Pricing</b>
                                </TableCell>
                                <TableCell>
                                    How much money was lost to transactions
                                    where a price charged had not been changed
                                    in over 6 months?
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <b>Pricing Lag</b>
                                </TableCell>
                                <TableCell>
                                    How much money was lost to transactions
                                    where the cost of materials had increased
                                    but the price charged remained stagnant?
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <b>Below Average Margin</b>
                                </TableCell>
                                <TableCell>
                                    How much money was lost to transactions
                                    where the transactional margin was less than
                                    product average margin.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <b>Below Goal Margin</b>
                                </TableCell>
                                <TableCell>
                                    How much money was lost to transactions
                                    where the transactional margin was less than
                                    order type goal margin.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <b>Below Average Price</b>
                                </TableCell>
                                <TableCell>
                                    How much money was lost to transactions
                                    where the transactional margin was less than
                                    product average price.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ fontSize: '16px' }}>
                                    <b>Pricing Concerns</b>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <b>Outdated Pricing</b>
                                </TableCell>
                                <TableCell>
                                    Order price for a customer/product has not
                                    changes in over 180 days.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <b>Pricing Lag</b>
                                </TableCell>
                                <TableCell>
                                    Product order cost has increased yet
                                    customer/product order price has stagnant.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <b>Below Average Margin</b>
                                </TableCell>
                                <TableCell>
                                    Mean customer/product margin is below mean
                                    average margin.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <b>Below Goal Margin</b>
                                </TableCell>
                                <TableCell>
                                    Mean customer/product margin is below
                                    proposed goal margin.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <b>Below Average Price</b>
                                </TableCell>
                                <TableCell>
                                    Mean customer/product price is below mean
                                    average price.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ fontSize: '16px' }}>
                                    <b>Pricing Concerns Dollars Lost</b>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <b>Outdated Pricing</b>
                                </TableCell>
                                <TableCell>
                                    Sum of dollars lost had each transaction
                                    seen a 1% increase price.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <b>Pricing Lag</b>
                                </TableCell>
                                <TableCell>
                                    Sum of dollars lost had each transaction
                                    price had a price increase equal to the
                                    known cost increase.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <b>Below Average Margin</b>
                                </TableCell>
                                <TableCell>
                                    Sum of dollars lost had each transaction
                                    price had a price increase to obtain the
                                    known product average margin.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <b>Below Goal Margin</b>
                                </TableCell>
                                <TableCell>
                                    Sum of dollars lost had each transaction
                                    price had a price increase to obtain the
                                    known company goal margin.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <b>Below Average Price</b>
                                </TableCell>
                                <TableCell>
                                    Sum of dollars lost had each transaction
                                    price had a price increase equal to the
                                    known product average price.
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
        </Dialog>
    );
};

export default PricingAnalysisDetailsModal;
