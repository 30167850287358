import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import {
    useGetBusinessEntitiesByIdQuery,
    useGetBusinessEntitiesForDropdownQuery,
    useGetBusinessEntitiesQuery,
} from '../../../services/organizations/organizations.service';
import { skipToken } from '@reduxjs/toolkit/query';

interface useBusinessEntitiesProps {
    businessEntityId?: string;
    subscriberId?: string;
}

const useGetBusinessEntities = (params: useBusinessEntitiesProps = {}) => {
    const { businessEntityId, subscriberId } = params;
    const user = useSelector((state: RootState) => state.user);
    const [businessEntities, setBusinessEntities] = useState([]);
    const [businessEntityDropdownOptions, setBusinessEntityDropdownOptions] =
        useState([]);

    const {
        data: adminBusinessEntities,
        isLoading: isLoadingAdminBusinessEntities,
        isFetching: isFetchingAdminBusinessEntities,
    } = useGetBusinessEntitiesQuery(subscriberId ? subscriberId : skipToken);

    const {
        data: businessEntityOptions,
        isLoading: isLoadingBusinessEntityDropdownOptions,
    } = useGetBusinessEntitiesForDropdownQuery(
        subscriberId ? subscriberId : skipToken
    );

    const isLoading =
        isFetchingAdminBusinessEntities || isLoadingAdminBusinessEntities;

    useEffect(() => {
        if (isLoadingAdminBusinessEntities) {
            return;
        }
        setBusinessEntities(adminBusinessEntities);
    }, [user, params, isLoadingAdminBusinessEntities]);

    useEffect(() => {
        if (isLoadingBusinessEntityDropdownOptions) {
            return;
        }
        setBusinessEntityDropdownOptions(businessEntityOptions);
    }, [user, params, isLoadingBusinessEntityDropdownOptions]);

    return {
        businessEntities,
        businessEntityDropdownOptions,
        isLoading,
    };
};

export default useGetBusinessEntities;
