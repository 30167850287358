import {
    GHS_PHRASE_FIELDS,
    GHS_PRECAUTIONARY_PHRASE_FIELDS,
    SDS_CAS_FIELDS,
    SDS_ECO_TOXICITY_FIELDS,
    SDS_EXPOSURE_FIELDS,
    SDS_OPTIONAL_FORMULA_HAZARD_CLASSIFICATION_FIELDS,
    SDS_OPTIONAL_GHS_CLASSIFICATION_FIELDS,
    SDS_OPTIONAL_HAZARD_PHRASES_FIELDS,
    SDS_OPTIONAL_LANGUAGE_FIELDS,
    SDS_OPTIONAL_LOCALES_FIELDS,
    SDS_OPTIONAL_PRECAUTIONARY_PHRASES_FIELDS,
    SDS_OPTIONAL_PROPERTIES_FIELDS,
    SDS_PROPERTY_FIELDS,
    SDS_REGULATION_FIELDS,
    SDS_SELECTED_PHRASE_FIELDS,
    SDS_TOXICITY_FIELDS,
} from './sdsEditableGridRevisions.constants';
import {
    RD_FORMULA_CAS_COMPOSITIONS_FIELDS,
    RD_FORMULA_COMPONENTS_FIELDS,
    RD_FORMULA_EQUATION_FIELDS,
    RD_FORMULA_HAZARD_RATINGS_FIELDS,
} from './rdFormulaEditableGridRevisions.constants';
import {
    JOURNAL_LINES_FIELDS,
    RECURRING_JOURNAL_LINES_FIELDS,
    SUPPLIER_INVOICE_LINES_FIELDS,
    CUSTOMER_INVOICE_LINES_FIELDS,
    PAYMENT_LINES_FIELDS,
} from './accountingEditableGridRevisions.constants';
import {
    ITEM_CHEMICAL_COMPOSITION_FIELDS,
    ITEM_PROPERTIES_FIELDS,
} from './itemMasterGridRevisions.constants';
import {
    CAS_ECO_TOXICITY_FIELDS,
    CAS_EXPOSURE_FIELDS,
    CAS_HAZARD_CLASSIFICATION_FIELDS,
    CAS_HAZARD_PHRASES_FIELDS,
    CAS_PRECAUTIONARY_PHRASES_FIELDS,
    CAS_PROPERTY_FIELDS,
    CAS_REGULATION_FIELDS,
    CAS_TOXICITY_FIELDS,
} from './casEditableGridRevisions.constants';

/**
 * here we need to map our editable grid names to the fields that are used in the grid
 * we can add more grids here as we need them
 */
export const EDITABLE_GRID_MAPPINGS: any = {
    // region ghs hazard / precautionary phrases
    ghsHazardPhrases: GHS_PHRASE_FIELDS,
    ghsPrecautionaryPhrases: GHS_PRECAUTIONARY_PHRASE_FIELDS,
    // endregion
    //region sds
    sdsCasComposition: SDS_CAS_FIELDS,
    sdsExposures: SDS_EXPOSURE_FIELDS,
    sdsProperties: SDS_PROPERTY_FIELDS,
    sdsToxicities: SDS_TOXICITY_FIELDS,
    sdsEcoToxicities: SDS_ECO_TOXICITY_FIELDS,
    sdsRegulations: SDS_REGULATION_FIELDS,
    sdsOptionalFormulaHazardClassification:
        SDS_OPTIONAL_FORMULA_HAZARD_CLASSIFICATION_FIELDS,
    sdsOptionalGhsClassification: SDS_OPTIONAL_GHS_CLASSIFICATION_FIELDS,
    sdsOptionalHazardPhrase: SDS_OPTIONAL_HAZARD_PHRASES_FIELDS,
    sdsOptionalPrecautionaryPhrase: SDS_OPTIONAL_PRECAUTIONARY_PHRASES_FIELDS,
    sdsOptionalProperty: SDS_OPTIONAL_PROPERTIES_FIELDS,
    sdsOptionalLocale: SDS_OPTIONAL_LOCALES_FIELDS,
    sdsOptionalLanguagePhrase: SDS_OPTIONAL_LANGUAGE_FIELDS,
    SdsSelectedOptionalPhrase: SDS_SELECTED_PHRASE_FIELDS,
    // endregion
    //region cas
    casGhsHazardPhrases: CAS_HAZARD_PHRASES_FIELDS,
    casGhsPrecautionaryPhrases: CAS_PRECAUTIONARY_PHRASES_FIELDS,
    casToxicities: CAS_TOXICITY_FIELDS,
    casEcoToxicities: CAS_ECO_TOXICITY_FIELDS,
    casExposures: CAS_EXPOSURE_FIELDS,
    casProperties: CAS_PROPERTY_FIELDS,
    casRegulations: CAS_REGULATION_FIELDS,
    casHazardClassificationMaps: CAS_HAZARD_CLASSIFICATION_FIELDS,
    // end region
    // region rdFormula
    casComposition: RD_FORMULA_CAS_COMPOSITIONS_FIELDS,
    equation: RD_FORMULA_EQUATION_FIELDS,
    components: RD_FORMULA_COMPONENTS_FIELDS,
    hazardRatings: RD_FORMULA_HAZARD_RATINGS_FIELDS,
    // endregion
    // region item master
    itemProperties: ITEM_PROPERTIES_FIELDS,
    chemicalComposition: ITEM_CHEMICAL_COMPOSITION_FIELDS,
    // end region
    // region accounting
    journalLines: JOURNAL_LINES_FIELDS,
    recurringJournalLines: RECURRING_JOURNAL_LINES_FIELDS,
    supplierInvoiceLines: SUPPLIER_INVOICE_LINES_FIELDS,
    customerInvoiceLines: CUSTOMER_INVOICE_LINES_FIELDS,
    paymentLines: PAYMENT_LINES_FIELDS,
    // endregion
};
