import { StringUtils } from '../../utils/objectUtils';
import { BatchTicket, Step } from '../../types/BatchTicket.types';

import moment from 'moment';

export const DATE_TIME_FORMAT = { hour: 'numeric', minute: '2-digit' };

export const checkForStepOverlapOrOutOfOrder = (step: any, events: any) => {
    const stepDetails = step.extendedProps ? step.extendedProps : step;
    let overlapExists = false;
    events.forEach((event: any) => {
        if (step.id !== event.id) {
            const stepEndDate = step.end
                ? step.end
                : moment(step.start).add(1, 'hours');
            const timesOverlap =
                moment(step.start).isBetween(event.start, event.end) ||
                moment(stepEndDate).isBetween(event.start, event.end);
            if (event.BatchNumber === stepDetails.BatchNumber) {
                let areStepsOutOfOrder = false;
                if (event.StepNumber < stepDetails.StepNumber) {
                    areStepsOutOfOrder = moment(step.start).isBefore(event.end);
                } else if (event.StepNumber > stepDetails.StepNumber) {
                    areStepsOutOfOrder = moment(event.start).isBefore(
                        stepEndDate
                    );
                }

                if (timesOverlap || areStepsOutOfOrder) {
                    overlapExists = true;
                }
            }
        }
    });

    return overlapExists;
};

export const convertStepToFullCalendarEvent = (
    step: any,
    backgroundColor: string,
    textColor: string,
    batchTicket: BatchTicket
) => {
    const newStep = { ...step };
    newStep.BatchNumber = newStep.BatchNumber.toString();
    return {
        start: step.StartDateTime,
        end: step.EndDateTime,
        resourceId: step.Workcenter,
        id: step.BatchNumber + '-' + step.StepNumber,
        backgroundColor: backgroundColor,
        textColor: textColor,
        batchTicket: batchTicket,
        ...newStep,
    };
};

export const notEnoughInventoryInWarehouseToCompleteBatchStep = (
    stepDetails: any,
    batchTickets: Array<BatchTicket>
) => {
    const stepComponents = stepDetails.ComponentsAndInstructions.filter(
        (ComponentOrInstruction: any) =>
            StringUtils.equals(ComponentOrInstruction.LineType, 'Component')
    );

    return stepComponents.some((component: any) => {
        const scheduledAmount = scheduledComponentsAndInstructions(
            batchTickets
        ).reduce((r: any, a: any) => {
            const amountScheduled = a.Code === component.Code ? a.Amount : 0;
            return r + amountScheduled;
        }, 0);

        return (
            !component.QuantityOnHand ||
            component.QuantityOnHand < scheduledAmount
        );
    });
};

export const scheduledComponentsAndInstructions = (
    batchTickets: Array<BatchTicket>
) => {
    //Brian S. and I decided this would be a good range, we really should make this a preference though.
    const openBatchTicketThresholds = moment().subtract(1, 'months');

    return batchTickets.reduce((r, a) => {
        return r
            .concat(
                a.Steps.filter(({ StartDateTime }) => {
                    return (
                        StartDateTime &&
                        moment(StartDateTime) >= openBatchTicketThresholds
                    );
                }).map((step) => step.ComponentsAndInstructions)
            )
            .flat();
    }, []);
};

export const getSchedulingErrors = (scheduledSteps: any) => {
    const eventsByEquipment = scheduledSteps.reduce((r: any, a: any) => {
        r[a.resourceId] = [...(r[a.resourceId] || []), a];
        return r;
    }, {});

    const eventsByBatch = scheduledSteps.reduce((r: any, a: any) => {
        r[a.BatchNumber] = [...(r[a.BatchNumber] || []), a];
        return r;
    }, {});

    return checkForOverlaps(eventsByEquipment, 'EQUIPMENT')
        .concat(checkForOverlaps(eventsByBatch, 'TIME'))
        .filter((value: any, index: number, self: any) => {
            return self.indexOf(value) === index;
        });
};

export const checkForOverlaps = (eventsByBatch: object, type: any) => {
    let scheduleErrorsTemp: any = [];
    for (const [key, value] of Object.entries(eventsByBatch)) {
        value.forEach((step: Step) => {
            if (checkForStepOverlapOrOutOfOrder(step, value)) {
                let text;
                if (StringUtils.equals(type, 'TIME')) {
                    text =
                        'Batch steps overlap or are out of order for ' +
                        key +
                        ' on ' +
                        moment(step.start).format('MM/DD/YYYY');
                } else if (StringUtils.equals(type, 'EQUIPMENT')) {
                    text =
                        'Batch steps overlap for ' +
                        key +
                        ' on ' +
                        moment(step.start).format('MM/DD/YYYY');
                }

                scheduleErrorsTemp.push(text);
            }
        });
    }
    return scheduleErrorsTemp;
};
