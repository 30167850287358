import { CellClickedEvent } from 'ag-grid-community';
import DefaultColumnTypes from './Column.constants';
import { EntityAttachmentSummary } from '../../../services/attachments/attachments.service';
import { ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';

export const entityAttachmentSummaryGridColumn = (params: any) => {
    const { setIsAttachmentSummaryOpen, setRowData, setAnchorEl } = params;

    return {
        field: 'entityAttachmentSummary',
        headerName: 'Attachments',
        ...DefaultColumnTypes.attachmentSummaryColumn,
        onCellClicked: (event: CellClickedEvent) => {
            setIsAttachmentSummaryOpen(true);
            setRowData(event.data);
            setAnchorEl(event.event.target);
        },
        comparator: (
            a: EntityAttachmentSummary,
            b: EntityAttachmentSummary
        ) => {
            const aVal: 0 | 1 = a?.attachments !== undefined ? 1 : 0;
            const bVal: 0 | 1 = b?.attachments !== undefined ? 1 : 0;
            return bVal - aVal;
        },
        filterValueGetter: (params: ValueGetterParams) => {
            return Boolean(
                params.getValue('entityAttachmentSummary').attachments !==
                    undefined
            );
        },
    };
};
