import { datacorAppApi } from '../../services/datacorAppApi';
import {
    accountingAppApiTagTypes,
    formulationAppApiTagTypes,
    refreshTags,
    supplyChainAppApiTagTypes,
    workflowAppApiTagTypes,
} from '../../services/tagTypes';
import { accountingAppApi } from '../../services/accountingAppApi';
import { formulationAppApi } from '../../services/formulationAppApi';
import { supplyChainAppApi } from '../../services/supplyChainAppApi';
import { workflowAppApi } from '../../services/workflowAppApi';

const tagsToInvalidate = {
    datacorAppApi: [...refreshTags],
    accountingAppApi: [...accountingAppApiTagTypes],
    formulationAppApi: [...formulationAppApiTagTypes],
    supplyChainAppApi: [...supplyChainAppApiTagTypes],
    workflowAppApi: [...workflowAppApiTagTypes],
};

const apiMappings = {
    datacorAppApi: datacorAppApi,
    accountingAppApi: accountingAppApi,
    formulationAppApi: formulationAppApi,
    supplyChainAppApi: supplyChainAppApi,
    workflowAppApi: workflowAppApi,
};

//helper to manually invalidate tags outside the given service
export const handleInvalidateTags = async (
    dispatch: any,
    api: any = null,
    tags: string[] = null,
    fullReset: boolean = false
) => {
    if (!tags || !api) {
        Object.keys(tagsToInvalidate).forEach((key) => {
            if (key !== 'datacorAppApi' || fullReset) {
                dispatch(
                    //@ts-ignore
                    apiMappings[key].util.resetApiState()
                );
            } else {
                dispatch(
                    //@ts-ignore
                    apiMappings[key].util.invalidateTags(tagsToInvalidate[key])
                );
            }
        });
    } else {
        dispatch(api.util.invalidateTags(tags));
    }
};
