import { createSlice } from '@reduxjs/toolkit';
import { SliceState } from '../types/store/UiElementsStore.types';

const initialState: SliceState = {
    snackbarBasic: {
        message: '',
        isOpen: false,
        autoHideDuration: null,
        anchorOrigin: null,
        severity: null,
    },
    modalConfirmBasic: {
        title: null,
        message: '',
        onConfirm: null,
        isOpen: false,
        onCancel: null,
    },
    gridDrawer: {
        isOpen: false,
        title: null,
        detailText: null,
        grid: null,
    },
    jobStarted: {
        isOpen: false,
        jobName: null,
        jobRunId: null,
    },
    jobItemModal: {
        isOpen: false,
        jobRunId: null,
        onClose: null,
    },
};

const slice = createSlice({
    name: 'uiElements',
    initialState: initialState,
    reducers: {
        clearSnackbarBasic: (state: any) => {
            state.snackbarBasic.isOpen = false;
            state.snackbarBasic.message = '';
            state.snackbarBasic.autoHideDuration = null;
            state.snackbarBasic.anchorOrigin = null;
            state.snackbarBasic.severity = null;
        },
        openSnackbarBasicWithMessage: (state: any, action) => {
            state.snackbarBasic.isOpen = true;
            state.snackbarBasic.message = action.payload.message;
            state.snackbarBasic.autoHideDuration =
                action.payload.autoHideDuration;
            state.snackbarBasic.anchorOrigin = action.payload.anchorOrigin;
            state.snackbarBasic.severity = action.payload.severity;
        },
        clearModalConfirmBasic: (state: any) => {
            state.modalConfirmBasic.isOpen = false;
            state.modalConfirmBasic.title = '';
            state.modalConfirmBasic.message = '';
            state.modalConfirmBasic.onConfirm = null;
        },
        clearGridDrawer: (state: any) => {
            state.gridDrawer.isOpen = false;
            state.gridDrawer.title = '';
            state.gridDrawer.detailText = '';
            state.gridDrawer.grid = null;
        },
        openPersistentDrawer: (state: any, action) => {
            state.gridDrawer.isOpen = true;
            state.gridDrawer.title = action.payload.title;
            state.gridDrawer.detailText = action.payload.detailText;
            state.gridDrawer.grid = action.payload.grid;
        },
        updatePersistentDrawer: (state: any, action) => {
            state.gridDrawer.grid = action.payload.grid;
        },
        openModalConfirmBasicWithMessage: (state: any, action) => {
            state.modalConfirmBasic.isOpen = true;
            state.modalConfirmBasic.title = action.payload.title;
            state.modalConfirmBasic.message = action.payload.message;
            state.modalConfirmBasic.onConfirm = action.payload.onConfirm;
            state.modalConfirmBasic.onCancel = action.payload.onCancel;
            state.modalConfirmBasic.hideCancelButton =
                action.payload.hideCancelButton;
            state.modalConfirmBasic.confirmButtonText =
                action.payload.confirmButtonText;
            state.modalConfirmBasic.declineButtonText =
                action.payload.declineButtonText;
        },
        openJobStartedModal: (state: any, action) => {
            state.jobStarted.isOpen = true;
            state.jobStarted.jobName = action.payload.jobName;
            state.jobStarted.jobRunId = action.payload.jobRunId;
        },
        clearJobStartedModal: (state: any) => {
            state.jobStarted.isOpen = false;
            state.jobStarted.jobName = '';
            state.jobStarted.jobRunId = '';
        },
        openJobItemModal: (state: any, action) => {
            state.jobItemModal.isOpen = true;
            state.jobItemModal.jobRunId = action.payload.jobRunId;
            state.jobItemModal.onClose = action.payload.onClose;
        },
        clearJobItemModal: (state: any) => {
            state.jobItemModal.isOpen = false;
            state.jobItemModal.jobRunId = '';
            state.jobItemModal.onClose = null;
        },
    },
});

export default slice.reducer;

// Actions
export const {
    clearSnackbarBasic,
    openSnackbarBasicWithMessage,
    clearModalConfirmBasic,
    openModalConfirmBasicWithMessage,
    clearGridDrawer,
    openPersistentDrawer,
    updatePersistentDrawer,
    openJobStartedModal,
    clearJobStartedModal,
    openJobItemModal,
    clearJobItemModal,
} = slice.actions;
