//This causes issues when trying to run tests, no good solution found yet.
//see https://github.com/wojtekmaj/react-pdf#enable-pdfjs-worker to why we have to import
//problem is test suite cannot compile this lib and mocking it out seemingly had no effect
//TODO: find good solution
import { Document, Page, pdfjs } from 'react-pdf';
import React from 'react';
import DatacorLogoSpinner from '../../components/datacorLogoSpinner/DatacorLogoSpinner';
import { Button, ButtonGroup } from '@mui/material';
import useInvoicePreview from './hooks/useInvoicePreview';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url
).toString();

interface InvoicePreviewProps {
    documentId?: string;
    pdfUrl?: string;
}
const InvoicePreview = ({ documentId, pdfUrl }: InvoicePreviewProps) => {
    const {
        activeDocumentPdfUrl,
        isLoadingPdfUrl,
        page,
        setPage,
        numPages,
        scale,
        setScale,
        onDocumentLoadSuccess,
    } = useInvoicePreview(documentId);

    return (
        <>
            {!isLoadingPdfUrl ? (
                <>
                    <div className="pdf-buttons">
                        <ButtonGroup>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => setPage(page - 1)}
                                disabled={page === 1}>
                                Previous Page
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => setPage(page + 1)}
                                disabled={page === numPages}>
                                Next Page
                            </Button>
                        </ButtonGroup>
                        <ButtonGroup>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    setScale(scale + 0.05);
                                }}>
                                Zoom +
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    setScale(scale - 0.05);
                                }}>
                                Zoom -
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    setScale(1);
                                }}>
                                Reset Zoom
                            </Button>
                        </ButtonGroup>
                    </div>

                    <p>
                        Page: {page} / {numPages}
                    </p>

                    <Document
                        file={pdfUrl ? pdfUrl : activeDocumentPdfUrl}
                        onLoadSuccess={onDocumentLoadSuccess}>
                        <div
                            style={{
                                display: 'grid',
                                placeItems: 'center',
                                overflow: 'scroll',
                                height: '600px',
                            }}>
                            <Page pageNumber={page} scale={scale} />
                        </div>
                    </Document>
                </>
            ) : (
                <DatacorLogoSpinner />
            )}
        </>
    );
};

export default InvoicePreview;
