import React from 'react';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import SettingsIcon from '@mui/icons-material/Settings';
import CallToActionOutlinedIcon from '@mui/icons-material/CallToActionOutlined';
import SortIcon from '@mui/icons-material/Sort';
import TranslatableText from '../i18n/TranslatableText';
import { SharedComponentsDefs } from '../../constants/i18n/translations/termDefinitions/platform';
import { isNilOrEmpty } from '../../utils/objectUtils';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { openPersistentDrawer } from '../../store/uiElements';
import { useDispatch } from 'react-redux';
import JobStartedModal from '../modals/JobStartedModal';
import JobItemModal from '../administration/jobs/JobItemModal';
import RefreshIcon from '@mui/icons-material/Refresh';

interface BaseGridToolbarProps {
    displayProps: any;
    helperMethods: any;
    editableGrid: any;
    popoutGrid: any;
    entitySettings: any;
    childrenProps: any;
    useRowDrag: any;
    sortGrid: any;
    rowData: any;
    helpers: any;
    termSet?: any;
    getAllRows?: any;
    customFormTermSet?: any;
    showDeleteButton?: any;
}
const BaseGridToolbar = (props: BaseGridToolbarProps) => {
    const {
        displayCreateNewButton,
        displayExportDataButton,
        displayImportDataButton,
        displayEnableDisableButton,
        displayResetStateButton,
        displayEntitySettings,
        displayDrawer,
        displaySortButton,
    } = props.displayProps;

    const {
        onCreateNew,
        exportDataAsExcel,
        onImportData,
        resetState,
        onEnableDisable,
        termSet,
        onRefetchRowData,
    } = props.helperMethods;

    const { useRowDrag } = props;

    const { setOpenSortGrid, openSortGrid } = props.sortGrid;

    const { isEditable, createNewEditableRow } = props.editableGrid;
    const dispatch = useDispatch();
    const {
        gridButtonTitle,
        setOpenGridDrawer,
        title,
        detailText,
        grid,
        persist,
    } = props.popoutGrid;

    const { setOpenSettings } = props.entitySettings;

    const { toolbarChildren } = props.childrenProps;

    const onClick = (event: any) => {
        setOpenSettings(true);
    };

    /**
     * when determining if we should show inline or modal sort
     * we need to use the non-deleted rows
     * **/
    const removeDeleted = props
        .getAllRows()
        ?.filter((row: any) => row?.rowStatus !== 'deleted');

    const removeDeletedProps = props.rowData?.filter(
        (row: any) => row?.rowStatus !== 'deleted'
    );

    const getTranslatableText = (termKey: string) => {
        if (!isNilOrEmpty(props.customFormTermSet)) {
            const termDefs: any[] =
                props.customFormTermSet?.languageTermSetDef?.termDefs;
            if (termDefs?.find((termDef: any) => termDef.code === termKey)) {
                return (
                    <TranslatableText
                        termSet={props.customFormTermSet}
                        termKey={termKey}
                    />
                );
            }
        }

        return <TranslatableText termSet={termSet} termKey={termKey} />;
    };

    const handleOpenGridDrawer = () => {
        if (persist) {
            dispatch(
                openPersistentDrawer({
                    title: title,
                    detailText: detailText,
                    grid: grid,
                })
            );
        } else {
            setOpenGridDrawer((prev: any) => !prev);
        }
    };

    return (
        <div className="action-bar">
            {displayCreateNewButton && !isEditable && !gridButtonTitle && (
                <Button
                    variant="text"
                    size="small"
                    onClick={onCreateNew}
                    data-testid="create-new-btn"
                    startIcon={<AddCircleIcon />}>
                    {getTranslatableText(SharedComponentsDefs.Add_New)}
                </Button>
            )}
            {(isEditable || gridButtonTitle) && (
                <>
                    {displayCreateNewButton && (
                        <Button
                            variant="text"
                            size="small"
                            onClick={
                                onCreateNew ? onCreateNew : createNewEditableRow
                            }
                            data-testid="create-new-btn"
                            startIcon={<AddCircleIcon />}>
                            {'Add ' + (gridButtonTitle || 'New')}
                        </Button>
                    )}
                    {displayCreateNewButton && props.showDeleteButton && (
                        <Button
                            variant="text"
                            size="small"
                            onClick={props.editableGrid?.handleDeleteRows}
                            color={'error'}
                            data-testid="delete-btn"
                            startIcon={<RemoveCircleIcon />}>
                            {getTranslatableText(SharedComponentsDefs.Remove)}
                        </Button>
                    )}
                </>
            )}
            {displayExportDataButton && (
                <Button
                    variant="text"
                    size="small"
                    onClick={exportDataAsExcel}
                    data-testid="bulk-download-btn"
                    startIcon={<FileDownloadIcon />}>
                    {getTranslatableText(SharedComponentsDefs.Bulk_Download)}
                </Button>
            )}
            {displayImportDataButton && (
                <Button
                    variant="text"
                    size="small"
                    onClick={onImportData}
                    data-testid="bulk-upload-btn"
                    startIcon={<FileUploadIcon />}>
                    {getTranslatableText(SharedComponentsDefs.Bulk_Upload)}
                </Button>
            )}
            {displayResetStateButton && (
                <Button
                    variant="text"
                    size="small"
                    onClick={resetState}
                    data-testid="reset-state-btn"
                    startIcon={<RestorePageIcon />}>
                    {getTranslatableText(SharedComponentsDefs.Reset_View)}
                </Button>
            )}
            {displayEnableDisableButton && (
                <Button
                    variant="text"
                    size="small"
                    onClick={onEnableDisable}
                    data-testid="enable-disable-btn">
                    {getTranslatableText(
                        SharedComponentsDefs.Enable_Disable_Selected
                    )}
                </Button>
            )}
            {displayEntitySettings && (
                <Button
                    variant="text"
                    size="small"
                    className="grid-create-new"
                    onClick={onClick}
                    startIcon={<SettingsIcon />}>
                    {getTranslatableText(SharedComponentsDefs.Settings)}
                </Button>
            )}
            {displayDrawer && (
                <Button
                    variant="text"
                    size="small"
                    className="grid-create-new"
                    onClick={handleOpenGridDrawer}
                    startIcon={<CallToActionOutlinedIcon />}>
                    {getTranslatableText(SharedComponentsDefs.Dock_Title)}
                </Button>
            )}

            {displaySortButton &&
                (props.helpers.paginationPageSize < removeDeleted.length ||
                    props.helpers.paginationPageSize <
                        removeDeletedProps.length) && (
                    <Button
                        variant="text"
                        size="small"
                        className="grid-create-new"
                        onClick={() => setOpenSortGrid(true)}
                        startIcon={<SortIcon />}>
                        {getTranslatableText(SharedComponentsDefs.Sort)}
                    </Button>
                )}

            {onRefetchRowData && (
                <Button
                    variant="text"
                    size="small"
                    className="grid-create-new"
                    onClick={() => onRefetchRowData()}
                    startIcon={<RefreshIcon />}>
                    {getTranslatableText(SharedComponentsDefs.Refetch)}
                </Button>
            )}
            {toolbarChildren}
            <JobStartedModal />
            <JobItemModal />
        </div>
    );
};

export default BaseGridToolbar;
