import React, { useEffect, useState } from 'react';
import {
    Checkbox,
    Grid,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    Tab,
    Popover,
} from '@mui/material';
import {
    useGetAllCompletedUserTasksQuery,
    useGetAllOpenUserTasksQuery,
    useMarkCompleteMutation,
    useUpdateTaskMutation,
} from '../../../services/task-manager/task-manager.service';
import useDate from '../../../utils/hooks/useDate';
import {
    organizationsApi,
    useGetUsersForDropdownQuery,
    User,
    useUpdateUserMutation,
} from '../../../services/organizations/organizations.service';
import BaseGrid from '../../grids/BaseGrid';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import TabPanel from '../../form/tabPanel/TabPanel';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { handleUpdateUserBusinessEntity } from '../../../store/user';
import { BaseUpdateEntityArgs } from '../../../services/serviceInterfaces';
import { openSnackbarBasicWithMessage } from '../../../store/uiElements';
import CustomHeader from '../../grids/CustomHeader';
import { TaskManagerDefs } from '../../../constants/i18n/translations/termDefinitions/platform';
import useTranslation from '../../i18n/hooks/useTranslation';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';
import TranslatableText from '../../i18n/TranslatableText';
import DefaultColumnTypes from '../../grids/columns/Column.constants';

const TaskListPopover = ({
    open,
    setOpen,
    setNewTaskPopoverOpen,
    setSelectedTask,
    isPersonal,
    setIsPersonal,
    anchorEl,
    setAnchorEl,
}: any) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const { data: openTasks } = useGetAllOpenUserTasksQuery();
    const { data: completedTasks } = useGetAllCompletedUserTasksQuery();
    const [markComplete] = useMarkCompleteMutation();
    const [updateUser] = useUpdateUserMutation();
    const [updateTask] = useUpdateTaskMutation();
    const { data: userList } = useGetUsersForDropdownQuery();
    const { dateFormatter } = useDate();
    const [tabValue, setTabValue] = useState(0);
    const { termSet } = useTranslation(PLATFORM_DEFS.TASK_MANAGER);
    const [rowsToComplete, setRowsToComplete] = useState<any[]>([]);
    const [rowsToUncomplete, setRowsToUncomplete] = useState<any[]>([]);

    useEffect(() => {
        if (!open) {
            while (rowsToComplete.length > 0) {
                const row = rowsToComplete.pop();

                markComplete({ id: row.data.id });
            }

            while (rowsToUncomplete.length > 0) {
                const row = rowsToUncomplete.pop();
                const updateArgs: any = {
                    id: row.data.id,
                    postBody: {
                        completedByUserId: null,
                        completedAt: null,
                    },
                };

                updateTask(updateArgs);
            }
        }
    }, [open, rowsToComplete, rowsToUncomplete]);

    useEffect(() => {
        while (rowsToComplete.length > 0) {
            const row = rowsToComplete.pop();

            markComplete({ id: row.data.id });
        }

        while (rowsToUncomplete.length > 0) {
            const row = rowsToUncomplete.pop();
            const updateArgs: any = {
                id: row.data.id,
                postBody: {
                    completedByUserId: null,
                    completedAt: null,
                },
            };

            updateTask(updateArgs);
        }
    }, [tabValue]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenNewTask = (personal?: boolean) => {
        setNewTaskPopoverOpen(true);

        if (personal) {
            setIsPersonal(true);
        }
    };

    const colDefs = [
        {
            field: 'completedByUserId',
            cellRenderer: 'checkboxRenderer',
            type: 'centerAligned',
            headerName: '',
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'title',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={TaskManagerDefs.Title_Header}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'taskTypeId',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={TaskManagerDefs.Task_Type_Header}
                    />
                );
            },
            valueFormatter: (params: any) => {
                const taskType = params.data.taskType;
                return taskType?.actionTitle;
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'AssignmentType',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={TaskManagerDefs.Assignment_Type_Header}
                    />
                );
            },
            valueFormatter: (params: any) => {
                return params.data.taskUsers ? 'User' : 'Group';
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'createdAt',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={TaskManagerDefs.Created_At_Header}
                    />
                );
            },
            valueFormatter: (params: any) => {
                return dateFormatter(params.value);
            },
            ...DefaultColumnTypes.DateOnly,
        },
        {
            field: 'dueAt',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={TaskManagerDefs.Due_At_Header}
                    />
                );
            },
            valueFormatter: (params: any) => {
                return dateFormatter(params.value);
            },
            ...DefaultColumnTypes.DateOnly,
        },
        {
            field: 'createdByUserId',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={TaskManagerDefs.Assigned_By_Header}
                    />
                );
            },
            valueFormatter: (params: any) => {
                const user = userList?.find(
                    (u: any) => u.value === params.value
                );
                return user?.label;
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'notes',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={TaskManagerDefs.Notes_Header}
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
        {
            field: 'goToEntity',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={TaskManagerDefs.Go_To_Item_Header}
                    />
                );
            },
            cellRenderer: 'goToEntityRenderer',
            type: 'leftAligned',
            ...DefaultColumnTypes.MediumText,
        },
    ];

    const goToEntityRenderer = (params: any) => {
        if (!params.data.entityPath) {
            return <></>;
        }

        return (
            <Button
                ref={(ref) => {
                    if (!ref) {
                        return;
                    }
                    ref.onclick = (e) => {
                        e.stopPropagation();
                        handleButtonClicked(params, e);
                    };
                }}
                variant="text"
                size="small"
                onClick={(e: any) => {
                    e.stopPropagation();
                    handleButtonClicked(params, e);
                }}>
                {params.data.entityPageTitle}
            </Button>
        );
    };

    const handleButtonClicked = async (params: any, e: any) => {
        if (user?.businessEntity?.id !== params.data.businessEntityId) {
            try {
                const businessEntity: any = await dispatch(
                    organizationsApi.endpoints.getBusinessEntity.initiate(
                        params?.data?.businessEntityId?.toString()
                    )
                );

                const { data: businessEntityData } = businessEntity;

                dispatch(handleUpdateUserBusinessEntity(businessEntityData));

                const args: BaseUpdateEntityArgs<User> = {
                    id: user?.userId as unknown as number,
                    ignoreRevision: 'true',
                    postBody: {
                        activeWebBusinessEntityId: params.data.businessEntityId,
                    },
                };

                updateUser(args);

                navigate(params.data.entityPath);
            } catch (error) {
                dispatch(
                    openSnackbarBasicWithMessage({
                        message: `Unable to switch business entity. Please try again.`,
                        severity: 'error',
                    })
                );
            }
        } else {
            navigate(params.data.entityPath);
        }
    };

    const checkboxRenderer = (params: any) => {
        const [isChecked, setIsChecked] = useState(
            params.data.completedByUserId !== null
        );
        return (
            <span
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}>
                <Checkbox
                    ref={(ref) => {
                        if (!ref) {
                            return;
                        }
                        ref.onclick = (e) => {
                            e.stopPropagation();
                            if (params.data.completedByUserId !== null) {
                                setIsChecked(false);
                                onRowSelectedCompleted(params);
                            } else {
                                setIsChecked(true);
                                onRowSelected(params);
                            }
                        };
                    }}
                    size="small"
                    checked={isChecked}
                    onChange={(e: any) => {
                        if (params.data.completedByUserId !== null) {
                            onRowSelectedCompleted(params);
                        } else {
                            onRowSelected(params);
                        }
                    }}
                />
            </span>
        );
    };
    const onRowClicked = (row: any) => {
        setNewTaskPopoverOpen(true);
        setSelectedTask(row?.data);
    };

    const onRowSelected = (row: any) => {
        setRowsToComplete((prev: any) => [...prev, row]);
    };

    const onRowSelectedCompleted = (row: any) => {
        setRowsToUncomplete((prev: any) => [...prev, row]);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <Popover
            onClose={handleClose}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            slotProps={{
                paper: {
                    style: {
                        width: '60%',
                        height: '60%',
                    },
                },
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}>
            <div style={{ padding: '10px' }}>
                <Button
                    variant="text"
                    size="small"
                    onClick={() => handleOpenNewTask(false)}
                    startIcon={<AddCircleIcon />}>
                    <TranslatableText
                        termSet={termSet}
                        termKey={TaskManagerDefs.New_Task_Button_Text}
                    />
                </Button>
                <Button
                    variant="text"
                    size="small"
                    onClick={() => handleOpenNewTask(true)}
                    startIcon={<AddCircleIcon />}>
                    <TranslatableText
                        termSet={termSet}
                        termKey={TaskManagerDefs.New_Personal_Task_Button_Text}
                    />
                </Button>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        aria-label="basic tabs example">
                        <Tab label="Open Tasks" />
                        <Tab label="Recently Completed" />
                    </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                    <BaseGrid
                        gridClass="full-size-item"
                        defaultColDef={{
                            sortable: true,
                            filter: true,
                            resizable: true,
                            wrapText: true,
                        }}
                        displayGrid={true}
                        columnDefs={colDefs}
                        rowData={openTasks}
                        onRowClicked={onRowClicked}
                        components={{
                            goToEntityRenderer: goToEntityRenderer,
                            checkboxRenderer: checkboxRenderer,
                        }}
                        onRowSelected={onRowSelected}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <BaseGrid
                        gridClass="full-size-item"
                        displayGrid={true}
                        columnDefs={colDefs}
                        rowData={completedTasks}
                        onRowClicked={onRowClicked}
                        components={{
                            goToEntityRenderer: goToEntityRenderer,
                            checkboxRenderer: checkboxRenderer,
                        }}
                        onRowSelected={onRowSelectedCompleted}
                    />
                </TabPanel>
            </div>
        </Popover>
    );
};

export default TaskListPopover;
