import React, { useEffect, useState } from 'react';

const parameterParser = (inputString: string, replacementObject: any) => {
    return inputString?.replace(
        /\${(.*?)}/g,
        (_, key) => replacementObject[key] || ''
    );
};

const TranslatableText = ({ termKey, termSet, parserObj = null }: any) => {
    const [def, setDef] = useState(null);
    const [translatedTerm, setTranslatedTerm] = useState(null);

    useEffect(() => {
        const termId = termSet?.languageTermSetDef?.termDefs?.find(
            (def: any) => def.code === termKey
        );
        if (termId) {
            setDef(termId);
        }
    }, [termKey, termSet]);

    useEffect(() => {
        if (def) {
            const term = termSet.languageTerms?.find(
                (term: any) => term.languageTermDefId === def?.id
            );
            if (term) {
                setTranslatedTerm(term);
            } else {
                setTranslatedTerm(null);
            }
        }
    }, [def]);

    return (
        <>
            {parameterParser(translatedTerm?.translatedValue, parserObj) ||
                parameterParser(def?.defaultValue, parserObj) ||
                ''}
        </>
    );
};

export default TranslatableText;
