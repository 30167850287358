import React from 'react';
import LoaderButton from '../../../components/LoaderButton';
import {
    Grid,
    TextField,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import useRecommendationForm from '../hooks/PricingConcernDetails/useRecommendationForm';

const RecommendationForm = ({
    handleClose = null,
    mostRecentTransaction = null,
}) => {
    const {
        fields,
        handleFieldChange,
        handleSubmitClick,
        validateForm,
        isSubmitting,
    } = useRecommendationForm({ handleClose, mostRecentTransaction });

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    id="recommendedPrice"
                    label="Recommended Price"
                    fullWidth
                    variant="outlined"
                    type="currency"
                    placeholder="0.00"
                    data-testid="suggestedPrice"
                    onChange={handleFieldChange}
                    value={fields.recommendedPrice}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="comment"
                    label="Notes"
                    multiline
                    rows={3}
                    fullWidth
                    variant="outlined"
                    type="text"
                    placeholder="0.00"
                    data-testid="suggestedPrice"
                    onChange={handleFieldChange}
                    value={fields.comment}
                />
            </Grid>
            <Grid item xs={12}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={handleFieldChange}
                                checked={fields.emailAgent}
                                data-testid="emailAgent"
                                id="emailAgent"
                            />
                        }
                        label={`Email recommendation to ${mostRecentTransaction.salesAgentName} at ${mostRecentTransaction.salesAgentEmail}`}
                    />
                </FormGroup>
            </Grid>
            <Grid item xs={12}>
                <LoaderButton
                    color="primary"
                    onClick={handleSubmitClick}
                    variant="contained"
                    type="submit"
                    disabled={!validateForm()}
                    isLoading={isSubmitting}>
                    Submit
                </LoaderButton>
            </Grid>
        </Grid>
    );
};

export default RecommendationForm;
