import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import {
    CellPosition,
    Column,
    DetailGridInfo,
    RowNode,
} from 'ag-grid-enterprise';
import { ValueSetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { ITooltipParams } from 'ag-grid-community/dist/lib/rendering/tooltipComponent';
import { RowDataChangedEvent } from 'ag-grid-community';
import { ErrorEntity } from '../../../types/Shared.types';
import { BaseGridProps } from '../Grids.propTypes';
import SingleSelectPopupsContext from '../../../contexts/singleSelectPopups.context';
import { isNilOrEmpty } from '../../../utils/objectUtils';

export enum RowStatus {
    ADDED = 'added',
    EDITED = 'edited',
    NEW = 'new',
    DELETED = 'deleted',
    SORTED = 'sorted',
}

export interface GenericEditableRow extends ErrorEntity {
    [param: string]: any;

    rowStatus: RowStatus;
    rowId: number;
    rowIndex?: number;
}

const UseBaseGridEditable = (
    props: BaseGridProps,
    gridRef?: React.RefObject<DetailGridInfo>
) => {
    /**
     * editable grid hook props **/
    const {
        onHandleRowValidations,
        newRowPlaceholderValues,
        onHandleGridEdits,
        dataKey,
    } = props;

    const useDataKey = dataKey;

    const [deletedRows, setDeletedRows] = useState([]);
    const [maxRowId, setMaxRowId] = useState(0);
    const [invalidRows, setInvalidRows] = useState(
        null as GenericEditableRow[]
    );

    const { gridPopups, setGridPopups } = useContext(SingleSelectPopupsContext);
    const createNewEditableRow = (): any => {
        const rowData = getAllRows();
        let concatenatedRowData = [...rowData.concat(...deletedRows)];
        let rowDataMaxIdRecord = _.maxBy(concatenatedRowData, 'rowId');
        let rowDataMaxId = rowDataMaxIdRecord?.rowId || 0;

        setMaxRowId(rowDataMaxId + 1);

        const newRow = newRowPlaceholderValues
            ? {
                  rowId: rowDataMaxId + 1,
                  rowStatus: RowStatus.NEW,
                  rowInitialStatus: RowStatus.NEW,
                  ...newRowPlaceholderValues,
              }
            : { rowId: rowDataMaxId + 1, rowStatus: RowStatus.NEW };
        if (props.onHandleNewRow) {
            props.onHandleNewRow(newRow, rowData);
            newRow['rowStatus'] = RowStatus.EDITED;
            gridRef.current!.api.applyTransaction({
                addIndex: 0,
                add: [newRow],
            });
            handleEditedData();
        } else {
            gridRef.current!.api.applyTransaction({
                addIndex: 0,
                add: [newRow],
            });
        }

        return newRow;
    };

    const handleValidationOnDataChange = (event: RowDataChangedEvent) => {
        setInvalidRowsRecords();
    };

    const handleNewValue = (params: ValueSetterParams) => {
        const editingCell = getEditingCell();
        if (editingCell) {
            const currentEditingRow = getCurrentEditingRow();
            if (params) {
                // Update the edited row with the new value and set the rowStatus to ADDED
                // @ts-ignore
                currentEditingRow[editingCell.column.colId] = params.newValue;
                currentEditingRow.rowStatus = RowStatus.ADDED;
                if (props.onHandleColumnChanged) {
                    props.onHandleColumnChanged(
                        editingCell.column.getColId(),
                        currentEditingRow,
                        getAllRows()
                    );
                }
            }
            handleEditedData();
        }
        return true;
    };

    const handleDeleteRow = (data: any) => {
        deleteRowByRowId(data.rowId);
        handleEditedData();
    };

    const handleDeleteRows = (selectedRows: any[]) => {
        const rowsToDelete = gridRef.current!.api.getSelectedRows();
        rowsToDelete.forEach((row: any) => {
            deleteRowByRowId(row.rowId);
        });

        if (props.onHandleDeleteRow) {
            props.onHandleDeleteRow(rowsToDelete, getAllRows());
        }

        handleEditedData();
    };

    const cleanPlaceholderValues = (row: GenericEditableRow) => {
        if (newRowPlaceholderValues) {
            const rowFields = Object.keys(row);
            rowFields.forEach((rowField) => {
                if (row[rowField] === newRowPlaceholderValues[rowField]) {
                    row[rowField] = '';
                }
            });
        }
        return row;
    };

    const handleEditedData = () => {
        const rows: GenericEditableRow[] = getAllRowsByStatuses([
            RowStatus.ADDED,
            RowStatus.EDITED,
        ]);

        if (onHandleRowValidations) {
            handleRowValidations(rows);
        } else {
            const errorRows = onHandleGridEdits(rows);
            handleErrorData(errorRows);
            updateRowData(rows);
        }
        setInvalidRowsRecords();
    };

    /**
     * Identify rows with errors
     * @param editedRows
     */
    const handleRowValidations = (editedRows: any[]) => {
        const evaluatedRows: any = onHandleRowValidations(editedRows);
        const errorRows = evaluatedRows?.filter(
            (row: GenericEditableRow) => row.hasError && row.errors?.length > 0
        );
        onHandleGridEdits(evaluatedRows);
        handleErrorData(errorRows);
        updateRowData(evaluatedRows);
    };

    /**
     * Gets all AgGrid rows
     */
    const getAllRows = (): any[] => {
        let rowData: any = [];
        gridRef?.current?.api?.forEachNode((node: RowNode) =>
            rowData.push(node.data)
        );
        return rowData;
    };

    const getAllRowsAfterFilterAndSort = (): any[] => {
        let rowData: any = [];
        gridRef.current!.api.forEachNodeAfterFilterAndSort((node: RowNode) =>
            rowData.push(node.data)
        );
        return rowData;
    };

    const getAllRowsByStatuses = (
        rowStatuses: RowStatus[] = [
            RowStatus.NEW,
            RowStatus.EDITED,
            RowStatus.ADDED,
            RowStatus.DELETED,
        ]
    ): GenericEditableRow[] => {
        const gridRows = getAllRows();
        const allChangedRows = gridRows.concat(...deletedRows);
        const filteredRows = allChangedRows.filter(
            (row: GenericEditableRow) =>
                rowStatuses.includes(row.rowStatus) ||
                row.rowStatus === undefined
        );
        return filteredRows.map((filteredRow) =>
            cleanPlaceholderValues(filteredRow)
        );
    };

    /**
     * Maps AgGrid rows with a hasError status if a validation error is found
     * @param errorRows
     */
    const handleErrorData = (errorRows: any) => {
        // reset all hasError on rows to false
        clearAllGridErrors();
        if (errorRows) {
            //set hasError to true on all gridRows matching rowIds from the errorRows
            const errorRowIds = errorRows?.map(
                (errorRow: any) => errorRow.rowId
            );
            setErrorsOnRows(errorRowIds);
        }
    };

    /**
     * Sets hasError to false on all AgGrid rows
     */
    const clearAllGridErrors = () => {
        const gridRows = getAllRows();
        gridRows.forEach(
            (gridRow: GenericEditableRow) => (gridRow.hasError = false)
        );

        updateRowData(gridRows);
    };

    /**
     * Sets hasError to flag for all matching rowIds in AgGrid rows
     */
    const setErrorsOnRows = (rowIds: number[], flag = true) => {
        const gridRows = getAllRows();
        gridRows.map((row: GenericEditableRow) => {
            if (rowIds.includes(row.rowId)) {
                row.hasError = true;
            }

            return row;
        });

        updateRowData(gridRows);
    };

    /**
     * Returns the first cell instance that is being edited
     */
    const getEditingCell = (): CellPosition => {
        return _.first(gridRef.current!.api.getEditingCells());
    };

    /**
     * Update and refresh rowData on the AgGrid
     * @param rowData
     */
    const updateRowData = (rowData: any) => {
        gridRef.current!.api.setRowData(rowData);
    };

    /**
     * Returns the full row of data where an edit is occurring
     */
    const getCurrentEditingRow = (): GenericEditableRow => {
        const editingCell = getEditingCell();
        const rowDataIndex = editingCell.rowIndex;
        const rowData = getAllRowsAfterFilterAndSort();

        return rowData[rowDataIndex];
    };

    /**
     * Gets all AgGrid data rows and removes row by rowId
     * @param rowId
     */
    const deleteRowByRowId = (rowId: number) => {
        const rowData = getAllRows();
        const index = rowData.findIndex((row: any) => row.rowId === rowId);
        const deletedRow = rowData.splice(index, 1);
        deletedRow[0].rowStatus = RowStatus.DELETED;
        deletedRows.push(deletedRow);
        setDeletedRows(deletedRows);
        updateRowData(rowData);
    };

    const handleErrorTooltipGetter = (params: ITooltipParams) => {
        const currentRow: GenericEditableRow = params?.data;
        if (currentRow && currentRow.hasError && currentRow.errors) {
            return currentRow.errors[0]?.errorMessage;
        }
        return null;
    };

    const setInvalidRowsRecords = () => {
        if (onHandleRowValidations) {
            const allRows = getAllRowsByStatuses([
                RowStatus.EDITED,
                RowStatus.ADDED,
            ]);
            const invalidRows = allRows?.filter(
                (current) => current.hasError && current.errors?.length > 0
            );
            if (invalidRows?.length > 0) {
                setRowsIndex(invalidRows);
                setInvalidRows(invalidRows);
            } else {
                setInvalidRows(null);
            }
        }
    };

    const setRowsIndex = (rowList: GenericEditableRow[]) => {
        if (rowList) {
            rowList.forEach((current) => {
                const node = getRowNodeByDataKey(current.rowId);
                current.rowIndex = node?.rowIndex;
            });
        }
    };

    const setSelectedInvalidRow = (rowId: number) => {
        const dataKeyColumn = gridRef.current!.columnApi.getColumn(dataKey);
        const firstColumn = gridRef
            .current!.columnApi.getAllDisplayedColumns()
            .find((column) => column.getColId() !== 'deleteColumn');
        const column = dataKeyColumn ? dataKeyColumn : firstColumn;
        focusRowCell(column, rowId);
    };

    const focusRowCell = (column: Column, rowId: number) => {
        if (column != null) {
            const node = getRowNodeByDataKey(rowId);
            if (node) {
                const rowPage = Math.floor(
                    node.rowIndex / gridRef.current!.api.paginationGetPageSize()
                );
                if (
                    rowPage !== gridRef.current!.api.paginationGetCurrentPage()
                ) {
                    gridRef.current!.api.paginationGoToPage(rowPage);
                }
                gridRef.current!.api.setFocusedCell(node.rowIndex, column);
            }
        }
    };

    const getRowNodeByDataKey = (rowId: number) => {
        const row = getAllRows().find((current) => current.rowId === rowId);
        if (row) {
            const dataKey = row[useDataKey];
            const node = gridRef.current!.api.getRowNode(dataKey);
            return node;
        }
        return null;
    };

    //This is useful when the grid is not being updated by the user,
    // but by an external event and the grid needs to validate the rows.
    useEffect(() => {
        if (props.forceValidation && !isNilOrEmpty(onHandleRowValidations)) {
            onHandleRowValidations(getAllRows());
            setInvalidRowsRecords();
            props.setForceValidation(false);
        }
    }, [props.forceValidation]);

    useEffect(() => {
        // Only update the columnDefs if the grid is no longer editable
        if (gridRef?.current?.api && !props.isEditable) {
            const newColumns = gridRef?.current?.api?.getColumnDefs();
            newColumns.forEach((newColumn: any) => {
                newColumn.editable = props.isEditable;
            });
            gridRef?.current?.api?.setColumnDefs(newColumns);
        }
    }, [props.isEditable]);

    return {
        invalidRows,
        createNewEditableRow,
        handleNewValue,
        handleEditedData,
        handleDeleteRow,
        handleDeleteRows,
        handleErrorTooltipGetter,
        setSelectedInvalidRow,
        handleValidationOnDataChange,
        getAllRows,
        deletedRows,
        updateRowData,
    };
};

export default UseBaseGridEditable;
