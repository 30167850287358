import React from 'react';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useGridDrawer from './useGridDrawer';
import Grid from '@mui/material/Grid';

const drawerWidth = '400';

export default function GridDrawer({
    open,
    setOpen,
    title,
    detailText,
    grid,
}: any) {
    const { handleDrawerClose, theme } = useGridDrawer({ setOpen });

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    marginLeft: 42,
                },
            }}
            variant="persistent"
            anchor="bottom"
            open={open}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div
                        onClick={handleDrawerClose}
                        style={{
                            cursor: 'pointer',
                            backgroundColor: '#007CBB',
                            display: 'grid',
                            placeItems: 'center',
                        }}>
                        <IconButton
                            onClick={handleDrawerClose}
                            style={{ color: 'white' }}>
                            {theme.direction === 'rtl' ? (
                                <ExpandLessIcon />
                            ) : (
                                <ExpandMoreIcon />
                            )}
                        </IconButton>
                    </div>
                    <h3 style={{ paddingLeft: '20px' }}>
                        {detailText} - {title}
                    </h3>
                    <Divider />
                    <div style={{ padding: '20px' }}>{grid}</div>
                </Grid>
            </Grid>
        </Drawer>
    );
}
