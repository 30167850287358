import React from 'react';
import RecommendationGrid from './RecommendationGrid';
import RecommendationForm from './RecommendationForm';
import { PricingDetail } from '../../../types/PricingAnalysis.types';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface RecommendationModalProps {
    show: boolean;
    customerProductData: PricingDetail;
    handleClose: (_: boolean) => void;
}

const RecommendationModal = ({
    show = false,
    customerProductData = null,
    handleClose,
}: RecommendationModalProps) => {
    return customerProductData && customerProductData.mostRecentTransaction ? (
        <Dialog
            open={show}
            onClose={handleClose}
            data-testid="recommendation-modal">
            <DialogTitle data-testid="approval-workflows-modal-header">
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>Make Recommendation</Box>
                    <Box>
                        <IconButton onClick={() => handleClose(true)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>

            <DialogContent>
                <RecommendationForm
                    handleClose={handleClose}
                    mostRecentTransaction={
                        customerProductData.mostRecentTransaction
                    }
                />
                <br />
                <h1 style={{ fontFamily: 'Open Sans', fontSize: '24px' }}>
                    Previous Recommendations
                </h1>
                <RecommendationGrid
                    externalCustomerId={
                        customerProductData.mostRecentTransaction
                            .externalCustomerId
                    }
                    productId={
                        customerProductData.mostRecentTransaction.productId
                    }
                />
            </DialogContent>
        </Dialog>
    ) : null;
};

export default RecommendationModal;
