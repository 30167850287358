import React from 'react';
import useDocumentDesigner from './hooks/useDocumentDesigner';

const DocumentDesigner = ({ documentId, dataSourceId, designerRef }: any) => {
    useDocumentDesigner(documentId, dataSourceId, designerRef);

    return (
        <div id="designerContainer" style={{ width: '100%', height: '100%' }} />
    );
};

export default DocumentDesigner;
