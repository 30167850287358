import { createContext } from 'react';
import { AttributeQueries } from '../../../types/platform/attributes/attributes.types';

export interface Form {
    fields: any;
    handleFieldChange: (e: any) => void;
    formMethods: any;
    termSet: any;
    formType: any;
    isFormSubmitted: any;

    permissionBase: string;

    getEntity: (params: any) => any;
    getEntityParams: any;
    updateEntity: (params: any) => any;
    updateEntityParams: any;
    deleteEntity: (params: any) => any;
    deleteEntityParams: any;
    createEntity: (params: any) => any;
    createEntityParams: any;

    setTermSet: (termSet: any) => void;
    setFields: (fields: any) => void;
    setFormType: (formType: any) => void;
    setIsFormSubmitted: (isFormSubmitted: any) => void;

    customContent?: any;
    setCustomContent?: (customContent: any) => void;
    attributeQueries?: AttributeQueries;
}

const FormContext = createContext<Form>({
    fields: null,
    handleFieldChange: null,
    formMethods: null,
    termSet: null,
    formType: null,
    isFormSubmitted: null,

    permissionBase: null,

    getEntity: (params: any) => {},
    getEntityParams: null,
    updateEntity: (params: any) => {},
    updateEntityParams: null,
    deleteEntity: (params: any) => {},
    deleteEntityParams: null,
    createEntity: (params: any) => {},
    createEntityParams: null,

    setTermSet: (params: any) => {},
    setFields: (params: any) => {},
    setFormType: (params: any) => {},
    setIsFormSubmitted: (any) => {},

    customContent: {},
    setCustomContent: (params: any) => {},
    attributeQueries: {} as AttributeQueries,
});

export default FormContext;
