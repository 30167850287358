import { useNavigate } from 'react-router-dom';
import { useGetTaskQuery } from '../../../services/task-manager/task-manager.service';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import React from 'react';

const TaskNotificationBody = ({ notification }: any) => {
    let navigate = useNavigate();
    const { data: taskData } = useGetTaskQuery(
        JSON.parse(notification.notification.eventContext.variableData).id
    );

    const handleGoToEntity = () => {
        navigate(taskData.entityPath);
    };
    return (
        <div style={{ paddingLeft: '30px', marginTop: '-10px' }}>
            Task: {taskData?.entityPageTitle} {taskData?.title}
            {taskData?.entityPath && (
                <Tooltip title={'Go to Item'}>
                    <IconButton>
                        <OpenInNewIcon onClick={handleGoToEntity} />
                    </IconButton>
                </Tooltip>
            )}
        </div>
    );
};

export default TaskNotificationBody;
