import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    SdsCasComposition,
    SdsEcoToxicity,
    SdsExposure,
    SdsGhsHazardPhrase,
    SdsGhsPrecautionaryPhrase,
    SdsHeader,
    SdsIdentification,
    SdsOtherInformation,
    SdsPictogram,
    SdsProperty,
    SdsRegulation,
    SdsToxicity,
    SdsTransportation,
} from '../../types/formulation';
import * as _ from 'lodash';

const initialState: any = {
    sdsCode: '',
    sdsDescription: '',
    itemCodeId: null as bigint,
    status: null as bigint,
    template: false,
    versionNumber: 1,
    versionDate: null as Date,
    langId: null as bigint,
    localeGroupId: null,
    substance: null,
    businessEntityId: null,
    sdsIdentification: {
        casId: null,
        productName: null,
        registrationNumber: '',
        additionalCode: null,
        synonyms: null,
        productUseDescription: null,
        restrictionOfUse: null,
        certified: null,
        certificationDate: null,
        certificationUserId: null,
        formulaId: null,
        contactName: null,
        contactPhone1: null,
        contactPhoneDescription1: null,
        contactPhone2: null,
        contactPhoneDescription2: null,
        supplierId: null,
        supplierAddressId: null,
    },
    sdsPictogram: {
        sdsPictogramSignalWordId: 1,
        explosive: false,
        flammable: false,
        oxidizing: false,
        compressedGas: false,
        toxic: false,
        corrosive: false,
        irritant: false,
        healthHazard: false,
        environmentDamaging: false,
    },
    sdsGhsHazardPhrases: [] as SdsGhsHazardPhrase[],
    sdsGhsPrecautionaryPhrases: [] as SdsGhsPrecautionaryPhrase[],
    sdsExposures: [] as SdsExposure[],
    sdsProperties: [] as SdsProperty[],
    sdsCasComposition: [] as SdsCasComposition[],
    sdsRegulations: [] as SdsRegulation[],
    sdsEcoToxicities: [] as SdsEcoToxicity[],
    sdsToxicities: [] as SdsToxicity[],
    sdsTransportation: {
        landProperShippingNameId: null,
        landProperShippingNameDescription: null,
        airProperShippingNameId: null,
        airProperShippingNameDescription: null,
        seaProperShippingNameId: null,
        seaProperShippingNameDescription: null,
    },
    sdsOtherInformation: {
        hmisChronic: null,
        webAddress: null,
        hmisHealthId: null,
        hmisFlammabilityId: null,
        hmisPhysicalHazardId: null,
        hmisEquipmentId: null,
        nfpaHealthId: null,
        nfpaFlammabilityId: null,
        nfpaReactivityId: null,
        nfpaSpecificHazardId: null,
    },
    blobData: null,
};

const slice = createSlice({
    name: 'sds',
    initialState: initialState,
    reducers: {
        setSdsData: (state: any, action) => {
            _.merge(state, action.payload);
        },
        setSdsIdentification: (
            state,
            action: PayloadAction<SdsIdentification>
        ) => {
            state.sdsIdentification = {
                ...state.sdsIdentification,
                ...action.payload,
            };
        },
        setSdsPictogram: (state: any, action: PayloadAction<SdsPictogram>) => {
            state.sdsPictogram = { ...state.sdsPictogram, ...action.payload };
        },
        addGhsHazardPhrase: (
            state: any,
            action: PayloadAction<SdsGhsHazardPhrase>
        ) => {
            state.sdsGhsHazardPhrases.push(action.payload);
        },
        removeGhsHazardPhrase: (state: any, action: PayloadAction<number>) => {
            state.sdsGhsHazardPhrases.splice(action.payload, 1);
        },
        setSdsGhsHazardPhrases: (
            state: any,
            action: PayloadAction<SdsGhsHazardPhrase[]>
        ) => {
            state.sdsGhsHazardPhrases = action.payload;
        },
        // Repeat similar actions for other arrays
        setTransportation: (
            state: any,
            action: PayloadAction<Partial<SdsTransportation>>
        ) => {
            state.sdsTransportation = {
                ...state.sdsTransportation,
                ...action.payload,
            };
        },
        setOtherInformation: (
            state: any,
            action: PayloadAction<Partial<SdsOtherInformation>>
        ) => {
            state.sdsOtherInformation = {
                ...state.sdsOtherInformation,
                ...action.payload,
            };
        },
        resetSds: () => initialState,
        setSdsBlobData: (state: any, action: PayloadAction<any>) => {
            state.sds.blobData = action.payload;
        },
        resetSdsBlobData: (state: any) => {
            state.blobData = null;
        },
    },
});

export default slice.reducer;

//TODO: specify actions for export
export const {
    setSdsData,
    setSdsIdentification,
    setSdsPictogram,
    addGhsHazardPhrase,
    removeGhsHazardPhrase,
    setSdsGhsHazardPhrases,
    setTransportation,
    setOtherInformation,
    resetSds,
    setSdsBlobData,
    resetSdsBlobData,
} = slice.actions;

export const selectSds = (state: any) => state.sds;
export const selectSdsIdentification = (state: any) =>
    state.sds.sdsIdentification;
export const selectSdsPictogram = (state: any) => state.sds.sdsPictogram;
export const selectGhsHazardPhrases = (state: any) =>
    state.sds.sdsGhsHazardPhrases;
export const selectGhsPrecautionaryPhrases = (state: any) =>
    state.sds.sdsGhsPrecautionaryPhrases;
export const selectExposures = (state: any) => state.sds.sdsExposures;
export const selectProperties = (state: any) => state.sds.sdsProperties;
export const selectCasComposition = (state: any) => state.sds.sdsCasComposition;
export const selectRegulations = (state: any) => state.sds.sdsRegulations;
export const selectEcoToxicities = (state: any) => state.sds.sdsEcoToxicities;
export const selectToxicities = (state: any) => state.sds.sdsToxicities;
export const selectTransportation = (state: any) => state.sds.sdsTransportation;
export const selectOtherInformation = (state: any) =>
    state.sds.sdsOtherInformation;
export const selectSdsHeader = (state: any) => state.sds;
export const selectSdsBlobData = (state: any) => state.sds.blobData;
