import React from 'react';
import './Login.css';
import { Link } from 'react-router-dom';
import { TextField, Grid, Card, CardHeader } from '@mui/material';
import LoaderButton from '../../components/LoaderButton';
import useLogin from './hooks/useLogin';

const Login = () => {
    const { user, fields, handleFieldChange, loginAction, validateForm } =
        useLogin();

    return (
        <Card className="Login">
            {user.hasInactiveTrial ? (
                <CardHeader title="Trial is inactive. Please contact adminstrator for assistance."></CardHeader>
            ) : null}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        onKeyPress={(event: any) => {
                            if (event.key === 'Enter') {
                                loginAction(event);
                            }
                        }}
                        fullWidth
                        variant="outlined"
                        required
                        id="email"
                        label="Email"
                        type="email"
                        data-testid="email"
                        value={fields.email}
                        onChange={handleFieldChange}
                        autoComplete="email"
                        autoFocus
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        onKeyPress={(event: any) => {
                            if (event.key === 'Enter') {
                                loginAction(event);
                            }
                        }}
                        required
                        id="password"
                        label="Password"
                        variant="outlined"
                        type="password"
                        data-testid="password"
                        onChange={handleFieldChange}
                        autoComplete="password"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Link to="/login/reset">Forgot password?</Link>
                </Grid>

                <Grid item xs={12}>
                    <LoaderButton
                        fullWidth
                        isLoading={user.isLoading}
                        onClick={loginAction}
                        type="submit"
                        variant="contained"
                        disabled={!validateForm()}>
                        Login
                    </LoaderButton>
                </Grid>
            </Grid>
        </Card>
    );
};

export default Login;
