import React, { useMemo } from 'react';
import { useListSystemsQuery } from '../../services/system/system.service';
import BaseFormGrid from '../../components/grids/BaseFormGrid';
import { ColDef } from 'ag-grid-community';

const SystemOverview = () => {
    const { data: systems, isLoading } = useListSystemsQuery();

    const [colDefs, setColDefs] = React.useState<any>([
        {
            headerName: 'Code',
            field: 'code',
            sortable: true,
            filter: true,
            resizable: true,
            width: 300,
        },
        {
            headerName: 'Name',
            field: 'name',
            sortable: true,
            filter: true,
            resizable: true,
            width: 300,
        },
        {
            headerName: 'Description',
            field: 'description',
            sortable: true,
            filter: true,
            resizable: true,
            width: 300,
        },
        {
            headerName: 'Path',
            field: 'path',
            sortable: true,
            filter: true,
            resizable: true,
            width: 300,
        },
    ]);

    const buildTreeData = (data: any) => {
        const flatTree: any = [];
        data.map((item: any) => {
            flatTree.push({ ...item, filePath: [item.name] });
            return {
                ...item,
                children: item.applications.map((app: any) => {
                    flatTree.push({ ...app, filePath: [item.name, app.name] });
                    return {
                        ...app,
                        filePath: [item.name, app.name],
                        children: app.services.map((service: any) => {
                            flatTree.push({
                                ...service,
                                filePath: [item.name, app.name, service.name],
                            });
                            return {
                                ...service,
                                filePath: [item.name, app.name, service.name],
                                children: service.entityTypes.map(
                                    (entity: any) => {
                                        flatTree.push({
                                            ...entity,
                                            filePath: [
                                                item.name,
                                                app.name,
                                                service.name,
                                                entity.name,
                                            ],
                                        });
                                    }
                                ),
                            };
                        }),
                    };
                }),
            };
        });
        return flatTree;
    };

    const autoGroupColumnDef = useMemo<ColDef>(() => {
        return {
            headerName: 'System Overview',
            minWidth: 330,
            rowDrag: false,
            filter: 'agTextColumnFilter',
        };
    }, [systems]);

    return (
        <BaseFormGrid
            isLoading={isLoading}
            displayGrid={true}
            treeData
            columnDefs={colDefs}
            gridClass="full-size-item"
            rowData={systems && buildTreeData(systems)}
            autoGroupColumnDef={autoGroupColumnDef}
            getDataPath={(data: any) => data.filePath}
        />
    );
};

export default SystemOverview;
