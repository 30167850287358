const organizationTags = [
    'Subscribers',
    'BusinessEntities',
    'BusinessEntityTypes',
    'BusinessEntityUsers',
    'BusinessEntityRoles',
    'BusinessEntityGroups',
    'BusinessEntityPermissions',
    'MessagingPreferences',
    'DepartmentCodes',
    'Buildings',
    'Languages',
    'UnitOfMeasure',
    'UnitOfMeasureGlobal',
    'ActiveUnitOfMeasure',
    'UnitOfMeasureConversion',
    'ActiveUnitOfMeasureConversion',
    'ActiveProperShippingName',
    'ProperShippingName',
    'UnitType',
    'Revisions',
    'BoilerPlateNote',
    'ActiveBoilerPlateNote',
    'EntitySetting',
    'SupplierTypes',
];

const refreshOrg = [
    'BusinessEntityTypes',
    'BusinessEntityUsers',
    'BusinessEntityRoles',
    'BusinessEntityGroups',
    'BusinessEntityPermissions',
    'MessagingPreferences',
    'DepartmentCodes',
    'Buildings',
    'Languages',
    'UnitOfMeasure',
    'ActiveUnitOfMeasure',
    'UnitOfMeasureConversion',
    'ActiveUnitOfMeasureConversion',
    'ActiveProperShippingName',
    'ProperShippingName',
    'UnitType',
    'Revisions',
    'BoilerPlateNote',
    'ActiveBoilerPlateNote',
    'EntitySetting',
];

const permissionsTagTypes = ['Role', 'Permissions'];

const accessTagTypes = [
    'FeatureFlags',
    'GlobalFeatureFlags',
    'AccessRestrictions',
];

const accountingTagTypes = [
    'AllGeneralLedgerAccounts',
    'ActiveGeneralLedgerAccount',
    'AllContracts',
    'ActiveContract',
    'AllContractTypes',
    'ActiveContractType',
    'FunctionCodes',
    'ActiveFunctionCode',
    'AllFixedAssetAccountFunctions',
    'FunctionCodesByGroup',
    'AllAccountStatuses',
    'AllAccountTypes',
    'AllBalanceTypes',
    'AllRecoveryUnits',
    'AllBuildings',
    'List_PeriodQuarter',
    'Active_PeriodQuarter',
    'List_Period',
    'Active_Period',
    'Search_Period',
    'Dropdown_Period',
    'Search_FiscalYear',
    'Dropdown_FiscalYearWithPeriods',
    'List_BankAccountBy',
    'Dropdown_AccountNames',
    'Search_PaymentTerm',
    'Search_SupplierInvoice',
    'Dropdown_VoucherNumber',
    'Dropdown_PayableAccounts',
    'Dropdown_AccountNumber',
    'Dropdown_DefaultSupplierInvoiceType',
    'Search_PaymentRun',
    'Search_Deposit',
];

const supplierTagTypes = [
    'List_ActiveSupplier',
    'List_ActiveSupplierAddressBy',
    'Search_Supplier',
    'List_SupplierBankAccountBy',
];

const customerTagTypes = [
    'List_CustomerAddressBy',
    'List_Customer_Attachments',
    'Search_Customer',
    'List_ActiveCustomer',
    'List_ActiveCustomerAddressBy',
];

const journalTagTypes = [
    'List_JournalStatus',
    'Active_JournalStatus',
    'List_JournalType',
    'Active_JournalType',
    'JournalSequence',
    'Search_Journal',
    'Dropdown_JournalNumber',
    'Dropdown_JournalType',
];

const supplierInvoiceTagTypes = ['SupplierInvoiceSequence'];

const customerInvoiceTagTypes = [
    'CustomerInvoiceSequence',
    'Search_CustomerInvoice',
    'CustomerStatementReport',
    'Dropdown_ListCustomerInvoiceType',
];

const apaTagTypes = [
    'Invoice',
    'Approvers',
    'Users',
    'Addresses',
    'Tolerance',
    'Comment',
    'Workflows',
];

const appStreamingTagTypes = ['stream'];

const attributeTagTypes = [
    'AttributeDefinitions',
    'AttributeTemplates',
    'AttributeEntities',
];

const featureFlagTagTypes = ['FeatureFlags'];

const fixedAssetTagTypes = [
    'AllFixedAssets',
    'ActiveFixedAsset',
    'AllStatuses',
    'ActiveStatus',
    'AllCostCodes',
    'ActiveCostCode',
    'AllAccountGroups',
    'ActiveAccountGroup',
    'AllTypes',
    'ActiveType',
    'AllDepreciationMethodStatuses',
    'ActiveDepreciationMethodStatus',
    'AllDepreciationMethodTypes',
    'ActiveDepreciationMethodType',
    'AllDepreciationMethods',
    'ActiveDepreciationMethod',
    'FA_DepreciationSchedules',
    'FA_DepreciationEntries',
    'FA_AssetContract',
    'FA_DisposalEntries',
    'AssetEntries',
    'AcquisitionReport',
    'DisposalReport',
    'DepreciationDetailReport',
    'DepreciationSummaryReport',
    'List_FA_Asset',
];

const casMasterTagTypes = ['Cas', 'ActiveCas'];

const casPicSignalWordTagTypes = ['CasPictogramSignalWord'];

const casSpeciesTagTypes = ['CasSpeciesClass'];

const casToxicRoutesTagType = ['CasToxicRoutes'];

const casHazardClassMappingsTagTypes = ['casHazardClassMappings'];

const exposureAgencyTagTypes = ['ExposureAgency', 'ActiveExposureAgency'];

const propertyTagTypes = ['PropertyType'];

const formulaPropertyTagTypes = ['FormulaProperty', 'ActiveFormulaProperty'];

const hazardTagTypes = ['HazardType'];

const ghsHazardTagTypes = ['GhsHazardPhrase'];

const hazardClassTagTypes = ['HazardClassification'];

const inventoryTypeTagTypes = ['InventoryType'];

const itemMasterTagTypes = ['ItemMaster', 'ActiveItemMaster'];

const itemStatusTagTypes = ['ItemStatus'];

const itemPropertiesTagTypes = ['ItemProperty'];

const localeTagTypes = ['Locale'];

const localeGroupTagTypes = ['LocaleGroup', 'ActiveLocaleGroup'];

const statementTypesTagTypes = ['StatementTypes'];

const precautionaryPhrasesTagTypes = [
    'PrecautionaryPhrases',
    'ActivePrecautionaryPhrase',
];

const rdFormulaTagTypes = [
    'ApprovalType',
    'Formula',
    'ActiveFormula',
    'FormulaStatus',
    'RDFormulaSequence',
    'RDFormulaHazardClassificationSource',
    'RDFormulaHazardClassificationMapping',
    'RDFormulaEquation',
    'RDFormulaPublishedSnapshot',
    'RDFormulaHazardRating',
    'FormulaPhysicalState',
    'FormulaPhysicalContainer',
];

const regulationTagTypes = [
    'RegulationBody',
    'RegulationSection',
    'Regulation',
    'ActiveRegulation',
    'RegulationBody',
    'RegulationSection',
];

const sdsTagTypes = [
    'SafetyDataSheetSectionHeader',
    'ActiveSafetyDataSheetSectionHeader',
    'SdsHeader',
    'ActiveSdsHeader',
    'SdsHeaderStatus',
    'ActiveSdsBlob',
    'SdsBlob',
    'SdsHeaderSequence',
    'HazardClassificationOperator',
    'GhsClassification',
];

const sdsOtherInformationTagTypes = [
    'HealthHazard',
    'PhysicalHazard',
    'ProtectiveEquipment',
    'SpecificHazard',
    'Reactivity',
    'Flammability',
];

const toxicSpeciesTagTypes = ['ToxicSpecies', 'ActiveToxicSpecies'];

const toxicTestTagTypes = ['ToxicTest', 'ActiveToxicTest'];

const hazardClassMappingsTagTypes = ['hazardClassMappings'];

const hazardRatingTagTypes = ['hazardRatings'];

const userTagTypes = ['User', 'Zones', 'Company', 'Audit'];

const casCompositionOperatorTypes = ['CasCompositionOperators'];

const hazardClassRulesTagTypes = [
    'HazardClassificationRule',
    'ActiveHazardClassificationRule',
];
const hazardClassStepsTagTypes = ['HazardClassificationStep'];
const formTagTypes = ['FormType'];
const formulaTemplateTagTypes = [
    'FormulaTemplate',
    'ActiveFormulaTemplate',
    'RequirementType',
];

const i18nTagTypes = [
    'TermSet',
    'CurrencyCodes',
    'Currencies',
    'ActiveCurrency',
    'CurrencyRateTypes',
    'CurrencyRateTypeCodes',
    'ActiveCurrencyRateType',
    'CurrencyExchangeRates',
    'ActiveCurrencyExchangeRate',
    'TransCurrencyExchangeRate',
];

const sequelizeTagTypes = ['Migrations'];

const equationTags = ['ConvFactor'];

const systemTagTypes = ['Applications', 'EntityTypes', 'Services', 'Systems'];

const documentDesignerTagTypes = [
    'DocDefinitions',
    'DocTemplates',
    'DocDataSource',
    'Doc',
];

const attachmentTagTypes = [
    'Attachments',
    'AttachmentsSources',
    'AttachmentsControls',
    'AttachmentsPermissions',
    'AttachmentsAliases',
    'AttachmentsFileVersions',
    'AttachmentFolders',
];

const jobTagTypes = [
    'JobDefinitionConfigurations',
    'JobDefinitions',
    'JobRuns',
];

const taskManagerTagTypes = ['Tasks', 'TaskTypes'];

const notificationTagTypes = [
    'Notifications',
    'NotificationTemplates',
    'NotificationConfig',
];

export const datacorAppApiTagTypes = [
    ...organizationTags,
    ...permissionsTagTypes,
    ...accessTagTypes,
    ...apaTagTypes,
    ...appStreamingTagTypes,
    ...attributeTagTypes,
    ...featureFlagTagTypes,
    ...userTagTypes,
    ...i18nTagTypes,
    ...sequelizeTagTypes,
    ...equationTags,
    ...documentDesignerTagTypes,
    ...systemTagTypes,
    ...attachmentTagTypes,
    ...jobTagTypes,
    ...taskManagerTagTypes,
    ...notificationTagTypes,
];

export const formulationAppApiTagTypes = [
    ...casMasterTagTypes,
    ...casPicSignalWordTagTypes,
    ...casSpeciesTagTypes,
    ...casToxicRoutesTagType,
    ...casHazardClassMappingsTagTypes,
    ...exposureAgencyTagTypes,
    ...propertyTagTypes,
    ...formulaPropertyTagTypes,
    ...hazardTagTypes,
    ...hazardClassTagTypes,
    ...ghsHazardTagTypes,
    ...inventoryTypeTagTypes,
    ...itemMasterTagTypes,
    ...itemStatusTagTypes,
    ...itemPropertiesTagTypes,
    ...localeGroupTagTypes,
    ...localeTagTypes,
    ...statementTypesTagTypes,
    ...precautionaryPhrasesTagTypes,
    ...rdFormulaTagTypes,
    ...regulationTagTypes,
    ...sdsTagTypes,
    ...toxicSpeciesTagTypes,
    ...toxicTestTagTypes,
    ...casCompositionOperatorTypes,
    ...hazardClassMappingsTagTypes,
    ...hazardRatingTagTypes,
    ...sdsOtherInformationTagTypes,
    ...hazardClassRulesTagTypes,
    ...hazardClassStepsTagTypes,
    ...formTagTypes,
    ...formulaTemplateTagTypes,
];

export const accountingAppApiTagTypes = [
    ...accountingTagTypes,
    ...journalTagTypes,
    ...fixedAssetTagTypes,
    ...supplierInvoiceTagTypes,
    ...customerInvoiceTagTypes,
];

export const supplyChainAppApiTagTypes = [
    ...supplierTagTypes,
    ...customerTagTypes,
];

export const workflowAppApiTagTypes: any = ['TestEntity'];

export const refreshTags = [...refreshOrg, ...datacorAppApiTagTypes];
