import React, { useEffect, useState } from 'react';
import {
    useGetDefaultApproversQuery,
    useGetDocumentQuery,
    useUpdateDefaultApproversMutation,
    useUpdateInvoiceApproversMutation,
} from '../../../services/apAutomation/apAutomation.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { Approver } from '../../../types/ApAutomation.types';
import { UserData } from '../../../types/User.types';
import { IconButton, Popover } from '@mui/material';
import Typography from '@mui/material/Typography';
import LoaderButton from '../../../components/LoaderButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useGetBusinessEntityUsersQuery } from '../../../services/organizations/organizations.service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

const useApprovalsModal = ({ handleClose, documentId }: any) => {
    const user = useSelector((state: RootState) => state.user);
    const { data: users, isLoading: isLoadingUsers } =
        useGetBusinessEntityUsersQuery(
            user?.businessEntity?.id
                ? user?.businessEntity?.id?.toString()
                : skipToken
        );
    const { data: defaultApprovers } = useGetDefaultApproversQuery();
    const { data: activeDocument, isLoading: isLoadingDoc } =
        useGetDocumentQuery(documentId ? documentId : skipToken);
    const [updateInvoiceApprovers] = useUpdateInvoiceApproversMutation();
    const [updateDefaultApprovers] = useUpdateDefaultApproversMutation();
    const [localApprovers, setLocalApprovers] = useState([]);

    /* istanbul ignore next */
    useEffect(() => {
        if (isLoadingUsers) {
            return;
        }
        if (isLoadingDoc) {
            return;
        }
        if (
            documentId &&
            activeDocument &&
            !isNilOrEmpty(activeDocument.approvers)
        ) {
            const approverEmails = activeDocument.approvers.map(
                (approver: Approver) => approver.email
            );
            setLocalApprovers(
                users?.filter((user: UserData) =>
                    approverEmails.includes(user.email)
                )
            );
        } else if (!isNilOrEmpty(defaultApprovers)) {
            setLocalApprovers(
                users?.filter((user: UserData) =>
                    defaultApprovers.includes(user.email)
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users, defaultApprovers, activeDocument, documentId]);

    /* istanbul ignore next */
    const saveChanges = async () => {
        const args = {
            documentId: documentId,
            approvers: localApprovers,
        };
        documentId
            ? updateInvoiceApprovers(args)
            : updateDefaultApprovers(localApprovers);

        handleClose(true);
    };

    /* istanbul ignore next */
    const addApprover = (approver: Approver) => {
        setLocalApprovers((oldApprovers) => [...oldApprovers, approver]);
    };

    /* istanbul ignore next */
    const removeApprover = (approverToRemove: Approver) => {
        setLocalApprovers((oldApprovers) =>
            oldApprovers?.filter(
                (approver) => approver.email !== approverToRemove.email
            )
        );
    };

    /* istanbul ignore next */
    const setSelectApproverOptions = () => {
        if (isLoadingUsers) {
            return;
        }

        return users
            ?.filter(
                (user: UserData) =>
                    !localApprovers
                        .map((approver) => approver.email)
                        .includes(user.email)
            )
            .map((user: UserData) => {
                return {
                    value: user.email,
                    label: `${user.firstName} ${user.lastName}`,
                };
            });
    };

    /* istanbul ignore next */
    const Delete = (props: any) => {
        const [showOverlay, setShowOverlay] = useState(false);
        const [anchorEl, setAnchorEl] =
            React.useState<HTMLButtonElement | null>(null);

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
            setShowOverlay(true);
        };
        const popover = (
            <Popover id="popover-basic" open={showOverlay} anchorEl={anchorEl}>
                <Typography sx={{ p: 2 }}>
                    Do you want to remove this approver?
                </Typography>

                <LoaderButton
                    isLoading={null}
                    variant="contained"
                    data-testid="removeConfirmationBtn"
                    onClick={() => removeApprover(props.data)}>
                    Yes
                </LoaderButton>
                <LoaderButton
                    isLoading={null}
                    variant="contained"
                    data-testid="cancelRemoveBtn"
                    onClick={() => setShowOverlay(false)}>
                    No
                </LoaderButton>
            </Popover>
        );

        return (
            <>
                {popover}
                <IconButton color="error" size="small" onClick={handleClick}>
                    <DeleteForeverIcon />
                </IconButton>
            </>
        );
    };
    return {
        users,
        setSelectApproverOptions,
        addApprover,
        isLoadingUsers,
        localApprovers,
        Delete,
        saveChanges,
    };
};

export default useApprovalsModal;
