import React, { forwardRef, useImperativeHandle } from 'react';
import { Popover, Typography } from '@mui/material';
import './pricingConcernDetails/PricingConcernDetails.css';

export default forwardRef((props: any, ref) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    useImperativeHandle(ref, () => {
        return {
            getReactContainerClasses() {
                return ['custom-tooltip'];
            },
        };
    });

    const ComponentSwitch = () => {
        switch (props.value) {
            case 'datacorScore':
                return <OpportuntiyScore />;
            case 'meanSalesDollars':
                return <MeanSalesDollars />;
            case 'meanOrderPrice':
                return <MeanOrderPrice />;
            case 'meanOrderCost':
                return <MeanOrderCost />;
            case 'meanProfit':
                return <MeanProfit />;
            case 'meanMargin':
                return <MeanMargin />;
            case 'averageMargin':
                return <AverageMargin />;
            case 'averagePrice':
                return <AveragePrice />;
            default:
                return null;
        }
    };

    const OpportuntiyScore = () => {
        return (
            <Popover
                id="popover-basic"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}>
                <Typography>Opportunity Score</Typography>
                <Typography>
                    Cumulative ranking used to identify high value
                    customers/product opportunities.
                    <br />
                    <small>(highest number = largest return)</small>
                </Typography>
            </Popover>
        );
    };

    const MeanSalesDollars = () => {
        return (
            <Popover
                id="popover-basic"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}>
                <Typography>Sales Dollars (avg)</Typography>
                <Typography>
                    Mean average of transaction's sales dollars
                </Typography>
            </Popover>
        );
    };

    const MeanOrderPrice = () => {
        return (
            <Popover
                id="popover-basic"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}>
                <Typography>Order Price (avg)</Typography>
                <Typography>
                    Mean average of transaction's order price
                </Typography>
            </Popover>
        );
    };

    const MeanOrderCost = () => {
        return (
            <Popover
                id="popover-basic"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}>
                <Typography>Order Cost (avg)</Typography>
                <Typography>
                    Mean average of transaction's order cost
                </Typography>
            </Popover>
        );
    };

    const MeanProfit = () => {
        return (
            <Popover
                id="popover-basic"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}>
                <Typography>Profit (avg)</Typography>
                <Typography>Mean average of transaction's profit</Typography>
            </Popover>
        );
    };

    const MeanMargin = () => {
        return (
            <Popover
                id="popover-basic"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}>
                <Typography>Margin (avg)</Typography>
                <Typography>Mean average of transaction's margin</Typography>
            </Popover>
        );
    };

    const AverageMargin = () => {
        return (
            <Popover
                id="popover-basic"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}>
                <Typography>Average Margin</Typography>
                <Typography>
                    Mean average margin of product transactions within the last
                    year
                </Typography>
            </Popover>
        );
    };

    const AveragePrice = () => {
        return (
            <Popover
                id="popover-basic"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}>
                <Typography>Average Price</Typography>
                <Typography>
                    Mean average price of product transactions within the last
                    year
                </Typography>
            </Popover>
        );
    };

    return (
        <div style={{ width: '500px' }}>
            <ComponentSwitch />
        </div>
    );
});
