import ApAutomation from '../containers/apAutomation/ApAutomation';
import DocumentView from '../containers/apAutomation/DocumentView';
import { RouteProps } from './routesConfig';

const ApAutomationRoutes: RouteProps[] = [
    {
        path: '/apAutomation',
        component: ApAutomation,
        featureFlag: 'dashboard.nav.apps.apAutomation',
    },
    {
        path: '/apAutomation/document/:documentId',
        component: DocumentView,
        featureFlag: 'dashboard.nav.apps.apAutomation',
    },
];

export default ApAutomationRoutes;
