import React, { useState } from 'react';
import { useGetArchivedInvoicesQuery } from '../../../../services/apAutomation/apAutomation.service';
import { useGetUsersQuery } from '../../../../services/organizations/organizations.service';

const useArchivedInvoiceModal = () => {
    const { data: archivedInvoices, isLoading: isLoadingArchived } =
        useGetArchivedInvoicesQuery();

    const { data: userList, isLoading } = useGetUsersQuery();

    const [selectedDocument, setSelectedDocument] = useState(null);

    const handleClick = (document: string) => {
        setSelectedDocument(document);
    };

    return {
        handleClick,
        archivedInvoices,
        isLoadingArchived,
        selectedDocument,
        setSelectedDocument,
        userList,
    };
};

export default useArchivedInvoiceModal;
