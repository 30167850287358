import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { onError } from '../libs/errorLib';
import { getCurrentUserCompanyId } from '../containers/Utils';
import { isNil } from '../utils/objectUtils';
import { SliceState } from '../types/store/PricingAnalysisStore.types';
import { Warehouse } from '../types/PricingAnalysis.types';
import { AppDispatch } from './index';
import moment from 'moment';

const initialState: SliceState = {
    pricingAnalysisToolSet: {
        activeCumulativeSummaryIsLoading: false,
        summaryAsOfDate: {
            formattedDate: moment()
                .subtract(1, 'months')
                .endOf('month')
                .format('MM/DD/YYYY'),
            rawDate: moment()
                .subtract(1, 'months')
                .endOf('month')
                .format('YYYYMMDD'),
        },
        summaryStartDate: {
            formattedDate: moment()
                .subtract(4, 'months')
                .startOf('month')
                .format('MM/DD/YYYY'),
            rawDate: moment()
                .subtract(4, 'months')
                .startOf('month')
                .format('YYYYMMDD'),
        },
        activeCumulativeSummary: null,
        pricingConcernDetailsIsLoading: false,
        pricingConcernSelectedCategories: [],
        transactionsUsedForDetail: null,
        activePricingConcernDetails: null,
        customerProductRecommendationsLoading: false,
        activeCustomerProductRecommendations: null,
        availableWarehouses: null,
        availableCustomerProductWarehouses: null,
        selectedWarehouse: null,
        activeCustomerProductDetailsLoading: false,
        activeCustomerProductDetails: null,
        selectedCustomerProductDetails: [],
    },
};

const slice = createSlice({
    name: 'pricingAnalysisTool',
    initialState: initialState,
    reducers: {
        pricingAnalysisToolLoading: (state, action) => {
            state.pricingAnalysisToolSet.activeCumulativeSummaryIsLoading =
                action.payload;
        },
        updateSelectedPricingConcerns: (state, action) => {
            if (Array.isArray(action.payload)) {
                state.pricingAnalysisToolSet.pricingConcernSelectedCategories =
                    action.payload;
            } else {
                const index =
                    state.pricingAnalysisToolSet.pricingConcernSelectedCategories.findIndex(
                        (concern) => concern === action.payload.id
                    );

                if (index !== -1) {
                    state.pricingAnalysisToolSet.pricingConcernSelectedCategories.splice(
                        index,
                        1
                    );
                }

                if (action.payload.checked) {
                    state.pricingAnalysisToolSet.pricingConcernSelectedCategories.push(
                        action.payload.id
                    );
                }
            }
        },
        updateSummaryStartDate: (state, action) => {
            state.pricingAnalysisToolSet.summaryStartDate = action.payload;
        },
        updateSummaryAsOfDate: (state, action) => {
            state.pricingAnalysisToolSet.summaryAsOfDate = action.payload;
        },
        pricingConcernDetailsIsLoading: (state, action) => {
            state.pricingAnalysisToolSet.pricingConcernDetailsIsLoading =
                action.payload;
        },
        getTransactionsByConcernAndDateRangeSuccess: (state, action) => {
            state.pricingAnalysisToolSet.transactionsUsedForDetail =
                action.payload.activePricingConcernDetails;
            state.pricingAnalysisToolSet.activePricingConcernDetails =
                calculateDatacorScore(
                    aggregateTransactions(
                        action.payload.activePricingConcernDetails
                    )
                );
            state.pricingAnalysisToolSet.availableWarehouses = [
                ...new Set(
                    action.payload.activePricingConcernDetails.map(
                        (item: Warehouse) => {
                            return JSON.stringify({
                                warehouseId: item.warehouseId,
                                warehouseName: item.warehouseName,
                            });
                        }
                    )
                ),
            ].map((itemString: any) => JSON.parse(itemString));
            state.pricingAnalysisToolSet.pricingConcernDetailsIsLoading =
                action.payload.pricingConcernDetailsIsLoading;
        },
        filterTransactionsByWarehouseSuccess: (state, action) => {
            //add logic for when all warehouses should be included.
            const filteredTransactions =
                action.payload.selectedWarehouseId === 'all'
                    ? state.pricingAnalysisToolSet.transactionsUsedForDetail
                    : state.pricingAnalysisToolSet.transactionsUsedForDetail.filter(
                          (transaction: any) =>
                              transaction.warehouseId ===
                              action.payload.selectedWarehouseId
                      );
            state.pricingAnalysisToolSet.selectedWarehouse =
                action.payload.selectedWarehouse;
            state.pricingAnalysisToolSet.activePricingConcernDetails =
                calculateDatacorScore(
                    aggregateTransactions(filteredTransactions)
                );
            state.pricingAnalysisToolSet.pricingConcernDetailsIsLoading =
                action.payload.pricingConcernDetailsIsLoading;
        },
        customerProductRecommendationsLoading: (state, action) => {
            state.pricingAnalysisToolSet.customerProductRecommendationsLoading =
                action.payload;
        },
        getCustomerProductRecommendationsSuccess: (state, action) => {
            state.pricingAnalysisToolSet.activeCustomerProductRecommendations =
                action.payload.customerProductRecommendations;
            state.pricingAnalysisToolSet.customerProductRecommendationsLoading =
                action.payload.customerProductRecommendationsLoading;
        },
        getMonthlyDetailSummarySuccess: (state, action) => {
            state.pricingAnalysisToolSet.activeCumulativeSummary = {
                ...aggregateMonthlySummaries(
                    action.payload.monthlyDetailSummaries
                ),
                monthlyDetails: action.payload.monthlyDetailSummaries,
                startDate: action.payload.startDate,
                asOfDate: action.payload.asOfDate,
            };
            state.pricingAnalysisToolSet.activeCumulativeSummaryIsLoading =
                action.payload.activeCumulativeSummaryIsLoading;
        },
        activeCustomerProductDetailsLoading: (state, action) => {
            state.pricingAnalysisToolSet.activeCustomerProductDetailsLoading =
                action.payload;
        },
        setActiveCustomerProductTransactionDetailsSuccess: (state, action) => {
            state.pricingAnalysisToolSet.activeCustomerProductDetails =
                state.pricingAnalysisToolSet.transactionsUsedForDetail.filter(
                    (transaction: any) =>
                        `${transaction.externalCustomerId}${transaction.productId}${transaction.orderType}` ===
                        action.payload.customerProductId
                );

            state.pricingAnalysisToolSet.availableCustomerProductWarehouses = [
                ...new Set(
                    // @ts-ignore
                    state.pricingAnalysisToolSet.activeCustomerProductDetails.map(
                        (item: any) => {
                            return JSON.stringify({
                                warehouseId: item.warehouseId,
                                warehouseName: item.warehouseName,
                            });
                        }
                    )
                ),
            ].map((itemString: any) => JSON.parse(itemString));
            state.pricingAnalysisToolSet.activeCustomerProductDetailsLoading =
                false;
        },
        setSelectedCustomerProductDetailsSuccess: (state, action) => {
            const index =
                state.pricingAnalysisToolSet.selectedCustomerProductDetails.findIndex(
                    (data) => data.id === action.payload.customerProductData.id
                );
            if (action.payload.action && index === -1) {
                const customerProductData = Object.assign(
                    {},
                    action.payload.customerProductData
                );
                customerProductData['proposedOrderPrice'] =
                    action.payload.customerProductData.mostRecentTransaction.orderPrice;
                customerProductData['proposedOrderCost'] =
                    action.payload.customerProductData.mostRecentTransaction.orderCost;
                customerProductData['proposedProfit'] =
                    action.payload.customerProductData.mostRecentTransaction.profit;
                customerProductData['proposedMargin'] =
                    action.payload.customerProductData.mostRecentTransaction.margin;
                customerProductData['proposedDollarsGained'] = '---';
                state.pricingAnalysisToolSet.selectedCustomerProductDetails.push(
                    customerProductData
                );
            } else if (!action.payload.action && index !== -1) {
                state.pricingAnalysisToolSet.selectedCustomerProductDetails.splice(
                    index,
                    1
                );
            }
        },
        updateSelectedCustomerProductDetailsSuccess: (state, action) => {
            const index =
                state.pricingAnalysisToolSet.selectedCustomerProductDetails.findIndex(
                    (data) => data.id === action.payload.customerProductData.id
                );
            const customerProductData = Object.assign(
                {},
                state.pricingAnalysisToolSet.selectedCustomerProductDetails[
                    index
                ]
            );
            let price =
                action.payload.columnName === 'proposedOrderPrice'
                    ? action.payload.newValue
                    : customerProductData.proposedOrderPrice;
            let cost =
                action.payload.columnName === 'proposedOrderCost'
                    ? action.payload.new
                    : customerProductData.proposedOrderCost;
            let profit = price - cost;
            let margin = (profit / price) * 100;

            if (action.payload.columnName === 'proposedProfit') {
                profit = action.payload.newValue;
                price = profit + cost;
                margin = (profit / price) * 100;
            } else if (action.payload.columnName === 'proposedMargin') {
                margin = action.payload.newValue;
                price = cost / (1 - margin / 100);
                profit = price - cost;
            }

            customerProductData['proposedOrderPrice'] = price;
            customerProductData['proposedOrderCost'] = cost;
            customerProductData['proposedProfit'] = profit;
            customerProductData['proposedMargin'] = margin;
            customerProductData['proposedDollarsGained'] =
                profit - customerProductData.mostRecentTransaction.profit;

            state.pricingAnalysisToolSet.selectedCustomerProductDetails.splice(
                index,
                1,
                customerProductData
            );
        },
        resetSelectedCustomerProductDetailsSuccess: (state) => {
            state.pricingAnalysisToolSet.selectedCustomerProductDetails.map(
                (selected) => {
                    selected.proposedOrderPrice =
                        selected.mostRecentTransaction.orderPrice;
                    selected.proposedOrderCost =
                        selected.mostRecentTransaction.orderCost;
                    selected.proposedProfit =
                        selected.mostRecentTransaction.profit;
                    selected.proposedMargin =
                        selected.mostRecentTransaction.margin;
                    selected.proposedDollarsGained = '---';

                    return selected;
                }
            );
        },
    },
});

export default slice.reducer;

// Actions
export const {
    pricingAnalysisToolLoading,
    updateSelectedPricingConcerns,
    updateSummaryStartDate,
    updateSummaryAsOfDate,
    pricingConcernDetailsIsLoading,
    getTransactionsByConcernAndDateRangeSuccess,
    customerProductRecommendationsLoading,
    getCustomerProductRecommendationsSuccess,
    filterTransactionsByWarehouseSuccess,
    getMonthlyDetailSummarySuccess,
    activeCustomerProductDetailsLoading,
    setActiveCustomerProductTransactionDetailsSuccess,
    setSelectedCustomerProductDetailsSuccess,
    updateSelectedCustomerProductDetailsSuccess,
    resetSelectedCustomerProductDetailsSuccess,
} = slice.actions;

export const getMonthlyDetailSummary =
    (startDate: string, asOfDate: string) => async (dispatch: AppDispatch) => {
        try {
            dispatch(pricingAnalysisToolLoading(true));
            const monthlyDetailResults = await API.get(
                'datacorAPIWithCustAuth',
                `/pricingAnalysisTool/getMonthlyDetailSummaries/${await getCurrentUserCompanyId()}/${startDate}/${asOfDate}`,
                null
            );
            dispatch(
                getMonthlyDetailSummarySuccess({
                    monthlyDetailSummaries: monthlyDetailResults,
                    activeCumulativeSummaryIsLoading: false,
                    startDate: startDate,
                    asOfDate: asOfDate,
                })
            );
        } catch (e) {
            dispatch(pricingAnalysisToolLoading(false));
            return onError(e.message);
        }
    };

export const getCustomerProductRecommendations =
    (externalCompanyId: string | number, productId: string | number) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(customerProductRecommendationsLoading(true));
            const customerProductRecommendationsResults = await API.get(
                'datacorAPIWithCustAuth',
                `/pricingAnalysisTool/getPricingRecommendations/${await getCurrentUserCompanyId()}/${externalCompanyId}/${productId}`,
                null
            );
            dispatch(
                getCustomerProductRecommendationsSuccess({
                    customerProductRecommendations: Array.isArray(
                        customerProductRecommendationsResults
                    )
                        ? customerProductRecommendationsResults
                        : [customerProductRecommendationsResults],
                    customerProductRecommendationsLoading: false,
                })
            );
        } catch (e) {
            dispatch(customerProductRecommendationsLoading(false));
            return onError(e.message);
        }
    };

export const createCustomerProductRecommendation =
    (recommendation: any, onSuccessFunction: any = null) =>
    async () => {
        try {
            await API.post(
                'datacorAPIWithCustAuth',
                '/pricingAnalysisTool/createPricingRecommendation',
                {
                    body: recommendation,
                }
            );

            if (onSuccessFunction) {
                onSuccessFunction();
            }
        } catch (e) {
            return onError(e.message);
        }
    };

export const getTransactionsByConcernAndDateRange =
    (startDate: string, asOfDate: string, concerns: any[]) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(pricingConcernDetailsIsLoading(true));
            const currentUser = await getCurrentUserCompanyId();
            const concernQueryString = concerns
                .map(
                    (concern) =>
                        `has${
                            concern.charAt(0).toUpperCase() + concern.slice(1)
                        }=true`
                )
                .join('&');
            let transactionResults: any = [];
            let response;
            let lastEvaluatedKey = undefined;

            do {
                const lastEvaluatedKeyQueryString: string = lastEvaluatedKey
                    ? `&lastEvaluatedKey=${lastEvaluatedKey}`
                    : '';

                response = await API.get(
                    'datacorAPIWithCustAuth',
                    `/pricingAnalysisTool/getTransactionsByConcernAndDateRange/${currentUser}/${startDate}/${asOfDate}?${concernQueryString}${lastEvaluatedKeyQueryString}`,
                    null
                );

                lastEvaluatedKey = response.lastKey
                    ? Buffer.from(JSON.stringify(response.lastKey)).toString(
                          'base64'
                      )
                    : undefined;

                transactionResults = [
                    ...transactionResults,
                    ...response.filteredTransactions,
                ];
            } while (!isNil(response.lastKey));

            dispatch(
                getTransactionsByConcernAndDateRangeSuccess({
                    activePricingConcernDetails: transactionResults,
                    pricingConcernDetailsIsLoading: false,
                })
            );
        } catch (e) {
            dispatch(pricingConcernDetailsIsLoading(false));
            return onError(e.message);
        }
    };

export const filterTransactionsByWarehouse =
    (selectedWarehouse: Warehouse) => async (dispatch: AppDispatch) => {
        dispatch(pricingConcernDetailsIsLoading(true));
        dispatch(
            filterTransactionsByWarehouseSuccess({
                selectedWarehouse: selectedWarehouse,
                selectedWarehouseId: selectedWarehouse
                    ? selectedWarehouse.warehouseId
                    : 'all',
                pricingConcernDetailsIsLoading: false,
            })
        );
    };

export const setActiveCustomerProductTransactionDetails =
    (customerProductId: string | number) => async (dispatch: AppDispatch) => {
        dispatch(activeCustomerProductDetailsLoading(true));
        dispatch(
            setActiveCustomerProductTransactionDetailsSuccess({
                customerProductId: customerProductId,
            })
        );
    };

export const setSelectedCustomerProductDetails =
    (customerProductData: any, action: any) =>
    async (dispatch: AppDispatch) => {
        dispatch(
            setSelectedCustomerProductDetailsSuccess({
                customerProductData: customerProductData,
                action: action,
            })
        );
    };

export const updateSelectedCustomerProductDetails =
    (customerProductData: any, columnName: any, newValue: any, oldValue: any) =>
    async (dispatch: AppDispatch) => {
        dispatch(
            updateSelectedCustomerProductDetailsSuccess({
                customerProductData: customerProductData,
                columnName: columnName,
                newValue: newValue,
                oldValue: oldValue,
            })
        );
    };

export const resetSelectedCustomerProductDetails =
    () => async (dispatch: AppDispatch) => {
        dispatch(resetSelectedCustomerProductDetailsSuccess());
    };

export const aggregateTransactions = (transactions: any[]) => {
    const aggregated: any = [];

    transactions
        .sort((a, b) => (moment(a.orderDate) < moment(b.orderDate) ? 1 : -1))
        .forEach((transaction) => {
            const itemIndex = aggregated.findIndex((item: any) => {
                return (
                    item.id ===
                    `${transaction.externalCustomerId}${transaction.productId}${item.orderType}`
                );
            });

            if (itemIndex === -1) {
                aggregated.push(buildInitialAggregatedObject(transaction));
            } else {
                const currentAggregatedItem = aggregated[itemIndex];
                aggregated.splice(
                    itemIndex,
                    1,
                    updateAggregatedObject(currentAggregatedItem, transaction)
                );
            }
        });

    return aggregated;
};

export const buildInitialAggregatedObject = (item: any) => {
    const daysSinceOrderDate = moment(item.asOfDate).diff(
        item.orderDate,
        'days'
    );

    return {
        id: `${item.externalCustomerId}${item.productId}${item.orderType}`,
        externalCustomer: `${item.externalCustomerName} (${item.externalCustomerId})`,
        product: `${item.productName} (${item.productId})`,
        orderType: item.orderType,
        meanSalesDollars: item.invoiceAmount,
        meanOrderCost: item.orderCost,
        meanOrderPrice: item.orderPrice,
        meanProfit: item.profit,
        meanFreightCost: item.freightCost,
        orderQuantity: item.orderQuantity,
        invoiceAmountTotal: item.invoiceAmount,
        orderCostTotal: item.orderCost,
        orderPriceTotal: item.orderPrice,
        profitTotal: item.profit,
        freightCostTotal: item.freightCost,
        margin: item.margin,
        meanMargin: item.margin,
        transactionCount: 1,
        mostRecentTransaction: item,
        outdatedPricingConsecutiveDays: item.hasOutdatedPricing
            ? daysSinceOrderDate
            : null,
        pricingLagConsecutiveDays: item.hasPricingLag
            ? daysSinceOrderDate
            : null,
        belowAverageMarginConsecutiveDays: item.hasBelowAverageMargin
            ? daysSinceOrderDate
            : null,
        marginBelowGoalConsecutiveDays: item.hasMarginBelowGoal
            ? daysSinceOrderDate
            : null,
        belowAveragePriceConsecutiveDays: item.hasBelowAveragePrice
            ? daysSinceOrderDate
            : null,
        outdatedPricingDollarsLost: item.hasOutdatedPricing
            ? item.outdatedPricingDollarsLost
            : null,
        pricingLagDollarsLost: item.hasPricingLag
            ? item.pricingLagDollarsLost
            : null,
        belowAverageMarginDollarsLost: item.hasBelowAverageMargin
            ? item.belowAverageMarginDollarsLost
            : null,
        marginBelowGoalDollarsLost: item.hasMarginBelowGoal
            ? item.marginBelowGoalDollarsLost
            : null,
        belowAveragePriceDollarsLost: item.hasBelowAveragePrice
            ? item.belowAveragePriceDollarsLost
            : null,
    };
};

export const updateAggregatedObject = (existing: any, newItem: any) => {
    const daysSinceOrderDate = moment(
        existing.mostRecentTransaction.asOfDate
    ).diff(newItem.orderDate, 'days');

    existing.invoiceAmountTotal =
        Number(existing.invoiceAmountTotal) + Number(newItem.invoiceAmount);
    existing.orderCostTotal =
        Number(existing.orderCostTotal) + Number(newItem.orderCost);
    existing.orderPriceTotal =
        Number(existing.orderPriceTotal) + Number(newItem.orderPrice);
    existing.profitTotal =
        Number(existing.profitTotal) + Number(newItem.profit);
    existing.freightCostTotal =
        Number(existing.freightCostTotal) + Number(newItem.freightCost);
    existing.margin = Number(existing.margin) + Number(newItem.margin);
    existing.transactionCount++;
    existing.meanSalesDollars =
        Number(existing.invoiceAmountTotal) / Number(existing.transactionCount);
    existing.meanOrderCost =
        Number(existing.orderCostTotal) / Number(existing.transactionCount);
    existing.meanOrderPrice =
        Number(existing.orderPriceTotal) / Number(existing.transactionCount);
    existing.meanProfit =
        Number(existing.profitTotal) / Number(existing.transactionCount);
    existing.meanFreightCost =
        Number(existing.freightCostTotal) / Number(existing.transactionCount);
    existing.meanMargin =
        Number(existing.margin) / Number(existing.transactionCount);
    existing.orderQuantity =
        Number(existing.orderQuantity) + Number(newItem.orderQuantity);

    existing.outdatedPricingConsecutiveDays =
        existing.mostRecentTransaction.hasOutdatedPricing &&
        newItem.hasOutdatedPricing
            ? daysSinceOrderDate
            : existing.outdatedPricingConsecutiveDays;

    existing.pricingLagConsecutiveDays =
        existing.mostRecentTransaction.hasPricingLag && newItem.hasPricingLag
            ? daysSinceOrderDate
            : existing.pricingLagConsecutiveDays;

    existing.belowAverageMarginConsecutiveDays =
        existing.mostRecentTransaction.hasBelowAverageMargin &&
        newItem.hasBelowAverageMargin
            ? daysSinceOrderDate
            : existing.belowAverageMarginConsecutiveDays;

    existing.marginBelowGoalConsecutiveDays =
        existing.mostRecentTransaction.hasMarginBelowGoal &&
        newItem.hasMarginBelowGoal
            ? daysSinceOrderDate
            : existing.marginBelowGoalConsecutiveDays;

    existing.belowAveragePriceConsecutiveDays =
        existing.mostRecentTransaction.hasBelowAveragePrice &&
        newItem.hasBelowAveragePrice
            ? daysSinceOrderDate
            : existing.belowAveragePriceConsecutiveDays;

    existing.outdatedPricingDollarsLost = newItem.hasOutdatedPricing
        ? Number(existing.outdatedPricingDollarsLost) +
          Number(newItem.outdatedPricingDollarsLost)
        : existing.outdatedPricingDollarsLost;

    existing.pricingLagDollarsLost = newItem.hasPricingLag
        ? Number(existing.pricingLagDollarsLost) +
          Number(newItem.pricingLagDollarsLost)
        : existing.pricingLagDollarsLost;

    existing.belowAverageMarginDollarsLost = newItem.hasBelowAverageMargin
        ? Number(existing.belowAverageMarginDollarsLost) +
          Number(newItem.belowAverageMarginDollarsLost)
        : existing.belowAverageMarginDollarsLost;

    existing.marginBelowGoalDollarsLost = newItem.hasMarginBelowGoal
        ? Number(existing.marginBelowGoalDollarsLost) +
          Number(newItem.marginBelowGoalDollarsLost)
        : existing.marginBelowGoalDollarsLost;

    existing.belowAveragePriceDollarsLost = newItem.hasBelowAveragePrice
        ? Number(existing.belowAveragePriceDollarsLost) +
          Number(newItem.belowAveragePriceDollarsLost)
        : existing.belowAveragePriceDollarsLost;

    return existing;
};

/*
RFM - shows high value customers/product entities to focus on (highest number is best)
Recency - days since last transaction (divide into 4 groups) --> most 4
Frequency - number of transactions (divide into 4 groups) --> most 4
Monitization: average Sales $ (divide into 4 groups) --> most 4

Dollars lost by Concern (should these be weighted in anyway, user option??)
Outdated Pricing - dollars lost (divide into 4 groups) --> most 4
Pricing Lag - dollars lost (divide into 4 groups) --> most 4
Below Avg Margin - dollars lost (divide into 4 groups) --> most 4
Margin Below Goal - dollars lost (divide into 4 groups) --> most 4
Pricing Below Avg - dollars lost (divide into 4 groups) --> most 4
 */
export const calculateDatacorScore = (
    aggregatedTransactionsByCustomerAndProduct: any
) => {
    const size = Math.floor(
        aggregatedTransactionsByCustomerAndProduct.length / 4
    );
    return (
        aggregatedTransactionsByCustomerAndProduct
            //set recentcy score based on most recent order date
            .sort((a: any, b: any) =>
                moment(a.mostRecentTransaction.orderDate) <
                moment(b.mostRecentTransaction.orderDate)
                    ? 1
                    : -1
            )
            .map((customerProductEntity: any, index: number) => {
                return datacorScoreRanking(
                    customerProductEntity,
                    index,
                    'recencyScore',
                    size
                );
            })
            //set frequency score based on number of transactions
            .sort((a: any, b: any) =>
                a.transactionCount < b.transactionCount ? 1 : -1
            )
            .map((customerProductEntity: any, index: number) => {
                return datacorScoreRanking(
                    customerProductEntity,
                    index,
                    'frequencyScore',
                    size
                );
            })
            //set monitization score based on average Sales dollars
            .sort((a: any, b: any) =>
                a.averageSalesDollars < b.averageSalesDollars ? 1 : -1
            )
            .map((customerProductEntity: any, index: number) => {
                return datacorScoreRanking(
                    customerProductEntity,
                    index,
                    'monitizationScore',
                    size
                );
            })
            //set outdatedPricing score based on dollars lost
            .sort((a: any, b: any) =>
                a.outdatedPricingDollarsLost < b.outdatedPricingDollarsLost
                    ? 1
                    : -1
            )
            .map((customerProductEntity: any, index: number) => {
                return datacorScoreRanking(
                    customerProductEntity,
                    index,
                    'outdatedPricingScore',
                    size
                );
            })
            //set pricingLag score based on dollars lost
            .sort((a: any, b: any) =>
                a.pricingLagDollarsLost < b.pricingLagDollarsLost ? 1 : -1
            )
            .map((customerProductEntity: any, index: number) => {
                return datacorScoreRanking(
                    customerProductEntity,
                    index,
                    'pricingLagScore',
                    size
                );
            })
            //set belowAverageMargin score based on dollars lost
            .sort((a: any, b: any) =>
                a.belowAverageMarginDollarsLost <
                b.belowAverageMarginDollarsLost
                    ? 1
                    : -1
            )
            .map((customerProductEntity: any, index: number) => {
                return datacorScoreRanking(
                    customerProductEntity,
                    index,
                    'belowAverageMarginScore',
                    size
                );
            })
            //set marginBelowGoal score based on dollars lost
            .sort((a: any, b: any) =>
                a.marginBelowGoalDollarsLost < b.marginBelowGoalDollarsLost
                    ? 1
                    : -1
            )
            .map((customerProductEntity: any, index: number) => {
                return datacorScoreRanking(
                    customerProductEntity,
                    index,
                    'marginBelowGoalScore',
                    size
                );
            })
            //set belowAveragePrice score based on dollars lost
            .sort((a: any, b: any) =>
                a.belowAveragePriceDollarsLost < b.belowAveragePriceDollarsLost
                    ? 1
                    : -1
            )
            .map((customerProductEntity: any, index: number) => {
                return datacorScoreRanking(
                    customerProductEntity,
                    index,
                    'belowAveragePriceScore',
                    size
                );
            })
            //set Datacor Score
            .map((customerProductEntity: any) => {
                const RfmScore =
                    Number(customerProductEntity.recencyScore) +
                    Number(customerProductEntity.frequencyScore) +
                    Number(customerProductEntity.monitizationScore);

                const pricingConcernScore =
                    Number(customerProductEntity.outdatedPricingScore) +
                    Number(customerProductEntity.pricingLagScore) +
                    Number(customerProductEntity.belowAverageMarginScore) +
                    Number(customerProductEntity.marginBelowGoalScore) +
                    Number(customerProductEntity.belowAveragePriceScore);

                const score = RfmScore + pricingConcernScore;
                return {
                    ...customerProductEntity,
                    RfmScore: RfmScore,
                    pricingConcernScore: pricingConcernScore,
                    datacorScore: `${score} / 32`,
                };
            })
    );
};

export const datacorScoreRanking = (
    entity: any,
    index: number,
    type: string,
    size: number
) => {
    if (index < size) {
        return { ...entity, [type]: 4 };
    } else if (index >= size && index < size * 2) {
        return { ...entity, [type]: 3 };
    } else if (index >= size * 2 && index < size * 3) {
        return { ...entity, [type]: 2 };
    } else {
        return { ...entity, [type]: 1 };
    }
};

export const aggregateMonthlySummaries = (monthlySummaries: any) => {
    return monthlySummaries.reduce(
        (total: any, current: any) => {
            const marginTotal = total.marginTotal + current.marginTotal;
            const numberOfTransactions =
                total.numberOfTransactions + current.numberOfTransactions;
            const outdatedPricingMarginTotal =
                total.outdatedPricingMarginTotal +
                current.outdatedPricingMarginTotal;
            const pricingLagMarginTotal =
                total.pricingLagMarginTotal + current.pricingLagMarginTotal;
            const belowAverageMarginMarginTotal =
                total.belowAverageMarginMarginTotal +
                current.belowAverageMarginMarginTotal;
            const marginBelowGoalMarginTotal =
                total.marginBelowGoalMarginTotal +
                current.marginBelowGoalMarginTotal;
            const belowAveragePriceMarginTotal =
                total.belowAveragePriceMarginTotal +
                current.belowAveragePriceMarginTotal;
            const outdatedPricingNumberOfTransactions =
                total.outdatedPricingNumberOfTransactions +
                current.outdatedPricingNumberOfTransactions;
            const pricingLagNumberOfTransactions =
                total.pricingLagNumberOfTransactions +
                current.pricingLagNumberOfTransactions;
            const belowAverageMarginNumberOfTransactions =
                total.belowAverageMarginNumberOfTransactions +
                current.belowAverageMarginNumberOfTransactions;
            const marginBelowGoalNumberOfTransactions =
                total.marginBelowGoalNumberOfTransactions +
                current.marginBelowGoalNumberOfTransactions;
            const belowAveragePriceNumberOfTransactions =
                total.belowAveragePriceNumberOfTransactions +
                current.belowAveragePriceNumberOfTransactions;

            return {
                dollarsLost: total.dollarsLost - current.dollarsLost,
                marginTotal: total.marginTotal + current.marginTotal,
                numberOfTransactions:
                    total.numberOfTransactions + current.numberOfTransactions,
                outdatedPricingMarginTotal:
                    total.outdatedPricingMarginTotal +
                    current.outdatedPricingMarginTotal,
                pricingLagMarginTotal:
                    total.pricingLagMarginTotal + current.pricingLagMarginTotal,
                belowAverageMarginMarginTotal:
                    total.belowAverageMarginMarginTotal +
                    current.belowAverageMarginMarginTotal,
                marginBelowGoalMarginTotal:
                    total.marginBelowGoalMarginTotal +
                    current.marginBelowGoalMarginTotal,
                belowAveragePriceMarginTotal:
                    total.belowAveragePriceMarginTotal +
                    current.belowAveragePriceMarginTotal,
                outdatedPricingNumberOfTransactions:
                    total.outdatedPricingNumberOfTransactions +
                    current.outdatedPricingNumberOfTransactions,
                pricingLagNumberOfTransactions:
                    total.pricingLagNumberOfTransactions +
                    current.pricingLagNumberOfTransactions,
                belowAverageMarginNumberOfTransactions:
                    total.belowAverageMarginNumberOfTransactions +
                    current.belowAverageMarginNumberOfTransactions,
                marginBelowGoalNumberOfTransactions:
                    total.marginBelowGoalNumberOfTransactions +
                    current.marginBelowGoalNumberOfTransactions,
                belowAveragePriceNumberOfTransactions:
                    total.belowAveragePriceNumberOfTransactions +
                    current.belowAveragePriceNumberOfTransactions,
                outdatedPricingDollarsLost:
                    total.outdatedPricingDollarsLost -
                    current.outdatedPricingDollarsLost,
                pricingLagDollarsLost:
                    total.pricingLagDollarsLost - current.pricingLagDollarsLost,
                belowAverageMarginDollarsLost:
                    total.belowAverageMarginDollarsLost -
                    current.belowAverageMarginDollarsLost,
                marginBelowGoalDollarsLost:
                    total.marginBelowGoalDollarsLost -
                    current.marginBelowGoalDollarsLost,
                belowAveragePriceDollarsLost:
                    total.belowAveragePriceDollarsLost -
                    current.belowAveragePriceDollarsLost,
                averageMargin: marginTotal / numberOfTransactions,
                overallMargin:
                    ((total.profitDollars + current.profitDollars) /
                        (total.totalPrice + current.totalPrice)) *
                    100,
                quantity: total.quantity + current.quantity,
                salesDollars: total.salesDollars + current.salesDollars,
                profitDollars: total.profitDollars + current.profitDollars,
                totalPrice: total.totalPrice + current.totalPrice,
                outdatedPricingQuantity:
                    total.outdatedPricingQuantity +
                    current.outdatedPricingQuantity,
                outdatedPricingSalesDollars:
                    total.outdatedPricingSalesDollars +
                    current.outdatedPricingSalesDollars,
                outdatedPricingTotalPrice:
                    total.outdatedPricingTotalPrice +
                    current.outdatedPricingTotalPrice,
                outdatedPricingProfitDollars:
                    total.outdatedPricingProfitDollars +
                    current.outdatedPricingProfitDollars,
                outdatedPricingAverageMargin:
                    outdatedPricingMarginTotal /
                    outdatedPricingNumberOfTransactions,
                pricingLagQuantity:
                    total.pricingLagQuantity + current.pricingLagQuantity,
                pricingLagSalesDollars:
                    total.pricingLagSalesDollars +
                    current.pricingLagSalesDollars,
                pricingLagTotalPrice:
                    total.pricingLagTotalPrice + current.pricingLagTotalPrice,
                pricingLagProfitDollars:
                    total.pricingLagProfitDollars +
                    current.pricingLagProfitDollars,
                pricingLagAverageMargin:
                    pricingLagMarginTotal / pricingLagNumberOfTransactions,
                belowAverageMarginQuantity:
                    total.belowAverageMarginQuantity +
                    current.belowAverageMarginQuantity,
                belowAverageMarginSalesDollars:
                    total.belowAverageMarginSalesDollars +
                    current.belowAverageMarginSalesDollars,
                belowAverageMarginTotalPrice:
                    total.belowAverageMarginTotalPrice +
                    current.belowAverageMarginTotalPrice,
                belowAverageMarginProfitDollars:
                    total.belowAverageMarginProfitDollars +
                    current.belowAverageMarginProfitDollars,
                belowAverageMarginAverageMargin:
                    belowAverageMarginMarginTotal /
                    belowAverageMarginNumberOfTransactions,
                marginBelowGoalQuantity:
                    total.marginBelowGoalQuantity +
                    current.marginBelowGoalQuantity,
                marginBelowGoalSalesDollars:
                    total.marginBelowGoalSalesDollars +
                    current.marginBelowGoalSalesDollars,
                marginBelowGoalTotalPrice:
                    total.marginBelowGoalTotalPrice +
                    current.marginBelowGoalTotalPrice,
                marginBelowGoalProfitDollars:
                    total.marginBelowGoalProfitDollars +
                    current.marginBelowGoalProfitDollars,
                marginBelowGoalAverageMargin:
                    marginBelowGoalMarginTotal /
                    marginBelowGoalNumberOfTransactions,
                belowAveragePriceQuantity:
                    total.belowAveragePriceQuantity +
                    current.belowAveragePriceQuantity,
                belowAveragePriceSalesDollars:
                    total.belowAveragePriceSalesDollars +
                    current.belowAveragePriceSalesDollars,
                belowAveragePriceTotalPrice:
                    total.belowAveragePriceTotalPrice +
                    current.belowAveragePriceTotalPrice,
                belowAveragePriceProfitDollars:
                    total.belowAveragePriceProfitDollars +
                    current.belowAveragePriceProfitDollars,
                belowAveragePriceAverageMargin:
                    belowAveragePriceMarginTotal /
                    belowAveragePriceNumberOfTransactions,
            };
        },
        {
            dollarsLost: 0,
            numberOfTransactions: 0,
            outdatedPricingDollarsLost: 0,
            pricingLagDollarsLost: 0,
            belowAverageMarginDollarsLost: 0,
            marginBelowGoalDollarsLost: 0,
            belowAveragePriceDollarsLost: 0,
            averageMargin: 0,
            overallMargin: 0,
            marginTotal: 0,
            quantity: 0,
            salesDollars: 0,
            profitDollars: 0,
            totalPrice: 0,
            outdatedPricingNumberOfTransactions: 0,
            outdatedPricingQuantity: 0,
            outdatedPricingSalesDollars: 0,
            outdatedPricingTotalPrice: 0,
            outdatedPricingProfitDollars: 0,
            outdatedPricingMarginTotal: 0,
            outdatedPricingAverageMargin: 0,
            pricingLagNumberOfTransactions: 0,
            pricingLagQuantity: 0,
            pricingLagSalesDollars: 0,
            pricingLagTotalPrice: 0,
            pricingLagProfitDollars: 0,
            pricingLagMarginTotal: 0,
            pricingLagAverageMargin: 0,
            belowAverageMarginMarginTotal: 0,
            belowAverageMarginNumberOfTransactions: 0,
            belowAverageMarginQuantity: 0,
            belowAverageMarginSalesDollars: 0,
            belowAverageMarginTotalPrice: 0,
            belowAverageMarginProfitDollars: 0,
            belowAverageMarginAverageMargin: 0,
            marginBelowGoalMarginTotal: 0,
            marginBelowGoalNumberOfTransactions: 0,
            marginBelowGoalQuantity: 0,
            marginBelowGoalSalesDollars: 0,
            marginBelowGoalTotalPrice: 0,
            marginBelowGoalProfitDollars: 0,
            marginBelowGoalAverageMargin: 0,
            belowAveragePriceMarginTotal: 0,
            belowAveragePriceNumberOfTransactions: 0,
            belowAveragePriceQuantity: 0,
            belowAveragePriceSalesDollars: 0,
            belowAveragePriceTotalPrice: 0,
            belowAveragePriceProfitDollars: 0,
            belowAveragePriceAverageMargin: 0,
        }
    );
};
