import React from 'react';
import { FormControlLabel, Grid, Radio, Tooltip } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import TaskNotificationBody from './TaskNotificationBody';
import { useMarkReadMutation } from '../../../services/notifications/notifications.service';
import useDate from '../../../utils/hooks/useDate';
import useTranslation from '../../i18n/hooks/useTranslation';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';
import TranslatableText from '../../i18n/TranslatableText';
import { NotificationPopoverDefs } from '../../../constants/i18n/translations/termDefinitions/platform';

enum EVENT_DEFINITION_CODE {
    TASK_ASSIGNED = 'TASK_ASSIGNED',
    USER_CREATED = 'USER_CREATED',
}

const NotificationBody = ({ notification }: any) => {
    if (
        notification.notification.eventDefinition.code ===
        EVENT_DEFINITION_CODE.TASK_ASSIGNED
    ) {
        return <TaskNotificationBody notification={notification} />;
    }
};

const NotificationItem = ({ notification }: any) => {
    const [markRead] = useMarkReadMutation();
    const [read, setRead] = React.useState(notification.read);
    const { termSet } = useTranslation(PLATFORM_DEFS.NOTIFICATIONS);
    const { dateTimeFormatter } = useDate();

    const handleMarkRead = (event: any) => {
        event.preventDefault();
        markRead(notification.notification.id);
        setRead(!read);
    };

    return (
        <MenuItem onClick={handleMarkRead}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}>
                        <div>
                            <h4>{notification.subject}</h4>
                        </div>

                        <Tooltip
                            title={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        notification.read
                                            ? NotificationPopoverDefs.MarkAsUnread
                                            : NotificationPopoverDefs.MarkAsRead
                                    }
                                />
                            }>
                            <FormControlLabel
                                onClick={handleMarkRead}
                                value="markRead"
                                control={
                                    <Radio
                                        sx={{
                                            color: 'white',
                                        }}
                                        checked={!read}
                                        size={'small'}
                                    />
                                }
                                label={''}
                                style={{
                                    marginLeft: 'auto',
                                    paddingRight: '8px',
                                }}
                            />
                        </Tooltip>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ paddingLeft: '30px', marginTop: '-25px' }}>
                        {dateTimeFormatter(notification.createdAt)}
                        <div style={{ paddingTop: '5px' }}>
                            <p style={{ whiteSpace: 'pre-wrap' }}>
                                {notification.body}
                            </p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div>
                        <NotificationBody notification={notification} />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>
        </MenuItem>
    );
};

export default NotificationItem;
