import React, { useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useGetAccessLogsByUuidQuery } from '../../../services/access/access.service';
import { skipToken } from '@reduxjs/toolkit/query';
import useDate from '../../../utils/hooks/useDate';
import _ from 'lodash';
import { isNilOrEmpty } from '../../../utils/objectUtils';

const AccessLogsModal = (props: any) => {
    const { open, setOpen, entityUuid } = props;

    const {
        data: accessLogs,
        isLoading: isLoadingAccessLogs,
        refetch,
    } = useGetAccessLogsByUuidQuery(entityUuid ? entityUuid : skipToken);

    useEffect(() => {
        refetch();
    }, [open]);

    //@ts-ignore
    const openState = _.isObject(open) ? open?.open : open;

    const handleClose = () => {
        if (_.isObject(open)) {
            setOpen((prev: any) => ({
                ...prev,
                logs: {
                    open: false,
                    data: null,
                },
            }));
        } else {
            setOpen(false);
        }
    };

    const { dateTimeFormatter } = useDate();

    return (
        <Dialog open={openState} onClose={handleClose}>
            <DialogTitle>Access Logs</DialogTitle>

            <DialogContent>
                {!isNilOrEmpty(accessLogs) && !isLoadingAccessLogs ? (
                    <div>
                        {accessLogs.map((log: any) => (
                            <div key={log.id}>
                                {log.user?.firstName} {log.user?.lastName} -{' '}
                                {log.action} -{' '}
                                {dateTimeFormatter(log.createdAt)}
                            </div>
                        ))}
                    </div>
                ) : (
                    <div>No Logs Available...</div>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default AccessLogsModal;
