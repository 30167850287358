import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { useContext } from 'react';
import SharedTranslationsContext from '../../../contexts/translations.context';
import { SharedComponentsDefs } from '../../../constants/i18n/translations/termDefinitions/platform';
import TranslatableText from '../../i18n/TranslatableText';

const BaseFormConfirmModal = ({
    showModal,
    setShowModal,
    onCancel,
    setShowConfirm,
}: any) => {
    const { sharedTranslations } = useContext(SharedTranslationsContext);

    return (
        <Dialog open={showModal}>
            <DialogContent>
                <TranslatableText
                    termSet={sharedTranslations}
                    termKey={SharedComponentsDefs.Unsaved_Changes}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setShowModal(false);
                        setShowConfirm(true);
                    }}
                    variant={'outlined'}>
                    NO
                </Button>
                <Button
                    onClick={() => {
                        onCancel();
                    }}
                    variant={'contained'}>
                    YES
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BaseFormConfirmModal;
