import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    useApproveDocumentMutation,
    useGetDocumentQuery,
    useUnapproveDocumentMutation,
} from '../../../../services/apAutomation/apAutomation.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { isNil, isNilOrEmpty } from '../../../../utils/objectUtils';
import { Approver } from '../../../../types/ApAutomation.types';
import { RootState } from '../../../../store';
import { useGetUsersQuery } from '../../../../services/organizations/organizations.service';

const useApprovalsTab = (documentId: string) => {
    const user = useSelector((state: RootState) => state.user);
    const { data: activeDocument } = useGetDocumentQuery(
        documentId ? documentId : skipToken
    );
    const [approveDocument, { isLoading: isLoadingSaveApprove }] =
        useApproveDocumentMutation();
    const [unapproveDocument, { isLoading: isLoadingSaveUnapprove }] =
        useUnapproveDocumentMutation();
    const [isUserApprover, setIsUserApprover] = useState(false);
    const [isUserApproved, setIsUserApproved] = useState(false);
    const [canUserUpdateApprovers, setCanUserUpdateApprovers] = useState(false);
    const [showChangeDefaultModal, setShowChangeDefaultModal] = useState(false);
    const [open, setOpen] = useState(false);
    const { data: userList, isLoading } = useGetUsersQuery();
    const REJECTION_REASONS: any = {
        duplicate: 'Duplicate Invoice',
        incorrectInfo: 'Incorrect Information',
        wrongDivision: 'Wrong Division',
        mistake: 'Mistaken Upload',
    };

    useEffect(() => {
        if (!isNilOrEmpty(activeDocument.approvers)) {
            let userApproval = activeDocument.approvers.find(
                (approver: Approver) => approver.email === user.signInEmail
            );
            setIsUserApprover(!isNil(userApproval));
            setIsUserApproved(
                (userApproval &&
                    userApproval.approvalStatus.key === 'APPROVED') ||
                    activeDocument?.approvalStatus?.key === 'AUTO_APPROVED'
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDocument.approvers]);

    useEffect(() => {
        setCanUserUpdateApprovers(isNil(activeDocument.vendorNumber));
    }, [activeDocument]);

    const approveCurrentDocument = async () => {
        approveDocument(documentId);
    };

    const unapproveCurrentDocument = async () => {
        unapproveDocument(documentId);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const getGridData = () => {
        return activeDocument?.approvalStatus?.key !== 'AUTO_APPROVED'
            ? activeDocument.approvers
            : [
                  {
                      email: 'System',
                      status: {
                          value: 'Auto Approved',
                      },
                  },
              ];
    };

    return {
        user,
        getGridData,
        userList,
        isLoading,
        canUserUpdateApprovers,
        isUserApprover,
        isUserApproved,
        approveCurrentDocument,
        unapproveCurrentDocument,
        isLoadingSaveApprove,
        isLoadingSaveUnapprove,
        activeDocument,
        showChangeDefaultModal,
        setShowChangeDefaultModal,
        open,
        handleClickOpen,
        handleClose,
        REJECTION_REASONS,
    };
};

export default useApprovalsTab;
