import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import TextInput from '../form/formInputs/TextInput/TextInput';
import { isNilOrEmpty } from '../../utils/objectUtils';
import LoaderButton from '../LoaderButton';
import { PermissionsUtil } from '../../utils/permissions/permissionsUtil';
import useEditModal from './hooks/useEditModal';

const EditModal = ({ settingValue, handleClose }: any) => {
    const { inputValue, handleChange, handleDelete, user, handleSave } =
        useEditModal({ settingValue, handleClose });

    return (
        <Dialog open={!isNilOrEmpty(settingValue)} onClose={handleClose}>
            <DialogTitle>Edit Grid Layout</DialogTitle>
            <DialogContent>
                <TextInput
                    fullWidth
                    value={inputValue}
                    onChange={handleChange}
                    label={'Name'}
                />
            </DialogContent>
            <DialogActions>
                <LoaderButton
                    variant={'contained'}
                    color={'error'}
                    onClick={handleDelete}
                    disabled={
                        !PermissionsUtil.isPermissionEnabled(
                            user?.permissions,
                            'Settings.EntitySettings.GridLayouts.delete'
                        )
                    }>
                    Delete
                </LoaderButton>
                <LoaderButton
                    variant={'contained'}
                    color={'primary'}
                    onClick={handleSave}
                    disabled={
                        !PermissionsUtil.isPermissionEnabled(
                            user?.permissions,
                            'Settings.EntitySettings.GridLayouts.edit'
                        )
                    }>
                    Save
                </LoaderButton>
            </DialogActions>
        </Dialog>
    );
};
export default EditModal;
