import React from 'react';
import {
    Grid,
    Alert,
    FormGroup,
    FormControlLabel,
    Checkbox,
    FormControl,
    MenuItem,
    InputLabel,
} from '@mui/material';
import MonthSelect from '../../../components/MonthSelect';
import {
    updateSummaryAsOfDate,
    updateSummaryStartDate,
} from '../../../store/pricingAnalysisTool';
import Select from '@mui/material/Select';
import usePricingConcernDetailFilterBy from '../hooks/PricingConcernDetails/usePricingConcernDetailFilterBy';

const PricingConcernDetailFilterBy = ({
    doFilterWarehousesByCustomerProduct = false,
}) => {
    const {
        handleChange,
        handlePricingConcernChecks,
        value,
        showAlert,
        dispatch,
        pricingAnalysisToolSet,
    } = usePricingConcernDetailFilterBy();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {showAlert && (
                    <Alert
                        style={{ width: '100%' }}
                        className="text-center"
                        severity="error">
                        <span>
                            <b>Error:</b> Must select at least 1 pricing
                            concern.
                        </span>
                    </Alert>
                )}
            </Grid>
            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={4}>
                        <MonthSelect
                            monthEndOrStart="start"
                            placeholder="Start Date"
                            className={'pr-5'}
                            defaultValue={
                                pricingAnalysisToolSet.summaryStartDate
                            }
                            onChange={(selectedValue: any) =>
                                dispatch(updateSummaryStartDate(selectedValue))
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <MonthSelect
                            monthEndOrStart="end"
                            placeholder="End Date"
                            className={'pr-5'}
                            isDisabled={true}
                            defaultValue={
                                pricingAnalysisToolSet.summaryAsOfDate
                            }
                            onChange={(selectedValue: any) =>
                                dispatch(updateSummaryAsOfDate(selectedValue))
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Warehouse</InputLabel>
                            <Select
                                fullWidth
                                className="basic-select"
                                name="warehouseSelect"
                                label="Select Warehouse"
                                value={value || ''}
                                renderValue={(value) => (
                                    <>{value.warehouseName}</>
                                )}
                                onChange={handleChange}>
                                {doFilterWarehousesByCustomerProduct
                                    ? pricingAnalysisToolSet.availableCustomerProductWarehouses?.map(
                                          (option: any) => {
                                              return (
                                                  <MenuItem
                                                      key={option.warehouseId}
                                                      value={option}>
                                                      {option.warehouseName}
                                                  </MenuItem>
                                              );
                                          }
                                      )
                                    : pricingAnalysisToolSet.availableWarehouses?.map(
                                          (option: any) => {
                                              return (
                                                  <MenuItem
                                                      key={option.warehouseId}
                                                      value={option}>
                                                      {option.warehouseName}
                                                  </MenuItem>
                                              );
                                          }
                                      )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}>
                {' '}
            </Grid>
            <Grid item xs={5}>
                <Grid container>
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handlePricingConcernChecks}
                                        checked={pricingAnalysisToolSet.pricingConcernSelectedCategories.includes(
                                            'outdatedPricing'
                                        )}
                                        id="outdatedPricing"
                                    />
                                }
                                label="Outdated Pricing"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handlePricingConcernChecks}
                                        checked={pricingAnalysisToolSet.pricingConcernSelectedCategories.includes(
                                            'pricingLag'
                                        )}
                                        id="pricingLag"
                                    />
                                }
                                label="Pricing Lag"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handlePricingConcernChecks}
                                        checked={pricingAnalysisToolSet.pricingConcernSelectedCategories.includes(
                                            'belowAverageMargin'
                                        )}
                                        id="belowAverageMargin"
                                    />
                                }
                                label="Below Average Margin"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handlePricingConcernChecks}
                                        checked={pricingAnalysisToolSet.pricingConcernSelectedCategories.includes(
                                            'marginBelowGoal'
                                        )}
                                        id="marginBelowGoal"
                                    />
                                }
                                label="Margin Below Goal"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handlePricingConcernChecks}
                                        checked={pricingAnalysisToolSet.pricingConcernSelectedCategories.includes(
                                            'belowAveragePrice'
                                        )}
                                        id="belowAveragePrice"
                                    />
                                }
                                label="Price Below Average"
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PricingConcernDetailFilterBy;
