import PricingAnalysisTool from '../containers/pricingAnalysisTool/PricingAnalysisTool';
import PricingConcernDetails from '../containers/pricingAnalysisTool/pricingConcernDetails/PricingConcernDetails';
import PricingProposalReport from '../containers/pricingAnalysisTool/pricingProposalReport/PricingProposalReport';
import PricingCustomerProductDetails from '../containers/pricingAnalysisTool/pricingCustomerProductDetails/PricingCustomerProductDetails';
import { RouteProps } from './routesConfig';

const PricingAnalysisRoutes: RouteProps[] = [
    {
        path: '/pricingAnalysisTool',
        component: PricingAnalysisTool,
        featureFlag: 'dashboard.nav.apps.pricingAnalysisTool',
    },
    {
        path: '/pricingAnalysisTool/pricingConcernDetails',
        component: PricingConcernDetails,
        featureFlag: 'dashboard.nav.apps.pricingAnalysisTool',
    },
    {
        path: '/pricingAnalysisTool/pricingProposalReport',
        component: PricingProposalReport,
        featureFlag: 'dashboard.nav.apps.pricingAnalysisTool',
    },
    {
        path: '/pricingAnalysisTool/pricingCustomerProductDetails/:customerProductId',
        component: PricingCustomerProductDetails,
        featureFlag: 'dashboard.nav.apps.pricingAnalysisTool',
    },
];

export default PricingAnalysisRoutes;
