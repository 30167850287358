import React from 'react';
import { GenericEditableRow, RowStatus } from './hooks/useBaseGridEditable';
import { Alert, AlertTitle } from '@mui/material';
import { EntityError } from '../../types/Shared.types';

export interface EditableGridErrorsProps {
    editableGridProps: any;
}

interface RowEntityError extends EntityError {
    rowId: number;
    rowIndex?: number;
}

const EditableGridErrors = (props: EditableGridErrorsProps) => {
    const { isEditable, invalidRows, setSelectedInvalidRow } =
        props.editableGridProps;
    const buildErrorList = (): RowEntityError[] => {
        const errorRowList = invalidRows?.filter(
            (current: any) =>
                current.hasError &&
                current.errors &&
                current.rowStatus !== RowStatus.DELETED
        );
        const errorList: RowEntityError[] = [];
        errorRowList?.forEach((currentRow: any) => {
            currentRow.errors.forEach((error: any) =>
                errorList.push({
                    rowId: currentRow.rowId,
                    errorMessage: error.errorMessage,
                    additionalErrorMessage: getAdditionalMessage(
                        currentRow,
                        error
                    ),
                })
            );
        });
        return errorList;
    };

    const getAdditionalMessage = (
        currentRow: GenericEditableRow,
        currentError: EntityError
    ) => {
        let message: string = null;
        if (currentError?.additionalErrorMessage) {
            message = currentError.additionalErrorMessage;
        } else if (currentRow?.rowIndex !== null) {
            message = `Row number ${currentRow.rowIndex + 1}`;
        }
        return message;
    };

    return (
        <>
            {isEditable && invalidRows && (
                <div
                    id={'grid-errors-container'}
                    style={{ marginBottom: '0.4em' }}>
                    <Alert severity="error">
                        <AlertTitle>
                            Error(s). Click message below to jump to row with
                            error
                        </AlertTitle>
                        {buildErrorList().map((entityError, index) => (
                            <div
                                style={{ display: 'block' }}
                                key={`Error${entityError.rowId}-${index}`}>
                                {entityError.additionalErrorMessage && (
                                    <span
                                        onClick={() =>
                                            setSelectedInvalidRow(
                                                entityError.rowId
                                            )
                                        }
                                        style={{
                                            cursor: 'pointer',
                                            textDecoration: 'underline',
                                            paddingRight: '0.2em',
                                        }}>
                                        {entityError.additionalErrorMessage} has
                                        an error:
                                    </span>
                                )}
                                <span>{entityError.errorMessage}</span>
                            </div>
                        ))}
                    </Alert>
                </div>
            )}
        </>
    );
};

export default EditableGridErrors;
