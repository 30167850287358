import { datacorAppApi } from '../datacorAppApi';

export const taskManagerAPI = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        listTaskTypes: build.query<any, any | void>({
            query: (args: any) => ({
                url: `/task-types`,
                method: 'GET',
                params: args ? args : null,
            }),
            providesTags: ['TaskTypes'],
        }),
        listTasks: build.query<any, void>({
            query: () => `/tasks`,
            providesTags: ['Tasks'],
        }),
        getAllUserTasks: build.query<any, void>({
            query: () => `/tasks/user`,
            providesTags: ['Tasks'],
        }),
        getAllOpenUserTasks: build.query<any, void>({
            query: () => `/tasks/user`,
            transformResponse: (response: any) =>
                response
                    .filter((task: any) => !task.completedByUserId)
                    .sort((a: any, b: any) => {
                        const dateA = new Date(a.dueAt).getTime();
                        const dateB = new Date(b.dueAt).getTime();

                        if (dateA === 0) {
                            return 1;
                        }
                        if (dateB === 0) {
                            return -1;
                        }
                        return dateA - dateB;
                    }),
            providesTags: ['Tasks'],
        }),
        getAllCompletedUserTasks: build.query<any, void>({
            query: () => `/tasks/user`,
            transformResponse: (response: any) =>
                //sort by most recently completed first
                response
                    .filter((task: any) => task.completedByUserId)
                    .sort(
                        (a: any, b: any) =>
                            new Date(b.completedAt).getTime() -
                            new Date(a.completedAt).getTime()
                    ),
            providesTags: ['Tasks'],
        }),
        createTask: build.mutation<any, any>({
            query: (args) => ({
                url: '/tasks',
                method: 'POST',
                body: args,
            }),
            invalidatesTags: ['Tasks'],
        }),
        updateTask: build.mutation<any, any>({
            query: (args) => ({
                url: `/tasks/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['Tasks'],
        }),
        deleteTask: build.mutation<any, any>({
            query: (id) => ({
                url: `/tasks/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Tasks'],
        }),
        assignTask: build.mutation<any, any>({
            query: (args) => ({
                url: `/tasks/${args.id}/assign`,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['Tasks'],
        }),
        getTask: build.query<any, any>({
            query: (id) => `/tasks/${id}`,
            providesTags: ['Tasks'],
        }),
        markComplete: build.mutation<any, any>({
            query: (args) => ({
                url: `/tasks/${args.id}/complete`,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['Tasks'],
        }),
    }),
});

export const {
    useListTasksQuery,
    useGetAllUserTasksQuery,
    useGetAllOpenUserTasksQuery,
    useGetAllCompletedUserTasksQuery,
    useCreateTaskMutation,
    useGetTaskQuery,
    useUpdateTaskMutation,
    useAssignTaskMutation,
    useMarkCompleteMutation,
    useListTaskTypesQuery,
} = taskManagerAPI;
