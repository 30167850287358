import React from 'react';

import MatchingForm from './MatchingForm';
import {
    Box,
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface MatchingModalProps {
    show: boolean;
    handleClose: (_: boolean) => void;
}

const MatchingModal = ({ show, handleClose }: MatchingModalProps) => {
    return (
        <Dialog
            open={show}
            onClose={handleClose}
            maxWidth="lg"
            data-testid="tolerance-modal">
            <DialogTitle data-testid="approval-workflows-modal-header">
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>Edit Default Tolerance</Box>
                    <Box>
                        <IconButton onClick={() => handleClose(true)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>

            <DialogContent className="ap-automation-approvers">
                <MatchingForm onSubmit={handleClose} />
            </DialogContent>
        </Dialog>
    );
};

export default MatchingModal;
