import React from 'react';
import './ApprovalWorkflows.css';
import useApproverWorkflowButtonGroup from '../hooks/ApprovalWorkflows/useApproverWorkflowButtonGroup';

const ApproverWorkflowsButtonGroup = ({ documentId }: any) => {
    const { approvalWorkflowButtons } =
        useApproverWorkflowButtonGroup(documentId);

    return (
        <div className="approval-workflow-button-group">
            {approvalWorkflowButtons}
        </div>
    );
};

export default ApproverWorkflowsButtonGroup;
