import React from 'react';
import { Link } from 'react-router-dom';
import './DocumentView.css';
import ApprovalsTab from './tabs/ApprovalsTab';
import DocumentInfoTab from './tabs/DocumentInfoTab';
import PoInformationTab from './tabs/PoInformationTab';
import InvoicePreview from './InvoicePreview';
import DatacorLogoSpinner from '../../components/datacorLogoSpinner/DatacorLogoSpinner';
import Box from '@mui/material/Box';
import {
    Tabs,
    Tab,
    Grid,
    Card,
    CardContent,
    CardHeader,
    IconButton,
    Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useDocumentView from './hooks/useDocumentView';
import InvoiceHistoryTab from './tabs/InvoiceHistoryTab';

const DocumentView = () => {
    const { isLoadingDoc, value, handleChange, documentId, TabPanel } =
        useDocumentView();

    return (
        <>
            {isLoadingDoc ? (
                <DatacorLogoSpinner />
            ) : (
                <Box className="DocumentView appPage" sx={{ width: '100%' }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}>
                        <IconButton
                            component={Link}
                            to="/apAutomation"
                            style={{ borderRadius: 0 }}
                            color="primary"
                            data-testid="breadcrumb">
                            <ArrowBackIcon />
                            <Typography>Back To Document List</Typography>
                        </IconButton>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={6} xl={6}>
                            <Card>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: 'divider',
                                    }}>
                                    <Tabs
                                        value={value}
                                        textColor="inherit"
                                        indicatorColor="secondary"
                                        onChange={handleChange}
                                        aria-label="document view tabs">
                                        <Tab label="Document Info" value={0} />
                                        <Tab label="PO Receipts" value={1} />
                                        <Tab label="Approvers" value={2} />
                                        <Tab label="History" value={3} />
                                    </Tabs>
                                </Box>
                                <TabPanel value={value} index={0}>
                                    <DocumentInfoTab documentId={documentId} />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <PoInformationTab documentId={documentId} />
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <ApprovalsTab documentId={documentId} />
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                    <InvoiceHistoryTab
                                        documentId={documentId}
                                    />
                                </TabPanel>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={12} lg={6} xl={6}>
                            <Card data-testid="invoice-preview">
                                <CardHeader title="Invoice Preview" />
                                <CardContent>
                                    <InvoicePreview documentId={documentId} />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
};

export default DocumentView;
