import React, { useEffect, useState } from 'react';
import {
    useDeleteCommentMutation,
    useGetDocumentQuery,
} from '../../../../services/apAutomation/apAutomation.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    Popover,
} from '@mui/material';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import LoaderButton from '../../../../components/LoaderButton';
import { isInvoiceLocked } from '../../Utils';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import '../../comments/Comments.css';
import { RootState } from '../../../../store';
import { useGetUsersQuery } from '../../../../services/organizations/organizations.service';
import { UserList } from '../../../../utils/users/userList';

interface DeleteProps {
    commentDate: number;
}

interface CommentProps {
    comment: Comment;
    author: CommentAuthor;
}

interface Comment {
    username: string;
    commentDate: number;
    comment: string;
    dateCreated: string;
    lastUpdateDate: string;
}

interface CommentAuthor {
    firstName: string;
    lastName: string;
    email: string;
}

const useCommentsList = (documentId: string) => {
    const [deleteComment] = useDeleteCommentMutation();
    const { data: activeDocument } = useGetDocumentQuery(
        documentId ? documentId : skipToken
    );
    let [commentComponents, setCommentComponents] = useState([]);
    const user = useSelector((state: RootState) => state.user);
    const { data: userList } = useGetUsersQuery();

    useEffect(() => {
        if (activeDocument?.comments && userList) {
            setCommentComponents(
                _.orderBy(
                    activeDocument.comments,
                    ['dateCreated'],
                    ['desc']
                ).map((comment: Comment, index: number) => {
                    const commentUser = new UserList(
                        userList
                    )?.getUserFromUsername(comment.username);
                    const commentAuthor: CommentAuthor = !isNilOrEmpty(
                        commentUser
                    )
                        ? {
                              firstName: commentUser.firstName,
                              lastName: commentUser.lastName,
                              email: commentUser.email,
                          }
                        : { firstName: '', lastName: '', email: '' };

                    return (
                        <Comment
                            comment={comment}
                            author={commentAuthor}
                            key={index}
                        />
                    );
                })
            );
        }
    }, [activeDocument, userList]);

    const submitDeleteComment = (commentDate: number) => {
        const args = {
            documentId: activeDocument.documentId,
            commentDate: commentDate,
        };
        deleteComment(args);
    };

    const Delete = (props: DeleteProps) => {
        const [showOverlay, setShowOverlay] = useState(false);

        const [anchorEl, setAnchorEl] =
            React.useState<HTMLButtonElement | null>(null);

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
            setShowOverlay(true);
        };

        return (
            <>
                <CommentPopOver
                    props={props}
                    showOverlay={showOverlay}
                    setShowOverlay={setShowOverlay}
                    anchorEl={anchorEl}
                />
                <IconButton
                    color="error"
                    onClick={handleClick}
                    style={{ maxHeight: '30px' }}
                    disabled={isInvoiceLocked(activeDocument)}>
                    <DeleteForeverIcon />
                </IconButton>
            </>
        );
    };

    const CommentPopOver = ({
        props,
        showOverlay,
        setShowOverlay,
        anchorEl,
    }: any) => {
        return (
            <Popover id="popover-basic" open={showOverlay} anchorEl={anchorEl}>
                <Typography sx={{ p: 2 }}>
                    Do you want to remove this comment?
                </Typography>
                <LoaderButton
                    isLoading={null}
                    variant="contained"
                    data-testid="removeConfirmationBtn"
                    onClick={() => {
                        submitDeleteComment(props.commentDate);
                        setShowOverlay(false);
                    }}>
                    Yes
                </LoaderButton>
                <LoaderButton
                    isLoading={null}
                    variant="contained"
                    data-testid="cancelRemoveBtn"
                    onClick={() => setShowOverlay(false)}>
                    No
                </LoaderButton>
            </Popover>
        );
    };

    const Comment = (props: CommentProps) => {
        return (
            <Card className="comment-list" data-testid="comment">
                <CardHeader
                    className="comment-list-header"
                    title={
                        <Grid container spacing={2}>
                            <Grid item xs={11} style={{ fontSize: '16px' }}>
                                <b>
                                    {props.author.firstName}{' '}
                                    {props.author.lastName} (
                                    {props.author.email}){' '}
                                </b>
                                posted at{' '}
                                {moment(props.comment.dateCreated).format(
                                    'MMMM Do YYYY, h:mm:ss a'
                                )}
                            </Grid>

                            <Grid item xs={1}>
                                {user.signInEmail === props.author.email ? (
                                    <Delete
                                        commentDate={props.comment.commentDate}
                                    />
                                ) : null}
                            </Grid>
                        </Grid>
                    }
                />
                <CardContent className="comment-list-comment">
                    {props.comment.comment}
                </CardContent>
            </Card>
        );
    };

    return { commentComponents };
};

export default useCommentsList;
