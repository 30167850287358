import React, { useContext } from 'react';
import SettingsContext from '../../contexts/settings.context';

/**
 * Determines the order of the inputs to display based on the
 * Business Entity Setting
 * **/
const DISPLAY_PREFERENCES: any = {
    firstLast: [1, 3],
    lastFirst: [3, ',', 1],
    prefixFMLSuffix: [0, 1, 2, 3, 4, ',', 5],
    prefLast: [5, 3],
};

interface NameDisplayProps {
    fields: any;
    style?: any;
}

const NameDisplay = ({ fields, style }: NameDisplayProps) => {
    const { settings } = useContext(SettingsContext);
    const { businessEntitySettings } = settings;

    const displayPref: string = businessEntitySettings?.displayPreference;

    const displayOrder =
        DISPLAY_PREFERENCES[displayPref ? displayPref : 'firstLast'];

    const NAME_MAPPINGS: any = {
        0: fields?.prefix || 'N/A' + ' ',
        1: fields?.firstName || 'N/A' + ' ',
        2: fields?.middleName || ' N/A' + ' ',
        3: fields?.lastName || '',
        4: fields?.suffix ? ' ' + fields?.suffix : ' N/A',
        5: fields?.preferred ? ' ' + fields?.preferred + ' ' : ' N/A ',
    };

    return (
        <span style={style}>
            {businessEntitySettings &&
                displayOrder?.map((item: number) => (
                    <React.Fragment key={item}>
                        {NAME_MAPPINGS[item] || ','}{' '}
                    </React.Fragment>
                ))}
        </span>
    );
};

export default NameDisplay;
