import { createSlice } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { onError } from '../libs/errorLib';
import { DATACOR_MULTIPLEXER_URL } from '../libs/constantsLib';
import { AppDispatch } from './index';

const slice = createSlice({
    name: 'workcenters',
    initialState: {
        workcentersSet: {
            isLoading: false,
            workcentersByWarehouseId: [],
        },
    },
    reducers: {
        workcentersLoading: (state, action) => {
            state.workcentersSet.isLoading = action.payload;
        },
        getWorkcentersSuccess: (state, action) => {
            state.workcentersSet.workcentersByWarehouseId =
                action.payload.workcenters.reduce((r: any, a: any) => {
                    const warehouseId = a.Warehouse
                        ? a.Warehouse.toUpperCase()
                        : 'unknown';
                    r[warehouseId] = [...(r[warehouseId] || []), a];
                    return r;
                }, {});
            state.workcentersSet.isLoading = action.payload.isLoading;
        },
    },
});

export default slice.reducer;

// Actions
export const { workcentersLoading, getWorkcentersSuccess } = slice.actions;

export const getWorkcenters = () => async (dispatch: AppDispatch) => {
    dispatch(workcentersLoading(true));
    const sessionData: any = await Auth.currentSession();
    await fetch(DATACOR_MULTIPLEXER_URL + 'workcenters', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionData.idToken.jwtToken,
            'Ocp-Apim-Trace': 'True',
            'Ocp-Apim-Subscription-Key': '536572ce1293439e9147aac7511889dd',
        },
    })
        // By design, the instant state and value of a promise cannot be inspected synchronously from code, without calling the then() method
        .then((dataWrappedByPromise) => dataWrappedByPromise.json())
        .then((data) => {
            dispatch(
                getWorkcentersSuccess({ isLoading: false, workcenters: data })
            );
        })
        .catch((error) => {
            return onError(error.message);
        });
};
