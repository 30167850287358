import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BatchTicketStepTable from './BatchTicketStepTable';
import { Product, Step } from '../../types/BatchTicket.types';
import { Warehouse } from '../../types/PricingAnalysis.types';
import { Grid, TextField } from '@mui/material';
import { RootState } from '../../store';

interface BatchTicketStepDetailsProps {
    step: Step;
    isReadOnly?: boolean;
}

const initialState = {
    Measure: 0,
    ProductClass: '',
    ProductName: '',
    ProductNumber: '',
    UnitOfMeasure: '',
    UnitOfPackaging: '',
    Weight: 0,
};

const BatchTicketStepDetails = ({
    step,
    isReadOnly = true,
}: BatchTicketStepDetailsProps) => {
    const [product, setProduct] = useState<Product>(initialState);
    const [warehouse, setWarehouse] = useState(null);
    const { warehousesSet } = useSelector(
        (state: RootState) => state.warehouses
    );

    useEffect(() => {
        if (
            !warehousesSet.warehouseProductsIsLoading &&
            warehousesSet.warehouseProductsByWarehouse[step.batchTicket.Whs]
        ) {
            const product = warehousesSet.warehouseProductsByWarehouse[
                step.batchTicket.Whs
            ].find(
                (product: Product) =>
                    product.ProductNumber === step.batchTicket.Pnum
            );
            if (product) {
                setProduct(product);
            }
            setWarehouse(
                warehousesSet.warehouses.find(
                    (warehouse: Warehouse) =>
                        warehouse.WarehouseNumber === step.batchTicket.Whs
                )
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return warehouse ? (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <TextField
                    label=" Product:"
                    inputProps={{ readOnly: isReadOnly }}
                    value={
                        step.batchTicket.ProductName
                            ? step.batchTicket.ProductName
                            : ''
                    }
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Description:"
                    inputProps={{ readOnly: isReadOnly }}
                    value={
                        step.batchTicket.FormulaDescription
                            ? step.batchTicket.FormulaDescription
                            : ''
                    }
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Prod. Measure:"
                    inputProps={{ readOnly: isReadOnly }}
                    value={product.Measure ? product.Measure : ''}
                />
            </Grid>
            <p />
            <Grid item xs={4}>
                <TextField
                    label="Batch Size:"
                    inputProps={{ readOnly: isReadOnly }}
                    value={
                        step.batchTicket.BatchSize
                            ? step.batchTicket.BatchSize
                            : ''
                    }
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Customer:"
                    inputProps={{ readOnly: isReadOnly }}
                    value={
                        step.batchTicket.SalesOrder
                            ? step.batchTicket.SalesOrder
                            : ''
                    }
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Remarks:"
                    inputProps={{ readOnly: isReadOnly }}
                    value={
                        step.batchTicket.FormulaRemarks
                            ? step.batchTicket.FormulaRemarks
                            : ''
                    }
                />
            </Grid>

            <hr />
            <Grid item xs={4}>
                <TextField
                    label="U/M:"
                    inputProps={{ readOnly: isReadOnly }}
                    value={product.UnitOfMeasure ? product.UnitOfMeasure : ''}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="U/P:"
                    inputProps={{ readOnly: isReadOnly }}
                    value={
                        product.UnitOfPackaging ? product.UnitOfPackaging : ''
                    }
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="ProductClass:"
                    inputProps={{ readOnly: isReadOnly }}
                    value={product.ProductClass ? product.ProductClass : ''}
                />
            </Grid>

            <Grid item xs={4}>
                <TextField
                    label="Wgt/Gal:"
                    inputProps={{ readOnly: isReadOnly }}
                    value={product.Weight ? product.Weight : ''}
                />
            </Grid>

            <Grid item xs={4}>
                <TextField />
            </Grid>
            <Grid item xs={4}>
                <TextField />
            </Grid>

            <hr />
            <Grid item xs={4}>
                <TextField
                    label="Warehouse:"
                    inputProps={{ readOnly: isReadOnly }}
                    value={warehouse.WarehouseName}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Workcenter:"
                    inputProps={{ readOnly: isReadOnly }}
                    value={
                        step.batchTicket.Workcenter
                            ? step.batchTicket.Workcenter
                            : ''
                    }
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="U/M:"
                    inputProps={{ readOnly: isReadOnly }}
                    value={
                        step.batchTicket.BatchUM ? step.batchTicket.BatchUM : ''
                    }
                />
            </Grid>

            <Grid item xs={4}>
                <TextField
                    label="Product Name:"
                    inputProps={{ readOnly: isReadOnly }}
                    value={product.ProductName ? product.ProductName : ''}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Packaging:"
                    inputProps={{ readOnly: true }}
                    value={`${product.Measure} ${product.UnitOfMeasure} ${product.UnitOfPackaging}`}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Warehouse/Lot:"
                    inputProps={{ readOnly: true }}
                    value={
                        step.batchTicket.LotNumber
                            ? step.batchTicket.LotNumber
                            : ''
                    }
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Form. Size, U/M:"
                    inputProps={{ readOnly: isReadOnly }}
                    value={`${step.batchTicket.FormulaSize} ${step.batchTicket.FormulaUM}`}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Product #:"
                    inputProps={{ readOnly: isReadOnly }}
                    value={product.ProductNumber ? product.ProductNumber : ''}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Formula Code:"
                    inputProps={{ readOnly: true }}
                    value={
                        step.batchTicket.FormulaCode
                            ? step.batchTicket.FormulaCode
                            : ''
                    }
                />
            </Grid>

            <p />
            <Grid item xs={4}>
                <TextField
                    label="Formula Version:"
                    inputProps={{ readOnly: true }}
                    value={
                        step.batchTicket.Version ? step.batchTicket.Version : ''
                    }
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Batch Amount, U/M:"
                    inputProps={{ readOnly: isReadOnly }}
                    value={`${step.batchTicket.BatchSize} ${step.batchTicket.BatchUM}`}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Remarks:"
                    inputProps={{ readOnly: isReadOnly }}
                    value={
                        step.batchTicket.BatchRemarks
                            ? step.batchTicket.BatchRemarks
                            : ''
                    }
                />
            </Grid>

            <hr />
            <Grid item xs={12}>
                <BatchTicketStepTable
                    step={step}
                    warehouseId={step.batchTicket.Whs}
                />
            </Grid>
        </Grid>
    ) : null;
};

export default BatchTicketStepDetails;
