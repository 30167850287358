import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import GlobalEntityDataContext from '../contexts/globalEntityData.context';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { useCreateUserHistoryMutation } from '../services/organizations/organizations.service';
import config from '../config';
import { handleInvalidateTags } from '../utils/services/invalidateTags';
import * as Sentry from '@sentry/react';
import DatacorLogoSpinner from './datacorLogoSpinner/DatacorLogoSpinner';

enum MutationType {
    characterData = 'characterData',
    childList = 'childList',
}
const TARGET_NODE_ID = 'title';
const HISTORY_KEY = 'history';

const RouteWrapper = ({ component, path, primaryEntity }: any) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const { entityData, setEntityData } = useContext(GlobalEntityDataContext);
    const [createHistory] = useCreateUserHistoryMutation();
    const [connected, setConnected] = useState(false);

    Sentry.getCurrentScope().setTransactionName(component.type.name);

    const callbackConfig = {
        attributes: true,
        childList: true,
        subtree: true,
        characterData: true,
    };

    const connectToWebSocket = () => {
        /**
         * if we don't have a webSocketGateway URL we don't want to connect
         */
        //@ts-ignore
        if (!config?.webSocketGateway?.URL) {
            return;
        }

        const socket = new WebSocket(
            //@ts-ignore
            `${config?.webSocketGateway?.URL}?userId=${user?.userId}&businessEntityId=${user?.businessEntity?.id}`
        );

        socket.addEventListener('open', function (event) {
            setConnected(true);
        });

        socket.addEventListener('message', async function (event) {
            const eventMessage = JSON.parse(event.data).message;
            const tag = JSON.parse(eventMessage).data;
            await handleInvalidateTags(dispatch, null, [tag]);
        });

        socket.addEventListener('close', function (event) {
            setConnected(false);
        });
    };

    useEffect(() => {
        if (!user?.userId) {
            return;
        }
        if (!connected) {
            connectToWebSocket();
        }
    }, [user, connected]);

    const callback = (mutationsList: any, observer: any) => {
        for (const mutation of mutationsList) {
            if (mutation.type === MutationType.characterData) {
                const newTitle = `${mutation.target.nodeValue} - ${
                    component?.props?.entityType?.name || ''
                } - Datacor`;
                document.title = newTitle;
                handleSetHistory(newTitle);
            } else if (mutation.type === MutationType.childList) {
                const newTitle = `${mutation.target.textContent} - ${
                    component?.props?.entityType?.name || ''
                } - Datacor`;
                document.title = newTitle;
                handleSetHistory(newTitle);
            }
        }
    };

    const observer = new MutationObserver(callback);
    const targetNode = document.getElementById(TARGET_NODE_ID);

    useEffect(() => {
        handlePageTitle();
        return () => {
            observer.disconnect();
        };
    }, [location.pathname, targetNode]);

    useEffect(() => {
        handleSetEntityData();
        //on unmount we want to clear the entityData
        return () => {
            setEntityData({});
        };
    }, [path, location.pathname, document.title]);

    const handleSetEntityData = () => {
        if (component.props.entityType) {
            setEntityData((prev: any) => ({
                ...prev,
                entityType: component.props.entityType,
                fullPath: location.pathname,
                primaryEntity: primaryEntity || false,
                businessEntityId: user?.businessEntity?.id,
                pageTitle: document.title,
            }));
        }
    };
    const handlePageTitle = () => {
        if (targetNode && component.props?.entityType?.name) {
            observer.observe(targetNode, callbackConfig);
            return;
        } else if (component.props?.entityType?.name) {
            const newTitle = `${component.props?.entityType?.name} - Datacor`;
            document.title = newTitle;
            if (!primaryEntity) {
                handleSetHistory(newTitle);
            }
            return;
        } else {
            document.title = 'Datacor Web';
        }

        if (!primaryEntity) {
            handleSetHistory('Datacor Web');
        }
    };

    const handleSetHistory = (pageTitle: string) => {
        //parse the - Datacor out of the title
        pageTitle = pageTitle.replace(' - Datacor', '');

        let historyItem = {
            entityTypeId: component.props?.entityType?.id || null,
            fullPath: location.pathname,
            pageTitle: pageTitle,
            primaryEntity: primaryEntity || false,
            businessEntityId: user?.businessEntity?.id,
        };

        /**
         * dont want the page to fail if history creation fails
         */
        try {
            createHistory(historyItem);
        } catch (error) {
            console.log('Error creating user history', error);
        }
    };

    return (
        <>
            <React.Suspense fallback={<DatacorLogoSpinner />}>
                {component}
            </React.Suspense>
        </>
    );
};

export default RouteWrapper;
