import { useDispatch, useSelector } from 'react-redux';
import {
    Alert,
    AlertColor,
    Icon,
    IconButton,
    Snackbar,
    SnackbarOrigin,
} from '@mui/material';
import React from 'react';
import { RootState } from '../../store';
import { clearSnackbarBasic } from '../../store/uiElements';

export interface ToastContainerProps {
    message: string;
    anchorOrigin?: SnackbarOrigin;
    severity?: AlertColor;
    autoHideDuration?: number;
}

const SnackbarBasic = () => {
    const dispatch = useDispatch();
    const { snackbarBasic } = useSelector(
        (state: RootState) => state.uiElements
    );

    function handleClose() {
        dispatch(clearSnackbarBasic());
    }

    return (
        <Snackbar
            anchorOrigin={
                snackbarBasic.anchorOrigin || {
                    vertical: 'top',
                    horizontal: 'right',
                }
            }
            autoHideDuration={snackbarBasic.autoHideDuration || 5000}
            open={snackbarBasic.isOpen}
            onClose={handleClose}>
            <Alert
                severity={snackbarBasic.severity || 'info'}
                sx={{
                    width: '100%',
                    textAlign: 'center',
                    whiteSpace: 'pre-line',
                }}>
                {snackbarBasic.message}
            </Alert>
        </Snackbar>
    );
};

export default SnackbarBasic;
