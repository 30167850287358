import { useDispatch } from 'react-redux';
import { logout } from '../../../store/user';
import { isFeatureFlagEnabled } from '../../../utils/featureFlag/featureFlagUtil';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import React from 'react';

interface SettingsMenuProps {
    user: any;
    anchorEl: HTMLElement | null;
    setAnchorEl: (value: any) => void;
}

const SettingsMenu = ({ user, anchorEl, setAnchorEl }: SettingsMenuProps) => {
    const dispatch = useDispatch();
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch(logout());
    };
    const menuItems = [
        {
            title: 'User Settings',
            link: '/settings/user',
            testId: 'user-settings',
            shouldShow: isFeatureFlagEnabled(
                user.enabledFeatureFlags,
                'dashboard.nav.apps.settings'
            ),
        },
        {
            title: 'Business Entity Settings',
            link: '/settings/business-entity',
            testId: 'be-settings',
            shouldShow:
                isFeatureFlagEnabled(
                    user.enabledFeatureFlags,
                    'dashboard.nav.apps.settings'
                ) &&
                isFeatureFlagEnabled(
                    user.enabledFeatureFlags,
                    'dashboard.nav.apps.administration'
                ),
        },
        {
            title: 'Module Settings',
            link: '/settings/module',
            testId: 'module-settings',
            shouldShow:
                isFeatureFlagEnabled(
                    user.enabledFeatureFlags,
                    'dashboard.nav.apps.settings'
                ) &&
                (isFeatureFlagEnabled(
                    user.enabledFeatureFlags,
                    'dashboard.nav.apps.formulation'
                ) ||
                    isFeatureFlagEnabled(
                        user.enabledFeatureFlags,
                        'dashboard.nav.apps.fixedAssets'
                    )),
        },
        {
            title: 'Roles',
            link: '/administration/organizations/roles',
            testId: 'access-roles',
            shouldShow:
                PermissionsUtil.isPermissionEnabled(
                    user.permissions,
                    PERMISSIONS.USER_PORTAL.VIEW
                ) &&
                isFeatureFlagEnabled(
                    user.enabledFeatureFlags,
                    'settings.roles'
                ) &&
                isFeatureFlagEnabled(
                    user.enabledFeatureFlags,
                    'dashboard.nav.apps.administration'
                ),
        },
        {
            title: 'Feature Flags',
            link: '/administration/feature-flags',
            testId: 'feature-flags',
            shouldShow:
                user.isDatacorAdmin &&
                PermissionsUtil.isPermissionEnabled(
                    user.permissions,
                    PERMISSIONS.SETTINGS.FEATURE_FLAGS.VIEW
                ) &&
                isFeatureFlagEnabled(
                    user.enabledFeatureFlags,
                    'dashboard.nav.apps.administration'
                ),
        },
        {
            title: 'Logout',
            testId: 'logout',
            shouldShow: true,
        },
    ];
    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}>
            {menuItems.map((item) => {
                if (item.shouldShow) {
                    return (
                        <Link
                            key={item.title}
                            to={item.link || '/'}
                            style={{
                                textDecoration: 'none',
                                color: 'black',
                            }}
                            data-testid={item.testId}>
                            <MenuItem
                                onClick={
                                    item.link ? handleClose : handleLogout
                                }>
                                {item.title}
                            </MenuItem>
                        </Link>
                    );
                }
            })}
        </Menu>
    );
};

export default SettingsMenu;
