import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    useGetAllApprovalWorkflowsQuery,
    useUpdateApprovalWorkflowMutation,
} from '../../../../services/apAutomation/apAutomation.service';
import { ApprovalWorkflow } from '../../../../types/ApAutomation.types';
import { RootState } from '../../../../store';

const useIsActiveCellRenderer = ({ data, rowIndex }: any) => {
    const user = useSelector((state: RootState) => state.user);
    const [approvers, setApprovers] = useState(data.approvers);
    const [updateApprovalWorkflow] = useUpdateApprovalWorkflowMutation();
    const { data: approvalWorkflows, isLoading: isLoadingWorkflows } =
        useGetAllApprovalWorkflowsQuery();

    useEffect(() => {
        if (isLoadingWorkflows) {
            return;
        }
        const workflow = approvalWorkflows.find(
            (workflow: ApprovalWorkflow) =>
                workflow.workflowId === data.workflowId
        );
        if (workflow) {
            setApprovers(workflow.approvers);
        }
    }, [approvalWorkflows]);

    const updateWorkflow = async (newIsActiveValue: boolean) => {
        const args = {
            workflowId: data.workflowId,
            workflowName: data.workflowName,
            approvers: approvers,
            isActive: newIsActiveValue,
        };

        updateApprovalWorkflow(args);
    };
    return { user, approvalWorkflows, updateWorkflow };
};

export default useIsActiveCellRenderer;
