import { lazy } from 'react';
import { RouteProps } from './routesConfig';
import { PERMISSIONS } from '../constants/permissions/Permissions.constants';
import { ENTITY_TYPES } from '../constants/shared/EntityTypes.constants';

const SubscribersGrid = lazy(
    () => import('../containers/administration/SubscribersGrid')
);
const SubscriberForm = lazy(
    () => import('../containers/administration/SubscriberForm')
);
const BusinessEntityGrid = lazy(
    () => import('../containers/administration/BusinessEntityGrid')
);
const BusinessEntityForm = lazy(
    () => import('../containers/administration/BusinessEntityForm')
);
const UserForm = lazy(() => import('../containers/administration/UserForm'));
const GroupsGrid = lazy(
    () => import('../containers/administration/GroupsGrid')
);
const GroupsForm = lazy(
    () => import('../containers/administration/GroupsForm')
);
const RoleForm = lazy(() => import('../containers/administration/RoleForm'));
const DepartmentCodesGrid = lazy(
    () => import('../containers/administration/DepartmentCodesGrid')
);
const DepartmentCodeForm = lazy(
    () => import('../containers/administration/DepartmentCodeForm')
);
const BuildingsGrid = lazy(
    () => import('../containers/administration/BuildingsGrid')
);
const BuildingsForm = lazy(
    () => import('../containers/administration/BuildingsForm')
);
const PermissionRoleGrid = lazy(
    () => import('../containers/administration/PermissionRoleGrid')
);
const PermissionTree = lazy(
    () => import('../containers/administration/PermissionTree')
);
const AdministrationPortal = lazy(
    () => import('../containers/administration/AdministrationPortal')
);
const LanguagesGrid = lazy(
    () => import('../containers/administration/LanguagesGrid')
);
const RevisionSearch = lazy(
    () => import('../containers/administration/RevisionSearch')
);
const FeatureFlagsGrid = lazy(
    () => import('../containers/administration/FeatureFlagsGrid')
);
const AttachmentsSearch = lazy(
    () => import('../containers/administration/attachments/AttachmentSearch')
);
const AttachmentForm = lazy(
    () => import('../containers/administration/attachments/AttachmentForm')
);
const JobLogsGrid = lazy(
    () => import('../containers/administration/jobs/JobLogsGrid')
);
const JobRunsGrid = lazy(
    () => import('../containers/administration/jobs/JobRunsGrid')
);
const JobDefinitionConfigurationGrid = lazy(
    () =>
        import(
            '../containers/administration/jobs/JobDefinitionConfigurationGrid'
        )
);
const JobRunsForm = lazy(
    () => import('../containers/administration/jobs/JobRunsForm')
);
const JobLogsForm = lazy(
    () => import('../containers/administration/jobs/JobLogsForm')
);
const JobDefinitionConfigurationForm = lazy(
    () =>
        import(
            '../containers/administration/jobs/JobDefinitionConfigurationForm'
        )
);
const DocumentFolderForm = lazy(
    () => import('../containers/administration/documents/DocumentFolderForm')
);
const DocumentFolderGrid = lazy(
    () => import('../containers/administration/documents/DocumentFolderGrid')
);
const CheckedoutDocumentGrid = lazy(
    () =>
        import('../containers/administration/documents/CheckedoutDocumentGrid')
);
const AccessRestrictions = lazy(
    () =>
        import(
            '../containers/administration/access-restrictions/AccessRestrictions'
        )
);
const NotificationsGrid = lazy(
    () => import('../components/administration/notifications/NotificationsGrid')
);
const BusinessEntitySubscriptionsGrid = lazy(
    () =>
        import(
            '../components/administration/notifications/BusinessEntitySubscriptionsGrid'
        )
);
const UserSubscriptionsGrid = lazy(
    () =>
        import(
            '../components/administration/notifications/UserSubscriptionsGrid'
        )
);

const UsersGrid = lazy(() => import('../containers/administration/UsersGrid'));
const AdministrationRolesGrid = lazy(
    () => import('../containers/administration/RolesGrid')
);

const AdministrationRoutes: RouteProps[] = [
    {
        path: '/administration',
        component: AdministrationPortal,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
    },
    {
        path: '/administration/organizations/subscribers',
        component: SubscribersGrid,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
        entityType: ENTITY_TYPES.SUBSCRIBER,
    },
    {
        path: '/administration/organizations/subscribers/new',
        component: SubscriberForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
        entityType: ENTITY_TYPES.SUBSCRIBER,
    },
    {
        path: '/administration/organizations/subscribers/:id',
        component: SubscriberForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
        entityType: ENTITY_TYPES.SUBSCRIBER,
        primaryEntity: true,
    },
    {
        path: '/administration/organizations/business-entities',
        component: BusinessEntityGrid,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.BUSINESS_ENTITIES.VIEW,
        entityType: ENTITY_TYPES.BUSINESS_ENTITY,
    },
    {
        path: '/administration/organizations/business-entities/new',
        component: BusinessEntityForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.BUSINESS_ENTITIES.CREATE,
        entityType: ENTITY_TYPES.BUSINESS_ENTITY,
    },
    {
        path: '/administration/organizations/business-entities/:id',
        component: BusinessEntityForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.BUSINESS_ENTITIES.VIEW,
        entityType: ENTITY_TYPES.BUSINESS_ENTITY,
        primaryEntity: true,
    },
    {
        path: '/administration/organizations/users',
        component: UsersGrid,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.USERS.VIEW,
        entityType: ENTITY_TYPES.USERS,
    },
    {
        path: '/administration/organizations/users/new',
        component: UserForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.USERS.CREATE,
        entityType: ENTITY_TYPES.USERS,
    },
    {
        path: '/administration/organizations/users/:id',
        component: UserForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.USERS.VIEW,
        entityType: ENTITY_TYPES.USERS,
        primaryEntity: true,
    },
    {
        path: '/administration/organizations/groups',
        component: GroupsGrid,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
        entityType: ENTITY_TYPES.GROUP,
    },
    {
        path: '/administration/organizations/groups/:id',
        component: GroupsForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
        entityType: ENTITY_TYPES.GROUP,
        primaryEntity: true,
    },
    {
        path: '/administration/organizations/roles',
        component: AdministrationRolesGrid,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
        entityType: ENTITY_TYPES.ROLE,
    },
    {
        path: '/administration/organizations/roles/new',
        component: RoleForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
        entityType: ENTITY_TYPES.ROLE,
    },
    {
        path: '/administration/organizations/roles/:id',
        component: RoleForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
        entityType: ENTITY_TYPES.ROLE,
        primaryEntity: true,
    },
    {
        path: '/administration/organizations/permissions',
        component: PermissionRoleGrid,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
        entityType: ENTITY_TYPES.PERMISSIONS,
    },
    {
        path: '/administration/organizations/permissions/:id',
        component: PermissionTree,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
        entityType: ENTITY_TYPES.PERMISSIONS,
        primaryEntity: true,
    },
    {
        path: '/administration/organizations/departmentCodes',
        component: DepartmentCodesGrid,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
        entityType: ENTITY_TYPES.DEPARTMENT_CODES,
    },
    {
        path: '/administration/organizations/departmentCodes/:id',
        component: DepartmentCodeForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
        entityType: ENTITY_TYPES.DEPARTMENT_CODES,
        primaryEntity: true,
    },
    {
        path: '/administration/organizations/buildings',
        component: BuildingsGrid,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
        entityType: ENTITY_TYPES.BUILDING,
    },
    {
        path: '/administration/organizations/buildings/new',
        component: BuildingsForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
        entityType: ENTITY_TYPES.BUILDING,
    },
    {
        path: '/administration/organizations/buildings/:id',
        component: BuildingsForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
        entityType: ENTITY_TYPES.BUILDING,
        primaryEntity: true,
    },
    {
        path: '/administration/setup/languages',
        component: LanguagesGrid,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
        entityType: ENTITY_TYPES.LANGUAGES,
        primaryEntity: true,
    },
    {
        path: '/administration/revisions',
        component: RevisionSearch,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
        entityType: ENTITY_TYPES.REVISIONS,
        primaryEntity: true,
    },
    {
        path: '/administration/attachments',
        component: AttachmentsSearch,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ATTACHMENTS.ATTACHMENT.VIEW,
        entityType: ENTITY_TYPES.ATTACHMENT,
    },
    {
        path: '/administration/attachments/:id',
        component: AttachmentForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ATTACHMENTS.ATTACHMENT.VIEW,
        entityType: ENTITY_TYPES.ATTACHMENT,
        primaryEntity: true,
    },
    {
        path: 'administration/documents/folders/:id',
        component: DocumentFolderForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ATTACHMENTS.ATTACHMENT_FOLDER.VIEW,
    },
    {
        path: 'administration/documents/folders',
        component: DocumentFolderGrid,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ATTACHMENTS.ATTACHMENT_FOLDER.VIEW,
        entityType: ENTITY_TYPES.FOLDER,
    },
    {
        path: 'administration/documents/checked-out',
        component: CheckedoutDocumentGrid,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ATTACHMENTS.ATTACHMENT.VIEW,
    },
    {
        path: '/administration/feature-flags',
        component: FeatureFlagsGrid,
        datacorAdminOnly: true,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
        entityType: ENTITY_TYPES.FEATURE_FLAGS,
        primaryEntity: true,
    },
    {
        path: '/administration/jobs/runs',
        component: JobRunsGrid,
        datacorAdminOnly: true,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
        entityType: ENTITY_TYPES.JOB_RUNS,
    },
    {
        path: '/administration/jobs/runs/:id',
        component: JobRunsForm,
        datacorAdminOnly: true,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
        entityType: ENTITY_TYPES.JOB_RUNS,
        primaryEntity: true,
    },
    {
        path: '/administration/jobs/logs',
        component: JobLogsGrid,
        datacorAdminOnly: true,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
        entityType: ENTITY_TYPES.JOB_LOGS,
    },
    {
        path: '/administration/jobs/logs/:id',
        component: JobLogsForm,
        datacorAdminOnly: true,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
        entityType: ENTITY_TYPES.JOB_LOGS,
        primaryEntity: true,
    },
    {
        path: '/administration/jobs/job-definition-configuration',
        component: JobDefinitionConfigurationGrid,
        datacorAdminOnly: true,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
        entityType: ENTITY_TYPES.JOB_DEFINITION_CONFIGURATION,
    },
    {
        path: '/administration/jobs/job-definition-configuration/:id',
        component: JobDefinitionConfigurationForm,
        datacorAdminOnly: true,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
        entityType: ENTITY_TYPES.JOB_DEFINITION_CONFIGURATION,
        primaryEntity: true,
    },
    {
        path: '/administration/accessRestrictions',
        component: AccessRestrictions,
        featureFlag: 'feature.apps.accessRestrictions',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
    },
    {
        path: '/administration/notifications',
        component: NotificationsGrid,
        featureFlag: 'feature.apps.notifications',
    },
    {
        path: '/administration/notifications/subscriptions/be',
        component: BusinessEntitySubscriptionsGrid,
        featureFlag: 'feature.apps.notifications',
    },
    {
        path: '/administration/notifications/subscriptions/user',
        component: UserSubscriptionsGrid,
        featureFlag: 'feature.apps.notifications',
    },
];

export default AdministrationRoutes;
