import React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import useDocumentViewer from './hooks/useDocumentViewer';
import DatacorLogoSpinner from '../datacorLogoSpinner/DatacorLogoSpinner';

const DocumentViewer = ({ id, documentType, document }: any) => {
    const { isLoading } = useDocumentViewer(document);
    return (
        <Card>
            <CardHeader title={'Document Preview'} />
            <CardContent>
                <div
                    id="reportViewer"
                    style={
                        isLoading
                            ? {
                                  width: '100%',
                                  height: '100%',
                                  visibility: 'hidden',
                              }
                            : { width: '100%', height: '100%' }
                    }
                />
                {isLoading && <DatacorLogoSpinner />}
            </CardContent>
        </Card>
    );
};

export default DocumentViewer;
