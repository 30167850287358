import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import {
    currencyFormatter,
    dateFormatter,
} from '../../../utils/formattingUtils';
import Grid from '../../../components/grids/Grid';
import useRecommendationGrid from '../hooks/PricingConcernDetails/useRecommendationGrid';

const RecommendationGrid = ({
    externalCustomerId = null,
    productId = null,
}) => {
    const { rowData, onGridReady, ResultingMargin, AtTimeOfRecommendation } =
        useRecommendationGrid({ externalCustomerId, productId });

    return (
        <Grid
            testId="recommendation-grid"
            gridStyle={{ width: '100%' }}
            immutableData={true}
            dataKey="value"
            defaultColDef={{
                resizable: true,
                sortable: true,
                unSortIcon: true,
                filter: true,
                wrapText: true,
            }}
            paginationPageSize={5}
            rowData={rowData}
            onGridReady={onGridReady}
            suppressMenuHide={true}
            frameworkComponents={{
                atTimeOfRecommendation: AtTimeOfRecommendation,
                resultingMargin: ResultingMargin,
            }}>
            <AgGridColumn
                field="recommendedBy"
                width={150}
                headerName="Name"
                headerClass={'pick-list-header'}
            />
            <AgGridColumn
                field="lastUpdateDate"
                width={125}
                headerName="Date"
                headerClass={'pick-list-header'}
                valueFormatter={dateFormatter}
            />
            <AgGridColumn headerName="Recommended">
                <AgGridColumn
                    field="recommendedPrice"
                    width={125}
                    headerName="Price"
                    headerClass={'pick-list-header'}
                    valueFormatter={currencyFormatter}
                />
                <AgGridColumn
                    field="resultingMargin"
                    width={125}
                    headerName="Margin"
                    headerClass={'pick-list-header'}
                    cellRenderer="resultingMargin"
                />
            </AgGridColumn>
            <AgGridColumn
                field="atTimeOfRecommendation"
                width={185}
                headerName="Cost / Price At Time Of Recommendation"
                headerClass={'pick-list-header'}
                cellRenderer="atTimeOfRecommendation"
            />
            <AgGridColumn
                field="userComment"
                headerName="Comment"
                width={185}
                headerClass={'pick-list-header'}
                flex={1}
            />
            <AgGridColumn
                field="emailedRecommentation"
                headerName="Emailed Agent"
                width={125}
                headerClass={'pick-list-header'}
            />
        </Grid>
    );
};

export default RecommendationGrid;
