import React from 'react';
import LoaderButton from '../../components/LoaderButton';
import './Signup.css';
import { TextField, Grid } from '@mui/material';
import useSignup from './hooks/useSignup';

const Signup = () => {
    const { fields, handleFieldChange, isLoading, validateForm, signupEvent } =
        useSignup();

    return (
        <div className="Signup">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        autoFocus
                        fullWidth
                        label="Email"
                        id="email"
                        data-testid="email"
                        type="email"
                        value={fields.email}
                        variant="outlined"
                        onChange={handleFieldChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Password"
                        id="password"
                        data-testid="password"
                        type="password"
                        value={fields.password}
                        onChange={handleFieldChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Confirm Password"
                        id="confirmPassword"
                        data-testid="confirmPassword"
                        type="password"
                        value={fields.confirmPassword}
                        onChange={handleFieldChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <LoaderButton
                        fullWidth
                        onClick={signupEvent}
                        type="submit"
                        size="large"
                        variant="contained"
                        isLoading={isLoading}
                        disabled={!validateForm()}>
                        Sign Up
                    </LoaderButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default Signup;
