import React from 'react';
import { ParameterizedValidator, Validator } from './validator.interface';
import _ from 'lodash';
import moment from 'moment';
import { dateFormatter } from '../utils/formattingUtils';

export const MinDateValidator: ParameterizedValidator = (minDate: Date) => {
    return {
        key: 'minDate',
        getErrorMessage: (fieldLabel: string) => {
            if (_.isObject(fieldLabel)) {
                return (
                    <>
                        {fieldLabel} must be greater than or equal to{' '}
                        {dateFormatter(minDate)}
                    </>
                );
            }
            return `${fieldLabel} must be greater than or equal to ${dateFormatter(
                minDate
            )}`;
        },
        isValid: (value: any) => {
            return moment(value).isSameOrAfter(moment(minDate));
        },
    } as Validator;
};
