import React, { createContext } from 'react';

/**
 * context file for getter and setter
 */
const SharedTranslationsContext = createContext({
    sharedTranslations: {},
    setSharedTranslations: (values: any) => {},
});

export default SharedTranslationsContext;
