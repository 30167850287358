import React, { useState } from 'react';
import { useGetDocumentQuery } from '../../../../services/apAutomation/apAutomation.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetAuditDataByKeyQuery } from '../../../../services/users/users.service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { useGetUsersQuery } from '../../../../services/organizations/organizations.service';

const useInvoiceHistoryTab = (documentId: string) => {
    const { data: activeDocument } = useGetDocumentQuery(
        documentId ? documentId : skipToken
    );

    const [open, setOpen] = useState(false);
    const [audit, setAudit] = useState(null);

    const args = {
        companyId: activeDocument.companyId,
        actionType: 'DOCUMENT_INVOICE_UPDATE',
        auditTrailKey: activeDocument.auditTrailKey,
    };

    const { data: auditData, isLoading: isLoadingAuditData } =
        useGetAuditDataByKeyQuery(activeDocument ? args : skipToken);
    const user = useSelector((state: RootState) => state.user);
    const { data: usersList, isLoading: isLoadingUsers } = useGetUsersQuery();
    const shouldLoad = isLoadingAuditData || isLoadingUsers;

    const handleClose = (value: string) => {
        setOpen(false);
    };

    const handleOpen = (e: any) => {
        setOpen(true);
        setAudit(e);
    };

    return {
        shouldLoad,
        activeDocument,
        auditData,
        open,
        handleOpen,
        handleClose,
        audit,
        usersList,
    };
};

export default useInvoiceHistoryTab;
