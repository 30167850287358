import { useContext, useMemo } from 'react';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';
import { skipToken } from '@reduxjs/toolkit/query';
import SettingsContext from '../../../contexts/settings.context';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import {
    clearJobStartedModal,
    openJobItemModal,
} from '../../../store/uiElements';

const useJobStartedModal = () => {
    const dispatch = useDispatch();
    const { jobStarted } = useSelector((state: RootState) => state.uiElements);

    const { settings } = useContext(SettingsContext);

    const handleClose = () => {
        dispatch(clearJobStartedModal());
    };

    const handleViewJob = () => {
        dispatch(clearJobStartedModal());

        dispatch(
            openJobItemModal({
                isOpen: true,
                jobRunId: jobStarted.jobRunId,
            })
        );
    };

    const message = useMemo(() => {
        return `Job '${jobStarted.jobName}' has been started. Click the 'View Job' button to view detail for the Job Run.`;
    }, [jobStarted.jobName]);

    const { data: sharedTermSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: PLATFORM_DEFS.SHARED_COMPONENTS,
              }
            : skipToken
    );

    const { data: jobStartedModalTermSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: PLATFORM_DEFS.JOB_STARTED_MODAL,
              }
            : skipToken
    );

    return {
        message,
        sharedTermSet,
        jobStartedModalTermSet,
        isOpen: jobStarted.isOpen,
        handleViewJob,
        handleClose,
    };
};

export default useJobStartedModal;
