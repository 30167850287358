import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { updateGridModels } from '../../../../store/grids';
import { GridReadyEvent } from 'ag-grid-community';
import {
    resetSelectedCustomerProductDetails,
    updateSelectedCustomerProductDetails,
} from '../../../../store/pricingAnalysisTool';
import { RootState } from '../../../../store';

const usePricingProposalReportGrid = () => {
    const dispatch = useDispatch();
    const [rowData, setRowData] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const { pricingAnalysisToolSet } = useSelector(
        (state: RootState) => state.pricingAnalysisTool
    );
    const { pricingAnalysisTool } = useSelector(
        (state: RootState) => state.grids
    );
    const [showMarginAlert, setShowMarginAlert] = useState(false);

    useEffect(() => {
        setRowData(pricingAnalysisToolSet.selectedCustomerProductDetails);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricingAnalysisToolSet.selectedCustomerProductDetails]);

    const onSortChanged = () => {
        dispatch(
            updateGridModels({
                gridLocation: 'pricingAnalysisTool',
                gridName: 'pricingProposalReport',
                type: 'column',
                model: gridColumnApi.getColumnState(),
            })
        );
    };

    const onFilterChanged = () => {
        dispatch(
            updateGridModels({
                gridLocation: 'pricingAnalysisTool',
                gridName: 'pricingProposalReport',
                type: 'filter',
                model: gridApi.getFilterModel(),
            })
        );
    };

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);

        setRowData(pricingAnalysisToolSet.selectedCustomerProductDetails);
    };

    const onFirstDataRendered = () => {
        gridColumnApi.applyColumnState({
            state: pricingAnalysisTool.pricingProposalReport.column,
            applyOrder: true,
        });

        gridApi.setFilterModel(
            pricingAnalysisTool.pricingProposalReport.filter
        );
    };

    const onCellValueChanged = (event: any): any => {
        const newValue = event.newValue.replace('$', '').replace('%', '');
        if (
            event.column.colId === 'proposedMargin' &&
            !(newValue >= 1 && newValue <= 100)
        ) {
            setShowMarginAlert(true);
        } else {
            setShowMarginAlert(false);
            dispatch(
                updateSelectedCustomerProductDetails(
                    event.data,
                    event.column.colId,
                    newValue,
                    event.oldValue
                )
            );
        }
    };

    const onBtExport = () => {
        gridApi.exportDataAsExcel();
    };

    const reset = () => {
        dispatch(resetSelectedCustomerProductDetails());
    };
    return {
        reset,
        onBtExport,
        onCellValueChanged,
        onFirstDataRendered,
        onGridReady,
        onFilterChanged,
        onSortChanged,
        showMarginAlert,
        rowData,
    };
};
export default usePricingProposalReportGrid;
