import React from 'react';
import FileDropper from './FileDropper';

export type DragAndDropUploaderProps = {
    onDrop: (fileArray: string[], rawFiles?: any) => Promise<void>;
};

const FileDropUploader = (props: DragAndDropUploaderProps) => {
    const readAsDataURL = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = async () => {
                const binaryStr = reader.result;
                resolve(binaryStr as string);
            };
            reader.onerror = () => reject();
            reader.readAsDataURL(file);
        });
    };

    const handleOnDrop = async (acceptedFiles: File[]) => {
        const fileArray: string[] = [];
        for await (const file of acceptedFiles) {
            const data = await readAsDataURL(file);
            fileArray.push(data);
        }
        await props.onDrop(fileArray, acceptedFiles);
    };

    return (
        <>
            <FileDropper onDrop={handleOnDrop} />
        </>
    );
};

export default FileDropUploader;
