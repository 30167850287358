import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { GridReadyEvent } from 'ag-grid-community';
import { updateGridModels } from '../../../../store/grids';
import { RootState } from '../../../../store';

const usePricingCustomerProductDetailsGrid = () => {
    const dispatch = useDispatch();
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [rowData, setRowData] = useState([]);
    const { pricingAnalysisToolSet } = useSelector(
        (state: RootState) => state.pricingAnalysisTool
    );
    const { pricingAnalysisTool } = useSelector(
        (state: RootState) => state.grids
    );

    useEffect(() => {
        setRowData(pricingAnalysisToolSet.activeCustomerProductDetails);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricingAnalysisToolSet.activeCustomerProductDetails]);

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const onFirstDataRendered = () => {
        gridColumnApi.applyColumnState({
            state: pricingAnalysisTool.pricingCustomerProductDetails.column,
            applyOrder: true,
        });

        gridApi.setFilterModel(
            pricingAnalysisTool.pricingCustomerProductDetails.filter
        );
    };

    const onSortChanged = () => {
        dispatch(
            updateGridModels({
                gridLocation: 'pricingAnalysisTool',
                gridName: 'pricingCustomerProductDetails',
                type: 'column',
                model: gridColumnApi.getColumnState(),
            })
        );
    };

    const onFilterChanged = () => {
        dispatch(
            updateGridModels({
                gridLocation: 'pricingAnalysisTool',
                gridName: 'pricingCustomerProductDetails',
                type: 'filter',
                model: gridApi.getFilterModel(),
            })
        );
    };

    const onBtExport = () => {
        gridApi.exportDataAsExcel();
    };
    return {
        pricingAnalysisToolSet,
        onBtExport,
        rowData,
        onFirstDataRendered,
        onFilterChanged,
        onGridReady,
        onSortChanged,
    };
};
export default usePricingCustomerProductDetailsGrid;
